import * as Sentry from '@sentry/browser'

import { filterErrors, reportError } from './utils/errorUtils'

if (process.env.RAZZLE_SENTRY_DSN_CLIENT) {
  Sentry.init({
    dsn: `${process.env.RAZZLE_SENTRY_DSN_CLIENT}`,
    beforeSend: filterErrors,
  })
}

// =============================================================================
// Apollo related imports
// =============================================================================
import { ApolloClient, InMemoryCache } from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { onError } from 'apollo-link-error'
import { setContext } from 'apollo-link-context'

const batchLink = new BatchHttpLink({
  uri: process.env.RAZZLE_GQL_URL_CLIENT,
  fetch: fetch,
})

const middlewareLink = setContext(() => {
  return {
    credentials: 'include',
    headers: {
      clientpath: `${window.location.pathname}${window.location.search}`,
    },
  }
})

const errorLink = onError(({ networkError = {}, graphQLErrors }) => {
  reportError(Sentry, graphQLErrors, networkError, undefined)
})

const link = errorLink.concat(middlewareLink).concat(batchLink)

const client = new ApolloClient({
  cache: new InMemoryCache().restore(window.__APOLLO_STATE__),
  link: link,
  queryDeduplication: true,
  ssrForceFetchDelay: 100,
})

export default client
