import './wdyr'
import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { BrowserRouter } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'
import { hydrate } from 'react-dom'
import { loadableReady } from '@loadable/component'

import App from './App'
import client from './apolloClient'

// NOTE: Sentry gets initated in ./apolloClient, which is basically as if
// we would initiate it at this line here.

const app = (
  <CookiesProvider>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApolloProvider>
  </CookiesProvider>
)

loadableReady(() => {
  const root = document.getElementById('root')
  hydrate(app, root)
})

if (module.hot) {
  module.hot.accept()
}
