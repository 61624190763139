import React from 'react'

interface Props {
  children: React.ReactNode
}

const PublicViews: React.FC<Props> = (props) => {
  return <React.Fragment>{props.children}</React.Fragment>
}

export default PublicViews
