import { css, SerializedStyles } from '@emotion/react'

export const focusStyles = {
  outlineOffset: '-2px',
  outline: '-webkit-focus-ring-color auto 5px',
}

const theme = {
  name: 'Artshare',
  fontSmooth: css`
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  `,
  mediaQueries: {
    xs: '@media screen and (min-width: 0px)',
    sm: '@media screen and (min-width: 576px)',
    md: '@media screen and (min-width: 768px)',
    lg: '@media screen and (min-width: 992px)',
    xl: '@media screen and (min-width: 1200px)',
    maxLandscapePhone: '@media screen and (max-width: 575px)',
    maxTablet: '@media screen and (max-width: 767px)',
    maxDesktop: '@media screen and (max-width: 991px)',
    minLandscapePhone: '@media screen and (min-width: 576px)',
    minTablet: '@media screen and (min-width: 768px)',
    minDesktop: '@media screen and (min-width: 992px)',
    minLargeDesktop: '@media screen and (min-width: 1200px)',
    print: '@media print',
  },
  borders: {
    inputRounded: {
      borderRadius: '20px',
    },
    card: {
      borderRadius: '5px',
    },
    focus: {
      outlineOffset: '-2px',
      outline: '3px solid rgba(0, 57, 140, 0.4)',
    },
  },
  colors: {
    white: '#ffffff',
    danger: '#C90000',
    dangerBg: '#F4D9D9',
    grayBg1: '#F7F7F7',
    grayBg2: '#EDEDED',
    iconGray: '#CECECE',
    primary: '#00398C',
    gold: '#C8AF6A',
    primaryLight: '#0BA7A7',
    success: '#016520',
    text: '#232323',
    textLink: '#C4BCB0',
    textMuted: '#6B6B6B',
    transparent: 'rgba(0,0,0,0)',
    vimr: '#FF9400',
    icons: {
      iconBlue: '#4363d8',
      iconGreen: '#0aa294',
      iconRed: '#F8947D',
      iconPink: '#F0818A',
      lightBlue: '#3bb4ff',
      iconWarning: '#C90000',
      magenta: '#8b0240',
      olive: '#b2b139',
      orange: '#FF9800',
    },
    backgrounds: {
      Bg: '#FFFFFF',
      BgGrayLight: '#f3f5f9',
      BgInputSecondary: '#C7BDB0',
      BgSidebar: '#e0e0e0',
      BgTopbar: '#A4A4A4',
      BgWizard: '#F8F8F8',
      BgWizardDark: '#E0E0E0',
      BgInfo: '#d4edda',
    },
    grays: {
      gray: '#A4A4A4',
      grayDark: '#8D8D8D',
      grayDarker: '#686868',
      colorGrayDisabled: '#A0A0A0',
      grayInputBorder: '#EEEEEE',
      grayInputBorderDark: '#AAAAAA',
      grayInputUnderline: '#222222',
      grayLight: '#CCCCCC',
      grayLighter: '#EBEBEB',
    },
    main: {
      black: '#000000',
      danger: '#910000',
      header: '#00398C',
      primary: '#022471',
      primaryDark: '#005787',
      primaryLight: '#357ae8',
      success: '#21a771',
    },
    texts: {
      text: '#232323',
      textHeading: '#1C2F51',
      textMuted: '#7C7C7C',
      textPlaceholder: '#CCCCCC',
      textCheckbox: '#232323',
      textRed: '#E94F31',
    },
    whites: {
      white: '#FFFFFF',
      whiteDark: '#D3D3D3',
    },
  },
  shadows: {
    boxShadowInput: '0px 0px 8px rgba(0, 0, 0, .1)',
    boxShadowCard: '0px 0px 5px rgba(0, 0, 0, .1)',
  },
  fonts: {
    inputText1: {
      fontSize: '22px',
      letterSpacing: '0.55px',
      lineHeight: '26px',
    },
    inputText2: {
      fontSize: '14px',
      letterSpacing: '0.35px',
      lineHeight: '18px',
    },
    buttonText2: {
      fontSize: '14px',
      letterSpacing: '0.7px',
      lineHeight: '18px',
    },
    families: {
      body: 'Cabin, sans-serif',
      headline: 'Cabin, sans-serif',
      label: 'Cabin, sans-serif',
    },
    spacing: {
      body: '0.5px',
      headline: '1px',
      button: '3px',
      lead: '2px',
      leadMobile: '1.5px',
      label: '1.4px',
      input: '0.7px',
    },
    sizes: {
      DisplayH1: {
        normal: '62px',
        mobile: '18px',
      },
      H1: {
        lead: '42px',
        leadMobile: '28px',
        normal: '28px',
        mobile: '24px',
        wizard: '38px',
      },
      H2: {
        wizard: '30px',
        normal: '18px',
      },
      H3: {
        normal: '18px',
        wizard: '24px',
      },
      inputs: {
        normal: '14px',
        small: '12px',
        wizard: '20px',
        wizardSmall: '18px',
        main: '28px',
      },
      label: {
        normal: '16px',
        small: '12px',
      },
      P: {
        normal: '16px',
        small: '14px',
        smaller: '12px',
        tiny: '10px',
        wizard: '20px',
        wizardSmall: '16px',
      },
    },
    weights: {
      label: {
        // strict casting for typechecking to work
        normal: 'bold' as 'bold',
        inactive: 'normal' as 'normal',
      },
    },
  },
  heights: {
    input: 35,
    inputWizard: 40,
    inputLarge: 51,
    inputSmall: 30,
    badge: 20,
    nav: '60px',
  },
  margins: {
    inputs: {
      inlineBlockLeftRight: '0px 0.8em',
      marginBottom: '27px',
    },
  },
  maxWidths: {
    tables: '1100px',
    paragraphs: '800px',
  },
  zIndexes: {
    sizesPopup: 1,
    topBar: 2,
    sticky: 3,
    modalContainer: 4,
    imagePreview: 5,
  },
  opacities: {
    hoverIcon: 0.6,
    disabledInput: 0.4,
  },
  paddings: {
    inputPaddingLeft: '10px',
  },
  transitions: {
    speed: 0.3,
  },
}

export const inputDisabled = {
  opacity: 0.4,
  cursor: 'not-allowed',
  color: theme.colors.grays.gray,
  ':hover': {
    opacity: 0.4,
  },
}

export const hoverOpacity = {
  transition: 'opacity 0.3s, background-color 0.3s ease, color 0.3s ease',
  ':hover': {
    opacity: 0.7,
  },
}

export const IMGSETTINGS = {
  imgTiny: '?tr=c-at_max,w-60,h-60,bg-FFFFFF',
  imgTinyPad: '?tr=cm-pad_resize,w-60,h-60,bg-FFFFFF',
  imgSmall: '?tr=c-at_max,w-200,h-200,bg-FFFFFF',
  imgSmallPad: '?tr=cm-pad_resize,w-200,h-200,bg-FFFFFF',
  imgInput: '?tr=c-at_max,w-300,h-300,bg-FFFFFF',
  imgSmallCover: '?tr=w-200,h-200,bg-FFFFFF',
  imgGrid: '?tr=c-at_least,w-360,h-360,bg-FFFFFF',
  imgDetail: '?tr=c-at_least,w-355,h-355',
  imgLarge: '?tr=c-at_least,w-1000,h-1000',
  imgPDF: '?tr=c-at_least,w-1000,h-1000,bg-FFFFFF',
  imgPDFList: '?tr=cm-pad_resize,w-150,h-150,bg-000000',
  imgFloorplan: '?tr=c-at_max,w-4400,h-2400',
}

export const whiteMQ = (props: any) =>
  !!props.whiteMQ && {
    [theme.mediaQueries[props.whiteMQ]]: {
      color: 'white',
    },
  }

export const centerMQ = (props: any) =>
  !!props.centerMQ && {
    [theme.mediaQueries[props.centerMQ]]: {
      textAlign: 'center',
    },
  }

// whenever you add a new helper-class to `const classes` below, also add it
// to this type.
type classesType =
  | ''
  | 'center'
  | 'd-none'
  | 'd-block'
  | 'd-flex'
  | 'd-inline'
  | 'd-inline-block'
  | 'd-inline-flex'
  | 'ellipsis'
  | 'flex-center'
  | 'flex-center-v'
  | 'float-left'
  | 'icon-inline'
  | 'text-black'
  | 'text-bold'
  | 'text-left'
  | 'text-right'
  | 'text-underline'
  | 'text-uppercase'
  | 'row'
  | 'col'
  | 'space-between'
  | 'm0'
  | 'mb0'
  | 'mb0.5'
  | 'mb1'
  | 'mb2'
  | 'ml0.5'
  | 'ml1'
  | 'ml1.5'
  | 'mr0.5'
  | 'mr1'
  | 'mr1.5'
  | 'mt0'
  | 'mt1'
  | 'mt2'
  | 'mt3'
  | 'mt4'
  | 'nowrap'
  | 'text-center'
  | 'text-white'
  | 'text-danger'
  | 'text-primary'
  | 'text-gray'
  | 'muted'
  | 'pointer'

// helper classes that we use often everywhere in the codebase
export const classes: { [key in classesType]: SerializedStyles } = {
  '': css({}),
  ellipsis: css({
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  }),
  center: css({ marginLeft: 'auto', marginRight: 'auto', display: 'block' }),
  'flex-center': css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  'flex-center-v': css({
    display: 'flex',
    alignItems: 'center',
  }),
  'float-left': css({ float: 'left' }),
  'icon-inline': css({
    display: 'inline-block',
    position: 'absolute',
    marginLeft: '0.5em',
  }),
  row: css({ display: 'flex', flexDirection: 'row' }),
  col: css({ display: 'flex', flexDirection: 'column' }),
  'space-between': css({justifyContent: 'space-between'}),
  'text-center': css({ textAlign: 'center' }),
  'text-right': css({ textAlign: 'right' }),
  'text-left': css({ textAlign: 'left' }),
  'text-underline': css({ textDecoration: 'underline' }),
  'text-uppercase': css({ textTransform: 'uppercase' }),
  'text-bold': css({ fontWeight: 'bold' }),
  m0: css({ margin: '0px' }),
  mb0: css({ marginBottom: '0px' }),
  'mb0.5': css({ marginBottom: '0.5em' }),
  mb1: css({ marginBottom: '1em' }),
  mb2: css({ marginBottom: '2em' }),
  mt0: css({ marginTop: '0px' }),
  mt1: css({ marginTop: '1em' }),
  mt2: css({ marginTop: '2em' }),
  mt3: css({ marginTop: '3em' }),
  mt4: css({ marginTop: '4em' }),
  'ml0.5': css({ marginLeft: '0.5em' }),
  ml1: css({ marginLeft: '1em' }),
  'ml1.5': css({ marginLeft: '1.5em' }),
  'mr0.5': css({ marginRight: '0.5em' }),
  mr1: css({ marginRight: '1em' }),
  'mr1.5': css({ marginRight: '1.5em' }),
  nowrap: css({ whiteSpace: 'nowrap' }),
  'd-none': css({ display: 'none' }),
  'd-block': css({ display: 'block' }),
  'd-inline': css({ display: 'inline' }),
  'd-flex': css({ display: 'flex' }),
  'd-inline-block': css({ display: 'inline-block' }),
  'd-inline-flex': css({ display: 'inline-flex' }),
  'text-black': css({ color: theme.colors.text }),
  'text-white': css({ color: theme.colors.white }),
  'text-danger': css({ color: theme.colors.danger }),
  'text-primary': css({ color: theme.colors.primary }),
  'text-gray': css({ color: theme.colors.iconGray }),
  muted: css({ color: theme.colors.textMuted }),
  pointer: css({ cursor: 'pointer' }),
}

export const mq = (
  className: classesType,
  mediaQuery?: MediaQuery,
  customStyles?: any
) => {
  /**
   * Helper function to add style-overrides for certain media queries and
   * helper-classes to components.
   *
   * Usage:
   *
   * <MyComponent css={[mq('mt1'), mq('d-none', 'maxLandscapePhone')]} />
   *
   * Can also be used to add custom styles for a media query:
   *
   * <MyComponent css={mq('', 'maxTablet', {fontSize: '10px'})} />
   *
   */
  if (className === '' && !!mediaQuery) {
    return {
      [theme.mediaQueries[mediaQuery]]: css(customStyles),
    }
  }

  if (!mediaQuery) {
    return classes[className]
  }

  return {
    [theme.mediaQueries[mediaQuery]]: classes[className],
  }
}

export default theme
