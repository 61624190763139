import React, { createContext, useMemo } from 'react'
import { useRouteMatch } from 'react-router-dom'
import gql from 'graphql-tag'
import get from 'lodash/get'
import { CurrentProfileFragment } from '../../fragments'
import {
  Maybe,
  useGlobalContextProviderQuery,
  GlobalContextProviderProjectFragmentFragment,
  CurrentProfileFragmentFragment,
} from '../../generated/graphql'

const query = gql`
  ${CurrentProfileFragment}
  query GlobalContextProvider($hashId: String) {
    currentProfile {
      id
      ...CurrentProfileFragment
    }
    project(hashId: $hashId) {
      id
      ...GlobalContextProviderProjectFragment
    }
  }
  fragment GlobalContextProviderProjectFragment on ProjectType {
    id
    title
    user {
      userprofile {
        hasTradeDiscount
      }
    }
  }
`

export interface GlobalContextInterface {
  loading?: boolean
  currentProfile?: Maybe<CurrentProfileFragmentFragment>
  project?: Maybe<GlobalContextProviderProjectFragmentFragment>
  tradeDiscountValid: boolean
}

export const GlobalContext = createContext<GlobalContextInterface | null>(null)

interface Props {
  children: React.ReactNode
}

const GlobalContextProvider: React.FC<Props> = (props) => {
  const match = useRouteMatch('/en/u/project/:hashId')
  const hashId = get(match, 'params.hashId', '')
  const { data, loading } = useGlobalContextProviderQuery({
    variables: { hashId },
    fetchPolicy: 'network-only',
  })

  const tradeDiscountValid = true
  // useMemo(() => {
  //   if (!data) {
  //     return false
  //   }

  //   if (get(data, 'project')) {
  //     return get(data, 'project.user.userprofile.hasTradeDiscount')
  //   }

  //   return get(data, 'currentProfile.hasTradeDiscount')
  // }, [data])

  if (!data) {
    return null
  }

  const ctx: GlobalContextInterface = {
    loading,
    currentProfile: data?.currentProfile,
    project: data?.project,
    tradeDiscountValid,
  }

  return (
    <GlobalContext.Provider value={ctx}>
      {props.children}
    </GlobalContext.Provider>
  )
}

export default GlobalContextProvider
