import React, {
  useRef,
  useEffect,
  useState,
  useContext,
  createContext,
} from 'react'

// ============================================================================
// THE CONTEXT
// ============================================================================
export interface EscKeyContextInterface {
  push: (identifier: string, onEscHandler: (e: KeyboardEvent) => void) => void
  pop: (identifier: string) => void
  stack: StackItem[]
}

export const EscKeyContext = createContext<EscKeyContextInterface | null>(null)

interface StackItem {
  identifier: string
  onEscHandler: (e: KeyboardEvent) => void
}

// ============================================================================
// THE PROVIDER
// ============================================================================
interface Props {
  children: React.ReactNode
}

export const EscKeyContextProvider: React.FC<Props> = ({ children }) => {
  const [stack, _setStack] = useState<StackItem[]>([])
  const stackRef = useRef(stack)
  const setStack = (value: StackItem[]) => {
    stackRef.current = value
    _setStack(value)
  }

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.code === 'Escape') {
      if (!!stackRef.current && stackRef.current.length > 0) {
        stackRef.current[0].onEscHandler(e)
        const newStack = [...stackRef.current]
        newStack.splice(0, 1)
        setStack(newStack)
      }
    }
  }
  const handleKeyPressRef = useRef(handleKeyPress)

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPressRef.current)
    return function cleanup() {
      window.removeEventListener('keydown', handleKeyPressRef.current)
    }
  })

  const push = (
    identifier: string,
    onEscHandler: (e: KeyboardEvent) => void
  ) => {
    const found = stackRef.current.find(
      (item) => item.identifier === identifier
    )
    if (!found) {
      const newStack = [...stackRef.current]
      newStack.splice(0, 0, { identifier, onEscHandler })
      setStack(newStack)
    }
  }

  const pop = (identifier: string) => {
    stackRef.current.forEach((item, index) => {
      if (item.identifier === identifier) {
        const newStack = [...stackRef.current]
        newStack.splice(index, 1)
        setStack(newStack)
      }
    })
  }

  const ctx = {
    push,
    pop,
    stack,
  }

  return <EscKeyContext.Provider value={ctx}>{children}</EscKeyContext.Provider>
}

// ============================================================================
// THE HOOK
// ============================================================================
export default function useEscKeyContext(): any {
  /**
   * The hook is really just a convenience function so that we can import
   * just one hook instead of importing useContext, and the Context object
   * and write this check for null everywhere we want to use it
   */
  const escCtx = useContext(EscKeyContext)
  if (!escCtx) {
    return null
  }
  return escCtx
}
