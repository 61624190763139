/** NOTE: This file is in .prettierignore */
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import loadable from '@loadable/component'
import { ThemeProvider } from '@emotion/react'

import Crisp from './components/misc/Crisp'
import EnvironmentBadge from './components/misc/EnvironmentBadge'
import GlobalContextProvider from './components/misc/GlobalContextProvider'
import PublicViews from './components/security/PublicViews'
import theme from './theme'
import { EscKeyContextProvider } from './components/hookforms/framework/hooks/useEscKeyContext'
import { ModalsContextProvider } from './components/modals/Modals'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const AdminViewsWrapper = loadable(() => import('./views/AdminViewsWrapper'))
const AppViewsWrapper = loadable(() => import('./views/AppViewsWrapper'))
const HomeView = loadable(() => import('./views/HomeView'))
const LayoutDemo = loadable(() => import('./views/LayoutDemo'))
const LoginView = loadable(() => import('./views/LoginView'))
const SignupView = loadable(() => import('./views/SignupView'))
const ResetPasswordKeyView = loadable(
  () => import('./views/ResetPasswordKeyView')
)
const WorkflowViewsWrapper = loadable(
  () => import('./views/WorkflowViewsWrapper')
)

const App = () => (
  <ThemeProvider theme={theme}>
    <GlobalContextProvider>
      <EscKeyContextProvider>
        <ModalsContextProvider>
          <EnvironmentBadge />
          <PublicViews>
            <Switch>
              <Route exact={true} path="/" component={HomeView} />
              <Route exact={true} path="/en/" component={HomeView} />
              <Route exact={true} path="/en/login/" component={LoginView} />
              <Route exact={true} path="/en/signup/" component={SignupView} />
              <Route
                exact={true}
                path="/en/password/reset/key/:key/"
                component={ResetPasswordKeyView}
              />
            </Switch>
          </PublicViews>
          <Switch>
            <Route path="/en/u/" component={AppViewsWrapper} />
            <Route path="/en/wf/" component={WorkflowViewsWrapper} />
            <Route path="/en/layout/" component={LayoutDemo} />
            <Route path="/en/staff/" component={AdminViewsWrapper} />
          </Switch>
          <Crisp />
        </ModalsContextProvider>
      </EscKeyContextProvider>
    </GlobalContextProvider>
  </ThemeProvider>
)

export default App
