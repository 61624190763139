import get from 'lodash/get'
import { useEffect, useContext } from 'react'
import { withRouter } from 'react-router'

import { GlobalContext } from './GlobalContextProvider'

const appID = process.env.RAZZLE_CRISP_WEBSITE

const canUseDOM = !!(
  typeof window !== 'undefined' &&
  appID &&
  window.document &&
  window.document.createElement
)

const Crisp = () => {
  if (!canUseDOM) {
    return null
  }

  const ctx = useContext(GlobalContext)

  useEffect(() => {
    // Initialising Crisp
    const w: any = window
    w.$crisp = []
    w.CRISP_WEBSITE_ID = `${appID}`
    const crispFunction = () => {
      const d = document
      const s: any = d.createElement('script')

      s.src = 'https://client.crisp.chat/l.js'
      s.async = 1
      d.getElementsByTagName('head')[0].appendChild(s)
    }
    crispFunction()
    w.$crisp.push(['safe', true])

    // Adding additional session data to the Crisp session
    if (!!ctx && !!ctx.currentProfile) {
      const crispInfo: any = {}
      if (!!get(ctx, 'currentProfile.id')) {
        crispInfo.userId = ctx.currentProfile.id
      }
      if (!!get(ctx, 'currentProfile.crispUserHash')) {
        crispInfo.userHash = ctx.currentProfile.crispUserHash
      }
      if (!!get(ctx, 'currentProfile.user.email')) {
        crispInfo.userEmail = ctx.currentProfile.user.email
      }

      const sessionData = []
      if (!!crispInfo.userId) {
        sessionData.push(['userId', `${crispInfo.userId}`])
      }
      if (sessionData.length > 0) {
        w.$crisp.push(['set', 'session:data', [sessionData]])
      }

      // Crisp identity verification
      // See https://help.crisp.chat/en/article/how-to-verify-user-identity-with-email-signatures-166sl01/
      if (!!crispInfo.userHash && crispInfo.userEmail) {
        w.$crisp.push([
          'set',
          'user:email',
          [crispInfo.userEmail, crispInfo.userHash],
        ])
      }
    }
  }, [])

  return null
}

const CrispRouted = withRouter(Crisp)
export default CrispRouted
