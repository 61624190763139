import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
};

export type AccessInquirySubmitMutation = {
  __typename?: 'AccessInquirySubmitMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
};

export type AddCroppedImageCacheMutation = {
  __typename?: 'AddCroppedImageCacheMutation';
  status?: Maybe<Scalars['Int']>;
  croppedImg?: Maybe<CroppedImageCacheType>;
};

export type AddFramersToProjectMutation = {
  __typename?: 'AddFramersToProjectMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  project?: Maybe<ProjectType>;
};

/** tags: addOrRemoveBucketItem */
export type AddOrRemoveBucketItemMutation = {
  __typename?: 'AddOrRemoveBucketItemMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  project?: Maybe<ProjectType>;
  locationItem?: Maybe<LocationItemType>;
};

/** tags: addProposalPage */
export type AddProposalPageMutation = {
  __typename?: 'AddProposalPageMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  proposal?: Maybe<PdfProposalType>;
  page?: Maybe<PdfPageType>;
};

/** An enumeration. */
export enum ArtistCountry {
  /** Afghanistan */
  Af = 'AF',
  /** Åland Islands */
  Ax = 'AX',
  /** Albania */
  Al = 'AL',
  /** Algeria */
  Dz = 'DZ',
  /** American Samoa */
  As = 'AS',
  /** Andorra */
  Ad = 'AD',
  /** Angola */
  Ao = 'AO',
  /** Anguilla */
  Ai = 'AI',
  /** Antarctica */
  Aq = 'AQ',
  /** Antigua and Barbuda */
  Ag = 'AG',
  /** Argentina */
  Ar = 'AR',
  /** Armenia */
  Am = 'AM',
  /** Aruba */
  Aw = 'AW',
  /** Australia */
  Au = 'AU',
  /** Austria */
  At = 'AT',
  /** Azerbaijan */
  Az = 'AZ',
  /** Bahamas */
  Bs = 'BS',
  /** Bahrain */
  Bh = 'BH',
  /** Bangladesh */
  Bd = 'BD',
  /** Barbados */
  Bb = 'BB',
  /** Belarus */
  By = 'BY',
  /** Belgium */
  Be = 'BE',
  /** Belize */
  Bz = 'BZ',
  /** Benin */
  Bj = 'BJ',
  /** Bermuda */
  Bm = 'BM',
  /** Bhutan */
  Bt = 'BT',
  /** Bolivia */
  Bo = 'BO',
  /** Bonaire, Sint Eustatius and Saba */
  Bq = 'BQ',
  /** Bosnia and Herzegovina */
  Ba = 'BA',
  /** Botswana */
  Bw = 'BW',
  /** Bouvet Island */
  Bv = 'BV',
  /** Brazil */
  Br = 'BR',
  /** British Indian Ocean Territory */
  Io = 'IO',
  /** Brunei */
  Bn = 'BN',
  /** Bulgaria */
  Bg = 'BG',
  /** Burkina Faso */
  Bf = 'BF',
  /** Burundi */
  Bi = 'BI',
  /** Cabo Verde */
  Cv = 'CV',
  /** Cambodia */
  Kh = 'KH',
  /** Cameroon */
  Cm = 'CM',
  /** Canada */
  Ca = 'CA',
  /** Cayman Islands */
  Ky = 'KY',
  /** Central African Republic */
  Cf = 'CF',
  /** Chad */
  Td = 'TD',
  /** Chile */
  Cl = 'CL',
  /** China */
  Cn = 'CN',
  /** Christmas Island */
  Cx = 'CX',
  /** Cocos (Keeling) Islands */
  Cc = 'CC',
  /** Colombia */
  Co = 'CO',
  /** Comoros */
  Km = 'KM',
  /** Congo */
  Cg = 'CG',
  /** Congo (the Democratic Republic of the) */
  Cd = 'CD',
  /** Cook Islands */
  Ck = 'CK',
  /** Costa Rica */
  Cr = 'CR',
  /** Côte d'Ivoire */
  Ci = 'CI',
  /** Croatia */
  Hr = 'HR',
  /** Cuba */
  Cu = 'CU',
  /** Curaçao */
  Cw = 'CW',
  /** Cyprus */
  Cy = 'CY',
  /** Czechia */
  Cz = 'CZ',
  /** Denmark */
  Dk = 'DK',
  /** Djibouti */
  Dj = 'DJ',
  /** Dominica */
  Dm = 'DM',
  /** Dominican Republic */
  Do = 'DO',
  /** Ecuador */
  Ec = 'EC',
  /** Egypt */
  Eg = 'EG',
  /** El Salvador */
  Sv = 'SV',
  /** Equatorial Guinea */
  Gq = 'GQ',
  /** Eritrea */
  Er = 'ER',
  /** Estonia */
  Ee = 'EE',
  /** Eswatini */
  Sz = 'SZ',
  /** Ethiopia */
  Et = 'ET',
  /** Falkland Islands  [Malvinas] */
  Fk = 'FK',
  /** Faroe Islands */
  Fo = 'FO',
  /** Fiji */
  Fj = 'FJ',
  /** Finland */
  Fi = 'FI',
  /** France */
  Fr = 'FR',
  /** French Guiana */
  Gf = 'GF',
  /** French Polynesia */
  Pf = 'PF',
  /** French Southern Territories */
  Tf = 'TF',
  /** Gabon */
  Ga = 'GA',
  /** Gambia */
  Gm = 'GM',
  /** Georgia */
  Ge = 'GE',
  /** Germany */
  De = 'DE',
  /** Ghana */
  Gh = 'GH',
  /** Gibraltar */
  Gi = 'GI',
  /** Greece */
  Gr = 'GR',
  /** Greenland */
  Gl = 'GL',
  /** Grenada */
  Gd = 'GD',
  /** Guadeloupe */
  Gp = 'GP',
  /** Guam */
  Gu = 'GU',
  /** Guatemala */
  Gt = 'GT',
  /** Guernsey */
  Gg = 'GG',
  /** Guinea */
  Gn = 'GN',
  /** Guinea-Bissau */
  Gw = 'GW',
  /** Guyana */
  Gy = 'GY',
  /** Haiti */
  Ht = 'HT',
  /** Heard Island and McDonald Islands */
  Hm = 'HM',
  /** Holy See */
  Va = 'VA',
  /** Honduras */
  Hn = 'HN',
  /** Hong Kong */
  Hk = 'HK',
  /** Hungary */
  Hu = 'HU',
  /** Iceland */
  Is = 'IS',
  /** India */
  In = 'IN',
  /** Indonesia */
  Id = 'ID',
  /** Iran */
  Ir = 'IR',
  /** Iraq */
  Iq = 'IQ',
  /** Ireland */
  Ie = 'IE',
  /** Isle of Man */
  Im = 'IM',
  /** Israel */
  Il = 'IL',
  /** Italy */
  It = 'IT',
  /** Jamaica */
  Jm = 'JM',
  /** Japan */
  Jp = 'JP',
  /** Jersey */
  Je = 'JE',
  /** Jordan */
  Jo = 'JO',
  /** Kazakhstan */
  Kz = 'KZ',
  /** Kenya */
  Ke = 'KE',
  /** Kiribati */
  Ki = 'KI',
  /** Kuwait */
  Kw = 'KW',
  /** Kyrgyzstan */
  Kg = 'KG',
  /** Laos */
  La = 'LA',
  /** Latvia */
  Lv = 'LV',
  /** Lebanon */
  Lb = 'LB',
  /** Lesotho */
  Ls = 'LS',
  /** Liberia */
  Lr = 'LR',
  /** Libya */
  Ly = 'LY',
  /** Liechtenstein */
  Li = 'LI',
  /** Lithuania */
  Lt = 'LT',
  /** Luxembourg */
  Lu = 'LU',
  /** Macao */
  Mo = 'MO',
  /** Madagascar */
  Mg = 'MG',
  /** Malawi */
  Mw = 'MW',
  /** Malaysia */
  My = 'MY',
  /** Maldives */
  Mv = 'MV',
  /** Mali */
  Ml = 'ML',
  /** Malta */
  Mt = 'MT',
  /** Marshall Islands */
  Mh = 'MH',
  /** Martinique */
  Mq = 'MQ',
  /** Mauritania */
  Mr = 'MR',
  /** Mauritius */
  Mu = 'MU',
  /** Mayotte */
  Yt = 'YT',
  /** Mexico */
  Mx = 'MX',
  /** Micronesia (Federated States of) */
  Fm = 'FM',
  /** Moldova */
  Md = 'MD',
  /** Monaco */
  Mc = 'MC',
  /** Mongolia */
  Mn = 'MN',
  /** Montenegro */
  Me = 'ME',
  /** Montserrat */
  Ms = 'MS',
  /** Morocco */
  Ma = 'MA',
  /** Mozambique */
  Mz = 'MZ',
  /** Myanmar */
  Mm = 'MM',
  /** Namibia */
  Na = 'NA',
  /** Nauru */
  Nr = 'NR',
  /** Nepal */
  Np = 'NP',
  /** Netherlands */
  Nl = 'NL',
  /** New Caledonia */
  Nc = 'NC',
  /** New Zealand */
  Nz = 'NZ',
  /** Nicaragua */
  Ni = 'NI',
  /** Niger */
  Ne = 'NE',
  /** Nigeria */
  Ng = 'NG',
  /** Niue */
  Nu = 'NU',
  /** Norfolk Island */
  Nf = 'NF',
  /** North Korea */
  Kp = 'KP',
  /** North Macedonia */
  Mk = 'MK',
  /** Northern Mariana Islands */
  Mp = 'MP',
  /** Norway */
  No = 'NO',
  /** Oman */
  Om = 'OM',
  /** Pakistan */
  Pk = 'PK',
  /** Palau */
  Pw = 'PW',
  /** Palestine, State of */
  Ps = 'PS',
  /** Panama */
  Pa = 'PA',
  /** Papua New Guinea */
  Pg = 'PG',
  /** Paraguay */
  Py = 'PY',
  /** Peru */
  Pe = 'PE',
  /** Philippines */
  Ph = 'PH',
  /** Pitcairn */
  Pn = 'PN',
  /** Poland */
  Pl = 'PL',
  /** Portugal */
  Pt = 'PT',
  /** Puerto Rico */
  Pr = 'PR',
  /** Qatar */
  Qa = 'QA',
  /** Réunion */
  Re = 'RE',
  /** Romania */
  Ro = 'RO',
  /** Russia */
  Ru = 'RU',
  /** Rwanda */
  Rw = 'RW',
  /** Saint Barthélemy */
  Bl = 'BL',
  /** Saint Helena, Ascension and Tristan da Cunha */
  Sh = 'SH',
  /** Saint Kitts and Nevis */
  Kn = 'KN',
  /** Saint Lucia */
  Lc = 'LC',
  /** Saint Martin (French part) */
  Mf = 'MF',
  /** Saint Pierre and Miquelon */
  Pm = 'PM',
  /** Saint Vincent and the Grenadines */
  Vc = 'VC',
  /** Samoa */
  Ws = 'WS',
  /** San Marino */
  Sm = 'SM',
  /** Sao Tome and Principe */
  St = 'ST',
  /** Saudi Arabia */
  Sa = 'SA',
  /** Senegal */
  Sn = 'SN',
  /** Serbia */
  Rs = 'RS',
  /** Seychelles */
  Sc = 'SC',
  /** Sierra Leone */
  Sl = 'SL',
  /** Singapore */
  Sg = 'SG',
  /** Sint Maarten (Dutch part) */
  Sx = 'SX',
  /** Slovakia */
  Sk = 'SK',
  /** Slovenia */
  Si = 'SI',
  /** Solomon Islands */
  Sb = 'SB',
  /** Somalia */
  So = 'SO',
  /** South Africa */
  Za = 'ZA',
  /** South Georgia and the South Sandwich Islands */
  Gs = 'GS',
  /** South Korea */
  Kr = 'KR',
  /** South Sudan */
  Ss = 'SS',
  /** Spain */
  Es = 'ES',
  /** Sri Lanka */
  Lk = 'LK',
  /** Sudan */
  Sd = 'SD',
  /** Suriname */
  Sr = 'SR',
  /** Svalbard and Jan Mayen */
  Sj = 'SJ',
  /** Sweden */
  Se = 'SE',
  /** Switzerland */
  Ch = 'CH',
  /** Syria */
  Sy = 'SY',
  /** Taiwan */
  Tw = 'TW',
  /** Tajikistan */
  Tj = 'TJ',
  /** Tanzania */
  Tz = 'TZ',
  /** Thailand */
  Th = 'TH',
  /** Timor-Leste */
  Tl = 'TL',
  /** Togo */
  Tg = 'TG',
  /** Tokelau */
  Tk = 'TK',
  /** Tonga */
  To = 'TO',
  /** Trinidad and Tobago */
  Tt = 'TT',
  /** Tunisia */
  Tn = 'TN',
  /** Turkey */
  Tr = 'TR',
  /** Turkmenistan */
  Tm = 'TM',
  /** Turks and Caicos Islands */
  Tc = 'TC',
  /** Tuvalu */
  Tv = 'TV',
  /** Uganda */
  Ug = 'UG',
  /** Ukraine */
  Ua = 'UA',
  /** United Arab Emirates */
  Ae = 'AE',
  /** United Kingdom */
  Gb = 'GB',
  /** United States Minor Outlying Islands */
  Um = 'UM',
  /** United States of America */
  Us = 'US',
  /** Uruguay */
  Uy = 'UY',
  /** Uzbekistan */
  Uz = 'UZ',
  /** Vanuatu */
  Vu = 'VU',
  /** Venezuela */
  Ve = 'VE',
  /** Vietnam */
  Vn = 'VN',
  /** Virgin Islands (British) */
  Vg = 'VG',
  /** Virgin Islands (U.S.) */
  Vi = 'VI',
  /** Wallis and Futuna */
  Wf = 'WF',
  /** Western Sahara */
  Eh = 'EH',
  /** Yemen */
  Ye = 'YE',
  /** Zambia */
  Zm = 'ZM',
  /** Zimbabwe */
  Zw = 'ZW'
}

export type ArtistCreateOrUpdateMutation = {
  __typename?: 'ArtistCreateOrUpdateMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  artist?: Maybe<ArtistSourcingType>;
};

export type ArtistDetailType = {
  __typename?: 'ArtistDetailType';
  artistName?: Maybe<Scalars['String']>;
  artistSlug?: Maybe<Scalars['String']>;
  artistContactEmail?: Maybe<Scalars['String']>;
  artistYearOfBirth?: Maybe<Scalars['String']>;
  artistCountry?: Maybe<Scalars['String']>;
  artistWebsite?: Maybe<Scalars['String']>;
};

export type ArtistProductImage = {
  __typename?: 'ArtistProductImage';
  id: Scalars['ID'];
  variant?: Maybe<ArtistVariantType>;
  croppedImage?: Maybe<Scalars['String']>;
  imageKey?: Maybe<Scalars['String']>;
  imageKeyFilename: Scalars['String'];
  features: Scalars['String'];
  esId?: Maybe<Scalars['String']>;
  tags: Scalars['String'];
  deleted: Scalars['Boolean'];
};

export type ArtistSourcingPaginatedType = {
  __typename?: 'ArtistSourcingPaginatedType';
  page: Scalars['Int'];
  pages: Scalars['Int'];
  hasNext: Scalars['Boolean'];
  hasPrev: Scalars['Boolean'];
  objects: Array<ArtistSourcingType>;
  total: Scalars['Int'];
};

export type ArtistSourcingType = {
  __typename?: 'ArtistSourcingType';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  contactEmail: Scalars['String'];
  yearOfBirth?: Maybe<Scalars['Int']>;
  country: ArtistCountry;
  tags: Scalars['String'];
  notes: Scalars['String'];
  deleted: Scalars['Boolean'];
  variants?: Maybe<Array<Maybe<ArtistVariantType>>>;
  flowitemcommissionedworkSet?: Maybe<Array<Maybe<FlowItemCommissionedWorkConfirmAvailabilityType>>>;
  countryName?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
};

export type ArtistType = {
  __typename?: 'ArtistType';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  gridInfo?: Maybe<Scalars['String']>;
  detailUrl?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  country?: Maybe<SlugAndNameType>;
  canDoCommissionedWork?: Maybe<Scalars['Boolean']>;
  canDoPublicMurals?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum ArtistVariantLocation {
  /** Afghanistan */
  Af = 'AF',
  /** Åland Islands */
  Ax = 'AX',
  /** Albania */
  Al = 'AL',
  /** Algeria */
  Dz = 'DZ',
  /** American Samoa */
  As = 'AS',
  /** Andorra */
  Ad = 'AD',
  /** Angola */
  Ao = 'AO',
  /** Anguilla */
  Ai = 'AI',
  /** Antarctica */
  Aq = 'AQ',
  /** Antigua and Barbuda */
  Ag = 'AG',
  /** Argentina */
  Ar = 'AR',
  /** Armenia */
  Am = 'AM',
  /** Aruba */
  Aw = 'AW',
  /** Australia */
  Au = 'AU',
  /** Austria */
  At = 'AT',
  /** Azerbaijan */
  Az = 'AZ',
  /** Bahamas */
  Bs = 'BS',
  /** Bahrain */
  Bh = 'BH',
  /** Bangladesh */
  Bd = 'BD',
  /** Barbados */
  Bb = 'BB',
  /** Belarus */
  By = 'BY',
  /** Belgium */
  Be = 'BE',
  /** Belize */
  Bz = 'BZ',
  /** Benin */
  Bj = 'BJ',
  /** Bermuda */
  Bm = 'BM',
  /** Bhutan */
  Bt = 'BT',
  /** Bolivia */
  Bo = 'BO',
  /** Bonaire, Sint Eustatius and Saba */
  Bq = 'BQ',
  /** Bosnia and Herzegovina */
  Ba = 'BA',
  /** Botswana */
  Bw = 'BW',
  /** Bouvet Island */
  Bv = 'BV',
  /** Brazil */
  Br = 'BR',
  /** British Indian Ocean Territory */
  Io = 'IO',
  /** Brunei */
  Bn = 'BN',
  /** Bulgaria */
  Bg = 'BG',
  /** Burkina Faso */
  Bf = 'BF',
  /** Burundi */
  Bi = 'BI',
  /** Cabo Verde */
  Cv = 'CV',
  /** Cambodia */
  Kh = 'KH',
  /** Cameroon */
  Cm = 'CM',
  /** Canada */
  Ca = 'CA',
  /** Cayman Islands */
  Ky = 'KY',
  /** Central African Republic */
  Cf = 'CF',
  /** Chad */
  Td = 'TD',
  /** Chile */
  Cl = 'CL',
  /** China */
  Cn = 'CN',
  /** Christmas Island */
  Cx = 'CX',
  /** Cocos (Keeling) Islands */
  Cc = 'CC',
  /** Colombia */
  Co = 'CO',
  /** Comoros */
  Km = 'KM',
  /** Congo */
  Cg = 'CG',
  /** Congo (the Democratic Republic of the) */
  Cd = 'CD',
  /** Cook Islands */
  Ck = 'CK',
  /** Costa Rica */
  Cr = 'CR',
  /** Côte d'Ivoire */
  Ci = 'CI',
  /** Croatia */
  Hr = 'HR',
  /** Cuba */
  Cu = 'CU',
  /** Curaçao */
  Cw = 'CW',
  /** Cyprus */
  Cy = 'CY',
  /** Czechia */
  Cz = 'CZ',
  /** Denmark */
  Dk = 'DK',
  /** Djibouti */
  Dj = 'DJ',
  /** Dominica */
  Dm = 'DM',
  /** Dominican Republic */
  Do = 'DO',
  /** Ecuador */
  Ec = 'EC',
  /** Egypt */
  Eg = 'EG',
  /** El Salvador */
  Sv = 'SV',
  /** Equatorial Guinea */
  Gq = 'GQ',
  /** Eritrea */
  Er = 'ER',
  /** Estonia */
  Ee = 'EE',
  /** Eswatini */
  Sz = 'SZ',
  /** Ethiopia */
  Et = 'ET',
  /** Falkland Islands  [Malvinas] */
  Fk = 'FK',
  /** Faroe Islands */
  Fo = 'FO',
  /** Fiji */
  Fj = 'FJ',
  /** Finland */
  Fi = 'FI',
  /** France */
  Fr = 'FR',
  /** French Guiana */
  Gf = 'GF',
  /** French Polynesia */
  Pf = 'PF',
  /** French Southern Territories */
  Tf = 'TF',
  /** Gabon */
  Ga = 'GA',
  /** Gambia */
  Gm = 'GM',
  /** Georgia */
  Ge = 'GE',
  /** Germany */
  De = 'DE',
  /** Ghana */
  Gh = 'GH',
  /** Gibraltar */
  Gi = 'GI',
  /** Greece */
  Gr = 'GR',
  /** Greenland */
  Gl = 'GL',
  /** Grenada */
  Gd = 'GD',
  /** Guadeloupe */
  Gp = 'GP',
  /** Guam */
  Gu = 'GU',
  /** Guatemala */
  Gt = 'GT',
  /** Guernsey */
  Gg = 'GG',
  /** Guinea */
  Gn = 'GN',
  /** Guinea-Bissau */
  Gw = 'GW',
  /** Guyana */
  Gy = 'GY',
  /** Haiti */
  Ht = 'HT',
  /** Heard Island and McDonald Islands */
  Hm = 'HM',
  /** Holy See */
  Va = 'VA',
  /** Honduras */
  Hn = 'HN',
  /** Hong Kong */
  Hk = 'HK',
  /** Hungary */
  Hu = 'HU',
  /** Iceland */
  Is = 'IS',
  /** India */
  In = 'IN',
  /** Indonesia */
  Id = 'ID',
  /** Iran */
  Ir = 'IR',
  /** Iraq */
  Iq = 'IQ',
  /** Ireland */
  Ie = 'IE',
  /** Isle of Man */
  Im = 'IM',
  /** Israel */
  Il = 'IL',
  /** Italy */
  It = 'IT',
  /** Jamaica */
  Jm = 'JM',
  /** Japan */
  Jp = 'JP',
  /** Jersey */
  Je = 'JE',
  /** Jordan */
  Jo = 'JO',
  /** Kazakhstan */
  Kz = 'KZ',
  /** Kenya */
  Ke = 'KE',
  /** Kiribati */
  Ki = 'KI',
  /** Kuwait */
  Kw = 'KW',
  /** Kyrgyzstan */
  Kg = 'KG',
  /** Laos */
  La = 'LA',
  /** Latvia */
  Lv = 'LV',
  /** Lebanon */
  Lb = 'LB',
  /** Lesotho */
  Ls = 'LS',
  /** Liberia */
  Lr = 'LR',
  /** Libya */
  Ly = 'LY',
  /** Liechtenstein */
  Li = 'LI',
  /** Lithuania */
  Lt = 'LT',
  /** Luxembourg */
  Lu = 'LU',
  /** Macao */
  Mo = 'MO',
  /** Madagascar */
  Mg = 'MG',
  /** Malawi */
  Mw = 'MW',
  /** Malaysia */
  My = 'MY',
  /** Maldives */
  Mv = 'MV',
  /** Mali */
  Ml = 'ML',
  /** Malta */
  Mt = 'MT',
  /** Marshall Islands */
  Mh = 'MH',
  /** Martinique */
  Mq = 'MQ',
  /** Mauritania */
  Mr = 'MR',
  /** Mauritius */
  Mu = 'MU',
  /** Mayotte */
  Yt = 'YT',
  /** Mexico */
  Mx = 'MX',
  /** Micronesia (Federated States of) */
  Fm = 'FM',
  /** Moldova */
  Md = 'MD',
  /** Monaco */
  Mc = 'MC',
  /** Mongolia */
  Mn = 'MN',
  /** Montenegro */
  Me = 'ME',
  /** Montserrat */
  Ms = 'MS',
  /** Morocco */
  Ma = 'MA',
  /** Mozambique */
  Mz = 'MZ',
  /** Myanmar */
  Mm = 'MM',
  /** Namibia */
  Na = 'NA',
  /** Nauru */
  Nr = 'NR',
  /** Nepal */
  Np = 'NP',
  /** Netherlands */
  Nl = 'NL',
  /** New Caledonia */
  Nc = 'NC',
  /** New Zealand */
  Nz = 'NZ',
  /** Nicaragua */
  Ni = 'NI',
  /** Niger */
  Ne = 'NE',
  /** Nigeria */
  Ng = 'NG',
  /** Niue */
  Nu = 'NU',
  /** Norfolk Island */
  Nf = 'NF',
  /** North Korea */
  Kp = 'KP',
  /** North Macedonia */
  Mk = 'MK',
  /** Northern Mariana Islands */
  Mp = 'MP',
  /** Norway */
  No = 'NO',
  /** Oman */
  Om = 'OM',
  /** Pakistan */
  Pk = 'PK',
  /** Palau */
  Pw = 'PW',
  /** Palestine, State of */
  Ps = 'PS',
  /** Panama */
  Pa = 'PA',
  /** Papua New Guinea */
  Pg = 'PG',
  /** Paraguay */
  Py = 'PY',
  /** Peru */
  Pe = 'PE',
  /** Philippines */
  Ph = 'PH',
  /** Pitcairn */
  Pn = 'PN',
  /** Poland */
  Pl = 'PL',
  /** Portugal */
  Pt = 'PT',
  /** Puerto Rico */
  Pr = 'PR',
  /** Qatar */
  Qa = 'QA',
  /** Réunion */
  Re = 'RE',
  /** Romania */
  Ro = 'RO',
  /** Russia */
  Ru = 'RU',
  /** Rwanda */
  Rw = 'RW',
  /** Saint Barthélemy */
  Bl = 'BL',
  /** Saint Helena, Ascension and Tristan da Cunha */
  Sh = 'SH',
  /** Saint Kitts and Nevis */
  Kn = 'KN',
  /** Saint Lucia */
  Lc = 'LC',
  /** Saint Martin (French part) */
  Mf = 'MF',
  /** Saint Pierre and Miquelon */
  Pm = 'PM',
  /** Saint Vincent and the Grenadines */
  Vc = 'VC',
  /** Samoa */
  Ws = 'WS',
  /** San Marino */
  Sm = 'SM',
  /** Sao Tome and Principe */
  St = 'ST',
  /** Saudi Arabia */
  Sa = 'SA',
  /** Senegal */
  Sn = 'SN',
  /** Serbia */
  Rs = 'RS',
  /** Seychelles */
  Sc = 'SC',
  /** Sierra Leone */
  Sl = 'SL',
  /** Singapore */
  Sg = 'SG',
  /** Sint Maarten (Dutch part) */
  Sx = 'SX',
  /** Slovakia */
  Sk = 'SK',
  /** Slovenia */
  Si = 'SI',
  /** Solomon Islands */
  Sb = 'SB',
  /** Somalia */
  So = 'SO',
  /** South Africa */
  Za = 'ZA',
  /** South Georgia and the South Sandwich Islands */
  Gs = 'GS',
  /** South Korea */
  Kr = 'KR',
  /** South Sudan */
  Ss = 'SS',
  /** Spain */
  Es = 'ES',
  /** Sri Lanka */
  Lk = 'LK',
  /** Sudan */
  Sd = 'SD',
  /** Suriname */
  Sr = 'SR',
  /** Svalbard and Jan Mayen */
  Sj = 'SJ',
  /** Sweden */
  Se = 'SE',
  /** Switzerland */
  Ch = 'CH',
  /** Syria */
  Sy = 'SY',
  /** Taiwan */
  Tw = 'TW',
  /** Tajikistan */
  Tj = 'TJ',
  /** Tanzania */
  Tz = 'TZ',
  /** Thailand */
  Th = 'TH',
  /** Timor-Leste */
  Tl = 'TL',
  /** Togo */
  Tg = 'TG',
  /** Tokelau */
  Tk = 'TK',
  /** Tonga */
  To = 'TO',
  /** Trinidad and Tobago */
  Tt = 'TT',
  /** Tunisia */
  Tn = 'TN',
  /** Turkey */
  Tr = 'TR',
  /** Turkmenistan */
  Tm = 'TM',
  /** Turks and Caicos Islands */
  Tc = 'TC',
  /** Tuvalu */
  Tv = 'TV',
  /** Uganda */
  Ug = 'UG',
  /** Ukraine */
  Ua = 'UA',
  /** United Arab Emirates */
  Ae = 'AE',
  /** United Kingdom */
  Gb = 'GB',
  /** United States Minor Outlying Islands */
  Um = 'UM',
  /** United States of America */
  Us = 'US',
  /** Uruguay */
  Uy = 'UY',
  /** Uzbekistan */
  Uz = 'UZ',
  /** Vanuatu */
  Vu = 'VU',
  /** Venezuela */
  Ve = 'VE',
  /** Vietnam */
  Vn = 'VN',
  /** Virgin Islands (British) */
  Vg = 'VG',
  /** Virgin Islands (U.S.) */
  Vi = 'VI',
  /** Wallis and Futuna */
  Wf = 'WF',
  /** Western Sahara */
  Eh = 'EH',
  /** Yemen */
  Ye = 'YE',
  /** Zambia */
  Zm = 'ZM',
  /** Zimbabwe */
  Zw = 'ZW'
}

export type ArtistVariantPaginatedType = {
  __typename?: 'ArtistVariantPaginatedType';
  page: Scalars['Int'];
  pages: Scalars['Int'];
  hasNext: Scalars['Boolean'];
  hasPrev: Scalars['Boolean'];
  objects: Array<ArtistVariantType>;
  total: Scalars['Int'];
};

/**
 * tags: artistVariantProjectCreateUpdate
 *
 * Used in ArtistVariantProjectForm in ModalArtistVariantProjectDetail
 */
export type ArtistVariantProjectCreateOrUpdateMutation = {
  __typename?: 'ArtistVariantProjectCreateOrUpdateMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  artistVariantProject?: Maybe<ArtistVariantProjectType>;
  artistVariant?: Maybe<ArtistVariantType>;
};

/**
 * tags: artistVariantProjectDelete
 *
 * Used in AddArtistVariantForm
 */
export type ArtistVariantProjectDeleteMutation = {
  __typename?: 'ArtistVariantProjectDeleteMutation';
  status?: Maybe<Scalars['Int']>;
  artistVariant?: Maybe<ArtistVariantType>;
};

/** GQL Type for the ArtistVariantProject model */
export type ArtistVariantProjectType = {
  __typename?: 'ArtistVariantProjectType';
  id: Scalars['ID'];
  variant?: Maybe<ArtistVariantType>;
  name: Scalars['String'];
  date?: Maybe<Scalars['Date']>;
  rate?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  deleted: Scalars['Boolean'];
  locItem?: Maybe<LocationItemType>;
};

export type ArtistVariantType = {
  __typename?: 'ArtistVariantType';
  id: Scalars['ID'];
  artist?: Maybe<ArtistSourcingType>;
  location: ArtistVariantLocation;
  galleries?: Maybe<Array<Maybe<GallerySourcingType>>>;
  medium?: Maybe<MediumType>;
  artworkStyle?: Maybe<StyleType>;
  priceLow?: Maybe<Scalars['Float']>;
  priceHigh?: Maybe<Scalars['Float']>;
  priceRange?: Maybe<Scalars['String']>;
  dataRequestDate?: Maybe<Scalars['Date']>;
  projectKeys?: Maybe<Array<Maybe<ProjectType>>>;
  tags: Scalars['String'];
  notes: Scalars['String'];
  deleted: Scalars['Boolean'];
  projects: Array<ArtistVariantProjectType>;
  productImages: Array<ArtistProductImage>;
  allProjects?: Maybe<Scalars['String']>;
};

export type ArtistVariantUpdateCreateMutation = {
  __typename?: 'ArtistVariantUpdateCreateMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  artistVariant?: Maybe<ArtistVariantType>;
};

/** GQL Type for the Attribute_1 model. */
export type Attribute1Type = {
  __typename?: 'Attribute1Type';
  id: Scalars['ID'];
  title: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  parent?: Maybe<Array<Maybe<CategoryType>>>;
  locationItems?: Maybe<Array<Maybe<LocationItemType>>>;
};

/** GQL Type for the Attribute_2 model. */
export type Attribute2Type = {
  __typename?: 'Attribute2Type';
  id: Scalars['ID'];
  title: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  parent?: Maybe<Array<Maybe<CategoryType>>>;
  locationItems?: Maybe<Array<Maybe<LocationItemType>>>;
};

/** GQL Type for the BucketItem model. */
export type BucketItemType = {
  __typename?: 'BucketItemType';
  id: Scalars['ID'];
  locationItem?: Maybe<LocationItemType>;
  location?: Maybe<LocationType>;
  artistSlug?: Maybe<Scalars['String']>;
  esId: Scalars['String'];
  position?: Maybe<Scalars['Int']>;
  selectedSize: Scalars['String'];
  wallPt: Scalars['String'];
  isShortlisted: Scalars['Boolean'];
  quotationFramingRequired: Scalars['Boolean'];
  quotationFramingComments: Scalars['String'];
  quotationAdditionalComments: Scalars['String'];
  quotationQuantity: Scalars['Int'];
  originalPrice?: Maybe<Scalars['Int']>;
  artworkTitle: Scalars['String'];
  artistName: Scalars['String'];
  price: Scalars['String'];
  shipsFrom: Scalars['String'];
  dimensions: Scalars['String'];
  mediums: Scalars['String'];
  otherDetails: Scalars['String'];
  image: Scalars['String'];
  customSize: Scalars['String'];
  pdfActive: Scalars['Boolean'];
  detailsGenerated: Scalars['Boolean'];
  vimrImages: Array<ProjectVimrImageType>;
  flowitemartworkSet?: Maybe<Array<Maybe<FlowItemArtworkFramerRfqType>>>;
  flowitemcommissionedworkSet?: Maybe<Array<Maybe<FlowItemCommissionedWorkConfirmAvailabilityType>>>;
  framingStatus: Scalars['String'];
  savedFrame?: Maybe<SavedFrameType>;
  stretchingStatus: Scalars['String'];
  framingAndStretchingStatus?: Maybe<Scalars['String']>;
  customText: Scalars['String'];
};

/** GQL Type for the Category model. */
export type CategoryType = {
  __typename?: 'CategoryType';
  id: Scalars['ID'];
  title: Scalars['String'];
  slug: Scalars['String'];
  articleAndNoun: Scalars['String'];
  attributes?: Maybe<Array<Maybe<Attribute1Type>>>;
  subAttributes?: Maybe<Array<Maybe<Attribute2Type>>>;
  locationItems?: Maybe<Array<Maybe<LocationItemType>>>;
};

/** GQL Type for the Client model. */
export type ClientType = {
  __typename?: 'ClientType';
  id: Scalars['ID'];
  user?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  projects?: Maybe<Array<Maybe<ProjectType>>>;
};

export type ColorType = {
  __typename?: 'ColorType';
  h?: Maybe<Scalars['Int']>;
  s?: Maybe<Scalars['Int']>;
  v?: Maybe<Scalars['Int']>;
};

export type CommissionedWorksLocation = {
  __typename?: 'CommissionedWorksLocation';
  locationTitle?: Maybe<Scalars['String']>;
  locationId: Scalars['String'];
  locationItems: Array<CommissionedWorksLocationItem>;
};

export type CommissionedWorksLocationItem = {
  __typename?: 'CommissionedWorksLocationItem';
  locationItemTitle?: Maybe<Scalars['String']>;
  locationItemId: Scalars['String'];
  steps: Array<CommissionedWorksStepItems>;
};

export type CommissionedWorksProjectSteps = {
  __typename?: 'CommissionedWorksProjectSteps';
  itemGroup?: Maybe<Scalars['String']>;
  projectTitle?: Maybe<Scalars['String']>;
  projectId: Scalars['String'];
  steps: Array<CommissionedWorksStepItems>;
};

export type CommissionedWorksStepItems = {
  __typename?: 'CommissionedWorksStepItems';
  step?: Maybe<Scalars['String']>;
  items: Array<FlowItemCommissionedWorkType>;
};

export type CountryType = {
  __typename?: 'CountryType';
  name: Scalars['String'];
  code: Scalars['String'];
};

export type CreateClientMutation = {
  __typename?: 'CreateClientMutation';
  status?: Maybe<Scalars['Int']>;
};

export type CreateNewLocationItemMutation = {
  __typename?: 'CreateNewLocationItemMutation';
  status?: Maybe<Scalars['Int']>;
  project?: Maybe<ProjectType>;
  location?: Maybe<LocationType>;
  locationItem?: Maybe<LocationItemType>;
};

export type CreateNewLocationMutation = {
  __typename?: 'CreateNewLocationMutation';
  status?: Maybe<Scalars['Int']>;
  project?: Maybe<ProjectType>;
  location?: Maybe<LocationType>;
};

/** tags: createNewProject */
export type CreateNewProjectMutation = {
  __typename?: 'CreateNewProjectMutation';
  status?: Maybe<Scalars['Int']>;
  project?: Maybe<ProjectType>;
};

/** tags: createNewProposal */
export type CreateNewProposalMutation = {
  __typename?: 'CreateNewProposalMutation';
  status?: Maybe<Scalars['Int']>;
  proposal?: Maybe<PdfProposalType>;
};

export type CreateOrUpdateFramerMutation = {
  __typename?: 'CreateOrUpdateFramerMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  framer?: Maybe<FramerType>;
};

/**
 * Called via AddToNewShortlistFormMutation.
 *
 * Creates a new Project with a Location and an LocationItem and adds the
 * given size & es_id as a BucketItem to the LocationItem.
 */
export type CreateQuickSourceMutation = {
  __typename?: 'CreateQuickSourceMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  project?: Maybe<ProjectType>;
};

export type CreateUpdateLocationImageMutation = {
  __typename?: 'CreateUpdateLocationImageMutation';
  status?: Maybe<Scalars['Int']>;
  project?: Maybe<ProjectType>;
  location?: Maybe<LocationType>;
};

/**
 * Called via ViewInRoomCTAMutation
 *
 * Uploads and saves user images for use with VIR
 * (frontend form limits number of active images to 3 by popping out oldest image)
 */
export type CreateUpdateUserVirImageMutation = {
  __typename?: 'CreateUpdateUserVIRImageMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  autoSubmit?: Maybe<Scalars['String']>;
  userProfile?: Maybe<UserProfileType>;
};

export type CreateVimrImagesMutation = {
  __typename?: 'CreateVIMRImagesMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  autoSubmit?: Maybe<Scalars['String']>;
  locationItem?: Maybe<LocationItemType>;
  project?: Maybe<ProjectType>;
  bucketItems?: Maybe<Array<BucketItemType>>;
};

/** GQL Type for the CroppedImageCache model. */
export type CroppedImageCacheType = {
  __typename?: 'CroppedImageCacheType';
  id: Scalars['ID'];
  imageHash: Scalars['String'];
  isCropped: Scalars['Boolean'];
  croppedKey: Scalars['String'];
  key: Scalars['String'];
  lastUsed: Scalars['DateTime'];
};

export type CurerntStateType = {
  __typename?: 'CurerntStateType';
  id: Scalars['ID'];
  state: Scalars['String'];
  workflow: Scalars['String'];
  objectId: Scalars['Int'];
  changeUser?: Maybe<UserType>;
  changeUserStr: Scalars['String'];
  dateCreated: Scalars['DateTime'];
  dateUpdated: Scalars['DateTime'];
  dateFinished?: Maybe<Scalars['DateTime']>;
  data: Scalars['String'];
  lastStates?: Maybe<Array<Maybe<CurerntStateType>>>;
  uniqueUrl: Scalars['String'];
  nextStates?: Maybe<Array<Maybe<CurerntStateType>>>;
  workflowNormalWorksFlowitemartworkFlowItems?: Maybe<Array<Maybe<FlowItemArtworkFramerRfqType>>>;
  workflowCommissionedWorksFlowitemcommissionedworkFlowItems?: Maybe<Array<Maybe<FlowItemCommissionedWorkConfirmAvailabilityType>>>;
};



export type DeleteFramerFromProjectMutation = {
  __typename?: 'DeleteFramerFromProjectMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  project?: Maybe<ProjectType>;
};

export type DeleteLocationItemMutation = {
  __typename?: 'DeleteLocationItemMutation';
  status?: Maybe<Scalars['Int']>;
  project?: Maybe<ProjectType>;
};

/** tags: deleteLocation */
export type DeleteLocationMutation = {
  __typename?: 'DeleteLocationMutation';
  status?: Maybe<Scalars['Int']>;
  project?: Maybe<ProjectType>;
};

/** tags: deleteProject */
export type DeleteProjectMutation = {
  __typename?: 'DeleteProjectMutation';
  status?: Maybe<Scalars['Int']>;
  project?: Maybe<ProjectType>;
};

/** tags: deleteProposal */
export type DeleteProposalMutation = {
  __typename?: 'DeleteProposalMutation';
  status?: Maybe<Scalars['Int']>;
};

/** tags: deleteProposalPage */
export type DeleteProposalPageMutation = {
  __typename?: 'DeleteProposalPageMutation';
  status?: Maybe<Scalars['Int']>;
  proposal?: Maybe<PdfProposalType>;
};

export type DeleteVimrImagesMutation = {
  __typename?: 'DeleteVIMRImagesMutation';
  status?: Maybe<Scalars['Int']>;
  project?: Maybe<ProjectType>;
};

export type DemoArtistUpdateMutation = {
  __typename?: 'DemoArtistUpdateMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  result?: Maybe<ArtistSourcingType>;
};

/** GQL Type for the Favorite model. */
export type FavoriteType = {
  __typename?: 'FavoriteType';
  id: Scalars['ID'];
  esId: Scalars['String'];
  title: Scalars['String'];
  user?: Maybe<UserType>;
  createdAt: Scalars['DateTime'];
};

/** GQL Type for the Feedback model */
export type FeedbackType = {
  __typename?: 'FeedbackType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  user?: Maybe<UserType>;
  feedback: Scalars['String'];
  querystring: Scalars['String'];
  esIds: Scalars['String'];
  isBad: Scalars['Boolean'];
};

/** GQL Type for Floorplan model */
export type FloorPlanType = {
  __typename?: 'FloorPlanType';
  id: Scalars['ID'];
  locationItem?: Maybe<LocationItemType>;
  location?: Maybe<LocationType>;
  measureType: Scalars['String'];
  scaleLine: Scalars['String'];
  paperSize: Scalars['String'];
  scaleMeasurement: Scalars['String'];
  unitRatio: Scalars['Float'];
  measurementUnit?: Maybe<Scalars['String']>;
  shownMeasurementUnit?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  markedImage?: Maybe<Scalars['String']>;
  editsJson: Scalars['String'];
  imageFilename: Scalars['String'];
  isSensitive: Scalars['Boolean'];
  deleted: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  pdfImageSize: Scalars['String'];
  pdfActive: Scalars['Boolean'];
};

/** tags: floorplanClearEdits */
export type FloorplanClearEditsMutation = {
  __typename?: 'FloorplanClearEditsMutation';
  status?: Maybe<Scalars['Int']>;
  floorplan?: Maybe<FloorPlanType>;
  page?: Maybe<PdfPageType>;
};

export type FlowItemArtworkConfirmAvailabilityType = {
  __typename?: 'FlowItemArtworkConfirmAvailabilityType';
  id: Scalars['ID'];
  image: Scalars['String'];
  title: Scalars['String'];
  artist: Scalars['String'];
  remainingQuantity?: Maybe<Scalars['Int']>;
  destinationCountry?: Maybe<Scalars['String']>;
  destinationCity: Scalars['String'];
  destinationZip: Scalars['String'];
  requestedQuantity?: Maybe<Scalars['Int']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isNotReady?: Maybe<Scalars['Boolean']>;
  leadTime?: Maybe<Scalars['Int']>;
  shipsFromCountry?: Maybe<Scalars['String']>;
  shipsFromCity: Scalars['String'];
  shipsFromZip: Scalars['String'];
  packedWidth?: Maybe<Scalars['Float']>;
  packedHeight?: Maybe<Scalars['Float']>;
  packedDepth?: Maybe<Scalars['Float']>;
  packedWeight?: Maybe<Scalars['Float']>;
  shippingCost?: Maybe<Scalars['Float']>;
  shippedFramed?: Maybe<Scalars['Boolean']>;
  shippedRolled?: Maybe<Scalars['Boolean']>;
  remarks: Scalars['String'];
  history: Array<CurerntStateType>;
  framerUnit?: Maybe<Scalars['String']>;
  dateFinished?: Maybe<Scalars['String']>;
  destinationCountryName?: Maybe<Scalars['String']>;
  shipsFromCountryName?: Maybe<Scalars['String']>;
  sizeString?: Maybe<Scalars['String']>;
  framerSizeStr?: Maybe<Scalars['String']>;
  framerWeightStr?: Maybe<Scalars['String']>;
  price: Scalars['String'];
};

export type FlowItemArtworkFramerRfqType = {
  __typename?: 'FlowItemArtworkFramerRFQType';
  id: Scalars['ID'];
  image: Scalars['String'];
  title: Scalars['String'];
  destinationCity: Scalars['String'];
  destinationZip: Scalars['String'];
  framerArtworkMaterial: Scalars['String'];
  framerUnit?: Maybe<Scalars['String']>;
  framerWidth?: Maybe<Scalars['Float']>;
  framerHeight?: Maybe<Scalars['Float']>;
  framerThickness?: Maybe<Scalars['Float']>;
  framerWeight?: Maybe<Scalars['Float']>;
  framerQuantity?: Maybe<Scalars['Int']>;
  framerNotes: Scalars['String'];
  framerStretchingRequired: Scalars['Boolean'];
  framerFramingRequired: Scalars['Boolean'];
  framerDeliveryRequired: Scalars['Boolean'];
  framerInstallationRequired: Scalars['Boolean'];
  framerStaffUpdatedAt?: Maybe<Scalars['DateTime']>;
  framerLocksComment: Scalars['String'];
  history: Array<CurerntStateType>;
  dateFinished?: Maybe<Scalars['String']>;
  destinationCountry?: Maybe<Scalars['String']>;
  destinationCountryName?: Maybe<Scalars['String']>;
  shipsFromCountry?: Maybe<Scalars['String']>;
  shipsFromCountryName?: Maybe<Scalars['String']>;
  sizeString?: Maybe<Scalars['String']>;
  framerSizeStr?: Maybe<Scalars['String']>;
  framerWeightStr?: Maybe<Scalars['String']>;
  framingSpecsQuotations: Array<FramingSpecsQuotationType>;
  stretchingQuotation?: Maybe<StretchingQuotationType>;
};


export type FlowItemArtworkFramerRfqTypeFramingSpecsQuotationsArgs = {
  framerHashId?: Maybe<Scalars['String']>;
};


export type FlowItemArtworkFramerRfqTypeStretchingQuotationArgs = {
  framerHashId?: Maybe<Scalars['String']>;
};

/** GQL Type for the FlowItemArtwork model. */
export type FlowItemArtworkType = {
  __typename?: 'FlowItemArtworkType';
  workflow: Scalars['String'];
  currentStates?: Maybe<Array<Maybe<CurerntStateType>>>;
  itemGroup: Scalars['String'];
  id: Scalars['ID'];
  esId: Scalars['String'];
  bucketItem?: Maybe<BucketItemType>;
  locationItem?: Maybe<LocationItemType>;
  image: Scalars['String'];
  title: Scalars['String'];
  artist: Scalars['String'];
  remainingQuantity?: Maybe<Scalars['Int']>;
  selectedSize: Scalars['String'];
  artlingProductId?: Maybe<Scalars['Int']>;
  sellerEmail: Scalars['String'];
  destinationCountry?: Maybe<Scalars['String']>;
  destinationCity: Scalars['String'];
  destinationZip: Scalars['String'];
  requestedQuantity?: Maybe<Scalars['Int']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isNotReady?: Maybe<Scalars['Boolean']>;
  leadTime?: Maybe<Scalars['Int']>;
  shipsFromCountry?: Maybe<Scalars['String']>;
  shipsFromCity: Scalars['String'];
  shipsFromZip: Scalars['String'];
  packedWidth?: Maybe<Scalars['Float']>;
  packedHeight?: Maybe<Scalars['Float']>;
  packedDepth?: Maybe<Scalars['Float']>;
  packedWeight?: Maybe<Scalars['Float']>;
  isShippingCostUnknown?: Maybe<Scalars['Boolean']>;
  shippingCost?: Maybe<Scalars['Float']>;
  shippedFramed?: Maybe<Scalars['Boolean']>;
  shippedRolled?: Maybe<Scalars['Boolean']>;
  remarks: Scalars['String'];
  framerArtworkMaterial: Scalars['String'];
  framerUnit?: Maybe<Scalars['String']>;
  framerWidth?: Maybe<Scalars['Float']>;
  framerHeight?: Maybe<Scalars['Float']>;
  framerThickness?: Maybe<Scalars['Float']>;
  framerWeight?: Maybe<Scalars['Float']>;
  framerQuantity?: Maybe<Scalars['Int']>;
  framerNotes: Scalars['String'];
  framerStretchingRequired: Scalars['Boolean'];
  framerFramingRequired: Scalars['Boolean'];
  framerDeliveryRequired: Scalars['Boolean'];
  framerInstallationRequired: Scalars['Boolean'];
  framerStaffUpdatedAt?: Maybe<Scalars['DateTime']>;
  framerLocksComment: Scalars['String'];
  framingSpecsQuotations?: Maybe<Array<Maybe<FramingSpecsQuotationType>>>;
  stretchingQuotations?: Maybe<Array<Maybe<StretchingQuotationType>>>;
  framerQuotations?: Maybe<Array<Maybe<FramerGlobalQuotationType>>>;
  history: Array<CurerntStateType>;
  dateFinished?: Maybe<Scalars['String']>;
  destinationCountryName?: Maybe<Scalars['String']>;
  shipsFromCountryName?: Maybe<Scalars['String']>;
  sizeString?: Maybe<Scalars['String']>;
  framerSizeStr?: Maybe<Scalars['String']>;
  framerWeightStr?: Maybe<Scalars['String']>;
};

export type FlowItemCommissionedWorkConfirmAvailabilityType = {
  __typename?: 'FlowItemCommissionedWorkConfirmAvailabilityType';
  id: Scalars['ID'];
  title: Scalars['String'];
  commission?: Maybe<Scalars['Float']>;
  artworkDescription: Scalars['String'];
  estimatedTimeline: Scalars['String'];
  additionalQuestions: Scalars['String'];
  budget?: Maybe<Scalars['Int']>;
  budgetCcy: Scalars['String'];
  wallWidth?: Maybe<Scalars['Float']>;
  wallHeight?: Maybe<Scalars['Float']>;
  itemWidth?: Maybe<Scalars['Float']>;
  itemHeight?: Maybe<Scalars['Float']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  cost?: Maybe<Scalars['Float']>;
  leadTime?: Maybe<Scalars['Int']>;
  hasSketchFee?: Maybe<Scalars['Boolean']>;
  sketchFee?: Maybe<Scalars['Float']>;
  portfolio1?: Maybe<Scalars['String']>;
  portfolio2?: Maybe<Scalars['String']>;
  portfolio3?: Maybe<Scalars['String']>;
  remarks: Scalars['String'];
  files: Array<FlowItemCommissionedWorkImageType>;
  artistName?: Maybe<Scalars['String']>;
  dateFinished?: Maybe<Scalars['String']>;
  history: Array<CurerntStateType>;
  wallSizeStr: Scalars['String'];
  itemSizeStr: Scalars['String'];
  artistConfirmAvailabilityUrl?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum FlowItemCommissionedWorkImageImageType {
  /** check_availability */
  CheckAvailability = 'CHECK_AVAILABILITY'
}

export type FlowItemCommissionedWorkImageType = {
  __typename?: 'FlowItemCommissionedWorkImageType';
  id: Scalars['ID'];
  flowItem?: Maybe<FlowItemCommissionedWorkConfirmAvailabilityType>;
  imageType: FlowItemCommissionedWorkImageImageType;
  imageKey?: Maybe<Scalars['String']>;
  imageKeyFilename: Scalars['String'];
  deleted: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** An enumeration. */
export enum FlowItemCommissionedWorkLeadTimeUnit {
  /** days */
  Days = 'DAYS',
  /** months */
  Months = 'MONTHS'
}

/** GQL Type for the FlowItemCommissionedWork model. */
export type FlowItemCommissionedWorkType = {
  __typename?: 'FlowItemCommissionedWorkType';
  id: Scalars['ID'];
  workflow: Scalars['String'];
  currentStates?: Maybe<Array<Maybe<CurerntStateType>>>;
  itemGroup: Scalars['String'];
  esId: Scalars['String'];
  bucketItem?: Maybe<BucketItemType>;
  title: Scalars['String'];
  image: Scalars['String'];
  commission?: Maybe<Scalars['Float']>;
  locationItem?: Maybe<LocationItemType>;
  artist?: Maybe<ArtistSourcingType>;
  artworkDescription: Scalars['String'];
  estimatedTimeline: Scalars['String'];
  additionalQuestions: Scalars['String'];
  budget?: Maybe<Scalars['Int']>;
  budgetCcy: Scalars['String'];
  wallWidth?: Maybe<Scalars['Float']>;
  wallHeight?: Maybe<Scalars['Float']>;
  itemWidth?: Maybe<Scalars['Float']>;
  itemHeight?: Maybe<Scalars['Float']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  cost?: Maybe<Scalars['Float']>;
  leadTime?: Maybe<Scalars['Int']>;
  leadTimeUnit: FlowItemCommissionedWorkLeadTimeUnit;
  hasSketchFee?: Maybe<Scalars['Boolean']>;
  sketchFee?: Maybe<Scalars['Float']>;
  portfolio1?: Maybe<Scalars['String']>;
  portfolio2?: Maybe<Scalars['String']>;
  portfolio3?: Maybe<Scalars['String']>;
  remarks: Scalars['String'];
  files: Array<FlowItemCommissionedWorkImageType>;
  artistName?: Maybe<Scalars['String']>;
  dateFinished?: Maybe<Scalars['String']>;
  history: Array<CurerntStateType>;
  wallSizeStr: Scalars['String'];
  itemSizeStr: Scalars['String'];
  artistConfirmAvailabilityUrl?: Maybe<Scalars['String']>;
};

export type ForgotPasswordMutation = {
  __typename?: 'ForgotPasswordMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
};

export type FrameMaterialType = {
  __typename?: 'FrameMaterialType';
  id: Scalars['Int'];
  name: Scalars['String'];
  slug: Scalars['String'];
  color: Scalars['String'];
  materialImage?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum FramerCountry {
  /** Afghanistan */
  Af = 'AF',
  /** Åland Islands */
  Ax = 'AX',
  /** Albania */
  Al = 'AL',
  /** Algeria */
  Dz = 'DZ',
  /** American Samoa */
  As = 'AS',
  /** Andorra */
  Ad = 'AD',
  /** Angola */
  Ao = 'AO',
  /** Anguilla */
  Ai = 'AI',
  /** Antarctica */
  Aq = 'AQ',
  /** Antigua and Barbuda */
  Ag = 'AG',
  /** Argentina */
  Ar = 'AR',
  /** Armenia */
  Am = 'AM',
  /** Aruba */
  Aw = 'AW',
  /** Australia */
  Au = 'AU',
  /** Austria */
  At = 'AT',
  /** Azerbaijan */
  Az = 'AZ',
  /** Bahamas */
  Bs = 'BS',
  /** Bahrain */
  Bh = 'BH',
  /** Bangladesh */
  Bd = 'BD',
  /** Barbados */
  Bb = 'BB',
  /** Belarus */
  By = 'BY',
  /** Belgium */
  Be = 'BE',
  /** Belize */
  Bz = 'BZ',
  /** Benin */
  Bj = 'BJ',
  /** Bermuda */
  Bm = 'BM',
  /** Bhutan */
  Bt = 'BT',
  /** Bolivia */
  Bo = 'BO',
  /** Bonaire, Sint Eustatius and Saba */
  Bq = 'BQ',
  /** Bosnia and Herzegovina */
  Ba = 'BA',
  /** Botswana */
  Bw = 'BW',
  /** Bouvet Island */
  Bv = 'BV',
  /** Brazil */
  Br = 'BR',
  /** British Indian Ocean Territory */
  Io = 'IO',
  /** Brunei */
  Bn = 'BN',
  /** Bulgaria */
  Bg = 'BG',
  /** Burkina Faso */
  Bf = 'BF',
  /** Burundi */
  Bi = 'BI',
  /** Cabo Verde */
  Cv = 'CV',
  /** Cambodia */
  Kh = 'KH',
  /** Cameroon */
  Cm = 'CM',
  /** Canada */
  Ca = 'CA',
  /** Cayman Islands */
  Ky = 'KY',
  /** Central African Republic */
  Cf = 'CF',
  /** Chad */
  Td = 'TD',
  /** Chile */
  Cl = 'CL',
  /** China */
  Cn = 'CN',
  /** Christmas Island */
  Cx = 'CX',
  /** Cocos (Keeling) Islands */
  Cc = 'CC',
  /** Colombia */
  Co = 'CO',
  /** Comoros */
  Km = 'KM',
  /** Congo */
  Cg = 'CG',
  /** Congo (the Democratic Republic of the) */
  Cd = 'CD',
  /** Cook Islands */
  Ck = 'CK',
  /** Costa Rica */
  Cr = 'CR',
  /** Côte d'Ivoire */
  Ci = 'CI',
  /** Croatia */
  Hr = 'HR',
  /** Cuba */
  Cu = 'CU',
  /** Curaçao */
  Cw = 'CW',
  /** Cyprus */
  Cy = 'CY',
  /** Czechia */
  Cz = 'CZ',
  /** Denmark */
  Dk = 'DK',
  /** Djibouti */
  Dj = 'DJ',
  /** Dominica */
  Dm = 'DM',
  /** Dominican Republic */
  Do = 'DO',
  /** Ecuador */
  Ec = 'EC',
  /** Egypt */
  Eg = 'EG',
  /** El Salvador */
  Sv = 'SV',
  /** Equatorial Guinea */
  Gq = 'GQ',
  /** Eritrea */
  Er = 'ER',
  /** Estonia */
  Ee = 'EE',
  /** Eswatini */
  Sz = 'SZ',
  /** Ethiopia */
  Et = 'ET',
  /** Falkland Islands  [Malvinas] */
  Fk = 'FK',
  /** Faroe Islands */
  Fo = 'FO',
  /** Fiji */
  Fj = 'FJ',
  /** Finland */
  Fi = 'FI',
  /** France */
  Fr = 'FR',
  /** French Guiana */
  Gf = 'GF',
  /** French Polynesia */
  Pf = 'PF',
  /** French Southern Territories */
  Tf = 'TF',
  /** Gabon */
  Ga = 'GA',
  /** Gambia */
  Gm = 'GM',
  /** Georgia */
  Ge = 'GE',
  /** Germany */
  De = 'DE',
  /** Ghana */
  Gh = 'GH',
  /** Gibraltar */
  Gi = 'GI',
  /** Greece */
  Gr = 'GR',
  /** Greenland */
  Gl = 'GL',
  /** Grenada */
  Gd = 'GD',
  /** Guadeloupe */
  Gp = 'GP',
  /** Guam */
  Gu = 'GU',
  /** Guatemala */
  Gt = 'GT',
  /** Guernsey */
  Gg = 'GG',
  /** Guinea */
  Gn = 'GN',
  /** Guinea-Bissau */
  Gw = 'GW',
  /** Guyana */
  Gy = 'GY',
  /** Haiti */
  Ht = 'HT',
  /** Heard Island and McDonald Islands */
  Hm = 'HM',
  /** Holy See */
  Va = 'VA',
  /** Honduras */
  Hn = 'HN',
  /** Hong Kong */
  Hk = 'HK',
  /** Hungary */
  Hu = 'HU',
  /** Iceland */
  Is = 'IS',
  /** India */
  In = 'IN',
  /** Indonesia */
  Id = 'ID',
  /** Iran */
  Ir = 'IR',
  /** Iraq */
  Iq = 'IQ',
  /** Ireland */
  Ie = 'IE',
  /** Isle of Man */
  Im = 'IM',
  /** Israel */
  Il = 'IL',
  /** Italy */
  It = 'IT',
  /** Jamaica */
  Jm = 'JM',
  /** Japan */
  Jp = 'JP',
  /** Jersey */
  Je = 'JE',
  /** Jordan */
  Jo = 'JO',
  /** Kazakhstan */
  Kz = 'KZ',
  /** Kenya */
  Ke = 'KE',
  /** Kiribati */
  Ki = 'KI',
  /** Kuwait */
  Kw = 'KW',
  /** Kyrgyzstan */
  Kg = 'KG',
  /** Laos */
  La = 'LA',
  /** Latvia */
  Lv = 'LV',
  /** Lebanon */
  Lb = 'LB',
  /** Lesotho */
  Ls = 'LS',
  /** Liberia */
  Lr = 'LR',
  /** Libya */
  Ly = 'LY',
  /** Liechtenstein */
  Li = 'LI',
  /** Lithuania */
  Lt = 'LT',
  /** Luxembourg */
  Lu = 'LU',
  /** Macao */
  Mo = 'MO',
  /** Madagascar */
  Mg = 'MG',
  /** Malawi */
  Mw = 'MW',
  /** Malaysia */
  My = 'MY',
  /** Maldives */
  Mv = 'MV',
  /** Mali */
  Ml = 'ML',
  /** Malta */
  Mt = 'MT',
  /** Marshall Islands */
  Mh = 'MH',
  /** Martinique */
  Mq = 'MQ',
  /** Mauritania */
  Mr = 'MR',
  /** Mauritius */
  Mu = 'MU',
  /** Mayotte */
  Yt = 'YT',
  /** Mexico */
  Mx = 'MX',
  /** Micronesia (Federated States of) */
  Fm = 'FM',
  /** Moldova */
  Md = 'MD',
  /** Monaco */
  Mc = 'MC',
  /** Mongolia */
  Mn = 'MN',
  /** Montenegro */
  Me = 'ME',
  /** Montserrat */
  Ms = 'MS',
  /** Morocco */
  Ma = 'MA',
  /** Mozambique */
  Mz = 'MZ',
  /** Myanmar */
  Mm = 'MM',
  /** Namibia */
  Na = 'NA',
  /** Nauru */
  Nr = 'NR',
  /** Nepal */
  Np = 'NP',
  /** Netherlands */
  Nl = 'NL',
  /** New Caledonia */
  Nc = 'NC',
  /** New Zealand */
  Nz = 'NZ',
  /** Nicaragua */
  Ni = 'NI',
  /** Niger */
  Ne = 'NE',
  /** Nigeria */
  Ng = 'NG',
  /** Niue */
  Nu = 'NU',
  /** Norfolk Island */
  Nf = 'NF',
  /** North Korea */
  Kp = 'KP',
  /** North Macedonia */
  Mk = 'MK',
  /** Northern Mariana Islands */
  Mp = 'MP',
  /** Norway */
  No = 'NO',
  /** Oman */
  Om = 'OM',
  /** Pakistan */
  Pk = 'PK',
  /** Palau */
  Pw = 'PW',
  /** Palestine, State of */
  Ps = 'PS',
  /** Panama */
  Pa = 'PA',
  /** Papua New Guinea */
  Pg = 'PG',
  /** Paraguay */
  Py = 'PY',
  /** Peru */
  Pe = 'PE',
  /** Philippines */
  Ph = 'PH',
  /** Pitcairn */
  Pn = 'PN',
  /** Poland */
  Pl = 'PL',
  /** Portugal */
  Pt = 'PT',
  /** Puerto Rico */
  Pr = 'PR',
  /** Qatar */
  Qa = 'QA',
  /** Réunion */
  Re = 'RE',
  /** Romania */
  Ro = 'RO',
  /** Russia */
  Ru = 'RU',
  /** Rwanda */
  Rw = 'RW',
  /** Saint Barthélemy */
  Bl = 'BL',
  /** Saint Helena, Ascension and Tristan da Cunha */
  Sh = 'SH',
  /** Saint Kitts and Nevis */
  Kn = 'KN',
  /** Saint Lucia */
  Lc = 'LC',
  /** Saint Martin (French part) */
  Mf = 'MF',
  /** Saint Pierre and Miquelon */
  Pm = 'PM',
  /** Saint Vincent and the Grenadines */
  Vc = 'VC',
  /** Samoa */
  Ws = 'WS',
  /** San Marino */
  Sm = 'SM',
  /** Sao Tome and Principe */
  St = 'ST',
  /** Saudi Arabia */
  Sa = 'SA',
  /** Senegal */
  Sn = 'SN',
  /** Serbia */
  Rs = 'RS',
  /** Seychelles */
  Sc = 'SC',
  /** Sierra Leone */
  Sl = 'SL',
  /** Singapore */
  Sg = 'SG',
  /** Sint Maarten (Dutch part) */
  Sx = 'SX',
  /** Slovakia */
  Sk = 'SK',
  /** Slovenia */
  Si = 'SI',
  /** Solomon Islands */
  Sb = 'SB',
  /** Somalia */
  So = 'SO',
  /** South Africa */
  Za = 'ZA',
  /** South Georgia and the South Sandwich Islands */
  Gs = 'GS',
  /** South Korea */
  Kr = 'KR',
  /** South Sudan */
  Ss = 'SS',
  /** Spain */
  Es = 'ES',
  /** Sri Lanka */
  Lk = 'LK',
  /** Sudan */
  Sd = 'SD',
  /** Suriname */
  Sr = 'SR',
  /** Svalbard and Jan Mayen */
  Sj = 'SJ',
  /** Sweden */
  Se = 'SE',
  /** Switzerland */
  Ch = 'CH',
  /** Syria */
  Sy = 'SY',
  /** Taiwan */
  Tw = 'TW',
  /** Tajikistan */
  Tj = 'TJ',
  /** Tanzania */
  Tz = 'TZ',
  /** Thailand */
  Th = 'TH',
  /** Timor-Leste */
  Tl = 'TL',
  /** Togo */
  Tg = 'TG',
  /** Tokelau */
  Tk = 'TK',
  /** Tonga */
  To = 'TO',
  /** Trinidad and Tobago */
  Tt = 'TT',
  /** Tunisia */
  Tn = 'TN',
  /** Turkey */
  Tr = 'TR',
  /** Turkmenistan */
  Tm = 'TM',
  /** Turks and Caicos Islands */
  Tc = 'TC',
  /** Tuvalu */
  Tv = 'TV',
  /** Uganda */
  Ug = 'UG',
  /** Ukraine */
  Ua = 'UA',
  /** United Arab Emirates */
  Ae = 'AE',
  /** United Kingdom */
  Gb = 'GB',
  /** United States Minor Outlying Islands */
  Um = 'UM',
  /** United States of America */
  Us = 'US',
  /** Uruguay */
  Uy = 'UY',
  /** Uzbekistan */
  Uz = 'UZ',
  /** Vanuatu */
  Vu = 'VU',
  /** Venezuela */
  Ve = 'VE',
  /** Vietnam */
  Vn = 'VN',
  /** Virgin Islands (British) */
  Vg = 'VG',
  /** Virgin Islands (U.S.) */
  Vi = 'VI',
  /** Wallis and Futuna */
  Wf = 'WF',
  /** Western Sahara */
  Eh = 'EH',
  /** Yemen */
  Ye = 'YE',
  /** Zambia */
  Zm = 'ZM',
  /** Zimbabwe */
  Zw = 'ZW'
}

/** An enumeration. */
export enum FramerGlobalQuotationCurrency {
  /** USD */
  Usd = 'USD',
  /** SGD */
  Sgd = 'SGD',
  /** RMB */
  Rmb = 'RMB'
}

/** GQL Type for the FramerGlobalQuotation model. */
export type FramerGlobalQuotationType = {
  __typename?: 'FramerGlobalQuotationType';
  id: Scalars['ID'];
  uniqueUrl: Scalars['String'];
  isAvailable?: Maybe<Scalars['Boolean']>;
  isAvailableStretching?: Maybe<Scalars['Boolean']>;
  isAvailableFraming?: Maybe<Scalars['Boolean']>;
  isAvailableDelivery?: Maybe<Scalars['Boolean']>;
  isAvailableInstallation?: Maybe<Scalars['Boolean']>;
  framer?: Maybe<FramerType>;
  flowItems: Array<FlowItemArtworkFramerRfqType>;
  currency?: Maybe<FramerGlobalQuotationCurrency>;
  unit?: Maybe<Scalars['String']>;
  installationCost?: Maybe<Scalars['Float']>;
  installationAdditionalCharge: Scalars['Boolean'];
  installationAdditionalCost?: Maybe<Scalars['Float']>;
  deliveryCost?: Maybe<Scalars['Float']>;
  installationComments: Scalars['String'];
  deliveryComments: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  dateFinished?: Maybe<Scalars['String']>;
  framingSpecsQuotations?: Maybe<Array<Maybe<FramingSpecsQuotationType>>>;
  stretchingQuotations?: Maybe<Array<Maybe<StretchingQuotationType>>>;
};

/**
 * Deletes selected framer spec
 *
 * tags: wfNwFramerRfqDeleteSpec, WFNormalWorksFramerFramingSpecsForm
 */
export type FramerRfqDeleteSpecMutation = {
  __typename?: 'FramerRFQDeleteSpecMutation';
  status?: Maybe<Scalars['Int']>;
  framerGlobalQuotation?: Maybe<FramerGlobalQuotationType>;
};

/**
 * Framer saves stretching info for a given flow item.
 *
 * tags: wfNwFramerRfqSaveStretchingInfo,
 *       WFNormalWorksFramerStretchingInfoForm
 */
export type FramerRfqSaveStretchingInfoMutation = {
  __typename?: 'FramerRFQSaveStretchingInfoMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  framerGlobalQuotation?: Maybe<FramerGlobalQuotationType>;
};

/**
 * Framer does the final submission of all RFQ data.
 *
 * tags: wf_nw_framer_rfq_submit_mutation, wfNwFramerRfqSubmitMutation,
 *   FramerWFNormalWorksQuotationFormGroup
 */
export type FramerRfqSubmitMutation = {
  __typename?: 'FramerRFQSubmitMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  framerGlobalQuotation?: Maybe<FramerGlobalQuotationType>;
};

/**
 * Framer submits what services they can provide.
 *
 * tags: WFNormalWorksFramerGlobalInfoForm, wfNwFramerRfqUpdateGlobal
 */
export type FramerRfqUpdateGlobalMutation = {
  __typename?: 'FramerRFQUpdateGlobalMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  framerGlobalQuotation?: Maybe<FramerGlobalQuotationType>;
};

/**
 * Creates new empty framer spec to be added to the framer global quotation
 *
 * tags: wfNwFramerRfqCreateNewSpecs, WFNormalWorksFramerFramingSpecsForm
 */
export type FramerRfQsCreateNewSpecsMutation = {
  __typename?: 'FramerRFQsCreateNewSpecsMutation';
  status?: Maybe<Scalars['Int']>;
  framerGlobalQuotation?: Maybe<FramerGlobalQuotationType>;
};

/**
 * Framer creates a new spec or updates an existing one.
 *
 * tags: wfNwFramerRfqCreateOrUpdateSpecs, WFNormalWorksFramerFramingSpecsForm
 */
export type FramerRfQsCreateOrUpdateSpecsMutation = {
  __typename?: 'FramerRFQsCreateOrUpdateSpecsMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  framerGlobalQuotation?: Maybe<FramerGlobalQuotationType>;
};

/**
 * Framer submits delivery information if available.
 *
 * tags: WFNormalWorksFramerDeliveryInfoForm, wfNwFramerRfqsSaveDeliveryInfo
 */
export type FramerRfQsSaveDeliveryInfoMutation = {
  __typename?: 'FramerRFQsSaveDeliveryInfoMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  framerGlobalQuotation?: Maybe<FramerGlobalQuotationType>;
};

/**
 * Framer submits installation information if available.
 *
 * tags: WFNormalWorksFramerInstallationInfoForm,
 *   wfNwFramerRfqSaveInstallationInfo
 */
export type FramerRfQsSaveInstallationInfoMutation = {
  __typename?: 'FramerRFQsSaveInstallationInfoMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  framerGlobalQuotation?: Maybe<FramerGlobalQuotationType>;
};

/** GQL type for the Framer model. */
export type FramerType = {
  __typename?: 'FramerType';
  id: Scalars['ID'];
  hashId: Scalars['String'];
  name: Scalars['String'];
  country: FramerCountry;
  primaryEmail: Scalars['String'];
  secondaryEmails: Scalars['String'];
  providesStretching: Scalars['Boolean'];
  providesFraming: Scalars['Boolean'];
  providesInstallation: Scalars['Boolean'];
  adminNotes: Scalars['String'];
  createdBy?: Maybe<UserType>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  projectSet?: Maybe<Array<Maybe<ProjectType>>>;
  framerglobalquotationSet?: Maybe<Array<Maybe<FramerGlobalQuotationType>>>;
};

/** GQL Type for the FramingSpecsQuotationImage model. */
export type FramingSpecsQuotationImageType = {
  __typename?: 'FramingSpecsQuotationImageType';
  id: Scalars['ID'];
  framingSpecsQuotation: FramingSpecsQuotationType;
  imageKey?: Maybe<Scalars['String']>;
  imageKeyFilename: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

/** GQL Type for the FramingSpecsQuotation model. */
export type FramingSpecsQuotationType = {
  __typename?: 'FramingSpecsQuotationType';
  id: Scalars['ID'];
  framerQuotation?: Maybe<FramerGlobalQuotationType>;
  flowItem: FlowItemArtworkFramerRfqType;
  frameWidth?: Maybe<Scalars['Float']>;
  frameHeight?: Maybe<Scalars['Float']>;
  frameThickness?: Maybe<Scalars['Float']>;
  frameMaterial: Scalars['String'];
  frameColour: Scalars['String'];
  matMaterial: Scalars['String'];
  matThickness?: Maybe<Scalars['Float']>;
  glazingMaterial: Scalars['String'];
  cost?: Maybe<Scalars['Float']>;
  leadTimeUnit?: Maybe<Scalars['String']>;
  leadTime?: Maybe<Scalars['Int']>;
  comments: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  images?: Maybe<Array<Maybe<FramingSpecsQuotationImageType>>>;
};

/** An enumeration. */
export enum GalleryCountries {
  /** Afghanistan */
  Af = 'AF',
  /** Åland Islands */
  Ax = 'AX',
  /** Albania */
  Al = 'AL',
  /** Algeria */
  Dz = 'DZ',
  /** American Samoa */
  As = 'AS',
  /** Andorra */
  Ad = 'AD',
  /** Angola */
  Ao = 'AO',
  /** Anguilla */
  Ai = 'AI',
  /** Antarctica */
  Aq = 'AQ',
  /** Antigua and Barbuda */
  Ag = 'AG',
  /** Argentina */
  Ar = 'AR',
  /** Armenia */
  Am = 'AM',
  /** Aruba */
  Aw = 'AW',
  /** Australia */
  Au = 'AU',
  /** Austria */
  At = 'AT',
  /** Azerbaijan */
  Az = 'AZ',
  /** Bahamas */
  Bs = 'BS',
  /** Bahrain */
  Bh = 'BH',
  /** Bangladesh */
  Bd = 'BD',
  /** Barbados */
  Bb = 'BB',
  /** Belarus */
  By = 'BY',
  /** Belgium */
  Be = 'BE',
  /** Belize */
  Bz = 'BZ',
  /** Benin */
  Bj = 'BJ',
  /** Bermuda */
  Bm = 'BM',
  /** Bhutan */
  Bt = 'BT',
  /** Bolivia */
  Bo = 'BO',
  /** Bonaire, Sint Eustatius and Saba */
  Bq = 'BQ',
  /** Bosnia and Herzegovina */
  Ba = 'BA',
  /** Botswana */
  Bw = 'BW',
  /** Bouvet Island */
  Bv = 'BV',
  /** Brazil */
  Br = 'BR',
  /** British Indian Ocean Territory */
  Io = 'IO',
  /** Brunei */
  Bn = 'BN',
  /** Bulgaria */
  Bg = 'BG',
  /** Burkina Faso */
  Bf = 'BF',
  /** Burundi */
  Bi = 'BI',
  /** Cabo Verde */
  Cv = 'CV',
  /** Cambodia */
  Kh = 'KH',
  /** Cameroon */
  Cm = 'CM',
  /** Canada */
  Ca = 'CA',
  /** Cayman Islands */
  Ky = 'KY',
  /** Central African Republic */
  Cf = 'CF',
  /** Chad */
  Td = 'TD',
  /** Chile */
  Cl = 'CL',
  /** China */
  Cn = 'CN',
  /** Christmas Island */
  Cx = 'CX',
  /** Cocos (Keeling) Islands */
  Cc = 'CC',
  /** Colombia */
  Co = 'CO',
  /** Comoros */
  Km = 'KM',
  /** Congo */
  Cg = 'CG',
  /** Congo (the Democratic Republic of the) */
  Cd = 'CD',
  /** Cook Islands */
  Ck = 'CK',
  /** Costa Rica */
  Cr = 'CR',
  /** Côte d'Ivoire */
  Ci = 'CI',
  /** Croatia */
  Hr = 'HR',
  /** Cuba */
  Cu = 'CU',
  /** Curaçao */
  Cw = 'CW',
  /** Cyprus */
  Cy = 'CY',
  /** Czechia */
  Cz = 'CZ',
  /** Denmark */
  Dk = 'DK',
  /** Djibouti */
  Dj = 'DJ',
  /** Dominica */
  Dm = 'DM',
  /** Dominican Republic */
  Do = 'DO',
  /** Ecuador */
  Ec = 'EC',
  /** Egypt */
  Eg = 'EG',
  /** El Salvador */
  Sv = 'SV',
  /** Equatorial Guinea */
  Gq = 'GQ',
  /** Eritrea */
  Er = 'ER',
  /** Estonia */
  Ee = 'EE',
  /** Eswatini */
  Sz = 'SZ',
  /** Ethiopia */
  Et = 'ET',
  /** Falkland Islands  [Malvinas] */
  Fk = 'FK',
  /** Faroe Islands */
  Fo = 'FO',
  /** Fiji */
  Fj = 'FJ',
  /** Finland */
  Fi = 'FI',
  /** France */
  Fr = 'FR',
  /** French Guiana */
  Gf = 'GF',
  /** French Polynesia */
  Pf = 'PF',
  /** French Southern Territories */
  Tf = 'TF',
  /** Gabon */
  Ga = 'GA',
  /** Gambia */
  Gm = 'GM',
  /** Georgia */
  Ge = 'GE',
  /** Germany */
  De = 'DE',
  /** Ghana */
  Gh = 'GH',
  /** Gibraltar */
  Gi = 'GI',
  /** Greece */
  Gr = 'GR',
  /** Greenland */
  Gl = 'GL',
  /** Grenada */
  Gd = 'GD',
  /** Guadeloupe */
  Gp = 'GP',
  /** Guam */
  Gu = 'GU',
  /** Guatemala */
  Gt = 'GT',
  /** Guernsey */
  Gg = 'GG',
  /** Guinea */
  Gn = 'GN',
  /** Guinea-Bissau */
  Gw = 'GW',
  /** Guyana */
  Gy = 'GY',
  /** Haiti */
  Ht = 'HT',
  /** Heard Island and McDonald Islands */
  Hm = 'HM',
  /** Holy See */
  Va = 'VA',
  /** Honduras */
  Hn = 'HN',
  /** Hong Kong */
  Hk = 'HK',
  /** Hungary */
  Hu = 'HU',
  /** Iceland */
  Is = 'IS',
  /** India */
  In = 'IN',
  /** Indonesia */
  Id = 'ID',
  /** Iran */
  Ir = 'IR',
  /** Iraq */
  Iq = 'IQ',
  /** Ireland */
  Ie = 'IE',
  /** Isle of Man */
  Im = 'IM',
  /** Israel */
  Il = 'IL',
  /** Italy */
  It = 'IT',
  /** Jamaica */
  Jm = 'JM',
  /** Japan */
  Jp = 'JP',
  /** Jersey */
  Je = 'JE',
  /** Jordan */
  Jo = 'JO',
  /** Kazakhstan */
  Kz = 'KZ',
  /** Kenya */
  Ke = 'KE',
  /** Kiribati */
  Ki = 'KI',
  /** Kuwait */
  Kw = 'KW',
  /** Kyrgyzstan */
  Kg = 'KG',
  /** Laos */
  La = 'LA',
  /** Latvia */
  Lv = 'LV',
  /** Lebanon */
  Lb = 'LB',
  /** Lesotho */
  Ls = 'LS',
  /** Liberia */
  Lr = 'LR',
  /** Libya */
  Ly = 'LY',
  /** Liechtenstein */
  Li = 'LI',
  /** Lithuania */
  Lt = 'LT',
  /** Luxembourg */
  Lu = 'LU',
  /** Macao */
  Mo = 'MO',
  /** Madagascar */
  Mg = 'MG',
  /** Malawi */
  Mw = 'MW',
  /** Malaysia */
  My = 'MY',
  /** Maldives */
  Mv = 'MV',
  /** Mali */
  Ml = 'ML',
  /** Malta */
  Mt = 'MT',
  /** Marshall Islands */
  Mh = 'MH',
  /** Martinique */
  Mq = 'MQ',
  /** Mauritania */
  Mr = 'MR',
  /** Mauritius */
  Mu = 'MU',
  /** Mayotte */
  Yt = 'YT',
  /** Mexico */
  Mx = 'MX',
  /** Micronesia (Federated States of) */
  Fm = 'FM',
  /** Moldova */
  Md = 'MD',
  /** Monaco */
  Mc = 'MC',
  /** Mongolia */
  Mn = 'MN',
  /** Montenegro */
  Me = 'ME',
  /** Montserrat */
  Ms = 'MS',
  /** Morocco */
  Ma = 'MA',
  /** Mozambique */
  Mz = 'MZ',
  /** Myanmar */
  Mm = 'MM',
  /** Namibia */
  Na = 'NA',
  /** Nauru */
  Nr = 'NR',
  /** Nepal */
  Np = 'NP',
  /** Netherlands */
  Nl = 'NL',
  /** New Caledonia */
  Nc = 'NC',
  /** New Zealand */
  Nz = 'NZ',
  /** Nicaragua */
  Ni = 'NI',
  /** Niger */
  Ne = 'NE',
  /** Nigeria */
  Ng = 'NG',
  /** Niue */
  Nu = 'NU',
  /** Norfolk Island */
  Nf = 'NF',
  /** North Korea */
  Kp = 'KP',
  /** North Macedonia */
  Mk = 'MK',
  /** Northern Mariana Islands */
  Mp = 'MP',
  /** Norway */
  No = 'NO',
  /** Oman */
  Om = 'OM',
  /** Pakistan */
  Pk = 'PK',
  /** Palau */
  Pw = 'PW',
  /** Palestine, State of */
  Ps = 'PS',
  /** Panama */
  Pa = 'PA',
  /** Papua New Guinea */
  Pg = 'PG',
  /** Paraguay */
  Py = 'PY',
  /** Peru */
  Pe = 'PE',
  /** Philippines */
  Ph = 'PH',
  /** Pitcairn */
  Pn = 'PN',
  /** Poland */
  Pl = 'PL',
  /** Portugal */
  Pt = 'PT',
  /** Puerto Rico */
  Pr = 'PR',
  /** Qatar */
  Qa = 'QA',
  /** Réunion */
  Re = 'RE',
  /** Romania */
  Ro = 'RO',
  /** Russia */
  Ru = 'RU',
  /** Rwanda */
  Rw = 'RW',
  /** Saint Barthélemy */
  Bl = 'BL',
  /** Saint Helena, Ascension and Tristan da Cunha */
  Sh = 'SH',
  /** Saint Kitts and Nevis */
  Kn = 'KN',
  /** Saint Lucia */
  Lc = 'LC',
  /** Saint Martin (French part) */
  Mf = 'MF',
  /** Saint Pierre and Miquelon */
  Pm = 'PM',
  /** Saint Vincent and the Grenadines */
  Vc = 'VC',
  /** Samoa */
  Ws = 'WS',
  /** San Marino */
  Sm = 'SM',
  /** Sao Tome and Principe */
  St = 'ST',
  /** Saudi Arabia */
  Sa = 'SA',
  /** Senegal */
  Sn = 'SN',
  /** Serbia */
  Rs = 'RS',
  /** Seychelles */
  Sc = 'SC',
  /** Sierra Leone */
  Sl = 'SL',
  /** Singapore */
  Sg = 'SG',
  /** Sint Maarten (Dutch part) */
  Sx = 'SX',
  /** Slovakia */
  Sk = 'SK',
  /** Slovenia */
  Si = 'SI',
  /** Solomon Islands */
  Sb = 'SB',
  /** Somalia */
  So = 'SO',
  /** South Africa */
  Za = 'ZA',
  /** South Georgia and the South Sandwich Islands */
  Gs = 'GS',
  /** South Korea */
  Kr = 'KR',
  /** South Sudan */
  Ss = 'SS',
  /** Spain */
  Es = 'ES',
  /** Sri Lanka */
  Lk = 'LK',
  /** Sudan */
  Sd = 'SD',
  /** Suriname */
  Sr = 'SR',
  /** Svalbard and Jan Mayen */
  Sj = 'SJ',
  /** Sweden */
  Se = 'SE',
  /** Switzerland */
  Ch = 'CH',
  /** Syria */
  Sy = 'SY',
  /** Taiwan */
  Tw = 'TW',
  /** Tajikistan */
  Tj = 'TJ',
  /** Tanzania */
  Tz = 'TZ',
  /** Thailand */
  Th = 'TH',
  /** Timor-Leste */
  Tl = 'TL',
  /** Togo */
  Tg = 'TG',
  /** Tokelau */
  Tk = 'TK',
  /** Tonga */
  To = 'TO',
  /** Trinidad and Tobago */
  Tt = 'TT',
  /** Tunisia */
  Tn = 'TN',
  /** Turkey */
  Tr = 'TR',
  /** Turkmenistan */
  Tm = 'TM',
  /** Turks and Caicos Islands */
  Tc = 'TC',
  /** Tuvalu */
  Tv = 'TV',
  /** Uganda */
  Ug = 'UG',
  /** Ukraine */
  Ua = 'UA',
  /** United Arab Emirates */
  Ae = 'AE',
  /** United Kingdom */
  Gb = 'GB',
  /** United States Minor Outlying Islands */
  Um = 'UM',
  /** United States of America */
  Us = 'US',
  /** Uruguay */
  Uy = 'UY',
  /** Uzbekistan */
  Uz = 'UZ',
  /** Vanuatu */
  Vu = 'VU',
  /** Venezuela */
  Ve = 'VE',
  /** Vietnam */
  Vn = 'VN',
  /** Virgin Islands (British) */
  Vg = 'VG',
  /** Virgin Islands (U.S.) */
  Vi = 'VI',
  /** Wallis and Futuna */
  Wf = 'WF',
  /** Western Sahara */
  Eh = 'EH',
  /** Yemen */
  Ye = 'YE',
  /** Zambia */
  Zm = 'ZM',
  /** Zimbabwe */
  Zw = 'ZW'
}

export type GalleryCreateOrUpdateMutation = {
  __typename?: 'GalleryCreateOrUpdateMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  gallery?: Maybe<GallerySourcingType>;
};

export type GalleryDetailType = {
  __typename?: 'GalleryDetailType';
  galleryName?: Maybe<Scalars['String']>;
  gallerySlug?: Maybe<Scalars['String']>;
  galleryWebsite?: Maybe<Scalars['String']>;
  galleryContactEmail?: Maybe<Scalars['String']>;
  galleryContactNumber?: Maybe<Scalars['String']>;
};

export type GallerySourcingPaginatedType = {
  __typename?: 'GallerySourcingPaginatedType';
  page: Scalars['Int'];
  pages: Scalars['Int'];
  hasNext: Scalars['Boolean'];
  hasPrev: Scalars['Boolean'];
  objects: Array<GallerySourcingType>;
  total: Scalars['Int'];
};

/** GQL Type for the Gallery model */
export type GallerySourcingType = {
  __typename?: 'GallerySourcingType';
  id: Scalars['ID'];
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  countries: GalleryCountries;
  contactEmail: Scalars['String'];
  contactNumber: Scalars['String'];
  tags: Scalars['String'];
  notes: Scalars['String'];
  deleted: Scalars['Boolean'];
  variants?: Maybe<Array<Maybe<ArtistVariantType>>>;
  countryNames?: Maybe<Array<Scalars['String']>>;
  countryCodes?: Maybe<Array<Scalars['String']>>;
};

export type GalleryType = {
  __typename?: 'GalleryType';
  name?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
};

export type GetImageCacheMutation = {
  __typename?: 'GetImageCacheMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  imageCache?: Maybe<ImageCacheType>;
};

/** GQL Type for the ImageCache model. */
export type ImageCacheType = {
  __typename?: 'ImageCacheType';
  id: Scalars['ID'];
  imageHash: Scalars['String'];
  key: Scalars['String'];
  lastUsed: Scalars['DateTime'];
  imageFeatures: Scalars['String'];
};

/** Helper type for image objects */
export type ImageType = {
  __typename?: 'ImageType';
  id: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  isSensitive: Scalars['Boolean'];
};

/** An enumeration. */
export enum InquiryBudgetCurrency {
  /** USD */
  Usd = 'USD',
  /** SGD */
  Sgd = 'SGD',
  /** RMB */
  Rmb = 'RMB'
}

/** GQL type for inquiry object */
export type InquiryType = {
  __typename?: 'InquiryType';
  id: Scalars['ID'];
  isDone: Scalars['Boolean'];
  adminNotes: Scalars['String'];
  user?: Maybe<UserType>;
  userEmail: Scalars['String'];
  esId: Scalars['String'];
  productName: Scalars['String'];
  inquiry: Scalars['String'];
  createdAt: Scalars['DateTime'];
  respondedAt?: Maybe<Scalars['DateTime']>;
  changeType?: Maybe<Array<Maybe<InquiryTypeType>>>;
  budget?: Maybe<Scalars['Int']>;
  budgetCurrency?: Maybe<InquiryBudgetCurrency>;
  product?: Maybe<ProductType>;
};

/** GQL type for inquirytype object */
export type InquiryTypeType = {
  __typename?: 'InquiryTypeType';
  id: Scalars['ID'];
  position?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  inquiries?: Maybe<Array<Maybe<InquiryType>>>;
};

/** GQL Type for the LocationImage model. */
export type LocationImageType = {
  __typename?: 'LocationImageType';
  id: Scalars['ID'];
  externalId?: Maybe<Scalars['Int']>;
  location: LocationType;
  image?: Maybe<Scalars['String']>;
  imageFilename: Scalars['String'];
  isSensitive: Scalars['Boolean'];
  deleted: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  imgBase64?: Maybe<Scalars['String']>;
};

export type LocationItemCommissionedWorksImageType = {
  __typename?: 'LocationItemCommissionedWorksImageType';
  id: Scalars['ID'];
  locationItem?: Maybe<LocationItemType>;
  imageKey?: Maybe<Scalars['String']>;
  imageKeyFilename: Scalars['String'];
  deleted: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** GQL Type for the LocationItemImage model. */
export type LocationItemImageType = {
  __typename?: 'LocationItemImageType';
  id: Scalars['ID'];
  externalId?: Maybe<Scalars['Int']>;
  locationItem: LocationItemType;
  image?: Maybe<Scalars['String']>;
  imageFilename: Scalars['String'];
  isSensitive: Scalars['Boolean'];
  deleted: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  imgBase64?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum LocationItemMeasurementUnit {
  /** cm */
  Cm = 'CM',
  /** in */
  In = 'IN'
}

/** GQL Type for the LocationItem model. */
export type LocationItemType = {
  __typename?: 'LocationItemType';
  id: Scalars['ID'];
  externalId: Scalars['Int'];
  title: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  location: LocationType;
  locationItemType?: Maybe<LocationItemTypeType>;
  category?: Maybe<Array<Maybe<CategoryType>>>;
  attribute1?: Maybe<Array<Maybe<Attribute1Type>>>;
  attribute2?: Maybe<Array<Maybe<Attribute2Type>>>;
  measurementUnit: LocationItemMeasurementUnit;
  wallSizeSameAsArtwork: Scalars['Boolean'];
  wallWidth?: Maybe<Scalars['Float']>;
  wallHeight?: Maybe<Scalars['Float']>;
  wallDepth?: Maybe<Scalars['Float']>;
  wallPt1: Scalars['String'];
  wallPt2: Scalars['String'];
  wallPt3: Scalars['String'];
  wallPt4: Scalars['String'];
  wallVimrBg: Scalars['String'];
  wallImgRotation: Scalars['Int'];
  wallUnsure: Scalars['Boolean'];
  itemWidth?: Maybe<Scalars['Float']>;
  itemHeight?: Maybe<Scalars['Float']>;
  itemDepth?: Maybe<Scalars['Float']>;
  itemUnsure: Scalars['Boolean'];
  position?: Maybe<Scalars['Int']>;
  deleted: Scalars['Boolean'];
  info: Scalars['String'];
  isCommissionedWork: Scalars['Boolean'];
  commissionedWorkInfo: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  wfCommissionedWorksWorkingTitle: Scalars['String'];
  wfCommissionedWorksArtworkDescription: Scalars['String'];
  wfCommissionedWorksEstimatedTimeline: Scalars['String'];
  wfCommissionedWorksAdditionalQuestions: Scalars['String'];
  wfCommissionedWorksBudgetOverride?: Maybe<Scalars['Int']>;
  wfCommissionedWorksBudgetCcy: Scalars['String'];
  wfCommissionedWorksPreFilledFiles: Scalars['Boolean'];
  wfCommissionedWorksWallWidthOverride?: Maybe<Scalars['Float']>;
  wfCommissionedWorksWallHeightOverride?: Maybe<Scalars['Float']>;
  wfCommissionedWorksItemWidthOverride?: Maybe<Scalars['Float']>;
  wfCommissionedWorksItemHeightOverride?: Maybe<Scalars['Float']>;
  wfCommissionedWorksCommission?: Maybe<Scalars['Float']>;
  pdfImage: Scalars['String'];
  pdfImageSize: Scalars['String'];
  pdfActive: Scalars['Boolean'];
  quotationitemSet?: Maybe<Array<Maybe<QuotationItemType>>>;
  workflowCommissionedWorksImages?: Maybe<Array<LocationItemCommissionedWorksImageType>>;
  locationItemImages?: Maybe<Array<LocationItemImageType>>;
  vimrImages: Array<ProjectVimrImageType>;
  floorplans?: Maybe<Array<FloorPlanType>>;
  artistVariantProjects?: Maybe<Array<Maybe<ArtistVariantProjectType>>>;
  bucketItems?: Maybe<Array<BucketItemType>>;
  flowItemsNormalWorks?: Maybe<Array<Maybe<FlowItemArtworkFramerRfqType>>>;
  flowItemsCommissionedWorks?: Maybe<Array<Maybe<FlowItemCommissionedWorkConfirmAvailabilityType>>>;
  locationItemIsReady?: Maybe<Scalars['Boolean']>;
  titleStr: Scalars['String'];
  bucketItemsData?: Maybe<Array<Maybe<ProductType>>>;
  productsAndBucketItems: Array<ProductAndItemType>;
  locationItemTypeStr?: Maybe<Scalars['String']>;
  dimensionsStr?: Maybe<Scalars['String']>;
  isShortlisted: Scalars['Boolean'];
  allImages: Array<Maybe<Scalars['String']>>;
  allCoverImages: Array<Maybe<Scalars['String']>>;
  allImagesFull: Array<Maybe<ImageType>>;
};


/** GQL Type for the LocationItem model. */
export type LocationItemTypeLocationItemImagesArgs = {
  imageOnly?: Maybe<Scalars['Boolean']>;
};

/** GQL Type for theh LocationItemType model. */
export type LocationItemTypeType = {
  __typename?: 'LocationItemTypeType';
  id: Scalars['ID'];
  title: Scalars['String'];
  slug: Scalars['String'];
  position?: Maybe<Scalars['Int']>;
  locationItems?: Maybe<Array<Maybe<LocationItemType>>>;
};

/** GQL Type for the Location model. */
export type LocationType = {
  __typename?: 'LocationType';
  id: Scalars['ID'];
  externalId: Scalars['Int'];
  title: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  budget: Scalars['Int'];
  project: ProjectType;
  info: Scalars['String'];
  tags?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  deleted: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  pdfImage: Scalars['String'];
  pdfImageSize: Scalars['String'];
  pdfActive: Scalars['Boolean'];
  locationItems: Array<LocationItemType>;
  locationImages?: Maybe<Array<LocationImageType>>;
  vimrImages: Array<ProjectVimrImageType>;
  floorplans?: Maybe<Array<FloorPlanType>>;
  bucketItems?: Maybe<Array<Maybe<BucketItemType>>>;
  activeLocationImages?: Maybe<Array<LocationImageType>>;
  locationImageUrl?: Maybe<Scalars['String']>;
  locationIsReady?: Maybe<Scalars['Boolean']>;
  activeLocationItems: Array<LocationItemType>;
  titleStr: Scalars['String'];
  isShortlisted: Scalars['Boolean'];
  hasCommissioned: Scalars['Boolean'];
  hasImages: Scalars['Boolean'];
};

export type LoginMutation = {
  __typename?: 'LoginMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  currentProfile?: Maybe<UserProfileType>;
};

export type LogoutMutation = {
  __typename?: 'LogoutMutation';
  status?: Maybe<Scalars['Int']>;
};

/** GQL Type for the Medium model */
export type MediumType = {
  __typename?: 'MediumType';
  id: Scalars['ID'];
  title: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  artistvariantSet?: Maybe<Array<Maybe<ArtistVariantType>>>;
};

export type Mutations = {
  __typename?: 'Mutations';
  wfCommissionedWorksStaffUpdateLocationInfo?: Maybe<StaffCommissionedWorksUpdateLocationInfoMutation>;
  wfCommissionedWorksStaffNotifySellers?: Maybe<StaffCommissionedWorksNotifySellersMutation>;
  wfCommissionedWorksSellerSaveAvailabilityData?: Maybe<SellerCommissionedWorksSaveAvailabilityDataMutation>;
  wfCommissionedWorksSellerSubmitAvailabilityData?: Maybe<SellerCommissionedWorksSubmitAvailabilityDataMutation>;
  wfStaffCheckAvailability?: Maybe<StaffCheckAvailabilityMutation>;
  wfSellerConfirmAvailabilityMutation?: Maybe<SellerConfirmAvailabilityMutation>;
  wfNwStaffUpdateFlowItemFramerInfo?: Maybe<StaffUpdateFlowItemFramerInfoMutation>;
  wfNwStaffRequestFramerQuotation?: Maybe<StaffRequestFramerQuotation>;
  wfNwFramerRfqUpdateGlobal?: Maybe<FramerRfqUpdateGlobalMutation>;
  wfNwFramerRfqSaveStretchingInfo?: Maybe<FramerRfqSaveStretchingInfoMutation>;
  wfNwFramerRfqSubmitMutation?: Maybe<FramerRfqSubmitMutation>;
  wfNwFramerRfqCreateOrUpdateSpecs?: Maybe<FramerRfQsCreateOrUpdateSpecsMutation>;
  wfNwFramerRfqCreateNewSpecs?: Maybe<FramerRfQsCreateNewSpecsMutation>;
  wfNwFramerRfqDeleteSpec?: Maybe<FramerRfqDeleteSpecMutation>;
  wfNwFramerRfqSaveInstallationInfo?: Maybe<FramerRfQsSaveInstallationInfoMutation>;
  wfNwFramerRfqSaveDeliveryInfo?: Maybe<FramerRfQsSaveDeliveryInfoMutation>;
  wfNwStaffMoveToStep?: Maybe<StaffMoveToStepMutation>;
  updateAccountInfo?: Maybe<UpdateAccountInfoMutation>;
  setJoyrideSearchView?: Maybe<SetJoyrideSearchViewMutation>;
  replayJoyrideSearchView?: Maybe<ReplayJoyrideSearchViewMutation>;
  setJoyrideShortlistedView?: Maybe<SetJoyrideShortlistedViewMutation>;
  replayJoyrideShortlistedView?: Maybe<ReplayJoyrideShortlistedViewMutation>;
  createUpdateUserVirImage?: Maybe<CreateUpdateUserVirImageMutation>;
  artistVariantUpdateCreate?: Maybe<ArtistVariantUpdateCreateMutation>;
  artistCreateUpdate?: Maybe<ArtistCreateOrUpdateMutation>;
  galleryCreateUpdate?: Maybe<GalleryCreateOrUpdateMutation>;
  artistVariantProjectCreateUpdate?: Maybe<ArtistVariantProjectCreateOrUpdateMutation>;
  artistVariantProjectDelete?: Maybe<ArtistVariantProjectDeleteMutation>;
  demoArtistUpdate?: Maybe<DemoArtistUpdateMutation>;
  updateArtistProductImageCropped?: Maybe<UpdateArtistProductImageCroppedMutation>;
  sourcedProductUpdateCreate?: Maybe<SourcedProductUpdateCreateMutation>;
  requestQuotation?: Maybe<RequestQuotationMutation>;
  staffCompleteQuotation?: Maybe<StaffCompleteQuotationMutation>;
  userAcceptQuotation?: Maybe<UserAcceptQuotationMutation>;
  createNewProposal?: Maybe<CreateNewProposalMutation>;
  addProposalPage?: Maybe<AddProposalPageMutation>;
  deleteProposal?: Maybe<DeleteProposalMutation>;
  deleteProposalPage?: Maybe<DeleteProposalPageMutation>;
  toggleProposalVertical?: Maybe<ToggleProposalVerticalMutation>;
  updateProposalActivePages?: Maybe<UpdateProposalActivePagesMutation>;
  updatePdfPageImage?: Maybe<UpdatePdfPageImageMutation>;
  updatePdfPageImageSize?: Maybe<UpdatePdfPageImageSizeMutation>;
  updateProposalHideFields?: Maybe<UpdateProposalHideFieldsMutation>;
  updateProposalContact?: Maybe<UpdateProposalContactMutation>;
  updateProposalLogo?: Maybe<UpdateProposalLogoMutation>;
  updatePageDetail?: Maybe<UpdatePageDetailMutation>;
  updateProposalPagesPosition?: Maybe<UpdateProposalPagesPositionMutation>;
  refreshPage?: Maybe<RefreshPageMutation>;
  floorplanClearEdits?: Maybe<FloorplanClearEditsMutation>;
  createQuickSource?: Maybe<CreateQuickSourceMutation>;
  createNewLocation?: Maybe<CreateNewLocationMutation>;
  createNewLocationItem?: Maybe<CreateNewLocationItemMutation>;
  createNewProject?: Maybe<CreateNewProjectMutation>;
  createUpdateLocationImage?: Maybe<CreateUpdateLocationImageMutation>;
  deleteProject?: Maybe<DeleteProjectMutation>;
  deleteLocation?: Maybe<DeleteLocationMutation>;
  deleteLocationItem?: Maybe<DeleteLocationItemMutation>;
  updateLocationInfo?: Maybe<UpdateLocationInfoMutation>;
  updateLocationItem?: Maybe<UpdateLocationItemMutation>;
  updateLocationTitle?: Maybe<UpdateLocationTitleMutation>;
  updateProjectBudget?: Maybe<UpdateProjectBudgetMutation>;
  updateProjectOverview?: Maybe<UpdateProjectOverviewMutation>;
  updateProjectLocationOverview?: Maybe<UpdateProjectLocationOverviewMutation>;
  updateProjectTimeline?: Maybe<UpdateProjectTimelineMutation>;
  updateProjectTitle?: Maybe<UpdateProjectTitleMutation>;
  updateProjectType?: Maybe<UpdateProjectTypeMutation>;
  updatePdfDetails?: Maybe<UpdatePdfDetailsMutation>;
  updatePdfHideFields?: Maybe<UpdatePdfHideFieldsMutation>;
  updatePdfImage?: Maybe<UpdatePdfImageMutation>;
  updatePdfImageSize?: Maybe<UpdatePdfImageSizeMutation>;
  updatePdfAreaImage?: Maybe<UpdatePdfAreaImageMutation>;
  updatePdfAreaImageSize?: Maybe<UpdatePdfAreaImageSizeMutation>;
  updatePdfLocationImage?: Maybe<UpdatePdfLocationImageMutation>;
  updatePdfLocationImageSize?: Maybe<UpdatePdfLocationImageSizeMutation>;
  updatePdfFloorplanSize?: Maybe<UpdatePdfFloorplanSizeMutation>;
  updateLocationItemVimrWall?: Maybe<UpdateLocationItemVimrWallMutation>;
  updateLocationItemPoints?: Maybe<UpdateLocationItemPointsMutation>;
  updateLocationRotation?: Maybe<UpdateLocationRotationMutation>;
  updateLocationName?: Maybe<UpdateLocationNameMutation>;
  updateLocationItemName?: Maybe<UpdateLocationItemNameMutation>;
  updateBucketItemPosition?: Maybe<UpdateBucketItemPositionMutation>;
  updateQuotationShippingAddress?: Maybe<UpdateQuotationShippingAddressMutation>;
  updateQuotationBillingAddress?: Maybe<UpdateQuotationBillingAddressMutation>;
  requestArtworkProposal?: Maybe<RequestArtworkProposalMutation>;
  staffFinishArtworkProposal?: Maybe<StaffFinishArtworkProposalMutation>;
  updateLocationPosition?: Maybe<UpdateLocationPositionMutation>;
  updateAreaBucketPositions?: Maybe<UpdateAreaBucketPositionsMutation>;
  createUpdateVimrImages?: Maybe<CreateVimrImagesMutation>;
  removeProductVimrImage?: Maybe<RemoveProductVimrImageMutation>;
  deleteVimrImages?: Maybe<DeleteVimrImagesMutation>;
  updateFloorplan?: Maybe<UpdateFloorplanMutation>;
  updateFloorplanScale?: Maybe<UpdateFloorplanScaleMutation>;
  updateFloorplanShownUnit?: Maybe<UpdateFloorplanShownUnitMutation>;
  revokeProjectAccess?: Maybe<RevokeProjectAccessMutation>;
  newsletterSignup?: Maybe<NewsletterSignupMutation>;
  newInquiry?: Maybe<NewInquiryMutation>;
  getImageCache?: Maybe<GetImageCacheMutation>;
  addCroppedImageCache?: Maybe<AddCroppedImageCacheMutation>;
  savedFrameUpdateCreate?: Maybe<SavedFrameUpdateCreateMutation>;
  createOrUpdateFramerMutation?: Maybe<CreateOrUpdateFramerMutation>;
  addFramersToProject?: Maybe<AddFramersToProjectMutation>;
  deleteFramerFromProject?: Maybe<DeleteFramerFromProjectMutation>;
  submitFeedback?: Maybe<SubmitFeedbackMutation>;
  updateFeedback?: Maybe<UpdateFeedbackMutation>;
  toggleFavorite?: Maybe<ToggleFavoriteMutation>;
  createClient?: Maybe<CreateClientMutation>;
  updateCreateClient?: Maybe<UpdateCreateClientMutation>;
  addOrRemoveBucketItem?: Maybe<AddOrRemoveBucketItemMutation>;
  updateBucketItem?: Maybe<UpdateBucketItemMutation>;
  updateBucketItemWallPt?: Maybe<UpdateBucketItemWallPtMutation>;
  toggleBucketItemShortlisted?: Maybe<ToggleBucketItemShortlistedMutation>;
  resetBucketItemCustomText?: Maybe<ResetBucketItemCustomTextMutation>;
  updateBucketItemDetails?: Maybe<UpdateBucketItemDetailsMutation>;
  updatePdfItemImageSize?: Maybe<UpdatePdfItemImageSizeMutation>;
  login?: Maybe<LoginMutation>;
  logout?: Maybe<LogoutMutation>;
  signup?: Maybe<SignupMutation>;
  forgotPassword?: Maybe<ForgotPasswordMutation>;
  resetPasswordWithKey?: Maybe<ResetPasswordWithKeyMutation>;
  updatePassword?: Maybe<UpdatePasswordMutation>;
  updateEmail?: Maybe<UpdateEmailMutation>;
  registerForAccess?: Maybe<AccessInquirySubmitMutation>;
  projectInvite?: Maybe<ProjectInviteMutation>;
};


export type MutationsWfCommissionedWorksStaffUpdateLocationInfoArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsWfCommissionedWorksStaffNotifySellersArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsWfCommissionedWorksSellerSaveAvailabilityDataArgs = {
  data?: Maybe<Scalars['String']>;
  flowItemId?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};


export type MutationsWfCommissionedWorksSellerSubmitAvailabilityDataArgs = {
  data?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};


export type MutationsWfStaffCheckAvailabilityArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsWfSellerConfirmAvailabilityMutationArgs = {
  data?: Maybe<Scalars['String']>;
  submit?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};


export type MutationsWfNwStaffUpdateFlowItemFramerInfoArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsWfNwStaffRequestFramerQuotationArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsWfNwFramerRfqUpdateGlobalArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsWfNwFramerRfqSaveStretchingInfoArgs = {
  data?: Maybe<Scalars['String']>;
  flowItemId?: Maybe<Scalars['String']>;
};


export type MutationsWfNwFramerRfqSubmitMutationArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsWfNwFramerRfqCreateOrUpdateSpecsArgs = {
  data?: Maybe<Scalars['String']>;
  flowItemId?: Maybe<Scalars['String']>;
  specId?: Maybe<Scalars['String']>;
};


export type MutationsWfNwFramerRfqCreateNewSpecsArgs = {
  flowItemId?: Maybe<Scalars['String']>;
  framerHashId?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};


export type MutationsWfNwFramerRfqDeleteSpecArgs = {
  flowItemId?: Maybe<Scalars['String']>;
  framerHashId?: Maybe<Scalars['String']>;
  specId?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};


export type MutationsWfNwFramerRfqSaveInstallationInfoArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsWfNwFramerRfqSaveDeliveryInfoArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsWfNwStaffMoveToStepArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdateAccountInfoArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsSetJoyrideSearchViewArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsReplayJoyrideSearchViewArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsSetJoyrideShortlistedViewArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsReplayJoyrideShortlistedViewArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsCreateUpdateUserVirImageArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsArtistVariantUpdateCreateArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsArtistCreateUpdateArgs = {
  artistId?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
};


export type MutationsGalleryCreateUpdateArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsArtistVariantProjectCreateUpdateArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsArtistVariantProjectDeleteArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsDemoArtistUpdateArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdateArtistProductImageCroppedArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsSourcedProductUpdateCreateArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsRequestQuotationArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsStaffCompleteQuotationArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUserAcceptQuotationArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsCreateNewProposalArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsAddProposalPageArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsDeleteProposalArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsDeleteProposalPageArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsToggleProposalVerticalArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdateProposalActivePagesArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdatePdfPageImageArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdatePdfPageImageSizeArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdateProposalHideFieldsArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdateProposalContactArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdateProposalLogoArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdatePageDetailArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdateProposalPagesPositionArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsRefreshPageArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsFloorplanClearEditsArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsCreateQuickSourceArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsCreateNewLocationArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsCreateNewLocationItemArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsCreateUpdateLocationImageArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsDeleteProjectArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsDeleteLocationArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsDeleteLocationItemArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdateLocationInfoArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdateLocationItemArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdateLocationTitleArgs = {
  hashId?: Maybe<Scalars['String']>;
};


export type MutationsUpdateProjectBudgetArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdateProjectOverviewArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdateProjectLocationOverviewArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdateProjectTimelineArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdateProjectTitleArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdateProjectTypeArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdatePdfDetailsArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdatePdfHideFieldsArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdatePdfImageArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdatePdfImageSizeArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdatePdfAreaImageArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdatePdfAreaImageSizeArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdatePdfLocationImageArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdatePdfLocationImageSizeArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdatePdfFloorplanSizeArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdateLocationItemVimrWallArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdateLocationItemPointsArgs = {
  locItemId?: Maybe<Scalars['String']>;
  pt1?: Maybe<Scalars['String']>;
  pt2?: Maybe<Scalars['String']>;
  pt3?: Maybe<Scalars['String']>;
  pt4?: Maybe<Scalars['String']>;
};


export type MutationsUpdateLocationRotationArgs = {
  locItemId?: Maybe<Scalars['String']>;
  rotate?: Maybe<Scalars['Int']>;
};


export type MutationsUpdateLocationNameArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdateLocationItemNameArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdateBucketItemPositionArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdateQuotationShippingAddressArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdateQuotationBillingAddressArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsRequestArtworkProposalArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsStaffFinishArtworkProposalArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdateLocationPositionArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdateAreaBucketPositionsArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsCreateUpdateVimrImagesArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsRemoveProductVimrImageArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsDeleteVimrImagesArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdateFloorplanArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdateFloorplanScaleArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdateFloorplanShownUnitArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsRevokeProjectAccessArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsNewsletterSignupArgs = {
  email?: Maybe<Scalars['String']>;
};


export type MutationsNewInquiryArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsGetImageCacheArgs = {
  fileKey?: Maybe<Scalars['String']>;
};


export type MutationsAddCroppedImageCacheArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsSavedFrameUpdateCreateArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsCreateOrUpdateFramerMutationArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsAddFramersToProjectArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsDeleteFramerFromProjectArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsSubmitFeedbackArgs = {
  formInput?: Maybe<Scalars['String']>;
};


export type MutationsUpdateFeedbackArgs = {
  id?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
};


export type MutationsToggleFavoriteArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsCreateClientArgs = {
  name?: Maybe<Scalars['String']>;
};


export type MutationsUpdateCreateClientArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsAddOrRemoveBucketItemArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdateBucketItemArgs = {
  customSize?: Maybe<Scalars['String']>;
  esId?: Maybe<Scalars['String']>;
  hashId?: Maybe<Scalars['String']>;
  locationItemId?: Maybe<Scalars['String']>;
};


export type MutationsUpdateBucketItemWallPtArgs = {
  esId?: Maybe<Scalars['String']>;
  locItemId?: Maybe<Scalars['String']>;
  wallPt?: Maybe<Scalars['String']>;
};


export type MutationsToggleBucketItemShortlistedArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsResetBucketItemCustomTextArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdateBucketItemDetailsArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdatePdfItemImageSizeArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsLoginArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsSignupArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsForgotPasswordArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsResetPasswordWithKeyArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdatePasswordArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsUpdateEmailArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsRegisterForAccessArgs = {
  data?: Maybe<Scalars['String']>;
};


export type MutationsProjectInviteArgs = {
  data?: Maybe<Scalars['String']>;
};

export type NewInquiryMutation = {
  __typename?: 'NewInquiryMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  inquiry?: Maybe<InquiryType>;
};

export type NewsletterSignupMutation = {
  __typename?: 'NewsletterSignupMutation';
  formErrors?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
};

export type NormalWorksLocation = {
  __typename?: 'NormalWorksLocation';
  locationTitle?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  locationItems: Array<NormalWorksLocationItem>;
};

export type NormalWorksLocationItem = {
  __typename?: 'NormalWorksLocationItem';
  locationItemTitle?: Maybe<Scalars['String']>;
  locationItemId?: Maybe<Scalars['String']>;
  steps: Array<NormalWorksStepItems>;
};

/**
 * For overview view. Shows all items for this workflow, grouped by their
 * current steps.
 */
export type NormalWorksProjectSteps = {
  __typename?: 'NormalWorksProjectSteps';
  itemGroup?: Maybe<Scalars['String']>;
  projectTitle?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  steps: Array<NormalWorksStepItems>;
};

export type NormalWorksStepItems = {
  __typename?: 'NormalWorksStepItems';
  step: Scalars['String'];
  items: Array<FlowItemArtworkType>;
};

/** GQL Type for the PDFType model */
export type PdfPageType = {
  __typename?: 'PDFPageType';
  id: Scalars['ID'];
  pdfDetails: Scalars['String'];
  pageType: Scalars['String'];
  project?: Maybe<ProjectType>;
  location?: Maybe<LocationType>;
  locationItem?: Maybe<LocationItemType>;
  bucketItem?: Maybe<BucketItemType>;
  floorplan?: Maybe<FloorPlanType>;
  virImage?: Maybe<ProjectVimrImageType>;
  position?: Maybe<Scalars['Int']>;
  pdf: PdfProposalType;
  isActive: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

/** GQL Type for the PDFProposal model */
export type PdfProposalType = {
  __typename?: 'PDFProposalType';
  id: Scalars['ID'];
  project: ProjectType;
  title: Scalars['String'];
  pdfVertical: Scalars['Boolean'];
  pdfEmail: Scalars['String'];
  pdfAddr: Scalars['String'];
  pdfSite: Scalars['String'];
  pdfLogo?: Maybe<Scalars['String']>;
  pdfHideTitle: Scalars['Boolean'];
  pdfHideArtist: Scalars['Boolean'];
  pdfHidePrice: Scalars['Boolean'];
  pdfHideShipping: Scalars['Boolean'];
  pdfHideAreaDetails: Scalars['Boolean'];
  pdfHideLogo: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  pages: Array<PdfPageType>;
  lastUpdated: Scalars['String'];
};


/** GQL Type for the PDFProposal model */
export type PdfProposalTypePagesArgs = {
  activeOnly?: Maybe<Scalars['Boolean']>;
};

export type PrintBaseCostType = {
  __typename?: 'PrintBaseCostType';
  printSize?: Maybe<Scalars['String']>;
  printSizePk?: Maybe<Scalars['String']>;
  printMaterial?: Maybe<Scalars['String']>;
};

/**
 * Special combined type that we need on the LocationDetailView.
 *
 * That view basically just needs to show a list of BucketItems, but it
 * also needs to display some product data. We COULD save that product
 * data on the BucketItem the moment we create it, but then this data can
 * get out of sync with reality in case we ever update prices and images of
 * our ES data. Therefore, we want the ES to remain the single source of
 * truth.
 *
 * That means, the LocationDetailView needs all BucketItem instances, but then
 * it also needs the ES Product for each BucketItem instance. Therefore, we
 * have this combined type, that contains both instances.
 *
 * This also allows us to have the resolve_copy_paste_text function here in
 * the backend vs letting the frontend doing both queries and then computing
 * the copy_paste_text there.
 */
export type ProductAndItemType = {
  __typename?: 'ProductAndItemType';
  id: Scalars['String'];
  product: ProductType;
  bucketItem: BucketItemType;
  copyPasteText: Scalars['String'];
};

export type ProductPaginatedType = {
  __typename?: 'ProductPaginatedType';
  page?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  hasNext?: Maybe<Scalars['Boolean']>;
  hasPrev?: Maybe<Scalars['Boolean']>;
  objects?: Maybe<Array<Maybe<ProductType>>>;
  total?: Maybe<Scalars['Int']>;
  relatedObject?: Maybe<ProductType>;
  showImage?: Maybe<Scalars['String']>;
};

export type ProductType = {
  __typename?: 'ProductType';
  id: Scalars['String'];
  esId: Scalars['String'];
  cheapestPriceString?: Maybe<Scalars['String']>;
  sizeString?: Maybe<Scalars['String']>;
  image: Scalars['String'];
  croppedImage?: Maybe<Scalars['String']>;
  extraImages: Array<Scalars['String']>;
  favorited: Scalars['Boolean'];
  artistUpdatedAt?: Maybe<Scalars['DateTime']>;
  selectedWidth?: Maybe<Scalars['String']>;
  selectedHeight?: Maybe<Scalars['String']>;
  selectedThickness?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  deleted?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  artworksMedium?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  exclusive?: Maybe<Scalars['Boolean']>;
  remainingCount?: Maybe<Scalars['Int']>;
  designLeadTime?: Maybe<Scalars['Int']>;
  designSuitableForOutdoors?: Maybe<Scalars['Boolean']>;
  bookAuthors?: Maybe<Scalars['String']>;
  bookPublisher?: Maybe<Scalars['String']>;
  printOrientation?: Maybe<Scalars['String']>;
  showPrice?: Maybe<Scalars['Boolean']>;
  submitted?: Maybe<Scalars['Boolean']>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  detailUrl?: Maybe<Scalars['String']>;
  cheapestPrice?: Maybe<Scalars['Float']>;
  tags?: Maybe<Scalars['String']>;
  rejected?: Maybe<Scalars['Boolean']>;
  site: Scalars['String'];
  artist?: Maybe<ArtistType>;
  gallery?: Maybe<GalleryType>;
  category?: Maybe<SlugType>;
  medium?: Maybe<SlugAndTitleType>;
  country?: Maybe<SlugAndNameType>;
  designMaterial?: Maybe<SlugType>;
  artworkStyle?: Maybe<SlugType>;
  sizes: Array<SizeType>;
  colors?: Maybe<Array<Maybe<ColorType>>>;
  gvLabels?: Maybe<Scalars['String']>;
  baseSize?: Maybe<SizeType>;
  wallPt?: Maybe<Scalars['String']>;
  hideVir?: Maybe<Scalars['Boolean']>;
  showFrameOnVir?: Maybe<Scalars['Boolean']>;
  bucketId?: Maybe<Scalars['String']>;
};

export type ProjectAggrType = {
  __typename?: 'ProjectAggrType';
  allCount?: Maybe<Scalars['Int']>;
  ongoingCount?: Maybe<Scalars['Int']>;
  onHoldCount?: Maybe<Scalars['Int']>;
  completedCount?: Maybe<Scalars['Int']>;
  deletedCount?: Maybe<Scalars['Int']>;
  projects?: Maybe<Array<ProjectType>>;
};

/** An enumeration. */
export enum ProjectBudgetCurrency {
  /** USD */
  Usd = 'USD',
  /** SGD */
  Sgd = 'SGD',
  /** RMB */
  Rmb = 'RMB'
}

/** An enumeration. */
export enum ProjectCountry {
  /** Afghanistan */
  Af = 'AF',
  /** Åland Islands */
  Ax = 'AX',
  /** Albania */
  Al = 'AL',
  /** Algeria */
  Dz = 'DZ',
  /** American Samoa */
  As = 'AS',
  /** Andorra */
  Ad = 'AD',
  /** Angola */
  Ao = 'AO',
  /** Anguilla */
  Ai = 'AI',
  /** Antarctica */
  Aq = 'AQ',
  /** Antigua and Barbuda */
  Ag = 'AG',
  /** Argentina */
  Ar = 'AR',
  /** Armenia */
  Am = 'AM',
  /** Aruba */
  Aw = 'AW',
  /** Australia */
  Au = 'AU',
  /** Austria */
  At = 'AT',
  /** Azerbaijan */
  Az = 'AZ',
  /** Bahamas */
  Bs = 'BS',
  /** Bahrain */
  Bh = 'BH',
  /** Bangladesh */
  Bd = 'BD',
  /** Barbados */
  Bb = 'BB',
  /** Belarus */
  By = 'BY',
  /** Belgium */
  Be = 'BE',
  /** Belize */
  Bz = 'BZ',
  /** Benin */
  Bj = 'BJ',
  /** Bermuda */
  Bm = 'BM',
  /** Bhutan */
  Bt = 'BT',
  /** Bolivia */
  Bo = 'BO',
  /** Bonaire, Sint Eustatius and Saba */
  Bq = 'BQ',
  /** Bosnia and Herzegovina */
  Ba = 'BA',
  /** Botswana */
  Bw = 'BW',
  /** Bouvet Island */
  Bv = 'BV',
  /** Brazil */
  Br = 'BR',
  /** British Indian Ocean Territory */
  Io = 'IO',
  /** Brunei */
  Bn = 'BN',
  /** Bulgaria */
  Bg = 'BG',
  /** Burkina Faso */
  Bf = 'BF',
  /** Burundi */
  Bi = 'BI',
  /** Cabo Verde */
  Cv = 'CV',
  /** Cambodia */
  Kh = 'KH',
  /** Cameroon */
  Cm = 'CM',
  /** Canada */
  Ca = 'CA',
  /** Cayman Islands */
  Ky = 'KY',
  /** Central African Republic */
  Cf = 'CF',
  /** Chad */
  Td = 'TD',
  /** Chile */
  Cl = 'CL',
  /** China */
  Cn = 'CN',
  /** Christmas Island */
  Cx = 'CX',
  /** Cocos (Keeling) Islands */
  Cc = 'CC',
  /** Colombia */
  Co = 'CO',
  /** Comoros */
  Km = 'KM',
  /** Congo */
  Cg = 'CG',
  /** Congo (the Democratic Republic of the) */
  Cd = 'CD',
  /** Cook Islands */
  Ck = 'CK',
  /** Costa Rica */
  Cr = 'CR',
  /** Côte d'Ivoire */
  Ci = 'CI',
  /** Croatia */
  Hr = 'HR',
  /** Cuba */
  Cu = 'CU',
  /** Curaçao */
  Cw = 'CW',
  /** Cyprus */
  Cy = 'CY',
  /** Czechia */
  Cz = 'CZ',
  /** Denmark */
  Dk = 'DK',
  /** Djibouti */
  Dj = 'DJ',
  /** Dominica */
  Dm = 'DM',
  /** Dominican Republic */
  Do = 'DO',
  /** Ecuador */
  Ec = 'EC',
  /** Egypt */
  Eg = 'EG',
  /** El Salvador */
  Sv = 'SV',
  /** Equatorial Guinea */
  Gq = 'GQ',
  /** Eritrea */
  Er = 'ER',
  /** Estonia */
  Ee = 'EE',
  /** Eswatini */
  Sz = 'SZ',
  /** Ethiopia */
  Et = 'ET',
  /** Falkland Islands  [Malvinas] */
  Fk = 'FK',
  /** Faroe Islands */
  Fo = 'FO',
  /** Fiji */
  Fj = 'FJ',
  /** Finland */
  Fi = 'FI',
  /** France */
  Fr = 'FR',
  /** French Guiana */
  Gf = 'GF',
  /** French Polynesia */
  Pf = 'PF',
  /** French Southern Territories */
  Tf = 'TF',
  /** Gabon */
  Ga = 'GA',
  /** Gambia */
  Gm = 'GM',
  /** Georgia */
  Ge = 'GE',
  /** Germany */
  De = 'DE',
  /** Ghana */
  Gh = 'GH',
  /** Gibraltar */
  Gi = 'GI',
  /** Greece */
  Gr = 'GR',
  /** Greenland */
  Gl = 'GL',
  /** Grenada */
  Gd = 'GD',
  /** Guadeloupe */
  Gp = 'GP',
  /** Guam */
  Gu = 'GU',
  /** Guatemala */
  Gt = 'GT',
  /** Guernsey */
  Gg = 'GG',
  /** Guinea */
  Gn = 'GN',
  /** Guinea-Bissau */
  Gw = 'GW',
  /** Guyana */
  Gy = 'GY',
  /** Haiti */
  Ht = 'HT',
  /** Heard Island and McDonald Islands */
  Hm = 'HM',
  /** Holy See */
  Va = 'VA',
  /** Honduras */
  Hn = 'HN',
  /** Hong Kong */
  Hk = 'HK',
  /** Hungary */
  Hu = 'HU',
  /** Iceland */
  Is = 'IS',
  /** India */
  In = 'IN',
  /** Indonesia */
  Id = 'ID',
  /** Iran */
  Ir = 'IR',
  /** Iraq */
  Iq = 'IQ',
  /** Ireland */
  Ie = 'IE',
  /** Isle of Man */
  Im = 'IM',
  /** Israel */
  Il = 'IL',
  /** Italy */
  It = 'IT',
  /** Jamaica */
  Jm = 'JM',
  /** Japan */
  Jp = 'JP',
  /** Jersey */
  Je = 'JE',
  /** Jordan */
  Jo = 'JO',
  /** Kazakhstan */
  Kz = 'KZ',
  /** Kenya */
  Ke = 'KE',
  /** Kiribati */
  Ki = 'KI',
  /** Kuwait */
  Kw = 'KW',
  /** Kyrgyzstan */
  Kg = 'KG',
  /** Laos */
  La = 'LA',
  /** Latvia */
  Lv = 'LV',
  /** Lebanon */
  Lb = 'LB',
  /** Lesotho */
  Ls = 'LS',
  /** Liberia */
  Lr = 'LR',
  /** Libya */
  Ly = 'LY',
  /** Liechtenstein */
  Li = 'LI',
  /** Lithuania */
  Lt = 'LT',
  /** Luxembourg */
  Lu = 'LU',
  /** Macao */
  Mo = 'MO',
  /** Madagascar */
  Mg = 'MG',
  /** Malawi */
  Mw = 'MW',
  /** Malaysia */
  My = 'MY',
  /** Maldives */
  Mv = 'MV',
  /** Mali */
  Ml = 'ML',
  /** Malta */
  Mt = 'MT',
  /** Marshall Islands */
  Mh = 'MH',
  /** Martinique */
  Mq = 'MQ',
  /** Mauritania */
  Mr = 'MR',
  /** Mauritius */
  Mu = 'MU',
  /** Mayotte */
  Yt = 'YT',
  /** Mexico */
  Mx = 'MX',
  /** Micronesia (Federated States of) */
  Fm = 'FM',
  /** Moldova */
  Md = 'MD',
  /** Monaco */
  Mc = 'MC',
  /** Mongolia */
  Mn = 'MN',
  /** Montenegro */
  Me = 'ME',
  /** Montserrat */
  Ms = 'MS',
  /** Morocco */
  Ma = 'MA',
  /** Mozambique */
  Mz = 'MZ',
  /** Myanmar */
  Mm = 'MM',
  /** Namibia */
  Na = 'NA',
  /** Nauru */
  Nr = 'NR',
  /** Nepal */
  Np = 'NP',
  /** Netherlands */
  Nl = 'NL',
  /** New Caledonia */
  Nc = 'NC',
  /** New Zealand */
  Nz = 'NZ',
  /** Nicaragua */
  Ni = 'NI',
  /** Niger */
  Ne = 'NE',
  /** Nigeria */
  Ng = 'NG',
  /** Niue */
  Nu = 'NU',
  /** Norfolk Island */
  Nf = 'NF',
  /** North Korea */
  Kp = 'KP',
  /** North Macedonia */
  Mk = 'MK',
  /** Northern Mariana Islands */
  Mp = 'MP',
  /** Norway */
  No = 'NO',
  /** Oman */
  Om = 'OM',
  /** Pakistan */
  Pk = 'PK',
  /** Palau */
  Pw = 'PW',
  /** Palestine, State of */
  Ps = 'PS',
  /** Panama */
  Pa = 'PA',
  /** Papua New Guinea */
  Pg = 'PG',
  /** Paraguay */
  Py = 'PY',
  /** Peru */
  Pe = 'PE',
  /** Philippines */
  Ph = 'PH',
  /** Pitcairn */
  Pn = 'PN',
  /** Poland */
  Pl = 'PL',
  /** Portugal */
  Pt = 'PT',
  /** Puerto Rico */
  Pr = 'PR',
  /** Qatar */
  Qa = 'QA',
  /** Réunion */
  Re = 'RE',
  /** Romania */
  Ro = 'RO',
  /** Russia */
  Ru = 'RU',
  /** Rwanda */
  Rw = 'RW',
  /** Saint Barthélemy */
  Bl = 'BL',
  /** Saint Helena, Ascension and Tristan da Cunha */
  Sh = 'SH',
  /** Saint Kitts and Nevis */
  Kn = 'KN',
  /** Saint Lucia */
  Lc = 'LC',
  /** Saint Martin (French part) */
  Mf = 'MF',
  /** Saint Pierre and Miquelon */
  Pm = 'PM',
  /** Saint Vincent and the Grenadines */
  Vc = 'VC',
  /** Samoa */
  Ws = 'WS',
  /** San Marino */
  Sm = 'SM',
  /** Sao Tome and Principe */
  St = 'ST',
  /** Saudi Arabia */
  Sa = 'SA',
  /** Senegal */
  Sn = 'SN',
  /** Serbia */
  Rs = 'RS',
  /** Seychelles */
  Sc = 'SC',
  /** Sierra Leone */
  Sl = 'SL',
  /** Singapore */
  Sg = 'SG',
  /** Sint Maarten (Dutch part) */
  Sx = 'SX',
  /** Slovakia */
  Sk = 'SK',
  /** Slovenia */
  Si = 'SI',
  /** Solomon Islands */
  Sb = 'SB',
  /** Somalia */
  So = 'SO',
  /** South Africa */
  Za = 'ZA',
  /** South Georgia and the South Sandwich Islands */
  Gs = 'GS',
  /** South Korea */
  Kr = 'KR',
  /** South Sudan */
  Ss = 'SS',
  /** Spain */
  Es = 'ES',
  /** Sri Lanka */
  Lk = 'LK',
  /** Sudan */
  Sd = 'SD',
  /** Suriname */
  Sr = 'SR',
  /** Svalbard and Jan Mayen */
  Sj = 'SJ',
  /** Sweden */
  Se = 'SE',
  /** Switzerland */
  Ch = 'CH',
  /** Syria */
  Sy = 'SY',
  /** Taiwan */
  Tw = 'TW',
  /** Tajikistan */
  Tj = 'TJ',
  /** Tanzania */
  Tz = 'TZ',
  /** Thailand */
  Th = 'TH',
  /** Timor-Leste */
  Tl = 'TL',
  /** Togo */
  Tg = 'TG',
  /** Tokelau */
  Tk = 'TK',
  /** Tonga */
  To = 'TO',
  /** Trinidad and Tobago */
  Tt = 'TT',
  /** Tunisia */
  Tn = 'TN',
  /** Turkey */
  Tr = 'TR',
  /** Turkmenistan */
  Tm = 'TM',
  /** Turks and Caicos Islands */
  Tc = 'TC',
  /** Tuvalu */
  Tv = 'TV',
  /** Uganda */
  Ug = 'UG',
  /** Ukraine */
  Ua = 'UA',
  /** United Arab Emirates */
  Ae = 'AE',
  /** United Kingdom */
  Gb = 'GB',
  /** United States Minor Outlying Islands */
  Um = 'UM',
  /** United States of America */
  Us = 'US',
  /** Uruguay */
  Uy = 'UY',
  /** Uzbekistan */
  Uz = 'UZ',
  /** Vanuatu */
  Vu = 'VU',
  /** Venezuela */
  Ve = 'VE',
  /** Vietnam */
  Vn = 'VN',
  /** Virgin Islands (British) */
  Vg = 'VG',
  /** Virgin Islands (U.S.) */
  Vi = 'VI',
  /** Wallis and Futuna */
  Wf = 'WF',
  /** Western Sahara */
  Eh = 'EH',
  /** Yemen */
  Ye = 'YE',
  /** Zambia */
  Zm = 'ZM',
  /** Zimbabwe */
  Zw = 'ZW'
}

/** tags: projectInvite */
export type ProjectInviteMutation = {
  __typename?: 'ProjectInviteMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  project?: Maybe<ProjectType>;
};

export type ProjectPaginatedType = {
  __typename?: 'ProjectPaginatedType';
  page?: Maybe<Scalars['Int']>;
  pages?: Maybe<Scalars['Int']>;
  hasNext?: Maybe<Scalars['Boolean']>;
  hasPrev?: Maybe<Scalars['Boolean']>;
  objects: Array<ProjectType>;
  total?: Maybe<Scalars['Int']>;
};

/** An enumeration. */
export enum ProjectQuotationBillingCountry {
  /** Afghanistan */
  Af = 'AF',
  /** Åland Islands */
  Ax = 'AX',
  /** Albania */
  Al = 'AL',
  /** Algeria */
  Dz = 'DZ',
  /** American Samoa */
  As = 'AS',
  /** Andorra */
  Ad = 'AD',
  /** Angola */
  Ao = 'AO',
  /** Anguilla */
  Ai = 'AI',
  /** Antarctica */
  Aq = 'AQ',
  /** Antigua and Barbuda */
  Ag = 'AG',
  /** Argentina */
  Ar = 'AR',
  /** Armenia */
  Am = 'AM',
  /** Aruba */
  Aw = 'AW',
  /** Australia */
  Au = 'AU',
  /** Austria */
  At = 'AT',
  /** Azerbaijan */
  Az = 'AZ',
  /** Bahamas */
  Bs = 'BS',
  /** Bahrain */
  Bh = 'BH',
  /** Bangladesh */
  Bd = 'BD',
  /** Barbados */
  Bb = 'BB',
  /** Belarus */
  By = 'BY',
  /** Belgium */
  Be = 'BE',
  /** Belize */
  Bz = 'BZ',
  /** Benin */
  Bj = 'BJ',
  /** Bermuda */
  Bm = 'BM',
  /** Bhutan */
  Bt = 'BT',
  /** Bolivia */
  Bo = 'BO',
  /** Bonaire, Sint Eustatius and Saba */
  Bq = 'BQ',
  /** Bosnia and Herzegovina */
  Ba = 'BA',
  /** Botswana */
  Bw = 'BW',
  /** Bouvet Island */
  Bv = 'BV',
  /** Brazil */
  Br = 'BR',
  /** British Indian Ocean Territory */
  Io = 'IO',
  /** Brunei */
  Bn = 'BN',
  /** Bulgaria */
  Bg = 'BG',
  /** Burkina Faso */
  Bf = 'BF',
  /** Burundi */
  Bi = 'BI',
  /** Cabo Verde */
  Cv = 'CV',
  /** Cambodia */
  Kh = 'KH',
  /** Cameroon */
  Cm = 'CM',
  /** Canada */
  Ca = 'CA',
  /** Cayman Islands */
  Ky = 'KY',
  /** Central African Republic */
  Cf = 'CF',
  /** Chad */
  Td = 'TD',
  /** Chile */
  Cl = 'CL',
  /** China */
  Cn = 'CN',
  /** Christmas Island */
  Cx = 'CX',
  /** Cocos (Keeling) Islands */
  Cc = 'CC',
  /** Colombia */
  Co = 'CO',
  /** Comoros */
  Km = 'KM',
  /** Congo */
  Cg = 'CG',
  /** Congo (the Democratic Republic of the) */
  Cd = 'CD',
  /** Cook Islands */
  Ck = 'CK',
  /** Costa Rica */
  Cr = 'CR',
  /** Côte d'Ivoire */
  Ci = 'CI',
  /** Croatia */
  Hr = 'HR',
  /** Cuba */
  Cu = 'CU',
  /** Curaçao */
  Cw = 'CW',
  /** Cyprus */
  Cy = 'CY',
  /** Czechia */
  Cz = 'CZ',
  /** Denmark */
  Dk = 'DK',
  /** Djibouti */
  Dj = 'DJ',
  /** Dominica */
  Dm = 'DM',
  /** Dominican Republic */
  Do = 'DO',
  /** Ecuador */
  Ec = 'EC',
  /** Egypt */
  Eg = 'EG',
  /** El Salvador */
  Sv = 'SV',
  /** Equatorial Guinea */
  Gq = 'GQ',
  /** Eritrea */
  Er = 'ER',
  /** Estonia */
  Ee = 'EE',
  /** Eswatini */
  Sz = 'SZ',
  /** Ethiopia */
  Et = 'ET',
  /** Falkland Islands  [Malvinas] */
  Fk = 'FK',
  /** Faroe Islands */
  Fo = 'FO',
  /** Fiji */
  Fj = 'FJ',
  /** Finland */
  Fi = 'FI',
  /** France */
  Fr = 'FR',
  /** French Guiana */
  Gf = 'GF',
  /** French Polynesia */
  Pf = 'PF',
  /** French Southern Territories */
  Tf = 'TF',
  /** Gabon */
  Ga = 'GA',
  /** Gambia */
  Gm = 'GM',
  /** Georgia */
  Ge = 'GE',
  /** Germany */
  De = 'DE',
  /** Ghana */
  Gh = 'GH',
  /** Gibraltar */
  Gi = 'GI',
  /** Greece */
  Gr = 'GR',
  /** Greenland */
  Gl = 'GL',
  /** Grenada */
  Gd = 'GD',
  /** Guadeloupe */
  Gp = 'GP',
  /** Guam */
  Gu = 'GU',
  /** Guatemala */
  Gt = 'GT',
  /** Guernsey */
  Gg = 'GG',
  /** Guinea */
  Gn = 'GN',
  /** Guinea-Bissau */
  Gw = 'GW',
  /** Guyana */
  Gy = 'GY',
  /** Haiti */
  Ht = 'HT',
  /** Heard Island and McDonald Islands */
  Hm = 'HM',
  /** Holy See */
  Va = 'VA',
  /** Honduras */
  Hn = 'HN',
  /** Hong Kong */
  Hk = 'HK',
  /** Hungary */
  Hu = 'HU',
  /** Iceland */
  Is = 'IS',
  /** India */
  In = 'IN',
  /** Indonesia */
  Id = 'ID',
  /** Iran */
  Ir = 'IR',
  /** Iraq */
  Iq = 'IQ',
  /** Ireland */
  Ie = 'IE',
  /** Isle of Man */
  Im = 'IM',
  /** Israel */
  Il = 'IL',
  /** Italy */
  It = 'IT',
  /** Jamaica */
  Jm = 'JM',
  /** Japan */
  Jp = 'JP',
  /** Jersey */
  Je = 'JE',
  /** Jordan */
  Jo = 'JO',
  /** Kazakhstan */
  Kz = 'KZ',
  /** Kenya */
  Ke = 'KE',
  /** Kiribati */
  Ki = 'KI',
  /** Kuwait */
  Kw = 'KW',
  /** Kyrgyzstan */
  Kg = 'KG',
  /** Laos */
  La = 'LA',
  /** Latvia */
  Lv = 'LV',
  /** Lebanon */
  Lb = 'LB',
  /** Lesotho */
  Ls = 'LS',
  /** Liberia */
  Lr = 'LR',
  /** Libya */
  Ly = 'LY',
  /** Liechtenstein */
  Li = 'LI',
  /** Lithuania */
  Lt = 'LT',
  /** Luxembourg */
  Lu = 'LU',
  /** Macao */
  Mo = 'MO',
  /** Madagascar */
  Mg = 'MG',
  /** Malawi */
  Mw = 'MW',
  /** Malaysia */
  My = 'MY',
  /** Maldives */
  Mv = 'MV',
  /** Mali */
  Ml = 'ML',
  /** Malta */
  Mt = 'MT',
  /** Marshall Islands */
  Mh = 'MH',
  /** Martinique */
  Mq = 'MQ',
  /** Mauritania */
  Mr = 'MR',
  /** Mauritius */
  Mu = 'MU',
  /** Mayotte */
  Yt = 'YT',
  /** Mexico */
  Mx = 'MX',
  /** Micronesia (Federated States of) */
  Fm = 'FM',
  /** Moldova */
  Md = 'MD',
  /** Monaco */
  Mc = 'MC',
  /** Mongolia */
  Mn = 'MN',
  /** Montenegro */
  Me = 'ME',
  /** Montserrat */
  Ms = 'MS',
  /** Morocco */
  Ma = 'MA',
  /** Mozambique */
  Mz = 'MZ',
  /** Myanmar */
  Mm = 'MM',
  /** Namibia */
  Na = 'NA',
  /** Nauru */
  Nr = 'NR',
  /** Nepal */
  Np = 'NP',
  /** Netherlands */
  Nl = 'NL',
  /** New Caledonia */
  Nc = 'NC',
  /** New Zealand */
  Nz = 'NZ',
  /** Nicaragua */
  Ni = 'NI',
  /** Niger */
  Ne = 'NE',
  /** Nigeria */
  Ng = 'NG',
  /** Niue */
  Nu = 'NU',
  /** Norfolk Island */
  Nf = 'NF',
  /** North Korea */
  Kp = 'KP',
  /** North Macedonia */
  Mk = 'MK',
  /** Northern Mariana Islands */
  Mp = 'MP',
  /** Norway */
  No = 'NO',
  /** Oman */
  Om = 'OM',
  /** Pakistan */
  Pk = 'PK',
  /** Palau */
  Pw = 'PW',
  /** Palestine, State of */
  Ps = 'PS',
  /** Panama */
  Pa = 'PA',
  /** Papua New Guinea */
  Pg = 'PG',
  /** Paraguay */
  Py = 'PY',
  /** Peru */
  Pe = 'PE',
  /** Philippines */
  Ph = 'PH',
  /** Pitcairn */
  Pn = 'PN',
  /** Poland */
  Pl = 'PL',
  /** Portugal */
  Pt = 'PT',
  /** Puerto Rico */
  Pr = 'PR',
  /** Qatar */
  Qa = 'QA',
  /** Réunion */
  Re = 'RE',
  /** Romania */
  Ro = 'RO',
  /** Russia */
  Ru = 'RU',
  /** Rwanda */
  Rw = 'RW',
  /** Saint Barthélemy */
  Bl = 'BL',
  /** Saint Helena, Ascension and Tristan da Cunha */
  Sh = 'SH',
  /** Saint Kitts and Nevis */
  Kn = 'KN',
  /** Saint Lucia */
  Lc = 'LC',
  /** Saint Martin (French part) */
  Mf = 'MF',
  /** Saint Pierre and Miquelon */
  Pm = 'PM',
  /** Saint Vincent and the Grenadines */
  Vc = 'VC',
  /** Samoa */
  Ws = 'WS',
  /** San Marino */
  Sm = 'SM',
  /** Sao Tome and Principe */
  St = 'ST',
  /** Saudi Arabia */
  Sa = 'SA',
  /** Senegal */
  Sn = 'SN',
  /** Serbia */
  Rs = 'RS',
  /** Seychelles */
  Sc = 'SC',
  /** Sierra Leone */
  Sl = 'SL',
  /** Singapore */
  Sg = 'SG',
  /** Sint Maarten (Dutch part) */
  Sx = 'SX',
  /** Slovakia */
  Sk = 'SK',
  /** Slovenia */
  Si = 'SI',
  /** Solomon Islands */
  Sb = 'SB',
  /** Somalia */
  So = 'SO',
  /** South Africa */
  Za = 'ZA',
  /** South Georgia and the South Sandwich Islands */
  Gs = 'GS',
  /** South Korea */
  Kr = 'KR',
  /** South Sudan */
  Ss = 'SS',
  /** Spain */
  Es = 'ES',
  /** Sri Lanka */
  Lk = 'LK',
  /** Sudan */
  Sd = 'SD',
  /** Suriname */
  Sr = 'SR',
  /** Svalbard and Jan Mayen */
  Sj = 'SJ',
  /** Sweden */
  Se = 'SE',
  /** Switzerland */
  Ch = 'CH',
  /** Syria */
  Sy = 'SY',
  /** Taiwan */
  Tw = 'TW',
  /** Tajikistan */
  Tj = 'TJ',
  /** Tanzania */
  Tz = 'TZ',
  /** Thailand */
  Th = 'TH',
  /** Timor-Leste */
  Tl = 'TL',
  /** Togo */
  Tg = 'TG',
  /** Tokelau */
  Tk = 'TK',
  /** Tonga */
  To = 'TO',
  /** Trinidad and Tobago */
  Tt = 'TT',
  /** Tunisia */
  Tn = 'TN',
  /** Turkey */
  Tr = 'TR',
  /** Turkmenistan */
  Tm = 'TM',
  /** Turks and Caicos Islands */
  Tc = 'TC',
  /** Tuvalu */
  Tv = 'TV',
  /** Uganda */
  Ug = 'UG',
  /** Ukraine */
  Ua = 'UA',
  /** United Arab Emirates */
  Ae = 'AE',
  /** United Kingdom */
  Gb = 'GB',
  /** United States Minor Outlying Islands */
  Um = 'UM',
  /** United States of America */
  Us = 'US',
  /** Uruguay */
  Uy = 'UY',
  /** Uzbekistan */
  Uz = 'UZ',
  /** Vanuatu */
  Vu = 'VU',
  /** Venezuela */
  Ve = 'VE',
  /** Vietnam */
  Vn = 'VN',
  /** Virgin Islands (British) */
  Vg = 'VG',
  /** Virgin Islands (U.S.) */
  Vi = 'VI',
  /** Wallis and Futuna */
  Wf = 'WF',
  /** Western Sahara */
  Eh = 'EH',
  /** Yemen */
  Ye = 'YE',
  /** Zambia */
  Zm = 'ZM',
  /** Zimbabwe */
  Zw = 'ZW'
}

/** An enumeration. */
export enum ProjectQuotationShippingCountry {
  /** Afghanistan */
  Af = 'AF',
  /** Åland Islands */
  Ax = 'AX',
  /** Albania */
  Al = 'AL',
  /** Algeria */
  Dz = 'DZ',
  /** American Samoa */
  As = 'AS',
  /** Andorra */
  Ad = 'AD',
  /** Angola */
  Ao = 'AO',
  /** Anguilla */
  Ai = 'AI',
  /** Antarctica */
  Aq = 'AQ',
  /** Antigua and Barbuda */
  Ag = 'AG',
  /** Argentina */
  Ar = 'AR',
  /** Armenia */
  Am = 'AM',
  /** Aruba */
  Aw = 'AW',
  /** Australia */
  Au = 'AU',
  /** Austria */
  At = 'AT',
  /** Azerbaijan */
  Az = 'AZ',
  /** Bahamas */
  Bs = 'BS',
  /** Bahrain */
  Bh = 'BH',
  /** Bangladesh */
  Bd = 'BD',
  /** Barbados */
  Bb = 'BB',
  /** Belarus */
  By = 'BY',
  /** Belgium */
  Be = 'BE',
  /** Belize */
  Bz = 'BZ',
  /** Benin */
  Bj = 'BJ',
  /** Bermuda */
  Bm = 'BM',
  /** Bhutan */
  Bt = 'BT',
  /** Bolivia */
  Bo = 'BO',
  /** Bonaire, Sint Eustatius and Saba */
  Bq = 'BQ',
  /** Bosnia and Herzegovina */
  Ba = 'BA',
  /** Botswana */
  Bw = 'BW',
  /** Bouvet Island */
  Bv = 'BV',
  /** Brazil */
  Br = 'BR',
  /** British Indian Ocean Territory */
  Io = 'IO',
  /** Brunei */
  Bn = 'BN',
  /** Bulgaria */
  Bg = 'BG',
  /** Burkina Faso */
  Bf = 'BF',
  /** Burundi */
  Bi = 'BI',
  /** Cabo Verde */
  Cv = 'CV',
  /** Cambodia */
  Kh = 'KH',
  /** Cameroon */
  Cm = 'CM',
  /** Canada */
  Ca = 'CA',
  /** Cayman Islands */
  Ky = 'KY',
  /** Central African Republic */
  Cf = 'CF',
  /** Chad */
  Td = 'TD',
  /** Chile */
  Cl = 'CL',
  /** China */
  Cn = 'CN',
  /** Christmas Island */
  Cx = 'CX',
  /** Cocos (Keeling) Islands */
  Cc = 'CC',
  /** Colombia */
  Co = 'CO',
  /** Comoros */
  Km = 'KM',
  /** Congo */
  Cg = 'CG',
  /** Congo (the Democratic Republic of the) */
  Cd = 'CD',
  /** Cook Islands */
  Ck = 'CK',
  /** Costa Rica */
  Cr = 'CR',
  /** Côte d'Ivoire */
  Ci = 'CI',
  /** Croatia */
  Hr = 'HR',
  /** Cuba */
  Cu = 'CU',
  /** Curaçao */
  Cw = 'CW',
  /** Cyprus */
  Cy = 'CY',
  /** Czechia */
  Cz = 'CZ',
  /** Denmark */
  Dk = 'DK',
  /** Djibouti */
  Dj = 'DJ',
  /** Dominica */
  Dm = 'DM',
  /** Dominican Republic */
  Do = 'DO',
  /** Ecuador */
  Ec = 'EC',
  /** Egypt */
  Eg = 'EG',
  /** El Salvador */
  Sv = 'SV',
  /** Equatorial Guinea */
  Gq = 'GQ',
  /** Eritrea */
  Er = 'ER',
  /** Estonia */
  Ee = 'EE',
  /** Eswatini */
  Sz = 'SZ',
  /** Ethiopia */
  Et = 'ET',
  /** Falkland Islands  [Malvinas] */
  Fk = 'FK',
  /** Faroe Islands */
  Fo = 'FO',
  /** Fiji */
  Fj = 'FJ',
  /** Finland */
  Fi = 'FI',
  /** France */
  Fr = 'FR',
  /** French Guiana */
  Gf = 'GF',
  /** French Polynesia */
  Pf = 'PF',
  /** French Southern Territories */
  Tf = 'TF',
  /** Gabon */
  Ga = 'GA',
  /** Gambia */
  Gm = 'GM',
  /** Georgia */
  Ge = 'GE',
  /** Germany */
  De = 'DE',
  /** Ghana */
  Gh = 'GH',
  /** Gibraltar */
  Gi = 'GI',
  /** Greece */
  Gr = 'GR',
  /** Greenland */
  Gl = 'GL',
  /** Grenada */
  Gd = 'GD',
  /** Guadeloupe */
  Gp = 'GP',
  /** Guam */
  Gu = 'GU',
  /** Guatemala */
  Gt = 'GT',
  /** Guernsey */
  Gg = 'GG',
  /** Guinea */
  Gn = 'GN',
  /** Guinea-Bissau */
  Gw = 'GW',
  /** Guyana */
  Gy = 'GY',
  /** Haiti */
  Ht = 'HT',
  /** Heard Island and McDonald Islands */
  Hm = 'HM',
  /** Holy See */
  Va = 'VA',
  /** Honduras */
  Hn = 'HN',
  /** Hong Kong */
  Hk = 'HK',
  /** Hungary */
  Hu = 'HU',
  /** Iceland */
  Is = 'IS',
  /** India */
  In = 'IN',
  /** Indonesia */
  Id = 'ID',
  /** Iran */
  Ir = 'IR',
  /** Iraq */
  Iq = 'IQ',
  /** Ireland */
  Ie = 'IE',
  /** Isle of Man */
  Im = 'IM',
  /** Israel */
  Il = 'IL',
  /** Italy */
  It = 'IT',
  /** Jamaica */
  Jm = 'JM',
  /** Japan */
  Jp = 'JP',
  /** Jersey */
  Je = 'JE',
  /** Jordan */
  Jo = 'JO',
  /** Kazakhstan */
  Kz = 'KZ',
  /** Kenya */
  Ke = 'KE',
  /** Kiribati */
  Ki = 'KI',
  /** Kuwait */
  Kw = 'KW',
  /** Kyrgyzstan */
  Kg = 'KG',
  /** Laos */
  La = 'LA',
  /** Latvia */
  Lv = 'LV',
  /** Lebanon */
  Lb = 'LB',
  /** Lesotho */
  Ls = 'LS',
  /** Liberia */
  Lr = 'LR',
  /** Libya */
  Ly = 'LY',
  /** Liechtenstein */
  Li = 'LI',
  /** Lithuania */
  Lt = 'LT',
  /** Luxembourg */
  Lu = 'LU',
  /** Macao */
  Mo = 'MO',
  /** Madagascar */
  Mg = 'MG',
  /** Malawi */
  Mw = 'MW',
  /** Malaysia */
  My = 'MY',
  /** Maldives */
  Mv = 'MV',
  /** Mali */
  Ml = 'ML',
  /** Malta */
  Mt = 'MT',
  /** Marshall Islands */
  Mh = 'MH',
  /** Martinique */
  Mq = 'MQ',
  /** Mauritania */
  Mr = 'MR',
  /** Mauritius */
  Mu = 'MU',
  /** Mayotte */
  Yt = 'YT',
  /** Mexico */
  Mx = 'MX',
  /** Micronesia (Federated States of) */
  Fm = 'FM',
  /** Moldova */
  Md = 'MD',
  /** Monaco */
  Mc = 'MC',
  /** Mongolia */
  Mn = 'MN',
  /** Montenegro */
  Me = 'ME',
  /** Montserrat */
  Ms = 'MS',
  /** Morocco */
  Ma = 'MA',
  /** Mozambique */
  Mz = 'MZ',
  /** Myanmar */
  Mm = 'MM',
  /** Namibia */
  Na = 'NA',
  /** Nauru */
  Nr = 'NR',
  /** Nepal */
  Np = 'NP',
  /** Netherlands */
  Nl = 'NL',
  /** New Caledonia */
  Nc = 'NC',
  /** New Zealand */
  Nz = 'NZ',
  /** Nicaragua */
  Ni = 'NI',
  /** Niger */
  Ne = 'NE',
  /** Nigeria */
  Ng = 'NG',
  /** Niue */
  Nu = 'NU',
  /** Norfolk Island */
  Nf = 'NF',
  /** North Korea */
  Kp = 'KP',
  /** North Macedonia */
  Mk = 'MK',
  /** Northern Mariana Islands */
  Mp = 'MP',
  /** Norway */
  No = 'NO',
  /** Oman */
  Om = 'OM',
  /** Pakistan */
  Pk = 'PK',
  /** Palau */
  Pw = 'PW',
  /** Palestine, State of */
  Ps = 'PS',
  /** Panama */
  Pa = 'PA',
  /** Papua New Guinea */
  Pg = 'PG',
  /** Paraguay */
  Py = 'PY',
  /** Peru */
  Pe = 'PE',
  /** Philippines */
  Ph = 'PH',
  /** Pitcairn */
  Pn = 'PN',
  /** Poland */
  Pl = 'PL',
  /** Portugal */
  Pt = 'PT',
  /** Puerto Rico */
  Pr = 'PR',
  /** Qatar */
  Qa = 'QA',
  /** Réunion */
  Re = 'RE',
  /** Romania */
  Ro = 'RO',
  /** Russia */
  Ru = 'RU',
  /** Rwanda */
  Rw = 'RW',
  /** Saint Barthélemy */
  Bl = 'BL',
  /** Saint Helena, Ascension and Tristan da Cunha */
  Sh = 'SH',
  /** Saint Kitts and Nevis */
  Kn = 'KN',
  /** Saint Lucia */
  Lc = 'LC',
  /** Saint Martin (French part) */
  Mf = 'MF',
  /** Saint Pierre and Miquelon */
  Pm = 'PM',
  /** Saint Vincent and the Grenadines */
  Vc = 'VC',
  /** Samoa */
  Ws = 'WS',
  /** San Marino */
  Sm = 'SM',
  /** Sao Tome and Principe */
  St = 'ST',
  /** Saudi Arabia */
  Sa = 'SA',
  /** Senegal */
  Sn = 'SN',
  /** Serbia */
  Rs = 'RS',
  /** Seychelles */
  Sc = 'SC',
  /** Sierra Leone */
  Sl = 'SL',
  /** Singapore */
  Sg = 'SG',
  /** Sint Maarten (Dutch part) */
  Sx = 'SX',
  /** Slovakia */
  Sk = 'SK',
  /** Slovenia */
  Si = 'SI',
  /** Solomon Islands */
  Sb = 'SB',
  /** Somalia */
  So = 'SO',
  /** South Africa */
  Za = 'ZA',
  /** South Georgia and the South Sandwich Islands */
  Gs = 'GS',
  /** South Korea */
  Kr = 'KR',
  /** South Sudan */
  Ss = 'SS',
  /** Spain */
  Es = 'ES',
  /** Sri Lanka */
  Lk = 'LK',
  /** Sudan */
  Sd = 'SD',
  /** Suriname */
  Sr = 'SR',
  /** Svalbard and Jan Mayen */
  Sj = 'SJ',
  /** Sweden */
  Se = 'SE',
  /** Switzerland */
  Ch = 'CH',
  /** Syria */
  Sy = 'SY',
  /** Taiwan */
  Tw = 'TW',
  /** Tajikistan */
  Tj = 'TJ',
  /** Tanzania */
  Tz = 'TZ',
  /** Thailand */
  Th = 'TH',
  /** Timor-Leste */
  Tl = 'TL',
  /** Togo */
  Tg = 'TG',
  /** Tokelau */
  Tk = 'TK',
  /** Tonga */
  To = 'TO',
  /** Trinidad and Tobago */
  Tt = 'TT',
  /** Tunisia */
  Tn = 'TN',
  /** Turkey */
  Tr = 'TR',
  /** Turkmenistan */
  Tm = 'TM',
  /** Turks and Caicos Islands */
  Tc = 'TC',
  /** Tuvalu */
  Tv = 'TV',
  /** Uganda */
  Ug = 'UG',
  /** Ukraine */
  Ua = 'UA',
  /** United Arab Emirates */
  Ae = 'AE',
  /** United Kingdom */
  Gb = 'GB',
  /** United States Minor Outlying Islands */
  Um = 'UM',
  /** United States of America */
  Us = 'US',
  /** Uruguay */
  Uy = 'UY',
  /** Uzbekistan */
  Uz = 'UZ',
  /** Vanuatu */
  Vu = 'VU',
  /** Venezuela */
  Ve = 'VE',
  /** Vietnam */
  Vn = 'VN',
  /** Virgin Islands (British) */
  Vg = 'VG',
  /** Virgin Islands (U.S.) */
  Vi = 'VI',
  /** Wallis and Futuna */
  Wf = 'WF',
  /** Western Sahara */
  Eh = 'EH',
  /** Yemen */
  Ye = 'YE',
  /** Zambia */
  Zm = 'ZM',
  /** Zimbabwe */
  Zw = 'ZW'
}

/** GQL Type for PROJECT_STATUS constant */
export type ProjectStatusType = {
  __typename?: 'ProjectStatusType';
  code: Scalars['String'];
  name: Scalars['String'];
};

/** GQL Type for the Project model. */
export type ProjectType = {
  __typename?: 'ProjectType';
  id: Scalars['ID'];
  hashId: Scalars['String'];
  wizardStep: Scalars['Int'];
  status?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  titleInfo: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  user?: Maybe<UserType>;
  sharedUsers?: Maybe<Array<Maybe<UserType>>>;
  client?: Maybe<ClientType>;
  country: ProjectCountry;
  city: Scalars['String'];
  postalCode: Scalars['String'];
  projectType?: Maybe<Array<Maybe<ProjectTypeType>>>;
  projectTypeOther?: Maybe<Scalars['String']>;
  projectTypeInfo: Scalars['String'];
  timelineStart?: Maybe<Scalars['Date']>;
  timelineEnd?: Maybe<Scalars['Date']>;
  timelineUnsure: Scalars['Boolean'];
  timelineInfo: Scalars['String'];
  budget?: Maybe<Scalars['Int']>;
  budgetCurrency?: Maybe<ProjectBudgetCurrency>;
  budgetUnsure: Scalars['Boolean'];
  budgetInfo: Scalars['String'];
  locationCount?: Maybe<Scalars['Int']>;
  locationUnsure: Scalars['Boolean'];
  locationInfo: Scalars['String'];
  info: Scalars['String'];
  tags?: Maybe<Scalars['String']>;
  deleted: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  wfNwFramers?: Maybe<Array<Maybe<FramerType>>>;
  quotationInstallationRequired: Scalars['Boolean'];
  quotationComments: Scalars['String'];
  quotationShippingName: Scalars['String'];
  quotationShippingAddress: Scalars['String'];
  quotationShippingCity: Scalars['String'];
  quotationShippingState: Scalars['String'];
  quotationShippingZip: Scalars['String'];
  quotationShippingCountry: ProjectQuotationShippingCountry;
  quotationBillingName: Scalars['String'];
  quotationBillingAddress: Scalars['String'];
  quotationBillingCity: Scalars['String'];
  quotationBillingState: Scalars['String'];
  quotationBillingZip: Scalars['String'];
  quotationBillingCountry: ProjectQuotationBillingCountry;
  artworkProposalRequestedAt?: Maybe<Scalars['DateTime']>;
  artworkProposalSentAt?: Maybe<Scalars['DateTime']>;
  pdfTitle: Scalars['String'];
  pdfClient: Scalars['String'];
  pdfLocation: Scalars['String'];
  pdfDate: Scalars['String'];
  pdfImage: Scalars['String'];
  pdfImageSize: Scalars['String'];
  pdfActive: Scalars['Boolean'];
  pdfVertical: Scalars['Boolean'];
  pdfEmail: Scalars['String'];
  pdfAddr: Scalars['String'];
  pdfSite: Scalars['String'];
  pdfLogo?: Maybe<Scalars['String']>;
  pdfHideTitle: Scalars['Boolean'];
  pdfHideArtist: Scalars['Boolean'];
  pdfHidePrice: Scalars['Boolean'];
  pdfHideShipping: Scalars['Boolean'];
  pdfHideAreaDetails: Scalars['Boolean'];
  pdfHideLogo: Scalars['Boolean'];
  quotations?: Maybe<Array<Maybe<QuotationType>>>;
  locations: Array<LocationType>;
  vimrImages: Array<ProjectVimrImageType>;
  signupkeys?: Maybe<Array<Maybe<SignupKeyType>>>;
  variants?: Maybe<Array<Maybe<ArtistVariantType>>>;
  proposals?: Maybe<Array<Maybe<PdfProposalType>>>;
  recentprojectSet?: Maybe<Array<Maybe<RecentProjectType>>>;
  activeLocations: Array<LocationType>;
  locationsCount?: Maybe<Scalars['Int']>;
  itemsCount?: Maybe<Scalars['Int']>;
  bucketItemsCount?: Maybe<Scalars['Int']>;
  selectionsCount?: Maybe<Scalars['Int']>;
  countryName?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  timelineEndDay?: Maybe<Scalars['String']>;
  timelineEndMonth?: Maybe<Scalars['String']>;
  timelineEndYear?: Maybe<Scalars['String']>;
  wizardStepIsReady?: Maybe<Scalars['Boolean']>;
  isQuickSourced?: Maybe<Scalars['Boolean']>;
  budgetUsd?: Maybe<Scalars['Float']>;
  projectTypesStr: Scalars['String'];
  quotationShippingCountryName?: Maybe<Scalars['String']>;
  quotationShippingCountryCode?: Maybe<Scalars['String']>;
  quotationBillingCountryName?: Maybe<Scalars['String']>;
  quotationBillingCountryCode?: Maybe<Scalars['String']>;
  statusComputed?: Maybe<Scalars['String']>;
  latestQuotation?: Maybe<QuotationType>;
  allImages: Array<Maybe<Scalars['String']>>;
  unusedSignupkeys: Array<Maybe<SignupKeyType>>;
};

/** GQL Type for the Location model. */
export type ProjectTypeType = {
  __typename?: 'ProjectTypeType';
  id: Scalars['ID'];
  title: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  iconSlug?: Maybe<Scalars['String']>;
  projects?: Maybe<Array<Maybe<ProjectType>>>;
};

/** GQL Type for the ProjectVIMRImage model. */
export type ProjectVimrImageType = {
  __typename?: 'ProjectVIMRImageType';
  id: Scalars['ID'];
  locationItem: LocationItemType;
  location: LocationType;
  project: ProjectType;
  productIds: Scalars['String'];
  bucketItems?: Maybe<Array<Maybe<BucketItemType>>>;
  image?: Maybe<Scalars['String']>;
  imageFilename: Scalars['String'];
  deleted: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type Queries = {
  __typename?: 'Queries';
  wfNwFramerRfqData?: Maybe<FramerGlobalQuotationType>;
  wfStaffFramerQuotations: Array<Maybe<FramerGlobalQuotationType>>;
  wfStaffFlowItem?: Maybe<FlowItemArtworkType>;
  wfNormalWorksStepsData?: Maybe<NormalWorksProjectSteps>;
  wfSellerConfirmAvailabilityData?: Maybe<FlowItemArtworkConfirmAvailabilityType>;
  wfSelectedWorks: Array<NormalWorksLocation>;
  wfCommissionedWorksSellerConfirmAvailabilityData: Array<FlowItemCommissionedWorkConfirmAvailabilityType>;
  wfStaffCommissionedWorksFlowItem?: Maybe<FlowItemCommissionedWorkType>;
  wfCmSelectedWorks: Array<CommissionedWorksLocation>;
  wfCommissionedWorksStepsData?: Maybe<CommissionedWorksProjectSteps>;
  currentProfile?: Maybe<UserProfileType>;
  staffQuotations?: Maybe<Array<QuotationType>>;
  quotation?: Maybe<QuotationType>;
  staticImage?: Maybe<StaticImageType>;
  tags: Array<Scalars['String']>;
  mediums: Array<MediumType>;
  styles: Array<StyleType>;
  artists: ArtistSourcingPaginatedType;
  artistsTypesense: Array<ArtistDetailType>;
  galleries: GallerySourcingPaginatedType;
  galleriesTypesense: Array<GalleryDetailType>;
  artistsAll: Array<ArtistSourcingType>;
  galleriesAll: Array<GallerySourcingType>;
  artistVariants: ArtistVariantPaginatedType;
  artist?: Maybe<ArtistSourcingType>;
  artistVariant?: Maybe<ArtistVariantType>;
  gallery?: Maybe<GallerySourcingType>;
  projectStatuses: Array<ProjectStatusType>;
  recentProjects?: Maybe<Array<RecentProjectType>>;
  getProposal?: Maybe<PdfProposalType>;
  getPdfPage?: Maybe<PdfPageType>;
  getProjectProposals: Array<PdfProposalType>;
  categories?: Maybe<Array<CategoryType>>;
  attr1s?: Maybe<Array<Attribute1Type>>;
  attr2s?: Maybe<Array<Attribute2Type>>;
  projectTypes?: Maybe<Array<ProjectTypeType>>;
  projectCurrencies?: Maybe<Array<Scalars['String']>>;
  locationItem?: Maybe<LocationItemType>;
  getLocation?: Maybe<LocationType>;
  location?: Maybe<LocationType>;
  locations?: Maybe<Array<LocationType>>;
  projects: ProjectAggrType;
  staffLocations?: Maybe<Array<LocationType>>;
  staffLocationItems?: Maybe<Array<LocationItemType>>;
  staffLocationItem?: Maybe<LocationItemType>;
  locationItemTypes: Array<LocationItemTypeType>;
  getFloorplan?: Maybe<FloorPlanType>;
  projectsWithFlowItems: ProjectPaginatedType;
  staffProject?: Maybe<ProjectType>;
  project?: Maybe<ProjectType>;
  getProducts?: Maybe<ProductPaginatedType>;
  getProduct?: Maybe<ProductType>;
  getProductSameArtist?: Maybe<ProductPaginatedType>;
  getSimilarImage?: Maybe<Scalars['String']>;
  inquiryTypes: Array<InquiryTypeType>;
  getInquiry?: Maybe<InquiryType>;
  getInquiries?: Maybe<Array<InquiryType>>;
  cacheImg?: Maybe<ImageCacheType>;
  getFrameMaterials: Array<FrameMaterialType>;
  framer: FramerType;
  framers: Array<FramerType>;
  getFilters?: Maybe<Scalars['String']>;
  getFeedback?: Maybe<FeedbackType>;
  clients: Array<ClientType>;
  client?: Maybe<ClientType>;
  bucketItems?: Maybe<Array<BucketItemType>>;
  getBucketItem?: Maybe<BucketItemType>;
  staffLocationItemBucketItems?: Maybe<Array<ProductAndItemType>>;
  staffGetLocationItemsForProduct?: Maybe<Array<BucketItemType>>;
  getExistingWorksForProject: Array<BucketItemType>;
  getCommissionedWorksForProject?: Maybe<Array<BucketItemType>>;
  getProductsForLocationItem?: Maybe<Array<ProductAndItemType>>;
  getProductsForProject?: Maybe<Array<ProductAndItemType>>;
  selectedBucketItems?: Maybe<Array<ProductAndItemType>>;
  dummyQuery?: Maybe<Scalars['String']>;
  countries: Array<CountryType>;
  typeOfIndustries: Array<TypeOfIndustryType>;
};


export type QueriesWfNwFramerRfqDataArgs = {
  uuid?: Maybe<Scalars['String']>;
  framerHashId?: Maybe<Scalars['String']>;
};


export type QueriesWfStaffFramerQuotationsArgs = {
  uuid?: Maybe<Scalars['String']>;
};


export type QueriesWfStaffFlowItemArgs = {
  flowItemId?: Maybe<Scalars['String']>;
};


export type QueriesWfNormalWorksStepsDataArgs = {
  data?: Maybe<Scalars['String']>;
};


export type QueriesWfSellerConfirmAvailabilityDataArgs = {
  uuid?: Maybe<Scalars['String']>;
};


export type QueriesWfSelectedWorksArgs = {
  hashId?: Maybe<Scalars['String']>;
  stepsFilter?: Maybe<Scalars['String']>;
};


export type QueriesWfCommissionedWorksSellerConfirmAvailabilityDataArgs = {
  uuid?: Maybe<Scalars['String']>;
};


export type QueriesWfStaffCommissionedWorksFlowItemArgs = {
  flowItemId?: Maybe<Scalars['String']>;
};


export type QueriesWfCmSelectedWorksArgs = {
  hashId?: Maybe<Scalars['String']>;
};


export type QueriesWfCommissionedWorksStepsDataArgs = {
  data?: Maybe<Scalars['String']>;
};


export type QueriesQuotationArgs = {
  hashId?: Maybe<Scalars['String']>;
};


export type QueriesStaticImageArgs = {
  identifier?: Maybe<Scalars['String']>;
};


export type QueriesArtistsArgs = {
  filters?: Maybe<Scalars['String']>;
};


export type QueriesArtistsTypesenseArgs = {
  search?: Maybe<Scalars['String']>;
};


export type QueriesGalleriesArgs = {
  filters?: Maybe<Scalars['String']>;
};


export type QueriesGalleriesTypesenseArgs = {
  search?: Maybe<Scalars['String']>;
};


export type QueriesArtistVariantsArgs = {
  filters?: Maybe<Scalars['String']>;
};


export type QueriesArtistArgs = {
  artistId?: Maybe<Scalars['String']>;
};


export type QueriesArtistVariantArgs = {
  varId?: Maybe<Scalars['String']>;
};


export type QueriesGalleryArgs = {
  galleryId?: Maybe<Scalars['String']>;
};


export type QueriesGetProposalArgs = {
  proposalId?: Maybe<Scalars['String']>;
};


export type QueriesGetPdfPageArgs = {
  pageId?: Maybe<Scalars['String']>;
};


export type QueriesGetProjectProposalsArgs = {
  hashId?: Maybe<Scalars['String']>;
};


export type QueriesAttr1sArgs = {
  category?: Maybe<Scalars['String']>;
};


export type QueriesAttr2sArgs = {
  attr1?: Maybe<Scalars['String']>;
};


export type QueriesLocationItemArgs = {
  hashId?: Maybe<Scalars['String']>;
  locId?: Maybe<Scalars['String']>;
  locItemId?: Maybe<Scalars['String']>;
  originalId?: Maybe<Scalars['Boolean']>;
};


export type QueriesGetLocationArgs = {
  hashId?: Maybe<Scalars['String']>;
  locId?: Maybe<Scalars['String']>;
};


export type QueriesLocationArgs = {
  locationId?: Maybe<Scalars['String']>;
};


export type QueriesLocationsArgs = {
  projectId?: Maybe<Scalars['String']>;
};


export type QueriesProjectsArgs = {
  filters?: Maybe<Scalars['String']>;
};


export type QueriesStaffLocationsArgs = {
  projectId?: Maybe<Scalars['String']>;
};


export type QueriesStaffLocationItemsArgs = {
  locationId?: Maybe<Scalars['String']>;
};


export type QueriesStaffLocationItemArgs = {
  locationItemId?: Maybe<Scalars['String']>;
};


export type QueriesGetFloorplanArgs = {
  floorplanId?: Maybe<Scalars['String']>;
};


export type QueriesProjectsWithFlowItemsArgs = {
  data?: Maybe<Scalars['String']>;
};


export type QueriesStaffProjectArgs = {
  hashId?: Maybe<Scalars['String']>;
};


export type QueriesProjectArgs = {
  hashId?: Maybe<Scalars['String']>;
  curWizardStep?: Maybe<Scalars['String']>;
};


export type QueriesGetProductsArgs = {
  filters?: Maybe<Scalars['String']>;
  locDetails?: Maybe<Scalars['String']>;
  favorites?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type QueriesGetProductArgs = {
  esId?: Maybe<Scalars['String']>;
};


export type QueriesGetProductSameArtistArgs = {
  esId?: Maybe<Scalars['String']>;
};


export type QueriesGetSimilarImageArgs = {
  queryString?: Maybe<Scalars['String']>;
};


export type QueriesGetInquiryArgs = {
  inquiryId?: Maybe<Scalars['String']>;
};


export type QueriesCacheImgArgs = {
  imgHash?: Maybe<Scalars['String']>;
};


export type QueriesFramerArgs = {
  framerId?: Maybe<Scalars['String']>;
};


export type QueriesGetFiltersArgs = {
  locDetails?: Maybe<Scalars['String']>;
};


export type QueriesGetFeedbackArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueriesClientArgs = {
  clientId?: Maybe<Scalars['String']>;
};


export type QueriesBucketItemsArgs = {
  locationId?: Maybe<Scalars['String']>;
};


export type QueriesGetBucketItemArgs = {
  bucketItemId?: Maybe<Scalars['String']>;
};


export type QueriesStaffLocationItemBucketItemsArgs = {
  locationItemId?: Maybe<Scalars['String']>;
};


export type QueriesStaffGetLocationItemsForProductArgs = {
  esId?: Maybe<Scalars['String']>;
};


export type QueriesGetExistingWorksForProjectArgs = {
  hashId?: Maybe<Scalars['String']>;
};


export type QueriesGetCommissionedWorksForProjectArgs = {
  hashId?: Maybe<Scalars['String']>;
};


export type QueriesGetProductsForLocationItemArgs = {
  hashId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  locationItemId?: Maybe<Scalars['String']>;
};


export type QueriesGetProductsForProjectArgs = {
  hashId?: Maybe<Scalars['String']>;
};


export type QueriesSelectedBucketItemsArgs = {
  hashId?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum QuotationBillingCountry {
  /** Afghanistan */
  Af = 'AF',
  /** Åland Islands */
  Ax = 'AX',
  /** Albania */
  Al = 'AL',
  /** Algeria */
  Dz = 'DZ',
  /** American Samoa */
  As = 'AS',
  /** Andorra */
  Ad = 'AD',
  /** Angola */
  Ao = 'AO',
  /** Anguilla */
  Ai = 'AI',
  /** Antarctica */
  Aq = 'AQ',
  /** Antigua and Barbuda */
  Ag = 'AG',
  /** Argentina */
  Ar = 'AR',
  /** Armenia */
  Am = 'AM',
  /** Aruba */
  Aw = 'AW',
  /** Australia */
  Au = 'AU',
  /** Austria */
  At = 'AT',
  /** Azerbaijan */
  Az = 'AZ',
  /** Bahamas */
  Bs = 'BS',
  /** Bahrain */
  Bh = 'BH',
  /** Bangladesh */
  Bd = 'BD',
  /** Barbados */
  Bb = 'BB',
  /** Belarus */
  By = 'BY',
  /** Belgium */
  Be = 'BE',
  /** Belize */
  Bz = 'BZ',
  /** Benin */
  Bj = 'BJ',
  /** Bermuda */
  Bm = 'BM',
  /** Bhutan */
  Bt = 'BT',
  /** Bolivia */
  Bo = 'BO',
  /** Bonaire, Sint Eustatius and Saba */
  Bq = 'BQ',
  /** Bosnia and Herzegovina */
  Ba = 'BA',
  /** Botswana */
  Bw = 'BW',
  /** Bouvet Island */
  Bv = 'BV',
  /** Brazil */
  Br = 'BR',
  /** British Indian Ocean Territory */
  Io = 'IO',
  /** Brunei */
  Bn = 'BN',
  /** Bulgaria */
  Bg = 'BG',
  /** Burkina Faso */
  Bf = 'BF',
  /** Burundi */
  Bi = 'BI',
  /** Cabo Verde */
  Cv = 'CV',
  /** Cambodia */
  Kh = 'KH',
  /** Cameroon */
  Cm = 'CM',
  /** Canada */
  Ca = 'CA',
  /** Cayman Islands */
  Ky = 'KY',
  /** Central African Republic */
  Cf = 'CF',
  /** Chad */
  Td = 'TD',
  /** Chile */
  Cl = 'CL',
  /** China */
  Cn = 'CN',
  /** Christmas Island */
  Cx = 'CX',
  /** Cocos (Keeling) Islands */
  Cc = 'CC',
  /** Colombia */
  Co = 'CO',
  /** Comoros */
  Km = 'KM',
  /** Congo */
  Cg = 'CG',
  /** Congo (the Democratic Republic of the) */
  Cd = 'CD',
  /** Cook Islands */
  Ck = 'CK',
  /** Costa Rica */
  Cr = 'CR',
  /** Côte d'Ivoire */
  Ci = 'CI',
  /** Croatia */
  Hr = 'HR',
  /** Cuba */
  Cu = 'CU',
  /** Curaçao */
  Cw = 'CW',
  /** Cyprus */
  Cy = 'CY',
  /** Czechia */
  Cz = 'CZ',
  /** Denmark */
  Dk = 'DK',
  /** Djibouti */
  Dj = 'DJ',
  /** Dominica */
  Dm = 'DM',
  /** Dominican Republic */
  Do = 'DO',
  /** Ecuador */
  Ec = 'EC',
  /** Egypt */
  Eg = 'EG',
  /** El Salvador */
  Sv = 'SV',
  /** Equatorial Guinea */
  Gq = 'GQ',
  /** Eritrea */
  Er = 'ER',
  /** Estonia */
  Ee = 'EE',
  /** Eswatini */
  Sz = 'SZ',
  /** Ethiopia */
  Et = 'ET',
  /** Falkland Islands  [Malvinas] */
  Fk = 'FK',
  /** Faroe Islands */
  Fo = 'FO',
  /** Fiji */
  Fj = 'FJ',
  /** Finland */
  Fi = 'FI',
  /** France */
  Fr = 'FR',
  /** French Guiana */
  Gf = 'GF',
  /** French Polynesia */
  Pf = 'PF',
  /** French Southern Territories */
  Tf = 'TF',
  /** Gabon */
  Ga = 'GA',
  /** Gambia */
  Gm = 'GM',
  /** Georgia */
  Ge = 'GE',
  /** Germany */
  De = 'DE',
  /** Ghana */
  Gh = 'GH',
  /** Gibraltar */
  Gi = 'GI',
  /** Greece */
  Gr = 'GR',
  /** Greenland */
  Gl = 'GL',
  /** Grenada */
  Gd = 'GD',
  /** Guadeloupe */
  Gp = 'GP',
  /** Guam */
  Gu = 'GU',
  /** Guatemala */
  Gt = 'GT',
  /** Guernsey */
  Gg = 'GG',
  /** Guinea */
  Gn = 'GN',
  /** Guinea-Bissau */
  Gw = 'GW',
  /** Guyana */
  Gy = 'GY',
  /** Haiti */
  Ht = 'HT',
  /** Heard Island and McDonald Islands */
  Hm = 'HM',
  /** Holy See */
  Va = 'VA',
  /** Honduras */
  Hn = 'HN',
  /** Hong Kong */
  Hk = 'HK',
  /** Hungary */
  Hu = 'HU',
  /** Iceland */
  Is = 'IS',
  /** India */
  In = 'IN',
  /** Indonesia */
  Id = 'ID',
  /** Iran */
  Ir = 'IR',
  /** Iraq */
  Iq = 'IQ',
  /** Ireland */
  Ie = 'IE',
  /** Isle of Man */
  Im = 'IM',
  /** Israel */
  Il = 'IL',
  /** Italy */
  It = 'IT',
  /** Jamaica */
  Jm = 'JM',
  /** Japan */
  Jp = 'JP',
  /** Jersey */
  Je = 'JE',
  /** Jordan */
  Jo = 'JO',
  /** Kazakhstan */
  Kz = 'KZ',
  /** Kenya */
  Ke = 'KE',
  /** Kiribati */
  Ki = 'KI',
  /** Kuwait */
  Kw = 'KW',
  /** Kyrgyzstan */
  Kg = 'KG',
  /** Laos */
  La = 'LA',
  /** Latvia */
  Lv = 'LV',
  /** Lebanon */
  Lb = 'LB',
  /** Lesotho */
  Ls = 'LS',
  /** Liberia */
  Lr = 'LR',
  /** Libya */
  Ly = 'LY',
  /** Liechtenstein */
  Li = 'LI',
  /** Lithuania */
  Lt = 'LT',
  /** Luxembourg */
  Lu = 'LU',
  /** Macao */
  Mo = 'MO',
  /** Madagascar */
  Mg = 'MG',
  /** Malawi */
  Mw = 'MW',
  /** Malaysia */
  My = 'MY',
  /** Maldives */
  Mv = 'MV',
  /** Mali */
  Ml = 'ML',
  /** Malta */
  Mt = 'MT',
  /** Marshall Islands */
  Mh = 'MH',
  /** Martinique */
  Mq = 'MQ',
  /** Mauritania */
  Mr = 'MR',
  /** Mauritius */
  Mu = 'MU',
  /** Mayotte */
  Yt = 'YT',
  /** Mexico */
  Mx = 'MX',
  /** Micronesia (Federated States of) */
  Fm = 'FM',
  /** Moldova */
  Md = 'MD',
  /** Monaco */
  Mc = 'MC',
  /** Mongolia */
  Mn = 'MN',
  /** Montenegro */
  Me = 'ME',
  /** Montserrat */
  Ms = 'MS',
  /** Morocco */
  Ma = 'MA',
  /** Mozambique */
  Mz = 'MZ',
  /** Myanmar */
  Mm = 'MM',
  /** Namibia */
  Na = 'NA',
  /** Nauru */
  Nr = 'NR',
  /** Nepal */
  Np = 'NP',
  /** Netherlands */
  Nl = 'NL',
  /** New Caledonia */
  Nc = 'NC',
  /** New Zealand */
  Nz = 'NZ',
  /** Nicaragua */
  Ni = 'NI',
  /** Niger */
  Ne = 'NE',
  /** Nigeria */
  Ng = 'NG',
  /** Niue */
  Nu = 'NU',
  /** Norfolk Island */
  Nf = 'NF',
  /** North Korea */
  Kp = 'KP',
  /** North Macedonia */
  Mk = 'MK',
  /** Northern Mariana Islands */
  Mp = 'MP',
  /** Norway */
  No = 'NO',
  /** Oman */
  Om = 'OM',
  /** Pakistan */
  Pk = 'PK',
  /** Palau */
  Pw = 'PW',
  /** Palestine, State of */
  Ps = 'PS',
  /** Panama */
  Pa = 'PA',
  /** Papua New Guinea */
  Pg = 'PG',
  /** Paraguay */
  Py = 'PY',
  /** Peru */
  Pe = 'PE',
  /** Philippines */
  Ph = 'PH',
  /** Pitcairn */
  Pn = 'PN',
  /** Poland */
  Pl = 'PL',
  /** Portugal */
  Pt = 'PT',
  /** Puerto Rico */
  Pr = 'PR',
  /** Qatar */
  Qa = 'QA',
  /** Réunion */
  Re = 'RE',
  /** Romania */
  Ro = 'RO',
  /** Russia */
  Ru = 'RU',
  /** Rwanda */
  Rw = 'RW',
  /** Saint Barthélemy */
  Bl = 'BL',
  /** Saint Helena, Ascension and Tristan da Cunha */
  Sh = 'SH',
  /** Saint Kitts and Nevis */
  Kn = 'KN',
  /** Saint Lucia */
  Lc = 'LC',
  /** Saint Martin (French part) */
  Mf = 'MF',
  /** Saint Pierre and Miquelon */
  Pm = 'PM',
  /** Saint Vincent and the Grenadines */
  Vc = 'VC',
  /** Samoa */
  Ws = 'WS',
  /** San Marino */
  Sm = 'SM',
  /** Sao Tome and Principe */
  St = 'ST',
  /** Saudi Arabia */
  Sa = 'SA',
  /** Senegal */
  Sn = 'SN',
  /** Serbia */
  Rs = 'RS',
  /** Seychelles */
  Sc = 'SC',
  /** Sierra Leone */
  Sl = 'SL',
  /** Singapore */
  Sg = 'SG',
  /** Sint Maarten (Dutch part) */
  Sx = 'SX',
  /** Slovakia */
  Sk = 'SK',
  /** Slovenia */
  Si = 'SI',
  /** Solomon Islands */
  Sb = 'SB',
  /** Somalia */
  So = 'SO',
  /** South Africa */
  Za = 'ZA',
  /** South Georgia and the South Sandwich Islands */
  Gs = 'GS',
  /** South Korea */
  Kr = 'KR',
  /** South Sudan */
  Ss = 'SS',
  /** Spain */
  Es = 'ES',
  /** Sri Lanka */
  Lk = 'LK',
  /** Sudan */
  Sd = 'SD',
  /** Suriname */
  Sr = 'SR',
  /** Svalbard and Jan Mayen */
  Sj = 'SJ',
  /** Sweden */
  Se = 'SE',
  /** Switzerland */
  Ch = 'CH',
  /** Syria */
  Sy = 'SY',
  /** Taiwan */
  Tw = 'TW',
  /** Tajikistan */
  Tj = 'TJ',
  /** Tanzania */
  Tz = 'TZ',
  /** Thailand */
  Th = 'TH',
  /** Timor-Leste */
  Tl = 'TL',
  /** Togo */
  Tg = 'TG',
  /** Tokelau */
  Tk = 'TK',
  /** Tonga */
  To = 'TO',
  /** Trinidad and Tobago */
  Tt = 'TT',
  /** Tunisia */
  Tn = 'TN',
  /** Turkey */
  Tr = 'TR',
  /** Turkmenistan */
  Tm = 'TM',
  /** Turks and Caicos Islands */
  Tc = 'TC',
  /** Tuvalu */
  Tv = 'TV',
  /** Uganda */
  Ug = 'UG',
  /** Ukraine */
  Ua = 'UA',
  /** United Arab Emirates */
  Ae = 'AE',
  /** United Kingdom */
  Gb = 'GB',
  /** United States Minor Outlying Islands */
  Um = 'UM',
  /** United States of America */
  Us = 'US',
  /** Uruguay */
  Uy = 'UY',
  /** Uzbekistan */
  Uz = 'UZ',
  /** Vanuatu */
  Vu = 'VU',
  /** Venezuela */
  Ve = 'VE',
  /** Vietnam */
  Vn = 'VN',
  /** Virgin Islands (British) */
  Vg = 'VG',
  /** Virgin Islands (U.S.) */
  Vi = 'VI',
  /** Wallis and Futuna */
  Wf = 'WF',
  /** Western Sahara */
  Eh = 'EH',
  /** Yemen */
  Ye = 'YE',
  /** Zambia */
  Zm = 'ZM',
  /** Zimbabwe */
  Zw = 'ZW'
}

/** GQL Type for the QuotationItem model. */
export type QuotationItemType = {
  __typename?: 'QuotationItemType';
  id: Scalars['ID'];
  quotation: QuotationType;
  locationItem?: Maybe<LocationItemType>;
  locationItemTitle: Scalars['String'];
  locationTitle: Scalars['String'];
  bucketItemCustomText: Scalars['String'];
  bucketItemSelectedSize: Scalars['String'];
  productImage: Scalars['String'];
  productTitle: Scalars['String'];
  artistName: Scalars['String'];
  price: Scalars['String'];
  quantity?: Maybe<Scalars['Int']>;
  framingRequired: Scalars['Boolean'];
  framingComments: Scalars['String'];
  additionalComments: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

/** An enumeration. */
export enum QuotationProjectCountry {
  /** Afghanistan */
  Af = 'AF',
  /** Åland Islands */
  Ax = 'AX',
  /** Albania */
  Al = 'AL',
  /** Algeria */
  Dz = 'DZ',
  /** American Samoa */
  As = 'AS',
  /** Andorra */
  Ad = 'AD',
  /** Angola */
  Ao = 'AO',
  /** Anguilla */
  Ai = 'AI',
  /** Antarctica */
  Aq = 'AQ',
  /** Antigua and Barbuda */
  Ag = 'AG',
  /** Argentina */
  Ar = 'AR',
  /** Armenia */
  Am = 'AM',
  /** Aruba */
  Aw = 'AW',
  /** Australia */
  Au = 'AU',
  /** Austria */
  At = 'AT',
  /** Azerbaijan */
  Az = 'AZ',
  /** Bahamas */
  Bs = 'BS',
  /** Bahrain */
  Bh = 'BH',
  /** Bangladesh */
  Bd = 'BD',
  /** Barbados */
  Bb = 'BB',
  /** Belarus */
  By = 'BY',
  /** Belgium */
  Be = 'BE',
  /** Belize */
  Bz = 'BZ',
  /** Benin */
  Bj = 'BJ',
  /** Bermuda */
  Bm = 'BM',
  /** Bhutan */
  Bt = 'BT',
  /** Bolivia */
  Bo = 'BO',
  /** Bonaire, Sint Eustatius and Saba */
  Bq = 'BQ',
  /** Bosnia and Herzegovina */
  Ba = 'BA',
  /** Botswana */
  Bw = 'BW',
  /** Bouvet Island */
  Bv = 'BV',
  /** Brazil */
  Br = 'BR',
  /** British Indian Ocean Territory */
  Io = 'IO',
  /** Brunei */
  Bn = 'BN',
  /** Bulgaria */
  Bg = 'BG',
  /** Burkina Faso */
  Bf = 'BF',
  /** Burundi */
  Bi = 'BI',
  /** Cabo Verde */
  Cv = 'CV',
  /** Cambodia */
  Kh = 'KH',
  /** Cameroon */
  Cm = 'CM',
  /** Canada */
  Ca = 'CA',
  /** Cayman Islands */
  Ky = 'KY',
  /** Central African Republic */
  Cf = 'CF',
  /** Chad */
  Td = 'TD',
  /** Chile */
  Cl = 'CL',
  /** China */
  Cn = 'CN',
  /** Christmas Island */
  Cx = 'CX',
  /** Cocos (Keeling) Islands */
  Cc = 'CC',
  /** Colombia */
  Co = 'CO',
  /** Comoros */
  Km = 'KM',
  /** Congo */
  Cg = 'CG',
  /** Congo (the Democratic Republic of the) */
  Cd = 'CD',
  /** Cook Islands */
  Ck = 'CK',
  /** Costa Rica */
  Cr = 'CR',
  /** Côte d'Ivoire */
  Ci = 'CI',
  /** Croatia */
  Hr = 'HR',
  /** Cuba */
  Cu = 'CU',
  /** Curaçao */
  Cw = 'CW',
  /** Cyprus */
  Cy = 'CY',
  /** Czechia */
  Cz = 'CZ',
  /** Denmark */
  Dk = 'DK',
  /** Djibouti */
  Dj = 'DJ',
  /** Dominica */
  Dm = 'DM',
  /** Dominican Republic */
  Do = 'DO',
  /** Ecuador */
  Ec = 'EC',
  /** Egypt */
  Eg = 'EG',
  /** El Salvador */
  Sv = 'SV',
  /** Equatorial Guinea */
  Gq = 'GQ',
  /** Eritrea */
  Er = 'ER',
  /** Estonia */
  Ee = 'EE',
  /** Eswatini */
  Sz = 'SZ',
  /** Ethiopia */
  Et = 'ET',
  /** Falkland Islands  [Malvinas] */
  Fk = 'FK',
  /** Faroe Islands */
  Fo = 'FO',
  /** Fiji */
  Fj = 'FJ',
  /** Finland */
  Fi = 'FI',
  /** France */
  Fr = 'FR',
  /** French Guiana */
  Gf = 'GF',
  /** French Polynesia */
  Pf = 'PF',
  /** French Southern Territories */
  Tf = 'TF',
  /** Gabon */
  Ga = 'GA',
  /** Gambia */
  Gm = 'GM',
  /** Georgia */
  Ge = 'GE',
  /** Germany */
  De = 'DE',
  /** Ghana */
  Gh = 'GH',
  /** Gibraltar */
  Gi = 'GI',
  /** Greece */
  Gr = 'GR',
  /** Greenland */
  Gl = 'GL',
  /** Grenada */
  Gd = 'GD',
  /** Guadeloupe */
  Gp = 'GP',
  /** Guam */
  Gu = 'GU',
  /** Guatemala */
  Gt = 'GT',
  /** Guernsey */
  Gg = 'GG',
  /** Guinea */
  Gn = 'GN',
  /** Guinea-Bissau */
  Gw = 'GW',
  /** Guyana */
  Gy = 'GY',
  /** Haiti */
  Ht = 'HT',
  /** Heard Island and McDonald Islands */
  Hm = 'HM',
  /** Holy See */
  Va = 'VA',
  /** Honduras */
  Hn = 'HN',
  /** Hong Kong */
  Hk = 'HK',
  /** Hungary */
  Hu = 'HU',
  /** Iceland */
  Is = 'IS',
  /** India */
  In = 'IN',
  /** Indonesia */
  Id = 'ID',
  /** Iran */
  Ir = 'IR',
  /** Iraq */
  Iq = 'IQ',
  /** Ireland */
  Ie = 'IE',
  /** Isle of Man */
  Im = 'IM',
  /** Israel */
  Il = 'IL',
  /** Italy */
  It = 'IT',
  /** Jamaica */
  Jm = 'JM',
  /** Japan */
  Jp = 'JP',
  /** Jersey */
  Je = 'JE',
  /** Jordan */
  Jo = 'JO',
  /** Kazakhstan */
  Kz = 'KZ',
  /** Kenya */
  Ke = 'KE',
  /** Kiribati */
  Ki = 'KI',
  /** Kuwait */
  Kw = 'KW',
  /** Kyrgyzstan */
  Kg = 'KG',
  /** Laos */
  La = 'LA',
  /** Latvia */
  Lv = 'LV',
  /** Lebanon */
  Lb = 'LB',
  /** Lesotho */
  Ls = 'LS',
  /** Liberia */
  Lr = 'LR',
  /** Libya */
  Ly = 'LY',
  /** Liechtenstein */
  Li = 'LI',
  /** Lithuania */
  Lt = 'LT',
  /** Luxembourg */
  Lu = 'LU',
  /** Macao */
  Mo = 'MO',
  /** Madagascar */
  Mg = 'MG',
  /** Malawi */
  Mw = 'MW',
  /** Malaysia */
  My = 'MY',
  /** Maldives */
  Mv = 'MV',
  /** Mali */
  Ml = 'ML',
  /** Malta */
  Mt = 'MT',
  /** Marshall Islands */
  Mh = 'MH',
  /** Martinique */
  Mq = 'MQ',
  /** Mauritania */
  Mr = 'MR',
  /** Mauritius */
  Mu = 'MU',
  /** Mayotte */
  Yt = 'YT',
  /** Mexico */
  Mx = 'MX',
  /** Micronesia (Federated States of) */
  Fm = 'FM',
  /** Moldova */
  Md = 'MD',
  /** Monaco */
  Mc = 'MC',
  /** Mongolia */
  Mn = 'MN',
  /** Montenegro */
  Me = 'ME',
  /** Montserrat */
  Ms = 'MS',
  /** Morocco */
  Ma = 'MA',
  /** Mozambique */
  Mz = 'MZ',
  /** Myanmar */
  Mm = 'MM',
  /** Namibia */
  Na = 'NA',
  /** Nauru */
  Nr = 'NR',
  /** Nepal */
  Np = 'NP',
  /** Netherlands */
  Nl = 'NL',
  /** New Caledonia */
  Nc = 'NC',
  /** New Zealand */
  Nz = 'NZ',
  /** Nicaragua */
  Ni = 'NI',
  /** Niger */
  Ne = 'NE',
  /** Nigeria */
  Ng = 'NG',
  /** Niue */
  Nu = 'NU',
  /** Norfolk Island */
  Nf = 'NF',
  /** North Korea */
  Kp = 'KP',
  /** North Macedonia */
  Mk = 'MK',
  /** Northern Mariana Islands */
  Mp = 'MP',
  /** Norway */
  No = 'NO',
  /** Oman */
  Om = 'OM',
  /** Pakistan */
  Pk = 'PK',
  /** Palau */
  Pw = 'PW',
  /** Palestine, State of */
  Ps = 'PS',
  /** Panama */
  Pa = 'PA',
  /** Papua New Guinea */
  Pg = 'PG',
  /** Paraguay */
  Py = 'PY',
  /** Peru */
  Pe = 'PE',
  /** Philippines */
  Ph = 'PH',
  /** Pitcairn */
  Pn = 'PN',
  /** Poland */
  Pl = 'PL',
  /** Portugal */
  Pt = 'PT',
  /** Puerto Rico */
  Pr = 'PR',
  /** Qatar */
  Qa = 'QA',
  /** Réunion */
  Re = 'RE',
  /** Romania */
  Ro = 'RO',
  /** Russia */
  Ru = 'RU',
  /** Rwanda */
  Rw = 'RW',
  /** Saint Barthélemy */
  Bl = 'BL',
  /** Saint Helena, Ascension and Tristan da Cunha */
  Sh = 'SH',
  /** Saint Kitts and Nevis */
  Kn = 'KN',
  /** Saint Lucia */
  Lc = 'LC',
  /** Saint Martin (French part) */
  Mf = 'MF',
  /** Saint Pierre and Miquelon */
  Pm = 'PM',
  /** Saint Vincent and the Grenadines */
  Vc = 'VC',
  /** Samoa */
  Ws = 'WS',
  /** San Marino */
  Sm = 'SM',
  /** Sao Tome and Principe */
  St = 'ST',
  /** Saudi Arabia */
  Sa = 'SA',
  /** Senegal */
  Sn = 'SN',
  /** Serbia */
  Rs = 'RS',
  /** Seychelles */
  Sc = 'SC',
  /** Sierra Leone */
  Sl = 'SL',
  /** Singapore */
  Sg = 'SG',
  /** Sint Maarten (Dutch part) */
  Sx = 'SX',
  /** Slovakia */
  Sk = 'SK',
  /** Slovenia */
  Si = 'SI',
  /** Solomon Islands */
  Sb = 'SB',
  /** Somalia */
  So = 'SO',
  /** South Africa */
  Za = 'ZA',
  /** South Georgia and the South Sandwich Islands */
  Gs = 'GS',
  /** South Korea */
  Kr = 'KR',
  /** South Sudan */
  Ss = 'SS',
  /** Spain */
  Es = 'ES',
  /** Sri Lanka */
  Lk = 'LK',
  /** Sudan */
  Sd = 'SD',
  /** Suriname */
  Sr = 'SR',
  /** Svalbard and Jan Mayen */
  Sj = 'SJ',
  /** Sweden */
  Se = 'SE',
  /** Switzerland */
  Ch = 'CH',
  /** Syria */
  Sy = 'SY',
  /** Taiwan */
  Tw = 'TW',
  /** Tajikistan */
  Tj = 'TJ',
  /** Tanzania */
  Tz = 'TZ',
  /** Thailand */
  Th = 'TH',
  /** Timor-Leste */
  Tl = 'TL',
  /** Togo */
  Tg = 'TG',
  /** Tokelau */
  Tk = 'TK',
  /** Tonga */
  To = 'TO',
  /** Trinidad and Tobago */
  Tt = 'TT',
  /** Tunisia */
  Tn = 'TN',
  /** Turkey */
  Tr = 'TR',
  /** Turkmenistan */
  Tm = 'TM',
  /** Turks and Caicos Islands */
  Tc = 'TC',
  /** Tuvalu */
  Tv = 'TV',
  /** Uganda */
  Ug = 'UG',
  /** Ukraine */
  Ua = 'UA',
  /** United Arab Emirates */
  Ae = 'AE',
  /** United Kingdom */
  Gb = 'GB',
  /** United States Minor Outlying Islands */
  Um = 'UM',
  /** United States of America */
  Us = 'US',
  /** Uruguay */
  Uy = 'UY',
  /** Uzbekistan */
  Uz = 'UZ',
  /** Vanuatu */
  Vu = 'VU',
  /** Venezuela */
  Ve = 'VE',
  /** Vietnam */
  Vn = 'VN',
  /** Virgin Islands (British) */
  Vg = 'VG',
  /** Virgin Islands (U.S.) */
  Vi = 'VI',
  /** Wallis and Futuna */
  Wf = 'WF',
  /** Western Sahara */
  Eh = 'EH',
  /** Yemen */
  Ye = 'YE',
  /** Zambia */
  Zm = 'ZM',
  /** Zimbabwe */
  Zw = 'ZW'
}

/** An enumeration. */
export enum QuotationShippingCountry {
  /** Afghanistan */
  Af = 'AF',
  /** Åland Islands */
  Ax = 'AX',
  /** Albania */
  Al = 'AL',
  /** Algeria */
  Dz = 'DZ',
  /** American Samoa */
  As = 'AS',
  /** Andorra */
  Ad = 'AD',
  /** Angola */
  Ao = 'AO',
  /** Anguilla */
  Ai = 'AI',
  /** Antarctica */
  Aq = 'AQ',
  /** Antigua and Barbuda */
  Ag = 'AG',
  /** Argentina */
  Ar = 'AR',
  /** Armenia */
  Am = 'AM',
  /** Aruba */
  Aw = 'AW',
  /** Australia */
  Au = 'AU',
  /** Austria */
  At = 'AT',
  /** Azerbaijan */
  Az = 'AZ',
  /** Bahamas */
  Bs = 'BS',
  /** Bahrain */
  Bh = 'BH',
  /** Bangladesh */
  Bd = 'BD',
  /** Barbados */
  Bb = 'BB',
  /** Belarus */
  By = 'BY',
  /** Belgium */
  Be = 'BE',
  /** Belize */
  Bz = 'BZ',
  /** Benin */
  Bj = 'BJ',
  /** Bermuda */
  Bm = 'BM',
  /** Bhutan */
  Bt = 'BT',
  /** Bolivia */
  Bo = 'BO',
  /** Bonaire, Sint Eustatius and Saba */
  Bq = 'BQ',
  /** Bosnia and Herzegovina */
  Ba = 'BA',
  /** Botswana */
  Bw = 'BW',
  /** Bouvet Island */
  Bv = 'BV',
  /** Brazil */
  Br = 'BR',
  /** British Indian Ocean Territory */
  Io = 'IO',
  /** Brunei */
  Bn = 'BN',
  /** Bulgaria */
  Bg = 'BG',
  /** Burkina Faso */
  Bf = 'BF',
  /** Burundi */
  Bi = 'BI',
  /** Cabo Verde */
  Cv = 'CV',
  /** Cambodia */
  Kh = 'KH',
  /** Cameroon */
  Cm = 'CM',
  /** Canada */
  Ca = 'CA',
  /** Cayman Islands */
  Ky = 'KY',
  /** Central African Republic */
  Cf = 'CF',
  /** Chad */
  Td = 'TD',
  /** Chile */
  Cl = 'CL',
  /** China */
  Cn = 'CN',
  /** Christmas Island */
  Cx = 'CX',
  /** Cocos (Keeling) Islands */
  Cc = 'CC',
  /** Colombia */
  Co = 'CO',
  /** Comoros */
  Km = 'KM',
  /** Congo */
  Cg = 'CG',
  /** Congo (the Democratic Republic of the) */
  Cd = 'CD',
  /** Cook Islands */
  Ck = 'CK',
  /** Costa Rica */
  Cr = 'CR',
  /** Côte d'Ivoire */
  Ci = 'CI',
  /** Croatia */
  Hr = 'HR',
  /** Cuba */
  Cu = 'CU',
  /** Curaçao */
  Cw = 'CW',
  /** Cyprus */
  Cy = 'CY',
  /** Czechia */
  Cz = 'CZ',
  /** Denmark */
  Dk = 'DK',
  /** Djibouti */
  Dj = 'DJ',
  /** Dominica */
  Dm = 'DM',
  /** Dominican Republic */
  Do = 'DO',
  /** Ecuador */
  Ec = 'EC',
  /** Egypt */
  Eg = 'EG',
  /** El Salvador */
  Sv = 'SV',
  /** Equatorial Guinea */
  Gq = 'GQ',
  /** Eritrea */
  Er = 'ER',
  /** Estonia */
  Ee = 'EE',
  /** Eswatini */
  Sz = 'SZ',
  /** Ethiopia */
  Et = 'ET',
  /** Falkland Islands  [Malvinas] */
  Fk = 'FK',
  /** Faroe Islands */
  Fo = 'FO',
  /** Fiji */
  Fj = 'FJ',
  /** Finland */
  Fi = 'FI',
  /** France */
  Fr = 'FR',
  /** French Guiana */
  Gf = 'GF',
  /** French Polynesia */
  Pf = 'PF',
  /** French Southern Territories */
  Tf = 'TF',
  /** Gabon */
  Ga = 'GA',
  /** Gambia */
  Gm = 'GM',
  /** Georgia */
  Ge = 'GE',
  /** Germany */
  De = 'DE',
  /** Ghana */
  Gh = 'GH',
  /** Gibraltar */
  Gi = 'GI',
  /** Greece */
  Gr = 'GR',
  /** Greenland */
  Gl = 'GL',
  /** Grenada */
  Gd = 'GD',
  /** Guadeloupe */
  Gp = 'GP',
  /** Guam */
  Gu = 'GU',
  /** Guatemala */
  Gt = 'GT',
  /** Guernsey */
  Gg = 'GG',
  /** Guinea */
  Gn = 'GN',
  /** Guinea-Bissau */
  Gw = 'GW',
  /** Guyana */
  Gy = 'GY',
  /** Haiti */
  Ht = 'HT',
  /** Heard Island and McDonald Islands */
  Hm = 'HM',
  /** Holy See */
  Va = 'VA',
  /** Honduras */
  Hn = 'HN',
  /** Hong Kong */
  Hk = 'HK',
  /** Hungary */
  Hu = 'HU',
  /** Iceland */
  Is = 'IS',
  /** India */
  In = 'IN',
  /** Indonesia */
  Id = 'ID',
  /** Iran */
  Ir = 'IR',
  /** Iraq */
  Iq = 'IQ',
  /** Ireland */
  Ie = 'IE',
  /** Isle of Man */
  Im = 'IM',
  /** Israel */
  Il = 'IL',
  /** Italy */
  It = 'IT',
  /** Jamaica */
  Jm = 'JM',
  /** Japan */
  Jp = 'JP',
  /** Jersey */
  Je = 'JE',
  /** Jordan */
  Jo = 'JO',
  /** Kazakhstan */
  Kz = 'KZ',
  /** Kenya */
  Ke = 'KE',
  /** Kiribati */
  Ki = 'KI',
  /** Kuwait */
  Kw = 'KW',
  /** Kyrgyzstan */
  Kg = 'KG',
  /** Laos */
  La = 'LA',
  /** Latvia */
  Lv = 'LV',
  /** Lebanon */
  Lb = 'LB',
  /** Lesotho */
  Ls = 'LS',
  /** Liberia */
  Lr = 'LR',
  /** Libya */
  Ly = 'LY',
  /** Liechtenstein */
  Li = 'LI',
  /** Lithuania */
  Lt = 'LT',
  /** Luxembourg */
  Lu = 'LU',
  /** Macao */
  Mo = 'MO',
  /** Madagascar */
  Mg = 'MG',
  /** Malawi */
  Mw = 'MW',
  /** Malaysia */
  My = 'MY',
  /** Maldives */
  Mv = 'MV',
  /** Mali */
  Ml = 'ML',
  /** Malta */
  Mt = 'MT',
  /** Marshall Islands */
  Mh = 'MH',
  /** Martinique */
  Mq = 'MQ',
  /** Mauritania */
  Mr = 'MR',
  /** Mauritius */
  Mu = 'MU',
  /** Mayotte */
  Yt = 'YT',
  /** Mexico */
  Mx = 'MX',
  /** Micronesia (Federated States of) */
  Fm = 'FM',
  /** Moldova */
  Md = 'MD',
  /** Monaco */
  Mc = 'MC',
  /** Mongolia */
  Mn = 'MN',
  /** Montenegro */
  Me = 'ME',
  /** Montserrat */
  Ms = 'MS',
  /** Morocco */
  Ma = 'MA',
  /** Mozambique */
  Mz = 'MZ',
  /** Myanmar */
  Mm = 'MM',
  /** Namibia */
  Na = 'NA',
  /** Nauru */
  Nr = 'NR',
  /** Nepal */
  Np = 'NP',
  /** Netherlands */
  Nl = 'NL',
  /** New Caledonia */
  Nc = 'NC',
  /** New Zealand */
  Nz = 'NZ',
  /** Nicaragua */
  Ni = 'NI',
  /** Niger */
  Ne = 'NE',
  /** Nigeria */
  Ng = 'NG',
  /** Niue */
  Nu = 'NU',
  /** Norfolk Island */
  Nf = 'NF',
  /** North Korea */
  Kp = 'KP',
  /** North Macedonia */
  Mk = 'MK',
  /** Northern Mariana Islands */
  Mp = 'MP',
  /** Norway */
  No = 'NO',
  /** Oman */
  Om = 'OM',
  /** Pakistan */
  Pk = 'PK',
  /** Palau */
  Pw = 'PW',
  /** Palestine, State of */
  Ps = 'PS',
  /** Panama */
  Pa = 'PA',
  /** Papua New Guinea */
  Pg = 'PG',
  /** Paraguay */
  Py = 'PY',
  /** Peru */
  Pe = 'PE',
  /** Philippines */
  Ph = 'PH',
  /** Pitcairn */
  Pn = 'PN',
  /** Poland */
  Pl = 'PL',
  /** Portugal */
  Pt = 'PT',
  /** Puerto Rico */
  Pr = 'PR',
  /** Qatar */
  Qa = 'QA',
  /** Réunion */
  Re = 'RE',
  /** Romania */
  Ro = 'RO',
  /** Russia */
  Ru = 'RU',
  /** Rwanda */
  Rw = 'RW',
  /** Saint Barthélemy */
  Bl = 'BL',
  /** Saint Helena, Ascension and Tristan da Cunha */
  Sh = 'SH',
  /** Saint Kitts and Nevis */
  Kn = 'KN',
  /** Saint Lucia */
  Lc = 'LC',
  /** Saint Martin (French part) */
  Mf = 'MF',
  /** Saint Pierre and Miquelon */
  Pm = 'PM',
  /** Saint Vincent and the Grenadines */
  Vc = 'VC',
  /** Samoa */
  Ws = 'WS',
  /** San Marino */
  Sm = 'SM',
  /** Sao Tome and Principe */
  St = 'ST',
  /** Saudi Arabia */
  Sa = 'SA',
  /** Senegal */
  Sn = 'SN',
  /** Serbia */
  Rs = 'RS',
  /** Seychelles */
  Sc = 'SC',
  /** Sierra Leone */
  Sl = 'SL',
  /** Singapore */
  Sg = 'SG',
  /** Sint Maarten (Dutch part) */
  Sx = 'SX',
  /** Slovakia */
  Sk = 'SK',
  /** Slovenia */
  Si = 'SI',
  /** Solomon Islands */
  Sb = 'SB',
  /** Somalia */
  So = 'SO',
  /** South Africa */
  Za = 'ZA',
  /** South Georgia and the South Sandwich Islands */
  Gs = 'GS',
  /** South Korea */
  Kr = 'KR',
  /** South Sudan */
  Ss = 'SS',
  /** Spain */
  Es = 'ES',
  /** Sri Lanka */
  Lk = 'LK',
  /** Sudan */
  Sd = 'SD',
  /** Suriname */
  Sr = 'SR',
  /** Svalbard and Jan Mayen */
  Sj = 'SJ',
  /** Sweden */
  Se = 'SE',
  /** Switzerland */
  Ch = 'CH',
  /** Syria */
  Sy = 'SY',
  /** Taiwan */
  Tw = 'TW',
  /** Tajikistan */
  Tj = 'TJ',
  /** Tanzania */
  Tz = 'TZ',
  /** Thailand */
  Th = 'TH',
  /** Timor-Leste */
  Tl = 'TL',
  /** Togo */
  Tg = 'TG',
  /** Tokelau */
  Tk = 'TK',
  /** Tonga */
  To = 'TO',
  /** Trinidad and Tobago */
  Tt = 'TT',
  /** Tunisia */
  Tn = 'TN',
  /** Turkey */
  Tr = 'TR',
  /** Turkmenistan */
  Tm = 'TM',
  /** Turks and Caicos Islands */
  Tc = 'TC',
  /** Tuvalu */
  Tv = 'TV',
  /** Uganda */
  Ug = 'UG',
  /** Ukraine */
  Ua = 'UA',
  /** United Arab Emirates */
  Ae = 'AE',
  /** United Kingdom */
  Gb = 'GB',
  /** United States Minor Outlying Islands */
  Um = 'UM',
  /** United States of America */
  Us = 'US',
  /** Uruguay */
  Uy = 'UY',
  /** Uzbekistan */
  Uz = 'UZ',
  /** Vanuatu */
  Vu = 'VU',
  /** Venezuela */
  Ve = 'VE',
  /** Vietnam */
  Vn = 'VN',
  /** Virgin Islands (British) */
  Vg = 'VG',
  /** Virgin Islands (U.S.) */
  Vi = 'VI',
  /** Wallis and Futuna */
  Wf = 'WF',
  /** Western Sahara */
  Eh = 'EH',
  /** Yemen */
  Ye = 'YE',
  /** Zambia */
  Zm = 'ZM',
  /** Zimbabwe */
  Zw = 'ZW'
}

/** GQL Type for the Quotation model. */
export type QuotationType = {
  __typename?: 'QuotationType';
  id: Scalars['ID'];
  project?: Maybe<ProjectType>;
  projectTitle: Scalars['String'];
  projectClientName: Scalars['String'];
  projectCountry: QuotationProjectCountry;
  projectCity: Scalars['String'];
  projectType: Scalars['String'];
  projectDeadline?: Maybe<Scalars['Date']>;
  projectBudget?: Maybe<Scalars['Int']>;
  shippingName: Scalars['String'];
  shippingAddress: Scalars['String'];
  shippingCity: Scalars['String'];
  shippingState: Scalars['String'];
  shippingZip: Scalars['String'];
  shippingCountry: QuotationShippingCountry;
  billingName: Scalars['String'];
  billingAddress: Scalars['String'];
  billingCity: Scalars['String'];
  billingState: Scalars['String'];
  billingZip: Scalars['String'];
  billingCountry: QuotationBillingCountry;
  installationRequired: Scalars['Boolean'];
  comments: Scalars['String'];
  isSubmitted: Scalars['Boolean'];
  submittedBy?: Maybe<UserType>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  isCompleted: Scalars['Boolean'];
  completedBy?: Maybe<UserType>;
  completedAt?: Maybe<Scalars['DateTime']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  quotationPdf?: Maybe<Scalars['String']>;
  isAccepted: Scalars['Boolean'];
  acceptedBy?: Maybe<UserType>;
  acceptedAt?: Maybe<Scalars['DateTime']>;
  isLatestQuotation: Scalars['Boolean'];
  hasTradeDiscount: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  quotationitemSet?: Maybe<Array<Maybe<QuotationItemType>>>;
  submittedAtStr?: Maybe<Scalars['String']>;
  shippingAddressStr?: Maybe<Scalars['String']>;
  billingAddressStr?: Maybe<Scalars['String']>;
};

/** GQL Type for the RecentProject model. */
export type RecentProjectType = {
  __typename?: 'RecentProjectType';
  id: Scalars['ID'];
  user: UserType;
  project: ProjectType;
  accessDate: Scalars['DateTime'];
};

/** tags: refreshPage */
export type RefreshPageMutation = {
  __typename?: 'RefreshPageMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  page?: Maybe<PdfPageType>;
};

export type RemoveProductVimrImageMutation = {
  __typename?: 'RemoveProductVIMRImageMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  bucketItem?: Maybe<BucketItemType>;
};

/**
 * Sets joyride_search_view_completed back to False for the current user.
 *
 * tags: replayJoyrideSearchView
 */
export type ReplayJoyrideSearchViewMutation = {
  __typename?: 'ReplayJoyrideSearchViewMutation';
  status?: Maybe<Scalars['Int']>;
  currentProfile?: Maybe<UserProfileType>;
};

/**
 * Sets joyride_shortlisted_view_completed back to False for the current user.
 *
 * tags: replayJoyrideShortlistedView
 */
export type ReplayJoyrideShortlistedViewMutation = {
  __typename?: 'ReplayJoyrideShortlistedViewMutation';
  status?: Maybe<Scalars['Int']>;
  currentProfile?: Maybe<UserProfileType>;
};

/** tags: requestArtworkProposal */
export type RequestArtworkProposalMutation = {
  __typename?: 'RequestArtworkProposalMutation';
  status?: Maybe<Scalars['Int']>;
  project?: Maybe<ProjectType>;
};

/**
 * Triggered by RequestQuotationView in SelectSensitiveDocumentsForm in
 * ModalSelectSensitiveDocuments.
 *
 * tags: requestQuotation
 */
export type RequestQuotationMutation = {
  __typename?: 'RequestQuotationMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  project?: Maybe<ProjectType>;
};

/**
 * Allows a user to reset the custom text of a bucket item
 *
 * NOTE: This function looks through ES for the products and only returns
 *       a list of found items. (Might cause issues on staging/local)
 *      If you did a django db sync but not an ES sync some products will
 *      not be available on ES
 */
export type ResetBucketItemCustomTextMutation = {
  __typename?: 'ResetBucketItemCustomTextMutation';
  status?: Maybe<Scalars['Int']>;
  bucketItem?: Maybe<ProductAndItemType>;
};

export type ResetPasswordWithKeyMutation = {
  __typename?: 'ResetPasswordWithKeyMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  currentProfile?: Maybe<UserProfileType>;
};

/** tags: revokeProjectAccess */
export type RevokeProjectAccessMutation = {
  __typename?: 'RevokeProjectAccessMutation';
  status?: Maybe<Scalars['Int']>;
  project?: Maybe<ProjectType>;
};

export type SavedFrameType = {
  __typename?: 'SavedFrameType';
  id?: Maybe<Scalars['Int']>;
  artworkWidth?: Maybe<Scalars['Float']>;
  artworkHeight?: Maybe<Scalars['Float']>;
  artworkDepth?: Maybe<Scalars['Float']>;
  framedWidth?: Maybe<Scalars['Float']>;
  framedHeight?: Maybe<Scalars['Float']>;
  framedDepth?: Maybe<Scalars['Float']>;
  matWidth?: Maybe<Scalars['Float']>;
  matColor?: Maybe<Scalars['String']>;
  matColorCode?: Maybe<Scalars['String']>;
  frameWidth?: Maybe<Scalars['Float']>;
  frameMaterial?: Maybe<FrameMaterialType>;
  artworkType?: Maybe<Scalars['String']>;
  framingType?: Maybe<Scalars['String']>;
  glazingType?: Maybe<Scalars['String']>;
  savedImage?: Maybe<Scalars['String']>;
  originalImg?: Maybe<Scalars['String']>;
};

export type SavedFrameUpdateCreateMutation = {
  __typename?: 'SavedFrameUpdateCreateMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  savedFrame?: Maybe<SavedFrameType>;
};

export type SellerCommissionedWorksSaveAvailabilityDataMutation = {
  __typename?: 'SellerCommissionedWorksSaveAvailabilityDataMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
};

export type SellerCommissionedWorksSubmitAvailabilityDataMutation = {
  __typename?: 'SellerCommissionedWorksSubmitAvailabilityDataMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
};

export type SellerConfirmAvailabilityMutation = {
  __typename?: 'SellerConfirmAvailabilityMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
};

/**
 * Sets joyride_search_view_completed to True for the current user.
 *
 * tags: setJoyrideSearchView
 */
export type SetJoyrideSearchViewMutation = {
  __typename?: 'SetJoyrideSearchViewMutation';
  status?: Maybe<Scalars['Int']>;
  currentProfile?: Maybe<UserProfileType>;
};

/**
 * Sets joyride_shortlisted_view_completed to True for the current user.
 *
 * tags: setJoyrideShortlistedView
 */
export type SetJoyrideShortlistedViewMutation = {
  __typename?: 'SetJoyrideShortlistedViewMutation';
  status?: Maybe<Scalars['Int']>;
  currentProfile?: Maybe<UserProfileType>;
};

export type SignupKeyType = {
  __typename?: 'SignupKeyType';
  id: Scalars['ID'];
  hash: Scalars['String'];
  user?: Maybe<UserType>;
  sharedProjects?: Maybe<Array<Maybe<ProjectType>>>;
  createdAt: Scalars['DateTime'];
  usedAt?: Maybe<Scalars['DateTime']>;
  inviteSentTo: Scalars['String'];
  adminNotes: Scalars['String'];
};

export type SignupMutation = {
  __typename?: 'SignupMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  currentProfile?: Maybe<UserProfileType>;
};

export type SizeType = {
  __typename?: 'SizeType';
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  thickness?: Maybe<Scalars['Float']>;
  diameter?: Maybe<Scalars['Float']>;
  widthIn?: Maybe<Scalars['Float']>;
  heightIn?: Maybe<Scalars['Float']>;
  thicknessIn?: Maybe<Scalars['Float']>;
  diameterIn?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  framingStatus?: Maybe<Scalars['String']>;
  stretchingStatus?: Maybe<Scalars['String']>;
  orientation?: Maybe<Scalars['String']>;
  printActive?: Maybe<Scalars['Boolean']>;
  printBaseCost?: Maybe<PrintBaseCostType>;
  priceString?: Maybe<Scalars['String']>;
  sizeString?: Maybe<Scalars['String']>;
  sizeAndPriceString?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type SlugAndNameType = {
  __typename?: 'SlugAndNameType';
  slug?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type SlugAndTitleType = {
  __typename?: 'SlugAndTitleType';
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type SlugType = {
  __typename?: 'SlugType';
  slug?: Maybe<Scalars['String']>;
};

export type SourcedProductUpdateCreateMutation = {
  __typename?: 'SourcedProductUpdateCreateMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  project?: Maybe<ProjectType>;
  locationItem?: Maybe<LocationItemType>;
};

/**
 * Kicks off the NormalWorksFlow in StaffWFNormalWorksNotifySellersView.
 *
 * tags: wfStaffCheckAvailability
 *
 * :flow_item_ids: The flow items that shall be processed to the next
 *     state.
 */
export type StaffCheckAvailabilityMutation = {
  __typename?: 'StaffCheckAvailabilityMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  sellerEmails?: Maybe<Scalars['String']>;
};

/** Mutation: wfCommissionedWorksStaffNotifySellers */
export type StaffCommissionedWorksNotifySellersMutation = {
  __typename?: 'StaffCommissionedWorksNotifySellersMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
};

/**
 * tags: wfCommissionedWorksStaffUpdateLocationInfo,
 *   WFCommissionedWorksLocationInfoFormMutation,
 *   WFCommissionedWorksLocationInfoForm
 */
export type StaffCommissionedWorksUpdateLocationInfoMutation = {
  __typename?: 'StaffCommissionedWorksUpdateLocationInfoMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
};

/**
 * Allows a staff user to upload a quotation pdf and complete the quotation.
 *
 * Used in ModalProjectSendQuotation
 *
 * tags: staff_complete_quotation, staffCompleteQuotation
 */
export type StaffCompleteQuotationMutation = {
  __typename?: 'StaffCompleteQuotationMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  quotation?: Maybe<QuotationType>;
};

/** tags: staffFinishArtworkProposal */
export type StaffFinishArtworkProposalMutation = {
  __typename?: 'StaffFinishArtworkProposalMutation';
  status?: Maybe<Scalars['Int']>;
  project?: Maybe<ProjectType>;
};

export type StaffMoveToStepMutation = {
  __typename?: 'StaffMoveToStepMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  flowItemArtwork?: Maybe<FlowItemArtworkType>;
};

export type StaffRequestFramerQuotation = {
  __typename?: 'StaffRequestFramerQuotation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
};

export type StaffUpdateFlowItemFramerInfoMutation = {
  __typename?: 'StaffUpdateFlowItemFramerInfoMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  flowItem?: Maybe<FlowItemArtworkType>;
};

/** GQL Type for the StaticImage model */
export type StaticImageType = {
  __typename?: 'StaticImageType';
  id: Scalars['ID'];
  identifier: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  altText: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
};

/** GQL Type for the StretchingQuotation model. */
export type StretchingQuotationType = {
  __typename?: 'StretchingQuotationType';
  id: Scalars['ID'];
  framerQuotation?: Maybe<FramerGlobalQuotationType>;
  flowItem: FlowItemArtworkFramerRfqType;
  cost?: Maybe<Scalars['Float']>;
  leadTimeUnit?: Maybe<Scalars['String']>;
  leadTime?: Maybe<Scalars['Int']>;
  comments: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

/** GQL Type for the Style model */
export type StyleType = {
  __typename?: 'StyleType';
  id: Scalars['ID'];
  title: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  artistVariants?: Maybe<Array<Maybe<ArtistVariantType>>>;
};

export type SubmitFeedbackMutation = {
  __typename?: 'SubmitFeedbackMutation';
  status?: Maybe<Scalars['Int']>;
  feedback?: Maybe<FeedbackType>;
};

/**
 * Allows a user to tick the checkbox so that a bucket item will appear
 * in the PDF view and the RequestQuotation view.
 */
export type ToggleBucketItemShortlistedMutation = {
  __typename?: 'ToggleBucketItemShortlistedMutation';
  status?: Maybe<Scalars['Int']>;
};

/** tags: toggleFavorite */
export type ToggleFavoriteMutation = {
  __typename?: 'ToggleFavoriteMutation';
  status?: Maybe<Scalars['Int']>;
};

/** tags: toggleProposalVertical */
export type ToggleProposalVerticalMutation = {
  __typename?: 'ToggleProposalVerticalMutation';
  status?: Maybe<Scalars['Int']>;
  proposal?: Maybe<PdfProposalType>;
};

export type TypeOfIndustryType = {
  __typename?: 'TypeOfIndustryType';
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

/** tags: updateAccountInfo */
export type UpdateAccountInfoMutation = {
  __typename?: 'UpdateAccountInfoMutation';
  status?: Maybe<Scalars['Int']>;
  userProfile?: Maybe<UserProfileType>;
  formErrors?: Maybe<Scalars['String']>;
};

/** tags: updateAreaBucketPositions */
export type UpdateAreaBucketPositionsMutation = {
  __typename?: 'UpdateAreaBucketPositionsMutation';
  status?: Maybe<Scalars['Int']>;
  project?: Maybe<ProjectType>;
};

/**
 * Called by img-remove-bg lambda function.
 * Triggered when design item is added to a location in artling projects
 */
export type UpdateArtistProductImageCroppedMutation = {
  __typename?: 'UpdateArtistProductImageCroppedMutation';
  status?: Maybe<Scalars['Int']>;
};

/**
 * Allows a user to update the product fields of a bucket item
 *
 * NOTE: This function looks through ES for the products and only returns
 *       a list of found items. (Might cause issues on staging/local)
 *      If you did a django db sync but not an ES sync some products will
 *      not be available on ES
 */
export type UpdateBucketItemDetailsMutation = {
  __typename?: 'UpdateBucketItemDetailsMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  bucketItem?: Maybe<ProductAndItemType>;
};

export type UpdateBucketItemMutation = {
  __typename?: 'UpdateBucketItemMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  bucketItem?: Maybe<BucketItemType>;
};

/**
 * NOTE: Unfortunately, we have this mutation in this file to prevent
 * a circular import issue. We cannot import LocationItemType in the buckets
 * schema.
 *
 * A possible solution around this could be this:
 * https://github.com/graphql-python/graphene/issues/522#issuecomment-626075061
 */
export type UpdateBucketItemPositionMutation = {
  __typename?: 'UpdateBucketItemPositionMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  locationItem?: Maybe<LocationItemType>;
  newLocationItem?: Maybe<LocationItemType>;
};

export type UpdateBucketItemWallPtMutation = {
  __typename?: 'UpdateBucketItemWallPtMutation';
  status?: Maybe<Scalars['Int']>;
  bucketItem?: Maybe<BucketItemType>;
};

export type UpdateCreateClientMutation = {
  __typename?: 'UpdateCreateClientMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  result?: Maybe<ClientType>;
};

export type UpdateEmailMutation = {
  __typename?: 'UpdateEmailMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
};

export type UpdateFeedbackMutation = {
  __typename?: 'UpdateFeedbackMutation';
  status?: Maybe<Scalars['Int']>;
  feedback?: Maybe<FeedbackType>;
};

/** tags: updateFloorplan */
export type UpdateFloorplanMutation = {
  __typename?: 'UpdateFloorplanMutation';
  status?: Maybe<Scalars['Int']>;
  floorplan?: Maybe<FloorPlanType>;
  page?: Maybe<PdfPageType>;
};

/** tags: updateFloorplanScale */
export type UpdateFloorplanScaleMutation = {
  __typename?: 'UpdateFloorplanScaleMutation';
  status?: Maybe<Scalars['Int']>;
  floorplan?: Maybe<FloorPlanType>;
  formErrors?: Maybe<Scalars['String']>;
};

/** tags: updateFloorplanShownUnit */
export type UpdateFloorplanShownUnitMutation = {
  __typename?: 'UpdateFloorplanShownUnitMutation';
  status?: Maybe<Scalars['Int']>;
  floorplan?: Maybe<FloorPlanType>;
  formErrors?: Maybe<Scalars['String']>;
};

export type UpdateLocationInfoMutation = {
  __typename?: 'UpdateLocationInfoMutation';
  status?: Maybe<Scalars['Int']>;
  location?: Maybe<LocationType>;
};

/** Tags: updateLocationItem */
export type UpdateLocationItemMutation = {
  __typename?: 'UpdateLocationItemMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  project?: Maybe<ProjectType>;
  locationItem?: Maybe<LocationItemType>;
  autoSubmit?: Maybe<Scalars['String']>;
};

export type UpdateLocationItemNameMutation = {
  __typename?: 'UpdateLocationItemNameMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  result?: Maybe<LocationItemType>;
};

export type UpdateLocationItemPointsMutation = {
  __typename?: 'UpdateLocationItemPointsMutation';
  status?: Maybe<Scalars['Int']>;
  locationItem?: Maybe<LocationItemType>;
};

/**
 * Allows the user to select which image shows the wall the best and update
 * the width/height of the wall, in case that was not entered correctly during
 * the wizard.
 *
 * tags: updateLocationItemVimrWall
 */
export type UpdateLocationItemVimrWallMutation = {
  __typename?: 'UpdateLocationItemVimrWallMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  locationItem?: Maybe<LocationItemType>;
};

export type UpdateLocationNameMutation = {
  __typename?: 'UpdateLocationNameMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  result?: Maybe<LocationType>;
};

/** tags: updateLocationPosition */
export type UpdateLocationPositionMutation = {
  __typename?: 'UpdateLocationPositionMutation';
  status?: Maybe<Scalars['Int']>;
  project?: Maybe<ProjectType>;
};

export type UpdateLocationRotationMutation = {
  __typename?: 'UpdateLocationRotationMutation';
  status?: Maybe<Scalars['Int']>;
  locationItem?: Maybe<LocationItemType>;
};

export type UpdateLocationTitleMutation = {
  __typename?: 'UpdateLocationTitleMutation';
  status?: Maybe<Scalars['Int']>;
  location?: Maybe<LocationType>;
};

/** tags: updatePdfAreaImage */
export type UpdatePdfAreaImageMutation = {
  __typename?: 'UpdatePDFAreaImageMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  area?: Maybe<LocationType>;
};

/** tags: updatePdfAreaImageSize */
export type UpdatePdfAreaImageSizeMutation = {
  __typename?: 'UpdatePDFAreaImageSizeMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  area?: Maybe<LocationType>;
};

/** tags: updatePDFDetails */
export type UpdatePdfDetailsMutation = {
  __typename?: 'UpdatePDFDetailsMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  project?: Maybe<ProjectType>;
};

/** tags: updatePDFFloorplanSizeMutation */
export type UpdatePdfFloorplanSizeMutation = {
  __typename?: 'UpdatePDFFloorplanSizeMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  floorplan?: Maybe<FloorPlanType>;
};

/** tags: updatePDFHideFields */
export type UpdatePdfHideFieldsMutation = {
  __typename?: 'UpdatePDFHideFieldsMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  project?: Maybe<ProjectType>;
};

/** tags: updatePDFImage */
export type UpdatePdfImageMutation = {
  __typename?: 'UpdatePDFImageMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  project?: Maybe<ProjectType>;
};

/** tags: updatePDFImageSize */
export type UpdatePdfImageSizeMutation = {
  __typename?: 'UpdatePDFImageSizeMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  project?: Maybe<ProjectType>;
};

/**
 * tags: updatePdfItemImageSize
 *
 * NOTE: This function looks through ES for the products and only returns
 *       a list of found items. (Might cause issues on staging/local)
 *      If you did a django db sync but not an ES sync some products will
 *      not be available on ES
 */
export type UpdatePdfItemImageSizeMutation = {
  __typename?: 'UpdatePDFItemImageSizeMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  bucketItem?: Maybe<ProductAndItemType>;
};

/** tags: updatePdfLocationImage */
export type UpdatePdfLocationImageMutation = {
  __typename?: 'UpdatePDFLocationImageMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  location?: Maybe<LocationItemType>;
};

/** tags: updatePdfLocationImageSize */
export type UpdatePdfLocationImageSizeMutation = {
  __typename?: 'UpdatePDFLocationImageSizeMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  location?: Maybe<LocationItemType>;
};

/** tags: updatePdfPageImage */
export type UpdatePdfPageImageMutation = {
  __typename?: 'UpdatePDFPageImageMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  page?: Maybe<PdfPageType>;
};

/** tags: updatePdfPageImageSize */
export type UpdatePdfPageImageSizeMutation = {
  __typename?: 'UpdatePDFPageImageSizeMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  page?: Maybe<PdfPageType>;
};

/** tags: updatePageDetail */
export type UpdatePageDetailMutation = {
  __typename?: 'UpdatePageDetailMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  page?: Maybe<PdfPageType>;
};

export type UpdatePasswordMutation = {
  __typename?: 'UpdatePasswordMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
};

export type UpdateProjectBudgetMutation = {
  __typename?: 'UpdateProjectBudgetMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  project?: Maybe<ProjectType>;
  autoSubmit?: Maybe<Scalars['String']>;
};

/** tags: updateProjectLocationOverview */
export type UpdateProjectLocationOverviewMutation = {
  __typename?: 'UpdateProjectLocationOverviewMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  autoSubmit?: Maybe<Scalars['String']>;
  project?: Maybe<ProjectType>;
};

export type UpdateProjectOverviewMutation = {
  __typename?: 'UpdateProjectOverviewMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  project?: Maybe<ProjectType>;
};

/** tags: updateProjectTimeline */
export type UpdateProjectTimelineMutation = {
  __typename?: 'UpdateProjectTimelineMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  project?: Maybe<ProjectType>;
  autoSubmit?: Maybe<Scalars['String']>;
};

export type UpdateProjectTitleMutation = {
  __typename?: 'UpdateProjectTitleMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  project?: Maybe<ProjectType>;
  autoSubmit?: Maybe<Scalars['String']>;
};

export type UpdateProjectTypeMutation = {
  __typename?: 'UpdateProjectTypeMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  project?: Maybe<ProjectType>;
  autoSubmit?: Maybe<Scalars['String']>;
};

/** tags: updateProposalActivePages */
export type UpdateProposalActivePagesMutation = {
  __typename?: 'UpdateProposalActivePagesMutation';
  status?: Maybe<Scalars['Int']>;
  proposal?: Maybe<PdfProposalType>;
};

/** tags: updateProposalContact */
export type UpdateProposalContactMutation = {
  __typename?: 'UpdateProposalContactMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  proposal?: Maybe<PdfProposalType>;
};

/** tags: updateProposalHideFields */
export type UpdateProposalHideFieldsMutation = {
  __typename?: 'UpdateProposalHideFieldsMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  proposal?: Maybe<PdfProposalType>;
};

/** tags: updateProposalLogo */
export type UpdateProposalLogoMutation = {
  __typename?: 'UpdateProposalLogoMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  proposal?: Maybe<PdfProposalType>;
};

/** tags: updateProposalPagesPosition */
export type UpdateProposalPagesPositionMutation = {
  __typename?: 'UpdateProposalPagesPositionMutation';
  status?: Maybe<Scalars['Int']>;
  proposal?: Maybe<PdfProposalType>;
};

export type UpdateQuotationBillingAddressMutation = {
  __typename?: 'UpdateQuotationBillingAddressMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  project?: Maybe<ProjectType>;
};

export type UpdateQuotationShippingAddressMutation = {
  __typename?: 'UpdateQuotationShippingAddressMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
  project?: Maybe<ProjectType>;
};

/**
 * Allows a user to accept the uploaded quotation.
 *
 * Used in ProjectReviewQuotationView in ModalAcceptQuotation.
 *
 * tags: user_accept_quotation, userAcceptQuotation
 */
export type UserAcceptQuotationMutation = {
  __typename?: 'UserAcceptQuotationMutation';
  status?: Maybe<Scalars['Int']>;
  formErrors?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum UserProfileIndustry {
  /** Architecture */
  A_1 = 'A_1',
  /** Interior Design */
  A_2 = 'A_2',
  /** Hospitality */
  A_3 = 'A_3',
  /** Real Estate */
  A_4 = 'A_4',
  /** Property Developer */
  A_5 = 'A_5',
  /** Others */
  A_6 = 'A_6'
}

/** GQL Type for the UserProfile model. */
export type UserProfileType = {
  __typename?: 'UserProfileType';
  id: Scalars['ID'];
  user: UserType;
  title?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNo?: Maybe<Scalars['String']>;
  companyName: Scalars['String'];
  companyWebsite: Scalars['String'];
  industry: UserProfileIndustry;
  userStreet: Scalars['String'];
  userCity: Scalars['String'];
  userCountry: UserProfileUserCountry;
  userZip: Scalars['String'];
  userPhoneNo?: Maybe<Scalars['String']>;
  userCompanyName: Scalars['String'];
  joyrideSearchViewCompleted: Scalars['Boolean'];
  joyrideShortlistedViewCompleted: Scalars['Boolean'];
  lastSourced: Scalars['String'];
  hasTradeDiscount: Scalars['Boolean'];
  userVirImages: Array<UserVirImageType>;
  isSuperuser?: Maybe<Scalars['Boolean']>;
  userCountryName?: Maybe<Scalars['String']>;
  userCountryCode?: Maybe<Scalars['String']>;
  crispUserHash: Scalars['String'];
};

/** An enumeration. */
export enum UserProfileUserCountry {
  /** Afghanistan */
  Af = 'AF',
  /** Åland Islands */
  Ax = 'AX',
  /** Albania */
  Al = 'AL',
  /** Algeria */
  Dz = 'DZ',
  /** American Samoa */
  As = 'AS',
  /** Andorra */
  Ad = 'AD',
  /** Angola */
  Ao = 'AO',
  /** Anguilla */
  Ai = 'AI',
  /** Antarctica */
  Aq = 'AQ',
  /** Antigua and Barbuda */
  Ag = 'AG',
  /** Argentina */
  Ar = 'AR',
  /** Armenia */
  Am = 'AM',
  /** Aruba */
  Aw = 'AW',
  /** Australia */
  Au = 'AU',
  /** Austria */
  At = 'AT',
  /** Azerbaijan */
  Az = 'AZ',
  /** Bahamas */
  Bs = 'BS',
  /** Bahrain */
  Bh = 'BH',
  /** Bangladesh */
  Bd = 'BD',
  /** Barbados */
  Bb = 'BB',
  /** Belarus */
  By = 'BY',
  /** Belgium */
  Be = 'BE',
  /** Belize */
  Bz = 'BZ',
  /** Benin */
  Bj = 'BJ',
  /** Bermuda */
  Bm = 'BM',
  /** Bhutan */
  Bt = 'BT',
  /** Bolivia */
  Bo = 'BO',
  /** Bonaire, Sint Eustatius and Saba */
  Bq = 'BQ',
  /** Bosnia and Herzegovina */
  Ba = 'BA',
  /** Botswana */
  Bw = 'BW',
  /** Bouvet Island */
  Bv = 'BV',
  /** Brazil */
  Br = 'BR',
  /** British Indian Ocean Territory */
  Io = 'IO',
  /** Brunei */
  Bn = 'BN',
  /** Bulgaria */
  Bg = 'BG',
  /** Burkina Faso */
  Bf = 'BF',
  /** Burundi */
  Bi = 'BI',
  /** Cabo Verde */
  Cv = 'CV',
  /** Cambodia */
  Kh = 'KH',
  /** Cameroon */
  Cm = 'CM',
  /** Canada */
  Ca = 'CA',
  /** Cayman Islands */
  Ky = 'KY',
  /** Central African Republic */
  Cf = 'CF',
  /** Chad */
  Td = 'TD',
  /** Chile */
  Cl = 'CL',
  /** China */
  Cn = 'CN',
  /** Christmas Island */
  Cx = 'CX',
  /** Cocos (Keeling) Islands */
  Cc = 'CC',
  /** Colombia */
  Co = 'CO',
  /** Comoros */
  Km = 'KM',
  /** Congo */
  Cg = 'CG',
  /** Congo (the Democratic Republic of the) */
  Cd = 'CD',
  /** Cook Islands */
  Ck = 'CK',
  /** Costa Rica */
  Cr = 'CR',
  /** Côte d'Ivoire */
  Ci = 'CI',
  /** Croatia */
  Hr = 'HR',
  /** Cuba */
  Cu = 'CU',
  /** Curaçao */
  Cw = 'CW',
  /** Cyprus */
  Cy = 'CY',
  /** Czechia */
  Cz = 'CZ',
  /** Denmark */
  Dk = 'DK',
  /** Djibouti */
  Dj = 'DJ',
  /** Dominica */
  Dm = 'DM',
  /** Dominican Republic */
  Do = 'DO',
  /** Ecuador */
  Ec = 'EC',
  /** Egypt */
  Eg = 'EG',
  /** El Salvador */
  Sv = 'SV',
  /** Equatorial Guinea */
  Gq = 'GQ',
  /** Eritrea */
  Er = 'ER',
  /** Estonia */
  Ee = 'EE',
  /** Eswatini */
  Sz = 'SZ',
  /** Ethiopia */
  Et = 'ET',
  /** Falkland Islands  [Malvinas] */
  Fk = 'FK',
  /** Faroe Islands */
  Fo = 'FO',
  /** Fiji */
  Fj = 'FJ',
  /** Finland */
  Fi = 'FI',
  /** France */
  Fr = 'FR',
  /** French Guiana */
  Gf = 'GF',
  /** French Polynesia */
  Pf = 'PF',
  /** French Southern Territories */
  Tf = 'TF',
  /** Gabon */
  Ga = 'GA',
  /** Gambia */
  Gm = 'GM',
  /** Georgia */
  Ge = 'GE',
  /** Germany */
  De = 'DE',
  /** Ghana */
  Gh = 'GH',
  /** Gibraltar */
  Gi = 'GI',
  /** Greece */
  Gr = 'GR',
  /** Greenland */
  Gl = 'GL',
  /** Grenada */
  Gd = 'GD',
  /** Guadeloupe */
  Gp = 'GP',
  /** Guam */
  Gu = 'GU',
  /** Guatemala */
  Gt = 'GT',
  /** Guernsey */
  Gg = 'GG',
  /** Guinea */
  Gn = 'GN',
  /** Guinea-Bissau */
  Gw = 'GW',
  /** Guyana */
  Gy = 'GY',
  /** Haiti */
  Ht = 'HT',
  /** Heard Island and McDonald Islands */
  Hm = 'HM',
  /** Holy See */
  Va = 'VA',
  /** Honduras */
  Hn = 'HN',
  /** Hong Kong */
  Hk = 'HK',
  /** Hungary */
  Hu = 'HU',
  /** Iceland */
  Is = 'IS',
  /** India */
  In = 'IN',
  /** Indonesia */
  Id = 'ID',
  /** Iran */
  Ir = 'IR',
  /** Iraq */
  Iq = 'IQ',
  /** Ireland */
  Ie = 'IE',
  /** Isle of Man */
  Im = 'IM',
  /** Israel */
  Il = 'IL',
  /** Italy */
  It = 'IT',
  /** Jamaica */
  Jm = 'JM',
  /** Japan */
  Jp = 'JP',
  /** Jersey */
  Je = 'JE',
  /** Jordan */
  Jo = 'JO',
  /** Kazakhstan */
  Kz = 'KZ',
  /** Kenya */
  Ke = 'KE',
  /** Kiribati */
  Ki = 'KI',
  /** Kuwait */
  Kw = 'KW',
  /** Kyrgyzstan */
  Kg = 'KG',
  /** Laos */
  La = 'LA',
  /** Latvia */
  Lv = 'LV',
  /** Lebanon */
  Lb = 'LB',
  /** Lesotho */
  Ls = 'LS',
  /** Liberia */
  Lr = 'LR',
  /** Libya */
  Ly = 'LY',
  /** Liechtenstein */
  Li = 'LI',
  /** Lithuania */
  Lt = 'LT',
  /** Luxembourg */
  Lu = 'LU',
  /** Macao */
  Mo = 'MO',
  /** Madagascar */
  Mg = 'MG',
  /** Malawi */
  Mw = 'MW',
  /** Malaysia */
  My = 'MY',
  /** Maldives */
  Mv = 'MV',
  /** Mali */
  Ml = 'ML',
  /** Malta */
  Mt = 'MT',
  /** Marshall Islands */
  Mh = 'MH',
  /** Martinique */
  Mq = 'MQ',
  /** Mauritania */
  Mr = 'MR',
  /** Mauritius */
  Mu = 'MU',
  /** Mayotte */
  Yt = 'YT',
  /** Mexico */
  Mx = 'MX',
  /** Micronesia (Federated States of) */
  Fm = 'FM',
  /** Moldova */
  Md = 'MD',
  /** Monaco */
  Mc = 'MC',
  /** Mongolia */
  Mn = 'MN',
  /** Montenegro */
  Me = 'ME',
  /** Montserrat */
  Ms = 'MS',
  /** Morocco */
  Ma = 'MA',
  /** Mozambique */
  Mz = 'MZ',
  /** Myanmar */
  Mm = 'MM',
  /** Namibia */
  Na = 'NA',
  /** Nauru */
  Nr = 'NR',
  /** Nepal */
  Np = 'NP',
  /** Netherlands */
  Nl = 'NL',
  /** New Caledonia */
  Nc = 'NC',
  /** New Zealand */
  Nz = 'NZ',
  /** Nicaragua */
  Ni = 'NI',
  /** Niger */
  Ne = 'NE',
  /** Nigeria */
  Ng = 'NG',
  /** Niue */
  Nu = 'NU',
  /** Norfolk Island */
  Nf = 'NF',
  /** North Korea */
  Kp = 'KP',
  /** North Macedonia */
  Mk = 'MK',
  /** Northern Mariana Islands */
  Mp = 'MP',
  /** Norway */
  No = 'NO',
  /** Oman */
  Om = 'OM',
  /** Pakistan */
  Pk = 'PK',
  /** Palau */
  Pw = 'PW',
  /** Palestine, State of */
  Ps = 'PS',
  /** Panama */
  Pa = 'PA',
  /** Papua New Guinea */
  Pg = 'PG',
  /** Paraguay */
  Py = 'PY',
  /** Peru */
  Pe = 'PE',
  /** Philippines */
  Ph = 'PH',
  /** Pitcairn */
  Pn = 'PN',
  /** Poland */
  Pl = 'PL',
  /** Portugal */
  Pt = 'PT',
  /** Puerto Rico */
  Pr = 'PR',
  /** Qatar */
  Qa = 'QA',
  /** Réunion */
  Re = 'RE',
  /** Romania */
  Ro = 'RO',
  /** Russia */
  Ru = 'RU',
  /** Rwanda */
  Rw = 'RW',
  /** Saint Barthélemy */
  Bl = 'BL',
  /** Saint Helena, Ascension and Tristan da Cunha */
  Sh = 'SH',
  /** Saint Kitts and Nevis */
  Kn = 'KN',
  /** Saint Lucia */
  Lc = 'LC',
  /** Saint Martin (French part) */
  Mf = 'MF',
  /** Saint Pierre and Miquelon */
  Pm = 'PM',
  /** Saint Vincent and the Grenadines */
  Vc = 'VC',
  /** Samoa */
  Ws = 'WS',
  /** San Marino */
  Sm = 'SM',
  /** Sao Tome and Principe */
  St = 'ST',
  /** Saudi Arabia */
  Sa = 'SA',
  /** Senegal */
  Sn = 'SN',
  /** Serbia */
  Rs = 'RS',
  /** Seychelles */
  Sc = 'SC',
  /** Sierra Leone */
  Sl = 'SL',
  /** Singapore */
  Sg = 'SG',
  /** Sint Maarten (Dutch part) */
  Sx = 'SX',
  /** Slovakia */
  Sk = 'SK',
  /** Slovenia */
  Si = 'SI',
  /** Solomon Islands */
  Sb = 'SB',
  /** Somalia */
  So = 'SO',
  /** South Africa */
  Za = 'ZA',
  /** South Georgia and the South Sandwich Islands */
  Gs = 'GS',
  /** South Korea */
  Kr = 'KR',
  /** South Sudan */
  Ss = 'SS',
  /** Spain */
  Es = 'ES',
  /** Sri Lanka */
  Lk = 'LK',
  /** Sudan */
  Sd = 'SD',
  /** Suriname */
  Sr = 'SR',
  /** Svalbard and Jan Mayen */
  Sj = 'SJ',
  /** Sweden */
  Se = 'SE',
  /** Switzerland */
  Ch = 'CH',
  /** Syria */
  Sy = 'SY',
  /** Taiwan */
  Tw = 'TW',
  /** Tajikistan */
  Tj = 'TJ',
  /** Tanzania */
  Tz = 'TZ',
  /** Thailand */
  Th = 'TH',
  /** Timor-Leste */
  Tl = 'TL',
  /** Togo */
  Tg = 'TG',
  /** Tokelau */
  Tk = 'TK',
  /** Tonga */
  To = 'TO',
  /** Trinidad and Tobago */
  Tt = 'TT',
  /** Tunisia */
  Tn = 'TN',
  /** Turkey */
  Tr = 'TR',
  /** Turkmenistan */
  Tm = 'TM',
  /** Turks and Caicos Islands */
  Tc = 'TC',
  /** Tuvalu */
  Tv = 'TV',
  /** Uganda */
  Ug = 'UG',
  /** Ukraine */
  Ua = 'UA',
  /** United Arab Emirates */
  Ae = 'AE',
  /** United Kingdom */
  Gb = 'GB',
  /** United States Minor Outlying Islands */
  Um = 'UM',
  /** United States of America */
  Us = 'US',
  /** Uruguay */
  Uy = 'UY',
  /** Uzbekistan */
  Uz = 'UZ',
  /** Vanuatu */
  Vu = 'VU',
  /** Venezuela */
  Ve = 'VE',
  /** Vietnam */
  Vn = 'VN',
  /** Virgin Islands (British) */
  Vg = 'VG',
  /** Virgin Islands (U.S.) */
  Vi = 'VI',
  /** Wallis and Futuna */
  Wf = 'WF',
  /** Western Sahara */
  Eh = 'EH',
  /** Yemen */
  Ye = 'YE',
  /** Zambia */
  Zm = 'ZM',
  /** Zimbabwe */
  Zw = 'ZW'
}

export type UserType = {
  __typename?: 'UserType';
  id: Scalars['ID'];
  password: Scalars['String'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser: Scalars['Boolean'];
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars['Boolean'];
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars['Boolean'];
  dateJoined: Scalars['DateTime'];
  quotationsSubmitted?: Maybe<Array<Maybe<QuotationType>>>;
  quotationsCompleted?: Maybe<Array<Maybe<QuotationType>>>;
  quotationsAccepted?: Maybe<Array<Maybe<QuotationType>>>;
  projects?: Maybe<Array<Maybe<ProjectType>>>;
  sharedProjects?: Maybe<Array<Maybe<ProjectType>>>;
  signupkeySet?: Maybe<Array<Maybe<SignupKeyType>>>;
  favorites?: Maybe<Array<Maybe<FavoriteType>>>;
  clients?: Maybe<Array<Maybe<ClientType>>>;
  feedback?: Maybe<Array<Maybe<FeedbackType>>>;
  inquirySet?: Maybe<Array<Maybe<InquiryType>>>;
  recentprojectSet?: Maybe<Array<Maybe<RecentProjectType>>>;
  framerSet?: Maybe<Array<Maybe<FramerType>>>;
  userprofile?: Maybe<UserProfileType>;
  currentstateSet?: Maybe<Array<Maybe<CurerntStateType>>>;
};

/** GQL Type for the UserVIRImage model. */
export type UserVirImageType = {
  __typename?: 'UserVIRImageType';
  id: Scalars['ID'];
  externalId?: Maybe<Scalars['Int']>;
  userProfile: UserProfileType;
  image?: Maybe<Scalars['String']>;
  imageFilename: Scalars['String'];
  deleted: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type LocationItemBucketQueryVariables = Exact<{
  hashId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  locationItemId?: Maybe<Scalars['String']>;
}>;


export type LocationItemBucketQuery = (
  { __typename?: 'Queries' }
  & { locationItem?: Maybe<(
    { __typename?: 'LocationItemType' }
    & Pick<LocationItemType, 'id' | 'externalId'>
    & { location: (
      { __typename?: 'LocationType' }
      & Pick<LocationType, 'id' | 'title'>
      & { project: (
        { __typename?: 'ProjectType' }
        & Pick<ProjectType, 'id' | 'title'>
      ) }
    ) }
    & LocationItemDetailFragmentFragment
  )> }
);

export type ButtonAddToBucketQueryVariables = Exact<{ [key: string]: never; }>;


export type ButtonAddToBucketQuery = (
  { __typename?: 'Queries' }
  & { projects: (
    { __typename?: 'ProjectAggrType' }
    & { projects?: Maybe<Array<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id'>
    )>> }
  ) }
);

export type ButtonLogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type ButtonLogoutMutation = (
  { __typename?: 'Mutations' }
  & { logout?: Maybe<(
    { __typename?: 'LogoutMutation' }
    & Pick<LogoutMutation, 'status'>
  )> }
);

export type ButtonReplayJoyrideInSearchViewMutationVariables = Exact<{ [key: string]: never; }>;


export type ButtonReplayJoyrideInSearchViewMutation = (
  { __typename?: 'Mutations' }
  & { replayJoyrideSearchView?: Maybe<(
    { __typename?: 'ReplayJoyrideSearchViewMutation' }
    & Pick<ReplayJoyrideSearchViewMutation, 'status'>
  )> }
);

export type ButtonReplayJoyrideInShortlistedViewMutationVariables = Exact<{ [key: string]: never; }>;


export type ButtonReplayJoyrideInShortlistedViewMutation = (
  { __typename?: 'Mutations' }
  & { replayJoyrideShortlistedView?: Maybe<(
    { __typename?: 'ReplayJoyrideShortlistedViewMutation' }
    & Pick<ReplayJoyrideShortlistedViewMutation, 'status'>
  )> }
);

export type ButtonToggleBucketMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ButtonToggleBucketMutation = (
  { __typename?: 'Mutations' }
  & { addOrRemoveBucketItem?: Maybe<(
    { __typename?: 'AddOrRemoveBucketItemMutation' }
    & Pick<AddOrRemoveBucketItemMutation, 'status' | 'formErrors'>
    & { project?: Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id' | 'bucketItemsCount'>
    )>, locationItem?: Maybe<(
      { __typename?: 'LocationItemType' }
      & Pick<LocationItemType, 'id'>
      & { productsAndBucketItems: Array<(
        { __typename?: 'ProductAndItemType' }
        & Pick<ProductAndItemType, 'id'>
        & { product: (
          { __typename?: 'ProductType' }
          & Pick<ProductType, 'id' | 'esId'>
        ), bucketItem: (
          { __typename?: 'BucketItemType' }
          & Pick<BucketItemType, 'id' | 'selectedSize'>
        ) }
      )> }
    )> }
  )> }
);

export type ButtonToggleBucketSizePopupSizeFragment = (
  { __typename?: 'SizeType' }
  & Pick<SizeType, 'id' | 'width' | 'height' | 'thickness' | 'diameter' | 'price'>
  & { printBaseCost?: Maybe<(
    { __typename?: 'PrintBaseCostType' }
    & Pick<PrintBaseCostType, 'printSize'>
  )> }
);

export type ButtonToggleFavoriteMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ButtonToggleFavoriteMutation = (
  { __typename?: 'Mutations' }
  & { toggleFavorite?: Maybe<(
    { __typename?: 'ToggleFavoriteMutation' }
    & Pick<ToggleFavoriteMutation, 'status'>
  )> }
);

export type FavFragment = (
  { __typename?: 'ProductType' }
  & Pick<ProductType, 'favorited'>
);

export type UserWizardAddAreaButtonMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type UserWizardAddAreaButtonMutation = (
  { __typename?: 'Mutations' }
  & { createNewLocation?: Maybe<(
    { __typename?: 'CreateNewLocationMutation' }
    & Pick<CreateNewLocationMutation, 'status'>
    & { project?: Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id'>
      & UserWizardProjectLocationItemFragmentFragment
    )> }
  )> }
);

export type UserWizardAddLocationButtonMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type UserWizardAddLocationButtonMutation = (
  { __typename?: 'Mutations' }
  & { createNewLocationItem?: Maybe<(
    { __typename?: 'CreateNewLocationItemMutation' }
    & Pick<CreateNewLocationItemMutation, 'status'>
    & { project?: Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id'>
      & UserWizardProjectLocationItemFragmentFragment
    )> }
  )> }
);

export type CanvasSceneUpdateLocationItemPointsMutationVariables = Exact<{
  locItemId?: Maybe<Scalars['String']>;
  pt1?: Maybe<Scalars['String']>;
  pt2?: Maybe<Scalars['String']>;
  pt3?: Maybe<Scalars['String']>;
  pt4?: Maybe<Scalars['String']>;
}>;


export type CanvasSceneUpdateLocationItemPointsMutation = (
  { __typename?: 'Mutations' }
  & { updateLocationItemPoints?: Maybe<(
    { __typename?: 'UpdateLocationItemPointsMutation' }
    & Pick<UpdateLocationItemPointsMutation, 'status'>
    & { locationItem?: Maybe<(
      { __typename?: 'LocationItemType' }
      & Pick<LocationItemType, 'id'>
      & LocationItemFragmentFragment
    )> }
  )> }
);

export type CanvasSceneUpdateBucketItemWallPtMutationVariables = Exact<{
  locItemId?: Maybe<Scalars['String']>;
  esId?: Maybe<Scalars['String']>;
  wallPt?: Maybe<Scalars['String']>;
}>;


export type CanvasSceneUpdateBucketItemWallPtMutation = (
  { __typename?: 'Mutations' }
  & { updateBucketItemWallPt?: Maybe<(
    { __typename?: 'UpdateBucketItemWallPtMutation' }
    & Pick<UpdateBucketItemWallPtMutation, 'status'>
    & { bucketItem?: Maybe<(
      { __typename?: 'BucketItemType' }
      & Pick<BucketItemType, 'id' | 'esId' | 'wallPt'>
    )> }
  )> }
);

export type ElevationsRenderQueryVariables = Exact<{
  esId?: Maybe<Scalars['String']>;
  hashId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  locationItemId?: Maybe<Scalars['String']>;
  originalId?: Maybe<Scalars['Boolean']>;
}>;


export type ElevationsRenderQuery = (
  { __typename?: 'Queries' }
  & { currentProfile?: Maybe<(
    { __typename?: 'UserProfileType' }
    & Pick<UserProfileType, 'id'>
    & { userVirImages: Array<(
      { __typename?: 'UserVIRImageType' }
      & Pick<UserVirImageType, 'id' | 'externalId' | 'image' | 'imageFilename'>
    )> }
  )>, locationItem?: Maybe<(
    { __typename?: 'LocationItemType' }
    & Pick<LocationItemType, 'id'>
    & ElevationsRenderLocationItemFragmentFragment
  )>, getProduct?: Maybe<(
    { __typename?: 'ProductType' }
    & Pick<ProductType, 'id'>
    & ProductFragmentFragment
  )> }
);

export type ElevationsRenderLocationItemFragmentFragment = (
  { __typename?: 'LocationItemType' }
  & Pick<LocationItemType, 'id'>
  & { locationItemImages?: Maybe<Array<(
    { __typename?: 'LocationItemImageType' }
    & Pick<LocationItemImageType, 'id' | 'image' | 'imageFilename'>
  )>>, vimrImages: Array<(
    { __typename?: 'ProjectVIMRImageType' }
    & Pick<ProjectVimrImageType, 'id' | 'image' | 'imageFilename'>
  )>, location: (
    { __typename?: 'LocationType' }
    & Pick<LocationType, 'id' | 'externalId'>
    & { project: (
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id' | 'hashId'>
    ) }
  ) }
  & LocationItemVimrFragmentFragment
  & LocationItemFragmentFragment
);

export type ElevationsRenderUpdateVimrImagesMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ElevationsRenderUpdateVimrImagesMutation = (
  { __typename?: 'Mutations' }
  & { createUpdateVimrImages?: Maybe<(
    { __typename?: 'CreateVIMRImagesMutation' }
    & Pick<CreateVimrImagesMutation, 'status' | 'formErrors' | 'autoSubmit'>
    & { project?: Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id'>
      & { vimrImages: Array<(
        { __typename?: 'ProjectVIMRImageType' }
        & Pick<ProjectVimrImageType, 'id'>
      )> }
    )>, locationItem?: Maybe<(
      { __typename?: 'LocationItemType' }
      & Pick<LocationItemType, 'id'>
      & { vimrImages: Array<(
        { __typename?: 'ProjectVIMRImageType' }
        & Pick<ProjectVimrImageType, 'id' | 'image' | 'imageFilename'>
      )> }
    )>, bucketItems?: Maybe<Array<(
      { __typename?: 'BucketItemType' }
      & Pick<BucketItemType, 'id'>
      & { vimrImages: Array<(
        { __typename?: 'ProjectVIMRImageType' }
        & Pick<ProjectVimrImageType, 'id' | 'image'>
      )> }
    )>> }
  )> }
);

export type ElevationsRenderUpdateLocationPointsMutationVariables = Exact<{
  locItemId?: Maybe<Scalars['String']>;
  pt1?: Maybe<Scalars['String']>;
  pt2?: Maybe<Scalars['String']>;
  pt3?: Maybe<Scalars['String']>;
  pt4?: Maybe<Scalars['String']>;
}>;


export type ElevationsRenderUpdateLocationPointsMutation = (
  { __typename?: 'Mutations' }
  & { updateLocationItemPoints?: Maybe<(
    { __typename?: 'UpdateLocationItemPointsMutation' }
    & Pick<UpdateLocationItemPointsMutation, 'status'>
    & { locationItem?: Maybe<(
      { __typename?: 'LocationItemType' }
      & Pick<LocationItemType, 'id'>
      & LocationItemVimrFragmentFragment
    )> }
  )> }
);

export type ImageRenderViewQueryVariables = Exact<{
  esId?: Maybe<Scalars['String']>;
  hashId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  locationItemId?: Maybe<Scalars['String']>;
  originalId?: Maybe<Scalars['Boolean']>;
}>;


export type ImageRenderViewQuery = (
  { __typename?: 'Queries' }
  & { currentProfile?: Maybe<(
    { __typename?: 'UserProfileType' }
    & Pick<UserProfileType, 'id'>
    & { userVirImages: Array<(
      { __typename?: 'UserVIRImageType' }
      & Pick<UserVirImageType, 'id' | 'externalId' | 'image' | 'imageFilename'>
    )> }
  )>, locationItem?: Maybe<(
    { __typename?: 'LocationItemType' }
    & Pick<LocationItemType, 'id'>
    & ImageRenderLocationItemFragmentFragment
  )>, getProduct?: Maybe<(
    { __typename?: 'ProductType' }
    & Pick<ProductType, 'id'>
    & ProductFragmentFragment
  )> }
);

export type ImageRenderLocationItemFragmentFragment = (
  { __typename?: 'LocationItemType' }
  & Pick<LocationItemType, 'id'>
  & { locationItemImages?: Maybe<Array<(
    { __typename?: 'LocationItemImageType' }
    & Pick<LocationItemImageType, 'id' | 'image' | 'imageFilename'>
  )>>, vimrImages: Array<(
    { __typename?: 'ProjectVIMRImageType' }
    & Pick<ProjectVimrImageType, 'id' | 'image' | 'imageFilename'>
  )>, location: (
    { __typename?: 'LocationType' }
    & Pick<LocationType, 'id' | 'externalId'>
    & { project: (
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id' | 'hashId'>
    ) }
  ) }
  & LocationItemVimrFragmentFragment
  & LocationItemFragmentFragment
);

export type ImageRenderViewUpdateVimrImagesMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ImageRenderViewUpdateVimrImagesMutation = (
  { __typename?: 'Mutations' }
  & { createUpdateVimrImages?: Maybe<(
    { __typename?: 'CreateVIMRImagesMutation' }
    & Pick<CreateVimrImagesMutation, 'status' | 'formErrors' | 'autoSubmit'>
    & { project?: Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id'>
      & { vimrImages: Array<(
        { __typename?: 'ProjectVIMRImageType' }
        & Pick<ProjectVimrImageType, 'id'>
      )> }
    )>, locationItem?: Maybe<(
      { __typename?: 'LocationItemType' }
      & Pick<LocationItemType, 'id'>
      & { vimrImages: Array<(
        { __typename?: 'ProjectVIMRImageType' }
        & Pick<ProjectVimrImageType, 'id' | 'image' | 'imageFilename'>
      )> }
    )>, bucketItems?: Maybe<Array<(
      { __typename?: 'BucketItemType' }
      & Pick<BucketItemType, 'id'>
      & { vimrImages: Array<(
        { __typename?: 'ProjectVIMRImageType' }
        & Pick<ProjectVimrImageType, 'id' | 'image'>
      )> }
    )>> }
  )> }
);

export type ImageRenderMutationMutationVariables = Exact<{
  locItemId?: Maybe<Scalars['String']>;
  pt1?: Maybe<Scalars['String']>;
  pt2?: Maybe<Scalars['String']>;
  pt3?: Maybe<Scalars['String']>;
  pt4?: Maybe<Scalars['String']>;
}>;


export type ImageRenderMutationMutation = (
  { __typename?: 'Mutations' }
  & { updateLocationItemPoints?: Maybe<(
    { __typename?: 'UpdateLocationItemPointsMutation' }
    & Pick<UpdateLocationItemPointsMutation, 'status'>
    & { locationItem?: Maybe<(
      { __typename?: 'LocationItemType' }
      & Pick<LocationItemType, 'id'>
      & LocationItemVimrFragmentFragment
    )> }
  )> }
);

export type ImageRenderViewLocationRotationMutationVariables = Exact<{
  locItemId?: Maybe<Scalars['String']>;
  rotate?: Maybe<Scalars['Int']>;
}>;


export type ImageRenderViewLocationRotationMutation = (
  { __typename?: 'Mutations' }
  & { updateLocationRotation?: Maybe<(
    { __typename?: 'UpdateLocationRotationMutation' }
    & Pick<UpdateLocationRotationMutation, 'status'>
    & { locationItem?: Maybe<(
      { __typename?: 'LocationItemType' }
      & Pick<LocationItemType, 'id'>
      & LocationItemFragmentFragment
    )> }
  )> }
);

export type SearchResultsGridMutationVariables = Exact<{ [key: string]: never; }>;


export type SearchResultsGridMutation = (
  { __typename?: 'Mutations' }
  & { setJoyrideSearchView?: Maybe<(
    { __typename?: 'SetJoyrideSearchViewMutation' }
    & Pick<SetJoyrideSearchViewMutation, 'status'>
    & { currentProfile?: Maybe<(
      { __typename?: 'UserProfileType' }
      & Pick<UserProfileType, 'id'>
      & CurrentProfileFragmentFragment
    )> }
  )> }
);

export type SimilarImageQueryVariables = Exact<{
  queryString?: Maybe<Scalars['String']>;
}>;


export type SimilarImageQuery = (
  { __typename?: 'Queries' }
  & Pick<Queries, 'getSimilarImage'>
);

export type ProductFiltersQueryVariables = Exact<{
  locDetails?: Maybe<Scalars['String']>;
}>;


export type ProductFiltersQuery = (
  { __typename?: 'Queries' }
  & Pick<Queries, 'getFilters'>
);

export type NewsletterSignupFormMutationVariables = Exact<{
  email?: Maybe<Scalars['String']>;
}>;


export type NewsletterSignupFormMutation = (
  { __typename?: 'Mutations' }
  & { newsletterSignup?: Maybe<(
    { __typename?: 'NewsletterSignupMutation' }
    & Pick<NewsletterSignupMutation, 'formErrors' | 'status'>
  )> }
);

export type AddArtistVariantFormArtistVariantUpdateMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type AddArtistVariantFormArtistVariantUpdateMutation = (
  { __typename?: 'Mutations' }
  & { artistVariantUpdateCreate?: Maybe<(
    { __typename?: 'ArtistVariantUpdateCreateMutation' }
    & Pick<ArtistVariantUpdateCreateMutation, 'status' | 'formErrors'>
    & { artistVariant?: Maybe<(
      { __typename?: 'ArtistVariantType' }
      & Pick<ArtistVariantType, 'id' | 'tags' | 'priceLow' | 'priceHigh' | 'priceRange' | 'dataRequestDate' | 'notes'>
      & { artist?: Maybe<(
        { __typename?: 'ArtistSourcingType' }
        & Pick<ArtistSourcingType, 'id' | 'name'>
      )>, galleries?: Maybe<Array<Maybe<(
        { __typename?: 'GallerySourcingType' }
        & Pick<GallerySourcingType, 'id' | 'name'>
      )>>>, medium?: Maybe<(
        { __typename?: 'MediumType' }
        & Pick<MediumType, 'id' | 'title'>
      )>, artworkStyle?: Maybe<(
        { __typename?: 'StyleType' }
        & Pick<StyleType, 'id' | 'title'>
      )>, productImages: Array<(
        { __typename?: 'ArtistProductImage' }
        & Pick<ArtistProductImage, 'id' | 'esId' | 'imageKey'>
      )> }
    )> }
  )> }
);

export type AddArtistVariantFormArtistVariantProjectDeleteMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type AddArtistVariantFormArtistVariantProjectDeleteMutation = (
  { __typename?: 'Mutations' }
  & { artistVariantProjectDelete?: Maybe<(
    { __typename?: 'ArtistVariantProjectDeleteMutation' }
    & Pick<ArtistVariantProjectDeleteMutation, 'status'>
    & { artistVariant?: Maybe<(
      { __typename?: 'ArtistVariantType' }
      & Pick<ArtistVariantType, 'id'>
      & { projects: Array<(
        { __typename?: 'ArtistVariantProjectType' }
        & Pick<ArtistVariantProjectType, 'id'>
        & ArtistVariantProjectFragmentFragment
      )> }
    )> }
  )> }
);

export type AddCroppedImageCachedFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type AddCroppedImageCachedFormMutation = (
  { __typename?: 'Mutations' }
  & { addCroppedImageCache?: Maybe<(
    { __typename?: 'AddCroppedImageCacheMutation' }
    & Pick<AddCroppedImageCacheMutation, 'status'>
    & { croppedImg?: Maybe<(
      { __typename?: 'CroppedImageCacheType' }
      & Pick<CroppedImageCacheType, 'id' | 'croppedKey' | 'key' | 'imageHash'>
    )> }
  )> }
);

export type AddSourcedProductMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type AddSourcedProductMutation = (
  { __typename?: 'Mutations' }
  & { sourcedProductUpdateCreate?: Maybe<(
    { __typename?: 'SourcedProductUpdateCreateMutation' }
    & Pick<SourcedProductUpdateCreateMutation, 'status' | 'formErrors'>
    & { project?: Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id' | 'bucketItemsCount'>
      & UserWizardProjectLocationItemFragmentFragment
    )> }
  )> }
);

export type ArtistTypesenseQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
}>;


export type ArtistTypesenseQuery = (
  { __typename?: 'Queries' }
  & { artistsTypesense: Array<(
    { __typename?: 'ArtistDetailType' }
    & Pick<ArtistDetailType, 'artistName' | 'artistSlug' | 'artistContactEmail' | 'artistYearOfBirth' | 'artistCountry' | 'artistWebsite'>
  )> }
);

export type GalleryTypesenseQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
}>;


export type GalleryTypesenseQuery = (
  { __typename?: 'Queries' }
  & { galleriesTypesense: Array<(
    { __typename?: 'GalleryDetailType' }
    & Pick<GalleryDetailType, 'galleryName' | 'gallerySlug' | 'galleryContactEmail' | 'galleryContactNumber' | 'galleryWebsite'>
  )> }
);

export type AddToExistingShortlistFormQueryVariables = Exact<{ [key: string]: never; }>;


export type AddToExistingShortlistFormQuery = (
  { __typename?: 'Queries' }
  & { projects: (
    { __typename?: 'ProjectAggrType' }
    & { projects?: Maybe<Array<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'hashId' | 'title'>
      & { locations: Array<(
        { __typename?: 'LocationType' }
        & Pick<LocationType, 'externalId' | 'titleStr'>
        & { locationItems: Array<(
          { __typename?: 'LocationItemType' }
          & Pick<LocationItemType, 'externalId' | 'titleStr'>
        )> }
      )> }
    )>> }
  ) }
);

export type AddToNewShortlistFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type AddToNewShortlistFormMutation = (
  { __typename?: 'Mutations' }
  & { createQuickSource?: Maybe<(
    { __typename?: 'CreateQuickSourceMutation' }
    & Pick<CreateQuickSourceMutation, 'status' | 'formErrors'>
    & { project?: Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id'>
    )> }
  )> }
);

export type ArtistVariantProjectFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ArtistVariantProjectFormMutation = (
  { __typename?: 'Mutations' }
  & { artistVariantProjectCreateUpdate?: Maybe<(
    { __typename?: 'ArtistVariantProjectCreateOrUpdateMutation' }
    & Pick<ArtistVariantProjectCreateOrUpdateMutation, 'status' | 'formErrors'>
    & { artistVariant?: Maybe<(
      { __typename?: 'ArtistVariantType' }
      & Pick<ArtistVariantType, 'id'>
      & { projects: Array<(
        { __typename?: 'ArtistVariantProjectType' }
        & Pick<ArtistVariantProjectType, 'id'>
        & ArtistVariantProjectFragmentFragment
      )> }
    )> }
  )> }
);

export type BucketItemDetailsFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type BucketItemDetailsFormMutation = (
  { __typename?: 'Mutations' }
  & { updateBucketItemDetails?: Maybe<(
    { __typename?: 'UpdateBucketItemDetailsMutation' }
    & Pick<UpdateBucketItemDetailsMutation, 'status' | 'formErrors'>
    & { bucketItem?: Maybe<(
      { __typename?: 'ProductAndItemType' }
      & Pick<ProductAndItemType, 'id' | 'copyPasteText'>
      & { bucketItem: (
        { __typename?: 'BucketItemType' }
        & Pick<BucketItemType, 'id'>
        & BucketItemDetailsFragmentFragment
      ) }
    )> }
  )> }
);

export type CreateOrUpdateFramerFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type CreateOrUpdateFramerFormMutation = (
  { __typename?: 'Mutations' }
  & { createOrUpdateFramerMutation?: Maybe<(
    { __typename?: 'CreateOrUpdateFramerMutation' }
    & Pick<CreateOrUpdateFramerMutation, 'status' | 'formErrors'>
    & { framer?: Maybe<(
      { __typename?: 'FramerType' }
      & Pick<FramerType, 'id'>
      & FramerRowFragmentFragment
    )> }
  )> }
);

export type DemoFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type DemoFormMutation = (
  { __typename?: 'Mutations' }
  & { demoArtistUpdate?: Maybe<(
    { __typename?: 'DemoArtistUpdateMutation' }
    & Pick<DemoArtistUpdateMutation, 'status' | 'formErrors'>
    & { result?: Maybe<(
      { __typename?: 'ArtistSourcingType' }
      & Pick<ArtistSourcingType, 'id' | 'name'>
    )> }
  )> }
);

export type ImageRenderFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ImageRenderFormMutation = (
  { __typename?: 'Mutations' }
  & { updateLocationItemVimrWall?: Maybe<(
    { __typename?: 'UpdateLocationItemVimrWallMutation' }
    & Pick<UpdateLocationItemVimrWallMutation, 'status' | 'formErrors'>
    & { locationItem?: Maybe<(
      { __typename?: 'LocationItemType' }
      & Pick<LocationItemType, 'id'>
      & LocationItemFragmentFragment
    )> }
  )> }
);

export type FloorplanPaperFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type FloorplanPaperFormMutation = (
  { __typename?: 'Mutations' }
  & { updateFloorplanScale?: Maybe<(
    { __typename?: 'UpdateFloorplanScaleMutation' }
    & Pick<UpdateFloorplanScaleMutation, 'status' | 'formErrors'>
    & { floorplan?: Maybe<(
      { __typename?: 'FloorPlanType' }
      & Pick<FloorPlanType, 'id' | 'image' | 'editsJson' | 'measurementUnit' | 'shownMeasurementUnit' | 'paperSize' | 'scaleMeasurement' | 'unitRatio' | 'measureType'>
    )> }
  )> }
);

export type FlowItemFramerInfoFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type FlowItemFramerInfoFormMutation = (
  { __typename?: 'Mutations' }
  & { wfNwStaffUpdateFlowItemFramerInfo?: Maybe<(
    { __typename?: 'StaffUpdateFlowItemFramerInfoMutation' }
    & Pick<StaffUpdateFlowItemFramerInfoMutation, 'status' | 'formErrors'>
    & { flowItem?: Maybe<(
      { __typename?: 'FlowItemArtworkType' }
      & Pick<FlowItemArtworkType, 'id'>
      & FlowItemFramerInfoFragmentFragment
    )> }
  )> }
);

export type ForgotPasswordFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ForgotPasswordFormMutation = (
  { __typename?: 'Mutations' }
  & { forgotPassword?: Maybe<(
    { __typename?: 'ForgotPasswordMutation' }
    & Pick<ForgotPasswordMutation, 'status' | 'formErrors'>
  )> }
);

export type FramingToolUpdateMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type FramingToolUpdateMutation = (
  { __typename?: 'Mutations' }
  & { savedFrameUpdateCreate?: Maybe<(
    { __typename?: 'SavedFrameUpdateCreateMutation' }
    & Pick<SavedFrameUpdateCreateMutation, 'status' | 'formErrors'>
    & { savedFrame?: Maybe<(
      { __typename?: 'SavedFrameType' }
      & Pick<SavedFrameType, 'id'>
    )> }
  )> }
);

export type LoginFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type LoginFormMutation = (
  { __typename?: 'Mutations' }
  & { login?: Maybe<(
    { __typename?: 'LoginMutation' }
    & Pick<LoginMutation, 'status' | 'formErrors'>
  )> }
);

export type NewInquiryFormQueryVariables = Exact<{ [key: string]: never; }>;


export type NewInquiryFormQuery = (
  { __typename?: 'Queries' }
  & { inquiryTypes: Array<(
    { __typename?: 'InquiryTypeType' }
    & Pick<InquiryTypeType, 'id' | 'title'>
  )> }
);

export type NewInquiryFormNewInquiryMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type NewInquiryFormNewInquiryMutation = (
  { __typename?: 'Mutations' }
  & { newInquiry?: Maybe<(
    { __typename?: 'NewInquiryMutation' }
    & Pick<NewInquiryMutation, 'status' | 'formErrors'>
  )> }
);

export type PdfActivePagesFormUpdateProposalActivePagesMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type PdfActivePagesFormUpdateProposalActivePagesMutation = (
  { __typename?: 'Mutations' }
  & { updateProposalActivePages?: Maybe<(
    { __typename?: 'UpdateProposalActivePagesMutation' }
    & Pick<UpdateProposalActivePagesMutation, 'status'>
    & { proposal?: Maybe<(
      { __typename?: 'PDFProposalType' }
      & Pick<PdfProposalType, 'id'>
      & { pages: Array<(
        { __typename?: 'PDFPageType' }
        & Pick<PdfPageType, 'id' | 'isActive'>
      )> }
    )> }
  )> }
);

export type PdfActivePagesFormUpdateProposalPagesPositionMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type PdfActivePagesFormUpdateProposalPagesPositionMutation = (
  { __typename?: 'Mutations' }
  & { updateProposalPagesPosition?: Maybe<(
    { __typename?: 'UpdateProposalPagesPositionMutation' }
    & Pick<UpdateProposalPagesPositionMutation, 'status'>
    & { proposal?: Maybe<(
      { __typename?: 'PDFProposalType' }
      & Pick<PdfProposalType, 'id'>
      & { pages: Array<(
        { __typename?: 'PDFPageType' }
        & Pick<PdfPageType, 'id' | 'isActive'>
      )> }
    )> }
  )> }
);

export type PdfBucketItemDetailsFormUpdatePageDetailMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type PdfBucketItemDetailsFormUpdatePageDetailMutation = (
  { __typename?: 'Mutations' }
  & { updatePageDetail?: Maybe<(
    { __typename?: 'UpdatePageDetailMutation' }
    & Pick<UpdatePageDetailMutation, 'status' | 'formErrors'>
    & { page?: Maybe<(
      { __typename?: 'PDFPageType' }
      & Pick<PdfPageType, 'id' | 'pdfDetails'>
    )> }
  )> }
);

export type PdfBucketItemDetailsFormRefreshPageMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type PdfBucketItemDetailsFormRefreshPageMutation = (
  { __typename?: 'Mutations' }
  & { refreshPage?: Maybe<(
    { __typename?: 'RefreshPageMutation' }
    & Pick<RefreshPageMutation, 'status' | 'formErrors'>
    & { page?: Maybe<(
      { __typename?: 'PDFPageType' }
      & Pick<PdfPageType, 'id' | 'pdfDetails'>
    )> }
  )> }
);

export type PdfProjectDetailsFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type PdfProjectDetailsFormMutation = (
  { __typename?: 'Mutations' }
  & { updatePageDetail?: Maybe<(
    { __typename?: 'UpdatePageDetailMutation' }
    & Pick<UpdatePageDetailMutation, 'status' | 'formErrors'>
    & { page?: Maybe<(
      { __typename?: 'PDFPageType' }
      & Pick<PdfPageType, 'id' | 'pdfDetails'>
    )> }
  )> }
);

export type PdfCoverImageMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type PdfCoverImageMutation = (
  { __typename?: 'Mutations' }
  & { updatePdfPageImage?: Maybe<(
    { __typename?: 'UpdatePDFPageImageMutation' }
    & Pick<UpdatePdfPageImageMutation, 'status' | 'formErrors'>
    & { page?: Maybe<(
      { __typename?: 'PDFPageType' }
      & Pick<PdfPageType, 'id'>
      & ProposalPageFragmentFragment
    )> }
  )> }
);

export type BucketItemDetailsFormUpdatePdfContactMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type BucketItemDetailsFormUpdatePdfContactMutation = (
  { __typename?: 'Mutations' }
  & { updateProposalContact?: Maybe<(
    { __typename?: 'UpdateProposalContactMutation' }
    & Pick<UpdateProposalContactMutation, 'status' | 'formErrors'>
    & { proposal?: Maybe<(
      { __typename?: 'PDFProposalType' }
      & Pick<PdfProposalType, 'id' | 'pdfAddr' | 'pdfEmail' | 'pdfSite'>
    )> }
  )> }
);

export type PdfHideFieldsFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type PdfHideFieldsFormMutation = (
  { __typename?: 'Mutations' }
  & { updateProposalHideFields?: Maybe<(
    { __typename?: 'UpdateProposalHideFieldsMutation' }
    & Pick<UpdateProposalHideFieldsMutation, 'status' | 'formErrors'>
    & { proposal?: Maybe<(
      { __typename?: 'PDFProposalType' }
      & Pick<PdfProposalType, 'id' | 'pdfHideTitle' | 'pdfHideArtist' | 'pdfHidePrice' | 'pdfHideShipping' | 'pdfHideAreaDetails' | 'pdfHideLogo'>
    )> }
  )> }
);

export type PdfItemSelectionFormToggleBucketItemMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type PdfItemSelectionFormToggleBucketItemMutation = (
  { __typename?: 'Mutations' }
  & { toggleBucketItemShortlisted?: Maybe<(
    { __typename?: 'ToggleBucketItemShortlistedMutation' }
    & Pick<ToggleBucketItemShortlistedMutation, 'status'>
  )> }
);

export type PdfItemSelectionFormCreateNewProposalMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type PdfItemSelectionFormCreateNewProposalMutation = (
  { __typename?: 'Mutations' }
  & { createNewProposal?: Maybe<(
    { __typename?: 'CreateNewProposalMutation' }
    & Pick<CreateNewProposalMutation, 'status'>
    & { proposal?: Maybe<(
      { __typename?: 'PDFProposalType' }
      & Pick<PdfProposalType, 'id'>
      & { pages: Array<(
        { __typename?: 'PDFPageType' }
        & Pick<PdfPageType, 'id'>
      )> }
    )> }
  )> }
);

export type PdfLocationImageFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type PdfLocationImageFormMutation = (
  { __typename?: 'Mutations' }
  & { updatePdfPageImage?: Maybe<(
    { __typename?: 'UpdatePDFPageImageMutation' }
    & Pick<UpdatePdfPageImageMutation, 'status' | 'formErrors'>
    & { page?: Maybe<(
      { __typename?: 'PDFPageType' }
      & Pick<PdfPageType, 'id' | 'pdfDetails'>
    )> }
  )> }
);

export type BucketItemDetailsFormUpdatePdfLogoMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type BucketItemDetailsFormUpdatePdfLogoMutation = (
  { __typename?: 'Mutations' }
  & { updateProposalLogo?: Maybe<(
    { __typename?: 'UpdateProposalLogoMutation' }
    & Pick<UpdateProposalLogoMutation, 'status' | 'formErrors'>
    & { proposal?: Maybe<(
      { __typename?: 'PDFProposalType' }
      & Pick<PdfProposalType, 'id' | 'pdfLogo'>
    )> }
  )> }
);

export type PdfNewPageFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type PdfNewPageFormMutation = (
  { __typename?: 'Mutations' }
  & { addProposalPage?: Maybe<(
    { __typename?: 'AddProposalPageMutation' }
    & Pick<AddProposalPageMutation, 'status' | 'formErrors'>
    & { proposal?: Maybe<(
      { __typename?: 'PDFProposalType' }
      & Pick<PdfProposalType, 'id'>
      & { pages: Array<(
        { __typename?: 'PDFPageType' }
        & Pick<PdfPageType, 'id'>
        & ProposalPageFragmentFragment
      )> }
    )>, page?: Maybe<(
      { __typename?: 'PDFPageType' }
      & Pick<PdfPageType, 'id' | 'isActive'>
    )> }
  )> }
);

export type PdfProjectImageFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type PdfProjectImageFormMutation = (
  { __typename?: 'Mutations' }
  & { updatePdfPageImage?: Maybe<(
    { __typename?: 'UpdatePDFPageImageMutation' }
    & Pick<UpdatePdfPageImageMutation, 'status' | 'formErrors'>
    & { page?: Maybe<(
      { __typename?: 'PDFPageType' }
      & Pick<PdfPageType, 'id' | 'pdfDetails'>
    )> }
  )> }
);

export type PdfvirDetailsFormUpdatePageDetailMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type PdfvirDetailsFormUpdatePageDetailMutation = (
  { __typename?: 'Mutations' }
  & { updatePageDetail?: Maybe<(
    { __typename?: 'UpdatePageDetailMutation' }
    & Pick<UpdatePageDetailMutation, 'status' | 'formErrors'>
    & { page?: Maybe<(
      { __typename?: 'PDFPageType' }
      & Pick<PdfPageType, 'id' | 'pdfDetails'>
    )> }
  )> }
);

export type PdfvirDetailsFormRefreshPageMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type PdfvirDetailsFormRefreshPageMutation = (
  { __typename?: 'Mutations' }
  & { refreshPage?: Maybe<(
    { __typename?: 'RefreshPageMutation' }
    & Pick<RefreshPageMutation, 'status' | 'formErrors'>
    & { page?: Maybe<(
      { __typename?: 'PDFPageType' }
      & Pick<PdfPageType, 'id' | 'pdfDetails'>
    )> }
  )> }
);

export type ProjectInviteFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ProjectInviteFormMutation = (
  { __typename?: 'Mutations' }
  & { projectInvite?: Maybe<(
    { __typename?: 'ProjectInviteMutation' }
    & Pick<ProjectInviteMutation, 'status' | 'formErrors'>
    & { project?: Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id'>
      & ProjectInviteFragmentFragment
    )> }
  )> }
);

export type ProjectLocationItemNameFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ProjectLocationItemNameFormMutation = (
  { __typename?: 'Mutations' }
  & { updateLocationItemName?: Maybe<(
    { __typename?: 'UpdateLocationItemNameMutation' }
    & Pick<UpdateLocationItemNameMutation, 'status' | 'formErrors'>
    & { result?: Maybe<(
      { __typename?: 'LocationItemType' }
      & Pick<LocationItemType, 'id' | 'title'>
    )> }
  )> }
);

export type ProjectLocationNameFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ProjectLocationNameFormMutation = (
  { __typename?: 'Mutations' }
  & { updateLocationName?: Maybe<(
    { __typename?: 'UpdateLocationNameMutation' }
    & Pick<UpdateLocationNameMutation, 'status' | 'formErrors'>
    & { result?: Maybe<(
      { __typename?: 'LocationType' }
      & Pick<LocationType, 'id' | 'title' | 'titleStr'>
    )> }
  )> }
);

export type ProjectSendQuotationFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ProjectSendQuotationFormMutation = (
  { __typename?: 'Mutations' }
  & { staffCompleteQuotation?: Maybe<(
    { __typename?: 'StaffCompleteQuotationMutation' }
    & Pick<StaffCompleteQuotationMutation, 'status' | 'formErrors'>
  )> }
);

export type QuotationBillingAddressFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type QuotationBillingAddressFormMutation = (
  { __typename?: 'Mutations' }
  & { updateQuotationBillingAddress?: Maybe<(
    { __typename?: 'UpdateQuotationBillingAddressMutation' }
    & Pick<UpdateQuotationBillingAddressMutation, 'formErrors' | 'status'>
    & { project?: Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id'>
      & ProjectSummaryFragmentFragment
    )> }
  )> }
);

export type QuotationShippingAddressFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type QuotationShippingAddressFormMutation = (
  { __typename?: 'Mutations' }
  & { updateQuotationShippingAddress?: Maybe<(
    { __typename?: 'UpdateQuotationShippingAddressMutation' }
    & Pick<UpdateQuotationShippingAddressMutation, 'formErrors' | 'status'>
    & { project?: Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id'>
      & ProjectSummaryFragmentFragment
    )> }
  )> }
);

export type RegisterForAccessFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type RegisterForAccessFormMutation = (
  { __typename?: 'Mutations' }
  & { registerForAccess?: Maybe<(
    { __typename?: 'AccessInquirySubmitMutation' }
    & Pick<AccessInquirySubmitMutation, 'status' | 'formErrors'>
  )> }
);

export type ResetPasswordKeyFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ResetPasswordKeyFormMutation = (
  { __typename?: 'Mutations' }
  & { resetPasswordWithKey?: Maybe<(
    { __typename?: 'ResetPasswordWithKeyMutation' }
    & Pick<ResetPasswordWithKeyMutation, 'status' | 'formErrors'>
  )> }
);

export type SelectSensitiveDocumentsFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type SelectSensitiveDocumentsFormMutation = (
  { __typename?: 'Mutations' }
  & { requestQuotation?: Maybe<(
    { __typename?: 'RequestQuotationMutation' }
    & Pick<RequestQuotationMutation, 'status' | 'formErrors'>
    & { project?: Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id' | 'status'>
    )> }
  )> }
);

export type SellerConfirmAvailabilityFormMutationVariables = Exact<{
  uuid?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
}>;


export type SellerConfirmAvailabilityFormMutation = (
  { __typename?: 'Mutations' }
  & { wfSellerConfirmAvailabilityMutation?: Maybe<(
    { __typename?: 'SellerConfirmAvailabilityMutation' }
    & Pick<SellerConfirmAvailabilityMutation, 'status' | 'formErrors'>
  )> }
);

export type SignupFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type SignupFormMutation = (
  { __typename?: 'Mutations' }
  & { signup?: Maybe<(
    { __typename?: 'SignupMutation' }
    & Pick<SignupMutation, 'status' | 'formErrors'>
    & { currentProfile?: Maybe<(
      { __typename?: 'UserProfileType' }
      & Pick<UserProfileType, 'id'>
      & CurrentProfileFragmentFragment
    )> }
  )> }
);

export type SourcingArtistCreateUpdateArtistFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
  artistId?: Maybe<Scalars['String']>;
}>;


export type SourcingArtistCreateUpdateArtistFormMutation = (
  { __typename?: 'Mutations' }
  & { artistCreateUpdate?: Maybe<(
    { __typename?: 'ArtistCreateOrUpdateMutation' }
    & Pick<ArtistCreateOrUpdateMutation, 'status' | 'formErrors'>
    & { artist?: Maybe<(
      { __typename?: 'ArtistSourcingType' }
      & Pick<ArtistSourcingType, 'id'>
    )> }
  )> }
);

export type SourcingArtistCreateUpdateGalleryFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type SourcingArtistCreateUpdateGalleryFormMutation = (
  { __typename?: 'Mutations' }
  & { galleryCreateUpdate?: Maybe<(
    { __typename?: 'GalleryCreateOrUpdateMutation' }
    & Pick<GalleryCreateOrUpdateMutation, 'status' | 'formErrors'>
    & { gallery?: Maybe<(
      { __typename?: 'GallerySourcingType' }
      & Pick<GallerySourcingType, 'id'>
    )> }
  )> }
);

export type UpdateAccountEmailFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type UpdateAccountEmailFormMutation = (
  { __typename?: 'Mutations' }
  & { updateEmail?: Maybe<(
    { __typename?: 'UpdateEmailMutation' }
    & Pick<UpdateEmailMutation, 'status' | 'formErrors'>
  )> }
);

export type UpdateAccountInfoFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type UpdateAccountInfoFormMutation = (
  { __typename?: 'Mutations' }
  & { updateAccountInfo?: Maybe<(
    { __typename?: 'UpdateAccountInfoMutation' }
    & Pick<UpdateAccountInfoMutation, 'status' | 'formErrors'>
  )> }
);

export type UpdateAccountPasswordFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type UpdateAccountPasswordFormMutation = (
  { __typename?: 'Mutations' }
  & { updatePassword?: Maybe<(
    { __typename?: 'UpdatePasswordMutation' }
    & Pick<UpdatePasswordMutation, 'status' | 'formErrors'>
  )> }
);

export type ViewInRoomCtaFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ViewInRoomCtaFormMutation = (
  { __typename?: 'Mutations' }
  & { createUpdateUserVirImage?: Maybe<(
    { __typename?: 'CreateUpdateUserVIRImageMutation' }
    & Pick<CreateUpdateUserVirImageMutation, 'status' | 'formErrors' | 'autoSubmit'>
    & { userProfile?: Maybe<(
      { __typename?: 'UserProfileType' }
      & Pick<UserProfileType, 'id'>
      & { userVirImages: Array<(
        { __typename?: 'UserVIRImageType' }
        & Pick<UserVirImageType, 'id' | 'externalId' | 'image' | 'imageFilename'>
      )> }
    )> }
  )> }
);

export type WfcwMoveToStepFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type WfcwMoveToStepFormMutation = (
  { __typename?: 'Mutations' }
  & { wfNwStaffMoveToStep?: Maybe<(
    { __typename?: 'StaffMoveToStepMutation' }
    & Pick<StaffMoveToStepMutation, 'status' | 'formErrors'>
    & { flowItemArtwork?: Maybe<(
      { __typename?: 'FlowItemArtworkType' }
      & Pick<FlowItemArtworkType, 'id'>
    )> }
  )> }
);

export type WfCommissionedWorksConfirmAvailabilityFormMutationVariables = Exact<{
  uuid?: Maybe<Scalars['String']>;
  flowItemId?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
}>;


export type WfCommissionedWorksConfirmAvailabilityFormMutation = (
  { __typename?: 'Mutations' }
  & { wfCommissionedWorksSellerSaveAvailabilityData?: Maybe<(
    { __typename?: 'SellerCommissionedWorksSaveAvailabilityDataMutation' }
    & Pick<SellerCommissionedWorksSaveAvailabilityDataMutation, 'status' | 'formErrors'>
  )> }
);

export type WfCommissionedWorksConfirmAvailabilityFormGroupMutationVariables = Exact<{
  uuid?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
}>;


export type WfCommissionedWorksConfirmAvailabilityFormGroupMutation = (
  { __typename?: 'Mutations' }
  & { wfCommissionedWorksSellerSubmitAvailabilityData?: Maybe<(
    { __typename?: 'SellerCommissionedWorksSubmitAvailabilityDataMutation' }
    & Pick<SellerCommissionedWorksSubmitAvailabilityDataMutation, 'status' | 'formErrors'>
  )> }
);

export type WfCommissionedWorksLocationInfoFormMutationMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type WfCommissionedWorksLocationInfoFormMutationMutation = (
  { __typename?: 'Mutations' }
  & { wfCommissionedWorksStaffUpdateLocationInfo?: Maybe<(
    { __typename?: 'StaffCommissionedWorksUpdateLocationInfoMutation' }
    & Pick<StaffCommissionedWorksUpdateLocationInfoMutation, 'status' | 'formErrors'>
  )> }
);

export type WfCommissionedWorksNotifySellersFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type WfCommissionedWorksNotifySellersFormMutation = (
  { __typename?: 'Mutations' }
  & { wfCommissionedWorksStaffNotifySellers?: Maybe<(
    { __typename?: 'StaffCommissionedWorksNotifySellersMutation' }
    & Pick<StaffCommissionedWorksNotifySellersMutation, 'status' | 'formErrors'>
  )> }
);

export type WfnwMoveToStepFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type WfnwMoveToStepFormMutation = (
  { __typename?: 'Mutations' }
  & { wfNwStaffMoveToStep?: Maybe<(
    { __typename?: 'StaffMoveToStepMutation' }
    & Pick<StaffMoveToStepMutation, 'status' | 'formErrors'>
    & { flowItemArtwork?: Maybe<(
      { __typename?: 'FlowItemArtworkType' }
      & Pick<FlowItemArtworkType, 'id'>
      & FlowItemArtworkStaffFragmentFragment
    )> }
  )> }
);

export type WfNormalWorksAddFramersToProjectFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type WfNormalWorksAddFramersToProjectFormMutation = (
  { __typename?: 'Mutations' }
  & { addFramersToProject?: Maybe<(
    { __typename?: 'AddFramersToProjectMutation' }
    & Pick<AddFramersToProjectMutation, 'status' | 'formErrors'>
    & { project?: Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id'>
      & { wfNwFramers?: Maybe<Array<Maybe<(
        { __typename?: 'FramerType' }
        & Pick<FramerType, 'id'>
        & FramerRowFragmentFragment
      )>>> }
    )> }
  )> }
);

export type WfNormalWorksConfirmAvailabilityFormGroupMutationVariables = Exact<{
  uuid?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
}>;


export type WfNormalWorksConfirmAvailabilityFormGroupMutation = (
  { __typename?: 'Mutations' }
  & { wfSellerConfirmAvailabilityMutation?: Maybe<(
    { __typename?: 'SellerConfirmAvailabilityMutation' }
    & Pick<SellerConfirmAvailabilityMutation, 'status' | 'formErrors'>
  )> }
);

export type WfNormalWorksFramerDeliveryInfoFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
  framerHashId?: Maybe<Scalars['String']>;
}>;


export type WfNormalWorksFramerDeliveryInfoFormMutation = (
  { __typename?: 'Mutations' }
  & { wfNwFramerRfqSaveDeliveryInfo?: Maybe<(
    { __typename?: 'FramerRFQsSaveDeliveryInfoMutation' }
    & Pick<FramerRfQsSaveDeliveryInfoMutation, 'status' | 'formErrors'>
    & { framerGlobalQuotation?: Maybe<(
      { __typename?: 'FramerGlobalQuotationType' }
      & Pick<FramerGlobalQuotationType, 'id'>
      & FramerGlobalQuotationFragmentFragment
    )> }
  )> }
);

export type WfNormalWorksFramerFramingSpecsFormUpdateSpecsMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
  framerHashId?: Maybe<Scalars['String']>;
  specId?: Maybe<Scalars['String']>;
  flowItemId?: Maybe<Scalars['String']>;
}>;


export type WfNormalWorksFramerFramingSpecsFormUpdateSpecsMutation = (
  { __typename?: 'Mutations' }
  & { wfNwFramerRfqCreateOrUpdateSpecs?: Maybe<(
    { __typename?: 'FramerRFQsCreateOrUpdateSpecsMutation' }
    & Pick<FramerRfQsCreateOrUpdateSpecsMutation, 'status' | 'formErrors'>
    & { framerGlobalQuotation?: Maybe<(
      { __typename?: 'FramerGlobalQuotationType' }
      & Pick<FramerGlobalQuotationType, 'id'>
      & FramerGlobalQuotationFragmentFragment
    )> }
  )> }
);

export type WfNormalWorksFramerFramingSpecsFormCreateNewSpecsMutationVariables = Exact<{
  flowItemId?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  framerHashId?: Maybe<Scalars['String']>;
}>;


export type WfNormalWorksFramerFramingSpecsFormCreateNewSpecsMutation = (
  { __typename?: 'Mutations' }
  & { wfNwFramerRfqCreateNewSpecs?: Maybe<(
    { __typename?: 'FramerRFQsCreateNewSpecsMutation' }
    & Pick<FramerRfQsCreateNewSpecsMutation, 'status'>
    & { framerGlobalQuotation?: Maybe<(
      { __typename?: 'FramerGlobalQuotationType' }
      & Pick<FramerGlobalQuotationType, 'id'>
      & FramerGlobalQuotationFragmentFragment
    )> }
  )> }
);

export type WfNormalWorksFramerFramingSpecsFormDeleteSpecMutationVariables = Exact<{
  flowItemId?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  framerHashId?: Maybe<Scalars['String']>;
  specId?: Maybe<Scalars['String']>;
}>;


export type WfNormalWorksFramerFramingSpecsFormDeleteSpecMutation = (
  { __typename?: 'Mutations' }
  & { wfNwFramerRfqDeleteSpec?: Maybe<(
    { __typename?: 'FramerRFQDeleteSpecMutation' }
    & Pick<FramerRfqDeleteSpecMutation, 'status'>
    & { framerGlobalQuotation?: Maybe<(
      { __typename?: 'FramerGlobalQuotationType' }
      & Pick<FramerGlobalQuotationType, 'id'>
      & FramerGlobalQuotationFragmentFragment
    )> }
  )> }
);

export type WfNormalWorksFramerGlobalInfoFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
  framerHashId?: Maybe<Scalars['String']>;
}>;


export type WfNormalWorksFramerGlobalInfoFormMutation = (
  { __typename?: 'Mutations' }
  & { wfNwFramerRfqUpdateGlobal?: Maybe<(
    { __typename?: 'FramerRFQUpdateGlobalMutation' }
    & Pick<FramerRfqUpdateGlobalMutation, 'status' | 'formErrors'>
    & { framerGlobalQuotation?: Maybe<(
      { __typename?: 'FramerGlobalQuotationType' }
      & Pick<FramerGlobalQuotationType, 'id'>
      & FramerGlobalQuotationFragmentFragment
    )> }
  )> }
);

export type WfNormalWorksFramerInstallationInfoFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
  framerHashId?: Maybe<Scalars['String']>;
}>;


export type WfNormalWorksFramerInstallationInfoFormMutation = (
  { __typename?: 'Mutations' }
  & { wfNwFramerRfqSaveInstallationInfo?: Maybe<(
    { __typename?: 'FramerRFQsSaveInstallationInfoMutation' }
    & Pick<FramerRfQsSaveInstallationInfoMutation, 'status' | 'formErrors'>
    & { framerGlobalQuotation?: Maybe<(
      { __typename?: 'FramerGlobalQuotationType' }
      & Pick<FramerGlobalQuotationType, 'id'>
      & FramerGlobalQuotationFragmentFragment
    )> }
  )> }
);

export type FramerWfNormalWorksQuotationFormGroupMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
  framerHashId?: Maybe<Scalars['String']>;
}>;


export type FramerWfNormalWorksQuotationFormGroupMutation = (
  { __typename?: 'Mutations' }
  & { wfNwFramerRfqSubmitMutation?: Maybe<(
    { __typename?: 'FramerRFQSubmitMutation' }
    & Pick<FramerRfqSubmitMutation, 'status' | 'formErrors'>
    & { framerGlobalQuotation?: Maybe<(
      { __typename?: 'FramerGlobalQuotationType' }
      & Pick<FramerGlobalQuotationType, 'id'>
      & FramerGlobalQuotationFragmentFragment
    )> }
  )> }
);

export type WfNormalWorksFramerStretchingInfoFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
  framerHashId?: Maybe<Scalars['String']>;
  flowItemId?: Maybe<Scalars['String']>;
}>;


export type WfNormalWorksFramerStretchingInfoFormMutation = (
  { __typename?: 'Mutations' }
  & { wfNwFramerRfqSaveStretchingInfo?: Maybe<(
    { __typename?: 'FramerRFQSaveStretchingInfoMutation' }
    & Pick<FramerRfqSaveStretchingInfoMutation, 'status' | 'formErrors'>
    & { framerGlobalQuotation?: Maybe<(
      { __typename?: 'FramerGlobalQuotationType' }
      & Pick<FramerGlobalQuotationType, 'id'>
      & FramerGlobalQuotationFragmentFragment
    )> }
  )> }
);

export type WfNormalWorksNotifyFramersFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type WfNormalWorksNotifyFramersFormMutation = (
  { __typename?: 'Mutations' }
  & { wfNwStaffRequestFramerQuotation?: Maybe<(
    { __typename?: 'StaffRequestFramerQuotation' }
    & Pick<StaffRequestFramerQuotation, 'status' | 'formErrors'>
  )> }
);

export type WfNormalWorksNotifySellersFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type WfNormalWorksNotifySellersFormMutation = (
  { __typename?: 'Mutations' }
  & { wfStaffCheckAvailability?: Maybe<(
    { __typename?: 'StaffCheckAvailabilityMutation' }
    & Pick<StaffCheckAvailabilityMutation, 'status' | 'formErrors'>
  )> }
);

export type ProjectAreaBoxFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ProjectAreaBoxFormMutation = (
  { __typename?: 'Mutations' }
  & { updateProjectLocationOverview?: Maybe<(
    { __typename?: 'UpdateProjectLocationOverviewMutation' }
    & Pick<UpdateProjectLocationOverviewMutation, 'status' | 'formErrors' | 'autoSubmit'>
    & { project?: Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id'>
      & UserWizardProjectLocationItemFragmentFragment
    )> }
  )> }
);

export type ProjectAreaFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ProjectAreaFormMutation = (
  { __typename?: 'Mutations' }
  & { updateProjectLocationOverview?: Maybe<(
    { __typename?: 'UpdateProjectLocationOverviewMutation' }
    & Pick<UpdateProjectLocationOverviewMutation, 'status' | 'formErrors' | 'autoSubmit'>
    & { project?: Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id'>
      & ProjectOverviewFragmentFragment
    )> }
  )> }
);

export type ProjectOverviewFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ProjectOverviewFormMutation = (
  { __typename?: 'Mutations' }
  & { updateProjectOverview?: Maybe<(
    { __typename?: 'UpdateProjectOverviewMutation' }
    & Pick<UpdateProjectOverviewMutation, 'status' | 'formErrors'>
    & { project?: Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id'>
      & ProjectOverviewFragmentFragment
    )> }
  )> }
);

export type ProjectBudgetFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ProjectBudgetFormMutation = (
  { __typename?: 'Mutations' }
  & { updateProjectBudget?: Maybe<(
    { __typename?: 'UpdateProjectBudgetMutation' }
    & Pick<UpdateProjectBudgetMutation, 'status' | 'formErrors' | 'autoSubmit'>
    & { project?: Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id'>
      & ProjectBudgetFragmentFragment
    )> }
  )> }
);

export type ProjectCreateUpdateClientFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ProjectCreateUpdateClientFormMutation = (
  { __typename?: 'Mutations' }
  & { updateCreateClient?: Maybe<(
    { __typename?: 'UpdateCreateClientMutation' }
    & Pick<UpdateCreateClientMutation, 'status' | 'formErrors'>
    & { result?: Maybe<(
      { __typename?: 'ClientType' }
      & Pick<ClientType, 'id' | 'name' | 'slug'>
    )> }
  )> }
);

export type ProjectLocationBoxFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ProjectLocationBoxFormMutation = (
  { __typename?: 'Mutations' }
  & { updateLocationItem?: Maybe<(
    { __typename?: 'UpdateLocationItemMutation' }
    & Pick<UpdateLocationItemMutation, 'status' | 'formErrors' | 'autoSubmit'>
    & { project?: Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id'>
    )>, locationItem?: Maybe<(
      { __typename?: 'LocationItemType' }
      & Pick<LocationItemType, 'id'>
      & LocationItemDetailFragmentFragment
    )> }
  )> }
);

export type ProjectQuickSourceFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ProjectQuickSourceFormMutation = (
  { __typename?: 'Mutations' }
  & { createQuickSource?: Maybe<(
    { __typename?: 'CreateQuickSourceMutation' }
    & Pick<CreateQuickSourceMutation, 'status' | 'formErrors'>
    & { project?: Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id' | 'hashId'>
    )> }
  )> }
);

export type ProjectTimelineFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ProjectTimelineFormMutation = (
  { __typename?: 'Mutations' }
  & { updateProjectTimeline?: Maybe<(
    { __typename?: 'UpdateProjectTimelineMutation' }
    & Pick<UpdateProjectTimelineMutation, 'status' | 'formErrors' | 'autoSubmit'>
    & { project?: Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id'>
      & ProjectTimelineFragmentFragment
    )> }
  )> }
);

export type ProjectTitleFormUpdateProjectTitleMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ProjectTitleFormUpdateProjectTitleMutation = (
  { __typename?: 'Mutations' }
  & { updateProjectTitle?: Maybe<(
    { __typename?: 'UpdateProjectTitleMutation' }
    & Pick<UpdateProjectTitleMutation, 'status' | 'formErrors' | 'autoSubmit'>
    & { project?: Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id'>
      & ProjectTitleFragmentFragment
    )> }
  )> }
);

export type ProjectTitleFormUpdateClientMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ProjectTitleFormUpdateClientMutation = (
  { __typename?: 'Mutations' }
  & { updateCreateClient?: Maybe<(
    { __typename?: 'UpdateCreateClientMutation' }
    & Pick<UpdateCreateClientMutation, 'status' | 'formErrors'>
    & { result?: Maybe<(
      { __typename?: 'ClientType' }
      & Pick<ClientType, 'id' | 'name' | 'slug'>
    )> }
  )> }
);

export type ProjectTypeFormMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ProjectTypeFormMutation = (
  { __typename?: 'Mutations' }
  & { updateProjectType?: Maybe<(
    { __typename?: 'UpdateProjectTypeMutation' }
    & Pick<UpdateProjectTypeMutation, 'status' | 'formErrors' | 'autoSubmit'>
    & { project?: Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id'>
      & ProjectTypeFragmentFragment
    )> }
  )> }
);

export type UseCanAccessQueryVariables = Exact<{
  hashId?: Maybe<Scalars['String']>;
}>;


export type UseCanAccessQuery = (
  { __typename?: 'Queries' }
  & { project?: Maybe<(
    { __typename?: 'ProjectType' }
    & Pick<ProjectType, 'id' | 'status'>
  )> }
);

export type UseCountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type UseCountriesQuery = (
  { __typename?: 'Queries' }
  & { countries: Array<(
    { __typename?: 'CountryType' }
    & Pick<CountryType, 'name' | 'code'>
  )> }
);

export type UseCurrenciesQueryVariables = Exact<{ [key: string]: never; }>;


export type UseCurrenciesQuery = (
  { __typename?: 'Queries' }
  & Pick<Queries, 'projectCurrencies'>
);

export type UseFramersQueryVariables = Exact<{ [key: string]: never; }>;


export type UseFramersQuery = (
  { __typename?: 'Queries' }
  & { framers: Array<(
    { __typename?: 'FramerType' }
    & Pick<FramerType, 'id' | 'name'>
  )> }
);

export type UseFramingMaterialsQueryVariables = Exact<{ [key: string]: never; }>;


export type UseFramingMaterialsQuery = (
  { __typename?: 'Queries' }
  & { getFrameMaterials: Array<(
    { __typename?: 'FrameMaterialType' }
    & Pick<FrameMaterialType, 'id' | 'name' | 'slug' | 'color' | 'materialImage'>
  )> }
);

export type UseIndustriesQueryVariables = Exact<{ [key: string]: never; }>;


export type UseIndustriesQuery = (
  { __typename?: 'Queries' }
  & { typeOfIndustries: Array<(
    { __typename?: 'TypeOfIndustryType' }
    & Pick<TypeOfIndustryType, 'id' | 'name' | 'slug'>
  )> }
);

export type UseLocationItemAttrsQueryVariables = Exact<{ [key: string]: never; }>;


export type UseLocationItemAttrsQuery = (
  { __typename?: 'Queries' }
  & { categories?: Maybe<Array<(
    { __typename?: 'CategoryType' }
    & Pick<CategoryType, 'id' | 'title'>
  )>>, attr1s?: Maybe<Array<(
    { __typename?: 'Attribute1Type' }
    & Pick<Attribute1Type, 'id' | 'title'>
    & { parent?: Maybe<Array<Maybe<(
      { __typename?: 'CategoryType' }
      & Pick<CategoryType, 'id'>
    )>>> }
  )>>, attr2s?: Maybe<Array<(
    { __typename?: 'Attribute2Type' }
    & Pick<Attribute2Type, 'id' | 'title'>
    & { parent?: Maybe<Array<Maybe<(
      { __typename?: 'CategoryType' }
      & Pick<CategoryType, 'id'>
    )>>> }
  )>> }
);

export type UseLocationItemTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type UseLocationItemTypesQuery = (
  { __typename?: 'Queries' }
  & { locationItemTypes: Array<(
    { __typename?: 'LocationItemTypeType' }
    & Pick<LocationItemTypeType, 'id' | 'title'>
  )> }
);

export type UseProductSizesQueryVariables = Exact<{
  esId?: Maybe<Scalars['String']>;
}>;


export type UseProductSizesQuery = (
  { __typename?: 'Queries' }
  & { getProduct?: Maybe<(
    { __typename?: 'ProductType' }
    & Pick<ProductType, 'id' | 'name'>
    & { sizes: Array<(
      { __typename?: 'SizeType' }
      & Pick<SizeType, 'id' | 'width' | 'height' | 'thickness' | 'diameter' | 'price' | 'sizeAndPriceString'>
      & { printBaseCost?: Maybe<(
        { __typename?: 'PrintBaseCostType' }
        & Pick<PrintBaseCostType, 'printSize' | 'printMaterial'>
      )> }
    )> }
  )> }
);

export type UseProjectClientsQueryVariables = Exact<{ [key: string]: never; }>;


export type UseProjectClientsQuery = (
  { __typename?: 'Queries' }
  & { clients: Array<(
    { __typename?: 'ClientType' }
    & Pick<ClientType, 'id' | 'slug' | 'name'>
  )> }
);

export type UseProjectStatusesQueryVariables = Exact<{ [key: string]: never; }>;


export type UseProjectStatusesQuery = (
  { __typename?: 'Queries' }
  & { projectStatuses: Array<(
    { __typename?: 'ProjectStatusType' }
    & Pick<ProjectStatusType, 'name' | 'code'>
  )> }
);

export type UseProjectTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type UseProjectTypesQuery = (
  { __typename?: 'Queries' }
  & { projectTypes?: Maybe<Array<(
    { __typename?: 'ProjectTypeType' }
    & Pick<ProjectTypeType, 'id' | 'title' | 'iconSlug'>
  )>> }
);

export type UseProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type UseProjectsQuery = (
  { __typename?: 'Queries' }
  & { projects: (
    { __typename?: 'ProjectAggrType' }
    & { projects?: Maybe<Array<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id' | 'title'>
    )>> }
  ) }
);

export type UseSourcingArtistTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type UseSourcingArtistTagsQuery = (
  { __typename?: 'Queries' }
  & Pick<Queries, 'tags'>
);

export type UseSourcingArtistsQueryVariables = Exact<{ [key: string]: never; }>;


export type UseSourcingArtistsQuery = (
  { __typename?: 'Queries' }
  & { artistsAll: Array<(
    { __typename?: 'ArtistSourcingType' }
    & Pick<ArtistSourcingType, 'id' | 'slug' | 'name'>
  )> }
);

export type UseSourcingGalleriesQueryVariables = Exact<{ [key: string]: never; }>;


export type UseSourcingGalleriesQuery = (
  { __typename?: 'Queries' }
  & { galleriesAll: Array<(
    { __typename?: 'GallerySourcingType' }
    & Pick<GallerySourcingType, 'id' | 'slug' | 'name'>
  )> }
);

export type UseSourcingMediumsQueryVariables = Exact<{ [key: string]: never; }>;


export type UseSourcingMediumsQuery = (
  { __typename?: 'Queries' }
  & { mediums: Array<(
    { __typename?: 'MediumType' }
    & Pick<MediumType, 'id' | 'slug' | 'title'>
  )> }
);

export type UseSourcingStylesQueryVariables = Exact<{ [key: string]: never; }>;


export type UseSourcingStylesQuery = (
  { __typename?: 'Queries' }
  & { styles: Array<(
    { __typename?: 'StyleType' }
    & Pick<StyleType, 'id' | 'slug' | 'title'>
  )> }
);

export type UseStaticImageQueryVariables = Exact<{
  identifier?: Maybe<Scalars['String']>;
}>;


export type UseStaticImageQuery = (
  { __typename?: 'Queries' }
  & { staticImage?: Maybe<(
    { __typename?: 'StaticImageType' }
    & Pick<StaticImageType, 'id' | 'image' | 'altText'>
  )> }
);

export type InputCountrySelectQueryVariables = Exact<{ [key: string]: never; }>;


export type InputCountrySelectQuery = (
  { __typename?: 'Queries' }
  & { countries: Array<(
    { __typename?: 'CountryType' }
    & Pick<CountryType, 'name' | 'code'>
  )> }
);

export type InputCountrySelectWizardQueryVariables = Exact<{ [key: string]: never; }>;


export type InputCountrySelectWizardQuery = (
  { __typename?: 'Queries' }
  & { countries: Array<(
    { __typename?: 'CountryType' }
    & Pick<CountryType, 'name' | 'code'>
  )> }
);

export type InputCurrencyWizardQueryVariables = Exact<{ [key: string]: never; }>;


export type InputCurrencyWizardQuery = (
  { __typename?: 'Queries' }
  & Pick<Queries, 'projectCurrencies'>
);

export type GlobalContextProviderQueryVariables = Exact<{
  hashId?: Maybe<Scalars['String']>;
}>;


export type GlobalContextProviderQuery = (
  { __typename?: 'Queries' }
  & { currentProfile?: Maybe<(
    { __typename?: 'UserProfileType' }
    & Pick<UserProfileType, 'id'>
    & CurrentProfileFragmentFragment
  )>, project?: Maybe<(
    { __typename?: 'ProjectType' }
    & Pick<ProjectType, 'id'>
    & GlobalContextProviderProjectFragmentFragment
  )> }
);

export type GlobalContextProviderProjectFragmentFragment = (
  { __typename?: 'ProjectType' }
  & Pick<ProjectType, 'id' | 'title'>
  & { user?: Maybe<(
    { __typename?: 'UserType' }
    & { userprofile?: Maybe<(
      { __typename?: 'UserProfileType' }
      & Pick<UserProfileType, 'hasTradeDiscount'>
    )> }
  )> }
);

export type ModalAcceptQuotationMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ModalAcceptQuotationMutation = (
  { __typename?: 'Mutations' }
  & { userAcceptQuotation?: Maybe<(
    { __typename?: 'UserAcceptQuotationMutation' }
    & Pick<UserAcceptQuotationMutation, 'status'>
  )> }
);

export type ModalBucketItemDetailsQueryVariables = Exact<{
  bucketItemId?: Maybe<Scalars['String']>;
}>;


export type ModalBucketItemDetailsQuery = (
  { __typename?: 'Queries' }
  & { getBucketItem?: Maybe<(
    { __typename?: 'BucketItemType' }
    & Pick<BucketItemType, 'id'>
    & BucketItemDetailsFragmentFragment
  )> }
);

export type ModalClearFloorplanConfirmMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ModalClearFloorplanConfirmMutation = (
  { __typename?: 'Mutations' }
  & { floorplanClearEdits?: Maybe<(
    { __typename?: 'FloorplanClearEditsMutation' }
    & Pick<FloorplanClearEditsMutation, 'status'>
    & { floorplan?: Maybe<(
      { __typename?: 'FloorPlanType' }
      & Pick<FloorPlanType, 'id' | 'markedImage'>
    )>, page?: Maybe<(
      { __typename?: 'PDFPageType' }
      & Pick<PdfPageType, 'id' | 'pdfDetails'>
    )> }
  )> }
);

export type ModalComissionedWorksLocationInfoQueryVariables = Exact<{
  locationItemId?: Maybe<Scalars['String']>;
}>;


export type ModalComissionedWorksLocationInfoQuery = (
  { __typename?: 'Queries' }
  & { staffLocationItem?: Maybe<(
    { __typename?: 'LocationItemType' }
    & StaffLocationItemFieldsFragment
  )> }
);

export type StaffLocationItemFieldsFragment = (
  { __typename?: 'LocationItemType' }
  & Pick<LocationItemType, 'id' | 'title' | 'wallWidth' | 'wallHeight' | 'itemWidth' | 'itemHeight' | 'wfCommissionedWorksWorkingTitle' | 'wfCommissionedWorksArtworkDescription' | 'wfCommissionedWorksEstimatedTimeline' | 'wfCommissionedWorksAdditionalQuestions' | 'wfCommissionedWorksBudgetOverride' | 'wfCommissionedWorksBudgetCcy' | 'wfCommissionedWorksWallWidthOverride' | 'wfCommissionedWorksWallHeightOverride' | 'wfCommissionedWorksItemWidthOverride' | 'wfCommissionedWorksItemHeightOverride' | 'wfCommissionedWorksCommission'>
  & { allImagesFull: Array<Maybe<(
    { __typename?: 'ImageType' }
    & Pick<ImageType, 'id' | 'image' | 'isSensitive'>
  )>>, workflowCommissionedWorksImages?: Maybe<Array<(
    { __typename?: 'LocationItemCommissionedWorksImageType' }
    & Pick<LocationItemCommissionedWorksImageType, 'id' | 'imageKey' | 'imageKeyFilename'>
  )>>, location: (
    { __typename?: 'LocationType' }
    & Pick<LocationType, 'id' | 'title'>
    & { locationImages?: Maybe<Array<(
      { __typename?: 'LocationImageType' }
      & Pick<LocationImageType, 'id' | 'image'>
    )>>, project: (
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id' | 'budget'>
    ) }
  ) }
);

export type ModalCreateOrUpdateFramerQueryVariables = Exact<{
  framerId?: Maybe<Scalars['String']>;
}>;


export type ModalCreateOrUpdateFramerQuery = (
  { __typename?: 'Queries' }
  & { framer: (
    { __typename?: 'FramerType' }
    & FramerFragmentFragment
  ) }
);

export type ModalDeleteLocationConfirmMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ModalDeleteLocationConfirmMutation = (
  { __typename?: 'Mutations' }
  & { deleteLocation?: Maybe<(
    { __typename?: 'DeleteLocationMutation' }
    & Pick<DeleteLocationMutation, 'status'>
    & { project?: Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id'>
      & UserWizardProjectLocationItemFragmentFragment
    )> }
  )> }
);

export type ModalDeleteLocationItemConfirmMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ModalDeleteLocationItemConfirmMutation = (
  { __typename?: 'Mutations' }
  & { deleteLocationItem?: Maybe<(
    { __typename?: 'DeleteLocationItemMutation' }
    & Pick<DeleteLocationItemMutation, 'status'>
    & { project?: Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id'>
      & UserWizardProjectLocationItemFragmentFragment
    )> }
  )> }
);

export type ModalDeleteProjectConfirmMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ModalDeleteProjectConfirmMutation = (
  { __typename?: 'Mutations' }
  & { deleteProject?: Maybe<(
    { __typename?: 'DeleteProjectMutation' }
    & Pick<DeleteProjectMutation, 'status'>
    & { project?: Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id'>
      & UserWizardProjectLocationItemFragmentFragment
    )> }
  )> }
);

export type ModalDeleteProposalConfirmMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ModalDeleteProposalConfirmMutation = (
  { __typename?: 'Mutations' }
  & { deleteProposal?: Maybe<(
    { __typename?: 'DeleteProposalMutation' }
    & Pick<DeleteProposalMutation, 'status'>
  )> }
);

export type ModalDeleteVimrImageConfirmMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ModalDeleteVimrImageConfirmMutation = (
  { __typename?: 'Mutations' }
  & { removeProductVimrImage?: Maybe<(
    { __typename?: 'RemoveProductVIMRImageMutation' }
    & Pick<RemoveProductVimrImageMutation, 'status' | 'formErrors'>
    & { bucketItem?: Maybe<(
      { __typename?: 'BucketItemType' }
      & Pick<BucketItemType, 'id'>
      & { vimrImages: Array<(
        { __typename?: 'ProjectVIMRImageType' }
        & Pick<ProjectVimrImageType, 'id' | 'image'>
      )> }
    )> }
  )> }
);

export type ModalDetailsDeleteItemConfirmMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ModalDetailsDeleteItemConfirmMutation = (
  { __typename?: 'Mutations' }
  & { addOrRemoveBucketItem?: Maybe<(
    { __typename?: 'AddOrRemoveBucketItemMutation' }
    & Pick<AddOrRemoveBucketItemMutation, 'status' | 'formErrors'>
    & { project?: Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id' | 'bucketItemsCount'>
    )> }
  )> }
);

export type ModalFeedbackReasonQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;


export type ModalFeedbackReasonQuery = (
  { __typename?: 'Queries' }
  & { getFeedback?: Maybe<(
    { __typename?: 'FeedbackType' }
    & Pick<FeedbackType, 'id' | 'querystring'>
  )> }
);

export type ModalFeedbackReasonUpdateFeedbackMutationVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
}>;


export type ModalFeedbackReasonUpdateFeedbackMutation = (
  { __typename?: 'Mutations' }
  & { updateFeedback?: Maybe<(
    { __typename?: 'UpdateFeedbackMutation' }
    & Pick<UpdateFeedbackMutation, 'status'>
    & { feedback?: Maybe<(
      { __typename?: 'FeedbackType' }
      & Pick<FeedbackType, 'id'>
    )> }
  )> }
);

export type ModalFloorplanQueryVariables = Exact<{
  floorplanId?: Maybe<Scalars['String']>;
}>;


export type ModalFloorplanQuery = (
  { __typename?: 'Queries' }
  & { getFloorplan?: Maybe<(
    { __typename?: 'FloorPlanType' }
    & Pick<FloorPlanType, 'id' | 'image' | 'editsJson' | 'measurementUnit' | 'shownMeasurementUnit' | 'paperSize' | 'scaleMeasurement' | 'unitRatio' | 'measureType'>
  )> }
);

export type ModalFloorplanUpdateFloorplanMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ModalFloorplanUpdateFloorplanMutation = (
  { __typename?: 'Mutations' }
  & { updateFloorplan?: Maybe<(
    { __typename?: 'UpdateFloorplanMutation' }
    & Pick<UpdateFloorplanMutation, 'status'>
    & { floorplan?: Maybe<(
      { __typename?: 'FloorPlanType' }
      & Pick<FloorPlanType, 'id' | 'markedImage'>
    )>, page?: Maybe<(
      { __typename?: 'PDFPageType' }
      & Pick<PdfPageType, 'id' | 'pdfDetails'>
    )> }
  )> }
);

export type ModalFloorplanUpdateFloorplanScaleMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ModalFloorplanUpdateFloorplanScaleMutation = (
  { __typename?: 'Mutations' }
  & { updateFloorplanShownUnit?: Maybe<(
    { __typename?: 'UpdateFloorplanShownUnitMutation' }
    & Pick<UpdateFloorplanShownUnitMutation, 'status' | 'formErrors'>
    & { floorplan?: Maybe<(
      { __typename?: 'FloorPlanType' }
      & Pick<FloorPlanType, 'id' | 'image' | 'editsJson' | 'measurementUnit' | 'shownMeasurementUnit' | 'paperSize' | 'scaleMeasurement' | 'unitRatio'>
    )> }
  )> }
);

export type ModalFlowItemFramerInfoQueryVariables = Exact<{
  flowItemId?: Maybe<Scalars['String']>;
  esId?: Maybe<Scalars['String']>;
}>;


export type ModalFlowItemFramerInfoQuery = (
  { __typename?: 'Queries' }
  & { getProduct?: Maybe<(
    { __typename?: 'ProductType' }
    & Pick<ProductType, 'id' | 'artworksMedium'>
    & { medium?: Maybe<(
      { __typename?: 'SlugAndTitleType' }
      & Pick<SlugAndTitleType, 'slug' | 'title'>
    )> }
  )>, wfStaffFlowItem?: Maybe<(
    { __typename?: 'FlowItemArtworkType' }
    & Pick<FlowItemArtworkType, 'id'>
    & FlowItemFramerInfoFragmentFragment
  )> }
);

export type ModalPdfCoverImageQueryVariables = Exact<{
  hashId?: Maybe<Scalars['String']>;
}>;


export type ModalPdfCoverImageQuery = (
  { __typename?: 'Queries' }
  & { project?: Maybe<(
    { __typename?: 'ProjectType' }
    & Pick<ProjectType, 'id' | 'allImages'>
  )> }
);

export type ModalPdfDeletePageMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ModalPdfDeletePageMutation = (
  { __typename?: 'Mutations' }
  & { deleteProposalPage?: Maybe<(
    { __typename?: 'DeleteProposalPageMutation' }
    & Pick<DeleteProposalPageMutation, 'status'>
    & { proposal?: Maybe<(
      { __typename?: 'PDFProposalType' }
      & Pick<PdfProposalType, 'id'>
      & { pages: Array<(
        { __typename?: 'PDFPageType' }
        & Pick<PdfPageType, 'id'>
        & ProposalPageFragmentFragment
      )> }
    )> }
  )> }
);

export type ModalPdfFooterDetailsQueryVariables = Exact<{
  proposalId?: Maybe<Scalars['String']>;
}>;


export type ModalPdfFooterDetailsQuery = (
  { __typename?: 'Queries' }
  & { getProposal?: Maybe<(
    { __typename?: 'PDFProposalType' }
    & Pick<PdfProposalType, 'id' | 'pdfAddr' | 'pdfEmail' | 'pdfSite'>
  )> }
);

export type ModalPdfLocationDetailsQueryVariables = Exact<{
  hashId?: Maybe<Scalars['String']>;
  locId?: Maybe<Scalars['String']>;
  locItemId?: Maybe<Scalars['String']>;
}>;


export type ModalPdfLocationDetailsQuery = (
  { __typename?: 'Queries' }
  & { locationItem?: Maybe<(
    { __typename?: 'LocationItemType' }
    & LocationItemFieldsFragment
  )> }
);

export type LocationItemFieldsFragment = (
  { __typename?: 'LocationItemType' }
  & Pick<LocationItemType, 'id' | 'allCoverImages' | 'pdfImage'>
);

export type ModalPdfLogoQueryVariables = Exact<{
  proposalId?: Maybe<Scalars['String']>;
}>;


export type ModalPdfLogoQuery = (
  { __typename?: 'Queries' }
  & { getProposal?: Maybe<(
    { __typename?: 'PDFProposalType' }
    & Pick<PdfProposalType, 'id' | 'pdfLogo'>
  )> }
);

export type ModalPdfNewPageDetailsQueryVariables = Exact<{
  hashId?: Maybe<Scalars['String']>;
}>;


export type ModalPdfNewPageDetailsQuery = (
  { __typename?: 'Queries' }
  & { project?: Maybe<(
    { __typename?: 'ProjectType' }
    & Pick<ProjectType, 'id' | 'allImages' | 'pdfImage' | 'pdfLogo'>
    & { vimrImages: Array<(
      { __typename?: 'ProjectVIMRImageType' }
      & Pick<ProjectVimrImageType, 'id' | 'image'>
    )>, locations: Array<(
      { __typename?: 'LocationType' }
      & Pick<LocationType, 'id' | 'titleStr'>
      & { floorplans?: Maybe<Array<(
        { __typename?: 'FloorPlanType' }
        & Pick<FloorPlanType, 'id' | 'markedImage'>
      )>>, locationItems: Array<(
        { __typename?: 'LocationItemType' }
        & LocationItemsFieldsFragment
      )> }
    )> }
  )> }
);

export type LocationItemsFieldsFragment = (
  { __typename?: 'LocationItemType' }
  & Pick<LocationItemType, 'id' | 'titleStr' | 'allCoverImages' | 'pdfImage'>
  & { bucketItems?: Maybe<Array<(
    { __typename?: 'BucketItemType' }
    & Pick<BucketItemType, 'id' | 'image'>
  )>>, floorplans?: Maybe<Array<(
    { __typename?: 'FloorPlanType' }
    & Pick<FloorPlanType, 'id' | 'markedImage'>
  )>> }
);

export type ModalPdfProjectDetailsQueryVariables = Exact<{
  hashId?: Maybe<Scalars['String']>;
}>;


export type ModalPdfProjectDetailsQuery = (
  { __typename?: 'Queries' }
  & { project?: Maybe<(
    { __typename?: 'ProjectType' }
    & Pick<ProjectType, 'id' | 'allImages' | 'pdfImage'>
  )> }
);

export type ModalPdfProposalsQueryVariables = Exact<{
  hashId?: Maybe<Scalars['String']>;
}>;


export type ModalPdfProposalsQuery = (
  { __typename?: 'Queries' }
  & { getProjectProposals: Array<(
    { __typename?: 'PDFProposalType' }
    & ProposalFieldsFragment
  )> }
);

export type ProposalFieldsFragment = (
  { __typename?: 'PDFProposalType' }
  & Pick<PdfProposalType, 'id' | 'title' | 'createdAt' | 'lastUpdated'>
  & { pages: Array<(
    { __typename?: 'PDFPageType' }
    & Pick<PdfPageType, 'id'>
  )> }
);

export type ModalProductDetailsQueryVariables = Exact<{
  esId?: Maybe<Scalars['String']>;
  hashId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  locationItemId?: Maybe<Scalars['String']>;
}>;


export type ModalProductDetailsQuery = (
  { __typename?: 'Queries' }
  & { currentProfile?: Maybe<(
    { __typename?: 'UserProfileType' }
    & Pick<UserProfileType, 'id'>
    & { userVirImages: Array<(
      { __typename?: 'UserVIRImageType' }
      & Pick<UserVirImageType, 'id' | 'externalId' | 'image' | 'imageFilename'>
    )> }
  )>, getProduct?: Maybe<(
    { __typename?: 'ProductType' }
    & Pick<ProductType, 'id'>
    & ProductFragmentFragment
  )>, getProductSameArtist?: Maybe<(
    { __typename?: 'ProductPaginatedType' }
    & Pick<ProductPaginatedType, 'total'>
    & { objects?: Maybe<Array<Maybe<(
      { __typename?: 'ProductType' }
      & Pick<ProductType, 'id'>
      & ProductGridFragmentFragment
    )>>> }
  )>, locationItem?: Maybe<(
    { __typename?: 'LocationItemType' }
    & Pick<LocationItemType, 'id'>
    & ModalProductDetailsLocationItemFragmentFragment
  )> }
);

export type ModalProductDetailsLocationItemFragmentFragment = (
  { __typename?: 'LocationItemType' }
  & Pick<LocationItemType, 'id' | 'allImages'>
  & { locationItemType?: Maybe<(
    { __typename?: 'LocationItemTypeType' }
    & Pick<LocationItemTypeType, 'id' | 'title'>
  )>, location: (
    { __typename?: 'LocationType' }
    & Pick<LocationType, 'id' | 'externalId'>
    & { project: (
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id' | 'hashId'>
    ) }
  ), locationItemImages?: Maybe<Array<(
    { __typename?: 'LocationItemImageType' }
    & Pick<LocationItemImageType, 'id' | 'image' | 'imageFilename'>
  )>> }
);

export type ModalProjectCreateUpdateClientQueryVariables = Exact<{
  clientId?: Maybe<Scalars['String']>;
}>;


export type ModalProjectCreateUpdateClientQuery = (
  { __typename?: 'Queries' }
  & { client?: Maybe<(
    { __typename?: 'ClientType' }
    & Pick<ClientType, 'id' | 'name'>
  )> }
);

export type ModalProjectRevokeAccessMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ModalProjectRevokeAccessMutation = (
  { __typename?: 'Mutations' }
  & { revokeProjectAccess?: Maybe<(
    { __typename?: 'RevokeProjectAccessMutation' }
    & Pick<RevokeProjectAccessMutation, 'status'>
    & { project?: Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id'>
      & ProjectInviteFragmentFragment
    )> }
  )> }
);

export type ModalProjectSavedImagesQueryVariables = Exact<{
  hashId?: Maybe<Scalars['String']>;
}>;


export type ModalProjectSavedImagesQuery = (
  { __typename?: 'Queries' }
  & { project?: Maybe<(
    { __typename?: 'ProjectType' }
    & Pick<ProjectType, 'id' | 'hashId'>
    & { vimrImages: Array<(
      { __typename?: 'ProjectVIMRImageType' }
      & Pick<ProjectVimrImageType, 'id' | 'image' | 'imageFilename'>
    )> }
  )> }
);

export type ImageSelectorDeleteVimrImagesMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ImageSelectorDeleteVimrImagesMutation = (
  { __typename?: 'Mutations' }
  & { deleteVimrImages?: Maybe<(
    { __typename?: 'DeleteVIMRImagesMutation' }
    & Pick<DeleteVimrImagesMutation, 'status'>
    & { project?: Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id' | 'hashId'>
      & { vimrImages: Array<(
        { __typename?: 'ProjectVIMRImageType' }
        & Pick<ProjectVimrImageType, 'id' | 'image' | 'imageFilename'>
      )> }
    )> }
  )> }
);

export type ModalRequestArtworkProposalMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ModalRequestArtworkProposalMutation = (
  { __typename?: 'Mutations' }
  & { requestArtworkProposal?: Maybe<(
    { __typename?: 'RequestArtworkProposalMutation' }
    & Pick<RequestArtworkProposalMutation, 'status'>
    & { project?: Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id' | 'artworkProposalRequestedAt'>
    )> }
  )> }
);

export type ModalReviewPendingQuotationQueryVariables = Exact<{
  hashId?: Maybe<Scalars['String']>;
}>;


export type ModalReviewPendingQuotationQuery = (
  { __typename?: 'Queries' }
  & { quotation?: Maybe<(
    { __typename?: 'QuotationType' }
    & Pick<QuotationType, 'id' | 'submittedAtStr' | 'installationRequired' | 'comments' | 'shippingAddressStr' | 'billingAddressStr'>
    & { quotationitemSet?: Maybe<Array<Maybe<(
      { __typename?: 'QuotationItemType' }
      & QuotationitemSetFieldsFragment
    )>>> }
  )> }
);

export type QuotationitemSetFieldsFragment = (
  { __typename?: 'QuotationItemType' }
  & Pick<QuotationItemType, 'id' | 'productImage' | 'productTitle' | 'artistName' | 'bucketItemCustomText' | 'price' | 'quantity' | 'framingRequired' | 'framingComments' | 'additionalComments'>
);

export type ModalSourcingArtistCreateUpdateArtistQueryVariables = Exact<{
  artistId?: Maybe<Scalars['String']>;
}>;


export type ModalSourcingArtistCreateUpdateArtistQuery = (
  { __typename?: 'Queries' }
  & { artist?: Maybe<(
    { __typename?: 'ArtistSourcingType' }
    & Pick<ArtistSourcingType, 'id' | 'name' | 'website' | 'contactEmail' | 'yearOfBirth' | 'country' | 'tags' | 'notes'>
  )> }
);

export type ModalSourcingArtistCreateUpdateGalleryQueryVariables = Exact<{
  galleryId?: Maybe<Scalars['String']>;
}>;


export type ModalSourcingArtistCreateUpdateGalleryQuery = (
  { __typename?: 'Queries' }
  & { gallery?: Maybe<(
    { __typename?: 'GallerySourcingType' }
    & Pick<GallerySourcingType, 'id' | 'name' | 'website' | 'contactEmail' | 'contactNumber' | 'countryCodes' | 'tags' | 'notes'>
  )> }
);

export type ModalStaffSendArtworkProposalMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ModalStaffSendArtworkProposalMutation = (
  { __typename?: 'Mutations' }
  & { staffFinishArtworkProposal?: Maybe<(
    { __typename?: 'StaffFinishArtworkProposalMutation' }
    & Pick<StaffFinishArtworkProposalMutation, 'status'>
    & { project?: Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id' | 'artworkProposalRequestedAt' | 'artworkProposalSentAt'>
    )> }
  )> }
);

export type SidebarUserQueryVariables = Exact<{
  hashId?: Maybe<Scalars['String']>;
}>;


export type SidebarUserQuery = (
  { __typename?: 'Queries' }
  & { project?: Maybe<(
    { __typename?: 'ProjectType' }
    & Pick<ProjectType, 'id' | 'hashId' | 'wizardStep' | 'bucketItemsCount' | 'artworkProposalRequestedAt' | 'artworkProposalSentAt' | 'status'>
  )> }
);

export type SidebarUserNewQueryVariables = Exact<{
  hashId?: Maybe<Scalars['String']>;
}>;


export type SidebarUserNewQuery = (
  { __typename?: 'Queries' }
  & { project?: Maybe<(
    { __typename?: 'ProjectType' }
    & Pick<ProjectType, 'id' | 'hashId' | 'wizardStep' | 'bucketItemsCount' | 'artworkProposalRequestedAt' | 'artworkProposalSentAt'>
  )> }
);

export type SidebarUserOldQueryVariables = Exact<{
  hashId?: Maybe<Scalars['String']>;
}>;


export type SidebarUserOldQuery = (
  { __typename?: 'Queries' }
  & { project?: Maybe<(
    { __typename?: 'ProjectType' }
    & Pick<ProjectType, 'id' | 'hashId' | 'wizardStep' | 'bucketItemsCount' | 'artworkProposalRequestedAt' | 'artworkProposalSentAt'>
  )> }
);

export type TopbarSubRecentProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type TopbarSubRecentProjectsQuery = (
  { __typename?: 'Queries' }
  & { recentProjects?: Maybe<Array<(
    { __typename?: 'RecentProjectType' }
    & Pick<RecentProjectType, 'id'>
    & { project: (
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id' | 'title'>
      & { client?: Maybe<(
        { __typename?: 'ClientType' }
        & Pick<ClientType, 'id' | 'name'>
      )> }
    ) }
  )>> }
);

export type LocationDetailsListItemUpdateBucketItemMutationVariables = Exact<{
  hashId?: Maybe<Scalars['String']>;
  locationItemId?: Maybe<Scalars['String']>;
  esId?: Maybe<Scalars['String']>;
  customText?: Maybe<Scalars['String']>;
}>;


export type LocationDetailsListItemUpdateBucketItemMutation = (
  { __typename?: 'Mutations' }
  & { updateBucketItem?: Maybe<(
    { __typename?: 'UpdateBucketItemMutation' }
    & Pick<UpdateBucketItemMutation, 'status' | 'formErrors'>
    & { bucketItem?: Maybe<(
      { __typename?: 'BucketItemType' }
      & Pick<BucketItemType, 'id' | 'customText' | 'customSize'>
    )> }
  )> }
);

export type LocationDetailsListItemResetBucketItemCustomTextMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type LocationDetailsListItemResetBucketItemCustomTextMutation = (
  { __typename?: 'Mutations' }
  & { resetBucketItemCustomText?: Maybe<(
    { __typename?: 'ResetBucketItemCustomTextMutation' }
    & Pick<ResetBucketItemCustomTextMutation, 'status'>
    & { bucketItem?: Maybe<(
      { __typename?: 'ProductAndItemType' }
      & Pick<ProductAndItemType, 'id' | 'copyPasteText'>
      & { bucketItem: (
        { __typename?: 'BucketItemType' }
        & Pick<BucketItemType, 'id'>
        & BucketItemDetailsFragmentFragment
      ) }
    )> }
  )> }
);

export type ProductGridItemFragment = (
  { __typename?: 'ProductType' }
  & Pick<ProductType, 'id' | 'esId' | 'image' | 'name' | 'artistUpdatedAt' | 'cheapestPriceString' | 'sizeString' | 'site' | 'favorited'>
  & { artist?: Maybe<(
    { __typename?: 'ArtistType' }
    & Pick<ArtistType, 'name'>
  )>, gallery?: Maybe<(
    { __typename?: 'GalleryType' }
    & Pick<GalleryType, 'name'>
  )>, category?: Maybe<(
    { __typename?: 'SlugType' }
    & Pick<SlugType, 'slug'>
  )>, sizes: Array<(
    { __typename?: 'SizeType' }
    & ButtonToggleBucketSizePopupSizeFragment
    & BucketUtilsFragment
  )> }
);

export type SearchSimilarImageCacheMutationVariables = Exact<{
  fileKey: Scalars['String'];
}>;


export type SearchSimilarImageCacheMutation = (
  { __typename?: 'Mutations' }
  & { getImageCache?: Maybe<(
    { __typename?: 'GetImageCacheMutation' }
    & Pick<GetImageCacheMutation, 'status'>
    & { imageCache?: Maybe<(
      { __typename?: 'ImageCacheType' }
      & Pick<ImageCacheType, 'id' | 'imageHash'>
    )> }
  )> }
);

export type SearchSimilarQueryVariables = Exact<{
  imgHash?: Maybe<Scalars['String']>;
}>;


export type SearchSimilarQuery = (
  { __typename?: 'Queries' }
  & { cacheImg?: Maybe<(
    { __typename?: 'ImageCacheType' }
    & Pick<ImageCacheType, 'id' | 'key'>
  )> }
);

export type SearchSimilarStretchedMutationVariables = Exact<{
  fileKey: Scalars['String'];
}>;


export type SearchSimilarStretchedMutation = (
  { __typename?: 'Mutations' }
  & { getImageCache?: Maybe<(
    { __typename?: 'GetImageCacheMutation' }
    & Pick<GetImageCacheMutation, 'status'>
    & { imageCache?: Maybe<(
      { __typename?: 'ImageCacheType' }
      & Pick<ImageCacheType, 'id' | 'imageHash'>
    )> }
  )> }
);

export type LocationImageMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type LocationImageMutation = (
  { __typename?: 'Mutations' }
  & { createUpdateLocationImage?: Maybe<(
    { __typename?: 'CreateUpdateLocationImageMutation' }
    & Pick<CreateUpdateLocationImageMutation, 'status'>
    & { location?: Maybe<(
      { __typename?: 'LocationType' }
      & Pick<LocationType, 'id'>
      & { locationImages?: Maybe<Array<(
        { __typename?: 'LocationImageType' }
        & Pick<LocationImageType, 'id' | 'externalId' | 'image'>
      )>> }
    )> }
  )> }
);

export type LocationInfoMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type LocationInfoMutation = (
  { __typename?: 'Mutations' }
  & { updateLocationInfo?: Maybe<(
    { __typename?: 'UpdateLocationInfoMutation' }
    & Pick<UpdateLocationInfoMutation, 'status'>
    & { location?: Maybe<(
      { __typename?: 'LocationType' }
      & Pick<LocationType, 'id' | 'externalId' | 'info'>
    )> }
  )> }
);

export type LocationListMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type LocationListMutation = (
  { __typename?: 'Mutations' }
  & { createNewLocation?: Maybe<(
    { __typename?: 'CreateNewLocationMutation' }
    & Pick<CreateNewLocationMutation, 'status'>
    & { project?: Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id'>
      & { locations: Array<(
        { __typename?: 'LocationType' }
        & Pick<LocationType, 'id' | 'externalId' | 'title'>
        & { locationImages?: Maybe<Array<(
          { __typename?: 'LocationImageType' }
          & Pick<LocationImageType, 'id' | 'externalId'>
        )>>, locationItems: Array<(
          { __typename?: 'LocationItemType' }
          & Pick<LocationItemType, 'id'>
          & LocationItemDetailFragmentFragment
        )> }
      )> }
    )> }
  )> }
);

export type LocationWithItemsLocationMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type LocationWithItemsLocationMutation = (
  { __typename?: 'Mutations' }
  & { createNewLocationItem?: Maybe<(
    { __typename?: 'CreateNewLocationItemMutation' }
    & Pick<CreateNewLocationItemMutation, 'status'>
    & { project?: Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id'>
      & { locations: Array<(
        { __typename?: 'LocationType' }
        & Pick<LocationType, 'id' | 'externalId' | 'title'>
        & { locationImages?: Maybe<Array<(
          { __typename?: 'LocationImageType' }
          & Pick<LocationImageType, 'id' | 'externalId' | 'image'>
        )>>, locationItems: Array<(
          { __typename?: 'LocationItemType' }
          & Pick<LocationItemType, 'id'>
          & LocationItemDetailFragmentFragment
        )> }
      )> }
    )> }
  )> }
);

export type WfnwFramersForProjectTableQueryVariables = Exact<{
  hashId?: Maybe<Scalars['String']>;
}>;


export type WfnwFramersForProjectTableQuery = (
  { __typename?: 'Queries' }
  & { project?: Maybe<(
    { __typename?: 'ProjectType' }
    & Pick<ProjectType, 'id'>
    & { wfNwFramers?: Maybe<Array<Maybe<(
      { __typename?: 'FramerType' }
      & Pick<FramerType, 'id'>
      & FramerRowFragmentFragment
    )>>> }
  )> }
);

export type WfnwFramersForProjectTableDeleteFramerMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type WfnwFramersForProjectTableDeleteFramerMutation = (
  { __typename?: 'Mutations' }
  & { deleteFramerFromProject?: Maybe<(
    { __typename?: 'DeleteFramerFromProjectMutation' }
    & Pick<DeleteFramerFromProjectMutation, 'status' | 'formErrors'>
    & { project?: Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id'>
      & { wfNwFramers?: Maybe<Array<Maybe<(
        { __typename?: 'FramerType' }
        & Pick<FramerType, 'id'>
        & FramerRowFragmentFragment
      )>>> }
    )> }
  )> }
);

export type WorkflowProjectQueryVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type WorkflowProjectQuery = (
  { __typename?: 'Queries' }
  & { wfNormalWorksStepsData?: Maybe<(
    { __typename?: 'NormalWorksProjectSteps' }
    & Pick<NormalWorksProjectSteps, 'itemGroup' | 'projectTitle'>
    & { steps: Array<(
      { __typename?: 'NormalWorksStepItems' }
      & Pick<NormalWorksStepItems, 'step'>
      & { items: Array<(
        { __typename?: 'FlowItemArtworkType' }
        & Pick<FlowItemArtworkType, 'id' | 'image' | 'esId'>
        & { currentStates?: Maybe<Array<Maybe<(
          { __typename?: 'CurerntStateType' }
          & Pick<CurerntStateType, 'id' | 'state' | 'uniqueUrl'>
        )>>>, locationItem?: Maybe<(
          { __typename?: 'LocationItemType' }
          & Pick<LocationItemType, 'id'>
          & { location: (
            { __typename?: 'LocationType' }
            & Pick<LocationType, 'id'>
            & { project: (
              { __typename?: 'ProjectType' }
              & Pick<ProjectType, 'id' | 'hashId'>
            ) }
          ) }
        )> }
      )> }
    )> }
  )>, wfCommissionedWorksStepsData?: Maybe<(
    { __typename?: 'CommissionedWorksProjectSteps' }
    & Pick<CommissionedWorksProjectSteps, 'itemGroup' | 'projectTitle'>
    & { steps: Array<(
      { __typename?: 'CommissionedWorksStepItems' }
      & Pick<CommissionedWorksStepItems, 'step'>
      & { items: Array<(
        { __typename?: 'FlowItemCommissionedWorkType' }
        & Pick<FlowItemCommissionedWorkType, 'id' | 'image' | 'esId'>
        & { artist?: Maybe<(
          { __typename?: 'ArtistSourcingType' }
          & Pick<ArtistSourcingType, 'id' | 'contactEmail'>
        )>, currentStates?: Maybe<Array<Maybe<(
          { __typename?: 'CurerntStateType' }
          & Pick<CurerntStateType, 'id' | 'state' | 'uniqueUrl'>
        )>>>, locationItem?: Maybe<(
          { __typename?: 'LocationItemType' }
          & Pick<LocationItemType, 'id'>
          & { location: (
            { __typename?: 'LocationType' }
            & Pick<LocationType, 'id'>
            & { project: (
              { __typename?: 'ProjectType' }
              & Pick<ProjectType, 'id' | 'hashId'>
            ) }
          ) }
        )> }
      )> }
    )> }
  )> }
);

export type FlowItemCommissionedWorkStaffFragmentFragment = (
  { __typename?: 'FlowItemCommissionedWorkType' }
  & Pick<FlowItemCommissionedWorkType, 'id' | 'title' | 'artworkDescription' | 'estimatedTimeline' | 'budget' | 'isAvailable' | 'cost' | 'leadTime' | 'hasSketchFee' | 'sketchFee' | 'portfolio1' | 'portfolio2' | 'portfolio3' | 'remarks'>
  & { artist?: Maybe<(
    { __typename?: 'ArtistSourcingType' }
    & Pick<ArtistSourcingType, 'id' | 'name'>
  )>, history: Array<(
    { __typename?: 'CurerntStateType' }
    & Pick<CurerntStateType, 'id' | 'state' | 'dateCreated' | 'dateFinished' | 'data' | 'uniqueUrl'>
    & { changeUser?: Maybe<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'id' | 'email'>
    )> }
  )> }
);

export type FlowItemArtworkStaffFragmentFragment = (
  { __typename?: 'FlowItemArtworkType' }
  & Pick<FlowItemArtworkType, 'id' | 'isAvailable' | 'isNotReady' | 'leadTime' | 'destinationCountryName' | 'destinationCity' | 'destinationZip' | 'shipsFromCountryName' | 'shipsFromCity' | 'shipsFromZip' | 'shippedRolled' | 'shippedFramed' | 'isShippingCostUnknown' | 'shippingCost' | 'remarks'>
  & { history: Array<(
    { __typename?: 'CurerntStateType' }
    & Pick<CurerntStateType, 'id' | 'state' | 'dateCreated' | 'dateFinished' | 'data' | 'uniqueUrl' | 'changeUserStr'>
    & { changeUser?: Maybe<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'id' | 'email'>
    )> }
  )> }
);

export type CurrentProfileFragmentFragment = (
  { __typename?: 'UserProfileType' }
  & Pick<UserProfileType, 'id' | 'isSuperuser' | 'crispUserHash' | 'joyrideSearchViewCompleted' | 'joyrideShortlistedViewCompleted' | 'lastSourced' | 'hasTradeDiscount'>
  & { user: (
    { __typename?: 'UserType' }
    & Pick<UserType, 'id' | 'email'>
  ) }
);

export type FramingSpecsQuotationsFieldsFragment = (
  { __typename?: 'FramingSpecsQuotationType' }
  & Pick<FramingSpecsQuotationType, 'id' | 'frameWidth' | 'frameHeight' | 'frameThickness' | 'frameMaterial' | 'frameColour' | 'matMaterial' | 'matThickness' | 'glazingMaterial' | 'cost' | 'leadTimeUnit' | 'leadTime' | 'comments'>
  & { images?: Maybe<Array<Maybe<(
    { __typename?: 'FramingSpecsQuotationImageType' }
    & Pick<FramingSpecsQuotationImageType, 'id' | 'imageKey' | 'imageKeyFilename'>
  )>>> }
);

export type FramerGlobalQuotationFragmentFlowItemsFieldsFragment = (
  { __typename?: 'FlowItemArtworkFramerRFQType' }
  & Pick<FlowItemArtworkFramerRfqType, 'id' | 'title' | 'image' | 'destinationCountryName' | 'destinationCity' | 'destinationZip' | 'framerArtworkMaterial' | 'framerDeliveryRequired' | 'framerStretchingRequired' | 'framerFramingRequired' | 'framerInstallationRequired' | 'framerLocksComment' | 'framerWeight' | 'framerSizeStr' | 'framerWeightStr' | 'framerQuantity' | 'framerNotes'>
  & { stretchingQuotation?: Maybe<(
    { __typename?: 'StretchingQuotationType' }
    & Pick<StretchingQuotationType, 'id' | 'cost' | 'leadTimeUnit' | 'leadTime' | 'comments'>
  )>, framingSpecsQuotations: Array<(
    { __typename?: 'FramingSpecsQuotationType' }
    & FramingSpecsQuotationsFieldsFragment
  )> }
);

export type FramerGlobalQuotationFragmentFragment = (
  { __typename?: 'FramerGlobalQuotationType' }
  & Pick<FramerGlobalQuotationType, 'isAvailable' | 'isAvailableStretching' | 'isAvailableFraming' | 'isAvailableDelivery' | 'isAvailableInstallation' | 'unit' | 'currency' | 'deliveryCost' | 'deliveryComments' | 'installationCost' | 'installationAdditionalCharge' | 'installationAdditionalCost' | 'installationComments' | 'dateFinished'>
  & { flowItems: Array<(
    { __typename?: 'FlowItemArtworkFramerRFQType' }
    & FramerGlobalQuotationFragmentFlowItemsFieldsFragment
  )> }
);

export type FramerFragmentFragment = (
  { __typename?: 'FramerType' }
  & Pick<FramerType, 'id' | 'name' | 'primaryEmail' | 'secondaryEmails' | 'providesFraming' | 'providesInstallation' | 'providesStretching' | 'adminNotes' | 'country'>
  & { framerId: FramerType['id'] }
);

export type FramerRowFragmentFragment = (
  { __typename?: 'FramerType' }
  & Pick<FramerType, 'name' | 'providesFraming' | 'providesInstallation' | 'providesStretching'>
);

export type FlowItemFramerInfoFragmentFragment = (
  { __typename?: 'FlowItemArtworkType' }
  & Pick<FlowItemArtworkType, 'framerArtworkMaterial' | 'framerUnit' | 'framerWidth' | 'framerHeight' | 'framerThickness' | 'framerWeight' | 'framerQuantity' | 'framerNotes' | 'framerLocksComment' | 'framerFramingRequired' | 'framerInstallationRequired' | 'framerStretchingRequired' | 'framerDeliveryRequired' | 'framerStaffUpdatedAt'>
  & { bucketItem?: Maybe<(
    { __typename?: 'BucketItemType' }
    & Pick<BucketItemType, 'id' | 'selectedSize'>
  )> }
);

export type WorkflowSelectedWorksFragmentFragment = (
  { __typename?: 'NormalWorksLocation' }
  & Pick<NormalWorksLocation, 'locationTitle'>
  & { id: NormalWorksLocation['locationId'] }
  & { locationItems: Array<(
    { __typename?: 'NormalWorksLocationItem' }
    & Pick<NormalWorksLocationItem, 'locationItemTitle'>
    & { id: NormalWorksLocationItem['locationItemId'] }
    & { steps: Array<(
      { __typename?: 'NormalWorksStepItems' }
      & Pick<NormalWorksStepItems, 'step'>
      & { items_final: Array<(
        { __typename?: 'FlowItemArtworkType' }
        & Pick<FlowItemArtworkType, 'id' | 'esId' | 'image' | 'title' | 'artist' | 'framerStaffUpdatedAt' | 'framerStretchingRequired' | 'framerDeliveryRequired' | 'framerFramingRequired' | 'framerInstallationRequired' | 'framerLocksComment'>
        & { locationItem?: Maybe<(
          { __typename?: 'LocationItemType' }
          & Pick<LocationItemType, 'id' | 'title'>
          & { location: (
            { __typename?: 'LocationType' }
            & Pick<LocationType, 'id' | 'title'>
            & { project: (
              { __typename?: 'ProjectType' }
              & Pick<ProjectType, 'hashId' | 'id'>
            ) }
          ) }
        )>, currentStates?: Maybe<Array<Maybe<(
          { __typename?: 'CurerntStateType' }
          & Pick<CurerntStateType, 'id' | 'state' | 'dateCreated' | 'uniqueUrl'>
        )>>> }
      )> }
    )> }
  )> }
);

export type LocationItemDetailFragmentFragment = (
  { __typename?: 'LocationItemType' }
  & Pick<LocationItemType, 'id' | 'dimensionsStr' | 'info' | 'externalId' | 'itemHeight' | 'itemUnsure' | 'itemWidth' | 'itemDepth' | 'measurementUnit' | 'title' | 'titleStr' | 'wallHeight' | 'wallUnsure' | 'wallWidth' | 'wallDepth' | 'wallSizeSameAsArtwork' | 'locationItemIsReady' | 'isCommissionedWork' | 'commissionedWorkInfo'>
  & { attribute1?: Maybe<Array<Maybe<(
    { __typename?: 'Attribute1Type' }
    & Pick<Attribute1Type, 'id' | 'title'>
  )>>>, attribute2?: Maybe<Array<Maybe<(
    { __typename?: 'Attribute2Type' }
    & Pick<Attribute2Type, 'id' | 'title'>
  )>>>, bucketItems?: Maybe<Array<(
    { __typename?: 'BucketItemType' }
    & Pick<BucketItemType, 'id'>
  )>>, category?: Maybe<Array<Maybe<(
    { __typename?: 'CategoryType' }
    & Pick<CategoryType, 'id' | 'title' | 'slug'>
  )>>>, locationItemType?: Maybe<(
    { __typename?: 'LocationItemTypeType' }
    & Pick<LocationItemTypeType, 'id' | 'title' | 'slug'>
  )>, locationItemImages?: Maybe<Array<(
    { __typename?: 'LocationItemImageType' }
    & Pick<LocationItemImageType, 'id' | 'externalId' | 'image' | 'imageFilename' | 'isSensitive'>
  )>>, floorplans?: Maybe<Array<(
    { __typename?: 'FloorPlanType' }
    & Pick<FloorPlanType, 'id' | 'image' | 'imageFilename' | 'isSensitive'>
  )>>, location: (
    { __typename?: 'LocationType' }
    & Pick<LocationType, 'id' | 'externalId' | 'title' | 'titleStr'>
    & { project: (
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id' | 'title' | 'hashId' | 'budgetUnsure' | 'budget' | 'budgetCurrency' | 'countryName' | 'status'>
    ), locationItems: Array<(
      { __typename?: 'LocationItemType' }
      & Pick<LocationItemType, 'id' | 'externalId'>
    )>, locationImages?: Maybe<Array<(
      { __typename?: 'LocationImageType' }
      & Pick<LocationImageType, 'id' | 'externalId' | 'image' | 'imageFilename'>
    )>> }
  ) }
);

export type ProjectOverviewFragmentFragment = (
  { __typename?: 'ProjectType' }
  & Pick<ProjectType, 'id' | 'hashId' | 'wizardStep' | 'locationCount' | 'wizardStepIsReady'>
  & { locations: Array<(
    { __typename?: 'LocationType' }
    & Pick<LocationType, 'id' | 'externalId' | 'title' | 'locationIsReady'>
    & { locationItems: Array<(
      { __typename?: 'LocationItemType' }
      & Pick<LocationItemType, 'id' | 'externalId' | 'locationItemIsReady'>
    )> }
  )> }
);

export type BucketItemDetailsFragmentFragment = (
  { __typename?: 'BucketItemType' }
  & Pick<BucketItemType, 'id' | 'artworkTitle' | 'artistName' | 'price' | 'shipsFrom' | 'dimensions' | 'mediums' | 'otherDetails'>
);

export type LocationSummaryFragmentFragment = (
  { __typename?: 'LocationType' }
  & Pick<LocationType, 'id' | 'externalId' | 'title' | 'info' | 'budget' | 'locationImageUrl' | 'hasCommissioned' | 'hasImages'>
  & { locationImages?: Maybe<Array<(
    { __typename?: 'LocationImageType' }
    & Pick<LocationImageType, 'id' | 'externalId' | 'image' | 'isSensitive'>
  )>>, locationItems: Array<(
    { __typename?: 'LocationItemType' }
    & Pick<LocationItemType, 'id'>
    & LocationItemDetailFragmentFragment
  )>, activeLocationItems: Array<(
    { __typename?: 'LocationItemType' }
    & Pick<LocationItemType, 'id'>
    & LocationItemDetailFragmentFragment
  )> }
);

export type ProjectInviteFragmentFragment = (
  { __typename?: 'ProjectType' }
  & Pick<ProjectType, 'id'>
  & { unusedSignupkeys: Array<Maybe<(
    { __typename?: 'SignupKeyType' }
    & Pick<SignupKeyType, 'id' | 'inviteSentTo'>
  )>>, sharedUsers?: Maybe<Array<Maybe<(
    { __typename?: 'UserType' }
    & Pick<UserType, 'id' | 'email'>
    & { userprofile?: Maybe<(
      { __typename?: 'UserProfileType' }
      & Pick<UserProfileType, 'id' | 'firstName' | 'lastName'>
    )> }
  )>>> }
);

export type ProjectSummaryFragmentFragment = (
  { __typename?: 'ProjectType' }
  & Pick<ProjectType, 'id' | 'hashId' | 'wizardStep' | 'status' | 'title' | 'timelineUnsure' | 'timelineStart' | 'timelineEnd' | 'budget' | 'budgetCurrency' | 'city' | 'countryName' | 'countryCode' | 'postalCode' | 'quotationInstallationRequired' | 'quotationComments' | 'quotationShippingName' | 'quotationShippingAddress' | 'quotationShippingCity' | 'quotationShippingState' | 'quotationShippingZip' | 'quotationShippingCountryName' | 'quotationShippingCountryCode' | 'quotationBillingName' | 'quotationBillingAddress' | 'quotationBillingCity' | 'quotationBillingState' | 'quotationBillingZip' | 'quotationBillingCountryName' | 'quotationBillingCountryCode' | 'statusComputed' | 'artworkProposalRequestedAt' | 'artworkProposalSentAt' | 'projectTypesStr'>
  & { client?: Maybe<(
    { __typename?: 'ClientType' }
    & Pick<ClientType, 'id' | 'name'>
  )>, projectType?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectTypeType' }
    & Pick<ProjectTypeType, 'id' | 'title'>
  )>>>, locations: Array<(
    { __typename?: 'LocationType' }
    & Pick<LocationType, 'id'>
    & LocationSummaryFragmentFragment
  )>, activeLocations: Array<(
    { __typename?: 'LocationType' }
    & Pick<LocationType, 'id'>
    & LocationSummaryFragmentFragment
  )> }
);

export type ProjectBudgetFragmentFragment = (
  { __typename?: 'ProjectType' }
  & Pick<ProjectType, 'id' | 'hashId' | 'wizardStep' | 'wizardStepIsReady' | 'budget' | 'budgetCurrency' | 'budgetInfo' | 'budgetUnsure'>
  & { locations: Array<(
    { __typename?: 'LocationType' }
    & Pick<LocationType, 'id' | 'externalId' | 'title'>
    & { locationItems: Array<(
      { __typename?: 'LocationItemType' }
      & Pick<LocationItemType, 'id' | 'externalId'>
    )> }
  )> }
);

export type ProjectTimelineFragmentFragment = (
  { __typename?: 'ProjectType' }
  & Pick<ProjectType, 'id' | 'hashId' | 'wizardStep' | 'wizardStepIsReady' | 'timelineEnd' | 'timelineUnsure' | 'timelineInfo'>
  & { locations: Array<(
    { __typename?: 'LocationType' }
    & Pick<LocationType, 'id' | 'externalId'>
    & { locationItems: Array<(
      { __typename?: 'LocationItemType' }
      & Pick<LocationItemType, 'id' | 'externalId'>
    )> }
  )> }
);

export type ProjectTypeFragmentFragment = (
  { __typename?: 'ProjectType' }
  & Pick<ProjectType, 'id' | 'hashId' | 'wizardStep' | 'wizardStepIsReady' | 'projectTypeOther' | 'projectTypeInfo'>
  & { projectType?: Maybe<Array<Maybe<(
    { __typename?: 'ProjectTypeType' }
    & Pick<ProjectTypeType, 'id' | 'title' | 'iconSlug'>
  )>>>, locations: Array<(
    { __typename?: 'LocationType' }
    & Pick<LocationType, 'id' | 'externalId'>
    & { locationItems: Array<(
      { __typename?: 'LocationItemType' }
      & Pick<LocationItemType, 'id' | 'externalId'>
    )> }
  )> }
);

export type ProjectTitleFragmentFragment = (
  { __typename?: 'ProjectType' }
  & Pick<ProjectType, 'id' | 'status' | 'hashId' | 'title' | 'titleInfo' | 'wizardStep' | 'wizardStepIsReady' | 'postalCode' | 'countryName' | 'countryCode' | 'city'>
  & { client?: Maybe<(
    { __typename?: 'ClientType' }
    & Pick<ClientType, 'id' | 'name'>
  )>, locations: Array<(
    { __typename?: 'LocationType' }
    & Pick<LocationType, 'id' | 'externalId'>
    & { locationItems: Array<(
      { __typename?: 'LocationItemType' }
      & Pick<LocationItemType, 'id' | 'externalId'>
    )> }
  )> }
);

export type ProjectPdfPageFragmentFragment = (
  { __typename?: 'ProjectType' }
  & Pick<ProjectType, 'id' | 'pdfVertical' | 'pdfEmail' | 'pdfAddr' | 'pdfSite' | 'pdfLogo'>
);

export type ProposalPageFragmentFragment = (
  { __typename?: 'PDFPageType' }
  & Pick<PdfPageType, 'id' | 'isActive' | 'pageType' | 'pdfDetails' | 'position'>
  & { project?: Maybe<(
    { __typename?: 'ProjectType' }
    & Pick<ProjectType, 'id'>
  )>, locationItem?: Maybe<(
    { __typename?: 'LocationItemType' }
    & Pick<LocationItemType, 'id' | 'externalId'>
    & { location: (
      { __typename?: 'LocationType' }
      & Pick<LocationType, 'id' | 'externalId'>
    ) }
  )>, bucketItem?: Maybe<(
    { __typename?: 'BucketItemType' }
    & Pick<BucketItemType, 'id'>
  )>, floorplan?: Maybe<(
    { __typename?: 'FloorPlanType' }
    & Pick<FloorPlanType, 'id'>
  )>, virImage?: Maybe<(
    { __typename?: 'ProjectVIMRImageType' }
    & Pick<ProjectVimrImageType, 'id'>
  )> }
);

export type LocationFragmentFragment = (
  { __typename?: 'LocationType' }
  & Pick<LocationType, 'id' | 'title'>
  & { project: (
    { __typename?: 'ProjectType' }
    & Pick<ProjectType, 'id' | 'title'>
  ) }
);

export type LocationOverviewFragmentFragment = (
  { __typename?: 'LocationType' }
  & Pick<LocationType, 'id' | 'title' | 'externalId' | 'info'>
  & { locationImages?: Maybe<Array<(
    { __typename?: 'LocationImageType' }
    & Pick<LocationImageType, 'id' | 'externalId' | 'image' | 'imageFilename'>
  )>>, project: (
    { __typename?: 'ProjectType' }
    & Pick<ProjectType, 'id' | 'hashId'>
  ) }
);

export type ProductForLocationItemFragmentFragment = (
  { __typename?: 'ProductType' }
  & Pick<ProductType, 'id' | 'esId' | 'name' | 'image' | 'cheapestPriceString' | 'site'>
  & { artist?: Maybe<(
    { __typename?: 'ArtistType' }
    & Pick<ArtistType, 'name'>
  )>, sizes: Array<(
    { __typename?: 'SizeType' }
    & Pick<SizeType, 'width' | 'height' | 'thickness' | 'diameter' | 'price'>
    & { printBaseCost?: Maybe<(
      { __typename?: 'PrintBaseCostType' }
      & Pick<PrintBaseCostType, 'printSize' | 'printMaterial'>
    )> }
  )> }
);

export type ProductGridFragmentFragment = (
  { __typename?: 'ProductType' }
  & Pick<ProductType, 'id' | 'esId' | 'favorited' | 'name' | 'image' | 'artistUpdatedAt' | 'cheapestPriceString' | 'sizeString' | 'site'>
  & { artist?: Maybe<(
    { __typename?: 'ArtistType' }
    & Pick<ArtistType, 'name'>
  )>, gallery?: Maybe<(
    { __typename?: 'GalleryType' }
    & Pick<GalleryType, 'name'>
  )>, category?: Maybe<(
    { __typename?: 'SlugType' }
    & Pick<SlugType, 'slug'>
  )>, sizes: Array<(
    { __typename?: 'SizeType' }
    & Pick<SizeType, 'id' | 'width' | 'height' | 'thickness' | 'diameter' | 'price' | 'framingStatus' | 'stretchingStatus' | 'sizeString'>
    & { printBaseCost?: Maybe<(
      { __typename?: 'PrintBaseCostType' }
      & Pick<PrintBaseCostType, 'printSize' | 'printMaterial'>
    )> }
  )> }
);

export type ProductFragmentFragment = (
  { __typename?: 'ProductType' }
  & Pick<ProductType, 'id' | 'extraImages' | 'detailUrl' | 'hideVir' | 'showFrameOnVir' | 'artistUpdatedAt' | 'description' | 'artworksMedium' | 'exclusive' | 'remainingCount' | 'designLeadTime' | 'designSuitableForOutdoors' | 'printOrientation' | 'tags' | 'gvLabels'>
  & { artist?: Maybe<(
    { __typename?: 'ArtistType' }
    & Pick<ArtistType, 'id' | 'detailUrl' | 'canDoCommissionedWork' | 'canDoPublicMurals'>
    & { country?: Maybe<(
      { __typename?: 'SlugAndNameType' }
      & Pick<SlugAndNameType, 'name'>
    )> }
  )>, artworkStyle?: Maybe<(
    { __typename?: 'SlugType' }
    & Pick<SlugType, 'slug'>
  )>, designMaterial?: Maybe<(
    { __typename?: 'SlugType' }
    & Pick<SlugType, 'slug'>
  )>, medium?: Maybe<(
    { __typename?: 'SlugAndTitleType' }
    & Pick<SlugAndTitleType, 'slug' | 'title'>
  )>, country?: Maybe<(
    { __typename?: 'SlugAndNameType' }
    & Pick<SlugAndNameType, 'slug' | 'name'>
  )>, sizes: Array<(
    { __typename?: 'SizeType' }
    & Pick<SizeType, 'id' | 'widthIn' | 'heightIn' | 'thicknessIn' | 'diameterIn' | 'orientation' | 'printActive' | 'priceString' | 'sizeAndPriceString'>
  )> }
  & ProductGridFragmentFragment
);

export type ProductAndItemFragmentFragment = (
  { __typename?: 'ProductAndItemType' }
  & Pick<ProductAndItemType, 'id' | 'copyPasteText'>
  & { product: (
    { __typename?: 'ProductType' }
    & Pick<ProductType, 'id' | 'designLeadTime' | 'remainingCount'>
    & { medium?: Maybe<(
      { __typename?: 'SlugAndTitleType' }
      & Pick<SlugAndTitleType, 'title'>
    )> }
    & ProductGridFragmentFragment
  ), bucketItem: (
    { __typename?: 'BucketItemType' }
    & Pick<BucketItemType, 'id' | 'esId' | 'customText' | 'customSize' | 'selectedSize' | 'originalPrice' | 'price' | 'framingStatus' | 'stretchingStatus' | 'framingAndStretchingStatus' | 'position' | 'isShortlisted' | 'quotationQuantity' | 'quotationFramingRequired' | 'quotationFramingComments' | 'quotationAdditionalComments'>
    & { vimrImages: Array<(
      { __typename?: 'ProjectVIMRImageType' }
      & Pick<ProjectVimrImageType, 'id' | 'image'>
    )>, locationItem?: Maybe<(
      { __typename?: 'LocationItemType' }
      & Pick<LocationItemType, 'id' | 'title' | 'titleStr' | 'externalId'>
      & { location: (
        { __typename?: 'LocationType' }
        & Pick<LocationType, 'id' | 'title' | 'titleStr' | 'externalId'>
      ) }
    )> }
  ) }
);

export type UserWizardProjectLocationItemFragmentLocationsFragmentFragment = (
  { __typename?: 'LocationType' }
  & Pick<LocationType, 'id' | 'externalId' | 'title' | 'titleStr'>
  & { locationImages?: Maybe<Array<(
    { __typename?: 'LocationImageType' }
    & Pick<LocationImageType, 'id' | 'externalId' | 'image' | 'imageFilename'>
  )>>, floorplans?: Maybe<Array<(
    { __typename?: 'FloorPlanType' }
    & Pick<FloorPlanType, 'id' | 'image' | 'imageFilename'>
  )>>, locationItems: Array<(
    { __typename?: 'LocationItemType' }
    & Pick<LocationItemType, 'id'>
    & LocationItemDetailFragmentFragment
  )> }
);

export type UserWizardProjectLocationItemFragmentFragment = (
  { __typename?: 'ProjectType' }
  & Pick<ProjectType, 'hashId' | 'wizardStep'>
  & { locations: Array<(
    { __typename?: 'LocationType' }
    & UserWizardProjectLocationItemFragmentLocationsFragmentFragment
  )> }
);

export type BucketItemsFragmentFragment = (
  { __typename?: 'ProductType' }
  & Pick<ProductType, 'id' | 'bucketId' | 'croppedImage' | 'image' | 'name' | 'hideVir' | 'site' | 'selectedWidth' | 'selectedHeight' | 'selectedThickness'>
  & { category?: Maybe<(
    { __typename?: 'SlugType' }
    & Pick<SlugType, 'slug'>
  )>, medium?: Maybe<(
    { __typename?: 'SlugAndTitleType' }
    & Pick<SlugAndTitleType, 'slug'>
  )>, baseSize?: Maybe<(
    { __typename?: 'SizeType' }
    & Pick<SizeType, 'height' | 'width' | 'thickness' | 'sizeString'>
  )> }
);

export type LocationItemFragmentFragment = (
  { __typename?: 'LocationItemType' }
  & Pick<LocationItemType, 'titleStr' | 'wallWidth' | 'wallHeight' | 'wallDepth' | 'wallVimrBg'>
  & { bucketItems?: Maybe<Array<(
    { __typename?: 'BucketItemType' }
    & Pick<BucketItemType, 'id' | 'esId' | 'wallPt'>
  )>>, bucketItemsData?: Maybe<Array<Maybe<(
    { __typename?: 'ProductType' }
    & Pick<ProductType, 'id'>
    & BucketItemsFragmentFragment
  )>>>, location: (
    { __typename?: 'LocationType' }
    & Pick<LocationType, 'id' | 'titleStr'>
  ) }
);

export type LocationItemVimrFragmentFragment = (
  { __typename?: 'LocationItemType' }
  & Pick<LocationItemType, 'allImages' | 'wallPt1' | 'wallPt2' | 'wallPt3' | 'wallPt4' | 'wallImgRotation'>
);

export type LocationPositionFragmentFragment = (
  { __typename?: 'LocationType' }
  & Pick<LocationType, 'id' | 'externalId' | 'title' | 'titleStr' | 'isShortlisted'>
  & { locationItems: Array<(
    { __typename?: 'LocationItemType' }
    & Pick<LocationItemType, 'id' | 'externalId' | 'title' | 'titleStr' | 'locationItemTypeStr' | 'dimensionsStr' | 'position' | 'isShortlisted'>
    & { category?: Maybe<Array<Maybe<(
      { __typename?: 'CategoryType' }
      & Pick<CategoryType, 'id' | 'title' | 'slug'>
    )>>>, location: (
      { __typename?: 'LocationType' }
      & Pick<LocationType, 'id' | 'externalId'>
    ), bucketItems_final: Array<(
      { __typename?: 'ProductAndItemType' }
      & Pick<ProductAndItemType, 'id'>
      & ProductAndItemFragmentFragment
    )> }
  )> }
);

export type UserProfileFragmentFragment = (
  { __typename?: 'UserProfileType' }
  & Pick<UserProfileType, 'firstName' | 'lastName' | 'title' | 'phoneNo' | 'companyName' | 'userStreet' | 'userCity' | 'userCountryCode' | 'userZip' | 'userPhoneNo' | 'userCompanyName'>
  & { user: (
    { __typename?: 'UserType' }
    & Pick<UserType, 'email' | 'id'>
  ) }
);

export type ArtistVariantProjectFragmentFragment = (
  { __typename?: 'ArtistVariantProjectType' }
  & Pick<ArtistVariantProjectType, 'name' | 'date' | 'rate' | 'size' | 'status'>
);

export type BucketUtilsFragment = (
  { __typename?: 'SizeType' }
  & Pick<SizeType, 'id' | 'width' | 'height' | 'thickness' | 'diameter' | 'framingStatus' | 'stretchingStatus' | 'price'>
  & { printBaseCost?: Maybe<(
    { __typename?: 'PrintBaseCostType' }
    & Pick<PrintBaseCostType, 'printSize' | 'printMaterial'>
  )> }
);

export type DemoFormViewQueryVariables = Exact<{ [key: string]: never; }>;


export type DemoFormViewQuery = (
  { __typename?: 'Queries' }
  & { artist?: Maybe<(
    { __typename?: 'ArtistSourcingType' }
    & Pick<ArtistSourcingType, 'id' | 'name' | 'country' | 'tags'>
  )> }
);

export type FavoritesViewQueryVariables = Exact<{
  filters?: Maybe<Scalars['String']>;
}>;


export type FavoritesViewQuery = (
  { __typename?: 'Queries' }
  & { getProducts?: Maybe<(
    { __typename?: 'ProductPaginatedType' }
    & Pick<ProductPaginatedType, 'page' | 'pages' | 'hasNext' | 'hasPrev' | 'total'>
    & { relatedObject?: Maybe<(
      { __typename?: 'ProductType' }
      & Pick<ProductType, 'id'>
      & ProductGridFragmentFragment
    )>, objects?: Maybe<Array<Maybe<(
      { __typename?: 'ProductType' }
      & Pick<ProductType, 'id'>
      & ProductGridFragmentFragment
    )>>> }
  )> }
);

export type FramerWfNormalWorksQuotationViewQueryVariables = Exact<{
  uuid?: Maybe<Scalars['String']>;
  framerHashId?: Maybe<Scalars['String']>;
}>;


export type FramerWfNormalWorksQuotationViewQuery = (
  { __typename?: 'Queries' }
  & { wfNwFramerRfqData?: Maybe<(
    { __typename?: 'FramerGlobalQuotationType' }
    & Pick<FramerGlobalQuotationType, 'id'>
    & FramerGlobalQuotationFragmentFragment
  )> }
);

export type LoginViewQueryVariables = Exact<{ [key: string]: never; }>;


export type LoginViewQuery = (
  { __typename?: 'Queries' }
  & { staticImage?: Maybe<(
    { __typename?: 'StaticImageType' }
    & Pick<StaticImageType, 'id' | 'image' | 'altText'>
  )> }
);

export type MyAccountViewQueryVariables = Exact<{ [key: string]: never; }>;


export type MyAccountViewQuery = (
  { __typename?: 'Queries' }
  & { currentProfile?: Maybe<(
    { __typename?: 'UserProfileType' }
    & Pick<UserProfileType, 'id'>
    & UserProfileFragmentFragment
  )> }
);

export type PdfPreviewViewQueryVariables = Exact<{
  proposalId?: Maybe<Scalars['String']>;
}>;


export type PdfPreviewViewQuery = (
  { __typename?: 'Queries' }
  & { getProposal?: Maybe<(
    { __typename?: 'PDFProposalType' }
    & Pick<PdfProposalType, 'id'>
    & PdfPreviewViewProposalFragmentFragment
  )> }
);

export type PdfPreviewViewProposalFragmentFragment = (
  { __typename?: 'PDFProposalType' }
  & Pick<PdfProposalType, 'id' | 'pdfHideTitle' | 'pdfHideArtist' | 'pdfHidePrice' | 'pdfHideShipping' | 'pdfHideAreaDetails' | 'pdfHideLogo' | 'pdfVertical' | 'pdfLogo' | 'pdfAddr' | 'pdfSite' | 'pdfEmail' | 'title'>
  & { project: (
    { __typename?: 'ProjectType' }
    & Pick<ProjectType, 'id' | 'hashId' | 'title'>
  ), pages: Array<(
    { __typename?: 'PDFPageType' }
    & Pick<PdfPageType, 'id'>
    & ProposalPageFragmentFragment
  )> }
);

export type PdfViewWrapperQueryVariables = Exact<{
  proposalId?: Maybe<Scalars['String']>;
}>;


export type PdfViewWrapperQuery = (
  { __typename?: 'Queries' }
  & { getProposal?: Maybe<(
    { __typename?: 'PDFProposalType' }
    & Pick<PdfProposalType, 'id' | 'pdfHideTitle' | 'pdfHideArtist' | 'pdfHidePrice' | 'pdfHideShipping' | 'pdfHideAreaDetails' | 'pdfHideLogo' | 'pdfVertical' | 'pdfLogo' | 'pdfAddr' | 'pdfSite' | 'pdfEmail' | 'title'>
    & { project: (
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id' | 'hashId'>
    ), pages: Array<(
      { __typename?: 'PDFPageType' }
      & Pick<PdfPageType, 'id'>
      & ProposalPageFragmentFragment
    )> }
  )> }
);

export type PdfViewWrapperToggleMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type PdfViewWrapperToggleMutation = (
  { __typename?: 'Mutations' }
  & { toggleProposalVertical?: Maybe<(
    { __typename?: 'ToggleProposalVerticalMutation' }
    & Pick<ToggleProposalVerticalMutation, 'status'>
    & { proposal?: Maybe<(
      { __typename?: 'PDFProposalType' }
      & Pick<PdfProposalType, 'id' | 'pdfVertical'>
    )> }
  )> }
);

export type PdfViewWrapperImageSizeMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type PdfViewWrapperImageSizeMutation = (
  { __typename?: 'Mutations' }
  & { updatePdfPageImageSize?: Maybe<(
    { __typename?: 'UpdatePDFPageImageSizeMutation' }
    & Pick<UpdatePdfPageImageSizeMutation, 'status' | 'formErrors'>
    & { page?: Maybe<(
      { __typename?: 'PDFPageType' }
      & Pick<PdfPageType, 'id' | 'pdfDetails'>
    )> }
  )> }
);

export type PdfViewWrapperImageMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type PdfViewWrapperImageMutation = (
  { __typename?: 'Mutations' }
  & { updatePdfPageImage?: Maybe<(
    { __typename?: 'UpdatePDFPageImageMutation' }
    & Pick<UpdatePdfPageImageMutation, 'status' | 'formErrors'>
    & { page?: Maybe<(
      { __typename?: 'PDFPageType' }
      & Pick<PdfPageType, 'id' | 'pdfDetails'>
    )> }
  )> }
);

export type ProjectDetailsAreaOrderingViewQueryVariables = Exact<{
  hashId?: Maybe<Scalars['String']>;
}>;


export type ProjectDetailsAreaOrderingViewQuery = (
  { __typename?: 'Queries' }
  & { project?: Maybe<(
    { __typename?: 'ProjectType' }
    & Pick<ProjectType, 'id'>
    & { locations: Array<(
      { __typename?: 'LocationType' }
      & ProjectLocationsFieldsFragment
    )> }
  )> }
);

export type ProjectLocationsFieldsFragment = (
  { __typename?: 'LocationType' }
  & Pick<LocationType, 'id' | 'title' | 'titleStr' | 'externalId' | 'isShortlisted'>
  & { locationItems: Array<(
    { __typename?: 'LocationItemType' }
    & Pick<LocationItemType, 'id' | 'titleStr' | 'isShortlisted'>
  )> }
);

export type ProjectDetailsAreaOrderingViewMutationMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ProjectDetailsAreaOrderingViewMutationMutation = (
  { __typename?: 'Mutations' }
  & { updateLocationPosition?: Maybe<(
    { __typename?: 'UpdateLocationPositionMutation' }
    & Pick<UpdateLocationPositionMutation, 'status'>
  )> }
);

export type ProjectDetailsOrderingViewQueryVariables = Exact<{
  hashId?: Maybe<Scalars['String']>;
}>;


export type ProjectDetailsOrderingViewQuery = (
  { __typename?: 'Queries' }
  & { project?: Maybe<(
    { __typename?: 'ProjectType' }
    & Pick<ProjectType, 'id' | 'title'>
    & { locations: Array<(
      { __typename?: 'LocationType' }
      & Pick<LocationType, 'id'>
      & LocationPositionFragmentFragment
    )> }
  )> }
);

export type ProjectDetailsOrderingViewMutationMutationVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type ProjectDetailsOrderingViewMutationMutation = (
  { __typename?: 'Mutations' }
  & { updateAreaBucketPositions?: Maybe<(
    { __typename?: 'UpdateAreaBucketPositionsMutation' }
    & Pick<UpdateAreaBucketPositionsMutation, 'status'>
  )> }
);

export type ProjectDetailsViewQueryVariables = Exact<{
  hashId?: Maybe<Scalars['String']>;
}>;


export type ProjectDetailsViewQuery = (
  { __typename?: 'Queries' }
  & { project?: Maybe<(
    { __typename?: 'ProjectType' }
    & Pick<ProjectType, 'id' | 'bucketItemsCount' | 'budget' | 'budgetCurrency' | 'budgetUsd' | 'title' | 'hashId' | 'artworkProposalRequestedAt' | 'artworkProposalSentAt' | 'statusComputed' | 'status'>
    & { vimrImages: Array<(
      { __typename?: 'ProjectVIMRImageType' }
      & Pick<ProjectVimrImageType, 'id'>
    )>, locations: Array<(
      { __typename?: 'LocationType' }
      & Pick<LocationType, 'id'>
      & LocationPositionFragmentFragment
    )> }
  )>, getProjectProposals: Array<(
    { __typename?: 'PDFProposalType' }
    & Pick<PdfProposalType, 'id'>
  )>, getExistingWorksForProject: Array<(
    { __typename?: 'BucketItemType' }
    & Pick<BucketItemType, 'id' | 'isShortlisted' | 'price'>
    & { locationItem?: Maybe<(
      { __typename?: 'LocationItemType' }
      & Pick<LocationItemType, 'id'>
      & { location: (
        { __typename?: 'LocationType' }
        & Pick<LocationType, 'id' | 'title'>
      ) }
    )> }
  )>, getCommissionedWorksForProject?: Maybe<Array<(
    { __typename?: 'BucketItemType' }
    & Pick<BucketItemType, 'id' | 'isShortlisted' | 'price'>
    & { locationItem?: Maybe<(
      { __typename?: 'LocationItemType' }
      & Pick<LocationItemType, 'id'>
      & { location: (
        { __typename?: 'LocationType' }
        & Pick<LocationType, 'id' | 'title'>
      ) }
    )> }
  )>> }
);

export type ProjectDetailsViewMutationMutationVariables = Exact<{ [key: string]: never; }>;


export type ProjectDetailsViewMutationMutation = (
  { __typename?: 'Mutations' }
  & { setJoyrideShortlistedView?: Maybe<(
    { __typename?: 'SetJoyrideShortlistedViewMutation' }
    & Pick<SetJoyrideShortlistedViewMutation, 'status'>
    & { currentProfile?: Maybe<(
      { __typename?: 'UserProfileType' }
      & Pick<UserProfileType, 'id'>
      & CurrentProfileFragmentFragment
    )> }
  )> }
);

export type ProjectInviteViewQueryVariables = Exact<{
  hashId?: Maybe<Scalars['String']>;
}>;


export type ProjectInviteViewQuery = (
  { __typename?: 'Queries' }
  & { project?: Maybe<(
    { __typename?: 'ProjectType' }
    & Pick<ProjectType, 'id'>
    & { user?: Maybe<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'id' | 'email'>
      & { userprofile?: Maybe<(
        { __typename?: 'UserProfileType' }
        & Pick<UserProfileType, 'id' | 'firstName' | 'lastName'>
      )> }
    )> }
    & ProjectInviteFragmentFragment
  )> }
);

export type ProjectItemsViewQueryVariables = Exact<{
  hashId?: Maybe<Scalars['String']>;
}>;


export type ProjectItemsViewQuery = (
  { __typename?: 'Queries' }
  & { project?: Maybe<(
    { __typename?: 'ProjectType' }
    & Pick<ProjectType, 'id' | 'title' | 'hashId' | 'statusComputed'>
    & { client?: Maybe<(
      { __typename?: 'ClientType' }
      & Pick<ClientType, 'id' | 'name' | 'user'>
    )>, locations: Array<(
      { __typename?: 'LocationType' }
      & Pick<LocationType, 'id' | 'externalId' | 'title' | 'titleStr'>
      & { locationImages?: Maybe<Array<(
        { __typename?: 'LocationImageType' }
        & Pick<LocationImageType, 'id' | 'image'>
      )>>, locationItems: Array<(
        { __typename?: 'LocationItemType' }
        & Pick<LocationItemType, 'id'>
        & LocationItemDetailFragmentFragment
      )> }
    )> }
  )> }
);

export type ProjectReviewQuotationViewQueryVariables = Exact<{
  hashId?: Maybe<Scalars['String']>;
}>;


export type ProjectReviewQuotationViewQuery = (
  { __typename?: 'Queries' }
  & { project?: Maybe<(
    { __typename?: 'ProjectType' }
    & Pick<ProjectType, 'id'>
    & { latestQuotation?: Maybe<(
      { __typename?: 'QuotationType' }
      & Pick<QuotationType, 'id' | 'isSubmitted' | 'isCompleted' | 'isAccepted' | 'quotationPdf'>
    )> }
  )> }
);

export type ProjectsListViewProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectsListViewProjectsQuery = (
  { __typename?: 'Queries' }
  & { projects: (
    { __typename?: 'ProjectAggrType' }
    & { projects?: Maybe<Array<(
      { __typename?: 'ProjectType' }
      & ProjectsFieldsFragment
    )>> }
  ) }
);

export type ProjectsFieldsFragment = (
  { __typename?: 'ProjectType' }
  & Pick<ProjectType, 'id' | 'hashId' | 'title' | 'locationsCount' | 'itemsCount' | 'selectionsCount' | 'isQuickSourced' | 'status'>
  & { user?: Maybe<(
    { __typename?: 'UserType' }
    & Pick<UserType, 'id' | 'email'>
  )>, client?: Maybe<(
    { __typename?: 'ClientType' }
    & Pick<ClientType, 'id' | 'name' | 'user'>
  )> }
);

export type ProjectsListViewProductsQueryVariables = Exact<{
  filters?: Maybe<Scalars['String']>;
}>;


export type ProjectsListViewProductsQuery = (
  { __typename?: 'Queries' }
  & { getProducts?: Maybe<(
    { __typename?: 'ProductPaginatedType' }
    & Pick<ProductPaginatedType, 'page' | 'pages' | 'hasNext' | 'hasPrev' | 'total' | 'showImage'>
    & { relatedObject?: Maybe<(
      { __typename?: 'ProductType' }
      & Pick<ProductType, 'id'>
      & ProductGridItemFragment
    )>, objects?: Maybe<Array<Maybe<(
      { __typename?: 'ProductType' }
      & Pick<ProductType, 'id'>
      & ProductGridItemFragment
    )>>> }
  )> }
);

export type RequestQuotationViewQueryVariables = Exact<{
  hashId?: Maybe<Scalars['String']>;
}>;


export type RequestQuotationViewQuery = (
  { __typename?: 'Queries' }
  & { quotation?: Maybe<(
    { __typename?: 'QuotationType' }
    & Pick<QuotationType, 'id'>
    & RequestQuotationViewQuotationFragmentFragment
  )>, project?: Maybe<(
    { __typename?: 'ProjectType' }
    & Pick<ProjectType, 'id'>
    & ProjectSummaryFragmentFragment
  )>, selectedBucketItems?: Maybe<Array<(
    { __typename?: 'ProductAndItemType' }
    & Pick<ProductAndItemType, 'id'>
    & ProductAndItemFragmentFragment
  )>> }
);

export type RequestQuotationViewQuotationFragmentFragment = (
  { __typename?: 'QuotationType' }
  & Pick<QuotationType, 'id' | 'submittedAtStr'>
  & { quotationitemSet?: Maybe<Array<Maybe<(
    { __typename?: 'QuotationItemType' }
    & Pick<QuotationItemType, 'id'>
  )>>> }
);

export type SearchViewLocationItemsQueryVariables = Exact<{
  hashId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  locationItemId?: Maybe<Scalars['String']>;
}>;


export type SearchViewLocationItemsQuery = (
  { __typename?: 'Queries' }
  & { locationItem?: Maybe<(
    { __typename?: 'LocationItemType' }
    & SearchViewLocationItemFragmentFragment
  )>, getProductsForProject?: Maybe<Array<(
    { __typename?: 'ProductAndItemType' }
    & Pick<ProductAndItemType, 'id'>
    & { bucketItem: (
      { __typename?: 'BucketItemType' }
      & Pick<BucketItemType, 'id' | 'esId'>
    ) }
  )>> }
);

export type SearchViewLocationItemFragmentFragment = (
  { __typename?: 'LocationItemType' }
  & Pick<LocationItemType, 'id'>
  & { productsAndBucketItems: Array<(
    { __typename?: 'ProductAndItemType' }
    & Pick<ProductAndItemType, 'id'>
    & ProductAndItemFragmentFragment
  )> }
  & LocationItemDetailFragmentFragment
);

export type SearchViewProductsQueryVariables = Exact<{
  filters?: Maybe<Scalars['String']>;
  locDetails?: Maybe<Scalars['String']>;
}>;


export type SearchViewProductsQuery = (
  { __typename?: 'Queries' }
  & { getProducts?: Maybe<(
    { __typename?: 'ProductPaginatedType' }
    & Pick<ProductPaginatedType, 'page' | 'pages' | 'hasNext' | 'hasPrev' | 'total' | 'showImage'>
    & { relatedObject?: Maybe<(
      { __typename?: 'ProductType' }
      & Pick<ProductType, 'id'>
      & ProductGridItemFragment
    )>, objects?: Maybe<Array<Maybe<(
      { __typename?: 'ProductType' }
      & Pick<ProductType, 'id'>
      & ProductGridItemFragment
    )>>> }
  )> }
);

export type SellerWfCommissionedWorksConfirmAvailabilityViewQueryVariables = Exact<{
  uuid?: Maybe<Scalars['String']>;
}>;


export type SellerWfCommissionedWorksConfirmAvailabilityViewQuery = (
  { __typename?: 'Queries' }
  & { wfCommissionedWorksSellerConfirmAvailabilityData: Array<(
    { __typename?: 'FlowItemCommissionedWorkConfirmAvailabilityType' }
    & WfCommissionedWorksSellerConfirmAvailabilityDataFieldsFragment
  )> }
);

export type WfCommissionedWorksSellerConfirmAvailabilityDataFieldsFragment = (
  { __typename?: 'FlowItemCommissionedWorkConfirmAvailabilityType' }
  & Pick<FlowItemCommissionedWorkConfirmAvailabilityType, 'id' | 'title' | 'commission' | 'artistName' | 'artworkDescription' | 'estimatedTimeline' | 'additionalQuestions' | 'budget' | 'budgetCcy' | 'wallWidth' | 'wallHeight' | 'wallSizeStr' | 'itemWidth' | 'itemHeight' | 'itemSizeStr' | 'dateFinished' | 'isAvailable' | 'cost' | 'leadTime' | 'hasSketchFee' | 'sketchFee' | 'portfolio1' | 'portfolio2' | 'portfolio3' | 'remarks'>
  & { files: Array<(
    { __typename?: 'FlowItemCommissionedWorkImageType' }
    & Pick<FlowItemCommissionedWorkImageType, 'id' | 'imageKey' | 'imageKeyFilename'>
  )> }
);

export type SellerWfNormalWorksConfirmAvailabilityViewQueryVariables = Exact<{
  uuid?: Maybe<Scalars['String']>;
}>;


export type SellerWfNormalWorksConfirmAvailabilityViewQuery = (
  { __typename?: 'Queries' }
  & { wfSellerConfirmAvailabilityData?: Maybe<(
    { __typename?: 'FlowItemArtworkConfirmAvailabilityType' }
    & Pick<FlowItemArtworkConfirmAvailabilityType, 'id' | 'image' | 'artist' | 'title' | 'sizeString' | 'remainingQuantity' | 'requestedQuantity' | 'dateFinished' | 'destinationCity' | 'destinationCountry' | 'destinationCountryName' | 'destinationZip' | 'isAvailable' | 'isNotReady' | 'leadTime' | 'shippingCost' | 'shipsFromCountry' | 'shipsFromCity' | 'shipsFromZip' | 'packedWidth' | 'packedHeight' | 'packedDepth' | 'packedWeight' | 'price' | 'shippedFramed' | 'shippedRolled' | 'remarks'>
  )> }
);

export type SourcingArtistVariantDetailViewQueryVariables = Exact<{
  variantId?: Maybe<Scalars['String']>;
}>;


export type SourcingArtistVariantDetailViewQuery = (
  { __typename?: 'Queries' }
  & { artistVariant?: Maybe<(
    { __typename?: 'ArtistVariantType' }
    & Pick<ArtistVariantType, 'id' | 'tags' | 'priceLow' | 'priceHigh' | 'priceRange' | 'dataRequestDate' | 'notes'>
    & { artist?: Maybe<(
      { __typename?: 'ArtistSourcingType' }
      & Pick<ArtistSourcingType, 'id' | 'name'>
    )>, galleries?: Maybe<Array<Maybe<(
      { __typename?: 'GallerySourcingType' }
      & Pick<GallerySourcingType, 'id' | 'name'>
    )>>>, medium?: Maybe<(
      { __typename?: 'MediumType' }
      & Pick<MediumType, 'id' | 'title'>
    )>, artworkStyle?: Maybe<(
      { __typename?: 'StyleType' }
      & Pick<StyleType, 'id' | 'title'>
    )>, productImages: Array<(
      { __typename?: 'ArtistProductImage' }
      & Pick<ArtistProductImage, 'id' | 'esId' | 'imageKey' | 'imageKeyFilename'>
    )>, projects: Array<(
      { __typename?: 'ArtistVariantProjectType' }
      & Pick<ArtistVariantProjectType, 'id'>
      & ArtistVariantProjectFragmentFragment
    )>, projectKeys?: Maybe<Array<Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id'>
    )>>> }
  )> }
);

export type SourcingArtistVariantsViewQueryVariables = Exact<{
  filters?: Maybe<Scalars['String']>;
}>;


export type SourcingArtistVariantsViewQuery = (
  { __typename?: 'Queries' }
  & { artistVariants: (
    { __typename?: 'ArtistVariantPaginatedType' }
    & Pick<ArtistVariantPaginatedType, 'page' | 'pages' | 'hasNext' | 'hasPrev' | 'total'>
    & { objects: Array<(
      { __typename?: 'ArtistVariantType' }
      & ArtistVariantsObjectsFieldsFragment
    )> }
  ) }
);

export type ArtistVariantsObjectsFieldsFragment = (
  { __typename?: 'ArtistVariantType' }
  & Pick<ArtistVariantType, 'id' | 'priceLow' | 'priceHigh' | 'dataRequestDate' | 'tags' | 'allProjects'>
  & { artist?: Maybe<(
    { __typename?: 'ArtistSourcingType' }
    & Pick<ArtistSourcingType, 'id' | 'name' | 'countryName'>
  )>, galleries?: Maybe<Array<Maybe<(
    { __typename?: 'GallerySourcingType' }
    & Pick<GallerySourcingType, 'id' | 'name' | 'countryNames'>
  )>>>, medium?: Maybe<(
    { __typename?: 'MediumType' }
    & Pick<MediumType, 'id' | 'title'>
  )>, productImages: Array<(
    { __typename?: 'ArtistProductImage' }
    & Pick<ArtistProductImage, 'id' | 'esId' | 'imageKey'>
  )> }
);

export type SourcingArtistsViewQueryVariables = Exact<{
  filters?: Maybe<Scalars['String']>;
}>;


export type SourcingArtistsViewQuery = (
  { __typename?: 'Queries' }
  & { artists: (
    { __typename?: 'ArtistSourcingPaginatedType' }
    & Pick<ArtistSourcingPaginatedType, 'page' | 'pages' | 'hasNext' | 'hasPrev' | 'total'>
    & { objects: Array<(
      { __typename?: 'ArtistSourcingType' }
      & ArtistsObjectsFieldsFragment
    )> }
  ) }
);

export type ArtistsObjectsFieldsFragment = (
  { __typename?: 'ArtistSourcingType' }
  & Pick<ArtistSourcingType, 'id' | 'name' | 'website' | 'contactEmail' | 'yearOfBirth' | 'countryName' | 'tags'>
);

export type SourcingGalleriesViewQueryVariables = Exact<{
  filters?: Maybe<Scalars['String']>;
}>;


export type SourcingGalleriesViewQuery = (
  { __typename?: 'Queries' }
  & { galleries: (
    { __typename?: 'GallerySourcingPaginatedType' }
    & Pick<GallerySourcingPaginatedType, 'page' | 'pages' | 'hasNext' | 'hasPrev' | 'total'>
    & { objects: Array<(
      { __typename?: 'GallerySourcingType' }
      & GalleriesObjectsFieldsFragment
    )> }
  ) }
);

export type GalleriesObjectsFieldsFragment = (
  { __typename?: 'GallerySourcingType' }
  & Pick<GallerySourcingType, 'id' | 'contactEmail' | 'contactNumber' | 'countryNames' | 'name' | 'tags' | 'website'>
);

export type StaffFramingToolViewQueryVariables = Exact<{
  bucketItemId?: Maybe<Scalars['String']>;
}>;


export type StaffFramingToolViewQuery = (
  { __typename?: 'Queries' }
  & { getBucketItem?: Maybe<(
    { __typename?: 'BucketItemType' }
    & Pick<BucketItemType, 'id' | 'image'>
    & { savedFrame?: Maybe<(
      { __typename?: 'SavedFrameType' }
      & Pick<SavedFrameType, 'id' | 'artworkWidth' | 'artworkHeight' | 'artworkDepth' | 'matWidth' | 'matColor' | 'matColorCode' | 'frameWidth' | 'artworkType' | 'framingType' | 'glazingType'>
      & { frameMaterial?: Maybe<(
        { __typename?: 'FrameMaterialType' }
        & Pick<FrameMaterialType, 'id' | 'name'>
      )> }
    )> }
    & BucketItemDetailsFragmentFragment
  )> }
);

export type StaffImageRenderViewQueryVariables = Exact<{
  hashId?: Maybe<Scalars['String']>;
  locId?: Maybe<Scalars['String']>;
  locItemId?: Maybe<Scalars['String']>;
}>;


export type StaffImageRenderViewQuery = (
  { __typename?: 'Queries' }
  & { locationItem?: Maybe<(
    { __typename?: 'LocationItemType' }
    & Pick<LocationItemType, 'id' | 'allImages'>
    & LocationItemFragmentFragment
  )> }
);

export type StaffInquiriesViewQueryVariables = Exact<{ [key: string]: never; }>;


export type StaffInquiriesViewQuery = (
  { __typename?: 'Queries' }
  & { getInquiries?: Maybe<Array<(
    { __typename?: 'InquiryType' }
    & GetInquiriesFieldsFragment
  )>> }
);

export type GetInquiriesFieldsFragment = (
  { __typename?: 'InquiryType' }
  & Pick<InquiryType, 'id' | 'userEmail' | 'productName' | 'createdAt' | 'esId' | 'inquiry' | 'budget' | 'budgetCurrency'>
  & { changeType?: Maybe<Array<Maybe<(
    { __typename?: 'InquiryTypeType' }
    & Pick<InquiryTypeType, 'id' | 'title'>
  )>>> }
);

export type StaffQuotationsViewQueryVariables = Exact<{ [key: string]: never; }>;


export type StaffQuotationsViewQuery = (
  { __typename?: 'Queries' }
  & { staffQuotations?: Maybe<Array<(
    { __typename?: 'QuotationType' }
    & Pick<QuotationType, 'id'>
    & StaffQuotationsViewFragmentFragment
  )>> }
);

export type StaffQuotationsViewFragmentFragment = (
  { __typename?: 'QuotationType' }
  & Pick<QuotationType, 'id' | 'submittedAt'>
  & { project?: Maybe<(
    { __typename?: 'ProjectType' }
    & Pick<ProjectType, 'id' | 'hashId' | 'title'>
    & { client?: Maybe<(
      { __typename?: 'ClientType' }
      & Pick<ClientType, 'id' | 'name'>
    )> }
  )> }
);

export type StaffWfCommissionedWorksFlowItemViewQueryVariables = Exact<{
  flowItemId?: Maybe<Scalars['String']>;
}>;


export type StaffWfCommissionedWorksFlowItemViewQuery = (
  { __typename?: 'Queries' }
  & { wfStaffCommissionedWorksFlowItem?: Maybe<(
    { __typename?: 'FlowItemCommissionedWorkType' }
    & Pick<FlowItemCommissionedWorkType, 'id' | 'title' | 'artworkDescription' | 'estimatedTimeline' | 'budget' | 'isAvailable' | 'cost' | 'leadTime' | 'hasSketchFee' | 'sketchFee' | 'portfolio1' | 'portfolio2' | 'portfolio3' | 'remarks'>
    & { artist?: Maybe<(
      { __typename?: 'ArtistSourcingType' }
      & Pick<ArtistSourcingType, 'id' | 'name'>
    )>, history: Array<(
      { __typename?: 'CurerntStateType' }
      & HistoryFieldsFragment
    )> }
  )> }
);

export type HistoryFieldsFragment = (
  { __typename?: 'CurerntStateType' }
  & Pick<CurerntStateType, 'id' | 'state' | 'dateCreated' | 'dateFinished' | 'data' | 'uniqueUrl'>
  & { changeUser?: Maybe<(
    { __typename?: 'UserType' }
    & Pick<UserType, 'id' | 'email'>
  )> }
);

export type StaffWfCommissionedWorksNotifySellersViewQueryVariables = Exact<{
  hashId?: Maybe<Scalars['String']>;
}>;


export type StaffWfCommissionedWorksNotifySellersViewQuery = (
  { __typename?: 'Queries' }
  & { wfCmSelectedWorks: Array<(
    { __typename?: 'CommissionedWorksLocation' }
    & StaffWfCommissionedWorksNotifySellersViewCommissionedWorksLocationFragmentFragment
  )> }
);

export type StaffWfCommissionedWorksNotifySellersViewCommissionedWorksLocationFragmentFragment = (
  { __typename?: 'CommissionedWorksLocation' }
  & Pick<CommissionedWorksLocation, 'locationTitle'>
  & { id: CommissionedWorksLocation['locationId'] }
  & { locationItems: Array<(
    { __typename?: 'CommissionedWorksLocationItem' }
    & Pick<CommissionedWorksLocationItem, 'locationItemTitle'>
    & { id: CommissionedWorksLocationItem['locationItemId'] }
    & { steps: Array<(
      { __typename?: 'CommissionedWorksStepItems' }
      & Pick<CommissionedWorksStepItems, 'step'>
      & { items_final: Array<(
        { __typename?: 'FlowItemCommissionedWorkType' }
        & Pick<FlowItemCommissionedWorkType, 'id' | 'esId' | 'image' | 'title'>
        & { artist?: Maybe<(
          { __typename?: 'ArtistSourcingType' }
          & Pick<ArtistSourcingType, 'id' | 'name' | 'contactEmail'>
        )>, locationItem?: Maybe<(
          { __typename?: 'LocationItemType' }
          & Pick<LocationItemType, 'id' | 'title' | 'titleStr'>
          & { location: (
            { __typename?: 'LocationType' }
            & Pick<LocationType, 'id' | 'title'>
            & { project: (
              { __typename?: 'ProjectType' }
              & Pick<ProjectType, 'id' | 'hashId'>
            ) }
          ) }
        )>, currentStates?: Maybe<Array<Maybe<(
          { __typename?: 'CurerntStateType' }
          & Pick<CurerntStateType, 'id' | 'state' | 'dateCreated' | 'uniqueUrl'>
        )>>> }
      )> }
    )> }
  )> }
);

export type StaffWfCommissionedWorksViewQueryVariables = Exact<{ [key: string]: never; }>;


export type StaffWfCommissionedWorksViewQuery = (
  { __typename?: 'Queries' }
  & { wfCommissionedWorksStepsData?: Maybe<(
    { __typename?: 'CommissionedWorksProjectSteps' }
    & Pick<CommissionedWorksProjectSteps, 'itemGroup' | 'projectTitle' | 'projectId'>
    & { steps: Array<(
      { __typename?: 'CommissionedWorksStepItems' }
      & Pick<CommissionedWorksStepItems, 'step'>
      & { items: Array<(
        { __typename?: 'FlowItemCommissionedWorkType' }
        & Pick<FlowItemCommissionedWorkType, 'id' | 'esId' | 'portfolio1' | 'artistConfirmAvailabilityUrl'>
        & { artist?: Maybe<(
          { __typename?: 'ArtistSourcingType' }
          & Pick<ArtistSourcingType, 'id' | 'name'>
        )>, currentStates?: Maybe<Array<Maybe<(
          { __typename?: 'CurerntStateType' }
          & Pick<CurerntStateType, 'id' | 'uniqueUrl'>
        )>>>, locationItem?: Maybe<(
          { __typename?: 'LocationItemType' }
          & Pick<LocationItemType, 'id'>
        )> }
      )> }
    )> }
  )> }
);

export type StaffWfNomalWorksFlowItemViewQueryVariables = Exact<{
  flowItemId?: Maybe<Scalars['String']>;
}>;


export type StaffWfNomalWorksFlowItemViewQuery = (
  { __typename?: 'Queries' }
  & { wfStaffFlowItem?: Maybe<(
    { __typename?: 'FlowItemArtworkType' }
    & Pick<FlowItemArtworkType, 'id'>
    & FlowItemArtworkStaffFragmentFragment
  )> }
);

export type StaffWfNormalWorksFramerRfqViewQueryVariables = Exact<{
  uuid?: Maybe<Scalars['String']>;
}>;


export type StaffWfNormalWorksFramerRfqViewQuery = (
  { __typename?: 'Queries' }
  & { wfStaffFramerQuotations: Array<Maybe<(
    { __typename?: 'FramerGlobalQuotationType' }
    & Pick<FramerGlobalQuotationType, 'id' | 'uniqueUrl'>
    & { framer?: Maybe<(
      { __typename?: 'FramerType' }
      & Pick<FramerType, 'id' | 'name' | 'hashId'>
    )> }
  )>> }
);

export type StaffWfNormalWorksNotifyFramersViewQueryVariables = Exact<{
  hashId?: Maybe<Scalars['String']>;
}>;


export type StaffWfNormalWorksNotifyFramersViewQuery = (
  { __typename?: 'Queries' }
  & { project?: Maybe<(
    { __typename?: 'ProjectType' }
    & Pick<ProjectType, 'id' | 'hashId' | 'title'>
  )>, wfSelectedWorks: Array<(
    { __typename?: 'NormalWorksLocation' }
    & WorkflowSelectedWorksFragmentFragment
  )> }
);

export type StaffWfNormalWorksNotifySellersViewQueryVariables = Exact<{
  hashId?: Maybe<Scalars['String']>;
}>;


export type StaffWfNormalWorksNotifySellersViewQuery = (
  { __typename?: 'Queries' }
  & { project?: Maybe<(
    { __typename?: 'ProjectType' }
    & Pick<ProjectType, 'id' | 'hashId' | 'title' | 'city' | 'countryCode' | 'postalCode'>
  )>, wfSelectedWorks: Array<(
    { __typename?: 'NormalWorksLocation' }
    & WorkflowSelectedWorksFragmentFragment
  )> }
);

export type StaffWfNormalWorksViewQueryVariables = Exact<{ [key: string]: never; }>;


export type StaffWfNormalWorksViewQuery = (
  { __typename?: 'Queries' }
  & { wfNormalWorksStepsData?: Maybe<(
    { __typename?: 'NormalWorksProjectSteps' }
    & Pick<NormalWorksProjectSteps, 'itemGroup' | 'projectTitle' | 'projectId'>
    & { steps: Array<(
      { __typename?: 'NormalWorksStepItems' }
      & WfNormalWorksStepsDataStepsFieldsFragment
    )> }
  )> }
);

export type WfNormalWorksStepsDataStepsFieldsFragment = (
  { __typename?: 'NormalWorksStepItems' }
  & Pick<NormalWorksStepItems, 'step'>
  & { items: Array<(
    { __typename?: 'FlowItemArtworkType' }
    & Pick<FlowItemArtworkType, 'id' | 'esId' | 'image'>
    & { currentStates?: Maybe<Array<Maybe<(
      { __typename?: 'CurerntStateType' }
      & Pick<CurerntStateType, 'id' | 'uniqueUrl'>
    )>>>, bucketItem?: Maybe<(
      { __typename?: 'BucketItemType' }
      & Pick<BucketItemType, 'id'>
      & { locationItem?: Maybe<(
        { __typename?: 'LocationItemType' }
        & Pick<LocationItemType, 'id'>
      )> }
    )> }
  )> }
);

export type StaffWfOverviewViewQueryVariables = Exact<{
  data?: Maybe<Scalars['String']>;
}>;


export type StaffWfOverviewViewQuery = (
  { __typename?: 'Queries' }
  & { projectsWithFlowItems: (
    { __typename?: 'ProjectPaginatedType' }
    & Pick<ProjectPaginatedType, 'page' | 'pages' | 'hasNext' | 'hasPrev' | 'total'>
    & { objects: Array<(
      { __typename?: 'ProjectType' }
      & ProjectsWithFlowItemsObjectsFieldsFragment
    )> }
  ) }
);

export type ProjectsWithFlowItemsObjectsFieldsFragment = (
  { __typename?: 'ProjectType' }
  & Pick<ProjectType, 'id' | 'hashId' | 'title'>
);

export type UserWizardCreateProjectViewMutationVariables = Exact<{ [key: string]: never; }>;


export type UserWizardCreateProjectViewMutation = (
  { __typename?: 'Mutations' }
  & { createNewProject?: Maybe<(
    { __typename?: 'CreateNewProjectMutation' }
    & Pick<CreateNewProjectMutation, 'status'>
    & { project?: Maybe<(
      { __typename?: 'ProjectType' }
      & Pick<ProjectType, 'id' | 'hashId'>
    )> }
  )> }
);

export type UserWizardProjectOverviewLocationViewQueryVariables = Exact<{
  hashId?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
}>;


export type UserWizardProjectOverviewLocationViewQuery = (
  { __typename?: 'Queries' }
  & { project?: Maybe<(
    { __typename?: 'ProjectType' }
    & Pick<ProjectType, 'id' | 'hashId' | 'wizardStep'>
    & { locations: Array<(
      { __typename?: 'LocationType' }
      & Pick<LocationType, 'id' | 'title' | 'externalId'>
      & { locationItems: Array<(
        { __typename?: 'LocationItemType' }
        & Pick<LocationItemType, 'id' | 'externalId'>
      )> }
    )> }
  )>, getLocation?: Maybe<(
    { __typename?: 'LocationType' }
    & Pick<LocationType, 'id'>
    & LocationOverviewFragmentFragment
  )> }
);

export type UserWizardProjectOverviewViewQueryVariables = Exact<{
  hashId?: Maybe<Scalars['String']>;
}>;


export type UserWizardProjectOverviewViewQuery = (
  { __typename?: 'Queries' }
  & { project?: Maybe<(
    { __typename?: 'ProjectType' }
    & Pick<ProjectType, 'id'>
    & ProjectOverviewFragmentFragment
  )> }
);

export type UserWizardProjectBudgetViewQueryVariables = Exact<{
  hashId?: Maybe<Scalars['String']>;
}>;


export type UserWizardProjectBudgetViewQuery = (
  { __typename?: 'Queries' }
  & { project?: Maybe<(
    { __typename?: 'ProjectType' }
    & Pick<ProjectType, 'id' | 'statusComputed'>
    & ProjectBudgetFragmentFragment
  )> }
);

export type UserWizardProjectLandscapeViewQueryVariables = Exact<{
  hashId?: Maybe<Scalars['String']>;
}>;


export type UserWizardProjectLandscapeViewQuery = (
  { __typename?: 'Queries' }
  & { project?: Maybe<(
    { __typename?: 'ProjectType' }
    & Pick<ProjectType, 'id' | 'statusComputed'>
    & UserWizardProjectLocationItemFragmentFragment
  )> }
);

export type UserWizardSummaryViewQueryVariables = Exact<{
  hashId?: Maybe<Scalars['String']>;
}>;


export type UserWizardSummaryViewQuery = (
  { __typename?: 'Queries' }
  & { project?: Maybe<(
    { __typename?: 'ProjectType' }
    & Pick<ProjectType, 'id'>
    & ProjectSummaryFragmentFragment
  )>, getProductsForProject?: Maybe<Array<(
    { __typename?: 'ProductAndItemType' }
    & Pick<ProductAndItemType, 'id'>
  )>> }
);

export type UserWizardProjectTimelineViewQueryVariables = Exact<{
  hashId?: Maybe<Scalars['String']>;
}>;


export type UserWizardProjectTimelineViewQuery = (
  { __typename?: 'Queries' }
  & { project?: Maybe<(
    { __typename?: 'ProjectType' }
    & Pick<ProjectType, 'id' | 'statusComputed'>
    & ProjectTimelineFragmentFragment
  )> }
);

export type UserWizardProjectTitleViewQueryVariables = Exact<{
  hashId?: Maybe<Scalars['String']>;
}>;


export type UserWizardProjectTitleViewQuery = (
  { __typename?: 'Queries' }
  & { project?: Maybe<(
    { __typename?: 'ProjectType' }
    & Pick<ProjectType, 'id' | 'statusComputed'>
    & ProjectTitleFragmentFragment
  )>, clients: Array<(
    { __typename?: 'ClientType' }
    & Pick<ClientType, 'id' | 'name'>
  )> }
);

export type UserWizardProjectTypeViewQueryVariables = Exact<{
  hashId?: Maybe<Scalars['String']>;
}>;


export type UserWizardProjectTypeViewQuery = (
  { __typename?: 'Queries' }
  & { project?: Maybe<(
    { __typename?: 'ProjectType' }
    & Pick<ProjectType, 'id' | 'statusComputed'>
    & ProjectTypeFragmentFragment
  )> }
);

export const FavFragmentDoc = gql`
    fragment Fav on ProductType {
  favorited
}
    `;
export const LocationItemVimrFragmentFragmentDoc = gql`
    fragment LocationItemVIMRFragment on LocationItemType {
  allImages
  wallPt1
  wallPt2
  wallPt3
  wallPt4
  wallImgRotation
}
    `;
export const BucketItemsFragmentFragmentDoc = gql`
    fragment BucketItemsFragment on ProductType {
  id
  bucketId
  croppedImage
  image
  name
  hideVir
  site
  category {
    slug
  }
  medium {
    slug
  }
  baseSize {
    height
    width
    thickness
    sizeString
  }
  selectedWidth
  selectedHeight
  selectedThickness
}
    `;
export const LocationItemFragmentFragmentDoc = gql`
    fragment LocationItemFragment on LocationItemType {
  titleStr
  wallWidth
  wallHeight
  wallDepth
  wallVimrBg
  bucketItems {
    id
    esId
    wallPt
  }
  bucketItemsData {
    id
    ...BucketItemsFragment
  }
  location {
    id
    titleStr
  }
}
    ${BucketItemsFragmentFragmentDoc}`;
export const ElevationsRenderLocationItemFragmentFragmentDoc = gql`
    fragment ElevationsRenderLocationItemFragment on LocationItemType {
  id
  ...LocationItemVIMRFragment
  ...LocationItemFragment
  locationItemImages(imageOnly: true) {
    id
    image
    imageFilename
  }
  vimrImages {
    id
    image
    imageFilename
  }
  location {
    id
    externalId
    project {
      id
      hashId
    }
  }
}
    ${LocationItemVimrFragmentFragmentDoc}
${LocationItemFragmentFragmentDoc}`;
export const ImageRenderLocationItemFragmentFragmentDoc = gql`
    fragment ImageRenderLocationItemFragment on LocationItemType {
  id
  ...LocationItemVIMRFragment
  ...LocationItemFragment
  locationItemImages(imageOnly: true) {
    id
    image
    imageFilename
  }
  vimrImages {
    id
    image
    imageFilename
  }
  location {
    id
    externalId
    project {
      id
      hashId
    }
  }
}
    ${LocationItemVimrFragmentFragmentDoc}
${LocationItemFragmentFragmentDoc}`;
export const GlobalContextProviderProjectFragmentFragmentDoc = gql`
    fragment GlobalContextProviderProjectFragment on ProjectType {
  id
  title
  user {
    userprofile {
      hasTradeDiscount
    }
  }
}
    `;
export const StaffLocationItemFieldsFragmentDoc = gql`
    fragment StaffLocationItemFields on LocationItemType {
  id
  allImagesFull {
    id
    image
    isSensitive
  }
  title
  wallWidth
  wallHeight
  itemWidth
  itemHeight
  wfCommissionedWorksWorkingTitle
  wfCommissionedWorksArtworkDescription
  wfCommissionedWorksEstimatedTimeline
  wfCommissionedWorksAdditionalQuestions
  wfCommissionedWorksBudgetOverride
  wfCommissionedWorksBudgetCcy
  wfCommissionedWorksWallWidthOverride
  wfCommissionedWorksWallHeightOverride
  wfCommissionedWorksItemWidthOverride
  wfCommissionedWorksItemHeightOverride
  wfCommissionedWorksCommission
  workflowCommissionedWorksImages {
    id
    imageKey
    imageKeyFilename
  }
  location {
    id
    title
    locationImages {
      id
      image
    }
    project {
      id
      budget
    }
  }
}
    `;
export const LocationItemFieldsFragmentDoc = gql`
    fragment LocationItemFields on LocationItemType {
  id
  allCoverImages
  pdfImage
}
    `;
export const LocationItemsFieldsFragmentDoc = gql`
    fragment LocationItemsFields on LocationItemType {
  id
  titleStr
  allCoverImages
  pdfImage
  bucketItems {
    id
    image
  }
  floorplans {
    id
    markedImage
  }
}
    `;
export const ProposalFieldsFragmentDoc = gql`
    fragment ProposalFields on PDFProposalType {
  id
  title
  createdAt
  lastUpdated
  pages {
    id
  }
}
    `;
export const ModalProductDetailsLocationItemFragmentFragmentDoc = gql`
    fragment ModalProductDetailsLocationItemFragment on LocationItemType {
  id
  allImages
  locationItemType {
    id
    title
  }
  location {
    id
    externalId
    project {
      id
      hashId
    }
  }
  locationItemImages(imageOnly: true) {
    id
    image
    imageFilename
  }
}
    `;
export const QuotationitemSetFieldsFragmentDoc = gql`
    fragment QuotationitemSetFields on QuotationItemType {
  id
  productImage
  productTitle
  artistName
  bucketItemCustomText
  price
  quantity
  framingRequired
  framingComments
  additionalComments
}
    `;
export const ButtonToggleBucketSizePopupSizeFragmentDoc = gql`
    fragment ButtonToggleBucketSizePopupSize on SizeType {
  id
  printBaseCost {
    printSize
  }
  width
  height
  thickness
  diameter
  price
}
    `;
export const BucketUtilsFragmentDoc = gql`
    fragment BucketUtils on SizeType {
  id
  printBaseCost {
    printSize
    printMaterial
  }
  width
  height
  thickness
  diameter
  framingStatus
  stretchingStatus
  price
}
    `;
export const ProductGridItemFragmentDoc = gql`
    fragment ProductGridItem on ProductType {
  id
  esId
  image
  name
  artistUpdatedAt
  artist {
    name
  }
  gallery {
    name
  }
  category {
    slug
  }
  cheapestPriceString
  sizeString
  site
  sizes {
    ...ButtonToggleBucketSizePopupSize
    ...BucketUtils
  }
  favorited
}
    ${ButtonToggleBucketSizePopupSizeFragmentDoc}
${BucketUtilsFragmentDoc}`;
export const FlowItemCommissionedWorkStaffFragmentFragmentDoc = gql`
    fragment FlowItemCommissionedWorkStaffFragment on FlowItemCommissionedWorkType {
  id
  title
  artist {
    id
    name
  }
  artworkDescription
  estimatedTimeline
  budget
  isAvailable
  cost
  leadTime
  hasSketchFee
  sketchFee
  portfolio1
  portfolio2
  portfolio3
  remarks
  history {
    id
    state
    dateCreated
    dateFinished
    data
    uniqueUrl
    changeUser {
      id
      email
    }
  }
}
    `;
export const FlowItemArtworkStaffFragmentFragmentDoc = gql`
    fragment FlowItemArtworkStaffFragment on FlowItemArtworkType {
  id
  isAvailable
  isNotReady
  leadTime
  destinationCountryName
  destinationCity
  destinationZip
  shipsFromCountryName
  shipsFromCity
  shipsFromZip
  shippedRolled
  shippedFramed
  isShippingCostUnknown
  shippingCost
  remarks
  history {
    id
    state
    dateCreated
    dateFinished
    data
    uniqueUrl
    changeUser {
      id
      email
    }
    changeUserStr
  }
}
    `;
export const CurrentProfileFragmentFragmentDoc = gql`
    fragment CurrentProfileFragment on UserProfileType {
  id
  isSuperuser
  crispUserHash
  joyrideSearchViewCompleted
  joyrideShortlistedViewCompleted
  lastSourced
  hasTradeDiscount
  user {
    id
    email
  }
}
    `;
export const FramingSpecsQuotationsFieldsFragmentDoc = gql`
    fragment FramingSpecsQuotationsFields on FramingSpecsQuotationType {
  id
  frameWidth
  frameHeight
  frameThickness
  frameMaterial
  frameColour
  matMaterial
  matThickness
  glazingMaterial
  cost
  leadTimeUnit
  leadTime
  comments
  images {
    id
    imageKey
    imageKeyFilename
  }
}
    `;
export const FramerGlobalQuotationFragmentFlowItemsFieldsFragmentDoc = gql`
    fragment FramerGlobalQuotationFragmentFlowItemsFields on FlowItemArtworkFramerRFQType {
  id
  title
  image
  destinationCountryName
  destinationCity
  destinationZip
  framerArtworkMaterial
  framerDeliveryRequired
  framerStretchingRequired
  framerFramingRequired
  framerInstallationRequired
  framerLocksComment
  framerWeight
  framerSizeStr
  framerWeightStr
  framerQuantity
  framerNotes
  stretchingQuotation(framerHashId: $framerHashId) {
    id
    cost
    leadTimeUnit
    leadTime
    comments
  }
  framingSpecsQuotations(framerHashId: $framerHashId) {
    ...FramingSpecsQuotationsFields
  }
}
    ${FramingSpecsQuotationsFieldsFragmentDoc}`;
export const FramerGlobalQuotationFragmentFragmentDoc = gql`
    fragment FramerGlobalQuotationFragment on FramerGlobalQuotationType {
  isAvailable
  isAvailableStretching
  isAvailableFraming
  isAvailableDelivery
  isAvailableInstallation
  unit
  currency
  deliveryCost
  deliveryComments
  installationCost
  installationAdditionalCharge
  installationAdditionalCost
  installationComments
  dateFinished
  flowItems {
    ...FramerGlobalQuotationFragmentFlowItemsFields
  }
}
    ${FramerGlobalQuotationFragmentFlowItemsFieldsFragmentDoc}`;
export const FramerFragmentFragmentDoc = gql`
    fragment FramerFragment on FramerType {
  id
  framerId: id
  name
  primaryEmail
  secondaryEmails
  providesFraming
  providesInstallation
  providesStretching
  adminNotes
  country
}
    `;
export const FramerRowFragmentFragmentDoc = gql`
    fragment FramerRowFragment on FramerType {
  name
  providesFraming
  providesInstallation
  providesStretching
}
    `;
export const FlowItemFramerInfoFragmentFragmentDoc = gql`
    fragment FlowItemFramerInfoFragment on FlowItemArtworkType {
  framerArtworkMaterial
  framerUnit
  framerWidth
  framerHeight
  framerThickness
  framerWeight
  framerQuantity
  framerNotes
  framerLocksComment
  framerFramingRequired
  framerInstallationRequired
  framerStretchingRequired
  framerDeliveryRequired
  framerStaffUpdatedAt
  bucketItem {
    id
    selectedSize
  }
}
    `;
export const WorkflowSelectedWorksFragmentFragmentDoc = gql`
    fragment WorkflowSelectedWorksFragment on NormalWorksLocation {
  id: locationId
  locationTitle
  locationItems {
    id: locationItemId
    locationItemTitle
    steps {
      step
      items_final: items {
        id
        esId
        image
        title
        artist
        framerStaffUpdatedAt
        framerStretchingRequired
        framerDeliveryRequired
        framerFramingRequired
        framerInstallationRequired
        framerLocksComment
        locationItem {
          id
          title
          location {
            id
            title
            project {
              hashId
              id
            }
          }
        }
        currentStates {
          id
          state
          dateCreated
          uniqueUrl
        }
      }
    }
  }
}
    `;
export const ProjectOverviewFragmentFragmentDoc = gql`
    fragment ProjectOverviewFragment on ProjectType {
  id
  hashId
  wizardStep
  locationCount
  wizardStepIsReady
  locations {
    id
    externalId
    title
    locationIsReady
    locationItems {
      id
      externalId
      locationItemIsReady
    }
  }
}
    `;
export const BucketItemDetailsFragmentFragmentDoc = gql`
    fragment BucketItemDetailsFragment on BucketItemType {
  id
  artworkTitle
  artistName
  price
  shipsFrom
  dimensions
  mediums
  otherDetails
}
    `;
export const ProjectInviteFragmentFragmentDoc = gql`
    fragment ProjectInviteFragment on ProjectType {
  id
  unusedSignupkeys {
    id
    inviteSentTo
  }
  sharedUsers {
    id
    email
    userprofile {
      id
      firstName
      lastName
    }
  }
}
    `;
export const LocationItemDetailFragmentFragmentDoc = gql`
    fragment LocationItemDetailFragment on LocationItemType {
  id
  dimensionsStr
  info
  externalId
  itemHeight
  itemUnsure
  itemWidth
  itemDepth
  measurementUnit
  title
  titleStr
  wallHeight
  wallUnsure
  wallWidth
  wallDepth
  wallSizeSameAsArtwork
  locationItemIsReady
  isCommissionedWork
  commissionedWorkInfo
  attribute1 {
    id
    title
  }
  attribute2 {
    id
    title
  }
  bucketItems {
    id
  }
  category {
    id
    title
    slug
  }
  locationItemType {
    id
    title
    slug
  }
  locationItemImages {
    id
    externalId
    image
    imageFilename
    isSensitive
  }
  floorplans {
    id
    image
    imageFilename
    isSensitive
  }
  location {
    id
    externalId
    title
    titleStr
    project {
      id
      title
      hashId
      budgetUnsure
      budget
      budgetCurrency
      countryName
      status
    }
    locationItems {
      id
      externalId
    }
    locationImages {
      id
      externalId
      image
      imageFilename
    }
  }
}
    `;
export const LocationSummaryFragmentFragmentDoc = gql`
    fragment LocationSummaryFragment on LocationType {
  id
  externalId
  title
  info
  budget
  locationImageUrl
  hasCommissioned
  hasImages
  locationImages {
    id
    externalId
    image
    isSensitive
  }
  locationItems {
    id
    ...LocationItemDetailFragment
  }
  activeLocationItems {
    id
    ...LocationItemDetailFragment
  }
}
    ${LocationItemDetailFragmentFragmentDoc}`;
export const ProjectSummaryFragmentFragmentDoc = gql`
    fragment ProjectSummaryFragment on ProjectType {
  id
  hashId
  wizardStep
  status
  title
  timelineUnsure
  timelineStart
  timelineEnd
  budget
  budgetCurrency
  city
  countryName
  countryCode
  postalCode
  quotationInstallationRequired
  quotationComments
  quotationShippingName
  quotationShippingAddress
  quotationShippingCity
  quotationShippingState
  quotationShippingZip
  quotationShippingCountryName
  quotationShippingCountryCode
  quotationBillingName
  quotationBillingAddress
  quotationBillingCity
  quotationBillingState
  quotationBillingZip
  quotationBillingCountryName
  quotationBillingCountryCode
  statusComputed
  artworkProposalRequestedAt
  artworkProposalSentAt
  client {
    id
    name
  }
  projectTypesStr
  projectType {
    id
    title
  }
  locations {
    id
    ...LocationSummaryFragment
  }
  activeLocations {
    id
    ...LocationSummaryFragment
  }
}
    ${LocationSummaryFragmentFragmentDoc}`;
export const ProjectBudgetFragmentFragmentDoc = gql`
    fragment ProjectBudgetFragment on ProjectType {
  id
  hashId
  wizardStep
  wizardStepIsReady
  budget
  budgetCurrency
  budgetInfo
  budgetUnsure
  locations {
    id
    externalId
    title
    locationItems {
      id
      externalId
    }
  }
}
    `;
export const ProjectTimelineFragmentFragmentDoc = gql`
    fragment ProjectTimelineFragment on ProjectType {
  id
  hashId
  wizardStep
  wizardStepIsReady
  timelineEnd
  timelineUnsure
  timelineInfo
  locations {
    id
    externalId
    locationItems {
      id
      externalId
    }
  }
}
    `;
export const ProjectTypeFragmentFragmentDoc = gql`
    fragment ProjectTypeFragment on ProjectType {
  id
  hashId
  wizardStep
  wizardStepIsReady
  projectType {
    id
    title
    iconSlug
  }
  projectTypeOther
  projectTypeInfo
  locations {
    id
    externalId
    locationItems {
      id
      externalId
    }
  }
}
    `;
export const ProjectTitleFragmentFragmentDoc = gql`
    fragment ProjectTitleFragment on ProjectType {
  id
  status
  hashId
  title
  titleInfo
  wizardStep
  wizardStepIsReady
  postalCode
  countryName
  countryCode
  city
  client {
    id
    name
  }
  locations {
    id
    externalId
    locationItems {
      id
      externalId
    }
  }
}
    `;
export const ProjectPdfPageFragmentFragmentDoc = gql`
    fragment ProjectPdfPageFragment on ProjectType {
  id
  pdfVertical
  pdfEmail
  pdfAddr
  pdfSite
  pdfLogo
}
    `;
export const LocationFragmentFragmentDoc = gql`
    fragment LocationFragment on LocationType {
  id
  title
  project {
    id
    title
  }
}
    `;
export const LocationOverviewFragmentFragmentDoc = gql`
    fragment LocationOverviewFragment on LocationType {
  id
  title
  externalId
  info
  locationImages {
    id
    externalId
    image
    imageFilename
  }
  project {
    id
    hashId
  }
}
    `;
export const ProductForLocationItemFragmentFragmentDoc = gql`
    fragment ProductForLocationItemFragment on ProductType {
  id
  esId
  name
  image
  artist {
    name
  }
  cheapestPriceString
  site
  sizes {
    width
    height
    thickness
    diameter
    printBaseCost {
      printSize
      printMaterial
    }
    price
  }
}
    `;
export const ProductGridFragmentFragmentDoc = gql`
    fragment ProductGridFragment on ProductType {
  id
  esId
  favorited
  name
  image
  artistUpdatedAt
  artist {
    name
  }
  gallery {
    name
  }
  category {
    slug
  }
  cheapestPriceString
  sizeString
  site
  sizes {
    id
    width
    height
    thickness
    diameter
    printBaseCost {
      printSize
      printMaterial
    }
    price
    framingStatus
    stretchingStatus
    sizeString
  }
}
    `;
export const ProductFragmentFragmentDoc = gql`
    fragment ProductFragment on ProductType {
  id
  ...ProductGridFragment
  extraImages
  detailUrl
  hideVir
  showFrameOnVir
  artistUpdatedAt
  artist {
    id
    detailUrl
    canDoCommissionedWork
    canDoPublicMurals
    country {
      name
    }
  }
  description
  artworksMedium
  artworkStyle {
    slug
  }
  exclusive
  remainingCount
  designLeadTime
  designSuitableForOutdoors
  designMaterial {
    slug
  }
  printOrientation
  medium {
    slug
    title
  }
  country {
    slug
    name
  }
  sizes {
    id
    widthIn
    heightIn
    thicknessIn
    diameterIn
    orientation
    printActive
    priceString
    sizeAndPriceString
  }
  tags
  gvLabels
}
    ${ProductGridFragmentFragmentDoc}`;
export const UserWizardProjectLocationItemFragmentLocationsFragmentFragmentDoc = gql`
    fragment UserWizardProjectLocationItemFragmentLocationsFragment on LocationType {
  id
  externalId
  title
  titleStr
  locationImages {
    id
    externalId
    image
    imageFilename
  }
  floorplans {
    id
    image
    imageFilename
  }
  locationItems {
    id
    ...LocationItemDetailFragment
  }
}
    ${LocationItemDetailFragmentFragmentDoc}`;
export const UserWizardProjectLocationItemFragmentFragmentDoc = gql`
    fragment UserWizardProjectLocationItemFragment on ProjectType {
  hashId
  wizardStep
  locations {
    ...UserWizardProjectLocationItemFragmentLocationsFragment
  }
}
    ${UserWizardProjectLocationItemFragmentLocationsFragmentFragmentDoc}`;
export const ProductAndItemFragmentFragmentDoc = gql`
    fragment ProductAndItemFragment on ProductAndItemType {
  id
  copyPasteText
  product {
    id
    ...ProductGridFragment
    designLeadTime
    remainingCount
    medium {
      title
    }
  }
  bucketItem {
    id
    esId
    vimrImages {
      id
      image
    }
    locationItem {
      id
      title
      titleStr
      externalId
      location {
        id
        title
        titleStr
        externalId
      }
    }
    customText
    customSize
    selectedSize
    originalPrice
    price
    framingStatus
    stretchingStatus
    framingAndStretchingStatus
    position
    isShortlisted
    quotationQuantity
    quotationFramingRequired
    quotationFramingComments
    quotationAdditionalComments
  }
}
    ${ProductGridFragmentFragmentDoc}`;
export const LocationPositionFragmentFragmentDoc = gql`
    fragment LocationPositionFragment on LocationType {
  id
  externalId
  title
  titleStr
  isShortlisted
  locationItems {
    id
    externalId
    title
    titleStr
    locationItemTypeStr
    dimensionsStr
    position
    isShortlisted
    category {
      id
      title
      slug
    }
    location {
      id
      externalId
    }
    bucketItems_final: productsAndBucketItems {
      id
      ...ProductAndItemFragment
    }
  }
}
    ${ProductAndItemFragmentFragmentDoc}`;
export const UserProfileFragmentFragmentDoc = gql`
    fragment UserProfileFragment on UserProfileType {
  user {
    email
    id
  }
  firstName
  lastName
  title
  phoneNo
  companyName
  userStreet
  userCity
  userCountryCode
  userZip
  userPhoneNo
  userCompanyName
}
    `;
export const ArtistVariantProjectFragmentFragmentDoc = gql`
    fragment ArtistVariantProjectFragment on ArtistVariantProjectType {
  name
  date
  rate
  size
  status
}
    `;
export const ProposalPageFragmentFragmentDoc = gql`
    fragment ProposalPageFragment on PDFPageType {
  id
  isActive
  pageType
  pdfDetails
  position
  project {
    id
  }
  locationItem {
    id
    externalId
    location {
      id
      externalId
    }
  }
  bucketItem {
    id
  }
  floorplan {
    id
  }
  virImage {
    id
  }
}
    `;
export const PdfPreviewViewProposalFragmentFragmentDoc = gql`
    fragment PdfPreviewViewProposalFragment on PDFProposalType {
  id
  pdfHideTitle
  pdfHideArtist
  pdfHidePrice
  pdfHideShipping
  pdfHideAreaDetails
  pdfHideLogo
  pdfVertical
  pdfLogo
  pdfAddr
  pdfSite
  pdfEmail
  title
  project {
    id
    hashId
    title
  }
  pages {
    id
    ...ProposalPageFragment
  }
}
    ${ProposalPageFragmentFragmentDoc}`;
export const ProjectLocationsFieldsFragmentDoc = gql`
    fragment ProjectLocationsFields on LocationType {
  id
  title
  titleStr
  externalId
  isShortlisted
  locationItems {
    id
    titleStr
    isShortlisted
  }
}
    `;
export const ProjectsFieldsFragmentDoc = gql`
    fragment ProjectsFields on ProjectType {
  id
  user {
    id
    email
  }
  hashId
  title
  locationsCount
  itemsCount
  selectionsCount
  isQuickSourced
  status
  client {
    id
    name
    user
  }
}
    `;
export const RequestQuotationViewQuotationFragmentFragmentDoc = gql`
    fragment RequestQuotationViewQuotationFragment on QuotationType {
  id
  submittedAtStr
  quotationitemSet {
    id
  }
}
    `;
export const SearchViewLocationItemFragmentFragmentDoc = gql`
    fragment SearchViewLocationItemFragment on LocationItemType {
  id
  ...LocationItemDetailFragment
  productsAndBucketItems {
    id
    ...ProductAndItemFragment
  }
}
    ${LocationItemDetailFragmentFragmentDoc}
${ProductAndItemFragmentFragmentDoc}`;
export const WfCommissionedWorksSellerConfirmAvailabilityDataFieldsFragmentDoc = gql`
    fragment wfCommissionedWorksSellerConfirmAvailabilityDataFields on FlowItemCommissionedWorkConfirmAvailabilityType {
  id
  title
  commission
  artistName
  artworkDescription
  estimatedTimeline
  additionalQuestions
  budget
  budgetCcy
  wallWidth
  wallHeight
  wallSizeStr
  itemWidth
  itemHeight
  itemSizeStr
  dateFinished
  isAvailable
  cost
  leadTime
  hasSketchFee
  sketchFee
  portfolio1
  portfolio2
  portfolio3
  remarks
  files {
    id
    imageKey
    imageKeyFilename
  }
}
    `;
export const ArtistVariantsObjectsFieldsFragmentDoc = gql`
    fragment ArtistVariantsObjectsFields on ArtistVariantType {
  id
  priceLow
  priceHigh
  dataRequestDate
  tags
  allProjects
  artist {
    id
    name
    countryName
  }
  galleries {
    id
    name
    countryNames
  }
  medium {
    id
    title
  }
  productImages {
    id
    esId
    imageKey
  }
}
    `;
export const ArtistsObjectsFieldsFragmentDoc = gql`
    fragment ArtistsObjectsFields on ArtistSourcingType {
  id
  name
  website
  contactEmail
  yearOfBirth
  countryName
  tags
}
    `;
export const GalleriesObjectsFieldsFragmentDoc = gql`
    fragment GalleriesObjectsFields on GallerySourcingType {
  id
  contactEmail
  contactNumber
  countryNames
  name
  tags
  website
}
    `;
export const GetInquiriesFieldsFragmentDoc = gql`
    fragment GetInquiriesFields on InquiryType {
  id
  userEmail
  productName
  createdAt
  esId
  inquiry
  changeType {
    id
    title
  }
  budget
  budgetCurrency
}
    `;
export const StaffQuotationsViewFragmentFragmentDoc = gql`
    fragment StaffQuotationsViewFragment on QuotationType {
  id
  submittedAt
  project {
    id
    hashId
    title
    client {
      id
      name
    }
  }
}
    `;
export const HistoryFieldsFragmentDoc = gql`
    fragment HistoryFields on CurerntStateType {
  id
  state
  dateCreated
  dateFinished
  data
  uniqueUrl
  changeUser {
    id
    email
  }
}
    `;
export const StaffWfCommissionedWorksNotifySellersViewCommissionedWorksLocationFragmentFragmentDoc = gql`
    fragment StaffWFCommissionedWorksNotifySellersViewCommissionedWorksLocationFragment on CommissionedWorksLocation {
  id: locationId
  locationTitle
  locationItems {
    id: locationItemId
    locationItemTitle
    steps {
      step
      items_final: items {
        id
        esId
        image
        title
        artist {
          id
          name
          contactEmail
        }
        locationItem {
          id
          title
          titleStr
          location {
            id
            title
            project {
              id
              hashId
            }
          }
        }
        currentStates {
          id
          state
          dateCreated
          uniqueUrl
        }
      }
    }
  }
}
    `;
export const WfNormalWorksStepsDataStepsFieldsFragmentDoc = gql`
    fragment WfNormalWorksStepsDataStepsFields on NormalWorksStepItems {
  step
  items {
    id
    esId
    image
    currentStates {
      id
      uniqueUrl
    }
    bucketItem {
      id
      locationItem {
        id
      }
    }
  }
}
    `;
export const ProjectsWithFlowItemsObjectsFieldsFragmentDoc = gql`
    fragment ProjectsWithFlowItemsObjectsFields on ProjectType {
  id
  hashId
  title
}
    `;
export const LocationItemBucketDocument = gql`
    query LocationItemBucket($hashId: String, $locationId: String, $locationItemId: String) {
  locationItem(hashId: $hashId, locId: $locationId, locItemId: $locationItemId) {
    id
    ...LocationItemDetailFragment
    externalId
    location {
      id
      title
      project {
        id
        title
      }
    }
  }
}
    ${LocationItemDetailFragmentFragmentDoc}`;

/**
 * __useLocationItemBucketQuery__
 *
 * To run a query within a React component, call `useLocationItemBucketQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationItemBucketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationItemBucketQuery({
 *   variables: {
 *      hashId: // value for 'hashId'
 *      locationId: // value for 'locationId'
 *      locationItemId: // value for 'locationItemId'
 *   },
 * });
 */
export function useLocationItemBucketQuery(baseOptions?: Apollo.QueryHookOptions<LocationItemBucketQuery, LocationItemBucketQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationItemBucketQuery, LocationItemBucketQueryVariables>(LocationItemBucketDocument, options);
      }
export function useLocationItemBucketLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationItemBucketQuery, LocationItemBucketQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationItemBucketQuery, LocationItemBucketQueryVariables>(LocationItemBucketDocument, options);
        }
export type LocationItemBucketQueryHookResult = ReturnType<typeof useLocationItemBucketQuery>;
export type LocationItemBucketLazyQueryHookResult = ReturnType<typeof useLocationItemBucketLazyQuery>;
export type LocationItemBucketQueryResult = Apollo.QueryResult<LocationItemBucketQuery, LocationItemBucketQueryVariables>;
export const ButtonAddToBucketDocument = gql`
    query ButtonAddToBucket {
  projects {
    projects {
      id
    }
  }
}
    `;

/**
 * __useButtonAddToBucketQuery__
 *
 * To run a query within a React component, call `useButtonAddToBucketQuery` and pass it any options that fit your needs.
 * When your component renders, `useButtonAddToBucketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useButtonAddToBucketQuery({
 *   variables: {
 *   },
 * });
 */
export function useButtonAddToBucketQuery(baseOptions?: Apollo.QueryHookOptions<ButtonAddToBucketQuery, ButtonAddToBucketQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ButtonAddToBucketQuery, ButtonAddToBucketQueryVariables>(ButtonAddToBucketDocument, options);
      }
export function useButtonAddToBucketLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ButtonAddToBucketQuery, ButtonAddToBucketQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ButtonAddToBucketQuery, ButtonAddToBucketQueryVariables>(ButtonAddToBucketDocument, options);
        }
export type ButtonAddToBucketQueryHookResult = ReturnType<typeof useButtonAddToBucketQuery>;
export type ButtonAddToBucketLazyQueryHookResult = ReturnType<typeof useButtonAddToBucketLazyQuery>;
export type ButtonAddToBucketQueryResult = Apollo.QueryResult<ButtonAddToBucketQuery, ButtonAddToBucketQueryVariables>;
export const ButtonLogoutDocument = gql`
    mutation ButtonLogout {
  logout {
    status
  }
}
    `;
export type ButtonLogoutMutationFn = Apollo.MutationFunction<ButtonLogoutMutation, ButtonLogoutMutationVariables>;

/**
 * __useButtonLogoutMutation__
 *
 * To run a mutation, you first call `useButtonLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useButtonLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buttonLogoutMutation, { data, loading, error }] = useButtonLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useButtonLogoutMutation(baseOptions?: Apollo.MutationHookOptions<ButtonLogoutMutation, ButtonLogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ButtonLogoutMutation, ButtonLogoutMutationVariables>(ButtonLogoutDocument, options);
      }
export type ButtonLogoutMutationHookResult = ReturnType<typeof useButtonLogoutMutation>;
export type ButtonLogoutMutationResult = Apollo.MutationResult<ButtonLogoutMutation>;
export type ButtonLogoutMutationOptions = Apollo.BaseMutationOptions<ButtonLogoutMutation, ButtonLogoutMutationVariables>;
export const ButtonReplayJoyrideInSearchViewDocument = gql`
    mutation ButtonReplayJoyrideInSearchView {
  replayJoyrideSearchView {
    status
  }
}
    `;
export type ButtonReplayJoyrideInSearchViewMutationFn = Apollo.MutationFunction<ButtonReplayJoyrideInSearchViewMutation, ButtonReplayJoyrideInSearchViewMutationVariables>;

/**
 * __useButtonReplayJoyrideInSearchViewMutation__
 *
 * To run a mutation, you first call `useButtonReplayJoyrideInSearchViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useButtonReplayJoyrideInSearchViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buttonReplayJoyrideInSearchViewMutation, { data, loading, error }] = useButtonReplayJoyrideInSearchViewMutation({
 *   variables: {
 *   },
 * });
 */
export function useButtonReplayJoyrideInSearchViewMutation(baseOptions?: Apollo.MutationHookOptions<ButtonReplayJoyrideInSearchViewMutation, ButtonReplayJoyrideInSearchViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ButtonReplayJoyrideInSearchViewMutation, ButtonReplayJoyrideInSearchViewMutationVariables>(ButtonReplayJoyrideInSearchViewDocument, options);
      }
export type ButtonReplayJoyrideInSearchViewMutationHookResult = ReturnType<typeof useButtonReplayJoyrideInSearchViewMutation>;
export type ButtonReplayJoyrideInSearchViewMutationResult = Apollo.MutationResult<ButtonReplayJoyrideInSearchViewMutation>;
export type ButtonReplayJoyrideInSearchViewMutationOptions = Apollo.BaseMutationOptions<ButtonReplayJoyrideInSearchViewMutation, ButtonReplayJoyrideInSearchViewMutationVariables>;
export const ButtonReplayJoyrideInShortlistedViewDocument = gql`
    mutation ButtonReplayJoyrideInShortlistedView {
  replayJoyrideShortlistedView {
    status
  }
}
    `;
export type ButtonReplayJoyrideInShortlistedViewMutationFn = Apollo.MutationFunction<ButtonReplayJoyrideInShortlistedViewMutation, ButtonReplayJoyrideInShortlistedViewMutationVariables>;

/**
 * __useButtonReplayJoyrideInShortlistedViewMutation__
 *
 * To run a mutation, you first call `useButtonReplayJoyrideInShortlistedViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useButtonReplayJoyrideInShortlistedViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buttonReplayJoyrideInShortlistedViewMutation, { data, loading, error }] = useButtonReplayJoyrideInShortlistedViewMutation({
 *   variables: {
 *   },
 * });
 */
export function useButtonReplayJoyrideInShortlistedViewMutation(baseOptions?: Apollo.MutationHookOptions<ButtonReplayJoyrideInShortlistedViewMutation, ButtonReplayJoyrideInShortlistedViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ButtonReplayJoyrideInShortlistedViewMutation, ButtonReplayJoyrideInShortlistedViewMutationVariables>(ButtonReplayJoyrideInShortlistedViewDocument, options);
      }
export type ButtonReplayJoyrideInShortlistedViewMutationHookResult = ReturnType<typeof useButtonReplayJoyrideInShortlistedViewMutation>;
export type ButtonReplayJoyrideInShortlistedViewMutationResult = Apollo.MutationResult<ButtonReplayJoyrideInShortlistedViewMutation>;
export type ButtonReplayJoyrideInShortlistedViewMutationOptions = Apollo.BaseMutationOptions<ButtonReplayJoyrideInShortlistedViewMutation, ButtonReplayJoyrideInShortlistedViewMutationVariables>;
export const ButtonToggleBucketDocument = gql`
    mutation ButtonToggleBucket($data: String) {
  addOrRemoveBucketItem(data: $data) {
    status
    formErrors
    project {
      id
      bucketItemsCount
    }
    locationItem {
      id
      productsAndBucketItems {
        id
        product {
          id
          esId
        }
        bucketItem {
          id
          selectedSize
        }
      }
    }
  }
}
    `;
export type ButtonToggleBucketMutationFn = Apollo.MutationFunction<ButtonToggleBucketMutation, ButtonToggleBucketMutationVariables>;

/**
 * __useButtonToggleBucketMutation__
 *
 * To run a mutation, you first call `useButtonToggleBucketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useButtonToggleBucketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buttonToggleBucketMutation, { data, loading, error }] = useButtonToggleBucketMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useButtonToggleBucketMutation(baseOptions?: Apollo.MutationHookOptions<ButtonToggleBucketMutation, ButtonToggleBucketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ButtonToggleBucketMutation, ButtonToggleBucketMutationVariables>(ButtonToggleBucketDocument, options);
      }
export type ButtonToggleBucketMutationHookResult = ReturnType<typeof useButtonToggleBucketMutation>;
export type ButtonToggleBucketMutationResult = Apollo.MutationResult<ButtonToggleBucketMutation>;
export type ButtonToggleBucketMutationOptions = Apollo.BaseMutationOptions<ButtonToggleBucketMutation, ButtonToggleBucketMutationVariables>;
export const ButtonToggleFavoriteDocument = gql`
    mutation ButtonToggleFavorite($data: String) {
  toggleFavorite(data: $data) {
    status
  }
}
    `;
export type ButtonToggleFavoriteMutationFn = Apollo.MutationFunction<ButtonToggleFavoriteMutation, ButtonToggleFavoriteMutationVariables>;

/**
 * __useButtonToggleFavoriteMutation__
 *
 * To run a mutation, you first call `useButtonToggleFavoriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useButtonToggleFavoriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buttonToggleFavoriteMutation, { data, loading, error }] = useButtonToggleFavoriteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useButtonToggleFavoriteMutation(baseOptions?: Apollo.MutationHookOptions<ButtonToggleFavoriteMutation, ButtonToggleFavoriteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ButtonToggleFavoriteMutation, ButtonToggleFavoriteMutationVariables>(ButtonToggleFavoriteDocument, options);
      }
export type ButtonToggleFavoriteMutationHookResult = ReturnType<typeof useButtonToggleFavoriteMutation>;
export type ButtonToggleFavoriteMutationResult = Apollo.MutationResult<ButtonToggleFavoriteMutation>;
export type ButtonToggleFavoriteMutationOptions = Apollo.BaseMutationOptions<ButtonToggleFavoriteMutation, ButtonToggleFavoriteMutationVariables>;
export const UserWizardAddAreaButtonDocument = gql`
    mutation UserWizardAddAreaButton($data: String) {
  createNewLocation(data: $data) {
    status
    project {
      id
      ...UserWizardProjectLocationItemFragment
    }
  }
}
    ${UserWizardProjectLocationItemFragmentFragmentDoc}`;
export type UserWizardAddAreaButtonMutationFn = Apollo.MutationFunction<UserWizardAddAreaButtonMutation, UserWizardAddAreaButtonMutationVariables>;

/**
 * __useUserWizardAddAreaButtonMutation__
 *
 * To run a mutation, you first call `useUserWizardAddAreaButtonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserWizardAddAreaButtonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userWizardAddAreaButtonMutation, { data, loading, error }] = useUserWizardAddAreaButtonMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserWizardAddAreaButtonMutation(baseOptions?: Apollo.MutationHookOptions<UserWizardAddAreaButtonMutation, UserWizardAddAreaButtonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserWizardAddAreaButtonMutation, UserWizardAddAreaButtonMutationVariables>(UserWizardAddAreaButtonDocument, options);
      }
export type UserWizardAddAreaButtonMutationHookResult = ReturnType<typeof useUserWizardAddAreaButtonMutation>;
export type UserWizardAddAreaButtonMutationResult = Apollo.MutationResult<UserWizardAddAreaButtonMutation>;
export type UserWizardAddAreaButtonMutationOptions = Apollo.BaseMutationOptions<UserWizardAddAreaButtonMutation, UserWizardAddAreaButtonMutationVariables>;
export const UserWizardAddLocationButtonDocument = gql`
    mutation UserWizardAddLocationButton($data: String) {
  createNewLocationItem(data: $data) {
    status
    project {
      id
      ...UserWizardProjectLocationItemFragment
    }
  }
}
    ${UserWizardProjectLocationItemFragmentFragmentDoc}`;
export type UserWizardAddLocationButtonMutationFn = Apollo.MutationFunction<UserWizardAddLocationButtonMutation, UserWizardAddLocationButtonMutationVariables>;

/**
 * __useUserWizardAddLocationButtonMutation__
 *
 * To run a mutation, you first call `useUserWizardAddLocationButtonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserWizardAddLocationButtonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userWizardAddLocationButtonMutation, { data, loading, error }] = useUserWizardAddLocationButtonMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUserWizardAddLocationButtonMutation(baseOptions?: Apollo.MutationHookOptions<UserWizardAddLocationButtonMutation, UserWizardAddLocationButtonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserWizardAddLocationButtonMutation, UserWizardAddLocationButtonMutationVariables>(UserWizardAddLocationButtonDocument, options);
      }
export type UserWizardAddLocationButtonMutationHookResult = ReturnType<typeof useUserWizardAddLocationButtonMutation>;
export type UserWizardAddLocationButtonMutationResult = Apollo.MutationResult<UserWizardAddLocationButtonMutation>;
export type UserWizardAddLocationButtonMutationOptions = Apollo.BaseMutationOptions<UserWizardAddLocationButtonMutation, UserWizardAddLocationButtonMutationVariables>;
export const CanvasSceneUpdateLocationItemPointsDocument = gql`
    mutation CanvasSceneUpdateLocationItemPoints($locItemId: String, $pt1: String, $pt2: String, $pt3: String, $pt4: String) {
  updateLocationItemPoints(
    locItemId: $locItemId
    pt1: $pt1
    pt2: $pt2
    pt3: $pt3
    pt4: $pt4
  ) {
    status
    locationItem {
      id
      ...LocationItemFragment
    }
  }
}
    ${LocationItemFragmentFragmentDoc}`;
export type CanvasSceneUpdateLocationItemPointsMutationFn = Apollo.MutationFunction<CanvasSceneUpdateLocationItemPointsMutation, CanvasSceneUpdateLocationItemPointsMutationVariables>;

/**
 * __useCanvasSceneUpdateLocationItemPointsMutation__
 *
 * To run a mutation, you first call `useCanvasSceneUpdateLocationItemPointsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCanvasSceneUpdateLocationItemPointsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [canvasSceneUpdateLocationItemPointsMutation, { data, loading, error }] = useCanvasSceneUpdateLocationItemPointsMutation({
 *   variables: {
 *      locItemId: // value for 'locItemId'
 *      pt1: // value for 'pt1'
 *      pt2: // value for 'pt2'
 *      pt3: // value for 'pt3'
 *      pt4: // value for 'pt4'
 *   },
 * });
 */
export function useCanvasSceneUpdateLocationItemPointsMutation(baseOptions?: Apollo.MutationHookOptions<CanvasSceneUpdateLocationItemPointsMutation, CanvasSceneUpdateLocationItemPointsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CanvasSceneUpdateLocationItemPointsMutation, CanvasSceneUpdateLocationItemPointsMutationVariables>(CanvasSceneUpdateLocationItemPointsDocument, options);
      }
export type CanvasSceneUpdateLocationItemPointsMutationHookResult = ReturnType<typeof useCanvasSceneUpdateLocationItemPointsMutation>;
export type CanvasSceneUpdateLocationItemPointsMutationResult = Apollo.MutationResult<CanvasSceneUpdateLocationItemPointsMutation>;
export type CanvasSceneUpdateLocationItemPointsMutationOptions = Apollo.BaseMutationOptions<CanvasSceneUpdateLocationItemPointsMutation, CanvasSceneUpdateLocationItemPointsMutationVariables>;
export const CanvasSceneUpdateBucketItemWallPtDocument = gql`
    mutation CanvasSceneUpdateBucketItemWallPt($locItemId: String, $esId: String, $wallPt: String) {
  updateBucketItemWallPt(locItemId: $locItemId, esId: $esId, wallPt: $wallPt) {
    status
    bucketItem {
      id
      esId
      wallPt
    }
  }
}
    `;
export type CanvasSceneUpdateBucketItemWallPtMutationFn = Apollo.MutationFunction<CanvasSceneUpdateBucketItemWallPtMutation, CanvasSceneUpdateBucketItemWallPtMutationVariables>;

/**
 * __useCanvasSceneUpdateBucketItemWallPtMutation__
 *
 * To run a mutation, you first call `useCanvasSceneUpdateBucketItemWallPtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCanvasSceneUpdateBucketItemWallPtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [canvasSceneUpdateBucketItemWallPtMutation, { data, loading, error }] = useCanvasSceneUpdateBucketItemWallPtMutation({
 *   variables: {
 *      locItemId: // value for 'locItemId'
 *      esId: // value for 'esId'
 *      wallPt: // value for 'wallPt'
 *   },
 * });
 */
export function useCanvasSceneUpdateBucketItemWallPtMutation(baseOptions?: Apollo.MutationHookOptions<CanvasSceneUpdateBucketItemWallPtMutation, CanvasSceneUpdateBucketItemWallPtMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CanvasSceneUpdateBucketItemWallPtMutation, CanvasSceneUpdateBucketItemWallPtMutationVariables>(CanvasSceneUpdateBucketItemWallPtDocument, options);
      }
export type CanvasSceneUpdateBucketItemWallPtMutationHookResult = ReturnType<typeof useCanvasSceneUpdateBucketItemWallPtMutation>;
export type CanvasSceneUpdateBucketItemWallPtMutationResult = Apollo.MutationResult<CanvasSceneUpdateBucketItemWallPtMutation>;
export type CanvasSceneUpdateBucketItemWallPtMutationOptions = Apollo.BaseMutationOptions<CanvasSceneUpdateBucketItemWallPtMutation, CanvasSceneUpdateBucketItemWallPtMutationVariables>;
export const ElevationsRenderDocument = gql`
    query ElevationsRender($esId: String, $hashId: String, $locationId: String, $locationItemId: String, $originalId: Boolean) {
  currentProfile {
    id
    userVirImages {
      id
      externalId
      image
      imageFilename
    }
  }
  locationItem(
    locItemId: $locationItemId
    locId: $locationId
    hashId: $hashId
    originalId: $originalId
  ) {
    id
    ...ElevationsRenderLocationItemFragment
  }
  getProduct(esId: $esId) {
    id
    ...ProductFragment
  }
}
    ${ElevationsRenderLocationItemFragmentFragmentDoc}
${ProductFragmentFragmentDoc}`;

/**
 * __useElevationsRenderQuery__
 *
 * To run a query within a React component, call `useElevationsRenderQuery` and pass it any options that fit your needs.
 * When your component renders, `useElevationsRenderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useElevationsRenderQuery({
 *   variables: {
 *      esId: // value for 'esId'
 *      hashId: // value for 'hashId'
 *      locationId: // value for 'locationId'
 *      locationItemId: // value for 'locationItemId'
 *      originalId: // value for 'originalId'
 *   },
 * });
 */
export function useElevationsRenderQuery(baseOptions?: Apollo.QueryHookOptions<ElevationsRenderQuery, ElevationsRenderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ElevationsRenderQuery, ElevationsRenderQueryVariables>(ElevationsRenderDocument, options);
      }
export function useElevationsRenderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ElevationsRenderQuery, ElevationsRenderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ElevationsRenderQuery, ElevationsRenderQueryVariables>(ElevationsRenderDocument, options);
        }
export type ElevationsRenderQueryHookResult = ReturnType<typeof useElevationsRenderQuery>;
export type ElevationsRenderLazyQueryHookResult = ReturnType<typeof useElevationsRenderLazyQuery>;
export type ElevationsRenderQueryResult = Apollo.QueryResult<ElevationsRenderQuery, ElevationsRenderQueryVariables>;
export const ElevationsRenderUpdateVimrImagesDocument = gql`
    mutation ElevationsRenderUpdateVimrImages($data: String) {
  createUpdateVimrImages(data: $data) {
    status
    formErrors
    autoSubmit
    project {
      id
      vimrImages {
        id
      }
    }
    locationItem {
      id
      vimrImages {
        id
        image
        imageFilename
      }
    }
    bucketItems {
      id
      vimrImages {
        id
        image
      }
    }
  }
}
    `;
export type ElevationsRenderUpdateVimrImagesMutationFn = Apollo.MutationFunction<ElevationsRenderUpdateVimrImagesMutation, ElevationsRenderUpdateVimrImagesMutationVariables>;

/**
 * __useElevationsRenderUpdateVimrImagesMutation__
 *
 * To run a mutation, you first call `useElevationsRenderUpdateVimrImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useElevationsRenderUpdateVimrImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [elevationsRenderUpdateVimrImagesMutation, { data, loading, error }] = useElevationsRenderUpdateVimrImagesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useElevationsRenderUpdateVimrImagesMutation(baseOptions?: Apollo.MutationHookOptions<ElevationsRenderUpdateVimrImagesMutation, ElevationsRenderUpdateVimrImagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ElevationsRenderUpdateVimrImagesMutation, ElevationsRenderUpdateVimrImagesMutationVariables>(ElevationsRenderUpdateVimrImagesDocument, options);
      }
export type ElevationsRenderUpdateVimrImagesMutationHookResult = ReturnType<typeof useElevationsRenderUpdateVimrImagesMutation>;
export type ElevationsRenderUpdateVimrImagesMutationResult = Apollo.MutationResult<ElevationsRenderUpdateVimrImagesMutation>;
export type ElevationsRenderUpdateVimrImagesMutationOptions = Apollo.BaseMutationOptions<ElevationsRenderUpdateVimrImagesMutation, ElevationsRenderUpdateVimrImagesMutationVariables>;
export const ElevationsRenderUpdateLocationPointsDocument = gql`
    mutation ElevationsRenderUpdateLocationPoints($locItemId: String, $pt1: String, $pt2: String, $pt3: String, $pt4: String) {
  updateLocationItemPoints(
    locItemId: $locItemId
    pt1: $pt1
    pt2: $pt2
    pt3: $pt3
    pt4: $pt4
  ) {
    status
    locationItem {
      id
      ...LocationItemVIMRFragment
    }
  }
}
    ${LocationItemVimrFragmentFragmentDoc}`;
export type ElevationsRenderUpdateLocationPointsMutationFn = Apollo.MutationFunction<ElevationsRenderUpdateLocationPointsMutation, ElevationsRenderUpdateLocationPointsMutationVariables>;

/**
 * __useElevationsRenderUpdateLocationPointsMutation__
 *
 * To run a mutation, you first call `useElevationsRenderUpdateLocationPointsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useElevationsRenderUpdateLocationPointsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [elevationsRenderUpdateLocationPointsMutation, { data, loading, error }] = useElevationsRenderUpdateLocationPointsMutation({
 *   variables: {
 *      locItemId: // value for 'locItemId'
 *      pt1: // value for 'pt1'
 *      pt2: // value for 'pt2'
 *      pt3: // value for 'pt3'
 *      pt4: // value for 'pt4'
 *   },
 * });
 */
export function useElevationsRenderUpdateLocationPointsMutation(baseOptions?: Apollo.MutationHookOptions<ElevationsRenderUpdateLocationPointsMutation, ElevationsRenderUpdateLocationPointsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ElevationsRenderUpdateLocationPointsMutation, ElevationsRenderUpdateLocationPointsMutationVariables>(ElevationsRenderUpdateLocationPointsDocument, options);
      }
export type ElevationsRenderUpdateLocationPointsMutationHookResult = ReturnType<typeof useElevationsRenderUpdateLocationPointsMutation>;
export type ElevationsRenderUpdateLocationPointsMutationResult = Apollo.MutationResult<ElevationsRenderUpdateLocationPointsMutation>;
export type ElevationsRenderUpdateLocationPointsMutationOptions = Apollo.BaseMutationOptions<ElevationsRenderUpdateLocationPointsMutation, ElevationsRenderUpdateLocationPointsMutationVariables>;
export const ImageRenderViewDocument = gql`
    query ImageRenderView($esId: String, $hashId: String, $locationId: String, $locationItemId: String, $originalId: Boolean) {
  currentProfile {
    id
    userVirImages {
      id
      externalId
      image
      imageFilename
    }
  }
  locationItem(
    locItemId: $locationItemId
    locId: $locationId
    hashId: $hashId
    originalId: $originalId
  ) {
    id
    ...ImageRenderLocationItemFragment
  }
  getProduct(esId: $esId) {
    id
    ...ProductFragment
  }
}
    ${ImageRenderLocationItemFragmentFragmentDoc}
${ProductFragmentFragmentDoc}`;

/**
 * __useImageRenderViewQuery__
 *
 * To run a query within a React component, call `useImageRenderViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useImageRenderViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImageRenderViewQuery({
 *   variables: {
 *      esId: // value for 'esId'
 *      hashId: // value for 'hashId'
 *      locationId: // value for 'locationId'
 *      locationItemId: // value for 'locationItemId'
 *      originalId: // value for 'originalId'
 *   },
 * });
 */
export function useImageRenderViewQuery(baseOptions?: Apollo.QueryHookOptions<ImageRenderViewQuery, ImageRenderViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ImageRenderViewQuery, ImageRenderViewQueryVariables>(ImageRenderViewDocument, options);
      }
export function useImageRenderViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ImageRenderViewQuery, ImageRenderViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ImageRenderViewQuery, ImageRenderViewQueryVariables>(ImageRenderViewDocument, options);
        }
export type ImageRenderViewQueryHookResult = ReturnType<typeof useImageRenderViewQuery>;
export type ImageRenderViewLazyQueryHookResult = ReturnType<typeof useImageRenderViewLazyQuery>;
export type ImageRenderViewQueryResult = Apollo.QueryResult<ImageRenderViewQuery, ImageRenderViewQueryVariables>;
export const ImageRenderViewUpdateVimrImagesDocument = gql`
    mutation ImageRenderViewUpdateVimrImages($data: String) {
  createUpdateVimrImages(data: $data) {
    status
    formErrors
    autoSubmit
    project {
      id
      vimrImages {
        id
      }
    }
    locationItem {
      id
      vimrImages {
        id
        image
        imageFilename
      }
    }
    bucketItems {
      id
      vimrImages {
        id
        image
      }
    }
  }
}
    `;
export type ImageRenderViewUpdateVimrImagesMutationFn = Apollo.MutationFunction<ImageRenderViewUpdateVimrImagesMutation, ImageRenderViewUpdateVimrImagesMutationVariables>;

/**
 * __useImageRenderViewUpdateVimrImagesMutation__
 *
 * To run a mutation, you first call `useImageRenderViewUpdateVimrImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImageRenderViewUpdateVimrImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [imageRenderViewUpdateVimrImagesMutation, { data, loading, error }] = useImageRenderViewUpdateVimrImagesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useImageRenderViewUpdateVimrImagesMutation(baseOptions?: Apollo.MutationHookOptions<ImageRenderViewUpdateVimrImagesMutation, ImageRenderViewUpdateVimrImagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImageRenderViewUpdateVimrImagesMutation, ImageRenderViewUpdateVimrImagesMutationVariables>(ImageRenderViewUpdateVimrImagesDocument, options);
      }
export type ImageRenderViewUpdateVimrImagesMutationHookResult = ReturnType<typeof useImageRenderViewUpdateVimrImagesMutation>;
export type ImageRenderViewUpdateVimrImagesMutationResult = Apollo.MutationResult<ImageRenderViewUpdateVimrImagesMutation>;
export type ImageRenderViewUpdateVimrImagesMutationOptions = Apollo.BaseMutationOptions<ImageRenderViewUpdateVimrImagesMutation, ImageRenderViewUpdateVimrImagesMutationVariables>;
export const ImageRenderMutationDocument = gql`
    mutation ImageRenderMutation($locItemId: String, $pt1: String, $pt2: String, $pt3: String, $pt4: String) {
  updateLocationItemPoints(
    locItemId: $locItemId
    pt1: $pt1
    pt2: $pt2
    pt3: $pt3
    pt4: $pt4
  ) {
    status
    locationItem {
      id
      ...LocationItemVIMRFragment
    }
  }
}
    ${LocationItemVimrFragmentFragmentDoc}`;
export type ImageRenderMutationMutationFn = Apollo.MutationFunction<ImageRenderMutationMutation, ImageRenderMutationMutationVariables>;

/**
 * __useImageRenderMutationMutation__
 *
 * To run a mutation, you first call `useImageRenderMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImageRenderMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [imageRenderMutationMutation, { data, loading, error }] = useImageRenderMutationMutation({
 *   variables: {
 *      locItemId: // value for 'locItemId'
 *      pt1: // value for 'pt1'
 *      pt2: // value for 'pt2'
 *      pt3: // value for 'pt3'
 *      pt4: // value for 'pt4'
 *   },
 * });
 */
export function useImageRenderMutationMutation(baseOptions?: Apollo.MutationHookOptions<ImageRenderMutationMutation, ImageRenderMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImageRenderMutationMutation, ImageRenderMutationMutationVariables>(ImageRenderMutationDocument, options);
      }
export type ImageRenderMutationMutationHookResult = ReturnType<typeof useImageRenderMutationMutation>;
export type ImageRenderMutationMutationResult = Apollo.MutationResult<ImageRenderMutationMutation>;
export type ImageRenderMutationMutationOptions = Apollo.BaseMutationOptions<ImageRenderMutationMutation, ImageRenderMutationMutationVariables>;
export const ImageRenderViewLocationRotationDocument = gql`
    mutation ImageRenderViewLocationRotation($locItemId: String, $rotate: Int) {
  updateLocationRotation(locItemId: $locItemId, rotate: $rotate) {
    status
    locationItem {
      id
      ...LocationItemFragment
    }
  }
}
    ${LocationItemFragmentFragmentDoc}`;
export type ImageRenderViewLocationRotationMutationFn = Apollo.MutationFunction<ImageRenderViewLocationRotationMutation, ImageRenderViewLocationRotationMutationVariables>;

/**
 * __useImageRenderViewLocationRotationMutation__
 *
 * To run a mutation, you first call `useImageRenderViewLocationRotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImageRenderViewLocationRotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [imageRenderViewLocationRotationMutation, { data, loading, error }] = useImageRenderViewLocationRotationMutation({
 *   variables: {
 *      locItemId: // value for 'locItemId'
 *      rotate: // value for 'rotate'
 *   },
 * });
 */
export function useImageRenderViewLocationRotationMutation(baseOptions?: Apollo.MutationHookOptions<ImageRenderViewLocationRotationMutation, ImageRenderViewLocationRotationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImageRenderViewLocationRotationMutation, ImageRenderViewLocationRotationMutationVariables>(ImageRenderViewLocationRotationDocument, options);
      }
export type ImageRenderViewLocationRotationMutationHookResult = ReturnType<typeof useImageRenderViewLocationRotationMutation>;
export type ImageRenderViewLocationRotationMutationResult = Apollo.MutationResult<ImageRenderViewLocationRotationMutation>;
export type ImageRenderViewLocationRotationMutationOptions = Apollo.BaseMutationOptions<ImageRenderViewLocationRotationMutation, ImageRenderViewLocationRotationMutationVariables>;
export const SearchResultsGridDocument = gql`
    mutation SearchResultsGrid {
  setJoyrideSearchView {
    status
    currentProfile {
      id
      ...CurrentProfileFragment
    }
  }
}
    ${CurrentProfileFragmentFragmentDoc}`;
export type SearchResultsGridMutationFn = Apollo.MutationFunction<SearchResultsGridMutation, SearchResultsGridMutationVariables>;

/**
 * __useSearchResultsGridMutation__
 *
 * To run a mutation, you first call `useSearchResultsGridMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSearchResultsGridMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [searchResultsGridMutation, { data, loading, error }] = useSearchResultsGridMutation({
 *   variables: {
 *   },
 * });
 */
export function useSearchResultsGridMutation(baseOptions?: Apollo.MutationHookOptions<SearchResultsGridMutation, SearchResultsGridMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SearchResultsGridMutation, SearchResultsGridMutationVariables>(SearchResultsGridDocument, options);
      }
export type SearchResultsGridMutationHookResult = ReturnType<typeof useSearchResultsGridMutation>;
export type SearchResultsGridMutationResult = Apollo.MutationResult<SearchResultsGridMutation>;
export type SearchResultsGridMutationOptions = Apollo.BaseMutationOptions<SearchResultsGridMutation, SearchResultsGridMutationVariables>;
export const SimilarImageDocument = gql`
    query SimilarImage($queryString: String) {
  getSimilarImage(queryString: $queryString)
}
    `;

/**
 * __useSimilarImageQuery__
 *
 * To run a query within a React component, call `useSimilarImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimilarImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimilarImageQuery({
 *   variables: {
 *      queryString: // value for 'queryString'
 *   },
 * });
 */
export function useSimilarImageQuery(baseOptions?: Apollo.QueryHookOptions<SimilarImageQuery, SimilarImageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SimilarImageQuery, SimilarImageQueryVariables>(SimilarImageDocument, options);
      }
export function useSimilarImageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SimilarImageQuery, SimilarImageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SimilarImageQuery, SimilarImageQueryVariables>(SimilarImageDocument, options);
        }
export type SimilarImageQueryHookResult = ReturnType<typeof useSimilarImageQuery>;
export type SimilarImageLazyQueryHookResult = ReturnType<typeof useSimilarImageLazyQuery>;
export type SimilarImageQueryResult = Apollo.QueryResult<SimilarImageQuery, SimilarImageQueryVariables>;
export const ProductFiltersDocument = gql`
    query ProductFilters($locDetails: String) {
  getFilters(locDetails: $locDetails)
}
    `;

/**
 * __useProductFiltersQuery__
 *
 * To run a query within a React component, call `useProductFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductFiltersQuery({
 *   variables: {
 *      locDetails: // value for 'locDetails'
 *   },
 * });
 */
export function useProductFiltersQuery(baseOptions?: Apollo.QueryHookOptions<ProductFiltersQuery, ProductFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductFiltersQuery, ProductFiltersQueryVariables>(ProductFiltersDocument, options);
      }
export function useProductFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductFiltersQuery, ProductFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductFiltersQuery, ProductFiltersQueryVariables>(ProductFiltersDocument, options);
        }
export type ProductFiltersQueryHookResult = ReturnType<typeof useProductFiltersQuery>;
export type ProductFiltersLazyQueryHookResult = ReturnType<typeof useProductFiltersLazyQuery>;
export type ProductFiltersQueryResult = Apollo.QueryResult<ProductFiltersQuery, ProductFiltersQueryVariables>;
export const NewsletterSignupFormDocument = gql`
    mutation NewsletterSignupForm($email: String) {
  newsletterSignup(email: $email) {
    formErrors
    status
  }
}
    `;
export type NewsletterSignupFormMutationFn = Apollo.MutationFunction<NewsletterSignupFormMutation, NewsletterSignupFormMutationVariables>;

/**
 * __useNewsletterSignupFormMutation__
 *
 * To run a mutation, you first call `useNewsletterSignupFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewsletterSignupFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newsletterSignupFormMutation, { data, loading, error }] = useNewsletterSignupFormMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useNewsletterSignupFormMutation(baseOptions?: Apollo.MutationHookOptions<NewsletterSignupFormMutation, NewsletterSignupFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewsletterSignupFormMutation, NewsletterSignupFormMutationVariables>(NewsletterSignupFormDocument, options);
      }
export type NewsletterSignupFormMutationHookResult = ReturnType<typeof useNewsletterSignupFormMutation>;
export type NewsletterSignupFormMutationResult = Apollo.MutationResult<NewsletterSignupFormMutation>;
export type NewsletterSignupFormMutationOptions = Apollo.BaseMutationOptions<NewsletterSignupFormMutation, NewsletterSignupFormMutationVariables>;
export const AddArtistVariantFormArtistVariantUpdateDocument = gql`
    mutation AddArtistVariantFormArtistVariantUpdate($data: String) {
  artistVariantUpdateCreate(data: $data) {
    status
    formErrors
    artistVariant {
      id
      artist {
        id
        name
      }
      galleries {
        id
        name
      }
      medium {
        id
        title
      }
      artworkStyle {
        id
        title
      }
      productImages {
        id
        esId
        imageKey
      }
      tags
      priceLow
      priceHigh
      priceRange
      dataRequestDate
      notes
    }
  }
}
    `;
export type AddArtistVariantFormArtistVariantUpdateMutationFn = Apollo.MutationFunction<AddArtistVariantFormArtistVariantUpdateMutation, AddArtistVariantFormArtistVariantUpdateMutationVariables>;

/**
 * __useAddArtistVariantFormArtistVariantUpdateMutation__
 *
 * To run a mutation, you first call `useAddArtistVariantFormArtistVariantUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddArtistVariantFormArtistVariantUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addArtistVariantFormArtistVariantUpdateMutation, { data, loading, error }] = useAddArtistVariantFormArtistVariantUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddArtistVariantFormArtistVariantUpdateMutation(baseOptions?: Apollo.MutationHookOptions<AddArtistVariantFormArtistVariantUpdateMutation, AddArtistVariantFormArtistVariantUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddArtistVariantFormArtistVariantUpdateMutation, AddArtistVariantFormArtistVariantUpdateMutationVariables>(AddArtistVariantFormArtistVariantUpdateDocument, options);
      }
export type AddArtistVariantFormArtistVariantUpdateMutationHookResult = ReturnType<typeof useAddArtistVariantFormArtistVariantUpdateMutation>;
export type AddArtistVariantFormArtistVariantUpdateMutationResult = Apollo.MutationResult<AddArtistVariantFormArtistVariantUpdateMutation>;
export type AddArtistVariantFormArtistVariantUpdateMutationOptions = Apollo.BaseMutationOptions<AddArtistVariantFormArtistVariantUpdateMutation, AddArtistVariantFormArtistVariantUpdateMutationVariables>;
export const AddArtistVariantFormArtistVariantProjectDeleteDocument = gql`
    mutation AddArtistVariantFormArtistVariantProjectDelete($data: String) {
  artistVariantProjectDelete(data: $data) {
    status
    artistVariant {
      id
      projects {
        id
        ...ArtistVariantProjectFragment
      }
    }
  }
}
    ${ArtistVariantProjectFragmentFragmentDoc}`;
export type AddArtistVariantFormArtistVariantProjectDeleteMutationFn = Apollo.MutationFunction<AddArtistVariantFormArtistVariantProjectDeleteMutation, AddArtistVariantFormArtistVariantProjectDeleteMutationVariables>;

/**
 * __useAddArtistVariantFormArtistVariantProjectDeleteMutation__
 *
 * To run a mutation, you first call `useAddArtistVariantFormArtistVariantProjectDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddArtistVariantFormArtistVariantProjectDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addArtistVariantFormArtistVariantProjectDeleteMutation, { data, loading, error }] = useAddArtistVariantFormArtistVariantProjectDeleteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddArtistVariantFormArtistVariantProjectDeleteMutation(baseOptions?: Apollo.MutationHookOptions<AddArtistVariantFormArtistVariantProjectDeleteMutation, AddArtistVariantFormArtistVariantProjectDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddArtistVariantFormArtistVariantProjectDeleteMutation, AddArtistVariantFormArtistVariantProjectDeleteMutationVariables>(AddArtistVariantFormArtistVariantProjectDeleteDocument, options);
      }
export type AddArtistVariantFormArtistVariantProjectDeleteMutationHookResult = ReturnType<typeof useAddArtistVariantFormArtistVariantProjectDeleteMutation>;
export type AddArtistVariantFormArtistVariantProjectDeleteMutationResult = Apollo.MutationResult<AddArtistVariantFormArtistVariantProjectDeleteMutation>;
export type AddArtistVariantFormArtistVariantProjectDeleteMutationOptions = Apollo.BaseMutationOptions<AddArtistVariantFormArtistVariantProjectDeleteMutation, AddArtistVariantFormArtistVariantProjectDeleteMutationVariables>;
export const AddCroppedImageCachedFormDocument = gql`
    mutation AddCroppedImageCachedForm($data: String) {
  addCroppedImageCache(data: $data) {
    status
    croppedImg {
      id
      croppedKey
      key
      imageHash
    }
  }
}
    `;
export type AddCroppedImageCachedFormMutationFn = Apollo.MutationFunction<AddCroppedImageCachedFormMutation, AddCroppedImageCachedFormMutationVariables>;

/**
 * __useAddCroppedImageCachedFormMutation__
 *
 * To run a mutation, you first call `useAddCroppedImageCachedFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCroppedImageCachedFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCroppedImageCachedFormMutation, { data, loading, error }] = useAddCroppedImageCachedFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddCroppedImageCachedFormMutation(baseOptions?: Apollo.MutationHookOptions<AddCroppedImageCachedFormMutation, AddCroppedImageCachedFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCroppedImageCachedFormMutation, AddCroppedImageCachedFormMutationVariables>(AddCroppedImageCachedFormDocument, options);
      }
export type AddCroppedImageCachedFormMutationHookResult = ReturnType<typeof useAddCroppedImageCachedFormMutation>;
export type AddCroppedImageCachedFormMutationResult = Apollo.MutationResult<AddCroppedImageCachedFormMutation>;
export type AddCroppedImageCachedFormMutationOptions = Apollo.BaseMutationOptions<AddCroppedImageCachedFormMutation, AddCroppedImageCachedFormMutationVariables>;
export const AddSourcedProductDocument = gql`
    mutation AddSourcedProduct($data: String) {
  sourcedProductUpdateCreate(data: $data) {
    status
    formErrors
    project {
      id
      bucketItemsCount
      ...UserWizardProjectLocationItemFragment
    }
  }
}
    ${UserWizardProjectLocationItemFragmentFragmentDoc}`;
export type AddSourcedProductMutationFn = Apollo.MutationFunction<AddSourcedProductMutation, AddSourcedProductMutationVariables>;

/**
 * __useAddSourcedProductMutation__
 *
 * To run a mutation, you first call `useAddSourcedProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSourcedProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSourcedProductMutation, { data, loading, error }] = useAddSourcedProductMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddSourcedProductMutation(baseOptions?: Apollo.MutationHookOptions<AddSourcedProductMutation, AddSourcedProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSourcedProductMutation, AddSourcedProductMutationVariables>(AddSourcedProductDocument, options);
      }
export type AddSourcedProductMutationHookResult = ReturnType<typeof useAddSourcedProductMutation>;
export type AddSourcedProductMutationResult = Apollo.MutationResult<AddSourcedProductMutation>;
export type AddSourcedProductMutationOptions = Apollo.BaseMutationOptions<AddSourcedProductMutation, AddSourcedProductMutationVariables>;
export const ArtistTypesenseDocument = gql`
    query ArtistTypesense($search: String) {
  artistsTypesense(search: $search) {
    artistName
    artistSlug
    artistContactEmail
    artistYearOfBirth
    artistCountry
    artistWebsite
  }
}
    `;

/**
 * __useArtistTypesenseQuery__
 *
 * To run a query within a React component, call `useArtistTypesenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtistTypesenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtistTypesenseQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useArtistTypesenseQuery(baseOptions?: Apollo.QueryHookOptions<ArtistTypesenseQuery, ArtistTypesenseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArtistTypesenseQuery, ArtistTypesenseQueryVariables>(ArtistTypesenseDocument, options);
      }
export function useArtistTypesenseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArtistTypesenseQuery, ArtistTypesenseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArtistTypesenseQuery, ArtistTypesenseQueryVariables>(ArtistTypesenseDocument, options);
        }
export type ArtistTypesenseQueryHookResult = ReturnType<typeof useArtistTypesenseQuery>;
export type ArtistTypesenseLazyQueryHookResult = ReturnType<typeof useArtistTypesenseLazyQuery>;
export type ArtistTypesenseQueryResult = Apollo.QueryResult<ArtistTypesenseQuery, ArtistTypesenseQueryVariables>;
export const GalleryTypesenseDocument = gql`
    query GalleryTypesense($search: String) {
  galleriesTypesense(search: $search) {
    galleryName
    gallerySlug
    galleryContactEmail
    galleryContactNumber
    galleryWebsite
  }
}
    `;

/**
 * __useGalleryTypesenseQuery__
 *
 * To run a query within a React component, call `useGalleryTypesenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGalleryTypesenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGalleryTypesenseQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGalleryTypesenseQuery(baseOptions?: Apollo.QueryHookOptions<GalleryTypesenseQuery, GalleryTypesenseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GalleryTypesenseQuery, GalleryTypesenseQueryVariables>(GalleryTypesenseDocument, options);
      }
export function useGalleryTypesenseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GalleryTypesenseQuery, GalleryTypesenseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GalleryTypesenseQuery, GalleryTypesenseQueryVariables>(GalleryTypesenseDocument, options);
        }
export type GalleryTypesenseQueryHookResult = ReturnType<typeof useGalleryTypesenseQuery>;
export type GalleryTypesenseLazyQueryHookResult = ReturnType<typeof useGalleryTypesenseLazyQuery>;
export type GalleryTypesenseQueryResult = Apollo.QueryResult<GalleryTypesenseQuery, GalleryTypesenseQueryVariables>;
export const AddToExistingShortlistFormDocument = gql`
    query AddToExistingShortlistForm {
  projects {
    projects {
      hashId
      title
      locations {
        externalId
        titleStr
        locationItems {
          externalId
          titleStr
        }
      }
    }
  }
}
    `;

/**
 * __useAddToExistingShortlistFormQuery__
 *
 * To run a query within a React component, call `useAddToExistingShortlistFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddToExistingShortlistFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddToExistingShortlistFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useAddToExistingShortlistFormQuery(baseOptions?: Apollo.QueryHookOptions<AddToExistingShortlistFormQuery, AddToExistingShortlistFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddToExistingShortlistFormQuery, AddToExistingShortlistFormQueryVariables>(AddToExistingShortlistFormDocument, options);
      }
export function useAddToExistingShortlistFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddToExistingShortlistFormQuery, AddToExistingShortlistFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddToExistingShortlistFormQuery, AddToExistingShortlistFormQueryVariables>(AddToExistingShortlistFormDocument, options);
        }
export type AddToExistingShortlistFormQueryHookResult = ReturnType<typeof useAddToExistingShortlistFormQuery>;
export type AddToExistingShortlistFormLazyQueryHookResult = ReturnType<typeof useAddToExistingShortlistFormLazyQuery>;
export type AddToExistingShortlistFormQueryResult = Apollo.QueryResult<AddToExistingShortlistFormQuery, AddToExistingShortlistFormQueryVariables>;
export const AddToNewShortlistFormDocument = gql`
    mutation AddToNewShortlistForm($data: String) {
  createQuickSource(data: $data) {
    status
    formErrors
    project {
      id
    }
  }
}
    `;
export type AddToNewShortlistFormMutationFn = Apollo.MutationFunction<AddToNewShortlistFormMutation, AddToNewShortlistFormMutationVariables>;

/**
 * __useAddToNewShortlistFormMutation__
 *
 * To run a mutation, you first call `useAddToNewShortlistFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToNewShortlistFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToNewShortlistFormMutation, { data, loading, error }] = useAddToNewShortlistFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddToNewShortlistFormMutation(baseOptions?: Apollo.MutationHookOptions<AddToNewShortlistFormMutation, AddToNewShortlistFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddToNewShortlistFormMutation, AddToNewShortlistFormMutationVariables>(AddToNewShortlistFormDocument, options);
      }
export type AddToNewShortlistFormMutationHookResult = ReturnType<typeof useAddToNewShortlistFormMutation>;
export type AddToNewShortlistFormMutationResult = Apollo.MutationResult<AddToNewShortlistFormMutation>;
export type AddToNewShortlistFormMutationOptions = Apollo.BaseMutationOptions<AddToNewShortlistFormMutation, AddToNewShortlistFormMutationVariables>;
export const ArtistVariantProjectFormDocument = gql`
    mutation ArtistVariantProjectForm($data: String) {
  artistVariantProjectCreateUpdate(data: $data) {
    status
    formErrors
    artistVariant {
      id
      projects {
        id
        ...ArtistVariantProjectFragment
      }
    }
  }
}
    ${ArtistVariantProjectFragmentFragmentDoc}`;
export type ArtistVariantProjectFormMutationFn = Apollo.MutationFunction<ArtistVariantProjectFormMutation, ArtistVariantProjectFormMutationVariables>;

/**
 * __useArtistVariantProjectFormMutation__
 *
 * To run a mutation, you first call `useArtistVariantProjectFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArtistVariantProjectFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [artistVariantProjectFormMutation, { data, loading, error }] = useArtistVariantProjectFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useArtistVariantProjectFormMutation(baseOptions?: Apollo.MutationHookOptions<ArtistVariantProjectFormMutation, ArtistVariantProjectFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArtistVariantProjectFormMutation, ArtistVariantProjectFormMutationVariables>(ArtistVariantProjectFormDocument, options);
      }
export type ArtistVariantProjectFormMutationHookResult = ReturnType<typeof useArtistVariantProjectFormMutation>;
export type ArtistVariantProjectFormMutationResult = Apollo.MutationResult<ArtistVariantProjectFormMutation>;
export type ArtistVariantProjectFormMutationOptions = Apollo.BaseMutationOptions<ArtistVariantProjectFormMutation, ArtistVariantProjectFormMutationVariables>;
export const BucketItemDetailsFormDocument = gql`
    mutation BucketItemDetailsForm($data: String) {
  updateBucketItemDetails(data: $data) {
    status
    formErrors
    bucketItem {
      id
      bucketItem {
        id
        ...BucketItemDetailsFragment
      }
      copyPasteText
    }
  }
}
    ${BucketItemDetailsFragmentFragmentDoc}`;
export type BucketItemDetailsFormMutationFn = Apollo.MutationFunction<BucketItemDetailsFormMutation, BucketItemDetailsFormMutationVariables>;

/**
 * __useBucketItemDetailsFormMutation__
 *
 * To run a mutation, you first call `useBucketItemDetailsFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBucketItemDetailsFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bucketItemDetailsFormMutation, { data, loading, error }] = useBucketItemDetailsFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useBucketItemDetailsFormMutation(baseOptions?: Apollo.MutationHookOptions<BucketItemDetailsFormMutation, BucketItemDetailsFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BucketItemDetailsFormMutation, BucketItemDetailsFormMutationVariables>(BucketItemDetailsFormDocument, options);
      }
export type BucketItemDetailsFormMutationHookResult = ReturnType<typeof useBucketItemDetailsFormMutation>;
export type BucketItemDetailsFormMutationResult = Apollo.MutationResult<BucketItemDetailsFormMutation>;
export type BucketItemDetailsFormMutationOptions = Apollo.BaseMutationOptions<BucketItemDetailsFormMutation, BucketItemDetailsFormMutationVariables>;
export const CreateOrUpdateFramerFormDocument = gql`
    mutation CreateOrUpdateFramerForm($data: String) {
  createOrUpdateFramerMutation(data: $data) {
    status
    formErrors
    framer {
      id
      ...FramerRowFragment
    }
  }
}
    ${FramerRowFragmentFragmentDoc}`;
export type CreateOrUpdateFramerFormMutationFn = Apollo.MutationFunction<CreateOrUpdateFramerFormMutation, CreateOrUpdateFramerFormMutationVariables>;

/**
 * __useCreateOrUpdateFramerFormMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateFramerFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateFramerFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateFramerFormMutation, { data, loading, error }] = useCreateOrUpdateFramerFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateOrUpdateFramerFormMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateFramerFormMutation, CreateOrUpdateFramerFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateFramerFormMutation, CreateOrUpdateFramerFormMutationVariables>(CreateOrUpdateFramerFormDocument, options);
      }
export type CreateOrUpdateFramerFormMutationHookResult = ReturnType<typeof useCreateOrUpdateFramerFormMutation>;
export type CreateOrUpdateFramerFormMutationResult = Apollo.MutationResult<CreateOrUpdateFramerFormMutation>;
export type CreateOrUpdateFramerFormMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateFramerFormMutation, CreateOrUpdateFramerFormMutationVariables>;
export const DemoFormDocument = gql`
    mutation DemoForm($data: String) {
  demoArtistUpdate(data: $data) {
    status
    formErrors
    result {
      id
      name
    }
  }
}
    `;
export type DemoFormMutationFn = Apollo.MutationFunction<DemoFormMutation, DemoFormMutationVariables>;

/**
 * __useDemoFormMutation__
 *
 * To run a mutation, you first call `useDemoFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDemoFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [demoFormMutation, { data, loading, error }] = useDemoFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDemoFormMutation(baseOptions?: Apollo.MutationHookOptions<DemoFormMutation, DemoFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DemoFormMutation, DemoFormMutationVariables>(DemoFormDocument, options);
      }
export type DemoFormMutationHookResult = ReturnType<typeof useDemoFormMutation>;
export type DemoFormMutationResult = Apollo.MutationResult<DemoFormMutation>;
export type DemoFormMutationOptions = Apollo.BaseMutationOptions<DemoFormMutation, DemoFormMutationVariables>;
export const ImageRenderFormDocument = gql`
    mutation ImageRenderForm($data: String) {
  updateLocationItemVimrWall(data: $data) {
    status
    formErrors
    locationItem {
      id
      ...LocationItemFragment
    }
  }
}
    ${LocationItemFragmentFragmentDoc}`;
export type ImageRenderFormMutationFn = Apollo.MutationFunction<ImageRenderFormMutation, ImageRenderFormMutationVariables>;

/**
 * __useImageRenderFormMutation__
 *
 * To run a mutation, you first call `useImageRenderFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImageRenderFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [imageRenderFormMutation, { data, loading, error }] = useImageRenderFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useImageRenderFormMutation(baseOptions?: Apollo.MutationHookOptions<ImageRenderFormMutation, ImageRenderFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImageRenderFormMutation, ImageRenderFormMutationVariables>(ImageRenderFormDocument, options);
      }
export type ImageRenderFormMutationHookResult = ReturnType<typeof useImageRenderFormMutation>;
export type ImageRenderFormMutationResult = Apollo.MutationResult<ImageRenderFormMutation>;
export type ImageRenderFormMutationOptions = Apollo.BaseMutationOptions<ImageRenderFormMutation, ImageRenderFormMutationVariables>;
export const FloorplanPaperFormDocument = gql`
    mutation FloorplanPaperForm($data: String) {
  updateFloorplanScale(data: $data) {
    status
    formErrors
    floorplan {
      id
      image
      editsJson
      measurementUnit
      shownMeasurementUnit
      paperSize
      scaleMeasurement
      unitRatio
      measureType
    }
  }
}
    `;
export type FloorplanPaperFormMutationFn = Apollo.MutationFunction<FloorplanPaperFormMutation, FloorplanPaperFormMutationVariables>;

/**
 * __useFloorplanPaperFormMutation__
 *
 * To run a mutation, you first call `useFloorplanPaperFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFloorplanPaperFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [floorplanPaperFormMutation, { data, loading, error }] = useFloorplanPaperFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useFloorplanPaperFormMutation(baseOptions?: Apollo.MutationHookOptions<FloorplanPaperFormMutation, FloorplanPaperFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FloorplanPaperFormMutation, FloorplanPaperFormMutationVariables>(FloorplanPaperFormDocument, options);
      }
export type FloorplanPaperFormMutationHookResult = ReturnType<typeof useFloorplanPaperFormMutation>;
export type FloorplanPaperFormMutationResult = Apollo.MutationResult<FloorplanPaperFormMutation>;
export type FloorplanPaperFormMutationOptions = Apollo.BaseMutationOptions<FloorplanPaperFormMutation, FloorplanPaperFormMutationVariables>;
export const FlowItemFramerInfoFormDocument = gql`
    mutation FlowItemFramerInfoForm($data: String) {
  wfNwStaffUpdateFlowItemFramerInfo(data: $data) {
    status
    formErrors
    flowItem {
      id
      ...FlowItemFramerInfoFragment
    }
  }
}
    ${FlowItemFramerInfoFragmentFragmentDoc}`;
export type FlowItemFramerInfoFormMutationFn = Apollo.MutationFunction<FlowItemFramerInfoFormMutation, FlowItemFramerInfoFormMutationVariables>;

/**
 * __useFlowItemFramerInfoFormMutation__
 *
 * To run a mutation, you first call `useFlowItemFramerInfoFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFlowItemFramerInfoFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [flowItemFramerInfoFormMutation, { data, loading, error }] = useFlowItemFramerInfoFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useFlowItemFramerInfoFormMutation(baseOptions?: Apollo.MutationHookOptions<FlowItemFramerInfoFormMutation, FlowItemFramerInfoFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FlowItemFramerInfoFormMutation, FlowItemFramerInfoFormMutationVariables>(FlowItemFramerInfoFormDocument, options);
      }
export type FlowItemFramerInfoFormMutationHookResult = ReturnType<typeof useFlowItemFramerInfoFormMutation>;
export type FlowItemFramerInfoFormMutationResult = Apollo.MutationResult<FlowItemFramerInfoFormMutation>;
export type FlowItemFramerInfoFormMutationOptions = Apollo.BaseMutationOptions<FlowItemFramerInfoFormMutation, FlowItemFramerInfoFormMutationVariables>;
export const ForgotPasswordFormDocument = gql`
    mutation ForgotPasswordForm($data: String) {
  forgotPassword(data: $data) {
    status
    formErrors
  }
}
    `;
export type ForgotPasswordFormMutationFn = Apollo.MutationFunction<ForgotPasswordFormMutation, ForgotPasswordFormMutationVariables>;

/**
 * __useForgotPasswordFormMutation__
 *
 * To run a mutation, you first call `useForgotPasswordFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordFormMutation, { data, loading, error }] = useForgotPasswordFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useForgotPasswordFormMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordFormMutation, ForgotPasswordFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordFormMutation, ForgotPasswordFormMutationVariables>(ForgotPasswordFormDocument, options);
      }
export type ForgotPasswordFormMutationHookResult = ReturnType<typeof useForgotPasswordFormMutation>;
export type ForgotPasswordFormMutationResult = Apollo.MutationResult<ForgotPasswordFormMutation>;
export type ForgotPasswordFormMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordFormMutation, ForgotPasswordFormMutationVariables>;
export const FramingToolUpdateDocument = gql`
    mutation FramingToolUpdate($data: String) {
  savedFrameUpdateCreate(data: $data) {
    status
    formErrors
    savedFrame {
      id
    }
  }
}
    `;
export type FramingToolUpdateMutationFn = Apollo.MutationFunction<FramingToolUpdateMutation, FramingToolUpdateMutationVariables>;

/**
 * __useFramingToolUpdateMutation__
 *
 * To run a mutation, you first call `useFramingToolUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFramingToolUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [framingToolUpdateMutation, { data, loading, error }] = useFramingToolUpdateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useFramingToolUpdateMutation(baseOptions?: Apollo.MutationHookOptions<FramingToolUpdateMutation, FramingToolUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FramingToolUpdateMutation, FramingToolUpdateMutationVariables>(FramingToolUpdateDocument, options);
      }
export type FramingToolUpdateMutationHookResult = ReturnType<typeof useFramingToolUpdateMutation>;
export type FramingToolUpdateMutationResult = Apollo.MutationResult<FramingToolUpdateMutation>;
export type FramingToolUpdateMutationOptions = Apollo.BaseMutationOptions<FramingToolUpdateMutation, FramingToolUpdateMutationVariables>;
export const LoginFormDocument = gql`
    mutation LoginForm($data: String) {
  login(data: $data) {
    status
    formErrors
  }
}
    `;
export type LoginFormMutationFn = Apollo.MutationFunction<LoginFormMutation, LoginFormMutationVariables>;

/**
 * __useLoginFormMutation__
 *
 * To run a mutation, you first call `useLoginFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginFormMutation, { data, loading, error }] = useLoginFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLoginFormMutation(baseOptions?: Apollo.MutationHookOptions<LoginFormMutation, LoginFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginFormMutation, LoginFormMutationVariables>(LoginFormDocument, options);
      }
export type LoginFormMutationHookResult = ReturnType<typeof useLoginFormMutation>;
export type LoginFormMutationResult = Apollo.MutationResult<LoginFormMutation>;
export type LoginFormMutationOptions = Apollo.BaseMutationOptions<LoginFormMutation, LoginFormMutationVariables>;
export const NewInquiryFormDocument = gql`
    query NewInquiryForm {
  inquiryTypes {
    id
    title
  }
}
    `;

/**
 * __useNewInquiryFormQuery__
 *
 * To run a query within a React component, call `useNewInquiryFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewInquiryFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewInquiryFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useNewInquiryFormQuery(baseOptions?: Apollo.QueryHookOptions<NewInquiryFormQuery, NewInquiryFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewInquiryFormQuery, NewInquiryFormQueryVariables>(NewInquiryFormDocument, options);
      }
export function useNewInquiryFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewInquiryFormQuery, NewInquiryFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewInquiryFormQuery, NewInquiryFormQueryVariables>(NewInquiryFormDocument, options);
        }
export type NewInquiryFormQueryHookResult = ReturnType<typeof useNewInquiryFormQuery>;
export type NewInquiryFormLazyQueryHookResult = ReturnType<typeof useNewInquiryFormLazyQuery>;
export type NewInquiryFormQueryResult = Apollo.QueryResult<NewInquiryFormQuery, NewInquiryFormQueryVariables>;
export const NewInquiryFormNewInquiryDocument = gql`
    mutation NewInquiryFormNewInquiry($data: String) {
  newInquiry(data: $data) {
    status
    formErrors
  }
}
    `;
export type NewInquiryFormNewInquiryMutationFn = Apollo.MutationFunction<NewInquiryFormNewInquiryMutation, NewInquiryFormNewInquiryMutationVariables>;

/**
 * __useNewInquiryFormNewInquiryMutation__
 *
 * To run a mutation, you first call `useNewInquiryFormNewInquiryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewInquiryFormNewInquiryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newInquiryFormNewInquiryMutation, { data, loading, error }] = useNewInquiryFormNewInquiryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNewInquiryFormNewInquiryMutation(baseOptions?: Apollo.MutationHookOptions<NewInquiryFormNewInquiryMutation, NewInquiryFormNewInquiryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewInquiryFormNewInquiryMutation, NewInquiryFormNewInquiryMutationVariables>(NewInquiryFormNewInquiryDocument, options);
      }
export type NewInquiryFormNewInquiryMutationHookResult = ReturnType<typeof useNewInquiryFormNewInquiryMutation>;
export type NewInquiryFormNewInquiryMutationResult = Apollo.MutationResult<NewInquiryFormNewInquiryMutation>;
export type NewInquiryFormNewInquiryMutationOptions = Apollo.BaseMutationOptions<NewInquiryFormNewInquiryMutation, NewInquiryFormNewInquiryMutationVariables>;
export const PdfActivePagesFormUpdateProposalActivePagesDocument = gql`
    mutation PDFActivePagesFormUpdateProposalActivePages($data: String) {
  updateProposalActivePages(data: $data) {
    status
    proposal {
      id
      pages {
        id
        isActive
      }
    }
  }
}
    `;
export type PdfActivePagesFormUpdateProposalActivePagesMutationFn = Apollo.MutationFunction<PdfActivePagesFormUpdateProposalActivePagesMutation, PdfActivePagesFormUpdateProposalActivePagesMutationVariables>;

/**
 * __usePdfActivePagesFormUpdateProposalActivePagesMutation__
 *
 * To run a mutation, you first call `usePdfActivePagesFormUpdateProposalActivePagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePdfActivePagesFormUpdateProposalActivePagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pdfActivePagesFormUpdateProposalActivePagesMutation, { data, loading, error }] = usePdfActivePagesFormUpdateProposalActivePagesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePdfActivePagesFormUpdateProposalActivePagesMutation(baseOptions?: Apollo.MutationHookOptions<PdfActivePagesFormUpdateProposalActivePagesMutation, PdfActivePagesFormUpdateProposalActivePagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PdfActivePagesFormUpdateProposalActivePagesMutation, PdfActivePagesFormUpdateProposalActivePagesMutationVariables>(PdfActivePagesFormUpdateProposalActivePagesDocument, options);
      }
export type PdfActivePagesFormUpdateProposalActivePagesMutationHookResult = ReturnType<typeof usePdfActivePagesFormUpdateProposalActivePagesMutation>;
export type PdfActivePagesFormUpdateProposalActivePagesMutationResult = Apollo.MutationResult<PdfActivePagesFormUpdateProposalActivePagesMutation>;
export type PdfActivePagesFormUpdateProposalActivePagesMutationOptions = Apollo.BaseMutationOptions<PdfActivePagesFormUpdateProposalActivePagesMutation, PdfActivePagesFormUpdateProposalActivePagesMutationVariables>;
export const PdfActivePagesFormUpdateProposalPagesPositionDocument = gql`
    mutation PDFActivePagesFormUpdateProposalPagesPosition($data: String) {
  updateProposalPagesPosition(data: $data) {
    status
    proposal {
      id
      pages {
        id
        isActive
      }
    }
  }
}
    `;
export type PdfActivePagesFormUpdateProposalPagesPositionMutationFn = Apollo.MutationFunction<PdfActivePagesFormUpdateProposalPagesPositionMutation, PdfActivePagesFormUpdateProposalPagesPositionMutationVariables>;

/**
 * __usePdfActivePagesFormUpdateProposalPagesPositionMutation__
 *
 * To run a mutation, you first call `usePdfActivePagesFormUpdateProposalPagesPositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePdfActivePagesFormUpdateProposalPagesPositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pdfActivePagesFormUpdateProposalPagesPositionMutation, { data, loading, error }] = usePdfActivePagesFormUpdateProposalPagesPositionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePdfActivePagesFormUpdateProposalPagesPositionMutation(baseOptions?: Apollo.MutationHookOptions<PdfActivePagesFormUpdateProposalPagesPositionMutation, PdfActivePagesFormUpdateProposalPagesPositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PdfActivePagesFormUpdateProposalPagesPositionMutation, PdfActivePagesFormUpdateProposalPagesPositionMutationVariables>(PdfActivePagesFormUpdateProposalPagesPositionDocument, options);
      }
export type PdfActivePagesFormUpdateProposalPagesPositionMutationHookResult = ReturnType<typeof usePdfActivePagesFormUpdateProposalPagesPositionMutation>;
export type PdfActivePagesFormUpdateProposalPagesPositionMutationResult = Apollo.MutationResult<PdfActivePagesFormUpdateProposalPagesPositionMutation>;
export type PdfActivePagesFormUpdateProposalPagesPositionMutationOptions = Apollo.BaseMutationOptions<PdfActivePagesFormUpdateProposalPagesPositionMutation, PdfActivePagesFormUpdateProposalPagesPositionMutationVariables>;
export const PdfBucketItemDetailsFormUpdatePageDetailDocument = gql`
    mutation PDFBucketItemDetailsFormUpdatePageDetail($data: String) {
  updatePageDetail(data: $data) {
    status
    formErrors
    page {
      id
      pdfDetails
    }
  }
}
    `;
export type PdfBucketItemDetailsFormUpdatePageDetailMutationFn = Apollo.MutationFunction<PdfBucketItemDetailsFormUpdatePageDetailMutation, PdfBucketItemDetailsFormUpdatePageDetailMutationVariables>;

/**
 * __usePdfBucketItemDetailsFormUpdatePageDetailMutation__
 *
 * To run a mutation, you first call `usePdfBucketItemDetailsFormUpdatePageDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePdfBucketItemDetailsFormUpdatePageDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pdfBucketItemDetailsFormUpdatePageDetailMutation, { data, loading, error }] = usePdfBucketItemDetailsFormUpdatePageDetailMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePdfBucketItemDetailsFormUpdatePageDetailMutation(baseOptions?: Apollo.MutationHookOptions<PdfBucketItemDetailsFormUpdatePageDetailMutation, PdfBucketItemDetailsFormUpdatePageDetailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PdfBucketItemDetailsFormUpdatePageDetailMutation, PdfBucketItemDetailsFormUpdatePageDetailMutationVariables>(PdfBucketItemDetailsFormUpdatePageDetailDocument, options);
      }
export type PdfBucketItemDetailsFormUpdatePageDetailMutationHookResult = ReturnType<typeof usePdfBucketItemDetailsFormUpdatePageDetailMutation>;
export type PdfBucketItemDetailsFormUpdatePageDetailMutationResult = Apollo.MutationResult<PdfBucketItemDetailsFormUpdatePageDetailMutation>;
export type PdfBucketItemDetailsFormUpdatePageDetailMutationOptions = Apollo.BaseMutationOptions<PdfBucketItemDetailsFormUpdatePageDetailMutation, PdfBucketItemDetailsFormUpdatePageDetailMutationVariables>;
export const PdfBucketItemDetailsFormRefreshPageDocument = gql`
    mutation PDFBucketItemDetailsFormRefreshPage($data: String) {
  refreshPage(data: $data) {
    status
    formErrors
    page {
      id
      pdfDetails
    }
  }
}
    `;
export type PdfBucketItemDetailsFormRefreshPageMutationFn = Apollo.MutationFunction<PdfBucketItemDetailsFormRefreshPageMutation, PdfBucketItemDetailsFormRefreshPageMutationVariables>;

/**
 * __usePdfBucketItemDetailsFormRefreshPageMutation__
 *
 * To run a mutation, you first call `usePdfBucketItemDetailsFormRefreshPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePdfBucketItemDetailsFormRefreshPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pdfBucketItemDetailsFormRefreshPageMutation, { data, loading, error }] = usePdfBucketItemDetailsFormRefreshPageMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePdfBucketItemDetailsFormRefreshPageMutation(baseOptions?: Apollo.MutationHookOptions<PdfBucketItemDetailsFormRefreshPageMutation, PdfBucketItemDetailsFormRefreshPageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PdfBucketItemDetailsFormRefreshPageMutation, PdfBucketItemDetailsFormRefreshPageMutationVariables>(PdfBucketItemDetailsFormRefreshPageDocument, options);
      }
export type PdfBucketItemDetailsFormRefreshPageMutationHookResult = ReturnType<typeof usePdfBucketItemDetailsFormRefreshPageMutation>;
export type PdfBucketItemDetailsFormRefreshPageMutationResult = Apollo.MutationResult<PdfBucketItemDetailsFormRefreshPageMutation>;
export type PdfBucketItemDetailsFormRefreshPageMutationOptions = Apollo.BaseMutationOptions<PdfBucketItemDetailsFormRefreshPageMutation, PdfBucketItemDetailsFormRefreshPageMutationVariables>;
export const PdfProjectDetailsFormDocument = gql`
    mutation PDFProjectDetailsForm($data: String) {
  updatePageDetail(data: $data) {
    status
    formErrors
    page {
      id
      pdfDetails
    }
  }
}
    `;
export type PdfProjectDetailsFormMutationFn = Apollo.MutationFunction<PdfProjectDetailsFormMutation, PdfProjectDetailsFormMutationVariables>;

/**
 * __usePdfProjectDetailsFormMutation__
 *
 * To run a mutation, you first call `usePdfProjectDetailsFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePdfProjectDetailsFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pdfProjectDetailsFormMutation, { data, loading, error }] = usePdfProjectDetailsFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePdfProjectDetailsFormMutation(baseOptions?: Apollo.MutationHookOptions<PdfProjectDetailsFormMutation, PdfProjectDetailsFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PdfProjectDetailsFormMutation, PdfProjectDetailsFormMutationVariables>(PdfProjectDetailsFormDocument, options);
      }
export type PdfProjectDetailsFormMutationHookResult = ReturnType<typeof usePdfProjectDetailsFormMutation>;
export type PdfProjectDetailsFormMutationResult = Apollo.MutationResult<PdfProjectDetailsFormMutation>;
export type PdfProjectDetailsFormMutationOptions = Apollo.BaseMutationOptions<PdfProjectDetailsFormMutation, PdfProjectDetailsFormMutationVariables>;
export const PdfCoverImageDocument = gql`
    mutation PdfCoverImage($data: String) {
  updatePdfPageImage(data: $data) {
    status
    formErrors
    page {
      id
      ...ProposalPageFragment
    }
  }
}
    ${ProposalPageFragmentFragmentDoc}`;
export type PdfCoverImageMutationFn = Apollo.MutationFunction<PdfCoverImageMutation, PdfCoverImageMutationVariables>;

/**
 * __usePdfCoverImageMutation__
 *
 * To run a mutation, you first call `usePdfCoverImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePdfCoverImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pdfCoverImageMutation, { data, loading, error }] = usePdfCoverImageMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePdfCoverImageMutation(baseOptions?: Apollo.MutationHookOptions<PdfCoverImageMutation, PdfCoverImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PdfCoverImageMutation, PdfCoverImageMutationVariables>(PdfCoverImageDocument, options);
      }
export type PdfCoverImageMutationHookResult = ReturnType<typeof usePdfCoverImageMutation>;
export type PdfCoverImageMutationResult = Apollo.MutationResult<PdfCoverImageMutation>;
export type PdfCoverImageMutationOptions = Apollo.BaseMutationOptions<PdfCoverImageMutation, PdfCoverImageMutationVariables>;
export const BucketItemDetailsFormUpdatePdfContactDocument = gql`
    mutation BucketItemDetailsFormUpdatePdfContact($data: String) {
  updateProposalContact(data: $data) {
    status
    formErrors
    proposal {
      id
      pdfAddr
      pdfEmail
      pdfSite
    }
  }
}
    `;
export type BucketItemDetailsFormUpdatePdfContactMutationFn = Apollo.MutationFunction<BucketItemDetailsFormUpdatePdfContactMutation, BucketItemDetailsFormUpdatePdfContactMutationVariables>;

/**
 * __useBucketItemDetailsFormUpdatePdfContactMutation__
 *
 * To run a mutation, you first call `useBucketItemDetailsFormUpdatePdfContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBucketItemDetailsFormUpdatePdfContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bucketItemDetailsFormUpdatePdfContactMutation, { data, loading, error }] = useBucketItemDetailsFormUpdatePdfContactMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useBucketItemDetailsFormUpdatePdfContactMutation(baseOptions?: Apollo.MutationHookOptions<BucketItemDetailsFormUpdatePdfContactMutation, BucketItemDetailsFormUpdatePdfContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BucketItemDetailsFormUpdatePdfContactMutation, BucketItemDetailsFormUpdatePdfContactMutationVariables>(BucketItemDetailsFormUpdatePdfContactDocument, options);
      }
export type BucketItemDetailsFormUpdatePdfContactMutationHookResult = ReturnType<typeof useBucketItemDetailsFormUpdatePdfContactMutation>;
export type BucketItemDetailsFormUpdatePdfContactMutationResult = Apollo.MutationResult<BucketItemDetailsFormUpdatePdfContactMutation>;
export type BucketItemDetailsFormUpdatePdfContactMutationOptions = Apollo.BaseMutationOptions<BucketItemDetailsFormUpdatePdfContactMutation, BucketItemDetailsFormUpdatePdfContactMutationVariables>;
export const PdfHideFieldsFormDocument = gql`
    mutation PDFHideFieldsForm($data: String) {
  updateProposalHideFields(data: $data) {
    status
    formErrors
    proposal {
      id
      pdfHideTitle
      pdfHideArtist
      pdfHidePrice
      pdfHideShipping
      pdfHideAreaDetails
      pdfHideLogo
    }
  }
}
    `;
export type PdfHideFieldsFormMutationFn = Apollo.MutationFunction<PdfHideFieldsFormMutation, PdfHideFieldsFormMutationVariables>;

/**
 * __usePdfHideFieldsFormMutation__
 *
 * To run a mutation, you first call `usePdfHideFieldsFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePdfHideFieldsFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pdfHideFieldsFormMutation, { data, loading, error }] = usePdfHideFieldsFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePdfHideFieldsFormMutation(baseOptions?: Apollo.MutationHookOptions<PdfHideFieldsFormMutation, PdfHideFieldsFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PdfHideFieldsFormMutation, PdfHideFieldsFormMutationVariables>(PdfHideFieldsFormDocument, options);
      }
export type PdfHideFieldsFormMutationHookResult = ReturnType<typeof usePdfHideFieldsFormMutation>;
export type PdfHideFieldsFormMutationResult = Apollo.MutationResult<PdfHideFieldsFormMutation>;
export type PdfHideFieldsFormMutationOptions = Apollo.BaseMutationOptions<PdfHideFieldsFormMutation, PdfHideFieldsFormMutationVariables>;
export const PdfItemSelectionFormToggleBucketItemDocument = gql`
    mutation PDFItemSelectionFormToggleBucketItem($data: String) {
  toggleBucketItemShortlisted(data: $data) {
    status
  }
}
    `;
export type PdfItemSelectionFormToggleBucketItemMutationFn = Apollo.MutationFunction<PdfItemSelectionFormToggleBucketItemMutation, PdfItemSelectionFormToggleBucketItemMutationVariables>;

/**
 * __usePdfItemSelectionFormToggleBucketItemMutation__
 *
 * To run a mutation, you first call `usePdfItemSelectionFormToggleBucketItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePdfItemSelectionFormToggleBucketItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pdfItemSelectionFormToggleBucketItemMutation, { data, loading, error }] = usePdfItemSelectionFormToggleBucketItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePdfItemSelectionFormToggleBucketItemMutation(baseOptions?: Apollo.MutationHookOptions<PdfItemSelectionFormToggleBucketItemMutation, PdfItemSelectionFormToggleBucketItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PdfItemSelectionFormToggleBucketItemMutation, PdfItemSelectionFormToggleBucketItemMutationVariables>(PdfItemSelectionFormToggleBucketItemDocument, options);
      }
export type PdfItemSelectionFormToggleBucketItemMutationHookResult = ReturnType<typeof usePdfItemSelectionFormToggleBucketItemMutation>;
export type PdfItemSelectionFormToggleBucketItemMutationResult = Apollo.MutationResult<PdfItemSelectionFormToggleBucketItemMutation>;
export type PdfItemSelectionFormToggleBucketItemMutationOptions = Apollo.BaseMutationOptions<PdfItemSelectionFormToggleBucketItemMutation, PdfItemSelectionFormToggleBucketItemMutationVariables>;
export const PdfItemSelectionFormCreateNewProposalDocument = gql`
    mutation PDFItemSelectionFormCreateNewProposal($data: String) {
  createNewProposal(data: $data) {
    status
    proposal {
      id
      pages {
        id
      }
    }
  }
}
    `;
export type PdfItemSelectionFormCreateNewProposalMutationFn = Apollo.MutationFunction<PdfItemSelectionFormCreateNewProposalMutation, PdfItemSelectionFormCreateNewProposalMutationVariables>;

/**
 * __usePdfItemSelectionFormCreateNewProposalMutation__
 *
 * To run a mutation, you first call `usePdfItemSelectionFormCreateNewProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePdfItemSelectionFormCreateNewProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pdfItemSelectionFormCreateNewProposalMutation, { data, loading, error }] = usePdfItemSelectionFormCreateNewProposalMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePdfItemSelectionFormCreateNewProposalMutation(baseOptions?: Apollo.MutationHookOptions<PdfItemSelectionFormCreateNewProposalMutation, PdfItemSelectionFormCreateNewProposalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PdfItemSelectionFormCreateNewProposalMutation, PdfItemSelectionFormCreateNewProposalMutationVariables>(PdfItemSelectionFormCreateNewProposalDocument, options);
      }
export type PdfItemSelectionFormCreateNewProposalMutationHookResult = ReturnType<typeof usePdfItemSelectionFormCreateNewProposalMutation>;
export type PdfItemSelectionFormCreateNewProposalMutationResult = Apollo.MutationResult<PdfItemSelectionFormCreateNewProposalMutation>;
export type PdfItemSelectionFormCreateNewProposalMutationOptions = Apollo.BaseMutationOptions<PdfItemSelectionFormCreateNewProposalMutation, PdfItemSelectionFormCreateNewProposalMutationVariables>;
export const PdfLocationImageFormDocument = gql`
    mutation PDFLocationImageForm($data: String) {
  updatePdfPageImage(data: $data) {
    status
    formErrors
    page {
      id
      pdfDetails
    }
  }
}
    `;
export type PdfLocationImageFormMutationFn = Apollo.MutationFunction<PdfLocationImageFormMutation, PdfLocationImageFormMutationVariables>;

/**
 * __usePdfLocationImageFormMutation__
 *
 * To run a mutation, you first call `usePdfLocationImageFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePdfLocationImageFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pdfLocationImageFormMutation, { data, loading, error }] = usePdfLocationImageFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePdfLocationImageFormMutation(baseOptions?: Apollo.MutationHookOptions<PdfLocationImageFormMutation, PdfLocationImageFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PdfLocationImageFormMutation, PdfLocationImageFormMutationVariables>(PdfLocationImageFormDocument, options);
      }
export type PdfLocationImageFormMutationHookResult = ReturnType<typeof usePdfLocationImageFormMutation>;
export type PdfLocationImageFormMutationResult = Apollo.MutationResult<PdfLocationImageFormMutation>;
export type PdfLocationImageFormMutationOptions = Apollo.BaseMutationOptions<PdfLocationImageFormMutation, PdfLocationImageFormMutationVariables>;
export const BucketItemDetailsFormUpdatePdfLogoDocument = gql`
    mutation BucketItemDetailsFormUpdatePdfLogo($data: String) {
  updateProposalLogo(data: $data) {
    status
    formErrors
    proposal {
      id
      pdfLogo
    }
  }
}
    `;
export type BucketItemDetailsFormUpdatePdfLogoMutationFn = Apollo.MutationFunction<BucketItemDetailsFormUpdatePdfLogoMutation, BucketItemDetailsFormUpdatePdfLogoMutationVariables>;

/**
 * __useBucketItemDetailsFormUpdatePdfLogoMutation__
 *
 * To run a mutation, you first call `useBucketItemDetailsFormUpdatePdfLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBucketItemDetailsFormUpdatePdfLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bucketItemDetailsFormUpdatePdfLogoMutation, { data, loading, error }] = useBucketItemDetailsFormUpdatePdfLogoMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useBucketItemDetailsFormUpdatePdfLogoMutation(baseOptions?: Apollo.MutationHookOptions<BucketItemDetailsFormUpdatePdfLogoMutation, BucketItemDetailsFormUpdatePdfLogoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BucketItemDetailsFormUpdatePdfLogoMutation, BucketItemDetailsFormUpdatePdfLogoMutationVariables>(BucketItemDetailsFormUpdatePdfLogoDocument, options);
      }
export type BucketItemDetailsFormUpdatePdfLogoMutationHookResult = ReturnType<typeof useBucketItemDetailsFormUpdatePdfLogoMutation>;
export type BucketItemDetailsFormUpdatePdfLogoMutationResult = Apollo.MutationResult<BucketItemDetailsFormUpdatePdfLogoMutation>;
export type BucketItemDetailsFormUpdatePdfLogoMutationOptions = Apollo.BaseMutationOptions<BucketItemDetailsFormUpdatePdfLogoMutation, BucketItemDetailsFormUpdatePdfLogoMutationVariables>;
export const PdfNewPageFormDocument = gql`
    mutation PDFNewPageForm($data: String) {
  addProposalPage(data: $data) {
    status
    formErrors
    proposal {
      id
      pages {
        id
        ...ProposalPageFragment
      }
    }
    page {
      id
      isActive
    }
  }
}
    ${ProposalPageFragmentFragmentDoc}`;
export type PdfNewPageFormMutationFn = Apollo.MutationFunction<PdfNewPageFormMutation, PdfNewPageFormMutationVariables>;

/**
 * __usePdfNewPageFormMutation__
 *
 * To run a mutation, you first call `usePdfNewPageFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePdfNewPageFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pdfNewPageFormMutation, { data, loading, error }] = usePdfNewPageFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePdfNewPageFormMutation(baseOptions?: Apollo.MutationHookOptions<PdfNewPageFormMutation, PdfNewPageFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PdfNewPageFormMutation, PdfNewPageFormMutationVariables>(PdfNewPageFormDocument, options);
      }
export type PdfNewPageFormMutationHookResult = ReturnType<typeof usePdfNewPageFormMutation>;
export type PdfNewPageFormMutationResult = Apollo.MutationResult<PdfNewPageFormMutation>;
export type PdfNewPageFormMutationOptions = Apollo.BaseMutationOptions<PdfNewPageFormMutation, PdfNewPageFormMutationVariables>;
export const PdfProjectImageFormDocument = gql`
    mutation PDFProjectImageForm($data: String) {
  updatePdfPageImage(data: $data) {
    status
    formErrors
    page {
      id
      pdfDetails
    }
  }
}
    `;
export type PdfProjectImageFormMutationFn = Apollo.MutationFunction<PdfProjectImageFormMutation, PdfProjectImageFormMutationVariables>;

/**
 * __usePdfProjectImageFormMutation__
 *
 * To run a mutation, you first call `usePdfProjectImageFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePdfProjectImageFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pdfProjectImageFormMutation, { data, loading, error }] = usePdfProjectImageFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePdfProjectImageFormMutation(baseOptions?: Apollo.MutationHookOptions<PdfProjectImageFormMutation, PdfProjectImageFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PdfProjectImageFormMutation, PdfProjectImageFormMutationVariables>(PdfProjectImageFormDocument, options);
      }
export type PdfProjectImageFormMutationHookResult = ReturnType<typeof usePdfProjectImageFormMutation>;
export type PdfProjectImageFormMutationResult = Apollo.MutationResult<PdfProjectImageFormMutation>;
export type PdfProjectImageFormMutationOptions = Apollo.BaseMutationOptions<PdfProjectImageFormMutation, PdfProjectImageFormMutationVariables>;
export const PdfvirDetailsFormUpdatePageDetailDocument = gql`
    mutation PDFVIRDetailsFormUpdatePageDetail($data: String) {
  updatePageDetail(data: $data) {
    status
    formErrors
    page {
      id
      pdfDetails
    }
  }
}
    `;
export type PdfvirDetailsFormUpdatePageDetailMutationFn = Apollo.MutationFunction<PdfvirDetailsFormUpdatePageDetailMutation, PdfvirDetailsFormUpdatePageDetailMutationVariables>;

/**
 * __usePdfvirDetailsFormUpdatePageDetailMutation__
 *
 * To run a mutation, you first call `usePdfvirDetailsFormUpdatePageDetailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePdfvirDetailsFormUpdatePageDetailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pdfvirDetailsFormUpdatePageDetailMutation, { data, loading, error }] = usePdfvirDetailsFormUpdatePageDetailMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePdfvirDetailsFormUpdatePageDetailMutation(baseOptions?: Apollo.MutationHookOptions<PdfvirDetailsFormUpdatePageDetailMutation, PdfvirDetailsFormUpdatePageDetailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PdfvirDetailsFormUpdatePageDetailMutation, PdfvirDetailsFormUpdatePageDetailMutationVariables>(PdfvirDetailsFormUpdatePageDetailDocument, options);
      }
export type PdfvirDetailsFormUpdatePageDetailMutationHookResult = ReturnType<typeof usePdfvirDetailsFormUpdatePageDetailMutation>;
export type PdfvirDetailsFormUpdatePageDetailMutationResult = Apollo.MutationResult<PdfvirDetailsFormUpdatePageDetailMutation>;
export type PdfvirDetailsFormUpdatePageDetailMutationOptions = Apollo.BaseMutationOptions<PdfvirDetailsFormUpdatePageDetailMutation, PdfvirDetailsFormUpdatePageDetailMutationVariables>;
export const PdfvirDetailsFormRefreshPageDocument = gql`
    mutation PDFVIRDetailsFormRefreshPage($data: String) {
  refreshPage(data: $data) {
    status
    formErrors
    page {
      id
      pdfDetails
    }
  }
}
    `;
export type PdfvirDetailsFormRefreshPageMutationFn = Apollo.MutationFunction<PdfvirDetailsFormRefreshPageMutation, PdfvirDetailsFormRefreshPageMutationVariables>;

/**
 * __usePdfvirDetailsFormRefreshPageMutation__
 *
 * To run a mutation, you first call `usePdfvirDetailsFormRefreshPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePdfvirDetailsFormRefreshPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pdfvirDetailsFormRefreshPageMutation, { data, loading, error }] = usePdfvirDetailsFormRefreshPageMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePdfvirDetailsFormRefreshPageMutation(baseOptions?: Apollo.MutationHookOptions<PdfvirDetailsFormRefreshPageMutation, PdfvirDetailsFormRefreshPageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PdfvirDetailsFormRefreshPageMutation, PdfvirDetailsFormRefreshPageMutationVariables>(PdfvirDetailsFormRefreshPageDocument, options);
      }
export type PdfvirDetailsFormRefreshPageMutationHookResult = ReturnType<typeof usePdfvirDetailsFormRefreshPageMutation>;
export type PdfvirDetailsFormRefreshPageMutationResult = Apollo.MutationResult<PdfvirDetailsFormRefreshPageMutation>;
export type PdfvirDetailsFormRefreshPageMutationOptions = Apollo.BaseMutationOptions<PdfvirDetailsFormRefreshPageMutation, PdfvirDetailsFormRefreshPageMutationVariables>;
export const ProjectInviteFormDocument = gql`
    mutation ProjectInviteForm($data: String) {
  projectInvite(data: $data) {
    status
    formErrors
    project {
      id
      ...ProjectInviteFragment
    }
  }
}
    ${ProjectInviteFragmentFragmentDoc}`;
export type ProjectInviteFormMutationFn = Apollo.MutationFunction<ProjectInviteFormMutation, ProjectInviteFormMutationVariables>;

/**
 * __useProjectInviteFormMutation__
 *
 * To run a mutation, you first call `useProjectInviteFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectInviteFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectInviteFormMutation, { data, loading, error }] = useProjectInviteFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useProjectInviteFormMutation(baseOptions?: Apollo.MutationHookOptions<ProjectInviteFormMutation, ProjectInviteFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectInviteFormMutation, ProjectInviteFormMutationVariables>(ProjectInviteFormDocument, options);
      }
export type ProjectInviteFormMutationHookResult = ReturnType<typeof useProjectInviteFormMutation>;
export type ProjectInviteFormMutationResult = Apollo.MutationResult<ProjectInviteFormMutation>;
export type ProjectInviteFormMutationOptions = Apollo.BaseMutationOptions<ProjectInviteFormMutation, ProjectInviteFormMutationVariables>;
export const ProjectLocationItemNameFormDocument = gql`
    mutation ProjectLocationItemNameForm($data: String) {
  updateLocationItemName(data: $data) {
    status
    formErrors
    result {
      id
      title
    }
  }
}
    `;
export type ProjectLocationItemNameFormMutationFn = Apollo.MutationFunction<ProjectLocationItemNameFormMutation, ProjectLocationItemNameFormMutationVariables>;

/**
 * __useProjectLocationItemNameFormMutation__
 *
 * To run a mutation, you first call `useProjectLocationItemNameFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectLocationItemNameFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectLocationItemNameFormMutation, { data, loading, error }] = useProjectLocationItemNameFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useProjectLocationItemNameFormMutation(baseOptions?: Apollo.MutationHookOptions<ProjectLocationItemNameFormMutation, ProjectLocationItemNameFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectLocationItemNameFormMutation, ProjectLocationItemNameFormMutationVariables>(ProjectLocationItemNameFormDocument, options);
      }
export type ProjectLocationItemNameFormMutationHookResult = ReturnType<typeof useProjectLocationItemNameFormMutation>;
export type ProjectLocationItemNameFormMutationResult = Apollo.MutationResult<ProjectLocationItemNameFormMutation>;
export type ProjectLocationItemNameFormMutationOptions = Apollo.BaseMutationOptions<ProjectLocationItemNameFormMutation, ProjectLocationItemNameFormMutationVariables>;
export const ProjectLocationNameFormDocument = gql`
    mutation ProjectLocationNameForm($data: String) {
  updateLocationName(data: $data) {
    status
    formErrors
    result {
      id
      title
      titleStr
    }
  }
}
    `;
export type ProjectLocationNameFormMutationFn = Apollo.MutationFunction<ProjectLocationNameFormMutation, ProjectLocationNameFormMutationVariables>;

/**
 * __useProjectLocationNameFormMutation__
 *
 * To run a mutation, you first call `useProjectLocationNameFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectLocationNameFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectLocationNameFormMutation, { data, loading, error }] = useProjectLocationNameFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useProjectLocationNameFormMutation(baseOptions?: Apollo.MutationHookOptions<ProjectLocationNameFormMutation, ProjectLocationNameFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectLocationNameFormMutation, ProjectLocationNameFormMutationVariables>(ProjectLocationNameFormDocument, options);
      }
export type ProjectLocationNameFormMutationHookResult = ReturnType<typeof useProjectLocationNameFormMutation>;
export type ProjectLocationNameFormMutationResult = Apollo.MutationResult<ProjectLocationNameFormMutation>;
export type ProjectLocationNameFormMutationOptions = Apollo.BaseMutationOptions<ProjectLocationNameFormMutation, ProjectLocationNameFormMutationVariables>;
export const ProjectSendQuotationFormDocument = gql`
    mutation ProjectSendQuotationForm($data: String) {
  staffCompleteQuotation(data: $data) {
    status
    formErrors
  }
}
    `;
export type ProjectSendQuotationFormMutationFn = Apollo.MutationFunction<ProjectSendQuotationFormMutation, ProjectSendQuotationFormMutationVariables>;

/**
 * __useProjectSendQuotationFormMutation__
 *
 * To run a mutation, you first call `useProjectSendQuotationFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectSendQuotationFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectSendQuotationFormMutation, { data, loading, error }] = useProjectSendQuotationFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useProjectSendQuotationFormMutation(baseOptions?: Apollo.MutationHookOptions<ProjectSendQuotationFormMutation, ProjectSendQuotationFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectSendQuotationFormMutation, ProjectSendQuotationFormMutationVariables>(ProjectSendQuotationFormDocument, options);
      }
export type ProjectSendQuotationFormMutationHookResult = ReturnType<typeof useProjectSendQuotationFormMutation>;
export type ProjectSendQuotationFormMutationResult = Apollo.MutationResult<ProjectSendQuotationFormMutation>;
export type ProjectSendQuotationFormMutationOptions = Apollo.BaseMutationOptions<ProjectSendQuotationFormMutation, ProjectSendQuotationFormMutationVariables>;
export const QuotationBillingAddressFormDocument = gql`
    mutation QuotationBillingAddressForm($data: String) {
  updateQuotationBillingAddress(data: $data) {
    formErrors
    status
    project {
      id
      ...ProjectSummaryFragment
    }
  }
}
    ${ProjectSummaryFragmentFragmentDoc}`;
export type QuotationBillingAddressFormMutationFn = Apollo.MutationFunction<QuotationBillingAddressFormMutation, QuotationBillingAddressFormMutationVariables>;

/**
 * __useQuotationBillingAddressFormMutation__
 *
 * To run a mutation, you first call `useQuotationBillingAddressFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuotationBillingAddressFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [quotationBillingAddressFormMutation, { data, loading, error }] = useQuotationBillingAddressFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useQuotationBillingAddressFormMutation(baseOptions?: Apollo.MutationHookOptions<QuotationBillingAddressFormMutation, QuotationBillingAddressFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<QuotationBillingAddressFormMutation, QuotationBillingAddressFormMutationVariables>(QuotationBillingAddressFormDocument, options);
      }
export type QuotationBillingAddressFormMutationHookResult = ReturnType<typeof useQuotationBillingAddressFormMutation>;
export type QuotationBillingAddressFormMutationResult = Apollo.MutationResult<QuotationBillingAddressFormMutation>;
export type QuotationBillingAddressFormMutationOptions = Apollo.BaseMutationOptions<QuotationBillingAddressFormMutation, QuotationBillingAddressFormMutationVariables>;
export const QuotationShippingAddressFormDocument = gql`
    mutation QuotationShippingAddressForm($data: String) {
  updateQuotationShippingAddress(data: $data) {
    formErrors
    status
    project {
      id
      ...ProjectSummaryFragment
    }
  }
}
    ${ProjectSummaryFragmentFragmentDoc}`;
export type QuotationShippingAddressFormMutationFn = Apollo.MutationFunction<QuotationShippingAddressFormMutation, QuotationShippingAddressFormMutationVariables>;

/**
 * __useQuotationShippingAddressFormMutation__
 *
 * To run a mutation, you first call `useQuotationShippingAddressFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useQuotationShippingAddressFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [quotationShippingAddressFormMutation, { data, loading, error }] = useQuotationShippingAddressFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useQuotationShippingAddressFormMutation(baseOptions?: Apollo.MutationHookOptions<QuotationShippingAddressFormMutation, QuotationShippingAddressFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<QuotationShippingAddressFormMutation, QuotationShippingAddressFormMutationVariables>(QuotationShippingAddressFormDocument, options);
      }
export type QuotationShippingAddressFormMutationHookResult = ReturnType<typeof useQuotationShippingAddressFormMutation>;
export type QuotationShippingAddressFormMutationResult = Apollo.MutationResult<QuotationShippingAddressFormMutation>;
export type QuotationShippingAddressFormMutationOptions = Apollo.BaseMutationOptions<QuotationShippingAddressFormMutation, QuotationShippingAddressFormMutationVariables>;
export const RegisterForAccessFormDocument = gql`
    mutation RegisterForAccessForm($data: String) {
  registerForAccess(data: $data) {
    status
    formErrors
  }
}
    `;
export type RegisterForAccessFormMutationFn = Apollo.MutationFunction<RegisterForAccessFormMutation, RegisterForAccessFormMutationVariables>;

/**
 * __useRegisterForAccessFormMutation__
 *
 * To run a mutation, you first call `useRegisterForAccessFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterForAccessFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerForAccessFormMutation, { data, loading, error }] = useRegisterForAccessFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegisterForAccessFormMutation(baseOptions?: Apollo.MutationHookOptions<RegisterForAccessFormMutation, RegisterForAccessFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterForAccessFormMutation, RegisterForAccessFormMutationVariables>(RegisterForAccessFormDocument, options);
      }
export type RegisterForAccessFormMutationHookResult = ReturnType<typeof useRegisterForAccessFormMutation>;
export type RegisterForAccessFormMutationResult = Apollo.MutationResult<RegisterForAccessFormMutation>;
export type RegisterForAccessFormMutationOptions = Apollo.BaseMutationOptions<RegisterForAccessFormMutation, RegisterForAccessFormMutationVariables>;
export const ResetPasswordKeyFormDocument = gql`
    mutation ResetPasswordKeyForm($data: String) {
  resetPasswordWithKey(data: $data) {
    status
    formErrors
  }
}
    `;
export type ResetPasswordKeyFormMutationFn = Apollo.MutationFunction<ResetPasswordKeyFormMutation, ResetPasswordKeyFormMutationVariables>;

/**
 * __useResetPasswordKeyFormMutation__
 *
 * To run a mutation, you first call `useResetPasswordKeyFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordKeyFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordKeyFormMutation, { data, loading, error }] = useResetPasswordKeyFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useResetPasswordKeyFormMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordKeyFormMutation, ResetPasswordKeyFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordKeyFormMutation, ResetPasswordKeyFormMutationVariables>(ResetPasswordKeyFormDocument, options);
      }
export type ResetPasswordKeyFormMutationHookResult = ReturnType<typeof useResetPasswordKeyFormMutation>;
export type ResetPasswordKeyFormMutationResult = Apollo.MutationResult<ResetPasswordKeyFormMutation>;
export type ResetPasswordKeyFormMutationOptions = Apollo.BaseMutationOptions<ResetPasswordKeyFormMutation, ResetPasswordKeyFormMutationVariables>;
export const SelectSensitiveDocumentsFormDocument = gql`
    mutation SelectSensitiveDocumentsForm($data: String) {
  requestQuotation(data: $data) {
    status
    formErrors
    project {
      id
      status
    }
  }
}
    `;
export type SelectSensitiveDocumentsFormMutationFn = Apollo.MutationFunction<SelectSensitiveDocumentsFormMutation, SelectSensitiveDocumentsFormMutationVariables>;

/**
 * __useSelectSensitiveDocumentsFormMutation__
 *
 * To run a mutation, you first call `useSelectSensitiveDocumentsFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectSensitiveDocumentsFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectSensitiveDocumentsFormMutation, { data, loading, error }] = useSelectSensitiveDocumentsFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSelectSensitiveDocumentsFormMutation(baseOptions?: Apollo.MutationHookOptions<SelectSensitiveDocumentsFormMutation, SelectSensitiveDocumentsFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SelectSensitiveDocumentsFormMutation, SelectSensitiveDocumentsFormMutationVariables>(SelectSensitiveDocumentsFormDocument, options);
      }
export type SelectSensitiveDocumentsFormMutationHookResult = ReturnType<typeof useSelectSensitiveDocumentsFormMutation>;
export type SelectSensitiveDocumentsFormMutationResult = Apollo.MutationResult<SelectSensitiveDocumentsFormMutation>;
export type SelectSensitiveDocumentsFormMutationOptions = Apollo.BaseMutationOptions<SelectSensitiveDocumentsFormMutation, SelectSensitiveDocumentsFormMutationVariables>;
export const SellerConfirmAvailabilityFormDocument = gql`
    mutation SellerConfirmAvailabilityForm($uuid: String, $data: String) {
  wfSellerConfirmAvailabilityMutation(uuid: $uuid, data: $data) {
    status
    formErrors
  }
}
    `;
export type SellerConfirmAvailabilityFormMutationFn = Apollo.MutationFunction<SellerConfirmAvailabilityFormMutation, SellerConfirmAvailabilityFormMutationVariables>;

/**
 * __useSellerConfirmAvailabilityFormMutation__
 *
 * To run a mutation, you first call `useSellerConfirmAvailabilityFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSellerConfirmAvailabilityFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sellerConfirmAvailabilityFormMutation, { data, loading, error }] = useSellerConfirmAvailabilityFormMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSellerConfirmAvailabilityFormMutation(baseOptions?: Apollo.MutationHookOptions<SellerConfirmAvailabilityFormMutation, SellerConfirmAvailabilityFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SellerConfirmAvailabilityFormMutation, SellerConfirmAvailabilityFormMutationVariables>(SellerConfirmAvailabilityFormDocument, options);
      }
export type SellerConfirmAvailabilityFormMutationHookResult = ReturnType<typeof useSellerConfirmAvailabilityFormMutation>;
export type SellerConfirmAvailabilityFormMutationResult = Apollo.MutationResult<SellerConfirmAvailabilityFormMutation>;
export type SellerConfirmAvailabilityFormMutationOptions = Apollo.BaseMutationOptions<SellerConfirmAvailabilityFormMutation, SellerConfirmAvailabilityFormMutationVariables>;
export const SignupFormDocument = gql`
    mutation SignupForm($data: String) {
  signup(data: $data) {
    status
    formErrors
    currentProfile {
      id
      ...CurrentProfileFragment
    }
  }
}
    ${CurrentProfileFragmentFragmentDoc}`;
export type SignupFormMutationFn = Apollo.MutationFunction<SignupFormMutation, SignupFormMutationVariables>;

/**
 * __useSignupFormMutation__
 *
 * To run a mutation, you first call `useSignupFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupFormMutation, { data, loading, error }] = useSignupFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSignupFormMutation(baseOptions?: Apollo.MutationHookOptions<SignupFormMutation, SignupFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignupFormMutation, SignupFormMutationVariables>(SignupFormDocument, options);
      }
export type SignupFormMutationHookResult = ReturnType<typeof useSignupFormMutation>;
export type SignupFormMutationResult = Apollo.MutationResult<SignupFormMutation>;
export type SignupFormMutationOptions = Apollo.BaseMutationOptions<SignupFormMutation, SignupFormMutationVariables>;
export const SourcingArtistCreateUpdateArtistFormDocument = gql`
    mutation SourcingArtistCreateUpdateArtistForm($data: String, $artistId: String) {
  artistCreateUpdate(data: $data, artistId: $artistId) {
    status
    formErrors
    artist {
      id
    }
  }
}
    `;
export type SourcingArtistCreateUpdateArtistFormMutationFn = Apollo.MutationFunction<SourcingArtistCreateUpdateArtistFormMutation, SourcingArtistCreateUpdateArtistFormMutationVariables>;

/**
 * __useSourcingArtistCreateUpdateArtistFormMutation__
 *
 * To run a mutation, you first call `useSourcingArtistCreateUpdateArtistFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSourcingArtistCreateUpdateArtistFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sourcingArtistCreateUpdateArtistFormMutation, { data, loading, error }] = useSourcingArtistCreateUpdateArtistFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *      artistId: // value for 'artistId'
 *   },
 * });
 */
export function useSourcingArtistCreateUpdateArtistFormMutation(baseOptions?: Apollo.MutationHookOptions<SourcingArtistCreateUpdateArtistFormMutation, SourcingArtistCreateUpdateArtistFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SourcingArtistCreateUpdateArtistFormMutation, SourcingArtistCreateUpdateArtistFormMutationVariables>(SourcingArtistCreateUpdateArtistFormDocument, options);
      }
export type SourcingArtistCreateUpdateArtistFormMutationHookResult = ReturnType<typeof useSourcingArtistCreateUpdateArtistFormMutation>;
export type SourcingArtistCreateUpdateArtistFormMutationResult = Apollo.MutationResult<SourcingArtistCreateUpdateArtistFormMutation>;
export type SourcingArtistCreateUpdateArtistFormMutationOptions = Apollo.BaseMutationOptions<SourcingArtistCreateUpdateArtistFormMutation, SourcingArtistCreateUpdateArtistFormMutationVariables>;
export const SourcingArtistCreateUpdateGalleryFormDocument = gql`
    mutation SourcingArtistCreateUpdateGalleryForm($data: String) {
  galleryCreateUpdate(data: $data) {
    status
    formErrors
    gallery {
      id
    }
  }
}
    `;
export type SourcingArtistCreateUpdateGalleryFormMutationFn = Apollo.MutationFunction<SourcingArtistCreateUpdateGalleryFormMutation, SourcingArtistCreateUpdateGalleryFormMutationVariables>;

/**
 * __useSourcingArtistCreateUpdateGalleryFormMutation__
 *
 * To run a mutation, you first call `useSourcingArtistCreateUpdateGalleryFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSourcingArtistCreateUpdateGalleryFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sourcingArtistCreateUpdateGalleryFormMutation, { data, loading, error }] = useSourcingArtistCreateUpdateGalleryFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSourcingArtistCreateUpdateGalleryFormMutation(baseOptions?: Apollo.MutationHookOptions<SourcingArtistCreateUpdateGalleryFormMutation, SourcingArtistCreateUpdateGalleryFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SourcingArtistCreateUpdateGalleryFormMutation, SourcingArtistCreateUpdateGalleryFormMutationVariables>(SourcingArtistCreateUpdateGalleryFormDocument, options);
      }
export type SourcingArtistCreateUpdateGalleryFormMutationHookResult = ReturnType<typeof useSourcingArtistCreateUpdateGalleryFormMutation>;
export type SourcingArtistCreateUpdateGalleryFormMutationResult = Apollo.MutationResult<SourcingArtistCreateUpdateGalleryFormMutation>;
export type SourcingArtistCreateUpdateGalleryFormMutationOptions = Apollo.BaseMutationOptions<SourcingArtistCreateUpdateGalleryFormMutation, SourcingArtistCreateUpdateGalleryFormMutationVariables>;
export const UpdateAccountEmailFormDocument = gql`
    mutation UpdateAccountEmailForm($data: String) {
  updateEmail(data: $data) {
    status
    formErrors
  }
}
    `;
export type UpdateAccountEmailFormMutationFn = Apollo.MutationFunction<UpdateAccountEmailFormMutation, UpdateAccountEmailFormMutationVariables>;

/**
 * __useUpdateAccountEmailFormMutation__
 *
 * To run a mutation, you first call `useUpdateAccountEmailFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountEmailFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountEmailFormMutation, { data, loading, error }] = useUpdateAccountEmailFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAccountEmailFormMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountEmailFormMutation, UpdateAccountEmailFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountEmailFormMutation, UpdateAccountEmailFormMutationVariables>(UpdateAccountEmailFormDocument, options);
      }
export type UpdateAccountEmailFormMutationHookResult = ReturnType<typeof useUpdateAccountEmailFormMutation>;
export type UpdateAccountEmailFormMutationResult = Apollo.MutationResult<UpdateAccountEmailFormMutation>;
export type UpdateAccountEmailFormMutationOptions = Apollo.BaseMutationOptions<UpdateAccountEmailFormMutation, UpdateAccountEmailFormMutationVariables>;
export const UpdateAccountInfoFormDocument = gql`
    mutation UpdateAccountInfoForm($data: String) {
  updateAccountInfo(data: $data) {
    status
    formErrors
  }
}
    `;
export type UpdateAccountInfoFormMutationFn = Apollo.MutationFunction<UpdateAccountInfoFormMutation, UpdateAccountInfoFormMutationVariables>;

/**
 * __useUpdateAccountInfoFormMutation__
 *
 * To run a mutation, you first call `useUpdateAccountInfoFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountInfoFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountInfoFormMutation, { data, loading, error }] = useUpdateAccountInfoFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAccountInfoFormMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountInfoFormMutation, UpdateAccountInfoFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountInfoFormMutation, UpdateAccountInfoFormMutationVariables>(UpdateAccountInfoFormDocument, options);
      }
export type UpdateAccountInfoFormMutationHookResult = ReturnType<typeof useUpdateAccountInfoFormMutation>;
export type UpdateAccountInfoFormMutationResult = Apollo.MutationResult<UpdateAccountInfoFormMutation>;
export type UpdateAccountInfoFormMutationOptions = Apollo.BaseMutationOptions<UpdateAccountInfoFormMutation, UpdateAccountInfoFormMutationVariables>;
export const UpdateAccountPasswordFormDocument = gql`
    mutation UpdateAccountPasswordForm($data: String) {
  updatePassword(data: $data) {
    status
    formErrors
  }
}
    `;
export type UpdateAccountPasswordFormMutationFn = Apollo.MutationFunction<UpdateAccountPasswordFormMutation, UpdateAccountPasswordFormMutationVariables>;

/**
 * __useUpdateAccountPasswordFormMutation__
 *
 * To run a mutation, you first call `useUpdateAccountPasswordFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountPasswordFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountPasswordFormMutation, { data, loading, error }] = useUpdateAccountPasswordFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAccountPasswordFormMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountPasswordFormMutation, UpdateAccountPasswordFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountPasswordFormMutation, UpdateAccountPasswordFormMutationVariables>(UpdateAccountPasswordFormDocument, options);
      }
export type UpdateAccountPasswordFormMutationHookResult = ReturnType<typeof useUpdateAccountPasswordFormMutation>;
export type UpdateAccountPasswordFormMutationResult = Apollo.MutationResult<UpdateAccountPasswordFormMutation>;
export type UpdateAccountPasswordFormMutationOptions = Apollo.BaseMutationOptions<UpdateAccountPasswordFormMutation, UpdateAccountPasswordFormMutationVariables>;
export const ViewInRoomCtaFormDocument = gql`
    mutation ViewInRoomCTAForm($data: String) {
  createUpdateUserVirImage(data: $data) {
    status
    formErrors
    autoSubmit
    userProfile {
      id
      userVirImages {
        id
        externalId
        image
        imageFilename
      }
    }
  }
}
    `;
export type ViewInRoomCtaFormMutationFn = Apollo.MutationFunction<ViewInRoomCtaFormMutation, ViewInRoomCtaFormMutationVariables>;

/**
 * __useViewInRoomCtaFormMutation__
 *
 * To run a mutation, you first call `useViewInRoomCtaFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewInRoomCtaFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewInRoomCtaFormMutation, { data, loading, error }] = useViewInRoomCtaFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useViewInRoomCtaFormMutation(baseOptions?: Apollo.MutationHookOptions<ViewInRoomCtaFormMutation, ViewInRoomCtaFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ViewInRoomCtaFormMutation, ViewInRoomCtaFormMutationVariables>(ViewInRoomCtaFormDocument, options);
      }
export type ViewInRoomCtaFormMutationHookResult = ReturnType<typeof useViewInRoomCtaFormMutation>;
export type ViewInRoomCtaFormMutationResult = Apollo.MutationResult<ViewInRoomCtaFormMutation>;
export type ViewInRoomCtaFormMutationOptions = Apollo.BaseMutationOptions<ViewInRoomCtaFormMutation, ViewInRoomCtaFormMutationVariables>;
export const WfcwMoveToStepFormDocument = gql`
    mutation WFCWMoveToStepForm($data: String) {
  wfNwStaffMoveToStep(data: $data) {
    status
    formErrors
    flowItemArtwork {
      id
    }
  }
}
    `;
export type WfcwMoveToStepFormMutationFn = Apollo.MutationFunction<WfcwMoveToStepFormMutation, WfcwMoveToStepFormMutationVariables>;

/**
 * __useWfcwMoveToStepFormMutation__
 *
 * To run a mutation, you first call `useWfcwMoveToStepFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWfcwMoveToStepFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wfcwMoveToStepFormMutation, { data, loading, error }] = useWfcwMoveToStepFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useWfcwMoveToStepFormMutation(baseOptions?: Apollo.MutationHookOptions<WfcwMoveToStepFormMutation, WfcwMoveToStepFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WfcwMoveToStepFormMutation, WfcwMoveToStepFormMutationVariables>(WfcwMoveToStepFormDocument, options);
      }
export type WfcwMoveToStepFormMutationHookResult = ReturnType<typeof useWfcwMoveToStepFormMutation>;
export type WfcwMoveToStepFormMutationResult = Apollo.MutationResult<WfcwMoveToStepFormMutation>;
export type WfcwMoveToStepFormMutationOptions = Apollo.BaseMutationOptions<WfcwMoveToStepFormMutation, WfcwMoveToStepFormMutationVariables>;
export const WfCommissionedWorksConfirmAvailabilityFormDocument = gql`
    mutation WFCommissionedWorksConfirmAvailabilityForm($uuid: String, $flowItemId: String, $data: String) {
  wfCommissionedWorksSellerSaveAvailabilityData(
    uuid: $uuid
    flowItemId: $flowItemId
    data: $data
  ) {
    status
    formErrors
  }
}
    `;
export type WfCommissionedWorksConfirmAvailabilityFormMutationFn = Apollo.MutationFunction<WfCommissionedWorksConfirmAvailabilityFormMutation, WfCommissionedWorksConfirmAvailabilityFormMutationVariables>;

/**
 * __useWfCommissionedWorksConfirmAvailabilityFormMutation__
 *
 * To run a mutation, you first call `useWfCommissionedWorksConfirmAvailabilityFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWfCommissionedWorksConfirmAvailabilityFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wfCommissionedWorksConfirmAvailabilityFormMutation, { data, loading, error }] = useWfCommissionedWorksConfirmAvailabilityFormMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      flowItemId: // value for 'flowItemId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useWfCommissionedWorksConfirmAvailabilityFormMutation(baseOptions?: Apollo.MutationHookOptions<WfCommissionedWorksConfirmAvailabilityFormMutation, WfCommissionedWorksConfirmAvailabilityFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WfCommissionedWorksConfirmAvailabilityFormMutation, WfCommissionedWorksConfirmAvailabilityFormMutationVariables>(WfCommissionedWorksConfirmAvailabilityFormDocument, options);
      }
export type WfCommissionedWorksConfirmAvailabilityFormMutationHookResult = ReturnType<typeof useWfCommissionedWorksConfirmAvailabilityFormMutation>;
export type WfCommissionedWorksConfirmAvailabilityFormMutationResult = Apollo.MutationResult<WfCommissionedWorksConfirmAvailabilityFormMutation>;
export type WfCommissionedWorksConfirmAvailabilityFormMutationOptions = Apollo.BaseMutationOptions<WfCommissionedWorksConfirmAvailabilityFormMutation, WfCommissionedWorksConfirmAvailabilityFormMutationVariables>;
export const WfCommissionedWorksConfirmAvailabilityFormGroupDocument = gql`
    mutation WFCommissionedWorksConfirmAvailabilityFormGroup($uuid: String, $data: String) {
  wfCommissionedWorksSellerSubmitAvailabilityData(uuid: $uuid, data: $data) {
    status
    formErrors
  }
}
    `;
export type WfCommissionedWorksConfirmAvailabilityFormGroupMutationFn = Apollo.MutationFunction<WfCommissionedWorksConfirmAvailabilityFormGroupMutation, WfCommissionedWorksConfirmAvailabilityFormGroupMutationVariables>;

/**
 * __useWfCommissionedWorksConfirmAvailabilityFormGroupMutation__
 *
 * To run a mutation, you first call `useWfCommissionedWorksConfirmAvailabilityFormGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWfCommissionedWorksConfirmAvailabilityFormGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wfCommissionedWorksConfirmAvailabilityFormGroupMutation, { data, loading, error }] = useWfCommissionedWorksConfirmAvailabilityFormGroupMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useWfCommissionedWorksConfirmAvailabilityFormGroupMutation(baseOptions?: Apollo.MutationHookOptions<WfCommissionedWorksConfirmAvailabilityFormGroupMutation, WfCommissionedWorksConfirmAvailabilityFormGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WfCommissionedWorksConfirmAvailabilityFormGroupMutation, WfCommissionedWorksConfirmAvailabilityFormGroupMutationVariables>(WfCommissionedWorksConfirmAvailabilityFormGroupDocument, options);
      }
export type WfCommissionedWorksConfirmAvailabilityFormGroupMutationHookResult = ReturnType<typeof useWfCommissionedWorksConfirmAvailabilityFormGroupMutation>;
export type WfCommissionedWorksConfirmAvailabilityFormGroupMutationResult = Apollo.MutationResult<WfCommissionedWorksConfirmAvailabilityFormGroupMutation>;
export type WfCommissionedWorksConfirmAvailabilityFormGroupMutationOptions = Apollo.BaseMutationOptions<WfCommissionedWorksConfirmAvailabilityFormGroupMutation, WfCommissionedWorksConfirmAvailabilityFormGroupMutationVariables>;
export const WfCommissionedWorksLocationInfoFormMutationDocument = gql`
    mutation WFCommissionedWorksLocationInfoFormMutation($data: String) {
  wfCommissionedWorksStaffUpdateLocationInfo(data: $data) {
    status
    formErrors
  }
}
    `;
export type WfCommissionedWorksLocationInfoFormMutationMutationFn = Apollo.MutationFunction<WfCommissionedWorksLocationInfoFormMutationMutation, WfCommissionedWorksLocationInfoFormMutationMutationVariables>;

/**
 * __useWfCommissionedWorksLocationInfoFormMutationMutation__
 *
 * To run a mutation, you first call `useWfCommissionedWorksLocationInfoFormMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWfCommissionedWorksLocationInfoFormMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wfCommissionedWorksLocationInfoFormMutationMutation, { data, loading, error }] = useWfCommissionedWorksLocationInfoFormMutationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useWfCommissionedWorksLocationInfoFormMutationMutation(baseOptions?: Apollo.MutationHookOptions<WfCommissionedWorksLocationInfoFormMutationMutation, WfCommissionedWorksLocationInfoFormMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WfCommissionedWorksLocationInfoFormMutationMutation, WfCommissionedWorksLocationInfoFormMutationMutationVariables>(WfCommissionedWorksLocationInfoFormMutationDocument, options);
      }
export type WfCommissionedWorksLocationInfoFormMutationMutationHookResult = ReturnType<typeof useWfCommissionedWorksLocationInfoFormMutationMutation>;
export type WfCommissionedWorksLocationInfoFormMutationMutationResult = Apollo.MutationResult<WfCommissionedWorksLocationInfoFormMutationMutation>;
export type WfCommissionedWorksLocationInfoFormMutationMutationOptions = Apollo.BaseMutationOptions<WfCommissionedWorksLocationInfoFormMutationMutation, WfCommissionedWorksLocationInfoFormMutationMutationVariables>;
export const WfCommissionedWorksNotifySellersFormDocument = gql`
    mutation WFCommissionedWorksNotifySellersForm($data: String) {
  wfCommissionedWorksStaffNotifySellers(data: $data) {
    status
    formErrors
  }
}
    `;
export type WfCommissionedWorksNotifySellersFormMutationFn = Apollo.MutationFunction<WfCommissionedWorksNotifySellersFormMutation, WfCommissionedWorksNotifySellersFormMutationVariables>;

/**
 * __useWfCommissionedWorksNotifySellersFormMutation__
 *
 * To run a mutation, you first call `useWfCommissionedWorksNotifySellersFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWfCommissionedWorksNotifySellersFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wfCommissionedWorksNotifySellersFormMutation, { data, loading, error }] = useWfCommissionedWorksNotifySellersFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useWfCommissionedWorksNotifySellersFormMutation(baseOptions?: Apollo.MutationHookOptions<WfCommissionedWorksNotifySellersFormMutation, WfCommissionedWorksNotifySellersFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WfCommissionedWorksNotifySellersFormMutation, WfCommissionedWorksNotifySellersFormMutationVariables>(WfCommissionedWorksNotifySellersFormDocument, options);
      }
export type WfCommissionedWorksNotifySellersFormMutationHookResult = ReturnType<typeof useWfCommissionedWorksNotifySellersFormMutation>;
export type WfCommissionedWorksNotifySellersFormMutationResult = Apollo.MutationResult<WfCommissionedWorksNotifySellersFormMutation>;
export type WfCommissionedWorksNotifySellersFormMutationOptions = Apollo.BaseMutationOptions<WfCommissionedWorksNotifySellersFormMutation, WfCommissionedWorksNotifySellersFormMutationVariables>;
export const WfnwMoveToStepFormDocument = gql`
    mutation WFNWMoveToStepForm($data: String) {
  wfNwStaffMoveToStep(data: $data) {
    status
    formErrors
    flowItemArtwork {
      id
      ...FlowItemArtworkStaffFragment
    }
  }
}
    ${FlowItemArtworkStaffFragmentFragmentDoc}`;
export type WfnwMoveToStepFormMutationFn = Apollo.MutationFunction<WfnwMoveToStepFormMutation, WfnwMoveToStepFormMutationVariables>;

/**
 * __useWfnwMoveToStepFormMutation__
 *
 * To run a mutation, you first call `useWfnwMoveToStepFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWfnwMoveToStepFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wfnwMoveToStepFormMutation, { data, loading, error }] = useWfnwMoveToStepFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useWfnwMoveToStepFormMutation(baseOptions?: Apollo.MutationHookOptions<WfnwMoveToStepFormMutation, WfnwMoveToStepFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WfnwMoveToStepFormMutation, WfnwMoveToStepFormMutationVariables>(WfnwMoveToStepFormDocument, options);
      }
export type WfnwMoveToStepFormMutationHookResult = ReturnType<typeof useWfnwMoveToStepFormMutation>;
export type WfnwMoveToStepFormMutationResult = Apollo.MutationResult<WfnwMoveToStepFormMutation>;
export type WfnwMoveToStepFormMutationOptions = Apollo.BaseMutationOptions<WfnwMoveToStepFormMutation, WfnwMoveToStepFormMutationVariables>;
export const WfNormalWorksAddFramersToProjectFormDocument = gql`
    mutation WFNormalWorksAddFramersToProjectForm($data: String) {
  addFramersToProject(data: $data) {
    status
    formErrors
    project {
      id
      wfNwFramers {
        id
        ...FramerRowFragment
      }
    }
  }
}
    ${FramerRowFragmentFragmentDoc}`;
export type WfNormalWorksAddFramersToProjectFormMutationFn = Apollo.MutationFunction<WfNormalWorksAddFramersToProjectFormMutation, WfNormalWorksAddFramersToProjectFormMutationVariables>;

/**
 * __useWfNormalWorksAddFramersToProjectFormMutation__
 *
 * To run a mutation, you first call `useWfNormalWorksAddFramersToProjectFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWfNormalWorksAddFramersToProjectFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wfNormalWorksAddFramersToProjectFormMutation, { data, loading, error }] = useWfNormalWorksAddFramersToProjectFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useWfNormalWorksAddFramersToProjectFormMutation(baseOptions?: Apollo.MutationHookOptions<WfNormalWorksAddFramersToProjectFormMutation, WfNormalWorksAddFramersToProjectFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WfNormalWorksAddFramersToProjectFormMutation, WfNormalWorksAddFramersToProjectFormMutationVariables>(WfNormalWorksAddFramersToProjectFormDocument, options);
      }
export type WfNormalWorksAddFramersToProjectFormMutationHookResult = ReturnType<typeof useWfNormalWorksAddFramersToProjectFormMutation>;
export type WfNormalWorksAddFramersToProjectFormMutationResult = Apollo.MutationResult<WfNormalWorksAddFramersToProjectFormMutation>;
export type WfNormalWorksAddFramersToProjectFormMutationOptions = Apollo.BaseMutationOptions<WfNormalWorksAddFramersToProjectFormMutation, WfNormalWorksAddFramersToProjectFormMutationVariables>;
export const WfNormalWorksConfirmAvailabilityFormGroupDocument = gql`
    mutation WFNormalWorksConfirmAvailabilityFormGroup($uuid: String, $data: String) {
  wfSellerConfirmAvailabilityMutation(uuid: $uuid, data: $data, submit: "true") {
    status
    formErrors
  }
}
    `;
export type WfNormalWorksConfirmAvailabilityFormGroupMutationFn = Apollo.MutationFunction<WfNormalWorksConfirmAvailabilityFormGroupMutation, WfNormalWorksConfirmAvailabilityFormGroupMutationVariables>;

/**
 * __useWfNormalWorksConfirmAvailabilityFormGroupMutation__
 *
 * To run a mutation, you first call `useWfNormalWorksConfirmAvailabilityFormGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWfNormalWorksConfirmAvailabilityFormGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wfNormalWorksConfirmAvailabilityFormGroupMutation, { data, loading, error }] = useWfNormalWorksConfirmAvailabilityFormGroupMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useWfNormalWorksConfirmAvailabilityFormGroupMutation(baseOptions?: Apollo.MutationHookOptions<WfNormalWorksConfirmAvailabilityFormGroupMutation, WfNormalWorksConfirmAvailabilityFormGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WfNormalWorksConfirmAvailabilityFormGroupMutation, WfNormalWorksConfirmAvailabilityFormGroupMutationVariables>(WfNormalWorksConfirmAvailabilityFormGroupDocument, options);
      }
export type WfNormalWorksConfirmAvailabilityFormGroupMutationHookResult = ReturnType<typeof useWfNormalWorksConfirmAvailabilityFormGroupMutation>;
export type WfNormalWorksConfirmAvailabilityFormGroupMutationResult = Apollo.MutationResult<WfNormalWorksConfirmAvailabilityFormGroupMutation>;
export type WfNormalWorksConfirmAvailabilityFormGroupMutationOptions = Apollo.BaseMutationOptions<WfNormalWorksConfirmAvailabilityFormGroupMutation, WfNormalWorksConfirmAvailabilityFormGroupMutationVariables>;
export const WfNormalWorksFramerDeliveryInfoFormDocument = gql`
    mutation WFNormalWorksFramerDeliveryInfoForm($data: String, $framerHashId: String) {
  wfNwFramerRfqSaveDeliveryInfo(data: $data) {
    status
    formErrors
    framerGlobalQuotation {
      id
      ...FramerGlobalQuotationFragment
    }
  }
}
    ${FramerGlobalQuotationFragmentFragmentDoc}`;
export type WfNormalWorksFramerDeliveryInfoFormMutationFn = Apollo.MutationFunction<WfNormalWorksFramerDeliveryInfoFormMutation, WfNormalWorksFramerDeliveryInfoFormMutationVariables>;

/**
 * __useWfNormalWorksFramerDeliveryInfoFormMutation__
 *
 * To run a mutation, you first call `useWfNormalWorksFramerDeliveryInfoFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWfNormalWorksFramerDeliveryInfoFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wfNormalWorksFramerDeliveryInfoFormMutation, { data, loading, error }] = useWfNormalWorksFramerDeliveryInfoFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *      framerHashId: // value for 'framerHashId'
 *   },
 * });
 */
export function useWfNormalWorksFramerDeliveryInfoFormMutation(baseOptions?: Apollo.MutationHookOptions<WfNormalWorksFramerDeliveryInfoFormMutation, WfNormalWorksFramerDeliveryInfoFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WfNormalWorksFramerDeliveryInfoFormMutation, WfNormalWorksFramerDeliveryInfoFormMutationVariables>(WfNormalWorksFramerDeliveryInfoFormDocument, options);
      }
export type WfNormalWorksFramerDeliveryInfoFormMutationHookResult = ReturnType<typeof useWfNormalWorksFramerDeliveryInfoFormMutation>;
export type WfNormalWorksFramerDeliveryInfoFormMutationResult = Apollo.MutationResult<WfNormalWorksFramerDeliveryInfoFormMutation>;
export type WfNormalWorksFramerDeliveryInfoFormMutationOptions = Apollo.BaseMutationOptions<WfNormalWorksFramerDeliveryInfoFormMutation, WfNormalWorksFramerDeliveryInfoFormMutationVariables>;
export const WfNormalWorksFramerFramingSpecsFormUpdateSpecsDocument = gql`
    mutation WFNormalWorksFramerFramingSpecsFormUpdateSpecs($data: String, $framerHashId: String, $specId: String, $flowItemId: String) {
  wfNwFramerRfqCreateOrUpdateSpecs(
    data: $data
    specId: $specId
    flowItemId: $flowItemId
  ) {
    status
    formErrors
    framerGlobalQuotation {
      id
      ...FramerGlobalQuotationFragment
    }
  }
}
    ${FramerGlobalQuotationFragmentFragmentDoc}`;
export type WfNormalWorksFramerFramingSpecsFormUpdateSpecsMutationFn = Apollo.MutationFunction<WfNormalWorksFramerFramingSpecsFormUpdateSpecsMutation, WfNormalWorksFramerFramingSpecsFormUpdateSpecsMutationVariables>;

/**
 * __useWfNormalWorksFramerFramingSpecsFormUpdateSpecsMutation__
 *
 * To run a mutation, you first call `useWfNormalWorksFramerFramingSpecsFormUpdateSpecsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWfNormalWorksFramerFramingSpecsFormUpdateSpecsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wfNormalWorksFramerFramingSpecsFormUpdateSpecsMutation, { data, loading, error }] = useWfNormalWorksFramerFramingSpecsFormUpdateSpecsMutation({
 *   variables: {
 *      data: // value for 'data'
 *      framerHashId: // value for 'framerHashId'
 *      specId: // value for 'specId'
 *      flowItemId: // value for 'flowItemId'
 *   },
 * });
 */
export function useWfNormalWorksFramerFramingSpecsFormUpdateSpecsMutation(baseOptions?: Apollo.MutationHookOptions<WfNormalWorksFramerFramingSpecsFormUpdateSpecsMutation, WfNormalWorksFramerFramingSpecsFormUpdateSpecsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WfNormalWorksFramerFramingSpecsFormUpdateSpecsMutation, WfNormalWorksFramerFramingSpecsFormUpdateSpecsMutationVariables>(WfNormalWorksFramerFramingSpecsFormUpdateSpecsDocument, options);
      }
export type WfNormalWorksFramerFramingSpecsFormUpdateSpecsMutationHookResult = ReturnType<typeof useWfNormalWorksFramerFramingSpecsFormUpdateSpecsMutation>;
export type WfNormalWorksFramerFramingSpecsFormUpdateSpecsMutationResult = Apollo.MutationResult<WfNormalWorksFramerFramingSpecsFormUpdateSpecsMutation>;
export type WfNormalWorksFramerFramingSpecsFormUpdateSpecsMutationOptions = Apollo.BaseMutationOptions<WfNormalWorksFramerFramingSpecsFormUpdateSpecsMutation, WfNormalWorksFramerFramingSpecsFormUpdateSpecsMutationVariables>;
export const WfNormalWorksFramerFramingSpecsFormCreateNewSpecsDocument = gql`
    mutation WFNormalWorksFramerFramingSpecsFormCreateNewSpecs($flowItemId: String, $uuid: String, $framerHashId: String) {
  wfNwFramerRfqCreateNewSpecs(
    flowItemId: $flowItemId
    uuid: $uuid
    framerHashId: $framerHashId
  ) {
    status
    framerGlobalQuotation {
      id
      ...FramerGlobalQuotationFragment
    }
  }
}
    ${FramerGlobalQuotationFragmentFragmentDoc}`;
export type WfNormalWorksFramerFramingSpecsFormCreateNewSpecsMutationFn = Apollo.MutationFunction<WfNormalWorksFramerFramingSpecsFormCreateNewSpecsMutation, WfNormalWorksFramerFramingSpecsFormCreateNewSpecsMutationVariables>;

/**
 * __useWfNormalWorksFramerFramingSpecsFormCreateNewSpecsMutation__
 *
 * To run a mutation, you first call `useWfNormalWorksFramerFramingSpecsFormCreateNewSpecsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWfNormalWorksFramerFramingSpecsFormCreateNewSpecsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wfNormalWorksFramerFramingSpecsFormCreateNewSpecsMutation, { data, loading, error }] = useWfNormalWorksFramerFramingSpecsFormCreateNewSpecsMutation({
 *   variables: {
 *      flowItemId: // value for 'flowItemId'
 *      uuid: // value for 'uuid'
 *      framerHashId: // value for 'framerHashId'
 *   },
 * });
 */
export function useWfNormalWorksFramerFramingSpecsFormCreateNewSpecsMutation(baseOptions?: Apollo.MutationHookOptions<WfNormalWorksFramerFramingSpecsFormCreateNewSpecsMutation, WfNormalWorksFramerFramingSpecsFormCreateNewSpecsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WfNormalWorksFramerFramingSpecsFormCreateNewSpecsMutation, WfNormalWorksFramerFramingSpecsFormCreateNewSpecsMutationVariables>(WfNormalWorksFramerFramingSpecsFormCreateNewSpecsDocument, options);
      }
export type WfNormalWorksFramerFramingSpecsFormCreateNewSpecsMutationHookResult = ReturnType<typeof useWfNormalWorksFramerFramingSpecsFormCreateNewSpecsMutation>;
export type WfNormalWorksFramerFramingSpecsFormCreateNewSpecsMutationResult = Apollo.MutationResult<WfNormalWorksFramerFramingSpecsFormCreateNewSpecsMutation>;
export type WfNormalWorksFramerFramingSpecsFormCreateNewSpecsMutationOptions = Apollo.BaseMutationOptions<WfNormalWorksFramerFramingSpecsFormCreateNewSpecsMutation, WfNormalWorksFramerFramingSpecsFormCreateNewSpecsMutationVariables>;
export const WfNormalWorksFramerFramingSpecsFormDeleteSpecDocument = gql`
    mutation WFNormalWorksFramerFramingSpecsFormDeleteSpec($flowItemId: String, $uuid: String, $framerHashId: String, $specId: String) {
  wfNwFramerRfqDeleteSpec(
    flowItemId: $flowItemId
    uuid: $uuid
    framerHashId: $framerHashId
    specId: $specId
  ) {
    status
    framerGlobalQuotation {
      id
      ...FramerGlobalQuotationFragment
    }
  }
}
    ${FramerGlobalQuotationFragmentFragmentDoc}`;
export type WfNormalWorksFramerFramingSpecsFormDeleteSpecMutationFn = Apollo.MutationFunction<WfNormalWorksFramerFramingSpecsFormDeleteSpecMutation, WfNormalWorksFramerFramingSpecsFormDeleteSpecMutationVariables>;

/**
 * __useWfNormalWorksFramerFramingSpecsFormDeleteSpecMutation__
 *
 * To run a mutation, you first call `useWfNormalWorksFramerFramingSpecsFormDeleteSpecMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWfNormalWorksFramerFramingSpecsFormDeleteSpecMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wfNormalWorksFramerFramingSpecsFormDeleteSpecMutation, { data, loading, error }] = useWfNormalWorksFramerFramingSpecsFormDeleteSpecMutation({
 *   variables: {
 *      flowItemId: // value for 'flowItemId'
 *      uuid: // value for 'uuid'
 *      framerHashId: // value for 'framerHashId'
 *      specId: // value for 'specId'
 *   },
 * });
 */
export function useWfNormalWorksFramerFramingSpecsFormDeleteSpecMutation(baseOptions?: Apollo.MutationHookOptions<WfNormalWorksFramerFramingSpecsFormDeleteSpecMutation, WfNormalWorksFramerFramingSpecsFormDeleteSpecMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WfNormalWorksFramerFramingSpecsFormDeleteSpecMutation, WfNormalWorksFramerFramingSpecsFormDeleteSpecMutationVariables>(WfNormalWorksFramerFramingSpecsFormDeleteSpecDocument, options);
      }
export type WfNormalWorksFramerFramingSpecsFormDeleteSpecMutationHookResult = ReturnType<typeof useWfNormalWorksFramerFramingSpecsFormDeleteSpecMutation>;
export type WfNormalWorksFramerFramingSpecsFormDeleteSpecMutationResult = Apollo.MutationResult<WfNormalWorksFramerFramingSpecsFormDeleteSpecMutation>;
export type WfNormalWorksFramerFramingSpecsFormDeleteSpecMutationOptions = Apollo.BaseMutationOptions<WfNormalWorksFramerFramingSpecsFormDeleteSpecMutation, WfNormalWorksFramerFramingSpecsFormDeleteSpecMutationVariables>;
export const WfNormalWorksFramerGlobalInfoFormDocument = gql`
    mutation WFNormalWorksFramerGlobalInfoForm($data: String, $framerHashId: String) {
  wfNwFramerRfqUpdateGlobal(data: $data) {
    status
    formErrors
    framerGlobalQuotation {
      id
      ...FramerGlobalQuotationFragment
    }
  }
}
    ${FramerGlobalQuotationFragmentFragmentDoc}`;
export type WfNormalWorksFramerGlobalInfoFormMutationFn = Apollo.MutationFunction<WfNormalWorksFramerGlobalInfoFormMutation, WfNormalWorksFramerGlobalInfoFormMutationVariables>;

/**
 * __useWfNormalWorksFramerGlobalInfoFormMutation__
 *
 * To run a mutation, you first call `useWfNormalWorksFramerGlobalInfoFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWfNormalWorksFramerGlobalInfoFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wfNormalWorksFramerGlobalInfoFormMutation, { data, loading, error }] = useWfNormalWorksFramerGlobalInfoFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *      framerHashId: // value for 'framerHashId'
 *   },
 * });
 */
export function useWfNormalWorksFramerGlobalInfoFormMutation(baseOptions?: Apollo.MutationHookOptions<WfNormalWorksFramerGlobalInfoFormMutation, WfNormalWorksFramerGlobalInfoFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WfNormalWorksFramerGlobalInfoFormMutation, WfNormalWorksFramerGlobalInfoFormMutationVariables>(WfNormalWorksFramerGlobalInfoFormDocument, options);
      }
export type WfNormalWorksFramerGlobalInfoFormMutationHookResult = ReturnType<typeof useWfNormalWorksFramerGlobalInfoFormMutation>;
export type WfNormalWorksFramerGlobalInfoFormMutationResult = Apollo.MutationResult<WfNormalWorksFramerGlobalInfoFormMutation>;
export type WfNormalWorksFramerGlobalInfoFormMutationOptions = Apollo.BaseMutationOptions<WfNormalWorksFramerGlobalInfoFormMutation, WfNormalWorksFramerGlobalInfoFormMutationVariables>;
export const WfNormalWorksFramerInstallationInfoFormDocument = gql`
    mutation WFNormalWorksFramerInstallationInfoForm($data: String, $framerHashId: String) {
  wfNwFramerRfqSaveInstallationInfo(data: $data) {
    status
    formErrors
    framerGlobalQuotation {
      id
      ...FramerGlobalQuotationFragment
    }
  }
}
    ${FramerGlobalQuotationFragmentFragmentDoc}`;
export type WfNormalWorksFramerInstallationInfoFormMutationFn = Apollo.MutationFunction<WfNormalWorksFramerInstallationInfoFormMutation, WfNormalWorksFramerInstallationInfoFormMutationVariables>;

/**
 * __useWfNormalWorksFramerInstallationInfoFormMutation__
 *
 * To run a mutation, you first call `useWfNormalWorksFramerInstallationInfoFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWfNormalWorksFramerInstallationInfoFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wfNormalWorksFramerInstallationInfoFormMutation, { data, loading, error }] = useWfNormalWorksFramerInstallationInfoFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *      framerHashId: // value for 'framerHashId'
 *   },
 * });
 */
export function useWfNormalWorksFramerInstallationInfoFormMutation(baseOptions?: Apollo.MutationHookOptions<WfNormalWorksFramerInstallationInfoFormMutation, WfNormalWorksFramerInstallationInfoFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WfNormalWorksFramerInstallationInfoFormMutation, WfNormalWorksFramerInstallationInfoFormMutationVariables>(WfNormalWorksFramerInstallationInfoFormDocument, options);
      }
export type WfNormalWorksFramerInstallationInfoFormMutationHookResult = ReturnType<typeof useWfNormalWorksFramerInstallationInfoFormMutation>;
export type WfNormalWorksFramerInstallationInfoFormMutationResult = Apollo.MutationResult<WfNormalWorksFramerInstallationInfoFormMutation>;
export type WfNormalWorksFramerInstallationInfoFormMutationOptions = Apollo.BaseMutationOptions<WfNormalWorksFramerInstallationInfoFormMutation, WfNormalWorksFramerInstallationInfoFormMutationVariables>;
export const FramerWfNormalWorksQuotationFormGroupDocument = gql`
    mutation FramerWFNormalWorksQuotationFormGroup($data: String, $framerHashId: String) {
  wfNwFramerRfqSubmitMutation(data: $data) {
    status
    formErrors
    framerGlobalQuotation {
      id
      ...FramerGlobalQuotationFragment
    }
  }
}
    ${FramerGlobalQuotationFragmentFragmentDoc}`;
export type FramerWfNormalWorksQuotationFormGroupMutationFn = Apollo.MutationFunction<FramerWfNormalWorksQuotationFormGroupMutation, FramerWfNormalWorksQuotationFormGroupMutationVariables>;

/**
 * __useFramerWfNormalWorksQuotationFormGroupMutation__
 *
 * To run a mutation, you first call `useFramerWfNormalWorksQuotationFormGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFramerWfNormalWorksQuotationFormGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [framerWfNormalWorksQuotationFormGroupMutation, { data, loading, error }] = useFramerWfNormalWorksQuotationFormGroupMutation({
 *   variables: {
 *      data: // value for 'data'
 *      framerHashId: // value for 'framerHashId'
 *   },
 * });
 */
export function useFramerWfNormalWorksQuotationFormGroupMutation(baseOptions?: Apollo.MutationHookOptions<FramerWfNormalWorksQuotationFormGroupMutation, FramerWfNormalWorksQuotationFormGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FramerWfNormalWorksQuotationFormGroupMutation, FramerWfNormalWorksQuotationFormGroupMutationVariables>(FramerWfNormalWorksQuotationFormGroupDocument, options);
      }
export type FramerWfNormalWorksQuotationFormGroupMutationHookResult = ReturnType<typeof useFramerWfNormalWorksQuotationFormGroupMutation>;
export type FramerWfNormalWorksQuotationFormGroupMutationResult = Apollo.MutationResult<FramerWfNormalWorksQuotationFormGroupMutation>;
export type FramerWfNormalWorksQuotationFormGroupMutationOptions = Apollo.BaseMutationOptions<FramerWfNormalWorksQuotationFormGroupMutation, FramerWfNormalWorksQuotationFormGroupMutationVariables>;
export const WfNormalWorksFramerStretchingInfoFormDocument = gql`
    mutation WFNormalWorksFramerStretchingInfoForm($data: String, $framerHashId: String, $flowItemId: String) {
  wfNwFramerRfqSaveStretchingInfo(data: $data, flowItemId: $flowItemId) {
    status
    formErrors
    framerGlobalQuotation {
      id
      ...FramerGlobalQuotationFragment
    }
  }
}
    ${FramerGlobalQuotationFragmentFragmentDoc}`;
export type WfNormalWorksFramerStretchingInfoFormMutationFn = Apollo.MutationFunction<WfNormalWorksFramerStretchingInfoFormMutation, WfNormalWorksFramerStretchingInfoFormMutationVariables>;

/**
 * __useWfNormalWorksFramerStretchingInfoFormMutation__
 *
 * To run a mutation, you first call `useWfNormalWorksFramerStretchingInfoFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWfNormalWorksFramerStretchingInfoFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wfNormalWorksFramerStretchingInfoFormMutation, { data, loading, error }] = useWfNormalWorksFramerStretchingInfoFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *      framerHashId: // value for 'framerHashId'
 *      flowItemId: // value for 'flowItemId'
 *   },
 * });
 */
export function useWfNormalWorksFramerStretchingInfoFormMutation(baseOptions?: Apollo.MutationHookOptions<WfNormalWorksFramerStretchingInfoFormMutation, WfNormalWorksFramerStretchingInfoFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WfNormalWorksFramerStretchingInfoFormMutation, WfNormalWorksFramerStretchingInfoFormMutationVariables>(WfNormalWorksFramerStretchingInfoFormDocument, options);
      }
export type WfNormalWorksFramerStretchingInfoFormMutationHookResult = ReturnType<typeof useWfNormalWorksFramerStretchingInfoFormMutation>;
export type WfNormalWorksFramerStretchingInfoFormMutationResult = Apollo.MutationResult<WfNormalWorksFramerStretchingInfoFormMutation>;
export type WfNormalWorksFramerStretchingInfoFormMutationOptions = Apollo.BaseMutationOptions<WfNormalWorksFramerStretchingInfoFormMutation, WfNormalWorksFramerStretchingInfoFormMutationVariables>;
export const WfNormalWorksNotifyFramersFormDocument = gql`
    mutation WFNormalWorksNotifyFramersForm($data: String) {
  wfNwStaffRequestFramerQuotation(data: $data) {
    status
    formErrors
  }
}
    `;
export type WfNormalWorksNotifyFramersFormMutationFn = Apollo.MutationFunction<WfNormalWorksNotifyFramersFormMutation, WfNormalWorksNotifyFramersFormMutationVariables>;

/**
 * __useWfNormalWorksNotifyFramersFormMutation__
 *
 * To run a mutation, you first call `useWfNormalWorksNotifyFramersFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWfNormalWorksNotifyFramersFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wfNormalWorksNotifyFramersFormMutation, { data, loading, error }] = useWfNormalWorksNotifyFramersFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useWfNormalWorksNotifyFramersFormMutation(baseOptions?: Apollo.MutationHookOptions<WfNormalWorksNotifyFramersFormMutation, WfNormalWorksNotifyFramersFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WfNormalWorksNotifyFramersFormMutation, WfNormalWorksNotifyFramersFormMutationVariables>(WfNormalWorksNotifyFramersFormDocument, options);
      }
export type WfNormalWorksNotifyFramersFormMutationHookResult = ReturnType<typeof useWfNormalWorksNotifyFramersFormMutation>;
export type WfNormalWorksNotifyFramersFormMutationResult = Apollo.MutationResult<WfNormalWorksNotifyFramersFormMutation>;
export type WfNormalWorksNotifyFramersFormMutationOptions = Apollo.BaseMutationOptions<WfNormalWorksNotifyFramersFormMutation, WfNormalWorksNotifyFramersFormMutationVariables>;
export const WfNormalWorksNotifySellersFormDocument = gql`
    mutation WFNormalWorksNotifySellersForm($data: String) {
  wfStaffCheckAvailability(data: $data) {
    status
    formErrors
  }
}
    `;
export type WfNormalWorksNotifySellersFormMutationFn = Apollo.MutationFunction<WfNormalWorksNotifySellersFormMutation, WfNormalWorksNotifySellersFormMutationVariables>;

/**
 * __useWfNormalWorksNotifySellersFormMutation__
 *
 * To run a mutation, you first call `useWfNormalWorksNotifySellersFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWfNormalWorksNotifySellersFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wfNormalWorksNotifySellersFormMutation, { data, loading, error }] = useWfNormalWorksNotifySellersFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useWfNormalWorksNotifySellersFormMutation(baseOptions?: Apollo.MutationHookOptions<WfNormalWorksNotifySellersFormMutation, WfNormalWorksNotifySellersFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WfNormalWorksNotifySellersFormMutation, WfNormalWorksNotifySellersFormMutationVariables>(WfNormalWorksNotifySellersFormDocument, options);
      }
export type WfNormalWorksNotifySellersFormMutationHookResult = ReturnType<typeof useWfNormalWorksNotifySellersFormMutation>;
export type WfNormalWorksNotifySellersFormMutationResult = Apollo.MutationResult<WfNormalWorksNotifySellersFormMutation>;
export type WfNormalWorksNotifySellersFormMutationOptions = Apollo.BaseMutationOptions<WfNormalWorksNotifySellersFormMutation, WfNormalWorksNotifySellersFormMutationVariables>;
export const ProjectAreaBoxFormDocument = gql`
    mutation ProjectAreaBoxForm($data: String) {
  updateProjectLocationOverview(data: $data) {
    status
    formErrors
    autoSubmit
    project {
      id
      ...UserWizardProjectLocationItemFragment
    }
  }
}
    ${UserWizardProjectLocationItemFragmentFragmentDoc}`;
export type ProjectAreaBoxFormMutationFn = Apollo.MutationFunction<ProjectAreaBoxFormMutation, ProjectAreaBoxFormMutationVariables>;

/**
 * __useProjectAreaBoxFormMutation__
 *
 * To run a mutation, you first call `useProjectAreaBoxFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectAreaBoxFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectAreaBoxFormMutation, { data, loading, error }] = useProjectAreaBoxFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useProjectAreaBoxFormMutation(baseOptions?: Apollo.MutationHookOptions<ProjectAreaBoxFormMutation, ProjectAreaBoxFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectAreaBoxFormMutation, ProjectAreaBoxFormMutationVariables>(ProjectAreaBoxFormDocument, options);
      }
export type ProjectAreaBoxFormMutationHookResult = ReturnType<typeof useProjectAreaBoxFormMutation>;
export type ProjectAreaBoxFormMutationResult = Apollo.MutationResult<ProjectAreaBoxFormMutation>;
export type ProjectAreaBoxFormMutationOptions = Apollo.BaseMutationOptions<ProjectAreaBoxFormMutation, ProjectAreaBoxFormMutationVariables>;
export const ProjectAreaFormDocument = gql`
    mutation ProjectAreaForm($data: String) {
  updateProjectLocationOverview(data: $data) {
    status
    formErrors
    autoSubmit
    project {
      id
      ...ProjectOverviewFragment
    }
  }
}
    ${ProjectOverviewFragmentFragmentDoc}`;
export type ProjectAreaFormMutationFn = Apollo.MutationFunction<ProjectAreaFormMutation, ProjectAreaFormMutationVariables>;

/**
 * __useProjectAreaFormMutation__
 *
 * To run a mutation, you first call `useProjectAreaFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectAreaFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectAreaFormMutation, { data, loading, error }] = useProjectAreaFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useProjectAreaFormMutation(baseOptions?: Apollo.MutationHookOptions<ProjectAreaFormMutation, ProjectAreaFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectAreaFormMutation, ProjectAreaFormMutationVariables>(ProjectAreaFormDocument, options);
      }
export type ProjectAreaFormMutationHookResult = ReturnType<typeof useProjectAreaFormMutation>;
export type ProjectAreaFormMutationResult = Apollo.MutationResult<ProjectAreaFormMutation>;
export type ProjectAreaFormMutationOptions = Apollo.BaseMutationOptions<ProjectAreaFormMutation, ProjectAreaFormMutationVariables>;
export const ProjectOverviewFormDocument = gql`
    mutation ProjectOverviewForm($data: String) {
  updateProjectOverview(data: $data) {
    status
    formErrors
    project {
      id
      ...ProjectOverviewFragment
    }
  }
}
    ${ProjectOverviewFragmentFragmentDoc}`;
export type ProjectOverviewFormMutationFn = Apollo.MutationFunction<ProjectOverviewFormMutation, ProjectOverviewFormMutationVariables>;

/**
 * __useProjectOverviewFormMutation__
 *
 * To run a mutation, you first call `useProjectOverviewFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectOverviewFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectOverviewFormMutation, { data, loading, error }] = useProjectOverviewFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useProjectOverviewFormMutation(baseOptions?: Apollo.MutationHookOptions<ProjectOverviewFormMutation, ProjectOverviewFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectOverviewFormMutation, ProjectOverviewFormMutationVariables>(ProjectOverviewFormDocument, options);
      }
export type ProjectOverviewFormMutationHookResult = ReturnType<typeof useProjectOverviewFormMutation>;
export type ProjectOverviewFormMutationResult = Apollo.MutationResult<ProjectOverviewFormMutation>;
export type ProjectOverviewFormMutationOptions = Apollo.BaseMutationOptions<ProjectOverviewFormMutation, ProjectOverviewFormMutationVariables>;
export const ProjectBudgetFormDocument = gql`
    mutation ProjectBudgetForm($data: String) {
  updateProjectBudget(data: $data) {
    status
    formErrors
    autoSubmit
    project {
      id
      ...ProjectBudgetFragment
    }
  }
}
    ${ProjectBudgetFragmentFragmentDoc}`;
export type ProjectBudgetFormMutationFn = Apollo.MutationFunction<ProjectBudgetFormMutation, ProjectBudgetFormMutationVariables>;

/**
 * __useProjectBudgetFormMutation__
 *
 * To run a mutation, you first call `useProjectBudgetFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectBudgetFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectBudgetFormMutation, { data, loading, error }] = useProjectBudgetFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useProjectBudgetFormMutation(baseOptions?: Apollo.MutationHookOptions<ProjectBudgetFormMutation, ProjectBudgetFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectBudgetFormMutation, ProjectBudgetFormMutationVariables>(ProjectBudgetFormDocument, options);
      }
export type ProjectBudgetFormMutationHookResult = ReturnType<typeof useProjectBudgetFormMutation>;
export type ProjectBudgetFormMutationResult = Apollo.MutationResult<ProjectBudgetFormMutation>;
export type ProjectBudgetFormMutationOptions = Apollo.BaseMutationOptions<ProjectBudgetFormMutation, ProjectBudgetFormMutationVariables>;
export const ProjectCreateUpdateClientFormDocument = gql`
    mutation ProjectCreateUpdateClientForm($data: String) {
  updateCreateClient(data: $data) {
    status
    formErrors
    result {
      id
      name
      slug
    }
  }
}
    `;
export type ProjectCreateUpdateClientFormMutationFn = Apollo.MutationFunction<ProjectCreateUpdateClientFormMutation, ProjectCreateUpdateClientFormMutationVariables>;

/**
 * __useProjectCreateUpdateClientFormMutation__
 *
 * To run a mutation, you first call `useProjectCreateUpdateClientFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectCreateUpdateClientFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectCreateUpdateClientFormMutation, { data, loading, error }] = useProjectCreateUpdateClientFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useProjectCreateUpdateClientFormMutation(baseOptions?: Apollo.MutationHookOptions<ProjectCreateUpdateClientFormMutation, ProjectCreateUpdateClientFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectCreateUpdateClientFormMutation, ProjectCreateUpdateClientFormMutationVariables>(ProjectCreateUpdateClientFormDocument, options);
      }
export type ProjectCreateUpdateClientFormMutationHookResult = ReturnType<typeof useProjectCreateUpdateClientFormMutation>;
export type ProjectCreateUpdateClientFormMutationResult = Apollo.MutationResult<ProjectCreateUpdateClientFormMutation>;
export type ProjectCreateUpdateClientFormMutationOptions = Apollo.BaseMutationOptions<ProjectCreateUpdateClientFormMutation, ProjectCreateUpdateClientFormMutationVariables>;
export const ProjectLocationBoxFormDocument = gql`
    mutation ProjectLocationBoxForm($data: String) {
  updateLocationItem(data: $data) {
    status
    formErrors
    autoSubmit
    project {
      id
    }
    locationItem {
      id
      ...LocationItemDetailFragment
    }
  }
}
    ${LocationItemDetailFragmentFragmentDoc}`;
export type ProjectLocationBoxFormMutationFn = Apollo.MutationFunction<ProjectLocationBoxFormMutation, ProjectLocationBoxFormMutationVariables>;

/**
 * __useProjectLocationBoxFormMutation__
 *
 * To run a mutation, you first call `useProjectLocationBoxFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectLocationBoxFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectLocationBoxFormMutation, { data, loading, error }] = useProjectLocationBoxFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useProjectLocationBoxFormMutation(baseOptions?: Apollo.MutationHookOptions<ProjectLocationBoxFormMutation, ProjectLocationBoxFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectLocationBoxFormMutation, ProjectLocationBoxFormMutationVariables>(ProjectLocationBoxFormDocument, options);
      }
export type ProjectLocationBoxFormMutationHookResult = ReturnType<typeof useProjectLocationBoxFormMutation>;
export type ProjectLocationBoxFormMutationResult = Apollo.MutationResult<ProjectLocationBoxFormMutation>;
export type ProjectLocationBoxFormMutationOptions = Apollo.BaseMutationOptions<ProjectLocationBoxFormMutation, ProjectLocationBoxFormMutationVariables>;
export const ProjectQuickSourceFormDocument = gql`
    mutation ProjectQuickSourceForm($data: String) {
  createQuickSource(data: $data) {
    status
    formErrors
    project {
      id
      hashId
    }
  }
}
    `;
export type ProjectQuickSourceFormMutationFn = Apollo.MutationFunction<ProjectQuickSourceFormMutation, ProjectQuickSourceFormMutationVariables>;

/**
 * __useProjectQuickSourceFormMutation__
 *
 * To run a mutation, you first call `useProjectQuickSourceFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectQuickSourceFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectQuickSourceFormMutation, { data, loading, error }] = useProjectQuickSourceFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useProjectQuickSourceFormMutation(baseOptions?: Apollo.MutationHookOptions<ProjectQuickSourceFormMutation, ProjectQuickSourceFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectQuickSourceFormMutation, ProjectQuickSourceFormMutationVariables>(ProjectQuickSourceFormDocument, options);
      }
export type ProjectQuickSourceFormMutationHookResult = ReturnType<typeof useProjectQuickSourceFormMutation>;
export type ProjectQuickSourceFormMutationResult = Apollo.MutationResult<ProjectQuickSourceFormMutation>;
export type ProjectQuickSourceFormMutationOptions = Apollo.BaseMutationOptions<ProjectQuickSourceFormMutation, ProjectQuickSourceFormMutationVariables>;
export const ProjectTimelineFormDocument = gql`
    mutation ProjectTimelineForm($data: String) {
  updateProjectTimeline(data: $data) {
    status
    formErrors
    autoSubmit
    project {
      id
      ...ProjectTimelineFragment
    }
  }
}
    ${ProjectTimelineFragmentFragmentDoc}`;
export type ProjectTimelineFormMutationFn = Apollo.MutationFunction<ProjectTimelineFormMutation, ProjectTimelineFormMutationVariables>;

/**
 * __useProjectTimelineFormMutation__
 *
 * To run a mutation, you first call `useProjectTimelineFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectTimelineFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectTimelineFormMutation, { data, loading, error }] = useProjectTimelineFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useProjectTimelineFormMutation(baseOptions?: Apollo.MutationHookOptions<ProjectTimelineFormMutation, ProjectTimelineFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectTimelineFormMutation, ProjectTimelineFormMutationVariables>(ProjectTimelineFormDocument, options);
      }
export type ProjectTimelineFormMutationHookResult = ReturnType<typeof useProjectTimelineFormMutation>;
export type ProjectTimelineFormMutationResult = Apollo.MutationResult<ProjectTimelineFormMutation>;
export type ProjectTimelineFormMutationOptions = Apollo.BaseMutationOptions<ProjectTimelineFormMutation, ProjectTimelineFormMutationVariables>;
export const ProjectTitleFormUpdateProjectTitleDocument = gql`
    mutation ProjectTitleFormUpdateProjectTitle($data: String) {
  updateProjectTitle(data: $data) {
    status
    formErrors
    autoSubmit
    project {
      id
      ...ProjectTitleFragment
    }
  }
}
    ${ProjectTitleFragmentFragmentDoc}`;
export type ProjectTitleFormUpdateProjectTitleMutationFn = Apollo.MutationFunction<ProjectTitleFormUpdateProjectTitleMutation, ProjectTitleFormUpdateProjectTitleMutationVariables>;

/**
 * __useProjectTitleFormUpdateProjectTitleMutation__
 *
 * To run a mutation, you first call `useProjectTitleFormUpdateProjectTitleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectTitleFormUpdateProjectTitleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectTitleFormUpdateProjectTitleMutation, { data, loading, error }] = useProjectTitleFormUpdateProjectTitleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useProjectTitleFormUpdateProjectTitleMutation(baseOptions?: Apollo.MutationHookOptions<ProjectTitleFormUpdateProjectTitleMutation, ProjectTitleFormUpdateProjectTitleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectTitleFormUpdateProjectTitleMutation, ProjectTitleFormUpdateProjectTitleMutationVariables>(ProjectTitleFormUpdateProjectTitleDocument, options);
      }
export type ProjectTitleFormUpdateProjectTitleMutationHookResult = ReturnType<typeof useProjectTitleFormUpdateProjectTitleMutation>;
export type ProjectTitleFormUpdateProjectTitleMutationResult = Apollo.MutationResult<ProjectTitleFormUpdateProjectTitleMutation>;
export type ProjectTitleFormUpdateProjectTitleMutationOptions = Apollo.BaseMutationOptions<ProjectTitleFormUpdateProjectTitleMutation, ProjectTitleFormUpdateProjectTitleMutationVariables>;
export const ProjectTitleFormUpdateClientDocument = gql`
    mutation ProjectTitleFormUpdateClient($data: String) {
  updateCreateClient(data: $data) {
    status
    formErrors
    result {
      id
      name
      slug
    }
  }
}
    `;
export type ProjectTitleFormUpdateClientMutationFn = Apollo.MutationFunction<ProjectTitleFormUpdateClientMutation, ProjectTitleFormUpdateClientMutationVariables>;

/**
 * __useProjectTitleFormUpdateClientMutation__
 *
 * To run a mutation, you first call `useProjectTitleFormUpdateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectTitleFormUpdateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectTitleFormUpdateClientMutation, { data, loading, error }] = useProjectTitleFormUpdateClientMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useProjectTitleFormUpdateClientMutation(baseOptions?: Apollo.MutationHookOptions<ProjectTitleFormUpdateClientMutation, ProjectTitleFormUpdateClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectTitleFormUpdateClientMutation, ProjectTitleFormUpdateClientMutationVariables>(ProjectTitleFormUpdateClientDocument, options);
      }
export type ProjectTitleFormUpdateClientMutationHookResult = ReturnType<typeof useProjectTitleFormUpdateClientMutation>;
export type ProjectTitleFormUpdateClientMutationResult = Apollo.MutationResult<ProjectTitleFormUpdateClientMutation>;
export type ProjectTitleFormUpdateClientMutationOptions = Apollo.BaseMutationOptions<ProjectTitleFormUpdateClientMutation, ProjectTitleFormUpdateClientMutationVariables>;
export const ProjectTypeFormDocument = gql`
    mutation ProjectTypeForm($data: String) {
  updateProjectType(data: $data) {
    status
    formErrors
    autoSubmit
    project {
      id
      ...ProjectTypeFragment
    }
  }
}
    ${ProjectTypeFragmentFragmentDoc}`;
export type ProjectTypeFormMutationFn = Apollo.MutationFunction<ProjectTypeFormMutation, ProjectTypeFormMutationVariables>;

/**
 * __useProjectTypeFormMutation__
 *
 * To run a mutation, you first call `useProjectTypeFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectTypeFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectTypeFormMutation, { data, loading, error }] = useProjectTypeFormMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useProjectTypeFormMutation(baseOptions?: Apollo.MutationHookOptions<ProjectTypeFormMutation, ProjectTypeFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectTypeFormMutation, ProjectTypeFormMutationVariables>(ProjectTypeFormDocument, options);
      }
export type ProjectTypeFormMutationHookResult = ReturnType<typeof useProjectTypeFormMutation>;
export type ProjectTypeFormMutationResult = Apollo.MutationResult<ProjectTypeFormMutation>;
export type ProjectTypeFormMutationOptions = Apollo.BaseMutationOptions<ProjectTypeFormMutation, ProjectTypeFormMutationVariables>;
export const UseCanAccessDocument = gql`
    query UseCanAccess($hashId: String) {
  project(hashId: $hashId) {
    id
    status
  }
}
    `;

/**
 * __useUseCanAccessQuery__
 *
 * To run a query within a React component, call `useUseCanAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseCanAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseCanAccessQuery({
 *   variables: {
 *      hashId: // value for 'hashId'
 *   },
 * });
 */
export function useUseCanAccessQuery(baseOptions?: Apollo.QueryHookOptions<UseCanAccessQuery, UseCanAccessQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UseCanAccessQuery, UseCanAccessQueryVariables>(UseCanAccessDocument, options);
      }
export function useUseCanAccessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UseCanAccessQuery, UseCanAccessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UseCanAccessQuery, UseCanAccessQueryVariables>(UseCanAccessDocument, options);
        }
export type UseCanAccessQueryHookResult = ReturnType<typeof useUseCanAccessQuery>;
export type UseCanAccessLazyQueryHookResult = ReturnType<typeof useUseCanAccessLazyQuery>;
export type UseCanAccessQueryResult = Apollo.QueryResult<UseCanAccessQuery, UseCanAccessQueryVariables>;
export const UseCountriesDocument = gql`
    query UseCountries {
  countries {
    name
    code
  }
}
    `;

/**
 * __useUseCountriesQuery__
 *
 * To run a query within a React component, call `useUseCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUseCountriesQuery(baseOptions?: Apollo.QueryHookOptions<UseCountriesQuery, UseCountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UseCountriesQuery, UseCountriesQueryVariables>(UseCountriesDocument, options);
      }
export function useUseCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UseCountriesQuery, UseCountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UseCountriesQuery, UseCountriesQueryVariables>(UseCountriesDocument, options);
        }
export type UseCountriesQueryHookResult = ReturnType<typeof useUseCountriesQuery>;
export type UseCountriesLazyQueryHookResult = ReturnType<typeof useUseCountriesLazyQuery>;
export type UseCountriesQueryResult = Apollo.QueryResult<UseCountriesQuery, UseCountriesQueryVariables>;
export const UseCurrenciesDocument = gql`
    query UseCurrencies {
  projectCurrencies
}
    `;

/**
 * __useUseCurrenciesQuery__
 *
 * To run a query within a React component, call `useUseCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseCurrenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUseCurrenciesQuery(baseOptions?: Apollo.QueryHookOptions<UseCurrenciesQuery, UseCurrenciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UseCurrenciesQuery, UseCurrenciesQueryVariables>(UseCurrenciesDocument, options);
      }
export function useUseCurrenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UseCurrenciesQuery, UseCurrenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UseCurrenciesQuery, UseCurrenciesQueryVariables>(UseCurrenciesDocument, options);
        }
export type UseCurrenciesQueryHookResult = ReturnType<typeof useUseCurrenciesQuery>;
export type UseCurrenciesLazyQueryHookResult = ReturnType<typeof useUseCurrenciesLazyQuery>;
export type UseCurrenciesQueryResult = Apollo.QueryResult<UseCurrenciesQuery, UseCurrenciesQueryVariables>;
export const UseFramersDocument = gql`
    query UseFramers {
  framers {
    id
    name
  }
}
    `;

/**
 * __useUseFramersQuery__
 *
 * To run a query within a React component, call `useUseFramersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseFramersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseFramersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUseFramersQuery(baseOptions?: Apollo.QueryHookOptions<UseFramersQuery, UseFramersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UseFramersQuery, UseFramersQueryVariables>(UseFramersDocument, options);
      }
export function useUseFramersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UseFramersQuery, UseFramersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UseFramersQuery, UseFramersQueryVariables>(UseFramersDocument, options);
        }
export type UseFramersQueryHookResult = ReturnType<typeof useUseFramersQuery>;
export type UseFramersLazyQueryHookResult = ReturnType<typeof useUseFramersLazyQuery>;
export type UseFramersQueryResult = Apollo.QueryResult<UseFramersQuery, UseFramersQueryVariables>;
export const UseFramingMaterialsDocument = gql`
    query UseFramingMaterials {
  getFrameMaterials {
    id
    name
    slug
    color
    materialImage
  }
}
    `;

/**
 * __useUseFramingMaterialsQuery__
 *
 * To run a query within a React component, call `useUseFramingMaterialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseFramingMaterialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseFramingMaterialsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUseFramingMaterialsQuery(baseOptions?: Apollo.QueryHookOptions<UseFramingMaterialsQuery, UseFramingMaterialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UseFramingMaterialsQuery, UseFramingMaterialsQueryVariables>(UseFramingMaterialsDocument, options);
      }
export function useUseFramingMaterialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UseFramingMaterialsQuery, UseFramingMaterialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UseFramingMaterialsQuery, UseFramingMaterialsQueryVariables>(UseFramingMaterialsDocument, options);
        }
export type UseFramingMaterialsQueryHookResult = ReturnType<typeof useUseFramingMaterialsQuery>;
export type UseFramingMaterialsLazyQueryHookResult = ReturnType<typeof useUseFramingMaterialsLazyQuery>;
export type UseFramingMaterialsQueryResult = Apollo.QueryResult<UseFramingMaterialsQuery, UseFramingMaterialsQueryVariables>;
export const UseIndustriesDocument = gql`
    query UseIndustries {
  typeOfIndustries {
    id
    name
    slug
  }
}
    `;

/**
 * __useUseIndustriesQuery__
 *
 * To run a query within a React component, call `useUseIndustriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseIndustriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseIndustriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUseIndustriesQuery(baseOptions?: Apollo.QueryHookOptions<UseIndustriesQuery, UseIndustriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UseIndustriesQuery, UseIndustriesQueryVariables>(UseIndustriesDocument, options);
      }
export function useUseIndustriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UseIndustriesQuery, UseIndustriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UseIndustriesQuery, UseIndustriesQueryVariables>(UseIndustriesDocument, options);
        }
export type UseIndustriesQueryHookResult = ReturnType<typeof useUseIndustriesQuery>;
export type UseIndustriesLazyQueryHookResult = ReturnType<typeof useUseIndustriesLazyQuery>;
export type UseIndustriesQueryResult = Apollo.QueryResult<UseIndustriesQuery, UseIndustriesQueryVariables>;
export const UseLocationItemAttrsDocument = gql`
    query UseLocationItemAttrs {
  categories {
    id
    title
  }
  attr1s {
    id
    title
    parent {
      id
    }
  }
  attr2s {
    id
    title
    parent {
      id
    }
  }
}
    `;

/**
 * __useUseLocationItemAttrsQuery__
 *
 * To run a query within a React component, call `useUseLocationItemAttrsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseLocationItemAttrsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseLocationItemAttrsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUseLocationItemAttrsQuery(baseOptions?: Apollo.QueryHookOptions<UseLocationItemAttrsQuery, UseLocationItemAttrsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UseLocationItemAttrsQuery, UseLocationItemAttrsQueryVariables>(UseLocationItemAttrsDocument, options);
      }
export function useUseLocationItemAttrsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UseLocationItemAttrsQuery, UseLocationItemAttrsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UseLocationItemAttrsQuery, UseLocationItemAttrsQueryVariables>(UseLocationItemAttrsDocument, options);
        }
export type UseLocationItemAttrsQueryHookResult = ReturnType<typeof useUseLocationItemAttrsQuery>;
export type UseLocationItemAttrsLazyQueryHookResult = ReturnType<typeof useUseLocationItemAttrsLazyQuery>;
export type UseLocationItemAttrsQueryResult = Apollo.QueryResult<UseLocationItemAttrsQuery, UseLocationItemAttrsQueryVariables>;
export const UseLocationItemTypesDocument = gql`
    query UseLocationItemTypes {
  locationItemTypes {
    id
    title
  }
}
    `;

/**
 * __useUseLocationItemTypesQuery__
 *
 * To run a query within a React component, call `useUseLocationItemTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseLocationItemTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseLocationItemTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUseLocationItemTypesQuery(baseOptions?: Apollo.QueryHookOptions<UseLocationItemTypesQuery, UseLocationItemTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UseLocationItemTypesQuery, UseLocationItemTypesQueryVariables>(UseLocationItemTypesDocument, options);
      }
export function useUseLocationItemTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UseLocationItemTypesQuery, UseLocationItemTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UseLocationItemTypesQuery, UseLocationItemTypesQueryVariables>(UseLocationItemTypesDocument, options);
        }
export type UseLocationItemTypesQueryHookResult = ReturnType<typeof useUseLocationItemTypesQuery>;
export type UseLocationItemTypesLazyQueryHookResult = ReturnType<typeof useUseLocationItemTypesLazyQuery>;
export type UseLocationItemTypesQueryResult = Apollo.QueryResult<UseLocationItemTypesQuery, UseLocationItemTypesQueryVariables>;
export const UseProductSizesDocument = gql`
    query UseProductSizes($esId: String) {
  getProduct(esId: $esId) {
    id
    name
    sizes {
      id
      width
      height
      thickness
      diameter
      price
      sizeAndPriceString
      printBaseCost {
        printSize
        printMaterial
      }
    }
  }
}
    `;

/**
 * __useUseProductSizesQuery__
 *
 * To run a query within a React component, call `useUseProductSizesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseProductSizesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseProductSizesQuery({
 *   variables: {
 *      esId: // value for 'esId'
 *   },
 * });
 */
export function useUseProductSizesQuery(baseOptions?: Apollo.QueryHookOptions<UseProductSizesQuery, UseProductSizesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UseProductSizesQuery, UseProductSizesQueryVariables>(UseProductSizesDocument, options);
      }
export function useUseProductSizesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UseProductSizesQuery, UseProductSizesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UseProductSizesQuery, UseProductSizesQueryVariables>(UseProductSizesDocument, options);
        }
export type UseProductSizesQueryHookResult = ReturnType<typeof useUseProductSizesQuery>;
export type UseProductSizesLazyQueryHookResult = ReturnType<typeof useUseProductSizesLazyQuery>;
export type UseProductSizesQueryResult = Apollo.QueryResult<UseProductSizesQuery, UseProductSizesQueryVariables>;
export const UseProjectClientsDocument = gql`
    query UseProjectClients {
  clients {
    id
    slug
    name
  }
}
    `;

/**
 * __useUseProjectClientsQuery__
 *
 * To run a query within a React component, call `useUseProjectClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseProjectClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseProjectClientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUseProjectClientsQuery(baseOptions?: Apollo.QueryHookOptions<UseProjectClientsQuery, UseProjectClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UseProjectClientsQuery, UseProjectClientsQueryVariables>(UseProjectClientsDocument, options);
      }
export function useUseProjectClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UseProjectClientsQuery, UseProjectClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UseProjectClientsQuery, UseProjectClientsQueryVariables>(UseProjectClientsDocument, options);
        }
export type UseProjectClientsQueryHookResult = ReturnType<typeof useUseProjectClientsQuery>;
export type UseProjectClientsLazyQueryHookResult = ReturnType<typeof useUseProjectClientsLazyQuery>;
export type UseProjectClientsQueryResult = Apollo.QueryResult<UseProjectClientsQuery, UseProjectClientsQueryVariables>;
export const UseProjectStatusesDocument = gql`
    query UseProjectStatuses {
  projectStatuses {
    name
    code
  }
}
    `;

/**
 * __useUseProjectStatusesQuery__
 *
 * To run a query within a React component, call `useUseProjectStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseProjectStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseProjectStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUseProjectStatusesQuery(baseOptions?: Apollo.QueryHookOptions<UseProjectStatusesQuery, UseProjectStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UseProjectStatusesQuery, UseProjectStatusesQueryVariables>(UseProjectStatusesDocument, options);
      }
export function useUseProjectStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UseProjectStatusesQuery, UseProjectStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UseProjectStatusesQuery, UseProjectStatusesQueryVariables>(UseProjectStatusesDocument, options);
        }
export type UseProjectStatusesQueryHookResult = ReturnType<typeof useUseProjectStatusesQuery>;
export type UseProjectStatusesLazyQueryHookResult = ReturnType<typeof useUseProjectStatusesLazyQuery>;
export type UseProjectStatusesQueryResult = Apollo.QueryResult<UseProjectStatusesQuery, UseProjectStatusesQueryVariables>;
export const UseProjectTypesDocument = gql`
    query UseProjectTypes {
  projectTypes {
    id
    title
    iconSlug
  }
}
    `;

/**
 * __useUseProjectTypesQuery__
 *
 * To run a query within a React component, call `useUseProjectTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseProjectTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseProjectTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUseProjectTypesQuery(baseOptions?: Apollo.QueryHookOptions<UseProjectTypesQuery, UseProjectTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UseProjectTypesQuery, UseProjectTypesQueryVariables>(UseProjectTypesDocument, options);
      }
export function useUseProjectTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UseProjectTypesQuery, UseProjectTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UseProjectTypesQuery, UseProjectTypesQueryVariables>(UseProjectTypesDocument, options);
        }
export type UseProjectTypesQueryHookResult = ReturnType<typeof useUseProjectTypesQuery>;
export type UseProjectTypesLazyQueryHookResult = ReturnType<typeof useUseProjectTypesLazyQuery>;
export type UseProjectTypesQueryResult = Apollo.QueryResult<UseProjectTypesQuery, UseProjectTypesQueryVariables>;
export const UseProjectsDocument = gql`
    query UseProjects {
  projects {
    projects {
      id
      title
    }
  }
}
    `;

/**
 * __useUseProjectsQuery__
 *
 * To run a query within a React component, call `useUseProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUseProjectsQuery(baseOptions?: Apollo.QueryHookOptions<UseProjectsQuery, UseProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UseProjectsQuery, UseProjectsQueryVariables>(UseProjectsDocument, options);
      }
export function useUseProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UseProjectsQuery, UseProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UseProjectsQuery, UseProjectsQueryVariables>(UseProjectsDocument, options);
        }
export type UseProjectsQueryHookResult = ReturnType<typeof useUseProjectsQuery>;
export type UseProjectsLazyQueryHookResult = ReturnType<typeof useUseProjectsLazyQuery>;
export type UseProjectsQueryResult = Apollo.QueryResult<UseProjectsQuery, UseProjectsQueryVariables>;
export const UseSourcingArtistTagsDocument = gql`
    query UseSourcingArtistTags {
  tags
}
    `;

/**
 * __useUseSourcingArtistTagsQuery__
 *
 * To run a query within a React component, call `useUseSourcingArtistTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseSourcingArtistTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseSourcingArtistTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUseSourcingArtistTagsQuery(baseOptions?: Apollo.QueryHookOptions<UseSourcingArtistTagsQuery, UseSourcingArtistTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UseSourcingArtistTagsQuery, UseSourcingArtistTagsQueryVariables>(UseSourcingArtistTagsDocument, options);
      }
export function useUseSourcingArtistTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UseSourcingArtistTagsQuery, UseSourcingArtistTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UseSourcingArtistTagsQuery, UseSourcingArtistTagsQueryVariables>(UseSourcingArtistTagsDocument, options);
        }
export type UseSourcingArtistTagsQueryHookResult = ReturnType<typeof useUseSourcingArtistTagsQuery>;
export type UseSourcingArtistTagsLazyQueryHookResult = ReturnType<typeof useUseSourcingArtistTagsLazyQuery>;
export type UseSourcingArtistTagsQueryResult = Apollo.QueryResult<UseSourcingArtistTagsQuery, UseSourcingArtistTagsQueryVariables>;
export const UseSourcingArtistsDocument = gql`
    query UseSourcingArtists {
  artistsAll {
    id
    slug
    name
  }
}
    `;

/**
 * __useUseSourcingArtistsQuery__
 *
 * To run a query within a React component, call `useUseSourcingArtistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseSourcingArtistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseSourcingArtistsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUseSourcingArtistsQuery(baseOptions?: Apollo.QueryHookOptions<UseSourcingArtistsQuery, UseSourcingArtistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UseSourcingArtistsQuery, UseSourcingArtistsQueryVariables>(UseSourcingArtistsDocument, options);
      }
export function useUseSourcingArtistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UseSourcingArtistsQuery, UseSourcingArtistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UseSourcingArtistsQuery, UseSourcingArtistsQueryVariables>(UseSourcingArtistsDocument, options);
        }
export type UseSourcingArtistsQueryHookResult = ReturnType<typeof useUseSourcingArtistsQuery>;
export type UseSourcingArtistsLazyQueryHookResult = ReturnType<typeof useUseSourcingArtistsLazyQuery>;
export type UseSourcingArtistsQueryResult = Apollo.QueryResult<UseSourcingArtistsQuery, UseSourcingArtistsQueryVariables>;
export const UseSourcingGalleriesDocument = gql`
    query UseSourcingGalleries {
  galleriesAll {
    id
    slug
    name
  }
}
    `;

/**
 * __useUseSourcingGalleriesQuery__
 *
 * To run a query within a React component, call `useUseSourcingGalleriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseSourcingGalleriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseSourcingGalleriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUseSourcingGalleriesQuery(baseOptions?: Apollo.QueryHookOptions<UseSourcingGalleriesQuery, UseSourcingGalleriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UseSourcingGalleriesQuery, UseSourcingGalleriesQueryVariables>(UseSourcingGalleriesDocument, options);
      }
export function useUseSourcingGalleriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UseSourcingGalleriesQuery, UseSourcingGalleriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UseSourcingGalleriesQuery, UseSourcingGalleriesQueryVariables>(UseSourcingGalleriesDocument, options);
        }
export type UseSourcingGalleriesQueryHookResult = ReturnType<typeof useUseSourcingGalleriesQuery>;
export type UseSourcingGalleriesLazyQueryHookResult = ReturnType<typeof useUseSourcingGalleriesLazyQuery>;
export type UseSourcingGalleriesQueryResult = Apollo.QueryResult<UseSourcingGalleriesQuery, UseSourcingGalleriesQueryVariables>;
export const UseSourcingMediumsDocument = gql`
    query UseSourcingMediums {
  mediums {
    id
    slug
    title
  }
}
    `;

/**
 * __useUseSourcingMediumsQuery__
 *
 * To run a query within a React component, call `useUseSourcingMediumsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseSourcingMediumsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseSourcingMediumsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUseSourcingMediumsQuery(baseOptions?: Apollo.QueryHookOptions<UseSourcingMediumsQuery, UseSourcingMediumsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UseSourcingMediumsQuery, UseSourcingMediumsQueryVariables>(UseSourcingMediumsDocument, options);
      }
export function useUseSourcingMediumsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UseSourcingMediumsQuery, UseSourcingMediumsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UseSourcingMediumsQuery, UseSourcingMediumsQueryVariables>(UseSourcingMediumsDocument, options);
        }
export type UseSourcingMediumsQueryHookResult = ReturnType<typeof useUseSourcingMediumsQuery>;
export type UseSourcingMediumsLazyQueryHookResult = ReturnType<typeof useUseSourcingMediumsLazyQuery>;
export type UseSourcingMediumsQueryResult = Apollo.QueryResult<UseSourcingMediumsQuery, UseSourcingMediumsQueryVariables>;
export const UseSourcingStylesDocument = gql`
    query UseSourcingStyles {
  styles {
    id
    slug
    title
  }
}
    `;

/**
 * __useUseSourcingStylesQuery__
 *
 * To run a query within a React component, call `useUseSourcingStylesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseSourcingStylesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseSourcingStylesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUseSourcingStylesQuery(baseOptions?: Apollo.QueryHookOptions<UseSourcingStylesQuery, UseSourcingStylesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UseSourcingStylesQuery, UseSourcingStylesQueryVariables>(UseSourcingStylesDocument, options);
      }
export function useUseSourcingStylesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UseSourcingStylesQuery, UseSourcingStylesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UseSourcingStylesQuery, UseSourcingStylesQueryVariables>(UseSourcingStylesDocument, options);
        }
export type UseSourcingStylesQueryHookResult = ReturnType<typeof useUseSourcingStylesQuery>;
export type UseSourcingStylesLazyQueryHookResult = ReturnType<typeof useUseSourcingStylesLazyQuery>;
export type UseSourcingStylesQueryResult = Apollo.QueryResult<UseSourcingStylesQuery, UseSourcingStylesQueryVariables>;
export const UseStaticImageDocument = gql`
    query UseStaticImage($identifier: String) {
  staticImage(identifier: $identifier) {
    id
    image
    altText
  }
}
    `;

/**
 * __useUseStaticImageQuery__
 *
 * To run a query within a React component, call `useUseStaticImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUseStaticImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUseStaticImageQuery({
 *   variables: {
 *      identifier: // value for 'identifier'
 *   },
 * });
 */
export function useUseStaticImageQuery(baseOptions?: Apollo.QueryHookOptions<UseStaticImageQuery, UseStaticImageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UseStaticImageQuery, UseStaticImageQueryVariables>(UseStaticImageDocument, options);
      }
export function useUseStaticImageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UseStaticImageQuery, UseStaticImageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UseStaticImageQuery, UseStaticImageQueryVariables>(UseStaticImageDocument, options);
        }
export type UseStaticImageQueryHookResult = ReturnType<typeof useUseStaticImageQuery>;
export type UseStaticImageLazyQueryHookResult = ReturnType<typeof useUseStaticImageLazyQuery>;
export type UseStaticImageQueryResult = Apollo.QueryResult<UseStaticImageQuery, UseStaticImageQueryVariables>;
export const InputCountrySelectDocument = gql`
    query InputCountrySelect {
  countries {
    name
    code
  }
}
    `;

/**
 * __useInputCountrySelectQuery__
 *
 * To run a query within a React component, call `useInputCountrySelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useInputCountrySelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInputCountrySelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useInputCountrySelectQuery(baseOptions?: Apollo.QueryHookOptions<InputCountrySelectQuery, InputCountrySelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InputCountrySelectQuery, InputCountrySelectQueryVariables>(InputCountrySelectDocument, options);
      }
export function useInputCountrySelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InputCountrySelectQuery, InputCountrySelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InputCountrySelectQuery, InputCountrySelectQueryVariables>(InputCountrySelectDocument, options);
        }
export type InputCountrySelectQueryHookResult = ReturnType<typeof useInputCountrySelectQuery>;
export type InputCountrySelectLazyQueryHookResult = ReturnType<typeof useInputCountrySelectLazyQuery>;
export type InputCountrySelectQueryResult = Apollo.QueryResult<InputCountrySelectQuery, InputCountrySelectQueryVariables>;
export const InputCountrySelectWizardDocument = gql`
    query InputCountrySelectWizard {
  countries {
    name
    code
  }
}
    `;

/**
 * __useInputCountrySelectWizardQuery__
 *
 * To run a query within a React component, call `useInputCountrySelectWizardQuery` and pass it any options that fit your needs.
 * When your component renders, `useInputCountrySelectWizardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInputCountrySelectWizardQuery({
 *   variables: {
 *   },
 * });
 */
export function useInputCountrySelectWizardQuery(baseOptions?: Apollo.QueryHookOptions<InputCountrySelectWizardQuery, InputCountrySelectWizardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InputCountrySelectWizardQuery, InputCountrySelectWizardQueryVariables>(InputCountrySelectWizardDocument, options);
      }
export function useInputCountrySelectWizardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InputCountrySelectWizardQuery, InputCountrySelectWizardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InputCountrySelectWizardQuery, InputCountrySelectWizardQueryVariables>(InputCountrySelectWizardDocument, options);
        }
export type InputCountrySelectWizardQueryHookResult = ReturnType<typeof useInputCountrySelectWizardQuery>;
export type InputCountrySelectWizardLazyQueryHookResult = ReturnType<typeof useInputCountrySelectWizardLazyQuery>;
export type InputCountrySelectWizardQueryResult = Apollo.QueryResult<InputCountrySelectWizardQuery, InputCountrySelectWizardQueryVariables>;
export const InputCurrencyWizardDocument = gql`
    query InputCurrencyWizard {
  projectCurrencies
}
    `;

/**
 * __useInputCurrencyWizardQuery__
 *
 * To run a query within a React component, call `useInputCurrencyWizardQuery` and pass it any options that fit your needs.
 * When your component renders, `useInputCurrencyWizardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInputCurrencyWizardQuery({
 *   variables: {
 *   },
 * });
 */
export function useInputCurrencyWizardQuery(baseOptions?: Apollo.QueryHookOptions<InputCurrencyWizardQuery, InputCurrencyWizardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InputCurrencyWizardQuery, InputCurrencyWizardQueryVariables>(InputCurrencyWizardDocument, options);
      }
export function useInputCurrencyWizardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InputCurrencyWizardQuery, InputCurrencyWizardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InputCurrencyWizardQuery, InputCurrencyWizardQueryVariables>(InputCurrencyWizardDocument, options);
        }
export type InputCurrencyWizardQueryHookResult = ReturnType<typeof useInputCurrencyWizardQuery>;
export type InputCurrencyWizardLazyQueryHookResult = ReturnType<typeof useInputCurrencyWizardLazyQuery>;
export type InputCurrencyWizardQueryResult = Apollo.QueryResult<InputCurrencyWizardQuery, InputCurrencyWizardQueryVariables>;
export const GlobalContextProviderDocument = gql`
    query GlobalContextProvider($hashId: String) {
  currentProfile {
    id
    ...CurrentProfileFragment
  }
  project(hashId: $hashId) {
    id
    ...GlobalContextProviderProjectFragment
  }
}
    ${CurrentProfileFragmentFragmentDoc}
${GlobalContextProviderProjectFragmentFragmentDoc}`;

/**
 * __useGlobalContextProviderQuery__
 *
 * To run a query within a React component, call `useGlobalContextProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalContextProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalContextProviderQuery({
 *   variables: {
 *      hashId: // value for 'hashId'
 *   },
 * });
 */
export function useGlobalContextProviderQuery(baseOptions?: Apollo.QueryHookOptions<GlobalContextProviderQuery, GlobalContextProviderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GlobalContextProviderQuery, GlobalContextProviderQueryVariables>(GlobalContextProviderDocument, options);
      }
export function useGlobalContextProviderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GlobalContextProviderQuery, GlobalContextProviderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GlobalContextProviderQuery, GlobalContextProviderQueryVariables>(GlobalContextProviderDocument, options);
        }
export type GlobalContextProviderQueryHookResult = ReturnType<typeof useGlobalContextProviderQuery>;
export type GlobalContextProviderLazyQueryHookResult = ReturnType<typeof useGlobalContextProviderLazyQuery>;
export type GlobalContextProviderQueryResult = Apollo.QueryResult<GlobalContextProviderQuery, GlobalContextProviderQueryVariables>;
export const ModalAcceptQuotationDocument = gql`
    mutation ModalAcceptQuotation($data: String) {
  userAcceptQuotation(data: $data) {
    status
  }
}
    `;
export type ModalAcceptQuotationMutationFn = Apollo.MutationFunction<ModalAcceptQuotationMutation, ModalAcceptQuotationMutationVariables>;

/**
 * __useModalAcceptQuotationMutation__
 *
 * To run a mutation, you first call `useModalAcceptQuotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModalAcceptQuotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modalAcceptQuotationMutation, { data, loading, error }] = useModalAcceptQuotationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useModalAcceptQuotationMutation(baseOptions?: Apollo.MutationHookOptions<ModalAcceptQuotationMutation, ModalAcceptQuotationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModalAcceptQuotationMutation, ModalAcceptQuotationMutationVariables>(ModalAcceptQuotationDocument, options);
      }
export type ModalAcceptQuotationMutationHookResult = ReturnType<typeof useModalAcceptQuotationMutation>;
export type ModalAcceptQuotationMutationResult = Apollo.MutationResult<ModalAcceptQuotationMutation>;
export type ModalAcceptQuotationMutationOptions = Apollo.BaseMutationOptions<ModalAcceptQuotationMutation, ModalAcceptQuotationMutationVariables>;
export const ModalBucketItemDetailsDocument = gql`
    query ModalBucketItemDetails($bucketItemId: String) {
  getBucketItem(bucketItemId: $bucketItemId) {
    id
    ...BucketItemDetailsFragment
  }
}
    ${BucketItemDetailsFragmentFragmentDoc}`;

/**
 * __useModalBucketItemDetailsQuery__
 *
 * To run a query within a React component, call `useModalBucketItemDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useModalBucketItemDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModalBucketItemDetailsQuery({
 *   variables: {
 *      bucketItemId: // value for 'bucketItemId'
 *   },
 * });
 */
export function useModalBucketItemDetailsQuery(baseOptions?: Apollo.QueryHookOptions<ModalBucketItemDetailsQuery, ModalBucketItemDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModalBucketItemDetailsQuery, ModalBucketItemDetailsQueryVariables>(ModalBucketItemDetailsDocument, options);
      }
export function useModalBucketItemDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModalBucketItemDetailsQuery, ModalBucketItemDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModalBucketItemDetailsQuery, ModalBucketItemDetailsQueryVariables>(ModalBucketItemDetailsDocument, options);
        }
export type ModalBucketItemDetailsQueryHookResult = ReturnType<typeof useModalBucketItemDetailsQuery>;
export type ModalBucketItemDetailsLazyQueryHookResult = ReturnType<typeof useModalBucketItemDetailsLazyQuery>;
export type ModalBucketItemDetailsQueryResult = Apollo.QueryResult<ModalBucketItemDetailsQuery, ModalBucketItemDetailsQueryVariables>;
export const ModalClearFloorplanConfirmDocument = gql`
    mutation ModalClearFloorplanConfirm($data: String) {
  floorplanClearEdits(data: $data) {
    status
    floorplan {
      id
      markedImage
    }
    page {
      id
      pdfDetails
    }
  }
}
    `;
export type ModalClearFloorplanConfirmMutationFn = Apollo.MutationFunction<ModalClearFloorplanConfirmMutation, ModalClearFloorplanConfirmMutationVariables>;

/**
 * __useModalClearFloorplanConfirmMutation__
 *
 * To run a mutation, you first call `useModalClearFloorplanConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModalClearFloorplanConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modalClearFloorplanConfirmMutation, { data, loading, error }] = useModalClearFloorplanConfirmMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useModalClearFloorplanConfirmMutation(baseOptions?: Apollo.MutationHookOptions<ModalClearFloorplanConfirmMutation, ModalClearFloorplanConfirmMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModalClearFloorplanConfirmMutation, ModalClearFloorplanConfirmMutationVariables>(ModalClearFloorplanConfirmDocument, options);
      }
export type ModalClearFloorplanConfirmMutationHookResult = ReturnType<typeof useModalClearFloorplanConfirmMutation>;
export type ModalClearFloorplanConfirmMutationResult = Apollo.MutationResult<ModalClearFloorplanConfirmMutation>;
export type ModalClearFloorplanConfirmMutationOptions = Apollo.BaseMutationOptions<ModalClearFloorplanConfirmMutation, ModalClearFloorplanConfirmMutationVariables>;
export const ModalComissionedWorksLocationInfoDocument = gql`
    query ModalComissionedWorksLocationInfo($locationItemId: String) {
  staffLocationItem(locationItemId: $locationItemId) {
    ...StaffLocationItemFields
  }
}
    ${StaffLocationItemFieldsFragmentDoc}`;

/**
 * __useModalComissionedWorksLocationInfoQuery__
 *
 * To run a query within a React component, call `useModalComissionedWorksLocationInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useModalComissionedWorksLocationInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModalComissionedWorksLocationInfoQuery({
 *   variables: {
 *      locationItemId: // value for 'locationItemId'
 *   },
 * });
 */
export function useModalComissionedWorksLocationInfoQuery(baseOptions?: Apollo.QueryHookOptions<ModalComissionedWorksLocationInfoQuery, ModalComissionedWorksLocationInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModalComissionedWorksLocationInfoQuery, ModalComissionedWorksLocationInfoQueryVariables>(ModalComissionedWorksLocationInfoDocument, options);
      }
export function useModalComissionedWorksLocationInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModalComissionedWorksLocationInfoQuery, ModalComissionedWorksLocationInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModalComissionedWorksLocationInfoQuery, ModalComissionedWorksLocationInfoQueryVariables>(ModalComissionedWorksLocationInfoDocument, options);
        }
export type ModalComissionedWorksLocationInfoQueryHookResult = ReturnType<typeof useModalComissionedWorksLocationInfoQuery>;
export type ModalComissionedWorksLocationInfoLazyQueryHookResult = ReturnType<typeof useModalComissionedWorksLocationInfoLazyQuery>;
export type ModalComissionedWorksLocationInfoQueryResult = Apollo.QueryResult<ModalComissionedWorksLocationInfoQuery, ModalComissionedWorksLocationInfoQueryVariables>;
export const ModalCreateOrUpdateFramerDocument = gql`
    query ModalCreateOrUpdateFramer($framerId: String) {
  framer(framerId: $framerId) {
    ...FramerFragment
  }
}
    ${FramerFragmentFragmentDoc}`;

/**
 * __useModalCreateOrUpdateFramerQuery__
 *
 * To run a query within a React component, call `useModalCreateOrUpdateFramerQuery` and pass it any options that fit your needs.
 * When your component renders, `useModalCreateOrUpdateFramerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModalCreateOrUpdateFramerQuery({
 *   variables: {
 *      framerId: // value for 'framerId'
 *   },
 * });
 */
export function useModalCreateOrUpdateFramerQuery(baseOptions?: Apollo.QueryHookOptions<ModalCreateOrUpdateFramerQuery, ModalCreateOrUpdateFramerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModalCreateOrUpdateFramerQuery, ModalCreateOrUpdateFramerQueryVariables>(ModalCreateOrUpdateFramerDocument, options);
      }
export function useModalCreateOrUpdateFramerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModalCreateOrUpdateFramerQuery, ModalCreateOrUpdateFramerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModalCreateOrUpdateFramerQuery, ModalCreateOrUpdateFramerQueryVariables>(ModalCreateOrUpdateFramerDocument, options);
        }
export type ModalCreateOrUpdateFramerQueryHookResult = ReturnType<typeof useModalCreateOrUpdateFramerQuery>;
export type ModalCreateOrUpdateFramerLazyQueryHookResult = ReturnType<typeof useModalCreateOrUpdateFramerLazyQuery>;
export type ModalCreateOrUpdateFramerQueryResult = Apollo.QueryResult<ModalCreateOrUpdateFramerQuery, ModalCreateOrUpdateFramerQueryVariables>;
export const ModalDeleteLocationConfirmDocument = gql`
    mutation ModalDeleteLocationConfirm($data: String) {
  deleteLocation(data: $data) {
    status
    project {
      id
      ...UserWizardProjectLocationItemFragment
    }
  }
}
    ${UserWizardProjectLocationItemFragmentFragmentDoc}`;
export type ModalDeleteLocationConfirmMutationFn = Apollo.MutationFunction<ModalDeleteLocationConfirmMutation, ModalDeleteLocationConfirmMutationVariables>;

/**
 * __useModalDeleteLocationConfirmMutation__
 *
 * To run a mutation, you first call `useModalDeleteLocationConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModalDeleteLocationConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modalDeleteLocationConfirmMutation, { data, loading, error }] = useModalDeleteLocationConfirmMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useModalDeleteLocationConfirmMutation(baseOptions?: Apollo.MutationHookOptions<ModalDeleteLocationConfirmMutation, ModalDeleteLocationConfirmMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModalDeleteLocationConfirmMutation, ModalDeleteLocationConfirmMutationVariables>(ModalDeleteLocationConfirmDocument, options);
      }
export type ModalDeleteLocationConfirmMutationHookResult = ReturnType<typeof useModalDeleteLocationConfirmMutation>;
export type ModalDeleteLocationConfirmMutationResult = Apollo.MutationResult<ModalDeleteLocationConfirmMutation>;
export type ModalDeleteLocationConfirmMutationOptions = Apollo.BaseMutationOptions<ModalDeleteLocationConfirmMutation, ModalDeleteLocationConfirmMutationVariables>;
export const ModalDeleteLocationItemConfirmDocument = gql`
    mutation ModalDeleteLocationItemConfirm($data: String) {
  deleteLocationItem(data: $data) {
    status
    project {
      id
      ...UserWizardProjectLocationItemFragment
    }
  }
}
    ${UserWizardProjectLocationItemFragmentFragmentDoc}`;
export type ModalDeleteLocationItemConfirmMutationFn = Apollo.MutationFunction<ModalDeleteLocationItemConfirmMutation, ModalDeleteLocationItemConfirmMutationVariables>;

/**
 * __useModalDeleteLocationItemConfirmMutation__
 *
 * To run a mutation, you first call `useModalDeleteLocationItemConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModalDeleteLocationItemConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modalDeleteLocationItemConfirmMutation, { data, loading, error }] = useModalDeleteLocationItemConfirmMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useModalDeleteLocationItemConfirmMutation(baseOptions?: Apollo.MutationHookOptions<ModalDeleteLocationItemConfirmMutation, ModalDeleteLocationItemConfirmMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModalDeleteLocationItemConfirmMutation, ModalDeleteLocationItemConfirmMutationVariables>(ModalDeleteLocationItemConfirmDocument, options);
      }
export type ModalDeleteLocationItemConfirmMutationHookResult = ReturnType<typeof useModalDeleteLocationItemConfirmMutation>;
export type ModalDeleteLocationItemConfirmMutationResult = Apollo.MutationResult<ModalDeleteLocationItemConfirmMutation>;
export type ModalDeleteLocationItemConfirmMutationOptions = Apollo.BaseMutationOptions<ModalDeleteLocationItemConfirmMutation, ModalDeleteLocationItemConfirmMutationVariables>;
export const ModalDeleteProjectConfirmDocument = gql`
    mutation ModalDeleteProjectConfirm($data: String) {
  deleteProject(data: $data) {
    status
    project {
      id
      ...UserWizardProjectLocationItemFragment
    }
  }
}
    ${UserWizardProjectLocationItemFragmentFragmentDoc}`;
export type ModalDeleteProjectConfirmMutationFn = Apollo.MutationFunction<ModalDeleteProjectConfirmMutation, ModalDeleteProjectConfirmMutationVariables>;

/**
 * __useModalDeleteProjectConfirmMutation__
 *
 * To run a mutation, you first call `useModalDeleteProjectConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModalDeleteProjectConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modalDeleteProjectConfirmMutation, { data, loading, error }] = useModalDeleteProjectConfirmMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useModalDeleteProjectConfirmMutation(baseOptions?: Apollo.MutationHookOptions<ModalDeleteProjectConfirmMutation, ModalDeleteProjectConfirmMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModalDeleteProjectConfirmMutation, ModalDeleteProjectConfirmMutationVariables>(ModalDeleteProjectConfirmDocument, options);
      }
export type ModalDeleteProjectConfirmMutationHookResult = ReturnType<typeof useModalDeleteProjectConfirmMutation>;
export type ModalDeleteProjectConfirmMutationResult = Apollo.MutationResult<ModalDeleteProjectConfirmMutation>;
export type ModalDeleteProjectConfirmMutationOptions = Apollo.BaseMutationOptions<ModalDeleteProjectConfirmMutation, ModalDeleteProjectConfirmMutationVariables>;
export const ModalDeleteProposalConfirmDocument = gql`
    mutation ModalDeleteProposalConfirm($data: String) {
  deleteProposal(data: $data) {
    status
  }
}
    `;
export type ModalDeleteProposalConfirmMutationFn = Apollo.MutationFunction<ModalDeleteProposalConfirmMutation, ModalDeleteProposalConfirmMutationVariables>;

/**
 * __useModalDeleteProposalConfirmMutation__
 *
 * To run a mutation, you first call `useModalDeleteProposalConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModalDeleteProposalConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modalDeleteProposalConfirmMutation, { data, loading, error }] = useModalDeleteProposalConfirmMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useModalDeleteProposalConfirmMutation(baseOptions?: Apollo.MutationHookOptions<ModalDeleteProposalConfirmMutation, ModalDeleteProposalConfirmMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModalDeleteProposalConfirmMutation, ModalDeleteProposalConfirmMutationVariables>(ModalDeleteProposalConfirmDocument, options);
      }
export type ModalDeleteProposalConfirmMutationHookResult = ReturnType<typeof useModalDeleteProposalConfirmMutation>;
export type ModalDeleteProposalConfirmMutationResult = Apollo.MutationResult<ModalDeleteProposalConfirmMutation>;
export type ModalDeleteProposalConfirmMutationOptions = Apollo.BaseMutationOptions<ModalDeleteProposalConfirmMutation, ModalDeleteProposalConfirmMutationVariables>;
export const ModalDeleteVimrImageConfirmDocument = gql`
    mutation ModalDeleteVIMRImageConfirm($data: String) {
  removeProductVimrImage(data: $data) {
    status
    formErrors
    bucketItem {
      id
      vimrImages {
        id
        image
      }
    }
  }
}
    `;
export type ModalDeleteVimrImageConfirmMutationFn = Apollo.MutationFunction<ModalDeleteVimrImageConfirmMutation, ModalDeleteVimrImageConfirmMutationVariables>;

/**
 * __useModalDeleteVimrImageConfirmMutation__
 *
 * To run a mutation, you first call `useModalDeleteVimrImageConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModalDeleteVimrImageConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modalDeleteVimrImageConfirmMutation, { data, loading, error }] = useModalDeleteVimrImageConfirmMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useModalDeleteVimrImageConfirmMutation(baseOptions?: Apollo.MutationHookOptions<ModalDeleteVimrImageConfirmMutation, ModalDeleteVimrImageConfirmMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModalDeleteVimrImageConfirmMutation, ModalDeleteVimrImageConfirmMutationVariables>(ModalDeleteVimrImageConfirmDocument, options);
      }
export type ModalDeleteVimrImageConfirmMutationHookResult = ReturnType<typeof useModalDeleteVimrImageConfirmMutation>;
export type ModalDeleteVimrImageConfirmMutationResult = Apollo.MutationResult<ModalDeleteVimrImageConfirmMutation>;
export type ModalDeleteVimrImageConfirmMutationOptions = Apollo.BaseMutationOptions<ModalDeleteVimrImageConfirmMutation, ModalDeleteVimrImageConfirmMutationVariables>;
export const ModalDetailsDeleteItemConfirmDocument = gql`
    mutation ModalDetailsDeleteItemConfirm($data: String) {
  addOrRemoveBucketItem(data: $data) {
    status
    formErrors
    project {
      id
      bucketItemsCount
    }
  }
}
    `;
export type ModalDetailsDeleteItemConfirmMutationFn = Apollo.MutationFunction<ModalDetailsDeleteItemConfirmMutation, ModalDetailsDeleteItemConfirmMutationVariables>;

/**
 * __useModalDetailsDeleteItemConfirmMutation__
 *
 * To run a mutation, you first call `useModalDetailsDeleteItemConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModalDetailsDeleteItemConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modalDetailsDeleteItemConfirmMutation, { data, loading, error }] = useModalDetailsDeleteItemConfirmMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useModalDetailsDeleteItemConfirmMutation(baseOptions?: Apollo.MutationHookOptions<ModalDetailsDeleteItemConfirmMutation, ModalDetailsDeleteItemConfirmMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModalDetailsDeleteItemConfirmMutation, ModalDetailsDeleteItemConfirmMutationVariables>(ModalDetailsDeleteItemConfirmDocument, options);
      }
export type ModalDetailsDeleteItemConfirmMutationHookResult = ReturnType<typeof useModalDetailsDeleteItemConfirmMutation>;
export type ModalDetailsDeleteItemConfirmMutationResult = Apollo.MutationResult<ModalDetailsDeleteItemConfirmMutation>;
export type ModalDetailsDeleteItemConfirmMutationOptions = Apollo.BaseMutationOptions<ModalDetailsDeleteItemConfirmMutation, ModalDetailsDeleteItemConfirmMutationVariables>;
export const ModalFeedbackReasonDocument = gql`
    query ModalFeedbackReason($id: String) {
  getFeedback(id: $id) {
    id
    querystring
  }
}
    `;

/**
 * __useModalFeedbackReasonQuery__
 *
 * To run a query within a React component, call `useModalFeedbackReasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useModalFeedbackReasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModalFeedbackReasonQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useModalFeedbackReasonQuery(baseOptions?: Apollo.QueryHookOptions<ModalFeedbackReasonQuery, ModalFeedbackReasonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModalFeedbackReasonQuery, ModalFeedbackReasonQueryVariables>(ModalFeedbackReasonDocument, options);
      }
export function useModalFeedbackReasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModalFeedbackReasonQuery, ModalFeedbackReasonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModalFeedbackReasonQuery, ModalFeedbackReasonQueryVariables>(ModalFeedbackReasonDocument, options);
        }
export type ModalFeedbackReasonQueryHookResult = ReturnType<typeof useModalFeedbackReasonQuery>;
export type ModalFeedbackReasonLazyQueryHookResult = ReturnType<typeof useModalFeedbackReasonLazyQuery>;
export type ModalFeedbackReasonQueryResult = Apollo.QueryResult<ModalFeedbackReasonQuery, ModalFeedbackReasonQueryVariables>;
export const ModalFeedbackReasonUpdateFeedbackDocument = gql`
    mutation ModalFeedbackReasonUpdateFeedback($id: String, $reason: String) {
  updateFeedback(id: $id, reason: $reason) {
    status
    feedback {
      id
    }
  }
}
    `;
export type ModalFeedbackReasonUpdateFeedbackMutationFn = Apollo.MutationFunction<ModalFeedbackReasonUpdateFeedbackMutation, ModalFeedbackReasonUpdateFeedbackMutationVariables>;

/**
 * __useModalFeedbackReasonUpdateFeedbackMutation__
 *
 * To run a mutation, you first call `useModalFeedbackReasonUpdateFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModalFeedbackReasonUpdateFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modalFeedbackReasonUpdateFeedbackMutation, { data, loading, error }] = useModalFeedbackReasonUpdateFeedbackMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useModalFeedbackReasonUpdateFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<ModalFeedbackReasonUpdateFeedbackMutation, ModalFeedbackReasonUpdateFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModalFeedbackReasonUpdateFeedbackMutation, ModalFeedbackReasonUpdateFeedbackMutationVariables>(ModalFeedbackReasonUpdateFeedbackDocument, options);
      }
export type ModalFeedbackReasonUpdateFeedbackMutationHookResult = ReturnType<typeof useModalFeedbackReasonUpdateFeedbackMutation>;
export type ModalFeedbackReasonUpdateFeedbackMutationResult = Apollo.MutationResult<ModalFeedbackReasonUpdateFeedbackMutation>;
export type ModalFeedbackReasonUpdateFeedbackMutationOptions = Apollo.BaseMutationOptions<ModalFeedbackReasonUpdateFeedbackMutation, ModalFeedbackReasonUpdateFeedbackMutationVariables>;
export const ModalFloorplanDocument = gql`
    query ModalFloorplan($floorplanId: String) {
  getFloorplan(floorplanId: $floorplanId) {
    id
    image
    editsJson
    measurementUnit
    shownMeasurementUnit
    paperSize
    scaleMeasurement
    unitRatio
    measureType
  }
}
    `;

/**
 * __useModalFloorplanQuery__
 *
 * To run a query within a React component, call `useModalFloorplanQuery` and pass it any options that fit your needs.
 * When your component renders, `useModalFloorplanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModalFloorplanQuery({
 *   variables: {
 *      floorplanId: // value for 'floorplanId'
 *   },
 * });
 */
export function useModalFloorplanQuery(baseOptions?: Apollo.QueryHookOptions<ModalFloorplanQuery, ModalFloorplanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModalFloorplanQuery, ModalFloorplanQueryVariables>(ModalFloorplanDocument, options);
      }
export function useModalFloorplanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModalFloorplanQuery, ModalFloorplanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModalFloorplanQuery, ModalFloorplanQueryVariables>(ModalFloorplanDocument, options);
        }
export type ModalFloorplanQueryHookResult = ReturnType<typeof useModalFloorplanQuery>;
export type ModalFloorplanLazyQueryHookResult = ReturnType<typeof useModalFloorplanLazyQuery>;
export type ModalFloorplanQueryResult = Apollo.QueryResult<ModalFloorplanQuery, ModalFloorplanQueryVariables>;
export const ModalFloorplanUpdateFloorplanDocument = gql`
    mutation ModalFloorplanUpdateFloorplan($data: String) {
  updateFloorplan(data: $data) {
    status
    floorplan {
      id
      markedImage
    }
    page {
      id
      pdfDetails
    }
  }
}
    `;
export type ModalFloorplanUpdateFloorplanMutationFn = Apollo.MutationFunction<ModalFloorplanUpdateFloorplanMutation, ModalFloorplanUpdateFloorplanMutationVariables>;

/**
 * __useModalFloorplanUpdateFloorplanMutation__
 *
 * To run a mutation, you first call `useModalFloorplanUpdateFloorplanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModalFloorplanUpdateFloorplanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modalFloorplanUpdateFloorplanMutation, { data, loading, error }] = useModalFloorplanUpdateFloorplanMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useModalFloorplanUpdateFloorplanMutation(baseOptions?: Apollo.MutationHookOptions<ModalFloorplanUpdateFloorplanMutation, ModalFloorplanUpdateFloorplanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModalFloorplanUpdateFloorplanMutation, ModalFloorplanUpdateFloorplanMutationVariables>(ModalFloorplanUpdateFloorplanDocument, options);
      }
export type ModalFloorplanUpdateFloorplanMutationHookResult = ReturnType<typeof useModalFloorplanUpdateFloorplanMutation>;
export type ModalFloorplanUpdateFloorplanMutationResult = Apollo.MutationResult<ModalFloorplanUpdateFloorplanMutation>;
export type ModalFloorplanUpdateFloorplanMutationOptions = Apollo.BaseMutationOptions<ModalFloorplanUpdateFloorplanMutation, ModalFloorplanUpdateFloorplanMutationVariables>;
export const ModalFloorplanUpdateFloorplanScaleDocument = gql`
    mutation ModalFloorplanUpdateFloorplanScale($data: String) {
  updateFloorplanShownUnit(data: $data) {
    status
    formErrors
    floorplan {
      id
      image
      editsJson
      measurementUnit
      shownMeasurementUnit
      paperSize
      scaleMeasurement
      unitRatio
    }
  }
}
    `;
export type ModalFloorplanUpdateFloorplanScaleMutationFn = Apollo.MutationFunction<ModalFloorplanUpdateFloorplanScaleMutation, ModalFloorplanUpdateFloorplanScaleMutationVariables>;

/**
 * __useModalFloorplanUpdateFloorplanScaleMutation__
 *
 * To run a mutation, you first call `useModalFloorplanUpdateFloorplanScaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModalFloorplanUpdateFloorplanScaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modalFloorplanUpdateFloorplanScaleMutation, { data, loading, error }] = useModalFloorplanUpdateFloorplanScaleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useModalFloorplanUpdateFloorplanScaleMutation(baseOptions?: Apollo.MutationHookOptions<ModalFloorplanUpdateFloorplanScaleMutation, ModalFloorplanUpdateFloorplanScaleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModalFloorplanUpdateFloorplanScaleMutation, ModalFloorplanUpdateFloorplanScaleMutationVariables>(ModalFloorplanUpdateFloorplanScaleDocument, options);
      }
export type ModalFloorplanUpdateFloorplanScaleMutationHookResult = ReturnType<typeof useModalFloorplanUpdateFloorplanScaleMutation>;
export type ModalFloorplanUpdateFloorplanScaleMutationResult = Apollo.MutationResult<ModalFloorplanUpdateFloorplanScaleMutation>;
export type ModalFloorplanUpdateFloorplanScaleMutationOptions = Apollo.BaseMutationOptions<ModalFloorplanUpdateFloorplanScaleMutation, ModalFloorplanUpdateFloorplanScaleMutationVariables>;
export const ModalFlowItemFramerInfoDocument = gql`
    query ModalFlowItemFramerInfo($flowItemId: String, $esId: String) {
  getProduct(esId: $esId) {
    id
    artworksMedium
    medium {
      slug
      title
    }
  }
  wfStaffFlowItem(flowItemId: $flowItemId) {
    id
    ...FlowItemFramerInfoFragment
  }
}
    ${FlowItemFramerInfoFragmentFragmentDoc}`;

/**
 * __useModalFlowItemFramerInfoQuery__
 *
 * To run a query within a React component, call `useModalFlowItemFramerInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useModalFlowItemFramerInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModalFlowItemFramerInfoQuery({
 *   variables: {
 *      flowItemId: // value for 'flowItemId'
 *      esId: // value for 'esId'
 *   },
 * });
 */
export function useModalFlowItemFramerInfoQuery(baseOptions?: Apollo.QueryHookOptions<ModalFlowItemFramerInfoQuery, ModalFlowItemFramerInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModalFlowItemFramerInfoQuery, ModalFlowItemFramerInfoQueryVariables>(ModalFlowItemFramerInfoDocument, options);
      }
export function useModalFlowItemFramerInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModalFlowItemFramerInfoQuery, ModalFlowItemFramerInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModalFlowItemFramerInfoQuery, ModalFlowItemFramerInfoQueryVariables>(ModalFlowItemFramerInfoDocument, options);
        }
export type ModalFlowItemFramerInfoQueryHookResult = ReturnType<typeof useModalFlowItemFramerInfoQuery>;
export type ModalFlowItemFramerInfoLazyQueryHookResult = ReturnType<typeof useModalFlowItemFramerInfoLazyQuery>;
export type ModalFlowItemFramerInfoQueryResult = Apollo.QueryResult<ModalFlowItemFramerInfoQuery, ModalFlowItemFramerInfoQueryVariables>;
export const ModalPdfCoverImageDocument = gql`
    query ModalPDFCoverImage($hashId: String) {
  project(hashId: $hashId) {
    id
    allImages
  }
}
    `;

/**
 * __useModalPdfCoverImageQuery__
 *
 * To run a query within a React component, call `useModalPdfCoverImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useModalPdfCoverImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModalPdfCoverImageQuery({
 *   variables: {
 *      hashId: // value for 'hashId'
 *   },
 * });
 */
export function useModalPdfCoverImageQuery(baseOptions?: Apollo.QueryHookOptions<ModalPdfCoverImageQuery, ModalPdfCoverImageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModalPdfCoverImageQuery, ModalPdfCoverImageQueryVariables>(ModalPdfCoverImageDocument, options);
      }
export function useModalPdfCoverImageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModalPdfCoverImageQuery, ModalPdfCoverImageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModalPdfCoverImageQuery, ModalPdfCoverImageQueryVariables>(ModalPdfCoverImageDocument, options);
        }
export type ModalPdfCoverImageQueryHookResult = ReturnType<typeof useModalPdfCoverImageQuery>;
export type ModalPdfCoverImageLazyQueryHookResult = ReturnType<typeof useModalPdfCoverImageLazyQuery>;
export type ModalPdfCoverImageQueryResult = Apollo.QueryResult<ModalPdfCoverImageQuery, ModalPdfCoverImageQueryVariables>;
export const ModalPdfDeletePageDocument = gql`
    mutation ModalPDFDeletePage($data: String) {
  deleteProposalPage(data: $data) {
    status
    proposal {
      id
      pages {
        id
        ...ProposalPageFragment
      }
    }
  }
}
    ${ProposalPageFragmentFragmentDoc}`;
export type ModalPdfDeletePageMutationFn = Apollo.MutationFunction<ModalPdfDeletePageMutation, ModalPdfDeletePageMutationVariables>;

/**
 * __useModalPdfDeletePageMutation__
 *
 * To run a mutation, you first call `useModalPdfDeletePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModalPdfDeletePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modalPdfDeletePageMutation, { data, loading, error }] = useModalPdfDeletePageMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useModalPdfDeletePageMutation(baseOptions?: Apollo.MutationHookOptions<ModalPdfDeletePageMutation, ModalPdfDeletePageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModalPdfDeletePageMutation, ModalPdfDeletePageMutationVariables>(ModalPdfDeletePageDocument, options);
      }
export type ModalPdfDeletePageMutationHookResult = ReturnType<typeof useModalPdfDeletePageMutation>;
export type ModalPdfDeletePageMutationResult = Apollo.MutationResult<ModalPdfDeletePageMutation>;
export type ModalPdfDeletePageMutationOptions = Apollo.BaseMutationOptions<ModalPdfDeletePageMutation, ModalPdfDeletePageMutationVariables>;
export const ModalPdfFooterDetailsDocument = gql`
    query ModalPDFFooterDetails($proposalId: String) {
  getProposal(proposalId: $proposalId) {
    id
    pdfAddr
    pdfEmail
    pdfSite
  }
}
    `;

/**
 * __useModalPdfFooterDetailsQuery__
 *
 * To run a query within a React component, call `useModalPdfFooterDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useModalPdfFooterDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModalPdfFooterDetailsQuery({
 *   variables: {
 *      proposalId: // value for 'proposalId'
 *   },
 * });
 */
export function useModalPdfFooterDetailsQuery(baseOptions?: Apollo.QueryHookOptions<ModalPdfFooterDetailsQuery, ModalPdfFooterDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModalPdfFooterDetailsQuery, ModalPdfFooterDetailsQueryVariables>(ModalPdfFooterDetailsDocument, options);
      }
export function useModalPdfFooterDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModalPdfFooterDetailsQuery, ModalPdfFooterDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModalPdfFooterDetailsQuery, ModalPdfFooterDetailsQueryVariables>(ModalPdfFooterDetailsDocument, options);
        }
export type ModalPdfFooterDetailsQueryHookResult = ReturnType<typeof useModalPdfFooterDetailsQuery>;
export type ModalPdfFooterDetailsLazyQueryHookResult = ReturnType<typeof useModalPdfFooterDetailsLazyQuery>;
export type ModalPdfFooterDetailsQueryResult = Apollo.QueryResult<ModalPdfFooterDetailsQuery, ModalPdfFooterDetailsQueryVariables>;
export const ModalPdfLocationDetailsDocument = gql`
    query ModalPDFLocationDetails($hashId: String, $locId: String, $locItemId: String) {
  locationItem(hashId: $hashId, locId: $locId, locItemId: $locItemId) {
    ...LocationItemFields
  }
}
    ${LocationItemFieldsFragmentDoc}`;

/**
 * __useModalPdfLocationDetailsQuery__
 *
 * To run a query within a React component, call `useModalPdfLocationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useModalPdfLocationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModalPdfLocationDetailsQuery({
 *   variables: {
 *      hashId: // value for 'hashId'
 *      locId: // value for 'locId'
 *      locItemId: // value for 'locItemId'
 *   },
 * });
 */
export function useModalPdfLocationDetailsQuery(baseOptions?: Apollo.QueryHookOptions<ModalPdfLocationDetailsQuery, ModalPdfLocationDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModalPdfLocationDetailsQuery, ModalPdfLocationDetailsQueryVariables>(ModalPdfLocationDetailsDocument, options);
      }
export function useModalPdfLocationDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModalPdfLocationDetailsQuery, ModalPdfLocationDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModalPdfLocationDetailsQuery, ModalPdfLocationDetailsQueryVariables>(ModalPdfLocationDetailsDocument, options);
        }
export type ModalPdfLocationDetailsQueryHookResult = ReturnType<typeof useModalPdfLocationDetailsQuery>;
export type ModalPdfLocationDetailsLazyQueryHookResult = ReturnType<typeof useModalPdfLocationDetailsLazyQuery>;
export type ModalPdfLocationDetailsQueryResult = Apollo.QueryResult<ModalPdfLocationDetailsQuery, ModalPdfLocationDetailsQueryVariables>;
export const ModalPdfLogoDocument = gql`
    query ModalPDFLogo($proposalId: String) {
  getProposal(proposalId: $proposalId) {
    id
    pdfLogo
  }
}
    `;

/**
 * __useModalPdfLogoQuery__
 *
 * To run a query within a React component, call `useModalPdfLogoQuery` and pass it any options that fit your needs.
 * When your component renders, `useModalPdfLogoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModalPdfLogoQuery({
 *   variables: {
 *      proposalId: // value for 'proposalId'
 *   },
 * });
 */
export function useModalPdfLogoQuery(baseOptions?: Apollo.QueryHookOptions<ModalPdfLogoQuery, ModalPdfLogoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModalPdfLogoQuery, ModalPdfLogoQueryVariables>(ModalPdfLogoDocument, options);
      }
export function useModalPdfLogoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModalPdfLogoQuery, ModalPdfLogoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModalPdfLogoQuery, ModalPdfLogoQueryVariables>(ModalPdfLogoDocument, options);
        }
export type ModalPdfLogoQueryHookResult = ReturnType<typeof useModalPdfLogoQuery>;
export type ModalPdfLogoLazyQueryHookResult = ReturnType<typeof useModalPdfLogoLazyQuery>;
export type ModalPdfLogoQueryResult = Apollo.QueryResult<ModalPdfLogoQuery, ModalPdfLogoQueryVariables>;
export const ModalPdfNewPageDetailsDocument = gql`
    query ModalPDFNewPageDetails($hashId: String) {
  project(hashId: $hashId) {
    id
    allImages
    pdfImage
    pdfLogo
    vimrImages {
      id
      image
    }
    locations {
      id
      titleStr
      floorplans {
        id
        markedImage
      }
      locationItems {
        ...LocationItemsFields
      }
    }
  }
}
    ${LocationItemsFieldsFragmentDoc}`;

/**
 * __useModalPdfNewPageDetailsQuery__
 *
 * To run a query within a React component, call `useModalPdfNewPageDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useModalPdfNewPageDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModalPdfNewPageDetailsQuery({
 *   variables: {
 *      hashId: // value for 'hashId'
 *   },
 * });
 */
export function useModalPdfNewPageDetailsQuery(baseOptions?: Apollo.QueryHookOptions<ModalPdfNewPageDetailsQuery, ModalPdfNewPageDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModalPdfNewPageDetailsQuery, ModalPdfNewPageDetailsQueryVariables>(ModalPdfNewPageDetailsDocument, options);
      }
export function useModalPdfNewPageDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModalPdfNewPageDetailsQuery, ModalPdfNewPageDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModalPdfNewPageDetailsQuery, ModalPdfNewPageDetailsQueryVariables>(ModalPdfNewPageDetailsDocument, options);
        }
export type ModalPdfNewPageDetailsQueryHookResult = ReturnType<typeof useModalPdfNewPageDetailsQuery>;
export type ModalPdfNewPageDetailsLazyQueryHookResult = ReturnType<typeof useModalPdfNewPageDetailsLazyQuery>;
export type ModalPdfNewPageDetailsQueryResult = Apollo.QueryResult<ModalPdfNewPageDetailsQuery, ModalPdfNewPageDetailsQueryVariables>;
export const ModalPdfProjectDetailsDocument = gql`
    query ModalPDFProjectDetails($hashId: String) {
  project(hashId: $hashId) {
    id
    allImages
    pdfImage
  }
}
    `;

/**
 * __useModalPdfProjectDetailsQuery__
 *
 * To run a query within a React component, call `useModalPdfProjectDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useModalPdfProjectDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModalPdfProjectDetailsQuery({
 *   variables: {
 *      hashId: // value for 'hashId'
 *   },
 * });
 */
export function useModalPdfProjectDetailsQuery(baseOptions?: Apollo.QueryHookOptions<ModalPdfProjectDetailsQuery, ModalPdfProjectDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModalPdfProjectDetailsQuery, ModalPdfProjectDetailsQueryVariables>(ModalPdfProjectDetailsDocument, options);
      }
export function useModalPdfProjectDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModalPdfProjectDetailsQuery, ModalPdfProjectDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModalPdfProjectDetailsQuery, ModalPdfProjectDetailsQueryVariables>(ModalPdfProjectDetailsDocument, options);
        }
export type ModalPdfProjectDetailsQueryHookResult = ReturnType<typeof useModalPdfProjectDetailsQuery>;
export type ModalPdfProjectDetailsLazyQueryHookResult = ReturnType<typeof useModalPdfProjectDetailsLazyQuery>;
export type ModalPdfProjectDetailsQueryResult = Apollo.QueryResult<ModalPdfProjectDetailsQuery, ModalPdfProjectDetailsQueryVariables>;
export const ModalPdfProposalsDocument = gql`
    query ModalPdfProposals($hashId: String) {
  getProjectProposals(hashId: $hashId) {
    ...ProposalFields
  }
}
    ${ProposalFieldsFragmentDoc}`;

/**
 * __useModalPdfProposalsQuery__
 *
 * To run a query within a React component, call `useModalPdfProposalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useModalPdfProposalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModalPdfProposalsQuery({
 *   variables: {
 *      hashId: // value for 'hashId'
 *   },
 * });
 */
export function useModalPdfProposalsQuery(baseOptions?: Apollo.QueryHookOptions<ModalPdfProposalsQuery, ModalPdfProposalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModalPdfProposalsQuery, ModalPdfProposalsQueryVariables>(ModalPdfProposalsDocument, options);
      }
export function useModalPdfProposalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModalPdfProposalsQuery, ModalPdfProposalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModalPdfProposalsQuery, ModalPdfProposalsQueryVariables>(ModalPdfProposalsDocument, options);
        }
export type ModalPdfProposalsQueryHookResult = ReturnType<typeof useModalPdfProposalsQuery>;
export type ModalPdfProposalsLazyQueryHookResult = ReturnType<typeof useModalPdfProposalsLazyQuery>;
export type ModalPdfProposalsQueryResult = Apollo.QueryResult<ModalPdfProposalsQuery, ModalPdfProposalsQueryVariables>;
export const ModalProductDetailsDocument = gql`
    query ModalProductDetails($esId: String, $hashId: String, $locationId: String, $locationItemId: String) {
  currentProfile {
    id
    userVirImages {
      id
      externalId
      image
      imageFilename
    }
  }
  getProduct(esId: $esId) {
    id
    ...ProductFragment
  }
  getProductSameArtist(esId: $esId) {
    total
    objects {
      id
      ...ProductGridFragment
    }
  }
  locationItem(
    hashId: $hashId
    locId: $locationId
    locItemId: $locationItemId
    originalId: false
  ) {
    id
    ...ModalProductDetailsLocationItemFragment
  }
}
    ${ProductFragmentFragmentDoc}
${ProductGridFragmentFragmentDoc}
${ModalProductDetailsLocationItemFragmentFragmentDoc}`;

/**
 * __useModalProductDetailsQuery__
 *
 * To run a query within a React component, call `useModalProductDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useModalProductDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModalProductDetailsQuery({
 *   variables: {
 *      esId: // value for 'esId'
 *      hashId: // value for 'hashId'
 *      locationId: // value for 'locationId'
 *      locationItemId: // value for 'locationItemId'
 *   },
 * });
 */
export function useModalProductDetailsQuery(baseOptions?: Apollo.QueryHookOptions<ModalProductDetailsQuery, ModalProductDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModalProductDetailsQuery, ModalProductDetailsQueryVariables>(ModalProductDetailsDocument, options);
      }
export function useModalProductDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModalProductDetailsQuery, ModalProductDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModalProductDetailsQuery, ModalProductDetailsQueryVariables>(ModalProductDetailsDocument, options);
        }
export type ModalProductDetailsQueryHookResult = ReturnType<typeof useModalProductDetailsQuery>;
export type ModalProductDetailsLazyQueryHookResult = ReturnType<typeof useModalProductDetailsLazyQuery>;
export type ModalProductDetailsQueryResult = Apollo.QueryResult<ModalProductDetailsQuery, ModalProductDetailsQueryVariables>;
export const ModalProjectCreateUpdateClientDocument = gql`
    query ModalProjectCreateUpdateClient($clientId: String) {
  client(clientId: $clientId) {
    id
    name
  }
}
    `;

/**
 * __useModalProjectCreateUpdateClientQuery__
 *
 * To run a query within a React component, call `useModalProjectCreateUpdateClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useModalProjectCreateUpdateClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModalProjectCreateUpdateClientQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useModalProjectCreateUpdateClientQuery(baseOptions?: Apollo.QueryHookOptions<ModalProjectCreateUpdateClientQuery, ModalProjectCreateUpdateClientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModalProjectCreateUpdateClientQuery, ModalProjectCreateUpdateClientQueryVariables>(ModalProjectCreateUpdateClientDocument, options);
      }
export function useModalProjectCreateUpdateClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModalProjectCreateUpdateClientQuery, ModalProjectCreateUpdateClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModalProjectCreateUpdateClientQuery, ModalProjectCreateUpdateClientQueryVariables>(ModalProjectCreateUpdateClientDocument, options);
        }
export type ModalProjectCreateUpdateClientQueryHookResult = ReturnType<typeof useModalProjectCreateUpdateClientQuery>;
export type ModalProjectCreateUpdateClientLazyQueryHookResult = ReturnType<typeof useModalProjectCreateUpdateClientLazyQuery>;
export type ModalProjectCreateUpdateClientQueryResult = Apollo.QueryResult<ModalProjectCreateUpdateClientQuery, ModalProjectCreateUpdateClientQueryVariables>;
export const ModalProjectRevokeAccessDocument = gql`
    mutation ModalProjectRevokeAccess($data: String) {
  revokeProjectAccess(data: $data) {
    status
    project {
      id
      ...ProjectInviteFragment
    }
  }
}
    ${ProjectInviteFragmentFragmentDoc}`;
export type ModalProjectRevokeAccessMutationFn = Apollo.MutationFunction<ModalProjectRevokeAccessMutation, ModalProjectRevokeAccessMutationVariables>;

/**
 * __useModalProjectRevokeAccessMutation__
 *
 * To run a mutation, you first call `useModalProjectRevokeAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModalProjectRevokeAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modalProjectRevokeAccessMutation, { data, loading, error }] = useModalProjectRevokeAccessMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useModalProjectRevokeAccessMutation(baseOptions?: Apollo.MutationHookOptions<ModalProjectRevokeAccessMutation, ModalProjectRevokeAccessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModalProjectRevokeAccessMutation, ModalProjectRevokeAccessMutationVariables>(ModalProjectRevokeAccessDocument, options);
      }
export type ModalProjectRevokeAccessMutationHookResult = ReturnType<typeof useModalProjectRevokeAccessMutation>;
export type ModalProjectRevokeAccessMutationResult = Apollo.MutationResult<ModalProjectRevokeAccessMutation>;
export type ModalProjectRevokeAccessMutationOptions = Apollo.BaseMutationOptions<ModalProjectRevokeAccessMutation, ModalProjectRevokeAccessMutationVariables>;
export const ModalProjectSavedImagesDocument = gql`
    query ModalProjectSavedImages($hashId: String) {
  project(hashId: $hashId) {
    id
    hashId
    vimrImages {
      id
      image
      imageFilename
    }
  }
}
    `;

/**
 * __useModalProjectSavedImagesQuery__
 *
 * To run a query within a React component, call `useModalProjectSavedImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useModalProjectSavedImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModalProjectSavedImagesQuery({
 *   variables: {
 *      hashId: // value for 'hashId'
 *   },
 * });
 */
export function useModalProjectSavedImagesQuery(baseOptions?: Apollo.QueryHookOptions<ModalProjectSavedImagesQuery, ModalProjectSavedImagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModalProjectSavedImagesQuery, ModalProjectSavedImagesQueryVariables>(ModalProjectSavedImagesDocument, options);
      }
export function useModalProjectSavedImagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModalProjectSavedImagesQuery, ModalProjectSavedImagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModalProjectSavedImagesQuery, ModalProjectSavedImagesQueryVariables>(ModalProjectSavedImagesDocument, options);
        }
export type ModalProjectSavedImagesQueryHookResult = ReturnType<typeof useModalProjectSavedImagesQuery>;
export type ModalProjectSavedImagesLazyQueryHookResult = ReturnType<typeof useModalProjectSavedImagesLazyQuery>;
export type ModalProjectSavedImagesQueryResult = Apollo.QueryResult<ModalProjectSavedImagesQuery, ModalProjectSavedImagesQueryVariables>;
export const ImageSelectorDeleteVimrImagesDocument = gql`
    mutation ImageSelectorDeleteVimrImages($data: String) {
  deleteVimrImages(data: $data) {
    status
    project {
      id
      hashId
      vimrImages {
        id
        image
        imageFilename
      }
    }
  }
}
    `;
export type ImageSelectorDeleteVimrImagesMutationFn = Apollo.MutationFunction<ImageSelectorDeleteVimrImagesMutation, ImageSelectorDeleteVimrImagesMutationVariables>;

/**
 * __useImageSelectorDeleteVimrImagesMutation__
 *
 * To run a mutation, you first call `useImageSelectorDeleteVimrImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImageSelectorDeleteVimrImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [imageSelectorDeleteVimrImagesMutation, { data, loading, error }] = useImageSelectorDeleteVimrImagesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useImageSelectorDeleteVimrImagesMutation(baseOptions?: Apollo.MutationHookOptions<ImageSelectorDeleteVimrImagesMutation, ImageSelectorDeleteVimrImagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImageSelectorDeleteVimrImagesMutation, ImageSelectorDeleteVimrImagesMutationVariables>(ImageSelectorDeleteVimrImagesDocument, options);
      }
export type ImageSelectorDeleteVimrImagesMutationHookResult = ReturnType<typeof useImageSelectorDeleteVimrImagesMutation>;
export type ImageSelectorDeleteVimrImagesMutationResult = Apollo.MutationResult<ImageSelectorDeleteVimrImagesMutation>;
export type ImageSelectorDeleteVimrImagesMutationOptions = Apollo.BaseMutationOptions<ImageSelectorDeleteVimrImagesMutation, ImageSelectorDeleteVimrImagesMutationVariables>;
export const ModalRequestArtworkProposalDocument = gql`
    mutation ModalRequestArtworkProposal($data: String) {
  requestArtworkProposal(data: $data) {
    status
    project {
      id
      artworkProposalRequestedAt
    }
  }
}
    `;
export type ModalRequestArtworkProposalMutationFn = Apollo.MutationFunction<ModalRequestArtworkProposalMutation, ModalRequestArtworkProposalMutationVariables>;

/**
 * __useModalRequestArtworkProposalMutation__
 *
 * To run a mutation, you first call `useModalRequestArtworkProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModalRequestArtworkProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modalRequestArtworkProposalMutation, { data, loading, error }] = useModalRequestArtworkProposalMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useModalRequestArtworkProposalMutation(baseOptions?: Apollo.MutationHookOptions<ModalRequestArtworkProposalMutation, ModalRequestArtworkProposalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModalRequestArtworkProposalMutation, ModalRequestArtworkProposalMutationVariables>(ModalRequestArtworkProposalDocument, options);
      }
export type ModalRequestArtworkProposalMutationHookResult = ReturnType<typeof useModalRequestArtworkProposalMutation>;
export type ModalRequestArtworkProposalMutationResult = Apollo.MutationResult<ModalRequestArtworkProposalMutation>;
export type ModalRequestArtworkProposalMutationOptions = Apollo.BaseMutationOptions<ModalRequestArtworkProposalMutation, ModalRequestArtworkProposalMutationVariables>;
export const ModalReviewPendingQuotationDocument = gql`
    query ModalReviewPendingQuotation($hashId: String) {
  quotation(hashId: $hashId) {
    id
    submittedAtStr
    installationRequired
    comments
    shippingAddressStr
    billingAddressStr
    quotationitemSet {
      ...QuotationitemSetFields
    }
  }
}
    ${QuotationitemSetFieldsFragmentDoc}`;

/**
 * __useModalReviewPendingQuotationQuery__
 *
 * To run a query within a React component, call `useModalReviewPendingQuotationQuery` and pass it any options that fit your needs.
 * When your component renders, `useModalReviewPendingQuotationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModalReviewPendingQuotationQuery({
 *   variables: {
 *      hashId: // value for 'hashId'
 *   },
 * });
 */
export function useModalReviewPendingQuotationQuery(baseOptions?: Apollo.QueryHookOptions<ModalReviewPendingQuotationQuery, ModalReviewPendingQuotationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModalReviewPendingQuotationQuery, ModalReviewPendingQuotationQueryVariables>(ModalReviewPendingQuotationDocument, options);
      }
export function useModalReviewPendingQuotationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModalReviewPendingQuotationQuery, ModalReviewPendingQuotationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModalReviewPendingQuotationQuery, ModalReviewPendingQuotationQueryVariables>(ModalReviewPendingQuotationDocument, options);
        }
export type ModalReviewPendingQuotationQueryHookResult = ReturnType<typeof useModalReviewPendingQuotationQuery>;
export type ModalReviewPendingQuotationLazyQueryHookResult = ReturnType<typeof useModalReviewPendingQuotationLazyQuery>;
export type ModalReviewPendingQuotationQueryResult = Apollo.QueryResult<ModalReviewPendingQuotationQuery, ModalReviewPendingQuotationQueryVariables>;
export const ModalSourcingArtistCreateUpdateArtistDocument = gql`
    query ModalSourcingArtistCreateUpdateArtist($artistId: String) {
  artist(artistId: $artistId) {
    id
    name
    website
    contactEmail
    yearOfBirth
    country
    tags
    notes
  }
}
    `;

/**
 * __useModalSourcingArtistCreateUpdateArtistQuery__
 *
 * To run a query within a React component, call `useModalSourcingArtistCreateUpdateArtistQuery` and pass it any options that fit your needs.
 * When your component renders, `useModalSourcingArtistCreateUpdateArtistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModalSourcingArtistCreateUpdateArtistQuery({
 *   variables: {
 *      artistId: // value for 'artistId'
 *   },
 * });
 */
export function useModalSourcingArtistCreateUpdateArtistQuery(baseOptions?: Apollo.QueryHookOptions<ModalSourcingArtistCreateUpdateArtistQuery, ModalSourcingArtistCreateUpdateArtistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModalSourcingArtistCreateUpdateArtistQuery, ModalSourcingArtistCreateUpdateArtistQueryVariables>(ModalSourcingArtistCreateUpdateArtistDocument, options);
      }
export function useModalSourcingArtistCreateUpdateArtistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModalSourcingArtistCreateUpdateArtistQuery, ModalSourcingArtistCreateUpdateArtistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModalSourcingArtistCreateUpdateArtistQuery, ModalSourcingArtistCreateUpdateArtistQueryVariables>(ModalSourcingArtistCreateUpdateArtistDocument, options);
        }
export type ModalSourcingArtistCreateUpdateArtistQueryHookResult = ReturnType<typeof useModalSourcingArtistCreateUpdateArtistQuery>;
export type ModalSourcingArtistCreateUpdateArtistLazyQueryHookResult = ReturnType<typeof useModalSourcingArtistCreateUpdateArtistLazyQuery>;
export type ModalSourcingArtistCreateUpdateArtistQueryResult = Apollo.QueryResult<ModalSourcingArtistCreateUpdateArtistQuery, ModalSourcingArtistCreateUpdateArtistQueryVariables>;
export const ModalSourcingArtistCreateUpdateGalleryDocument = gql`
    query ModalSourcingArtistCreateUpdateGallery($galleryId: String) {
  gallery(galleryId: $galleryId) {
    id
    name
    website
    contactEmail
    contactNumber
    countryCodes
    tags
    notes
  }
}
    `;

/**
 * __useModalSourcingArtistCreateUpdateGalleryQuery__
 *
 * To run a query within a React component, call `useModalSourcingArtistCreateUpdateGalleryQuery` and pass it any options that fit your needs.
 * When your component renders, `useModalSourcingArtistCreateUpdateGalleryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModalSourcingArtistCreateUpdateGalleryQuery({
 *   variables: {
 *      galleryId: // value for 'galleryId'
 *   },
 * });
 */
export function useModalSourcingArtistCreateUpdateGalleryQuery(baseOptions?: Apollo.QueryHookOptions<ModalSourcingArtistCreateUpdateGalleryQuery, ModalSourcingArtistCreateUpdateGalleryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModalSourcingArtistCreateUpdateGalleryQuery, ModalSourcingArtistCreateUpdateGalleryQueryVariables>(ModalSourcingArtistCreateUpdateGalleryDocument, options);
      }
export function useModalSourcingArtistCreateUpdateGalleryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModalSourcingArtistCreateUpdateGalleryQuery, ModalSourcingArtistCreateUpdateGalleryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModalSourcingArtistCreateUpdateGalleryQuery, ModalSourcingArtistCreateUpdateGalleryQueryVariables>(ModalSourcingArtistCreateUpdateGalleryDocument, options);
        }
export type ModalSourcingArtistCreateUpdateGalleryQueryHookResult = ReturnType<typeof useModalSourcingArtistCreateUpdateGalleryQuery>;
export type ModalSourcingArtistCreateUpdateGalleryLazyQueryHookResult = ReturnType<typeof useModalSourcingArtistCreateUpdateGalleryLazyQuery>;
export type ModalSourcingArtistCreateUpdateGalleryQueryResult = Apollo.QueryResult<ModalSourcingArtistCreateUpdateGalleryQuery, ModalSourcingArtistCreateUpdateGalleryQueryVariables>;
export const ModalStaffSendArtworkProposalDocument = gql`
    mutation ModalStaffSendArtworkProposal($data: String) {
  staffFinishArtworkProposal(data: $data) {
    status
    project {
      id
      artworkProposalRequestedAt
      artworkProposalSentAt
    }
  }
}
    `;
export type ModalStaffSendArtworkProposalMutationFn = Apollo.MutationFunction<ModalStaffSendArtworkProposalMutation, ModalStaffSendArtworkProposalMutationVariables>;

/**
 * __useModalStaffSendArtworkProposalMutation__
 *
 * To run a mutation, you first call `useModalStaffSendArtworkProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModalStaffSendArtworkProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modalStaffSendArtworkProposalMutation, { data, loading, error }] = useModalStaffSendArtworkProposalMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useModalStaffSendArtworkProposalMutation(baseOptions?: Apollo.MutationHookOptions<ModalStaffSendArtworkProposalMutation, ModalStaffSendArtworkProposalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModalStaffSendArtworkProposalMutation, ModalStaffSendArtworkProposalMutationVariables>(ModalStaffSendArtworkProposalDocument, options);
      }
export type ModalStaffSendArtworkProposalMutationHookResult = ReturnType<typeof useModalStaffSendArtworkProposalMutation>;
export type ModalStaffSendArtworkProposalMutationResult = Apollo.MutationResult<ModalStaffSendArtworkProposalMutation>;
export type ModalStaffSendArtworkProposalMutationOptions = Apollo.BaseMutationOptions<ModalStaffSendArtworkProposalMutation, ModalStaffSendArtworkProposalMutationVariables>;
export const SidebarUserDocument = gql`
    query SidebarUser($hashId: String) {
  project(hashId: $hashId) {
    id
    hashId
    wizardStep
    bucketItemsCount
    artworkProposalRequestedAt
    artworkProposalSentAt
    status
  }
}
    `;

/**
 * __useSidebarUserQuery__
 *
 * To run a query within a React component, call `useSidebarUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useSidebarUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSidebarUserQuery({
 *   variables: {
 *      hashId: // value for 'hashId'
 *   },
 * });
 */
export function useSidebarUserQuery(baseOptions?: Apollo.QueryHookOptions<SidebarUserQuery, SidebarUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SidebarUserQuery, SidebarUserQueryVariables>(SidebarUserDocument, options);
      }
export function useSidebarUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SidebarUserQuery, SidebarUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SidebarUserQuery, SidebarUserQueryVariables>(SidebarUserDocument, options);
        }
export type SidebarUserQueryHookResult = ReturnType<typeof useSidebarUserQuery>;
export type SidebarUserLazyQueryHookResult = ReturnType<typeof useSidebarUserLazyQuery>;
export type SidebarUserQueryResult = Apollo.QueryResult<SidebarUserQuery, SidebarUserQueryVariables>;
export const SidebarUserNewDocument = gql`
    query SidebarUserNew($hashId: String) {
  project(hashId: $hashId) {
    id
    hashId
    wizardStep
    bucketItemsCount
    artworkProposalRequestedAt
    artworkProposalSentAt
  }
}
    `;

/**
 * __useSidebarUserNewQuery__
 *
 * To run a query within a React component, call `useSidebarUserNewQuery` and pass it any options that fit your needs.
 * When your component renders, `useSidebarUserNewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSidebarUserNewQuery({
 *   variables: {
 *      hashId: // value for 'hashId'
 *   },
 * });
 */
export function useSidebarUserNewQuery(baseOptions?: Apollo.QueryHookOptions<SidebarUserNewQuery, SidebarUserNewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SidebarUserNewQuery, SidebarUserNewQueryVariables>(SidebarUserNewDocument, options);
      }
export function useSidebarUserNewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SidebarUserNewQuery, SidebarUserNewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SidebarUserNewQuery, SidebarUserNewQueryVariables>(SidebarUserNewDocument, options);
        }
export type SidebarUserNewQueryHookResult = ReturnType<typeof useSidebarUserNewQuery>;
export type SidebarUserNewLazyQueryHookResult = ReturnType<typeof useSidebarUserNewLazyQuery>;
export type SidebarUserNewQueryResult = Apollo.QueryResult<SidebarUserNewQuery, SidebarUserNewQueryVariables>;
export const SidebarUserOldDocument = gql`
    query SidebarUserOld($hashId: String) {
  project(hashId: $hashId) {
    id
    hashId
    wizardStep
    bucketItemsCount
    artworkProposalRequestedAt
    artworkProposalSentAt
  }
}
    `;

/**
 * __useSidebarUserOldQuery__
 *
 * To run a query within a React component, call `useSidebarUserOldQuery` and pass it any options that fit your needs.
 * When your component renders, `useSidebarUserOldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSidebarUserOldQuery({
 *   variables: {
 *      hashId: // value for 'hashId'
 *   },
 * });
 */
export function useSidebarUserOldQuery(baseOptions?: Apollo.QueryHookOptions<SidebarUserOldQuery, SidebarUserOldQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SidebarUserOldQuery, SidebarUserOldQueryVariables>(SidebarUserOldDocument, options);
      }
export function useSidebarUserOldLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SidebarUserOldQuery, SidebarUserOldQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SidebarUserOldQuery, SidebarUserOldQueryVariables>(SidebarUserOldDocument, options);
        }
export type SidebarUserOldQueryHookResult = ReturnType<typeof useSidebarUserOldQuery>;
export type SidebarUserOldLazyQueryHookResult = ReturnType<typeof useSidebarUserOldLazyQuery>;
export type SidebarUserOldQueryResult = Apollo.QueryResult<SidebarUserOldQuery, SidebarUserOldQueryVariables>;
export const TopbarSubRecentProjectsDocument = gql`
    query TopbarSubRecentProjects {
  recentProjects {
    id
    project {
      id
      title
      client {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useTopbarSubRecentProjectsQuery__
 *
 * To run a query within a React component, call `useTopbarSubRecentProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopbarSubRecentProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopbarSubRecentProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTopbarSubRecentProjectsQuery(baseOptions?: Apollo.QueryHookOptions<TopbarSubRecentProjectsQuery, TopbarSubRecentProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopbarSubRecentProjectsQuery, TopbarSubRecentProjectsQueryVariables>(TopbarSubRecentProjectsDocument, options);
      }
export function useTopbarSubRecentProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopbarSubRecentProjectsQuery, TopbarSubRecentProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopbarSubRecentProjectsQuery, TopbarSubRecentProjectsQueryVariables>(TopbarSubRecentProjectsDocument, options);
        }
export type TopbarSubRecentProjectsQueryHookResult = ReturnType<typeof useTopbarSubRecentProjectsQuery>;
export type TopbarSubRecentProjectsLazyQueryHookResult = ReturnType<typeof useTopbarSubRecentProjectsLazyQuery>;
export type TopbarSubRecentProjectsQueryResult = Apollo.QueryResult<TopbarSubRecentProjectsQuery, TopbarSubRecentProjectsQueryVariables>;
export const LocationDetailsListItemUpdateBucketItemDocument = gql`
    mutation LocationDetailsListItemUpdateBucketItem($hashId: String, $locationItemId: String, $esId: String, $customText: String) {
  updateBucketItem(hashId: $hashId, locationItemId: $locationItemId, esId: $esId) {
    status
    formErrors
    bucketItem {
      id
      customText
      customSize
    }
  }
}
    `;
export type LocationDetailsListItemUpdateBucketItemMutationFn = Apollo.MutationFunction<LocationDetailsListItemUpdateBucketItemMutation, LocationDetailsListItemUpdateBucketItemMutationVariables>;

/**
 * __useLocationDetailsListItemUpdateBucketItemMutation__
 *
 * To run a mutation, you first call `useLocationDetailsListItemUpdateBucketItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLocationDetailsListItemUpdateBucketItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [locationDetailsListItemUpdateBucketItemMutation, { data, loading, error }] = useLocationDetailsListItemUpdateBucketItemMutation({
 *   variables: {
 *      hashId: // value for 'hashId'
 *      locationItemId: // value for 'locationItemId'
 *      esId: // value for 'esId'
 *      customText: // value for 'customText'
 *   },
 * });
 */
export function useLocationDetailsListItemUpdateBucketItemMutation(baseOptions?: Apollo.MutationHookOptions<LocationDetailsListItemUpdateBucketItemMutation, LocationDetailsListItemUpdateBucketItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LocationDetailsListItemUpdateBucketItemMutation, LocationDetailsListItemUpdateBucketItemMutationVariables>(LocationDetailsListItemUpdateBucketItemDocument, options);
      }
export type LocationDetailsListItemUpdateBucketItemMutationHookResult = ReturnType<typeof useLocationDetailsListItemUpdateBucketItemMutation>;
export type LocationDetailsListItemUpdateBucketItemMutationResult = Apollo.MutationResult<LocationDetailsListItemUpdateBucketItemMutation>;
export type LocationDetailsListItemUpdateBucketItemMutationOptions = Apollo.BaseMutationOptions<LocationDetailsListItemUpdateBucketItemMutation, LocationDetailsListItemUpdateBucketItemMutationVariables>;
export const LocationDetailsListItemResetBucketItemCustomTextDocument = gql`
    mutation LocationDetailsListItemResetBucketItemCustomText($data: String) {
  resetBucketItemCustomText(data: $data) {
    status
    bucketItem {
      id
      copyPasteText
      bucketItem {
        id
        ...BucketItemDetailsFragment
      }
    }
  }
}
    ${BucketItemDetailsFragmentFragmentDoc}`;
export type LocationDetailsListItemResetBucketItemCustomTextMutationFn = Apollo.MutationFunction<LocationDetailsListItemResetBucketItemCustomTextMutation, LocationDetailsListItemResetBucketItemCustomTextMutationVariables>;

/**
 * __useLocationDetailsListItemResetBucketItemCustomTextMutation__
 *
 * To run a mutation, you first call `useLocationDetailsListItemResetBucketItemCustomTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLocationDetailsListItemResetBucketItemCustomTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [locationDetailsListItemResetBucketItemCustomTextMutation, { data, loading, error }] = useLocationDetailsListItemResetBucketItemCustomTextMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLocationDetailsListItemResetBucketItemCustomTextMutation(baseOptions?: Apollo.MutationHookOptions<LocationDetailsListItemResetBucketItemCustomTextMutation, LocationDetailsListItemResetBucketItemCustomTextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LocationDetailsListItemResetBucketItemCustomTextMutation, LocationDetailsListItemResetBucketItemCustomTextMutationVariables>(LocationDetailsListItemResetBucketItemCustomTextDocument, options);
      }
export type LocationDetailsListItemResetBucketItemCustomTextMutationHookResult = ReturnType<typeof useLocationDetailsListItemResetBucketItemCustomTextMutation>;
export type LocationDetailsListItemResetBucketItemCustomTextMutationResult = Apollo.MutationResult<LocationDetailsListItemResetBucketItemCustomTextMutation>;
export type LocationDetailsListItemResetBucketItemCustomTextMutationOptions = Apollo.BaseMutationOptions<LocationDetailsListItemResetBucketItemCustomTextMutation, LocationDetailsListItemResetBucketItemCustomTextMutationVariables>;
export const SearchSimilarImageCacheDocument = gql`
    mutation SearchSimilarImageCache($fileKey: String!) {
  getImageCache(fileKey: $fileKey) {
    status
    imageCache {
      id
      imageHash
    }
  }
}
    `;
export type SearchSimilarImageCacheMutationFn = Apollo.MutationFunction<SearchSimilarImageCacheMutation, SearchSimilarImageCacheMutationVariables>;

/**
 * __useSearchSimilarImageCacheMutation__
 *
 * To run a mutation, you first call `useSearchSimilarImageCacheMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSearchSimilarImageCacheMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [searchSimilarImageCacheMutation, { data, loading, error }] = useSearchSimilarImageCacheMutation({
 *   variables: {
 *      fileKey: // value for 'fileKey'
 *   },
 * });
 */
export function useSearchSimilarImageCacheMutation(baseOptions?: Apollo.MutationHookOptions<SearchSimilarImageCacheMutation, SearchSimilarImageCacheMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SearchSimilarImageCacheMutation, SearchSimilarImageCacheMutationVariables>(SearchSimilarImageCacheDocument, options);
      }
export type SearchSimilarImageCacheMutationHookResult = ReturnType<typeof useSearchSimilarImageCacheMutation>;
export type SearchSimilarImageCacheMutationResult = Apollo.MutationResult<SearchSimilarImageCacheMutation>;
export type SearchSimilarImageCacheMutationOptions = Apollo.BaseMutationOptions<SearchSimilarImageCacheMutation, SearchSimilarImageCacheMutationVariables>;
export const SearchSimilarDocument = gql`
    query SearchSimilar($imgHash: String) {
  cacheImg(imgHash: $imgHash) {
    id
    key
  }
}
    `;

/**
 * __useSearchSimilarQuery__
 *
 * To run a query within a React component, call `useSearchSimilarQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSimilarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSimilarQuery({
 *   variables: {
 *      imgHash: // value for 'imgHash'
 *   },
 * });
 */
export function useSearchSimilarQuery(baseOptions?: Apollo.QueryHookOptions<SearchSimilarQuery, SearchSimilarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchSimilarQuery, SearchSimilarQueryVariables>(SearchSimilarDocument, options);
      }
export function useSearchSimilarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchSimilarQuery, SearchSimilarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchSimilarQuery, SearchSimilarQueryVariables>(SearchSimilarDocument, options);
        }
export type SearchSimilarQueryHookResult = ReturnType<typeof useSearchSimilarQuery>;
export type SearchSimilarLazyQueryHookResult = ReturnType<typeof useSearchSimilarLazyQuery>;
export type SearchSimilarQueryResult = Apollo.QueryResult<SearchSimilarQuery, SearchSimilarQueryVariables>;
export const SearchSimilarStretchedDocument = gql`
    mutation SearchSimilarStretched($fileKey: String!) {
  getImageCache(fileKey: $fileKey) {
    status
    imageCache {
      id
      imageHash
    }
  }
}
    `;
export type SearchSimilarStretchedMutationFn = Apollo.MutationFunction<SearchSimilarStretchedMutation, SearchSimilarStretchedMutationVariables>;

/**
 * __useSearchSimilarStretchedMutation__
 *
 * To run a mutation, you first call `useSearchSimilarStretchedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSearchSimilarStretchedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [searchSimilarStretchedMutation, { data, loading, error }] = useSearchSimilarStretchedMutation({
 *   variables: {
 *      fileKey: // value for 'fileKey'
 *   },
 * });
 */
export function useSearchSimilarStretchedMutation(baseOptions?: Apollo.MutationHookOptions<SearchSimilarStretchedMutation, SearchSimilarStretchedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SearchSimilarStretchedMutation, SearchSimilarStretchedMutationVariables>(SearchSimilarStretchedDocument, options);
      }
export type SearchSimilarStretchedMutationHookResult = ReturnType<typeof useSearchSimilarStretchedMutation>;
export type SearchSimilarStretchedMutationResult = Apollo.MutationResult<SearchSimilarStretchedMutation>;
export type SearchSimilarStretchedMutationOptions = Apollo.BaseMutationOptions<SearchSimilarStretchedMutation, SearchSimilarStretchedMutationVariables>;
export const LocationImageDocument = gql`
    mutation LocationImage($data: String) {
  createUpdateLocationImage(data: $data) {
    status
    location {
      id
      locationImages {
        id
        externalId
        image
      }
    }
  }
}
    `;
export type LocationImageMutationFn = Apollo.MutationFunction<LocationImageMutation, LocationImageMutationVariables>;

/**
 * __useLocationImageMutation__
 *
 * To run a mutation, you first call `useLocationImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLocationImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [locationImageMutation, { data, loading, error }] = useLocationImageMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLocationImageMutation(baseOptions?: Apollo.MutationHookOptions<LocationImageMutation, LocationImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LocationImageMutation, LocationImageMutationVariables>(LocationImageDocument, options);
      }
export type LocationImageMutationHookResult = ReturnType<typeof useLocationImageMutation>;
export type LocationImageMutationResult = Apollo.MutationResult<LocationImageMutation>;
export type LocationImageMutationOptions = Apollo.BaseMutationOptions<LocationImageMutation, LocationImageMutationVariables>;
export const LocationInfoDocument = gql`
    mutation LocationInfo($data: String) {
  updateLocationInfo(data: $data) {
    status
    location {
      id
      externalId
      info
    }
  }
}
    `;
export type LocationInfoMutationFn = Apollo.MutationFunction<LocationInfoMutation, LocationInfoMutationVariables>;

/**
 * __useLocationInfoMutation__
 *
 * To run a mutation, you first call `useLocationInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLocationInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [locationInfoMutation, { data, loading, error }] = useLocationInfoMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLocationInfoMutation(baseOptions?: Apollo.MutationHookOptions<LocationInfoMutation, LocationInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LocationInfoMutation, LocationInfoMutationVariables>(LocationInfoDocument, options);
      }
export type LocationInfoMutationHookResult = ReturnType<typeof useLocationInfoMutation>;
export type LocationInfoMutationResult = Apollo.MutationResult<LocationInfoMutation>;
export type LocationInfoMutationOptions = Apollo.BaseMutationOptions<LocationInfoMutation, LocationInfoMutationVariables>;
export const LocationListDocument = gql`
    mutation LocationList($data: String) {
  createNewLocation(data: $data) {
    status
    project {
      id
      locations {
        id
        externalId
        title
        locationImages {
          id
          externalId
        }
        locationItems {
          id
          ...LocationItemDetailFragment
        }
      }
    }
  }
}
    ${LocationItemDetailFragmentFragmentDoc}`;
export type LocationListMutationFn = Apollo.MutationFunction<LocationListMutation, LocationListMutationVariables>;

/**
 * __useLocationListMutation__
 *
 * To run a mutation, you first call `useLocationListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLocationListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [locationListMutation, { data, loading, error }] = useLocationListMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLocationListMutation(baseOptions?: Apollo.MutationHookOptions<LocationListMutation, LocationListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LocationListMutation, LocationListMutationVariables>(LocationListDocument, options);
      }
export type LocationListMutationHookResult = ReturnType<typeof useLocationListMutation>;
export type LocationListMutationResult = Apollo.MutationResult<LocationListMutation>;
export type LocationListMutationOptions = Apollo.BaseMutationOptions<LocationListMutation, LocationListMutationVariables>;
export const LocationWithItemsLocationDocument = gql`
    mutation LocationWithItemsLocation($data: String) {
  createNewLocationItem(data: $data) {
    status
    project {
      id
      locations {
        id
        externalId
        title
        locationImages {
          id
          externalId
          image
        }
        locationItems {
          id
          ...LocationItemDetailFragment
        }
      }
    }
  }
}
    ${LocationItemDetailFragmentFragmentDoc}`;
export type LocationWithItemsLocationMutationFn = Apollo.MutationFunction<LocationWithItemsLocationMutation, LocationWithItemsLocationMutationVariables>;

/**
 * __useLocationWithItemsLocationMutation__
 *
 * To run a mutation, you first call `useLocationWithItemsLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLocationWithItemsLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [locationWithItemsLocationMutation, { data, loading, error }] = useLocationWithItemsLocationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLocationWithItemsLocationMutation(baseOptions?: Apollo.MutationHookOptions<LocationWithItemsLocationMutation, LocationWithItemsLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LocationWithItemsLocationMutation, LocationWithItemsLocationMutationVariables>(LocationWithItemsLocationDocument, options);
      }
export type LocationWithItemsLocationMutationHookResult = ReturnType<typeof useLocationWithItemsLocationMutation>;
export type LocationWithItemsLocationMutationResult = Apollo.MutationResult<LocationWithItemsLocationMutation>;
export type LocationWithItemsLocationMutationOptions = Apollo.BaseMutationOptions<LocationWithItemsLocationMutation, LocationWithItemsLocationMutationVariables>;
export const WfnwFramersForProjectTableDocument = gql`
    query WFNWFramersForProjectTable($hashId: String) {
  project(hashId: $hashId) {
    id
    wfNwFramers {
      id
      ...FramerRowFragment
    }
  }
}
    ${FramerRowFragmentFragmentDoc}`;

/**
 * __useWfnwFramersForProjectTableQuery__
 *
 * To run a query within a React component, call `useWfnwFramersForProjectTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useWfnwFramersForProjectTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWfnwFramersForProjectTableQuery({
 *   variables: {
 *      hashId: // value for 'hashId'
 *   },
 * });
 */
export function useWfnwFramersForProjectTableQuery(baseOptions?: Apollo.QueryHookOptions<WfnwFramersForProjectTableQuery, WfnwFramersForProjectTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WfnwFramersForProjectTableQuery, WfnwFramersForProjectTableQueryVariables>(WfnwFramersForProjectTableDocument, options);
      }
export function useWfnwFramersForProjectTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WfnwFramersForProjectTableQuery, WfnwFramersForProjectTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WfnwFramersForProjectTableQuery, WfnwFramersForProjectTableQueryVariables>(WfnwFramersForProjectTableDocument, options);
        }
export type WfnwFramersForProjectTableQueryHookResult = ReturnType<typeof useWfnwFramersForProjectTableQuery>;
export type WfnwFramersForProjectTableLazyQueryHookResult = ReturnType<typeof useWfnwFramersForProjectTableLazyQuery>;
export type WfnwFramersForProjectTableQueryResult = Apollo.QueryResult<WfnwFramersForProjectTableQuery, WfnwFramersForProjectTableQueryVariables>;
export const WfnwFramersForProjectTableDeleteFramerDocument = gql`
    mutation WFNWFramersForProjectTableDeleteFramer($data: String) {
  deleteFramerFromProject(data: $data) {
    status
    formErrors
    project {
      id
      wfNwFramers {
        id
        ...FramerRowFragment
      }
    }
  }
}
    ${FramerRowFragmentFragmentDoc}`;
export type WfnwFramersForProjectTableDeleteFramerMutationFn = Apollo.MutationFunction<WfnwFramersForProjectTableDeleteFramerMutation, WfnwFramersForProjectTableDeleteFramerMutationVariables>;

/**
 * __useWfnwFramersForProjectTableDeleteFramerMutation__
 *
 * To run a mutation, you first call `useWfnwFramersForProjectTableDeleteFramerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWfnwFramersForProjectTableDeleteFramerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wfnwFramersForProjectTableDeleteFramerMutation, { data, loading, error }] = useWfnwFramersForProjectTableDeleteFramerMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useWfnwFramersForProjectTableDeleteFramerMutation(baseOptions?: Apollo.MutationHookOptions<WfnwFramersForProjectTableDeleteFramerMutation, WfnwFramersForProjectTableDeleteFramerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WfnwFramersForProjectTableDeleteFramerMutation, WfnwFramersForProjectTableDeleteFramerMutationVariables>(WfnwFramersForProjectTableDeleteFramerDocument, options);
      }
export type WfnwFramersForProjectTableDeleteFramerMutationHookResult = ReturnType<typeof useWfnwFramersForProjectTableDeleteFramerMutation>;
export type WfnwFramersForProjectTableDeleteFramerMutationResult = Apollo.MutationResult<WfnwFramersForProjectTableDeleteFramerMutation>;
export type WfnwFramersForProjectTableDeleteFramerMutationOptions = Apollo.BaseMutationOptions<WfnwFramersForProjectTableDeleteFramerMutation, WfnwFramersForProjectTableDeleteFramerMutationVariables>;
export const WorkflowProjectDocument = gql`
    query WorkflowProject($data: String) {
  wfNormalWorksStepsData(data: $data) {
    itemGroup
    projectTitle
    steps {
      step
      items {
        id
        image
        esId
        currentStates {
          id
          state
          uniqueUrl
        }
        locationItem {
          id
          location {
            id
            project {
              id
              hashId
            }
          }
        }
      }
    }
  }
  wfCommissionedWorksStepsData(data: $data) {
    itemGroup
    projectTitle
    steps {
      step
      items {
        id
        image
        esId
        artist {
          id
          contactEmail
        }
        currentStates {
          id
          state
          uniqueUrl
        }
        locationItem {
          id
          location {
            id
            project {
              id
              hashId
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useWorkflowProjectQuery__
 *
 * To run a query within a React component, call `useWorkflowProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkflowProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkflowProjectQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useWorkflowProjectQuery(baseOptions?: Apollo.QueryHookOptions<WorkflowProjectQuery, WorkflowProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkflowProjectQuery, WorkflowProjectQueryVariables>(WorkflowProjectDocument, options);
      }
export function useWorkflowProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkflowProjectQuery, WorkflowProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkflowProjectQuery, WorkflowProjectQueryVariables>(WorkflowProjectDocument, options);
        }
export type WorkflowProjectQueryHookResult = ReturnType<typeof useWorkflowProjectQuery>;
export type WorkflowProjectLazyQueryHookResult = ReturnType<typeof useWorkflowProjectLazyQuery>;
export type WorkflowProjectQueryResult = Apollo.QueryResult<WorkflowProjectQuery, WorkflowProjectQueryVariables>;
export const DemoFormViewDocument = gql`
    query DemoFormView {
  artist(artistId: "442") {
    id
    name
    country
    tags
  }
}
    `;

/**
 * __useDemoFormViewQuery__
 *
 * To run a query within a React component, call `useDemoFormViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useDemoFormViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDemoFormViewQuery({
 *   variables: {
 *   },
 * });
 */
export function useDemoFormViewQuery(baseOptions?: Apollo.QueryHookOptions<DemoFormViewQuery, DemoFormViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DemoFormViewQuery, DemoFormViewQueryVariables>(DemoFormViewDocument, options);
      }
export function useDemoFormViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DemoFormViewQuery, DemoFormViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DemoFormViewQuery, DemoFormViewQueryVariables>(DemoFormViewDocument, options);
        }
export type DemoFormViewQueryHookResult = ReturnType<typeof useDemoFormViewQuery>;
export type DemoFormViewLazyQueryHookResult = ReturnType<typeof useDemoFormViewLazyQuery>;
export type DemoFormViewQueryResult = Apollo.QueryResult<DemoFormViewQuery, DemoFormViewQueryVariables>;
export const FavoritesViewDocument = gql`
    query FavoritesView($filters: String) {
  getProducts(filters: $filters, favorites: "true") {
    page
    pages
    hasNext
    hasPrev
    total
    relatedObject {
      id
      ...ProductGridFragment
    }
    objects {
      id
      ...ProductGridFragment
    }
  }
}
    ${ProductGridFragmentFragmentDoc}`;

/**
 * __useFavoritesViewQuery__
 *
 * To run a query within a React component, call `useFavoritesViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useFavoritesViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFavoritesViewQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useFavoritesViewQuery(baseOptions?: Apollo.QueryHookOptions<FavoritesViewQuery, FavoritesViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FavoritesViewQuery, FavoritesViewQueryVariables>(FavoritesViewDocument, options);
      }
export function useFavoritesViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FavoritesViewQuery, FavoritesViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FavoritesViewQuery, FavoritesViewQueryVariables>(FavoritesViewDocument, options);
        }
export type FavoritesViewQueryHookResult = ReturnType<typeof useFavoritesViewQuery>;
export type FavoritesViewLazyQueryHookResult = ReturnType<typeof useFavoritesViewLazyQuery>;
export type FavoritesViewQueryResult = Apollo.QueryResult<FavoritesViewQuery, FavoritesViewQueryVariables>;
export const FramerWfNormalWorksQuotationViewDocument = gql`
    query FramerWFNormalWorksQuotationView($uuid: String, $framerHashId: String) {
  wfNwFramerRfqData(uuid: $uuid, framerHashId: $framerHashId) {
    id
    ...FramerGlobalQuotationFragment
  }
}
    ${FramerGlobalQuotationFragmentFragmentDoc}`;

/**
 * __useFramerWfNormalWorksQuotationViewQuery__
 *
 * To run a query within a React component, call `useFramerWfNormalWorksQuotationViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useFramerWfNormalWorksQuotationViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFramerWfNormalWorksQuotationViewQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      framerHashId: // value for 'framerHashId'
 *   },
 * });
 */
export function useFramerWfNormalWorksQuotationViewQuery(baseOptions?: Apollo.QueryHookOptions<FramerWfNormalWorksQuotationViewQuery, FramerWfNormalWorksQuotationViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FramerWfNormalWorksQuotationViewQuery, FramerWfNormalWorksQuotationViewQueryVariables>(FramerWfNormalWorksQuotationViewDocument, options);
      }
export function useFramerWfNormalWorksQuotationViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FramerWfNormalWorksQuotationViewQuery, FramerWfNormalWorksQuotationViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FramerWfNormalWorksQuotationViewQuery, FramerWfNormalWorksQuotationViewQueryVariables>(FramerWfNormalWorksQuotationViewDocument, options);
        }
export type FramerWfNormalWorksQuotationViewQueryHookResult = ReturnType<typeof useFramerWfNormalWorksQuotationViewQuery>;
export type FramerWfNormalWorksQuotationViewLazyQueryHookResult = ReturnType<typeof useFramerWfNormalWorksQuotationViewLazyQuery>;
export type FramerWfNormalWorksQuotationViewQueryResult = Apollo.QueryResult<FramerWfNormalWorksQuotationViewQuery, FramerWfNormalWorksQuotationViewQueryVariables>;
export const LoginViewDocument = gql`
    query LoginView {
  staticImage(identifier: "login-bg") {
    id
    image
    altText
  }
}
    `;

/**
 * __useLoginViewQuery__
 *
 * To run a query within a React component, call `useLoginViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginViewQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoginViewQuery(baseOptions?: Apollo.QueryHookOptions<LoginViewQuery, LoginViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoginViewQuery, LoginViewQueryVariables>(LoginViewDocument, options);
      }
export function useLoginViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoginViewQuery, LoginViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoginViewQuery, LoginViewQueryVariables>(LoginViewDocument, options);
        }
export type LoginViewQueryHookResult = ReturnType<typeof useLoginViewQuery>;
export type LoginViewLazyQueryHookResult = ReturnType<typeof useLoginViewLazyQuery>;
export type LoginViewQueryResult = Apollo.QueryResult<LoginViewQuery, LoginViewQueryVariables>;
export const MyAccountViewDocument = gql`
    query MyAccountView {
  currentProfile {
    id
    ...UserProfileFragment
  }
}
    ${UserProfileFragmentFragmentDoc}`;

/**
 * __useMyAccountViewQuery__
 *
 * To run a query within a React component, call `useMyAccountViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyAccountViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyAccountViewQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyAccountViewQuery(baseOptions?: Apollo.QueryHookOptions<MyAccountViewQuery, MyAccountViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyAccountViewQuery, MyAccountViewQueryVariables>(MyAccountViewDocument, options);
      }
export function useMyAccountViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyAccountViewQuery, MyAccountViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyAccountViewQuery, MyAccountViewQueryVariables>(MyAccountViewDocument, options);
        }
export type MyAccountViewQueryHookResult = ReturnType<typeof useMyAccountViewQuery>;
export type MyAccountViewLazyQueryHookResult = ReturnType<typeof useMyAccountViewLazyQuery>;
export type MyAccountViewQueryResult = Apollo.QueryResult<MyAccountViewQuery, MyAccountViewQueryVariables>;
export const PdfPreviewViewDocument = gql`
    query PDFPreviewView($proposalId: String) {
  getProposal(proposalId: $proposalId) {
    id
    ...PdfPreviewViewProposalFragment
  }
}
    ${PdfPreviewViewProposalFragmentFragmentDoc}`;

/**
 * __usePdfPreviewViewQuery__
 *
 * To run a query within a React component, call `usePdfPreviewViewQuery` and pass it any options that fit your needs.
 * When your component renders, `usePdfPreviewViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePdfPreviewViewQuery({
 *   variables: {
 *      proposalId: // value for 'proposalId'
 *   },
 * });
 */
export function usePdfPreviewViewQuery(baseOptions?: Apollo.QueryHookOptions<PdfPreviewViewQuery, PdfPreviewViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PdfPreviewViewQuery, PdfPreviewViewQueryVariables>(PdfPreviewViewDocument, options);
      }
export function usePdfPreviewViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PdfPreviewViewQuery, PdfPreviewViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PdfPreviewViewQuery, PdfPreviewViewQueryVariables>(PdfPreviewViewDocument, options);
        }
export type PdfPreviewViewQueryHookResult = ReturnType<typeof usePdfPreviewViewQuery>;
export type PdfPreviewViewLazyQueryHookResult = ReturnType<typeof usePdfPreviewViewLazyQuery>;
export type PdfPreviewViewQueryResult = Apollo.QueryResult<PdfPreviewViewQuery, PdfPreviewViewQueryVariables>;
export const PdfViewWrapperDocument = gql`
    query PDFViewWrapper($proposalId: String) {
  getProposal(proposalId: $proposalId) {
    id
    pdfHideTitle
    pdfHideArtist
    pdfHidePrice
    pdfHideShipping
    pdfHideAreaDetails
    pdfHideLogo
    pdfVertical
    pdfLogo
    pdfAddr
    pdfSite
    pdfEmail
    title
    project {
      id
      hashId
    }
    pages {
      id
      ...ProposalPageFragment
    }
  }
}
    ${ProposalPageFragmentFragmentDoc}`;

/**
 * __usePdfViewWrapperQuery__
 *
 * To run a query within a React component, call `usePdfViewWrapperQuery` and pass it any options that fit your needs.
 * When your component renders, `usePdfViewWrapperQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePdfViewWrapperQuery({
 *   variables: {
 *      proposalId: // value for 'proposalId'
 *   },
 * });
 */
export function usePdfViewWrapperQuery(baseOptions?: Apollo.QueryHookOptions<PdfViewWrapperQuery, PdfViewWrapperQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PdfViewWrapperQuery, PdfViewWrapperQueryVariables>(PdfViewWrapperDocument, options);
      }
export function usePdfViewWrapperLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PdfViewWrapperQuery, PdfViewWrapperQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PdfViewWrapperQuery, PdfViewWrapperQueryVariables>(PdfViewWrapperDocument, options);
        }
export type PdfViewWrapperQueryHookResult = ReturnType<typeof usePdfViewWrapperQuery>;
export type PdfViewWrapperLazyQueryHookResult = ReturnType<typeof usePdfViewWrapperLazyQuery>;
export type PdfViewWrapperQueryResult = Apollo.QueryResult<PdfViewWrapperQuery, PdfViewWrapperQueryVariables>;
export const PdfViewWrapperToggleDocument = gql`
    mutation PDFViewWrapperToggle($data: String) {
  toggleProposalVertical(data: $data) {
    status
    proposal {
      id
      pdfVertical
    }
  }
}
    `;
export type PdfViewWrapperToggleMutationFn = Apollo.MutationFunction<PdfViewWrapperToggleMutation, PdfViewWrapperToggleMutationVariables>;

/**
 * __usePdfViewWrapperToggleMutation__
 *
 * To run a mutation, you first call `usePdfViewWrapperToggleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePdfViewWrapperToggleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pdfViewWrapperToggleMutation, { data, loading, error }] = usePdfViewWrapperToggleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePdfViewWrapperToggleMutation(baseOptions?: Apollo.MutationHookOptions<PdfViewWrapperToggleMutation, PdfViewWrapperToggleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PdfViewWrapperToggleMutation, PdfViewWrapperToggleMutationVariables>(PdfViewWrapperToggleDocument, options);
      }
export type PdfViewWrapperToggleMutationHookResult = ReturnType<typeof usePdfViewWrapperToggleMutation>;
export type PdfViewWrapperToggleMutationResult = Apollo.MutationResult<PdfViewWrapperToggleMutation>;
export type PdfViewWrapperToggleMutationOptions = Apollo.BaseMutationOptions<PdfViewWrapperToggleMutation, PdfViewWrapperToggleMutationVariables>;
export const PdfViewWrapperImageSizeDocument = gql`
    mutation PDFViewWrapperImageSize($data: String) {
  updatePdfPageImageSize(data: $data) {
    status
    formErrors
    page {
      id
      pdfDetails
    }
  }
}
    `;
export type PdfViewWrapperImageSizeMutationFn = Apollo.MutationFunction<PdfViewWrapperImageSizeMutation, PdfViewWrapperImageSizeMutationVariables>;

/**
 * __usePdfViewWrapperImageSizeMutation__
 *
 * To run a mutation, you first call `usePdfViewWrapperImageSizeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePdfViewWrapperImageSizeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pdfViewWrapperImageSizeMutation, { data, loading, error }] = usePdfViewWrapperImageSizeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePdfViewWrapperImageSizeMutation(baseOptions?: Apollo.MutationHookOptions<PdfViewWrapperImageSizeMutation, PdfViewWrapperImageSizeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PdfViewWrapperImageSizeMutation, PdfViewWrapperImageSizeMutationVariables>(PdfViewWrapperImageSizeDocument, options);
      }
export type PdfViewWrapperImageSizeMutationHookResult = ReturnType<typeof usePdfViewWrapperImageSizeMutation>;
export type PdfViewWrapperImageSizeMutationResult = Apollo.MutationResult<PdfViewWrapperImageSizeMutation>;
export type PdfViewWrapperImageSizeMutationOptions = Apollo.BaseMutationOptions<PdfViewWrapperImageSizeMutation, PdfViewWrapperImageSizeMutationVariables>;
export const PdfViewWrapperImageDocument = gql`
    mutation PDFViewWrapperImage($data: String) {
  updatePdfPageImage(data: $data) {
    status
    formErrors
    page {
      id
      pdfDetails
    }
  }
}
    `;
export type PdfViewWrapperImageMutationFn = Apollo.MutationFunction<PdfViewWrapperImageMutation, PdfViewWrapperImageMutationVariables>;

/**
 * __usePdfViewWrapperImageMutation__
 *
 * To run a mutation, you first call `usePdfViewWrapperImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePdfViewWrapperImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pdfViewWrapperImageMutation, { data, loading, error }] = usePdfViewWrapperImageMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function usePdfViewWrapperImageMutation(baseOptions?: Apollo.MutationHookOptions<PdfViewWrapperImageMutation, PdfViewWrapperImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PdfViewWrapperImageMutation, PdfViewWrapperImageMutationVariables>(PdfViewWrapperImageDocument, options);
      }
export type PdfViewWrapperImageMutationHookResult = ReturnType<typeof usePdfViewWrapperImageMutation>;
export type PdfViewWrapperImageMutationResult = Apollo.MutationResult<PdfViewWrapperImageMutation>;
export type PdfViewWrapperImageMutationOptions = Apollo.BaseMutationOptions<PdfViewWrapperImageMutation, PdfViewWrapperImageMutationVariables>;
export const ProjectDetailsAreaOrderingViewDocument = gql`
    query ProjectDetailsAreaOrderingView($hashId: String) {
  project(hashId: $hashId) {
    id
    locations {
      ...ProjectLocationsFields
    }
  }
}
    ${ProjectLocationsFieldsFragmentDoc}`;

/**
 * __useProjectDetailsAreaOrderingViewQuery__
 *
 * To run a query within a React component, call `useProjectDetailsAreaOrderingViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectDetailsAreaOrderingViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectDetailsAreaOrderingViewQuery({
 *   variables: {
 *      hashId: // value for 'hashId'
 *   },
 * });
 */
export function useProjectDetailsAreaOrderingViewQuery(baseOptions?: Apollo.QueryHookOptions<ProjectDetailsAreaOrderingViewQuery, ProjectDetailsAreaOrderingViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectDetailsAreaOrderingViewQuery, ProjectDetailsAreaOrderingViewQueryVariables>(ProjectDetailsAreaOrderingViewDocument, options);
      }
export function useProjectDetailsAreaOrderingViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectDetailsAreaOrderingViewQuery, ProjectDetailsAreaOrderingViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectDetailsAreaOrderingViewQuery, ProjectDetailsAreaOrderingViewQueryVariables>(ProjectDetailsAreaOrderingViewDocument, options);
        }
export type ProjectDetailsAreaOrderingViewQueryHookResult = ReturnType<typeof useProjectDetailsAreaOrderingViewQuery>;
export type ProjectDetailsAreaOrderingViewLazyQueryHookResult = ReturnType<typeof useProjectDetailsAreaOrderingViewLazyQuery>;
export type ProjectDetailsAreaOrderingViewQueryResult = Apollo.QueryResult<ProjectDetailsAreaOrderingViewQuery, ProjectDetailsAreaOrderingViewQueryVariables>;
export const ProjectDetailsAreaOrderingViewMutationDocument = gql`
    mutation ProjectDetailsAreaOrderingViewMutation($data: String) {
  updateLocationPosition(data: $data) {
    status
  }
}
    `;
export type ProjectDetailsAreaOrderingViewMutationMutationFn = Apollo.MutationFunction<ProjectDetailsAreaOrderingViewMutationMutation, ProjectDetailsAreaOrderingViewMutationMutationVariables>;

/**
 * __useProjectDetailsAreaOrderingViewMutationMutation__
 *
 * To run a mutation, you first call `useProjectDetailsAreaOrderingViewMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectDetailsAreaOrderingViewMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectDetailsAreaOrderingViewMutationMutation, { data, loading, error }] = useProjectDetailsAreaOrderingViewMutationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useProjectDetailsAreaOrderingViewMutationMutation(baseOptions?: Apollo.MutationHookOptions<ProjectDetailsAreaOrderingViewMutationMutation, ProjectDetailsAreaOrderingViewMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectDetailsAreaOrderingViewMutationMutation, ProjectDetailsAreaOrderingViewMutationMutationVariables>(ProjectDetailsAreaOrderingViewMutationDocument, options);
      }
export type ProjectDetailsAreaOrderingViewMutationMutationHookResult = ReturnType<typeof useProjectDetailsAreaOrderingViewMutationMutation>;
export type ProjectDetailsAreaOrderingViewMutationMutationResult = Apollo.MutationResult<ProjectDetailsAreaOrderingViewMutationMutation>;
export type ProjectDetailsAreaOrderingViewMutationMutationOptions = Apollo.BaseMutationOptions<ProjectDetailsAreaOrderingViewMutationMutation, ProjectDetailsAreaOrderingViewMutationMutationVariables>;
export const ProjectDetailsOrderingViewDocument = gql`
    query ProjectDetailsOrderingView($hashId: String) {
  project(hashId: $hashId) {
    id
    title
    locations {
      id
      ...LocationPositionFragment
    }
  }
}
    ${LocationPositionFragmentFragmentDoc}`;

/**
 * __useProjectDetailsOrderingViewQuery__
 *
 * To run a query within a React component, call `useProjectDetailsOrderingViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectDetailsOrderingViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectDetailsOrderingViewQuery({
 *   variables: {
 *      hashId: // value for 'hashId'
 *   },
 * });
 */
export function useProjectDetailsOrderingViewQuery(baseOptions?: Apollo.QueryHookOptions<ProjectDetailsOrderingViewQuery, ProjectDetailsOrderingViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectDetailsOrderingViewQuery, ProjectDetailsOrderingViewQueryVariables>(ProjectDetailsOrderingViewDocument, options);
      }
export function useProjectDetailsOrderingViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectDetailsOrderingViewQuery, ProjectDetailsOrderingViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectDetailsOrderingViewQuery, ProjectDetailsOrderingViewQueryVariables>(ProjectDetailsOrderingViewDocument, options);
        }
export type ProjectDetailsOrderingViewQueryHookResult = ReturnType<typeof useProjectDetailsOrderingViewQuery>;
export type ProjectDetailsOrderingViewLazyQueryHookResult = ReturnType<typeof useProjectDetailsOrderingViewLazyQuery>;
export type ProjectDetailsOrderingViewQueryResult = Apollo.QueryResult<ProjectDetailsOrderingViewQuery, ProjectDetailsOrderingViewQueryVariables>;
export const ProjectDetailsOrderingViewMutationDocument = gql`
    mutation ProjectDetailsOrderingViewMutation($data: String) {
  updateAreaBucketPositions(data: $data) {
    status
  }
}
    `;
export type ProjectDetailsOrderingViewMutationMutationFn = Apollo.MutationFunction<ProjectDetailsOrderingViewMutationMutation, ProjectDetailsOrderingViewMutationMutationVariables>;

/**
 * __useProjectDetailsOrderingViewMutationMutation__
 *
 * To run a mutation, you first call `useProjectDetailsOrderingViewMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectDetailsOrderingViewMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectDetailsOrderingViewMutationMutation, { data, loading, error }] = useProjectDetailsOrderingViewMutationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useProjectDetailsOrderingViewMutationMutation(baseOptions?: Apollo.MutationHookOptions<ProjectDetailsOrderingViewMutationMutation, ProjectDetailsOrderingViewMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectDetailsOrderingViewMutationMutation, ProjectDetailsOrderingViewMutationMutationVariables>(ProjectDetailsOrderingViewMutationDocument, options);
      }
export type ProjectDetailsOrderingViewMutationMutationHookResult = ReturnType<typeof useProjectDetailsOrderingViewMutationMutation>;
export type ProjectDetailsOrderingViewMutationMutationResult = Apollo.MutationResult<ProjectDetailsOrderingViewMutationMutation>;
export type ProjectDetailsOrderingViewMutationMutationOptions = Apollo.BaseMutationOptions<ProjectDetailsOrderingViewMutationMutation, ProjectDetailsOrderingViewMutationMutationVariables>;
export const ProjectDetailsViewDocument = gql`
    query ProjectDetailsView($hashId: String) {
  project(hashId: $hashId) {
    id
    bucketItemsCount
    budget
    budgetCurrency
    budgetUsd
    title
    hashId
    artworkProposalRequestedAt
    artworkProposalSentAt
    statusComputed
    status
    vimrImages {
      id
    }
    locations {
      id
      ...LocationPositionFragment
    }
  }
  getProjectProposals(hashId: $hashId) {
    id
  }
  getExistingWorksForProject(hashId: $hashId) {
    id
    isShortlisted
    price
    locationItem {
      id
      location {
        id
        title
      }
    }
  }
  getCommissionedWorksForProject(hashId: $hashId) {
    id
    isShortlisted
    price
    locationItem {
      id
      location {
        id
        title
      }
    }
  }
}
    ${LocationPositionFragmentFragmentDoc}`;

/**
 * __useProjectDetailsViewQuery__
 *
 * To run a query within a React component, call `useProjectDetailsViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectDetailsViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectDetailsViewQuery({
 *   variables: {
 *      hashId: // value for 'hashId'
 *   },
 * });
 */
export function useProjectDetailsViewQuery(baseOptions?: Apollo.QueryHookOptions<ProjectDetailsViewQuery, ProjectDetailsViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectDetailsViewQuery, ProjectDetailsViewQueryVariables>(ProjectDetailsViewDocument, options);
      }
export function useProjectDetailsViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectDetailsViewQuery, ProjectDetailsViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectDetailsViewQuery, ProjectDetailsViewQueryVariables>(ProjectDetailsViewDocument, options);
        }
export type ProjectDetailsViewQueryHookResult = ReturnType<typeof useProjectDetailsViewQuery>;
export type ProjectDetailsViewLazyQueryHookResult = ReturnType<typeof useProjectDetailsViewLazyQuery>;
export type ProjectDetailsViewQueryResult = Apollo.QueryResult<ProjectDetailsViewQuery, ProjectDetailsViewQueryVariables>;
export const ProjectDetailsViewMutationDocument = gql`
    mutation ProjectDetailsViewMutation {
  setJoyrideShortlistedView {
    status
    currentProfile {
      id
      ...CurrentProfileFragment
    }
  }
}
    ${CurrentProfileFragmentFragmentDoc}`;
export type ProjectDetailsViewMutationMutationFn = Apollo.MutationFunction<ProjectDetailsViewMutationMutation, ProjectDetailsViewMutationMutationVariables>;

/**
 * __useProjectDetailsViewMutationMutation__
 *
 * To run a mutation, you first call `useProjectDetailsViewMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectDetailsViewMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectDetailsViewMutationMutation, { data, loading, error }] = useProjectDetailsViewMutationMutation({
 *   variables: {
 *   },
 * });
 */
export function useProjectDetailsViewMutationMutation(baseOptions?: Apollo.MutationHookOptions<ProjectDetailsViewMutationMutation, ProjectDetailsViewMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectDetailsViewMutationMutation, ProjectDetailsViewMutationMutationVariables>(ProjectDetailsViewMutationDocument, options);
      }
export type ProjectDetailsViewMutationMutationHookResult = ReturnType<typeof useProjectDetailsViewMutationMutation>;
export type ProjectDetailsViewMutationMutationResult = Apollo.MutationResult<ProjectDetailsViewMutationMutation>;
export type ProjectDetailsViewMutationMutationOptions = Apollo.BaseMutationOptions<ProjectDetailsViewMutationMutation, ProjectDetailsViewMutationMutationVariables>;
export const ProjectInviteViewDocument = gql`
    query ProjectInviteView($hashId: String) {
  project(hashId: $hashId) {
    id
    user {
      id
      email
      userprofile {
        id
        firstName
        lastName
      }
    }
    ...ProjectInviteFragment
  }
}
    ${ProjectInviteFragmentFragmentDoc}`;

/**
 * __useProjectInviteViewQuery__
 *
 * To run a query within a React component, call `useProjectInviteViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectInviteViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectInviteViewQuery({
 *   variables: {
 *      hashId: // value for 'hashId'
 *   },
 * });
 */
export function useProjectInviteViewQuery(baseOptions?: Apollo.QueryHookOptions<ProjectInviteViewQuery, ProjectInviteViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectInviteViewQuery, ProjectInviteViewQueryVariables>(ProjectInviteViewDocument, options);
      }
export function useProjectInviteViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectInviteViewQuery, ProjectInviteViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectInviteViewQuery, ProjectInviteViewQueryVariables>(ProjectInviteViewDocument, options);
        }
export type ProjectInviteViewQueryHookResult = ReturnType<typeof useProjectInviteViewQuery>;
export type ProjectInviteViewLazyQueryHookResult = ReturnType<typeof useProjectInviteViewLazyQuery>;
export type ProjectInviteViewQueryResult = Apollo.QueryResult<ProjectInviteViewQuery, ProjectInviteViewQueryVariables>;
export const ProjectItemsViewDocument = gql`
    query ProjectItemsView($hashId: String) {
  project(hashId: $hashId) {
    id
    title
    hashId
    statusComputed
    client {
      id
      name
      user
    }
    locations {
      id
      externalId
      title
      titleStr
      locationImages {
        id
        image
      }
      locationItems {
        id
        ...LocationItemDetailFragment
      }
    }
  }
}
    ${LocationItemDetailFragmentFragmentDoc}`;

/**
 * __useProjectItemsViewQuery__
 *
 * To run a query within a React component, call `useProjectItemsViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectItemsViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectItemsViewQuery({
 *   variables: {
 *      hashId: // value for 'hashId'
 *   },
 * });
 */
export function useProjectItemsViewQuery(baseOptions?: Apollo.QueryHookOptions<ProjectItemsViewQuery, ProjectItemsViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectItemsViewQuery, ProjectItemsViewQueryVariables>(ProjectItemsViewDocument, options);
      }
export function useProjectItemsViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectItemsViewQuery, ProjectItemsViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectItemsViewQuery, ProjectItemsViewQueryVariables>(ProjectItemsViewDocument, options);
        }
export type ProjectItemsViewQueryHookResult = ReturnType<typeof useProjectItemsViewQuery>;
export type ProjectItemsViewLazyQueryHookResult = ReturnType<typeof useProjectItemsViewLazyQuery>;
export type ProjectItemsViewQueryResult = Apollo.QueryResult<ProjectItemsViewQuery, ProjectItemsViewQueryVariables>;
export const ProjectReviewQuotationViewDocument = gql`
    query ProjectReviewQuotationView($hashId: String) {
  project(hashId: $hashId) {
    id
    latestQuotation {
      id
      isSubmitted
      isCompleted
      isAccepted
      quotationPdf
    }
  }
}
    `;

/**
 * __useProjectReviewQuotationViewQuery__
 *
 * To run a query within a React component, call `useProjectReviewQuotationViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectReviewQuotationViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectReviewQuotationViewQuery({
 *   variables: {
 *      hashId: // value for 'hashId'
 *   },
 * });
 */
export function useProjectReviewQuotationViewQuery(baseOptions?: Apollo.QueryHookOptions<ProjectReviewQuotationViewQuery, ProjectReviewQuotationViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectReviewQuotationViewQuery, ProjectReviewQuotationViewQueryVariables>(ProjectReviewQuotationViewDocument, options);
      }
export function useProjectReviewQuotationViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectReviewQuotationViewQuery, ProjectReviewQuotationViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectReviewQuotationViewQuery, ProjectReviewQuotationViewQueryVariables>(ProjectReviewQuotationViewDocument, options);
        }
export type ProjectReviewQuotationViewQueryHookResult = ReturnType<typeof useProjectReviewQuotationViewQuery>;
export type ProjectReviewQuotationViewLazyQueryHookResult = ReturnType<typeof useProjectReviewQuotationViewLazyQuery>;
export type ProjectReviewQuotationViewQueryResult = Apollo.QueryResult<ProjectReviewQuotationViewQuery, ProjectReviewQuotationViewQueryVariables>;
export const ProjectsListViewProjectsDocument = gql`
    query ProjectsListViewProjects {
  projects {
    projects {
      ...ProjectsFields
    }
  }
}
    ${ProjectsFieldsFragmentDoc}`;

/**
 * __useProjectsListViewProjectsQuery__
 *
 * To run a query within a React component, call `useProjectsListViewProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsListViewProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsListViewProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectsListViewProjectsQuery(baseOptions?: Apollo.QueryHookOptions<ProjectsListViewProjectsQuery, ProjectsListViewProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsListViewProjectsQuery, ProjectsListViewProjectsQueryVariables>(ProjectsListViewProjectsDocument, options);
      }
export function useProjectsListViewProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsListViewProjectsQuery, ProjectsListViewProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsListViewProjectsQuery, ProjectsListViewProjectsQueryVariables>(ProjectsListViewProjectsDocument, options);
        }
export type ProjectsListViewProjectsQueryHookResult = ReturnType<typeof useProjectsListViewProjectsQuery>;
export type ProjectsListViewProjectsLazyQueryHookResult = ReturnType<typeof useProjectsListViewProjectsLazyQuery>;
export type ProjectsListViewProjectsQueryResult = Apollo.QueryResult<ProjectsListViewProjectsQuery, ProjectsListViewProjectsQueryVariables>;
export const ProjectsListViewProductsDocument = gql`
    query ProjectsListViewProducts($filters: String) {
  getProducts(filters: $filters) {
    page
    pages
    hasNext
    hasPrev
    total
    showImage
    relatedObject {
      id
      ...ProductGridItem
    }
    objects {
      id
      ...ProductGridItem
    }
  }
}
    ${ProductGridItemFragmentDoc}`;

/**
 * __useProjectsListViewProductsQuery__
 *
 * To run a query within a React component, call `useProjectsListViewProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsListViewProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsListViewProductsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useProjectsListViewProductsQuery(baseOptions?: Apollo.QueryHookOptions<ProjectsListViewProductsQuery, ProjectsListViewProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsListViewProductsQuery, ProjectsListViewProductsQueryVariables>(ProjectsListViewProductsDocument, options);
      }
export function useProjectsListViewProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsListViewProductsQuery, ProjectsListViewProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsListViewProductsQuery, ProjectsListViewProductsQueryVariables>(ProjectsListViewProductsDocument, options);
        }
export type ProjectsListViewProductsQueryHookResult = ReturnType<typeof useProjectsListViewProductsQuery>;
export type ProjectsListViewProductsLazyQueryHookResult = ReturnType<typeof useProjectsListViewProductsLazyQuery>;
export type ProjectsListViewProductsQueryResult = Apollo.QueryResult<ProjectsListViewProductsQuery, ProjectsListViewProductsQueryVariables>;
export const RequestQuotationViewDocument = gql`
    query RequestQuotationView($hashId: String) {
  quotation(hashId: $hashId) {
    id
    ...RequestQuotationViewQuotationFragment
  }
  project(hashId: $hashId) {
    id
    ...ProjectSummaryFragment
  }
  selectedBucketItems(hashId: $hashId) {
    id
    ...ProductAndItemFragment
  }
}
    ${RequestQuotationViewQuotationFragmentFragmentDoc}
${ProjectSummaryFragmentFragmentDoc}
${ProductAndItemFragmentFragmentDoc}`;

/**
 * __useRequestQuotationViewQuery__
 *
 * To run a query within a React component, call `useRequestQuotationViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestQuotationViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestQuotationViewQuery({
 *   variables: {
 *      hashId: // value for 'hashId'
 *   },
 * });
 */
export function useRequestQuotationViewQuery(baseOptions?: Apollo.QueryHookOptions<RequestQuotationViewQuery, RequestQuotationViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RequestQuotationViewQuery, RequestQuotationViewQueryVariables>(RequestQuotationViewDocument, options);
      }
export function useRequestQuotationViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RequestQuotationViewQuery, RequestQuotationViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RequestQuotationViewQuery, RequestQuotationViewQueryVariables>(RequestQuotationViewDocument, options);
        }
export type RequestQuotationViewQueryHookResult = ReturnType<typeof useRequestQuotationViewQuery>;
export type RequestQuotationViewLazyQueryHookResult = ReturnType<typeof useRequestQuotationViewLazyQuery>;
export type RequestQuotationViewQueryResult = Apollo.QueryResult<RequestQuotationViewQuery, RequestQuotationViewQueryVariables>;
export const SearchViewLocationItemsDocument = gql`
    query SearchViewLocationItems($hashId: String, $locationId: String, $locationItemId: String) {
  locationItem(locItemId: $locationItemId, locId: $locationId, hashId: $hashId) {
    ...SearchViewLocationItemFragment
  }
  getProductsForProject(hashId: $hashId) {
    id
    bucketItem {
      id
      esId
    }
  }
}
    ${SearchViewLocationItemFragmentFragmentDoc}`;

/**
 * __useSearchViewLocationItemsQuery__
 *
 * To run a query within a React component, call `useSearchViewLocationItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchViewLocationItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchViewLocationItemsQuery({
 *   variables: {
 *      hashId: // value for 'hashId'
 *      locationId: // value for 'locationId'
 *      locationItemId: // value for 'locationItemId'
 *   },
 * });
 */
export function useSearchViewLocationItemsQuery(baseOptions?: Apollo.QueryHookOptions<SearchViewLocationItemsQuery, SearchViewLocationItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchViewLocationItemsQuery, SearchViewLocationItemsQueryVariables>(SearchViewLocationItemsDocument, options);
      }
export function useSearchViewLocationItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchViewLocationItemsQuery, SearchViewLocationItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchViewLocationItemsQuery, SearchViewLocationItemsQueryVariables>(SearchViewLocationItemsDocument, options);
        }
export type SearchViewLocationItemsQueryHookResult = ReturnType<typeof useSearchViewLocationItemsQuery>;
export type SearchViewLocationItemsLazyQueryHookResult = ReturnType<typeof useSearchViewLocationItemsLazyQuery>;
export type SearchViewLocationItemsQueryResult = Apollo.QueryResult<SearchViewLocationItemsQuery, SearchViewLocationItemsQueryVariables>;
export const SearchViewProductsDocument = gql`
    query SearchViewProducts($filters: String, $locDetails: String) {
  getProducts(filters: $filters, locDetails: $locDetails) {
    page
    pages
    hasNext
    hasPrev
    total
    showImage
    relatedObject {
      id
      ...ProductGridItem
    }
    objects {
      id
      ...ProductGridItem
    }
  }
}
    ${ProductGridItemFragmentDoc}`;

/**
 * __useSearchViewProductsQuery__
 *
 * To run a query within a React component, call `useSearchViewProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchViewProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchViewProductsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      locDetails: // value for 'locDetails'
 *   },
 * });
 */
export function useSearchViewProductsQuery(baseOptions?: Apollo.QueryHookOptions<SearchViewProductsQuery, SearchViewProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchViewProductsQuery, SearchViewProductsQueryVariables>(SearchViewProductsDocument, options);
      }
export function useSearchViewProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchViewProductsQuery, SearchViewProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchViewProductsQuery, SearchViewProductsQueryVariables>(SearchViewProductsDocument, options);
        }
export type SearchViewProductsQueryHookResult = ReturnType<typeof useSearchViewProductsQuery>;
export type SearchViewProductsLazyQueryHookResult = ReturnType<typeof useSearchViewProductsLazyQuery>;
export type SearchViewProductsQueryResult = Apollo.QueryResult<SearchViewProductsQuery, SearchViewProductsQueryVariables>;
export const SellerWfCommissionedWorksConfirmAvailabilityViewDocument = gql`
    query SellerWFCommissionedWorksConfirmAvailabilityView($uuid: String) {
  wfCommissionedWorksSellerConfirmAvailabilityData(uuid: $uuid) {
    ...wfCommissionedWorksSellerConfirmAvailabilityDataFields
  }
}
    ${WfCommissionedWorksSellerConfirmAvailabilityDataFieldsFragmentDoc}`;

/**
 * __useSellerWfCommissionedWorksConfirmAvailabilityViewQuery__
 *
 * To run a query within a React component, call `useSellerWfCommissionedWorksConfirmAvailabilityViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellerWfCommissionedWorksConfirmAvailabilityViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellerWfCommissionedWorksConfirmAvailabilityViewQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useSellerWfCommissionedWorksConfirmAvailabilityViewQuery(baseOptions?: Apollo.QueryHookOptions<SellerWfCommissionedWorksConfirmAvailabilityViewQuery, SellerWfCommissionedWorksConfirmAvailabilityViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SellerWfCommissionedWorksConfirmAvailabilityViewQuery, SellerWfCommissionedWorksConfirmAvailabilityViewQueryVariables>(SellerWfCommissionedWorksConfirmAvailabilityViewDocument, options);
      }
export function useSellerWfCommissionedWorksConfirmAvailabilityViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SellerWfCommissionedWorksConfirmAvailabilityViewQuery, SellerWfCommissionedWorksConfirmAvailabilityViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SellerWfCommissionedWorksConfirmAvailabilityViewQuery, SellerWfCommissionedWorksConfirmAvailabilityViewQueryVariables>(SellerWfCommissionedWorksConfirmAvailabilityViewDocument, options);
        }
export type SellerWfCommissionedWorksConfirmAvailabilityViewQueryHookResult = ReturnType<typeof useSellerWfCommissionedWorksConfirmAvailabilityViewQuery>;
export type SellerWfCommissionedWorksConfirmAvailabilityViewLazyQueryHookResult = ReturnType<typeof useSellerWfCommissionedWorksConfirmAvailabilityViewLazyQuery>;
export type SellerWfCommissionedWorksConfirmAvailabilityViewQueryResult = Apollo.QueryResult<SellerWfCommissionedWorksConfirmAvailabilityViewQuery, SellerWfCommissionedWorksConfirmAvailabilityViewQueryVariables>;
export const SellerWfNormalWorksConfirmAvailabilityViewDocument = gql`
    query SellerWFNormalWorksConfirmAvailabilityView($uuid: String) {
  wfSellerConfirmAvailabilityData(uuid: $uuid) {
    id
    image
    artist
    title
    sizeString
    remainingQuantity
    requestedQuantity
    dateFinished
    destinationCity
    destinationCountry
    destinationCountryName
    destinationZip
    isAvailable
    isNotReady
    leadTime
    shippingCost
    shipsFromCountry
    shipsFromCity
    shipsFromZip
    packedWidth
    packedHeight
    packedDepth
    packedWeight
    price
    shippedFramed
    shippedRolled
    remarks
  }
}
    `;

/**
 * __useSellerWfNormalWorksConfirmAvailabilityViewQuery__
 *
 * To run a query within a React component, call `useSellerWfNormalWorksConfirmAvailabilityViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useSellerWfNormalWorksConfirmAvailabilityViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSellerWfNormalWorksConfirmAvailabilityViewQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useSellerWfNormalWorksConfirmAvailabilityViewQuery(baseOptions?: Apollo.QueryHookOptions<SellerWfNormalWorksConfirmAvailabilityViewQuery, SellerWfNormalWorksConfirmAvailabilityViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SellerWfNormalWorksConfirmAvailabilityViewQuery, SellerWfNormalWorksConfirmAvailabilityViewQueryVariables>(SellerWfNormalWorksConfirmAvailabilityViewDocument, options);
      }
export function useSellerWfNormalWorksConfirmAvailabilityViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SellerWfNormalWorksConfirmAvailabilityViewQuery, SellerWfNormalWorksConfirmAvailabilityViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SellerWfNormalWorksConfirmAvailabilityViewQuery, SellerWfNormalWorksConfirmAvailabilityViewQueryVariables>(SellerWfNormalWorksConfirmAvailabilityViewDocument, options);
        }
export type SellerWfNormalWorksConfirmAvailabilityViewQueryHookResult = ReturnType<typeof useSellerWfNormalWorksConfirmAvailabilityViewQuery>;
export type SellerWfNormalWorksConfirmAvailabilityViewLazyQueryHookResult = ReturnType<typeof useSellerWfNormalWorksConfirmAvailabilityViewLazyQuery>;
export type SellerWfNormalWorksConfirmAvailabilityViewQueryResult = Apollo.QueryResult<SellerWfNormalWorksConfirmAvailabilityViewQuery, SellerWfNormalWorksConfirmAvailabilityViewQueryVariables>;
export const SourcingArtistVariantDetailViewDocument = gql`
    query SourcingArtistVariantDetailView($variantId: String) {
  artistVariant(varId: $variantId) {
    id
    artist {
      id
      name
    }
    galleries {
      id
      name
    }
    medium {
      id
      title
    }
    artworkStyle {
      id
      title
    }
    productImages {
      id
      esId
      imageKey
      imageKeyFilename
    }
    tags
    priceLow
    priceHigh
    priceRange
    projects {
      id
      ...ArtistVariantProjectFragment
    }
    projectKeys {
      id
    }
    dataRequestDate
    notes
  }
}
    ${ArtistVariantProjectFragmentFragmentDoc}`;

/**
 * __useSourcingArtistVariantDetailViewQuery__
 *
 * To run a query within a React component, call `useSourcingArtistVariantDetailViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useSourcingArtistVariantDetailViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSourcingArtistVariantDetailViewQuery({
 *   variables: {
 *      variantId: // value for 'variantId'
 *   },
 * });
 */
export function useSourcingArtistVariantDetailViewQuery(baseOptions?: Apollo.QueryHookOptions<SourcingArtistVariantDetailViewQuery, SourcingArtistVariantDetailViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SourcingArtistVariantDetailViewQuery, SourcingArtistVariantDetailViewQueryVariables>(SourcingArtistVariantDetailViewDocument, options);
      }
export function useSourcingArtistVariantDetailViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SourcingArtistVariantDetailViewQuery, SourcingArtistVariantDetailViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SourcingArtistVariantDetailViewQuery, SourcingArtistVariantDetailViewQueryVariables>(SourcingArtistVariantDetailViewDocument, options);
        }
export type SourcingArtistVariantDetailViewQueryHookResult = ReturnType<typeof useSourcingArtistVariantDetailViewQuery>;
export type SourcingArtistVariantDetailViewLazyQueryHookResult = ReturnType<typeof useSourcingArtistVariantDetailViewLazyQuery>;
export type SourcingArtistVariantDetailViewQueryResult = Apollo.QueryResult<SourcingArtistVariantDetailViewQuery, SourcingArtistVariantDetailViewQueryVariables>;
export const SourcingArtistVariantsViewDocument = gql`
    query SourcingArtistVariantsView($filters: String) {
  artistVariants(filters: $filters) {
    page
    pages
    hasNext
    hasPrev
    total
    objects {
      ...ArtistVariantsObjectsFields
    }
  }
}
    ${ArtistVariantsObjectsFieldsFragmentDoc}`;

/**
 * __useSourcingArtistVariantsViewQuery__
 *
 * To run a query within a React component, call `useSourcingArtistVariantsViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useSourcingArtistVariantsViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSourcingArtistVariantsViewQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSourcingArtistVariantsViewQuery(baseOptions?: Apollo.QueryHookOptions<SourcingArtistVariantsViewQuery, SourcingArtistVariantsViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SourcingArtistVariantsViewQuery, SourcingArtistVariantsViewQueryVariables>(SourcingArtistVariantsViewDocument, options);
      }
export function useSourcingArtistVariantsViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SourcingArtistVariantsViewQuery, SourcingArtistVariantsViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SourcingArtistVariantsViewQuery, SourcingArtistVariantsViewQueryVariables>(SourcingArtistVariantsViewDocument, options);
        }
export type SourcingArtistVariantsViewQueryHookResult = ReturnType<typeof useSourcingArtistVariantsViewQuery>;
export type SourcingArtistVariantsViewLazyQueryHookResult = ReturnType<typeof useSourcingArtistVariantsViewLazyQuery>;
export type SourcingArtistVariantsViewQueryResult = Apollo.QueryResult<SourcingArtistVariantsViewQuery, SourcingArtistVariantsViewQueryVariables>;
export const SourcingArtistsViewDocument = gql`
    query SourcingArtistsView($filters: String) {
  artists(filters: $filters) {
    page
    pages
    hasNext
    hasPrev
    total
    objects {
      ...ArtistsObjectsFields
    }
  }
}
    ${ArtistsObjectsFieldsFragmentDoc}`;

/**
 * __useSourcingArtistsViewQuery__
 *
 * To run a query within a React component, call `useSourcingArtistsViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useSourcingArtistsViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSourcingArtistsViewQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSourcingArtistsViewQuery(baseOptions?: Apollo.QueryHookOptions<SourcingArtistsViewQuery, SourcingArtistsViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SourcingArtistsViewQuery, SourcingArtistsViewQueryVariables>(SourcingArtistsViewDocument, options);
      }
export function useSourcingArtistsViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SourcingArtistsViewQuery, SourcingArtistsViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SourcingArtistsViewQuery, SourcingArtistsViewQueryVariables>(SourcingArtistsViewDocument, options);
        }
export type SourcingArtistsViewQueryHookResult = ReturnType<typeof useSourcingArtistsViewQuery>;
export type SourcingArtistsViewLazyQueryHookResult = ReturnType<typeof useSourcingArtistsViewLazyQuery>;
export type SourcingArtistsViewQueryResult = Apollo.QueryResult<SourcingArtistsViewQuery, SourcingArtistsViewQueryVariables>;
export const SourcingGalleriesViewDocument = gql`
    query SourcingGalleriesView($filters: String) {
  galleries(filters: $filters) {
    page
    pages
    hasNext
    hasPrev
    total
    objects {
      ...GalleriesObjectsFields
    }
  }
}
    ${GalleriesObjectsFieldsFragmentDoc}`;

/**
 * __useSourcingGalleriesViewQuery__
 *
 * To run a query within a React component, call `useSourcingGalleriesViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useSourcingGalleriesViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSourcingGalleriesViewQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSourcingGalleriesViewQuery(baseOptions?: Apollo.QueryHookOptions<SourcingGalleriesViewQuery, SourcingGalleriesViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SourcingGalleriesViewQuery, SourcingGalleriesViewQueryVariables>(SourcingGalleriesViewDocument, options);
      }
export function useSourcingGalleriesViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SourcingGalleriesViewQuery, SourcingGalleriesViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SourcingGalleriesViewQuery, SourcingGalleriesViewQueryVariables>(SourcingGalleriesViewDocument, options);
        }
export type SourcingGalleriesViewQueryHookResult = ReturnType<typeof useSourcingGalleriesViewQuery>;
export type SourcingGalleriesViewLazyQueryHookResult = ReturnType<typeof useSourcingGalleriesViewLazyQuery>;
export type SourcingGalleriesViewQueryResult = Apollo.QueryResult<SourcingGalleriesViewQuery, SourcingGalleriesViewQueryVariables>;
export const StaffFramingToolViewDocument = gql`
    query StaffFramingToolView($bucketItemId: String) {
  getBucketItem(bucketItemId: $bucketItemId) {
    id
    ...BucketItemDetailsFragment
    image
    savedFrame {
      id
      artworkWidth
      artworkHeight
      artworkDepth
      matWidth
      matColor
      matColorCode
      frameWidth
      frameMaterial {
        id
        name
      }
      artworkType
      framingType
      glazingType
    }
  }
}
    ${BucketItemDetailsFragmentFragmentDoc}`;

/**
 * __useStaffFramingToolViewQuery__
 *
 * To run a query within a React component, call `useStaffFramingToolViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffFramingToolViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffFramingToolViewQuery({
 *   variables: {
 *      bucketItemId: // value for 'bucketItemId'
 *   },
 * });
 */
export function useStaffFramingToolViewQuery(baseOptions?: Apollo.QueryHookOptions<StaffFramingToolViewQuery, StaffFramingToolViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffFramingToolViewQuery, StaffFramingToolViewQueryVariables>(StaffFramingToolViewDocument, options);
      }
export function useStaffFramingToolViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffFramingToolViewQuery, StaffFramingToolViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffFramingToolViewQuery, StaffFramingToolViewQueryVariables>(StaffFramingToolViewDocument, options);
        }
export type StaffFramingToolViewQueryHookResult = ReturnType<typeof useStaffFramingToolViewQuery>;
export type StaffFramingToolViewLazyQueryHookResult = ReturnType<typeof useStaffFramingToolViewLazyQuery>;
export type StaffFramingToolViewQueryResult = Apollo.QueryResult<StaffFramingToolViewQuery, StaffFramingToolViewQueryVariables>;
export const StaffImageRenderViewDocument = gql`
    query StaffImageRenderView($hashId: String, $locId: String, $locItemId: String) {
  locationItem(
    locItemId: $locItemId
    locId: $locId
    hashId: $hashId
    originalId: true
  ) {
    id
    allImages
    ...LocationItemFragment
  }
}
    ${LocationItemFragmentFragmentDoc}`;

/**
 * __useStaffImageRenderViewQuery__
 *
 * To run a query within a React component, call `useStaffImageRenderViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffImageRenderViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffImageRenderViewQuery({
 *   variables: {
 *      hashId: // value for 'hashId'
 *      locId: // value for 'locId'
 *      locItemId: // value for 'locItemId'
 *   },
 * });
 */
export function useStaffImageRenderViewQuery(baseOptions?: Apollo.QueryHookOptions<StaffImageRenderViewQuery, StaffImageRenderViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffImageRenderViewQuery, StaffImageRenderViewQueryVariables>(StaffImageRenderViewDocument, options);
      }
export function useStaffImageRenderViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffImageRenderViewQuery, StaffImageRenderViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffImageRenderViewQuery, StaffImageRenderViewQueryVariables>(StaffImageRenderViewDocument, options);
        }
export type StaffImageRenderViewQueryHookResult = ReturnType<typeof useStaffImageRenderViewQuery>;
export type StaffImageRenderViewLazyQueryHookResult = ReturnType<typeof useStaffImageRenderViewLazyQuery>;
export type StaffImageRenderViewQueryResult = Apollo.QueryResult<StaffImageRenderViewQuery, StaffImageRenderViewQueryVariables>;
export const StaffInquiriesViewDocument = gql`
    query StaffInquiriesView {
  getInquiries {
    ...GetInquiriesFields
  }
}
    ${GetInquiriesFieldsFragmentDoc}`;

/**
 * __useStaffInquiriesViewQuery__
 *
 * To run a query within a React component, call `useStaffInquiriesViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffInquiriesViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffInquiriesViewQuery({
 *   variables: {
 *   },
 * });
 */
export function useStaffInquiriesViewQuery(baseOptions?: Apollo.QueryHookOptions<StaffInquiriesViewQuery, StaffInquiriesViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffInquiriesViewQuery, StaffInquiriesViewQueryVariables>(StaffInquiriesViewDocument, options);
      }
export function useStaffInquiriesViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffInquiriesViewQuery, StaffInquiriesViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffInquiriesViewQuery, StaffInquiriesViewQueryVariables>(StaffInquiriesViewDocument, options);
        }
export type StaffInquiriesViewQueryHookResult = ReturnType<typeof useStaffInquiriesViewQuery>;
export type StaffInquiriesViewLazyQueryHookResult = ReturnType<typeof useStaffInquiriesViewLazyQuery>;
export type StaffInquiriesViewQueryResult = Apollo.QueryResult<StaffInquiriesViewQuery, StaffInquiriesViewQueryVariables>;
export const StaffQuotationsViewDocument = gql`
    query StaffQuotationsView {
  staffQuotations {
    id
    ...StaffQuotationsViewFragment
  }
}
    ${StaffQuotationsViewFragmentFragmentDoc}`;

/**
 * __useStaffQuotationsViewQuery__
 *
 * To run a query within a React component, call `useStaffQuotationsViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffQuotationsViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffQuotationsViewQuery({
 *   variables: {
 *   },
 * });
 */
export function useStaffQuotationsViewQuery(baseOptions?: Apollo.QueryHookOptions<StaffQuotationsViewQuery, StaffQuotationsViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffQuotationsViewQuery, StaffQuotationsViewQueryVariables>(StaffQuotationsViewDocument, options);
      }
export function useStaffQuotationsViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffQuotationsViewQuery, StaffQuotationsViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffQuotationsViewQuery, StaffQuotationsViewQueryVariables>(StaffQuotationsViewDocument, options);
        }
export type StaffQuotationsViewQueryHookResult = ReturnType<typeof useStaffQuotationsViewQuery>;
export type StaffQuotationsViewLazyQueryHookResult = ReturnType<typeof useStaffQuotationsViewLazyQuery>;
export type StaffQuotationsViewQueryResult = Apollo.QueryResult<StaffQuotationsViewQuery, StaffQuotationsViewQueryVariables>;
export const StaffWfCommissionedWorksFlowItemViewDocument = gql`
    query StaffWFCommissionedWorksFlowItemView($flowItemId: String) {
  wfStaffCommissionedWorksFlowItem(flowItemId: $flowItemId) {
    id
    title
    artist {
      id
      name
    }
    artworkDescription
    estimatedTimeline
    budget
    isAvailable
    cost
    leadTime
    hasSketchFee
    sketchFee
    portfolio1
    portfolio2
    portfolio3
    remarks
    history {
      ...HistoryFields
    }
  }
}
    ${HistoryFieldsFragmentDoc}`;

/**
 * __useStaffWfCommissionedWorksFlowItemViewQuery__
 *
 * To run a query within a React component, call `useStaffWfCommissionedWorksFlowItemViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffWfCommissionedWorksFlowItemViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffWfCommissionedWorksFlowItemViewQuery({
 *   variables: {
 *      flowItemId: // value for 'flowItemId'
 *   },
 * });
 */
export function useStaffWfCommissionedWorksFlowItemViewQuery(baseOptions?: Apollo.QueryHookOptions<StaffWfCommissionedWorksFlowItemViewQuery, StaffWfCommissionedWorksFlowItemViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffWfCommissionedWorksFlowItemViewQuery, StaffWfCommissionedWorksFlowItemViewQueryVariables>(StaffWfCommissionedWorksFlowItemViewDocument, options);
      }
export function useStaffWfCommissionedWorksFlowItemViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffWfCommissionedWorksFlowItemViewQuery, StaffWfCommissionedWorksFlowItemViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffWfCommissionedWorksFlowItemViewQuery, StaffWfCommissionedWorksFlowItemViewQueryVariables>(StaffWfCommissionedWorksFlowItemViewDocument, options);
        }
export type StaffWfCommissionedWorksFlowItemViewQueryHookResult = ReturnType<typeof useStaffWfCommissionedWorksFlowItemViewQuery>;
export type StaffWfCommissionedWorksFlowItemViewLazyQueryHookResult = ReturnType<typeof useStaffWfCommissionedWorksFlowItemViewLazyQuery>;
export type StaffWfCommissionedWorksFlowItemViewQueryResult = Apollo.QueryResult<StaffWfCommissionedWorksFlowItemViewQuery, StaffWfCommissionedWorksFlowItemViewQueryVariables>;
export const StaffWfCommissionedWorksNotifySellersViewDocument = gql`
    query StaffWFCommissionedWorksNotifySellersView($hashId: String) {
  wfCmSelectedWorks(hashId: $hashId) {
    ...StaffWFCommissionedWorksNotifySellersViewCommissionedWorksLocationFragment
  }
}
    ${StaffWfCommissionedWorksNotifySellersViewCommissionedWorksLocationFragmentFragmentDoc}`;

/**
 * __useStaffWfCommissionedWorksNotifySellersViewQuery__
 *
 * To run a query within a React component, call `useStaffWfCommissionedWorksNotifySellersViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffWfCommissionedWorksNotifySellersViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffWfCommissionedWorksNotifySellersViewQuery({
 *   variables: {
 *      hashId: // value for 'hashId'
 *   },
 * });
 */
export function useStaffWfCommissionedWorksNotifySellersViewQuery(baseOptions?: Apollo.QueryHookOptions<StaffWfCommissionedWorksNotifySellersViewQuery, StaffWfCommissionedWorksNotifySellersViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffWfCommissionedWorksNotifySellersViewQuery, StaffWfCommissionedWorksNotifySellersViewQueryVariables>(StaffWfCommissionedWorksNotifySellersViewDocument, options);
      }
export function useStaffWfCommissionedWorksNotifySellersViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffWfCommissionedWorksNotifySellersViewQuery, StaffWfCommissionedWorksNotifySellersViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffWfCommissionedWorksNotifySellersViewQuery, StaffWfCommissionedWorksNotifySellersViewQueryVariables>(StaffWfCommissionedWorksNotifySellersViewDocument, options);
        }
export type StaffWfCommissionedWorksNotifySellersViewQueryHookResult = ReturnType<typeof useStaffWfCommissionedWorksNotifySellersViewQuery>;
export type StaffWfCommissionedWorksNotifySellersViewLazyQueryHookResult = ReturnType<typeof useStaffWfCommissionedWorksNotifySellersViewLazyQuery>;
export type StaffWfCommissionedWorksNotifySellersViewQueryResult = Apollo.QueryResult<StaffWfCommissionedWorksNotifySellersViewQuery, StaffWfCommissionedWorksNotifySellersViewQueryVariables>;
export const StaffWfCommissionedWorksViewDocument = gql`
    query StaffWFCommissionedWorksView {
  wfCommissionedWorksStepsData {
    itemGroup
    projectTitle
    projectId
    steps {
      step
      items {
        id
        esId
        portfolio1
        artistConfirmAvailabilityUrl
        artist {
          id
          name
        }
        currentStates {
          id
          uniqueUrl
        }
        locationItem {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useStaffWfCommissionedWorksViewQuery__
 *
 * To run a query within a React component, call `useStaffWfCommissionedWorksViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffWfCommissionedWorksViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffWfCommissionedWorksViewQuery({
 *   variables: {
 *   },
 * });
 */
export function useStaffWfCommissionedWorksViewQuery(baseOptions?: Apollo.QueryHookOptions<StaffWfCommissionedWorksViewQuery, StaffWfCommissionedWorksViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffWfCommissionedWorksViewQuery, StaffWfCommissionedWorksViewQueryVariables>(StaffWfCommissionedWorksViewDocument, options);
      }
export function useStaffWfCommissionedWorksViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffWfCommissionedWorksViewQuery, StaffWfCommissionedWorksViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffWfCommissionedWorksViewQuery, StaffWfCommissionedWorksViewQueryVariables>(StaffWfCommissionedWorksViewDocument, options);
        }
export type StaffWfCommissionedWorksViewQueryHookResult = ReturnType<typeof useStaffWfCommissionedWorksViewQuery>;
export type StaffWfCommissionedWorksViewLazyQueryHookResult = ReturnType<typeof useStaffWfCommissionedWorksViewLazyQuery>;
export type StaffWfCommissionedWorksViewQueryResult = Apollo.QueryResult<StaffWfCommissionedWorksViewQuery, StaffWfCommissionedWorksViewQueryVariables>;
export const StaffWfNomalWorksFlowItemViewDocument = gql`
    query StaffWFNomalWorksFlowItemView($flowItemId: String) {
  wfStaffFlowItem(flowItemId: $flowItemId) {
    id
    ...FlowItemArtworkStaffFragment
  }
}
    ${FlowItemArtworkStaffFragmentFragmentDoc}`;

/**
 * __useStaffWfNomalWorksFlowItemViewQuery__
 *
 * To run a query within a React component, call `useStaffWfNomalWorksFlowItemViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffWfNomalWorksFlowItemViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffWfNomalWorksFlowItemViewQuery({
 *   variables: {
 *      flowItemId: // value for 'flowItemId'
 *   },
 * });
 */
export function useStaffWfNomalWorksFlowItemViewQuery(baseOptions?: Apollo.QueryHookOptions<StaffWfNomalWorksFlowItemViewQuery, StaffWfNomalWorksFlowItemViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffWfNomalWorksFlowItemViewQuery, StaffWfNomalWorksFlowItemViewQueryVariables>(StaffWfNomalWorksFlowItemViewDocument, options);
      }
export function useStaffWfNomalWorksFlowItemViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffWfNomalWorksFlowItemViewQuery, StaffWfNomalWorksFlowItemViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffWfNomalWorksFlowItemViewQuery, StaffWfNomalWorksFlowItemViewQueryVariables>(StaffWfNomalWorksFlowItemViewDocument, options);
        }
export type StaffWfNomalWorksFlowItemViewQueryHookResult = ReturnType<typeof useStaffWfNomalWorksFlowItemViewQuery>;
export type StaffWfNomalWorksFlowItemViewLazyQueryHookResult = ReturnType<typeof useStaffWfNomalWorksFlowItemViewLazyQuery>;
export type StaffWfNomalWorksFlowItemViewQueryResult = Apollo.QueryResult<StaffWfNomalWorksFlowItemViewQuery, StaffWfNomalWorksFlowItemViewQueryVariables>;
export const StaffWfNormalWorksFramerRfqViewDocument = gql`
    query StaffWFNormalWorksFramerRFQView($uuid: String) {
  wfStaffFramerQuotations(uuid: $uuid) {
    id
    uniqueUrl
    framer {
      id
      name
      hashId
    }
  }
}
    `;

/**
 * __useStaffWfNormalWorksFramerRfqViewQuery__
 *
 * To run a query within a React component, call `useStaffWfNormalWorksFramerRfqViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffWfNormalWorksFramerRfqViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffWfNormalWorksFramerRfqViewQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useStaffWfNormalWorksFramerRfqViewQuery(baseOptions?: Apollo.QueryHookOptions<StaffWfNormalWorksFramerRfqViewQuery, StaffWfNormalWorksFramerRfqViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffWfNormalWorksFramerRfqViewQuery, StaffWfNormalWorksFramerRfqViewQueryVariables>(StaffWfNormalWorksFramerRfqViewDocument, options);
      }
export function useStaffWfNormalWorksFramerRfqViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffWfNormalWorksFramerRfqViewQuery, StaffWfNormalWorksFramerRfqViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffWfNormalWorksFramerRfqViewQuery, StaffWfNormalWorksFramerRfqViewQueryVariables>(StaffWfNormalWorksFramerRfqViewDocument, options);
        }
export type StaffWfNormalWorksFramerRfqViewQueryHookResult = ReturnType<typeof useStaffWfNormalWorksFramerRfqViewQuery>;
export type StaffWfNormalWorksFramerRfqViewLazyQueryHookResult = ReturnType<typeof useStaffWfNormalWorksFramerRfqViewLazyQuery>;
export type StaffWfNormalWorksFramerRfqViewQueryResult = Apollo.QueryResult<StaffWfNormalWorksFramerRfqViewQuery, StaffWfNormalWorksFramerRfqViewQueryVariables>;
export const StaffWfNormalWorksNotifyFramersViewDocument = gql`
    query StaffWFNormalWorksNotifyFramersView($hashId: String) {
  project(hashId: $hashId) {
    id
    hashId
    title
  }
  wfSelectedWorks(hashId: $hashId, stepsFilter: "NOTIFY_FRAMER_VIEW_STEPS") {
    ...WorkflowSelectedWorksFragment
  }
}
    ${WorkflowSelectedWorksFragmentFragmentDoc}`;

/**
 * __useStaffWfNormalWorksNotifyFramersViewQuery__
 *
 * To run a query within a React component, call `useStaffWfNormalWorksNotifyFramersViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffWfNormalWorksNotifyFramersViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffWfNormalWorksNotifyFramersViewQuery({
 *   variables: {
 *      hashId: // value for 'hashId'
 *   },
 * });
 */
export function useStaffWfNormalWorksNotifyFramersViewQuery(baseOptions?: Apollo.QueryHookOptions<StaffWfNormalWorksNotifyFramersViewQuery, StaffWfNormalWorksNotifyFramersViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffWfNormalWorksNotifyFramersViewQuery, StaffWfNormalWorksNotifyFramersViewQueryVariables>(StaffWfNormalWorksNotifyFramersViewDocument, options);
      }
export function useStaffWfNormalWorksNotifyFramersViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffWfNormalWorksNotifyFramersViewQuery, StaffWfNormalWorksNotifyFramersViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffWfNormalWorksNotifyFramersViewQuery, StaffWfNormalWorksNotifyFramersViewQueryVariables>(StaffWfNormalWorksNotifyFramersViewDocument, options);
        }
export type StaffWfNormalWorksNotifyFramersViewQueryHookResult = ReturnType<typeof useStaffWfNormalWorksNotifyFramersViewQuery>;
export type StaffWfNormalWorksNotifyFramersViewLazyQueryHookResult = ReturnType<typeof useStaffWfNormalWorksNotifyFramersViewLazyQuery>;
export type StaffWfNormalWorksNotifyFramersViewQueryResult = Apollo.QueryResult<StaffWfNormalWorksNotifyFramersViewQuery, StaffWfNormalWorksNotifyFramersViewQueryVariables>;
export const StaffWfNormalWorksNotifySellersViewDocument = gql`
    query StaffWFNormalWorksNotifySellersView($hashId: String) {
  project(hashId: $hashId) {
    id
    hashId
    title
    city
    countryCode
    postalCode
  }
  wfSelectedWorks(hashId: $hashId) {
    ...WorkflowSelectedWorksFragment
  }
}
    ${WorkflowSelectedWorksFragmentFragmentDoc}`;

/**
 * __useStaffWfNormalWorksNotifySellersViewQuery__
 *
 * To run a query within a React component, call `useStaffWfNormalWorksNotifySellersViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffWfNormalWorksNotifySellersViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffWfNormalWorksNotifySellersViewQuery({
 *   variables: {
 *      hashId: // value for 'hashId'
 *   },
 * });
 */
export function useStaffWfNormalWorksNotifySellersViewQuery(baseOptions?: Apollo.QueryHookOptions<StaffWfNormalWorksNotifySellersViewQuery, StaffWfNormalWorksNotifySellersViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffWfNormalWorksNotifySellersViewQuery, StaffWfNormalWorksNotifySellersViewQueryVariables>(StaffWfNormalWorksNotifySellersViewDocument, options);
      }
export function useStaffWfNormalWorksNotifySellersViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffWfNormalWorksNotifySellersViewQuery, StaffWfNormalWorksNotifySellersViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffWfNormalWorksNotifySellersViewQuery, StaffWfNormalWorksNotifySellersViewQueryVariables>(StaffWfNormalWorksNotifySellersViewDocument, options);
        }
export type StaffWfNormalWorksNotifySellersViewQueryHookResult = ReturnType<typeof useStaffWfNormalWorksNotifySellersViewQuery>;
export type StaffWfNormalWorksNotifySellersViewLazyQueryHookResult = ReturnType<typeof useStaffWfNormalWorksNotifySellersViewLazyQuery>;
export type StaffWfNormalWorksNotifySellersViewQueryResult = Apollo.QueryResult<StaffWfNormalWorksNotifySellersViewQuery, StaffWfNormalWorksNotifySellersViewQueryVariables>;
export const StaffWfNormalWorksViewDocument = gql`
    query StaffWFNormalWorksView {
  wfNormalWorksStepsData {
    itemGroup
    projectTitle
    projectId
    steps {
      ...WfNormalWorksStepsDataStepsFields
    }
  }
}
    ${WfNormalWorksStepsDataStepsFieldsFragmentDoc}`;

/**
 * __useStaffWfNormalWorksViewQuery__
 *
 * To run a query within a React component, call `useStaffWfNormalWorksViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffWfNormalWorksViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffWfNormalWorksViewQuery({
 *   variables: {
 *   },
 * });
 */
export function useStaffWfNormalWorksViewQuery(baseOptions?: Apollo.QueryHookOptions<StaffWfNormalWorksViewQuery, StaffWfNormalWorksViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffWfNormalWorksViewQuery, StaffWfNormalWorksViewQueryVariables>(StaffWfNormalWorksViewDocument, options);
      }
export function useStaffWfNormalWorksViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffWfNormalWorksViewQuery, StaffWfNormalWorksViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffWfNormalWorksViewQuery, StaffWfNormalWorksViewQueryVariables>(StaffWfNormalWorksViewDocument, options);
        }
export type StaffWfNormalWorksViewQueryHookResult = ReturnType<typeof useStaffWfNormalWorksViewQuery>;
export type StaffWfNormalWorksViewLazyQueryHookResult = ReturnType<typeof useStaffWfNormalWorksViewLazyQuery>;
export type StaffWfNormalWorksViewQueryResult = Apollo.QueryResult<StaffWfNormalWorksViewQuery, StaffWfNormalWorksViewQueryVariables>;
export const StaffWfOverviewViewDocument = gql`
    query StaffWFOverviewView($data: String) {
  projectsWithFlowItems(data: $data) {
    page
    pages
    hasNext
    hasPrev
    total
    objects {
      ...ProjectsWithFlowItemsObjectsFields
    }
  }
}
    ${ProjectsWithFlowItemsObjectsFieldsFragmentDoc}`;

/**
 * __useStaffWfOverviewViewQuery__
 *
 * To run a query within a React component, call `useStaffWfOverviewViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffWfOverviewViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffWfOverviewViewQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useStaffWfOverviewViewQuery(baseOptions?: Apollo.QueryHookOptions<StaffWfOverviewViewQuery, StaffWfOverviewViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffWfOverviewViewQuery, StaffWfOverviewViewQueryVariables>(StaffWfOverviewViewDocument, options);
      }
export function useStaffWfOverviewViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffWfOverviewViewQuery, StaffWfOverviewViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffWfOverviewViewQuery, StaffWfOverviewViewQueryVariables>(StaffWfOverviewViewDocument, options);
        }
export type StaffWfOverviewViewQueryHookResult = ReturnType<typeof useStaffWfOverviewViewQuery>;
export type StaffWfOverviewViewLazyQueryHookResult = ReturnType<typeof useStaffWfOverviewViewLazyQuery>;
export type StaffWfOverviewViewQueryResult = Apollo.QueryResult<StaffWfOverviewViewQuery, StaffWfOverviewViewQueryVariables>;
export const UserWizardCreateProjectViewDocument = gql`
    mutation UserWizardCreateProjectView {
  createNewProject {
    status
    project {
      id
      hashId
    }
  }
}
    `;
export type UserWizardCreateProjectViewMutationFn = Apollo.MutationFunction<UserWizardCreateProjectViewMutation, UserWizardCreateProjectViewMutationVariables>;

/**
 * __useUserWizardCreateProjectViewMutation__
 *
 * To run a mutation, you first call `useUserWizardCreateProjectViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserWizardCreateProjectViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userWizardCreateProjectViewMutation, { data, loading, error }] = useUserWizardCreateProjectViewMutation({
 *   variables: {
 *   },
 * });
 */
export function useUserWizardCreateProjectViewMutation(baseOptions?: Apollo.MutationHookOptions<UserWizardCreateProjectViewMutation, UserWizardCreateProjectViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserWizardCreateProjectViewMutation, UserWizardCreateProjectViewMutationVariables>(UserWizardCreateProjectViewDocument, options);
      }
export type UserWizardCreateProjectViewMutationHookResult = ReturnType<typeof useUserWizardCreateProjectViewMutation>;
export type UserWizardCreateProjectViewMutationResult = Apollo.MutationResult<UserWizardCreateProjectViewMutation>;
export type UserWizardCreateProjectViewMutationOptions = Apollo.BaseMutationOptions<UserWizardCreateProjectViewMutation, UserWizardCreateProjectViewMutationVariables>;
export const UserWizardProjectOverviewLocationViewDocument = gql`
    query UserWizardProjectOverviewLocationView($hashId: String, $externalId: String) {
  project(hashId: $hashId) {
    id
    hashId
    wizardStep
    locations {
      id
      title
      externalId
      locationItems {
        id
        externalId
      }
    }
  }
  getLocation(hashId: $hashId, locId: $externalId) {
    id
    ...LocationOverviewFragment
  }
}
    ${LocationOverviewFragmentFragmentDoc}`;

/**
 * __useUserWizardProjectOverviewLocationViewQuery__
 *
 * To run a query within a React component, call `useUserWizardProjectOverviewLocationViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWizardProjectOverviewLocationViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWizardProjectOverviewLocationViewQuery({
 *   variables: {
 *      hashId: // value for 'hashId'
 *      externalId: // value for 'externalId'
 *   },
 * });
 */
export function useUserWizardProjectOverviewLocationViewQuery(baseOptions?: Apollo.QueryHookOptions<UserWizardProjectOverviewLocationViewQuery, UserWizardProjectOverviewLocationViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserWizardProjectOverviewLocationViewQuery, UserWizardProjectOverviewLocationViewQueryVariables>(UserWizardProjectOverviewLocationViewDocument, options);
      }
export function useUserWizardProjectOverviewLocationViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserWizardProjectOverviewLocationViewQuery, UserWizardProjectOverviewLocationViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserWizardProjectOverviewLocationViewQuery, UserWizardProjectOverviewLocationViewQueryVariables>(UserWizardProjectOverviewLocationViewDocument, options);
        }
export type UserWizardProjectOverviewLocationViewQueryHookResult = ReturnType<typeof useUserWizardProjectOverviewLocationViewQuery>;
export type UserWizardProjectOverviewLocationViewLazyQueryHookResult = ReturnType<typeof useUserWizardProjectOverviewLocationViewLazyQuery>;
export type UserWizardProjectOverviewLocationViewQueryResult = Apollo.QueryResult<UserWizardProjectOverviewLocationViewQuery, UserWizardProjectOverviewLocationViewQueryVariables>;
export const UserWizardProjectOverviewViewDocument = gql`
    query UserWizardProjectOverviewView($hashId: String) {
  project(hashId: $hashId) {
    id
    ...ProjectOverviewFragment
  }
}
    ${ProjectOverviewFragmentFragmentDoc}`;

/**
 * __useUserWizardProjectOverviewViewQuery__
 *
 * To run a query within a React component, call `useUserWizardProjectOverviewViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWizardProjectOverviewViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWizardProjectOverviewViewQuery({
 *   variables: {
 *      hashId: // value for 'hashId'
 *   },
 * });
 */
export function useUserWizardProjectOverviewViewQuery(baseOptions?: Apollo.QueryHookOptions<UserWizardProjectOverviewViewQuery, UserWizardProjectOverviewViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserWizardProjectOverviewViewQuery, UserWizardProjectOverviewViewQueryVariables>(UserWizardProjectOverviewViewDocument, options);
      }
export function useUserWizardProjectOverviewViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserWizardProjectOverviewViewQuery, UserWizardProjectOverviewViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserWizardProjectOverviewViewQuery, UserWizardProjectOverviewViewQueryVariables>(UserWizardProjectOverviewViewDocument, options);
        }
export type UserWizardProjectOverviewViewQueryHookResult = ReturnType<typeof useUserWizardProjectOverviewViewQuery>;
export type UserWizardProjectOverviewViewLazyQueryHookResult = ReturnType<typeof useUserWizardProjectOverviewViewLazyQuery>;
export type UserWizardProjectOverviewViewQueryResult = Apollo.QueryResult<UserWizardProjectOverviewViewQuery, UserWizardProjectOverviewViewQueryVariables>;
export const UserWizardProjectBudgetViewDocument = gql`
    query UserWizardProjectBudgetView($hashId: String) {
  project(hashId: $hashId, curWizardStep: "4") {
    id
    statusComputed
    ...ProjectBudgetFragment
  }
}
    ${ProjectBudgetFragmentFragmentDoc}`;

/**
 * __useUserWizardProjectBudgetViewQuery__
 *
 * To run a query within a React component, call `useUserWizardProjectBudgetViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWizardProjectBudgetViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWizardProjectBudgetViewQuery({
 *   variables: {
 *      hashId: // value for 'hashId'
 *   },
 * });
 */
export function useUserWizardProjectBudgetViewQuery(baseOptions?: Apollo.QueryHookOptions<UserWizardProjectBudgetViewQuery, UserWizardProjectBudgetViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserWizardProjectBudgetViewQuery, UserWizardProjectBudgetViewQueryVariables>(UserWizardProjectBudgetViewDocument, options);
      }
export function useUserWizardProjectBudgetViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserWizardProjectBudgetViewQuery, UserWizardProjectBudgetViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserWizardProjectBudgetViewQuery, UserWizardProjectBudgetViewQueryVariables>(UserWizardProjectBudgetViewDocument, options);
        }
export type UserWizardProjectBudgetViewQueryHookResult = ReturnType<typeof useUserWizardProjectBudgetViewQuery>;
export type UserWizardProjectBudgetViewLazyQueryHookResult = ReturnType<typeof useUserWizardProjectBudgetViewLazyQuery>;
export type UserWizardProjectBudgetViewQueryResult = Apollo.QueryResult<UserWizardProjectBudgetViewQuery, UserWizardProjectBudgetViewQueryVariables>;
export const UserWizardProjectLandscapeViewDocument = gql`
    query UserWizardProjectLandscapeView($hashId: String) {
  project(hashId: $hashId) {
    id
    statusComputed
    ...UserWizardProjectLocationItemFragment
  }
}
    ${UserWizardProjectLocationItemFragmentFragmentDoc}`;

/**
 * __useUserWizardProjectLandscapeViewQuery__
 *
 * To run a query within a React component, call `useUserWizardProjectLandscapeViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWizardProjectLandscapeViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWizardProjectLandscapeViewQuery({
 *   variables: {
 *      hashId: // value for 'hashId'
 *   },
 * });
 */
export function useUserWizardProjectLandscapeViewQuery(baseOptions?: Apollo.QueryHookOptions<UserWizardProjectLandscapeViewQuery, UserWizardProjectLandscapeViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserWizardProjectLandscapeViewQuery, UserWizardProjectLandscapeViewQueryVariables>(UserWizardProjectLandscapeViewDocument, options);
      }
export function useUserWizardProjectLandscapeViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserWizardProjectLandscapeViewQuery, UserWizardProjectLandscapeViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserWizardProjectLandscapeViewQuery, UserWizardProjectLandscapeViewQueryVariables>(UserWizardProjectLandscapeViewDocument, options);
        }
export type UserWizardProjectLandscapeViewQueryHookResult = ReturnType<typeof useUserWizardProjectLandscapeViewQuery>;
export type UserWizardProjectLandscapeViewLazyQueryHookResult = ReturnType<typeof useUserWizardProjectLandscapeViewLazyQuery>;
export type UserWizardProjectLandscapeViewQueryResult = Apollo.QueryResult<UserWizardProjectLandscapeViewQuery, UserWizardProjectLandscapeViewQueryVariables>;
export const UserWizardSummaryViewDocument = gql`
    query UserWizardSummaryView($hashId: String) {
  project(hashId: $hashId) {
    id
    ...ProjectSummaryFragment
  }
  getProductsForProject(hashId: $hashId) {
    id
  }
}
    ${ProjectSummaryFragmentFragmentDoc}`;

/**
 * __useUserWizardSummaryViewQuery__
 *
 * To run a query within a React component, call `useUserWizardSummaryViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWizardSummaryViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWizardSummaryViewQuery({
 *   variables: {
 *      hashId: // value for 'hashId'
 *   },
 * });
 */
export function useUserWizardSummaryViewQuery(baseOptions?: Apollo.QueryHookOptions<UserWizardSummaryViewQuery, UserWizardSummaryViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserWizardSummaryViewQuery, UserWizardSummaryViewQueryVariables>(UserWizardSummaryViewDocument, options);
      }
export function useUserWizardSummaryViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserWizardSummaryViewQuery, UserWizardSummaryViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserWizardSummaryViewQuery, UserWizardSummaryViewQueryVariables>(UserWizardSummaryViewDocument, options);
        }
export type UserWizardSummaryViewQueryHookResult = ReturnType<typeof useUserWizardSummaryViewQuery>;
export type UserWizardSummaryViewLazyQueryHookResult = ReturnType<typeof useUserWizardSummaryViewLazyQuery>;
export type UserWizardSummaryViewQueryResult = Apollo.QueryResult<UserWizardSummaryViewQuery, UserWizardSummaryViewQueryVariables>;
export const UserWizardProjectTimelineViewDocument = gql`
    query UserWizardProjectTimelineView($hashId: String) {
  project(hashId: $hashId, curWizardStep: "3") {
    id
    statusComputed
    ...ProjectTimelineFragment
  }
}
    ${ProjectTimelineFragmentFragmentDoc}`;

/**
 * __useUserWizardProjectTimelineViewQuery__
 *
 * To run a query within a React component, call `useUserWizardProjectTimelineViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWizardProjectTimelineViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWizardProjectTimelineViewQuery({
 *   variables: {
 *      hashId: // value for 'hashId'
 *   },
 * });
 */
export function useUserWizardProjectTimelineViewQuery(baseOptions?: Apollo.QueryHookOptions<UserWizardProjectTimelineViewQuery, UserWizardProjectTimelineViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserWizardProjectTimelineViewQuery, UserWizardProjectTimelineViewQueryVariables>(UserWizardProjectTimelineViewDocument, options);
      }
export function useUserWizardProjectTimelineViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserWizardProjectTimelineViewQuery, UserWizardProjectTimelineViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserWizardProjectTimelineViewQuery, UserWizardProjectTimelineViewQueryVariables>(UserWizardProjectTimelineViewDocument, options);
        }
export type UserWizardProjectTimelineViewQueryHookResult = ReturnType<typeof useUserWizardProjectTimelineViewQuery>;
export type UserWizardProjectTimelineViewLazyQueryHookResult = ReturnType<typeof useUserWizardProjectTimelineViewLazyQuery>;
export type UserWizardProjectTimelineViewQueryResult = Apollo.QueryResult<UserWizardProjectTimelineViewQuery, UserWizardProjectTimelineViewQueryVariables>;
export const UserWizardProjectTitleViewDocument = gql`
    query UserWizardProjectTitleView($hashId: String) {
  project(hashId: $hashId, curWizardStep: "1") {
    id
    statusComputed
    ...ProjectTitleFragment
  }
  clients {
    id
    name
  }
}
    ${ProjectTitleFragmentFragmentDoc}`;

/**
 * __useUserWizardProjectTitleViewQuery__
 *
 * To run a query within a React component, call `useUserWizardProjectTitleViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWizardProjectTitleViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWizardProjectTitleViewQuery({
 *   variables: {
 *      hashId: // value for 'hashId'
 *   },
 * });
 */
export function useUserWizardProjectTitleViewQuery(baseOptions?: Apollo.QueryHookOptions<UserWizardProjectTitleViewQuery, UserWizardProjectTitleViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserWizardProjectTitleViewQuery, UserWizardProjectTitleViewQueryVariables>(UserWizardProjectTitleViewDocument, options);
      }
export function useUserWizardProjectTitleViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserWizardProjectTitleViewQuery, UserWizardProjectTitleViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserWizardProjectTitleViewQuery, UserWizardProjectTitleViewQueryVariables>(UserWizardProjectTitleViewDocument, options);
        }
export type UserWizardProjectTitleViewQueryHookResult = ReturnType<typeof useUserWizardProjectTitleViewQuery>;
export type UserWizardProjectTitleViewLazyQueryHookResult = ReturnType<typeof useUserWizardProjectTitleViewLazyQuery>;
export type UserWizardProjectTitleViewQueryResult = Apollo.QueryResult<UserWizardProjectTitleViewQuery, UserWizardProjectTitleViewQueryVariables>;
export const UserWizardProjectTypeViewDocument = gql`
    query UserWizardProjectTypeView($hashId: String) {
  project(hashId: $hashId, curWizardStep: "2") {
    id
    statusComputed
    ...ProjectTypeFragment
  }
}
    ${ProjectTypeFragmentFragmentDoc}`;

/**
 * __useUserWizardProjectTypeViewQuery__
 *
 * To run a query within a React component, call `useUserWizardProjectTypeViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWizardProjectTypeViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWizardProjectTypeViewQuery({
 *   variables: {
 *      hashId: // value for 'hashId'
 *   },
 * });
 */
export function useUserWizardProjectTypeViewQuery(baseOptions?: Apollo.QueryHookOptions<UserWizardProjectTypeViewQuery, UserWizardProjectTypeViewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserWizardProjectTypeViewQuery, UserWizardProjectTypeViewQueryVariables>(UserWizardProjectTypeViewDocument, options);
      }
export function useUserWizardProjectTypeViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserWizardProjectTypeViewQuery, UserWizardProjectTypeViewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserWizardProjectTypeViewQuery, UserWizardProjectTypeViewQueryVariables>(UserWizardProjectTypeViewDocument, options);
        }
export type UserWizardProjectTypeViewQueryHookResult = ReturnType<typeof useUserWizardProjectTypeViewQuery>;
export type UserWizardProjectTypeViewLazyQueryHookResult = ReturnType<typeof useUserWizardProjectTypeViewLazyQuery>;
export type UserWizardProjectTypeViewQueryResult = Apollo.QueryResult<UserWizardProjectTypeViewQuery, UserWizardProjectTypeViewQueryVariables>;