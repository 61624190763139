import gql from 'graphql-tag'

export const FlowItemCommissionedWorkStaffFragment = gql`
  fragment FlowItemCommissionedWorkStaffFragment on FlowItemCommissionedWorkType {
    id
    title
    artist {
      id
      name
    }
    artworkDescription
    estimatedTimeline
    budget

    isAvailable
    cost
    leadTime
    hasSketchFee
    sketchFee
    portfolio1
    portfolio2
    portfolio3
    remarks

    history {
      id
      state
      dateCreated
      dateFinished
      data
      uniqueUrl
      changeUser {
        id
        email
      }
    }
  }
`

export const FlowItemArtworkStaffFragment = gql`
  fragment FlowItemArtworkStaffFragment on FlowItemArtworkType {
    id
    isAvailable
    isNotReady
    leadTime
    destinationCountryName
    destinationCity
    destinationZip
    shipsFromCountryName
    shipsFromCity
    shipsFromZip
    shippedRolled
    shippedFramed
    isShippingCostUnknown
    shippingCost
    remarks
    history {
      id
      state
      dateCreated
      dateFinished
      data
      uniqueUrl
      changeUser {
        id
        email
      }
      changeUserStr
    }
  }
`

export const CurrentProfileFragment = gql`
  fragment CurrentProfileFragment on UserProfileType {
    id
    isSuperuser
    crispUserHash
    joyrideSearchViewCompleted
    joyrideShortlistedViewCompleted
    lastSourced
    hasTradeDiscount
    user {
      id
      email
    }
  }
`

export const FramingSpecsQuotationsFields = gql`
  fragment FramingSpecsQuotationsFields on FramingSpecsQuotationType {
    id
    frameWidth
    frameHeight
    frameThickness
    frameMaterial
    frameColour
    matMaterial
    matThickness
    glazingMaterial
    cost
    leadTimeUnit
    leadTime
    comments
    images {
      id
      imageKey
      imageKeyFilename
    }
  }
`

export const FramerGlobalQuotationFragmentFlowItemsFields = gql`
  ${FramingSpecsQuotationsFields}
  fragment FramerGlobalQuotationFragmentFlowItemsFields on FlowItemArtworkFramerRFQType {
    id
    title
    image
    destinationCountryName
    destinationCity
    destinationZip
    framerArtworkMaterial
    framerDeliveryRequired
    framerStretchingRequired
    framerFramingRequired
    framerInstallationRequired
    framerLocksComment
    framerWeight
    framerSizeStr
    framerWeightStr
    framerQuantity
    framerNotes
    stretchingQuotation(framerHashId: $framerHashId) {
      id
      cost
      leadTimeUnit
      leadTime
      comments
    }
    framingSpecsQuotations(framerHashId: $framerHashId) {
      ...FramingSpecsQuotationsFields
    }
  }
`

export const FramerGlobalQuotationFragment = gql`
  ${FramerGlobalQuotationFragmentFlowItemsFields}
  fragment FramerGlobalQuotationFragment on FramerGlobalQuotationType {
    isAvailable
    isAvailableStretching
    isAvailableFraming
    isAvailableDelivery
    isAvailableInstallation
    unit
    currency
    deliveryCost
    deliveryComments
    installationCost
    installationAdditionalCharge
    installationAdditionalCost
    installationComments
    dateFinished
    flowItems {
      ...FramerGlobalQuotationFragmentFlowItemsFields
    }
  }
`

export const FramerFragment = gql`
  fragment FramerFragment on FramerType {
    id
    framerId: id
    name
    primaryEmail
    secondaryEmails
    providesFraming
    providesInstallation
    providesStretching
    adminNotes
    country
  }
`

export const FramerRowFragment = gql`
  fragment FramerRowFragment on FramerType {
    name
    providesFraming
    providesInstallation
    providesStretching
  }
`

export const FlowItemFramerInfoFragment = gql`
  fragment FlowItemFramerInfoFragment on FlowItemArtworkType {
    framerArtworkMaterial
    framerUnit
    framerWidth
    framerHeight
    framerThickness
    framerWeight
    framerQuantity
    framerNotes
    framerLocksComment
    framerFramingRequired
    framerInstallationRequired
    framerStretchingRequired
    framerDeliveryRequired
    framerStaffUpdatedAt
    bucketItem {
      id
      selectedSize
    }
  }
`

export const WorkflowSelectedWorksFragment = gql`
  fragment WorkflowSelectedWorksFragment on NormalWorksLocation {
    id: locationId
    locationTitle
    locationItems {
      id: locationItemId
      locationItemTitle
      steps {
        step
        items_final: items {
          id
          esId
          image
          title
          artist
          framerStaffUpdatedAt
          framerStretchingRequired
          framerDeliveryRequired
          framerFramingRequired
          framerInstallationRequired
          framerLocksComment
          locationItem {
            id
            title
            location {
              id
              title
              project {
                hashId
                id
              }
            }
          }
          currentStates {
            id
            state
            dateCreated
            uniqueUrl
          }
        }
      }
    }
  }
`

export const LocationItemDetailFragment = gql`
  fragment LocationItemDetailFragment on LocationItemType {
    id
    dimensionsStr
    info
    externalId
    itemHeight
    itemUnsure
    itemWidth
    itemDepth
    measurementUnit
    title
    titleStr
    wallHeight
    wallUnsure
    wallWidth
    wallDepth
    wallSizeSameAsArtwork
    locationItemIsReady
    isCommissionedWork
    commissionedWorkInfo
    attribute1 {
      id
      title
    }
    attribute2 {
      id
      title
    }
    bucketItems {
      id
    }
    category {
      id
      title
      slug
    }
    locationItemType {
      id
      title
      slug
    }
    locationItemImages {
      id
      externalId
      image
      imageFilename
      isSensitive
    }
    floorplans {
      id
      image
      imageFilename
      isSensitive
    }
    location {
      id
      externalId
      title
      titleStr
      project {
        id
        title
        hashId
        budgetUnsure
        budget
        budgetCurrency
        countryName
        status
      }
      locationItems {
        id
        externalId
      }
      locationImages {
        id
        externalId
        image
        imageFilename
      }
    }
  }
`

export const ProjectOverviewFragment = gql`
  fragment ProjectOverviewFragment on ProjectType {
    id
    hashId
    wizardStep
    locationCount
    wizardStepIsReady
    locations {
      id
      externalId
      title
      locationIsReady
      locationItems {
        id
        externalId
        locationItemIsReady
      }
    }
  }
`

export const BucketItemDetailsFragment = gql`
  fragment BucketItemDetailsFragment on BucketItemType {
    id
    artworkTitle
    artistName
    price
    shipsFrom
    dimensions
    mediums
    otherDetails
  }
`

export const LocationSummaryFragment = gql`
  ${LocationItemDetailFragment}
  fragment LocationSummaryFragment on LocationType {
    id
    externalId
    title
    info
    budget
    locationImageUrl
    hasCommissioned
    hasImages
    locationImages {
      id
      externalId
      image
      isSensitive
    }
    locationItems {
      id
      ...LocationItemDetailFragment
    }
    activeLocationItems {
      id
      ...LocationItemDetailFragment
    }
  }
`

export const ProjectInviteFragment = gql`
  fragment ProjectInviteFragment on ProjectType {
    id
    unusedSignupkeys {
      id
      inviteSentTo
    }
    sharedUsers {
      id
      email
      userprofile {
        id
        firstName
        lastName
      }
    }
  }
`

export const ProjectSummaryFragment = gql`
  ${LocationSummaryFragment}
  fragment ProjectSummaryFragment on ProjectType {
    id
    hashId
    wizardStep
    status
    title
    timelineUnsure
    timelineStart
    timelineEnd
    budget
    budgetCurrency
    city
    countryName
    countryCode
    postalCode
    quotationInstallationRequired
    quotationComments
    quotationShippingName
    quotationShippingAddress
    quotationShippingCity
    quotationShippingState
    quotationShippingZip
    quotationShippingCountryName
    quotationShippingCountryCode
    quotationBillingName
    quotationBillingAddress
    quotationBillingCity
    quotationBillingState
    quotationBillingZip
    quotationBillingCountryName
    quotationBillingCountryCode
    statusComputed
    artworkProposalRequestedAt
    artworkProposalSentAt
    client {
      id
      name
    }
    projectTypesStr
    projectType {
      id
      title
    }
    locations {
      id
      ...LocationSummaryFragment
    }
    activeLocations {
      id
      ...LocationSummaryFragment
    }
  }
`

export const ProjectBudgetFragment = gql`
  fragment ProjectBudgetFragment on ProjectType {
    id
    hashId
    wizardStep
    wizardStepIsReady
    budget
    budgetCurrency
    budgetInfo
    budgetUnsure
    locations {
      id
      externalId
      title
      locationItems {
        id
        externalId
      }
    }
  }
`

export const ProjectTimelineFragment = gql`
  fragment ProjectTimelineFragment on ProjectType {
    id
    hashId
    wizardStep
    wizardStepIsReady
    timelineEnd
    timelineUnsure
    timelineInfo
    locations {
      id
      externalId
      locationItems {
        id
        externalId
      }
    }
  }
`

export const ProjectTypeFragment = gql`
  fragment ProjectTypeFragment on ProjectType {
    id
    hashId
    wizardStep
    wizardStepIsReady
    projectType {
      id
      title
      iconSlug
    }
    projectTypeOther
    projectTypeInfo
    locations {
      id
      externalId
      locationItems {
        id
        externalId
      }
    }
  }
`

export const ProjectTitleFragment = gql`
  fragment ProjectTitleFragment on ProjectType {
    id
    status
    hashId
    title
    titleInfo
    wizardStep
    wizardStepIsReady
    postalCode
    countryName
    countryCode
    city
    client {
      id
      name
    }
    locations {
      id
      externalId
      locationItems {
        id
        externalId
      }
    }
  }
`

export const ProjectPDFPageFragment = gql`
  fragment ProjectPdfPageFragment on ProjectType {
    id
    pdfVertical
    pdfEmail
    pdfAddr
    pdfSite
    pdfLogo
  }
`

export const ProposalPageFragment = gql`
  fragment ProposalPageFragment on PDFPageType {
    id
    isActive
    pageType
    pdfDetails
    position
    project {
      id
    }
    locationItem {
      id
      externalId
      location {
        id
        externalId
      }
    }
    bucketItem {
      id
    }
    floorplan {
      id
    }
    virImage {
      id
    }
  }
`

export const LocationFragment = gql`
  fragment LocationFragment on LocationType {
    id
    title
    project {
      id
      title
    }
  }
`

export const LocationOverviewFragment = gql`
  fragment LocationOverviewFragment on LocationType {
    id
    title
    externalId
    info
    locationImages {
      id
      externalId
      image
      imageFilename
    }
    project {
      id
      hashId
    }
  }
`

export const ProductForLocationItemFragment = gql`
  fragment ProductForLocationItemFragment on ProductType {
    id
    esId
    name
    image
    artist {
      name
    }
    cheapestPriceString
    site
    sizes {
      width
      height
      thickness
      diameter
      printBaseCost {
        printSize
        printMaterial
      }
      price
    }
  }
`

export const ProductGridFragment = gql`
  fragment ProductGridFragment on ProductType {
    id
    esId
    favorited
    name
    image
    artistUpdatedAt
    artist {
      name
    }
    gallery {
      name
    }
    category {
      slug
    }
    cheapestPriceString
    sizeString
    site
    sizes {
      id
      width
      height
      thickness
      diameter
      printBaseCost {
        printSize
        printMaterial
      }
      price
      framingStatus
      stretchingStatus
      sizeString
    }
  }
`

export const ProductFragment = gql`
  ${ProductGridFragment}
  fragment ProductFragment on ProductType {
    id
    ...ProductGridFragment
    extraImages
    detailUrl
    hideVir
    showFrameOnVir
    artistUpdatedAt
    artist {
      id
      detailUrl
      canDoCommissionedWork
      canDoPublicMurals
      country {
        name
      }
    }
    description
    artworksMedium
    artworkStyle {
      slug
    }
    exclusive
    remainingCount
    designLeadTime
    designSuitableForOutdoors
    designMaterial {
      slug
    }
    printOrientation
    medium {
      slug
      title
    }
    country {
      slug
      name
    }
    sizes {
      id
      widthIn
      heightIn
      thicknessIn
      diameterIn
      orientation
      printActive
      priceString
      sizeAndPriceString
    }
    tags
    gvLabels
  }
`

export const ProductAndItemFragment = gql`
  ${ProductGridFragment}
  ${LocationItemDetailFragment}
  fragment ProductAndItemFragment on ProductAndItemType {
    id
    copyPasteText
    product {
      id
      ...ProductGridFragment
      designLeadTime
      remainingCount
      medium {
        title
      }
    }
    bucketItem {
      id
      esId
      vimrImages {
        id
        image
      }
      locationItem {
        id
        ...LocationItemDetailFragment
      }
      customText
      customSize
      selectedSize
      originalPrice
      price
      framingStatus
      stretchingStatus
      framingAndStretchingStatus
      position
      isShortlisted
      quotationQuantity
      quotationFramingRequired
      quotationFramingComments
      quotationAdditionalComments
    }
  }
`

export const UserWizardProjectLocationItemFragmentLocationsFragment = gql`
  ${LocationItemDetailFragment}
  fragment UserWizardProjectLocationItemFragmentLocationsFragment on LocationType {
    id
    externalId
    title
    titleStr
    locationImages {
      id
      externalId
      image
      imageFilename
    }
    floorplans {
      id
      image
      imageFilename
    }
    locationItems {
      id
      ...LocationItemDetailFragment
    }
  }
`

export const UserWizardProjectLocationItemFragment = gql`
  ${UserWizardProjectLocationItemFragmentLocationsFragment}
  fragment UserWizardProjectLocationItemFragment on ProjectType {
    hashId
    wizardStep
    locations {
      ...UserWizardProjectLocationItemFragmentLocationsFragment
    }
  }
`

export const BucketItemsFragment = gql`
  fragment BucketItemsFragment on ProductType {
    id
    bucketId
    croppedImage
    image
    name
    hideVir
    site
    category {
      slug
    }
    medium {
      slug
    }
    baseSize {
      height
      width
      thickness
      sizeString
    }
    selectedWidth
    selectedHeight
    selectedThickness
  }
`

export const LocationItemFragment = gql`
  ${BucketItemsFragment}
  fragment LocationItemFragment on LocationItemType {
    titleStr
    wallWidth
    wallHeight
    wallDepth
    wallVimrBg
    bucketItems {
      id
      esId
      wallPt
    }
    bucketItemsData {
      id
      ...BucketItemsFragment
    }
    location {
      id
      titleStr
    }
  }
`

export const LocationItemVIMRFragment = gql`
  fragment LocationItemVIMRFragment on LocationItemType {
    allImages
    wallPt1
    wallPt2
    wallPt3
    wallPt4
    wallImgRotation
  }
`

export const LocationPositionFragment = gql`
  ${ProductAndItemFragment}
  fragment LocationPositionFragment on LocationType {
    id
    externalId
    title
    titleStr
    isShortlisted
    locationItems {
      id
      externalId
      title
      titleStr
      locationItemTypeStr
      dimensionsStr
      position
      isShortlisted
      category {
        id
        title
        slug
      }
      location {
        id
        externalId
      }
      bucketItems_final: productsAndBucketItems {
        id
        ...ProductAndItemFragment
      }
    }
  }
`

export const UserProfileFragment = gql`
  fragment UserProfileFragment on UserProfileType {
    user {
      email
      id
    }
    firstName
    lastName
    title
    phoneNo
    companyName
    userStreet
    userCity
    userCountryCode
    userZip
    userPhoneNo
    userCompanyName
  }
`

export const ArtistVariantProjectFragment = gql`
  fragment ArtistVariantProjectFragment on ArtistVariantProjectType {
    name
    date
    rate
    size
    status
  }
`
