/** @jsx jsx */
import React, { useState } from 'react'
import { jsx } from '@emotion/react'

const EnvironmentBadge: React.FC = () => {
  if (process.env.RAZZLE_ENVIRONMENT === 'prod') {
    return null
  }

  const [closed, setClosed] = useState(false)

  const value =
    process.env.RAZZLE_ENVIRONMENT === 'local' ? 'LOCALHOST' : 'STAGING'

  return (
    <div
      css={{ height: '50px', display: closed ? 'none' : 'block' }}
      onClick={() => setClosed(true)}
    >
      <div
        css={{
          height: '50px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'purple',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 1,
          background: '#EFEFEF',
          width: '100%',
          fontWeight: 'bold',
        }}
      >
        {value}
      </div>
    </div>
  )
}

export default EnvironmentBadge
