import get from 'lodash/get'
import { Scope, Event } from '@sentry/node'

import { log } from './logging'

export function filterErrors(event: Event, hint: any) {
  if (event.user) {
    // preserving some privacy for our users, Sentry doesn't need to see
    // this information
    delete event.user.email
    delete event.user.username
  }

  // this is a list of URLs for which we never want to log any errors
  const url_blacklist = ['https://translate.googleusercontent.com/']

  // this is a list of filenames for externally loaded libraries that are
  // noisy, so we want to surpress their errors from arriving in Sentry
  const file_blacklist = [
    '/en_US/fbevents.js',
    'https://client.crisp.chat',
    'chrome-extension://',
    './node_modules/',
  ]

  // this is a list of lists, the inner lists contain
  // the Exception type and the Exception message
  const error_blacklist: string[] = []

  let blacklisted = false

  url_blacklist.forEach((item: any) => {
    const url = get(event, 'request.url', '')
    if (!!url && url !== '') {
      if (url.startsWith(item)) {
        log('blacklisted')
        blacklisted = true
      }
    }
  })

  file_blacklist.forEach((item: any) => {
    const frames = get(event, 'exception.values.0.stacktrace.frames', '')

    if (frames && frames.length > 0) {
      // the first frame is often our own bundle, so we better look at the
      // last frame in the trace
      const lastFrame = frames[frames.length - 1]
      const filename = get(lastFrame, 'filename', '')
      if (filename.startsWith(item)) {
        blacklisted = true
      }
    }
  })

  error_blacklist.forEach((item: any) => {
    const error = get(event, 'exception.values.0')
    const errorType = `${error.type}`
    const errorValue = `${error.value}`
    if (!!error && errorType == item[0] && errorValue.startsWith(item[1])) {
      blacklisted = true
    }
  })

  // blacklist errors that come from third party sources
  const stack = get(hint, 'syntheticException.stack')
  if (!!stack && `${stack}`.indexOf('./src/') < 0) {
    blacklisted = true
  }

  if (!!blacklisted) {
    return null
  }

  return event
}

export function reportError(
  Sentry: any,
  graphQLErrors: any,
  networkError: any,
  req?: Request
) {
  const errors: any[] = []
  let fullError
  let fingerprint: string[] = []

  if (graphQLErrors)
    graphQLErrors.forEach((item: any) => {
      fingerprint = ['GraphQLError', item.path[0], item.message]
      fullError = `[GraphQL error]: Message: ${item.message}, Path: ${item.path[0]}`
      errors.push(fullError)
      log(fullError)
    })

  if (networkError && !!networkError.result) {
    networkError.result.forEach((item: any) => {
      if (!!item.errors) {
        fingerprint = ['NetworkError', item.errors[0].message]
        fullError = `[Network error]: ${item.errors[0].message}`
        errors.push(fullError)
        log(fullError)
        log(networkError)
      }
    })
  }

  if (!!Sentry && process.env.RAZZLE_SENTRY_DSN) {
    Sentry.withScope((scope: Scope) => {
      // all errors will be added as Extra data, only the first will be
      // added so that it looks nice in the Sentry issues view... should be
      // a very rare case that we ever get more than one error anyways
      scope.setExtras({ errors })
      scope.setFingerprint(fingerprint)

      let errorMsg = 'No error message found'
      if (errors && errors.length > 0 && !!errors[0]) {
        errorMsg = `${errors[0]}`
      }

      Sentry.captureException(new Error(errorMsg), { req: req })
    })
  }

  return errors
}
