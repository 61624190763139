import React, { createContext, useState, useContext } from 'react'

export interface ModalsContextInterface {
  // this is supposed to be a mounted React component, ie <ModalTest />
  currentModal: any
  // modal arg is a mounted React component, ie <ModalTest />
  show: (modal: any) => void
  hide: () => void
  clear: () => void
}

export const ModalsContext = createContext<ModalsContextInterface | null>(null)

interface Props {
  children: React.ReactNode
}

export const ModalsContextProvider: React.FC<Props> = ({ children }) => {
  const [currentModal, setCurrentModal] = useState<any[]>([])

  function show(modal: any) {
    setCurrentModal((prev) => prev.concat([modal]))
  }

  function hide() {
    setCurrentModal((prev) => prev.slice(0, -1))
  }

  function clear() {
    setCurrentModal([])
  }

  const ctx = {
    currentModal,
    show,
    hide,
    clear,
  }
  return <ModalsContext.Provider value={ctx}>{children}</ModalsContext.Provider>
}

const Modals = () => {
  const modalsCtx = useContext(ModalsContext)

  if (!modalsCtx) {
    return null
  }
  const len = modalsCtx.currentModal.length
  if (!!len) {
    // here we take whatever mounted React component we got and add the prop
    // isVisible={true} to it
    const modal = React.cloneElement(modalsCtx.currentModal[len - 1], {
      isVisible: true,
    })
    return modal
  }

  return null
}

export default Modals
